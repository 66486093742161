import React, { useEffect, useState } from "react";
import PopupHeader from "../../../common-page/popupHeader";
import {
  Button,
  Card,
  CardContent,
  TextareaAutosize,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles,
  Splitter,
  ButtonWrapper,
} from "../../../../styles/common-styles";
import {
  SelectInput,
  TextInput,
  DateInput,
  CheckBoxInput,
  TextLookUpInput,
} from "../../../../components/Forms";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { financialAid_AssLoadSeq } from "../../../../services/navigation_folder/financialAid";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { addDoc_select_items, document_tracking, get_documents, load_doc_seq } from "../../../../services/apiURL";
import { getApi, postApi } from "../../../../services/apiBase";
import { toastMessage } from "../../../../utils/Message/toasts";

const AddLoadDocSequence = ({ onClose, getSelectedRow, setFinancialAwards, locationId,selectedSelected, filtertoPackage, setIsloading,setShowAddData, setShowLoadSeq,tableRow,setTableRow,selectedStatusRow }) => {
  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);
  const [loadDocSequence] = useState([
    { id: 'group', numeric: true, disablePadding: false, label: t('loadDocSequence.group', { ns: 'financialAidHeadcells' }) },
    { id: 'docName', numeric: true, disablePadding: false, label: t('loadDocSequence.docName', { ns: 'financialAidHeadcells' }) },
    { id: 'docStatus', numeric: true, disablePadding: false, label: t('loadDocSequence.docStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'defStatus', numeric: true, disablePadding: false, label: t('loadDocSequence.defStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'daysBetween', numeric: true, disablePadding: false, label: t('loadDocSequence.daysBetween', { ns: 'financialAidHeadcells' }) },
    { id: 'location', numeric: true, disablePadding: false, label: t('loadDocSequence.location', { ns: 'financialAidHeadcells' }) },
    { id: 'locationID', numeric: true, disablePadding: false, label: t('loadDocSequence.locationID', { ns: 'financialAidHeadcells' }) },
    { id: 'activeFlag', numeric: true, disablePadding: false, label: t('loadDocSequence.activeFlag', { ns: 'financialAidHeadcells' }) },
    { id: 'internal', numeric: true, disablePadding: false, label: t('loadDocSequence.internal', { ns: 'financialAidHeadcells' }) },

  ])

  const [tableRowData, setTableRowData] = useState([])
  const validationSchema = yup.object({
    documentGrp: yup
      .string()
      .required(t("validationMessages.isRequired", { what: t("commonLabel.documentGroup", { ns: "financialAid" }), })),
    documentdate: yup
      .date()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.documentDate') }))
      .nullable()
      .max(new Date('12/12/2100')) // date max range 2100
      .min(new Date('12/12/2000'))
      .typeError(''),

  });
  const username = localStorage.getItem("username");
  const formik = useFormik({
    initialValues: {
      documentGrp: '',
      documentdate: new Date()
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payloadRequest = [];
      if (tableRowData.length > 0) {
        tableRowData.map((row) => {
          let payload =
          {
            "docTrackId": 0,
            "studentUid": selectedSelected.studentUID,
            "docDate": values.documentdate,
            "docNameId": row.docNameId,
            "docDescription": row.docDescription,
            "docStatusId": row.defStatusId,
            "comments": "",
            "reference": "",
            "compDate": null,
            "userDefinedFieldId": 0,
            "finAidYearSeq": selectedStatusRow?.financialAidYearUID || 0,
            "docGroupID": row.groupId,
            "locationId": location,
            "admissionGpagroupId": 0,
            "internal": row.internal,
            "isProspectDoc": false,
            "userId": username
          }
          payloadRequest.push(payload);
        })
        postApi(`${document_tracking}?requestFrom=DOCSEQ`, payloadRequest).then((res) => {
          toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'))
          onClose('');
        }).catch((err) => {
          console.log(err)
        })
      }
    },
  })
  const [location, setLocationId] = useState(0)
//   const [module, setModule] = useState({
//     moduleName: "", moduleId: ""
// })
  useEffect(()=> {
    getApi(`${document_tracking}/Financial aid`).then((resp) => {
      const { uniqueId, displayText } = resp.data.data;
      // setModule({ ...module, "moduleName": displayText, "moduleId": uniqueId });
      setLocationId(uniqueId)
  }).catch((err) => {
      console.log(err);
  })
    
  },[])

  const handleDelete = () => { };

  const classess = useStyles();

  React.useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
  }, []);
  const [Dropdown, setDropdown] = useState({
    documentGroupDrop: []
  })

  useEffect(() => {
    getApi(addDoc_select_items)
      .then((resp) => {
        const {
          documentGroup
        } = resp.data.data
        setDropdown({
          ...Dropdown,
          documentGroupDrop: documentGroup
        })
      })
      .catch((err) => {
        console.log(err)
      })
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
  }, [])
  useEffect(() => {
    if (formik.values.documentGrp) {
      getApi(`${load_doc_seq}/${formik.values.documentGrp}`).then((resp) => {
        setTableRowData(resp.data.data)
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [formik.values.documentGrp])
  const onCancel = () => {
    setShowLoadSeq(false)
    setShowAddData(true)
    document.getElementsByTagName("html")[0].classList.remove("popup-Page");
}
  return (
    <>
      <PopupHeader
        title={t("commonLabel.addLoadDocSequence", { ns: "financialAid" })}
        onClose={onClose}
        navigationList={financialAid_AssLoadSeq}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <CardContent>
              <FormGroup>
                <SelectInput mandatory={true} isDoubled label={t('commonLabel.documentGroup')} labelId={"documentGrp"} formikValue={formik.values.documentGrp} formikChange={formik.handleChange}
                  errors={formik.errors.documentGrp}
                  touched={formik.touched.documentGrp}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentGroupDrop }}
                />
                <DateInput
                  label={t("commonLabel.documentdate", { ns: "financialAid" })}
                  labelId={"documentdate"}
                  errors={formik.errors.documentdate}
                  touched={formik.touched.documentdate}
                  mandatory
                  defaultValue={formik.values.documentdate}
                  formik={formik}
                />

                <ButtonWrapperFilter className="flexStart">
                  <Button
                    className="BgLightBlue"
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {t("buttonLabel.load", { ns: "financialAid" })}
                  </Button>
                </ButtonWrapperFilter>
              </FormGroup>
              <DefaultTable
                headCells={loadDocSequence}
                rows={tableRowData}
                pagination={true}
                onRowSelect={getSelectedRow}
                onDelete={handleDelete}
                isLookup={true}
              />
            </CardContent>
          </CardContent>
        </Card>
        <ButtonWrapperFilter>
          <Button
            aria-label={"Load"}
            className="BgLightBlue"
            variant="contained"
            size="large"
            onClick={()=>{onClose('')}}
          >
            {t("buttonLabel.cancel")}
          </Button>
        </ButtonWrapperFilter>
      </form>
    </>
  );
};

export default AddLoadDocSequence;
