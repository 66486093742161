import React, { useState, useEffect } from "react";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { Button, Card, CardContent, TextareaAutosize } from "@material-ui/core";
import { useHistory } from "react-router";
import { Form, useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput, TextAreaInput } from "../../../../components/Forms";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles,
    Splitter,
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_Adddocuments } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from 'yup';
import { addDoc_select_items, add_document, more_tab_acadmecId, update_document } from "../../../../services/apiURL";
import { getApi, postApi, putApi } from "../../../../services/apiBase";
import { toastMessage } from "../../../../utils/Message/toasts";
import DefaultTable from "../../../../utils/Table/DefaultTable";

const AddDocuments = ({ onClose, selectedDoc, selectedStatusRow, filtertoPackage, selectedSelected, fyDropDown, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const [locactionData, setLocationData] = useState('')
    const [locationId, setLocationId] = useState('')
    const [date, setDate] = useState(new Date())
    const classess = useStyles();
    const initialValues = {
        "docDate": date,
        "docDescription": "",
        "comments": "",
        "reference": "",
        "compDate": null,
        "financialAidYearID": selectedStatusRow?.financialAidYearUID || 0,
        "docNameID": 0,
        "docStatusID": 0,
        "userDefinedFieldID": 0,
        "location": locactionData,
        "internal": false,
        academicYear: selectedStatusRow?.academicYearUID || 0
    };

    const validationSchema = yup.object({
        docNameID:
            yup
                .number()
                .min(1, t('validationMessages.isRequired', { what: t("commonLabel.documentname", { ns: "financialAid" }), }))
                .required(t("validationMessages.isRequired", { what: t("commonLabel.documentname", { ns: "financialAid" }), })),

        docDate: yup
            .date()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.documentDate') }))
            .nullable()
            .max(new Date('12/12/2100')) // date max range 2100
            .min(new Date('12/12/2000'))
            .typeError(''),
        docStatusID: yup
            .number()
            .min(1, t('validationMessages.isRequired', { what: t("commonLabel.status", { ns: "financialAid" }) }))
            .required(t("validationMessages.isRequired", { what: t("commonLabel.status", { ns: "financialAid" }) })),

    });
    const [formValues, setFormValues] = useState('')
    const formik = useFormik({
        initialValues: formValues || initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if ((Object.keys(selectedDoc).length) <= 0) {
                const payload = {
                    "docTrackID": 0,
                    "academicYear": Number(values.academicYear),
                    "documentName": Number(values.docNameID),
                    "financialAidYear": Number(values.financialAidYearID),
                    "documentDate": values.docDate,
                    "status": Number(values.docStatusID),
                    "reference": values.reference,
                    "internal": values.internal,
                    "comment": values.comments,
                    "completionDate": values.compDate,
                    "userDefineFiled": Number(values.userDefinedFieldID),
                    "description": values.docDescription,
                    "studentUID": selectedSelected.studentUID,
                    "locationID": locationId
                }
                postApi(`${add_document}`, payload).then((resp) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                    DefaultTable.resetTable('documentDataFinancial');
                    onClose('')
                }).catch((err) => {
                    console.log(err);
                })
            }
            else {
                const payload = {
                    "docTrackID": selectedDoc.docTrackID,
                    "academicYear": Number(values.academicYear),
                    "documentName": Number(values.docNameID),
                    "financialAidYear": values.financialAidYearID,
                    "documentDate": values.docDate,
                    "status": Number(values.docStatusID),
                    "reference": values.reference,
                    "internal": values.internal,
                    "comment": values.comments,
                    "completionDate": values.compDate,
                    "userDefineFiled": Number(values.userDefinedFieldID),
                    "description": values.docDescription,
                    "studentUID": selectedSelected.studentUID,
                    "locationID": locationId
                }
                putApi(`${update_document}`, payload).then((resp) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    DefaultTable.updateSelectedRow("documentDataFinancial", values)
                    onClose('')
                }).catch((err) => {
                    console.log(err);
                })
            }

        }

    });
    const [Dropdown, setDropdown] = useState({
        statusDrop: [],
        documentNameDrop: [],
        academicDrop: [],
        userDefinedDrop: [],
    })

    const [documentDescription, setDocumentDescription] = useState([])
    useEffect(() => {
        getApi(addDoc_select_items)
            .then((resp) => {
                const {
                    academicYear,
                    documentName,
                    status,
                    userDefineField,
                } = resp.data.data
                setDropdown({
                    ...Dropdown,
                    statusDrop: status,
                    documentNameDrop: documentName,
                    academicDrop: academicYear,
                    userDefinedDrop: userDefineField,

                })
                setLocationData(resp.data.data.location)
                setLocationId(resp.data.data.locationID)
                setDocumentDescription(resp.data.data.documentDescInternal)
            })
            .catch((err) => {
                console.log(err)
            })
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
    }, [])
    useEffect(() => {
        if ((Object.keys(selectedDoc).length) > 0) {
            setFormValues({
                ...formValues,
                academicYear: selectedStatusRow.academicYearUID,
                financialAidYearID: selectedStatusRow.financialAidYearUID,
                "docDate": selectedDoc.docDate,
                "comments": selectedDoc.comments,
                "reference": selectedDoc.reference,
                "compDate": selectedDoc.compDate,
                "docNameID": selectedDoc.docNameID,
                "docStatusID": selectedDoc.docStatusID,
                "userDefinedFieldID": selectedDoc.userDefinedFieldID,
                "internal": selectedDoc.internal,
                "location": selectedDoc.location,
                "docDescription": selectedDoc.docDescription
            })
        }
    }, [])

    const [findropdown, setFinDropdown] = useState({
        finacialDrop: []
    })
    const handleAcademicYear = (e) => {
        formik.handleChange(e)
        const { value } = e.target
        getApi(`${more_tab_acadmecId}/${value}`)
            .then((resp) => {
                setFinDropdown({
                    finacialDrop: resp.data.data,
                })
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        if (selectedStatusRow) {
            const { academicYearUID, financialAidYearUID } = selectedStatusRow
            getApi(`${more_tab_acadmecId}/${Number(academicYearUID)}`)
                .then((resp) => {
                    setFinDropdown({
                        finacialDrop: resp.data.data,
                    })
                    formik.setFieldValue("financialAidYearID", Number(financialAidYearUID))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [selectedStatusRow])
    const docDesc = []
    const handleChange = (e) => {
        formik.handleChange(e)
        docDesc.push((documentDescription).find(o => o.uniqueId === formik.values.docNameID))
        formik.setFieldValue("docDescription", (docDesc[0].documentDesc))
        formik.setFieldValue("internal", (docDesc[0].internal) ? true : false)
    }
    
    return (
        <>
            <PopupHeader
                title=
                {
                    (Object.keys(selectedDoc).length) <= 0
                        ? t("commonLabel.addDocuments", {
                            ns: "financialAid",
                        })
                        : t("commonLabel.updateDocuments", {
                            ns: "financialAid",
                        })
                }
                navigationList={financialAid_Adddocuments}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput isDoubled
                                label={t("commonLabel.academicYear", { ns: "financialAid" })}
                                labelId={"academicYear"}
                                formikValue={formik.values.academicYear}
                                formikChange={handleAcademicYear}
                                disabled={(selectedDoc)}
                                dropdownData={{
                                    key: 'displayText',
                                    value: 'uniqueId',
                                    optionLists: Dropdown.academicDrop,
                                }}
                            />
                            <SelectInput isDoubled
                                label={t("commonLabel.financialAidYear", { ns: "financialAid" })}
                                labelId={"financialAidYearID"}
                                formikValue={formik.values.financialAidYearID}
                                disabled={(selectedDoc)}
                                formikChange={formik.handleChange}
                                dropdownData={{
                                    key: 'displayText',
                                    value: 'uniqueId',
                                    optionLists: findropdown.finacialDrop,
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput disabled
                                label={t("commonLabel.location", { ns: "financialAid" })}
                                labelId="location"
                                formikValue={formik.values.location}
                                formikChange={formik.handleChange}
                                isDoubled
                            />
                        </FormGroup>
                        <FormGroup>
                            <DateInput isDoubled
                                label={t("commonLabel.documentdate", { ns: "financialAid" })}
                                labelId={"docDate"}
                                errors={formik.errors.docDate}
                                touched={formik.touched.docDate}
                                defaultValue={formik.values.docDate}
                                mandatory
                                disabled={accessFlag}
                                formik={formik}
                            />
                            <DateInput isDoubled
                                label={t("commonLabel.completiondate", { ns: "financialAid" })}
                                labelId={"compDate"}
                                defaultValue={formik.values.compDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>

                            <SelectInput isDoubled
                                label={t("commonLabel.documentname", { ns: "financialAid" })}
                                labelId="docNameID"
                                formikValue={formik.values.docNameID}
                                formikChange={handleChange}
                                disabled={accessFlag}
                                errors={formik.errors.docNameID}
                                touched={formik.touched.docNameID}
                                mandatory
                                dropdownData={{
                                    key: 'displayText',
                                    value: 'uniqueId',
                                    optionLists: Dropdown.documentNameDrop,
                                }}
                            />
                            <TextInput isDoubled
                                label={t("commonLabel.description", { ns: "financialAid" })}
                                labelId="docDescription"
                                formikValue={formik.values.docDescription}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput isDoubled
                                label={t("commonLabel.status", { ns: "financialAid" })}
                                labelId="docStatusID"
                                formikValue={formik.values.docStatusID}
                                formikChange={formik.handleChange}
                                errors={formik.errors.docStatusID}
                                touched={formik.touched.docStatusID}
                                disabled={accessFlag}
                                mandatory
                                dropdownData={{
                                    key: 'displayText',
                                    value: 'uniqueId',
                                    optionLists: Dropdown.statusDrop,
                                }}
                            />
                            <SelectInput isDoubled
                                label={t("commonLabel.userDefinedField", { ns: "financialAid" })}
                                labelId="userDefinedFieldID"
                                formikValue={formik.values.userDefinedFieldID}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{
                                    key: 'displayText',
                                    value: 'uniqueId',
                                    optionLists: Dropdown.userDefinedDrop,
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={t("commonLabel.reference", { ns: "financialAid" })}
                                labelId="reference"
                                formikValue={formik.values.reference}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <CheckBoxInput isDoubled
                                label={t("commonLabel.internal", { ns: "financialAid" })}
                                labelId={"internal"}
                                formikValue={formik.values.internal}
                                formikChange={formik.handleChange}
                                checked={formik.values.internal}
                                disabled={accessFlag}
                            />

                        </FormGroup>
                        <FormGroup>
                            <TextAreaInput isDoubled
                                label={t('commonLabel.comment', { ns: 'financialAid' })}
                                labelId={'comments'}
                                formikChange={formik.handleChange}
                                formikValue={formik.values.comments}
                                disabled={accessFlag}
                            />
                        </FormGroup>

                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips flag={((Object.keys(selectedDoc).length) > 0) ? true : false} row={(Object.keys(selectedDoc).length) ? {
                                insertUserID: selectedDoc.insertUserID,
                                insertTime: selectedDoc.insertTime,
                                updateUserID: selectedDoc.updateUserID,
                                updateTime: selectedDoc.updateTime
                            } : ''} />

                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={accessFlag}
                                >
                                    {(Object.keys(selectedDoc).length) <= 0 ? t("buttonLabel.add") : t("buttonLabel.update")}
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onClose("")}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};
export default AddDocuments; 
