import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles,
    ButtonWrapper,
    Splitter,
} from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../components/Forms";
import AddISIRDetails from "./addIsrDetails";
import AddCustomImportField from "./AddCustomReport";
import AddCustomISRRecord from "./addCustomIsrRecord";
import { useHistory } from "react-router-dom";
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import { useDispatch, useSelector } from 'react-redux';
import { deleteApi, getApi, postApi } from "../../../../services/apiBase";
import { isir_financial, isir_financial_custom, isir_financial_grid1, isir_financial_grid_2_3, isir_select_items, isir_set_comment_resolved } from "../../../../services/apiURL";
import GenericModal from "../../../../components/Modal/GenericModal";
import { toastMessage } from "../../../../utils/Message/toasts";
import ShowISIRHistoricalReport from "./ShowISIRHistoricalReport";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";


const IsirHistorical = ({ getSelectedRow, selectedStatusRow, addDatas, fyDropDown, selectedSelected }) => {

    const initialValues = {
        filtertoPackage: selectedStatusRow?.financialStatusID
    };

    const [tableRow, setTableRow] = useState([]);
    const [tableRow2, setTableRow2] = useState([]);
    const [showCustomReport, setShowCustomReport] = useState(false);
    const [showISIRFullReport, setShowISIRFullReport] = useState(false);

    const [tableRow3, setTableRow3] = useState([]);
    const [selectedRow1, setSelectedRow1] = useState('');
    const [selectedRow2, setSelectedRow2] = useState('');
    const [selectedRow3, setSelectedRow3] = useState('');
    const [fullReportSelectedRow, setFullReportSelectedRow] = useState('');
    const [dropdown, setDropdown] = useState({
        "applicationTypeList": [],
        "housingStatusList": [],
        "fieldNamesList": [],
        "pellLifeTimeList": [],
        "dependentStatusList": [],
        "enrollmentStatusList": [],
        "interestedInLoansList": [],
        "finISARRecordsList": [],
        "customImportFieldsList": [],
        "filterToPackageList": []
    });

    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells', `registrationHeadCells`]);
    const [showAddIsirdetails, setShowAddIsirdetails] = useState(false);
    const [showAddCustomIsirdetails, setShowCustomAddIsirdetails] = useState(false);
    const [financialISR] = useState([
        { id: 'financialISARUID', numeric: true, disablePadding: false, label: t('financialISR.financialISARUID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialStatusUID', numeric: true, disablePadding: false, label: t('financialISR.financialStatusUID', { ns: 'financialAidHeadcells' }) },
        { id: 'studentUID', numeric: true, disablePadding: false, label: t('financialISR.studentUID', { ns: 'financialAidHeadcells' }) },
        { id: 'activeFlag', numeric: true, disablePadding: false, label: t('financialISR.activeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'lock', numeric: true, disablePadding: false, label: t('financialISR.lock', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUserID', numeric: true, disablePadding: false, label: t('financialISR.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('financialISR.insertTime', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('financialISR.updateUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('financialISR.updateTime', { ns: 'financialAidHeadcells' }) },
        { id: 'studentSSN', numeric: true, disablePadding: false, label: t('financialISR.studentSSN', { ns: 'financialAidHeadcells' }) },
        { id: 'originalNameID', numeric: true, disablePadding: false, label: t('financialISR.originalNameID', { ns: 'financialAidHeadcells' }) },
        { id: 'transactionID', numeric: true, disablePadding: false, label: t('financialISR.transactionID', { ns: 'financialAidHeadcells' }) },
        { id: 'studentLast', numeric: true, disablePadding: false, label: t('financialISR.studentLast', { ns: 'financialAidHeadcells' }) },
        { id: 'studentFirst', numeric: true, disablePadding: false, label: t('financialISR.studentFirst', { ns: 'financialAidHeadcells' }) },
        { id: 'studentMiddle', numeric: true, disablePadding: false, label: t('financialISR.studentMiddle', { ns: 'financialAidHeadcells' }) },
        { id: 'address', numeric: true, disablePadding: false, label: t('financialISR.address', { ns: 'financialAidHeadcells' }) },
        { id: 'city', numeric: true, disablePadding: false, label: t('financialISR.city', { ns: 'financialAidHeadcells' }) },
        { id: 'state', numeric: true, disablePadding: false, label: t('financialISR.state', { ns: 'financialAidHeadcells' }) },
        { id: 'zipCode', numeric: true, disablePadding: false, label: t('financialISR.zipCode', { ns: 'financialAidHeadcells' }) },
        { id: 'birthDate', numeric: true, disablePadding: false, label: t('financialISR.birthDate', { ns: 'financialAidHeadcells' }) },
        { id: 'phone', numeric: true, disablePadding: false, label: t('financialISR.phone', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsDriversLicenseNumber', numeric: true, disablePadding: false, label: t('financialISR.studentsDriversLicenseNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsDriversLicenseStateCode', numeric: true, disablePadding: false, label: t('financialISR.studentsDriversLicenseStateCode', { ns: 'financialAidHeadcells' }) },
        { id: 'email', numeric: true, disablePadding: false, label: t('financialISR.email', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCitizenshipStatus', numeric: true, disablePadding: false, label: t('financialISR.studentsCitizenshipStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsAlienRegistrationNumber', numeric: true, disablePadding: false, label: t('financialISR.studentsAlienRegistrationNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsMaritalStatus', numeric: true, disablePadding: false, label: t('financialISR.studentsMaritalStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsMaritalStatusDate', numeric: true, disablePadding: false, label: t('financialISR.studentsMaritalStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsStateofLegalResidence', numeric: true, disablePadding: false, label: t('financialISR.studentsStateofLegalResidence', { ns: 'financialAidHeadcells' }) },
        { id: 'studentLegalResidentBeforeIndicatedYear', numeric: true, disablePadding: false, label: t('financialISR.studentLegalResidentbeforeIndicatedYear', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsLegalResidenceDate', numeric: true, disablePadding: false, label: t('financialISR.studentsLegalResidenceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'areYouMaleorFemale', numeric: true, disablePadding: false, label: t('financialISR.areYouMaleorFemale', { ns: 'financialAidHeadcells' }) },
        { id: 'doYouWantSelectiveServicetoRegisterYou', numeric: true, disablePadding: false, label: t('financialISR.doYouWantSelectiveServicetoRegisterYou', { ns: 'financialAidHeadcells' }) },
        { id: 'drugConvictionAffectingEligibility', numeric: true, disablePadding: false, label: t('financialISR.drugConvictionAffectingEligibility', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersHighestGradeLevelCompleted', numeric: true, disablePadding: false, label: t('financialISR.fathersHighestGradeLevelCompleted', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersHighestGradeLevelCompleted', numeric: true, disablePadding: false, label: t('financialISR.mothersHighestGradeLevelCompleted', { ns: 'financialAidHeadcells' }) },
        { id: 'hsDiplomaorEquivalent', numeric: true, disablePadding: false, label: t('financialISR.hsDiplomaorEquivalent', { ns: 'financialAidHeadcells' }) },
        { id: 'previousBachelorsDegree', numeric: true, disablePadding: false, label: t('financialISR.prevBachelors', { ns: 'financialAidHeadcells' }) },
        { id: 'enrolledGradProf', numeric: true, disablePadding: false, label: t('financialISR.enrolledGradProf', { ns: 'financialAidHeadcells' }) },
        { id: 'degreeCertificate', numeric: true, disablePadding: false, label: t('financialISR.degreeCertificate', { ns: 'financialAidHeadcells' }) },
        { id: 'enrollmentStatus', numeric: true, disablePadding: false, label: t('financialISR.enrollmentStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'enrollmentStatusID', numeric: true, disablePadding: false, label: t('financialISR.enrollmentStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'interestInLoansDisplay', numeric: true, disablePadding: false, label: t('financialISR.interestInLoansDisplay', { ns: 'financialAidHeadcells' }) },
        { id: 'interestInLoans', numeric: true, disablePadding: false, label: t('financialISR.interestInLoans', { ns: 'financialAidHeadcells' }) },
        { id: 'teacherCoursework', numeric: true, disablePadding: false, label: t('financialISR.teacherCourseWork', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsTaxReturnCompleted', numeric: true, disablePadding: false, label: t('financialISR.studentsTaxReturnCompleted', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsTypeofTaxFormUsed', numeric: true, disablePadding: false, label: t('financialISR.studentsTypeofTaxFormUsed', { ns: 'financialAidHeadcells' }) },
        { id: 'studentEligibletoFile1040Aor1040EZ', numeric: true, disablePadding: false, label: t('financialISR.studentEligibletoFile1040Aor1040EZ', { ns: 'financialAidHeadcells' }) },
        { id: 'income', numeric: true, disablePadding: false, label: t('financialISR.income', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsUSIncomeTaxPaid', numeric: true, disablePadding: false, label: t('financialISR.studentsUSIncomeTaxPaid', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsExemptionsClaimed', numeric: true, disablePadding: false, label: t('financialISR.studentsExemptionsClaimed', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.studentsIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'spousesIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.spousesIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCashSavingsandChecking', numeric: true, disablePadding: false, label: t('financialISR.studentsCashSavingsAndChecking', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsInvestmentNetWorth', numeric: true, disablePadding: false, label: t('financialISR.studentsInvestmentNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsBusinessInvestmentFarmNetWorth', numeric: true, disablePadding: false, label: t('financialISR.studentsBusinessInvestmentFarmNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsEducationalCredits', numeric: true, disablePadding: false, label: t('financialISR.studentsEducationalCredits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsChildSupportPaid', numeric: true, disablePadding: false, label: t('financialISR.studentsChildSupportPaid', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsNeedBasedEmployment', numeric: true, disablePadding: false, label: t('financialISR.studentsNeedBasedEmployment', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsGrantScholarshipAid', numeric: true, disablePadding: false, label: t('financialISR.studentsGrantScholarshipAid', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCombatPay', numeric: true, disablePadding: false, label: t('financialISR.studentsCombatPay', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCoopEarnings', numeric: true, disablePadding: false, label: t('financialISR.studentsCoopEarnings', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsPensionPayments', numeric: true, disablePadding: false, label: t('financialISR.studentsPensionPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsIRAPayments', numeric: true, disablePadding: false, label: t('financialISR.studentsIRAPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsChildSupportReceived', numeric: true, disablePadding: false, label: t('financialISR.studentsChildSupportReceived', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsInterestIncome', numeric: true, disablePadding: false, label: t('financialISR.studentsInterestIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsIRADistributions', numeric: true, disablePadding: false, label: t('financialISR.studentsIRADistributions', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsUntaxedPensions', numeric: true, disablePadding: false, label: t('financialISR.studentsUntaxedPensions', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsMilitaryClergyAllowances', numeric: true, disablePadding: false, label: t('financialISR.studentsMilitaryClergyAllowances', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsVeteransNoneducationBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentsVeteransNonEducationBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsOtherUntaxedIncome', numeric: true, disablePadding: false, label: t('financialISR.studentsOtherUntaxedIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsOtherNonReportedMoney', numeric: true, disablePadding: false, label: t('financialISR.studentsOtherNonReportedMoney', { ns: 'financialAidHeadcells' }) },
        { id: 'bornBeforeIndicatedYear', numeric: true, disablePadding: false, label: t('financialISR.bornBeforeIndicatedYear', { ns: 'financialAidHeadcells' }) },
        { id: 'isStudentMarried', numeric: true, disablePadding: false, label: t('financialISR.isStudentMarried', { ns: 'financialAidHeadcells' }) },
        { id: 'workingonaMastersorDoctorateProgram', numeric: true, disablePadding: false, label: t('financialISR.workingonaMastersorDoctorateProgram', { ns: 'financialAidHeadcells' }) },
        { id: 'areYouonActiveDutyinUSArmedForces', numeric: true, disablePadding: false, label: t('financialISR.areYouonActiveDutyinUSAArmedForces', { ns: 'financialAidHeadcells' }) },
        { id: 'veteranofUSArmedForces', numeric: true, disablePadding: false, label: t('financialISR.veteranofUSAArmedForces', { ns: 'financialAidHeadcells' }) },
        { id: 'haveChildrenYouSupport', numeric: true, disablePadding: false, label: t('financialISR.haveChildrenYouSupport', { ns: 'financialAidHeadcells' }) },
        { id: 'haveLegalDependentsOtherThanChildrenorSpouse', numeric: true, disablePadding: false, label: t('financialISR.haveLegalDependentsOtherThanChildrenorSpouse', { ns: 'financialAidHeadcells' }) },
        { id: 'orphanWardofCourtorFosterCare', numeric: true, disablePadding: false, label: t('financialISR.orphanWardofCourtorFosterCare', { ns: 'financialAidHeadcells' }) },
        { id: 'emancipatedMinor', numeric: true, disablePadding: false, label: t('financialISR.emancipatedMinor', { ns: 'financialAidHeadcells' }) },
        { id: 'inLegalGuardianShip', numeric: true, disablePadding: false, label: t('financialISR.inLegalGuardianShip', { ns: 'financialAidHeadcells' }) },
        { id: 'unaccompaniedYouthDetByShoolDistrictLiaison', numeric: true, disablePadding: false, label: t('financialISR.unaccompaniedYouthDetBySchoolDistrictLiaison', { ns: 'financialAidHeadcells' }) },
        { id: 'unaccompaniedYouthDetByHUD', numeric: true, disablePadding: false, label: t('financialISR.unaccompaniedYouthDetByHUD', { ns: 'financialAidHeadcells' }) },
        { id: 'atRiskofHomelessness', numeric: true, disablePadding: false, label: t('financialISR.atRiskofHomelessness', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsMaritalStatus', numeric: true, disablePadding: false, label: t('financialISR.parentsMaritalStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsMaritalStatusDate', numeric: true, disablePadding: false, label: t('financialISR.parentsMaritalStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersSocialSecurityNumber', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersSocialSecurityNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersLastName', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersLastName', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersFirstNameInitial', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersFirstNameInitial', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersDateofBirth', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersDateofBirth', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersSocialSecurityNumber', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersSocialSecurityNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersLastName', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersLastName', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersFirstNameInitial', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersFirstNameInitial', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersDateofBirth', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersDateOfBirth', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsEmailAddress', numeric: true, disablePadding: false, label: t('financialISR.parentsEmailAddress', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsStateOfLegalResidence', numeric: true, disablePadding: false, label: t('financialISR.parentsStateOfLegalResidence', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsLegalResidentsBeforeIndicatedYear', numeric: true, disablePadding: false, label: t('financialISR.parentsLegalResidentsBeforeIndicatedYear', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsLegalResidenceDate', numeric: true, disablePadding: false, label: t('financialISR.parentsLegalResidenceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'parentFamilyMembers', numeric: true, disablePadding: false, label: t('financialISR.parentFamilyMembers', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsNumberinCollege', numeric: true, disablePadding: false, label: t('financialISR.parentsNumberinCollege', { ns: 'financialAidHeadcells' }) },
        { id: 'parentSupSecurityIncome', numeric: true, disablePadding: false, label: t('financialISR.parentSupplementalSecurityIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentFoodStampBenifits', numeric: true, disablePadding: false, label: t('financialISR.parentFoodStampBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'parentFreeRedLunch', numeric: true, disablePadding: false, label: t('financialISR.parentFreeReducedLunch', { ns: 'financialAidHeadcells' }) },
        { id: 'parentTANF', numeric: true, disablePadding: false, label: t('financialISR.parentTANF', { ns: 'financialAidHeadcells' }) },
        { id: 'parentWIC', numeric: true, disablePadding: false, label: t('financialISR.parentWIC', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsTaxReturnCompleted', numeric: true, disablePadding: false, label: t('financialISR.parentsTaxReturnCompleted', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsTypeofTaxFormUsed', numeric: true, disablePadding: false, label: t('financialISR.parentsTypeofTaxFormUsed', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsEligibletoFile1040Aor1040EZ', numeric: true, disablePadding: false, label: t('financialISR.parentsEligibletoFile1040Aor1040EZ', { ns: 'financialAidHeadcells' }) },
        { id: 'parentDislocatedWorker', numeric: true, disablePadding: false, label: t('financialISR.parentDislocatedWorker', { ns: 'financialAidHeadcells' }) },
        { id: 'parentGrossIncome', numeric: true, disablePadding: false, label: t('financialISR.parentGrossIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsUSIncomeTaxpaid', numeric: true, disablePadding: false, label: t('financialISR.parentsUSIncomeTaxpaid', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsExemptionsClaimed', numeric: true, disablePadding: false, label: t('financialISR.parentsExemptionsClaimed', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsCashSavingsandChecking', numeric: true, disablePadding: false, label: t('financialISR.parentsCashSavingsandChecking', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsInvestmentNetWorth', numeric: true, disablePadding: false, label: t('financialISR.parentsInvestmentNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsBusinessandorInvestmentFarmNetWorth', numeric: true, disablePadding: false, label: t('financialISR.parentsBusinessandorInvestmentFarmNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsEducationalCredits', numeric: true, disablePadding: false, label: t('financialISR.parentsEducationalCredits', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsChildSupportPaid', numeric: true, disablePadding: false, label: t('financialISR.parentsChildSupportPaid', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsNeedBasedEmployment', numeric: true, disablePadding: false, label: t('financialISR.parentsNeedBasedEmployment', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsGrantScholarshipAid', numeric: true, disablePadding: false, label: t('financialISR.parentsGrantScholarshipAid', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsCombatPay', numeric: true, disablePadding: false, label: t('financialISR.parentsCombatPay', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsCoopPay', numeric: true, disablePadding: false, label: t('financialISR.parentsCoopPay', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsPensionPayments', numeric: true, disablePadding: false, label: t('financialISR.parentsPensionPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsIRAPayments', numeric: true, disablePadding: false, label: t('financialISR.parentsIRAPPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsChildSupportReceived', numeric: true, disablePadding: false, label: t('financialISR.parentsChildSupportReceived', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsInterestIncome', numeric: true, disablePadding: false, label: t('financialISR.parentsInterestIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsIRADistributions', numeric: true, disablePadding: false, label: t('financialISR.parentsIRADDistributions', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsUntaxedPensions', numeric: true, disablePadding: false, label: t('financialISR.parentsUntaxedPensions', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsMilitaryClergyAllowances', numeric: true, disablePadding: false, label: t('financialISR.parentsMilitaryClergyAllowances', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsVeteransNoneducationalBenefits', numeric: true, disablePadding: false, label: t('financialISR.parentsVeteransNoneducationalBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsOtherUntaxedIncome', numeric: true, disablePadding: false, label: t('financialISR.parentsOtherUntaxedIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'numFamilyMembers', numeric: true, disablePadding: false, label: t('financialISR.numberOfFamilyMembers', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsNumberinCollege', numeric: true, disablePadding: false, label: t('financialISR.studentsNumberinCollege', { ns: 'financialAidHeadcells' }) },
        { id: 'studentSupplementalSecurityIncomeBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentSupplementalSecurityIncomeBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentFoodStampBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentFoodStampBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentFreeorReducedPriceSchoolLunchBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentFreeorReducedPriceSchoolLunchBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentTANFBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentTANFBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentWICBenefits', numeric: true, disablePadding: false, label: t('financialISR.studentWICBenefits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentSpouseDislocatedWorker', numeric: true, disablePadding: false, label: t('financialISR.studentSpouseDislocatedWorker', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode1', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode1HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode1HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode2', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode2HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode2HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode3', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode3HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode3HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode4', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode4', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode4HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode4HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode5', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode5', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode5HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode5HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode6', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode6', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode6HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode6HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode7', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode7', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode7HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode7HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode8', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode8', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode8HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode8HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode9', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode9', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode9HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode9HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode10', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode10', { ns: 'financialAidHeadcells' }) },
        { id: 'federalSchoolCode10HousingPlans', numeric: true, disablePadding: false, label: t('financialISR.federalSchoolCode10HousingPlans', { ns: 'financialAidHeadcells' }) },
        { id: 'housingStatus', numeric: true, disablePadding: false, label: t('financialISR.housingStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'housingStatusID', numeric: true, disablePadding: false, label: t('financialISR.housingStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'dateApplicationCompleted', numeric: true, disablePadding: false, label: t('financialISR.dateApplicationCompleted', { ns: 'financialAidHeadcells' }) },
        { id: 'signedBy', numeric: true, disablePadding: false, label: t('financialISR.signedBy', { ns: 'financialAidHeadcells' }) },
        { id: 'preparersSocialSecurityNumber', numeric: true, disablePadding: false, label: t('financialISR.preparersSocialSecurityNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'preparersEmployerIdentificationNumber', numeric: true, disablePadding: false, label: t('financialISR.preparersEmployerIdentificationNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'preparersSignature', numeric: true, disablePadding: false, label: t('financialISR.preparersSignature', { ns: 'financialAidHeadcells' }) },
        { id: 'dependencyOverrideIndicator', numeric: true, disablePadding: false, label: t('financialISR.dependencyOverrideIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'faaFederalSchoolCode', numeric: true, disablePadding: false, label: t('financialISR.faaFederalSchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'dependentStatus', numeric: true, disablePadding: false, label: t('financialISR.dependentStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'transactionDataSourceTypeCode', numeric: true, disablePadding: false, label: t('financialISR.transactionDataSourceTypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'transactionReceiptDate', numeric: true, disablePadding: false, label: t('financialISR.transactionReceiptDate', { ns: 'financialAidHeadcells' }) },
        { id: 'specialCircumstancesFlag', numeric: true, disablePadding: false, label: t('financialISR.specialCircumstancesFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSRequestFlag', numeric: true, disablePadding: false, label: t('financialISR.studentIRSRequestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSRequestFlag', numeric: true, disablePadding: false, label: t('financialISR.parentIRSRequestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'irsAdjustedGrossIncome', numeric: true, disablePadding: false, label: t('financialISR.IRSAdjustedGrossIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'irsStudentFederalIncomeTax', numeric: true, disablePadding: false, label: t('financialISR.IRSStudentFederalIncomeTax', { ns: 'financialAidHeadcells' }) },
        { id: 'irsParentAGI', numeric: true, disablePadding: false, label: t('financialISR.IRSParentAGI', { ns: 'financialAidHeadcells' }) },
        { id: 'irsParentFIT', numeric: true, disablePadding: false, label: t('financialISR.IRSParentFIT', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride1', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride1', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride2', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride2', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride3', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride3', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride4', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride4', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride5', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride5', { ns: 'financialAidHeadcells' }) },
        { id: 'assumptionOverride6', numeric: true, disablePadding: false, label: t('financialISR.assumptionOverride6', { ns: 'financialAidHeadcells' }) },
        { id: 'electronicTransactionIndicatorDestinationNumber', numeric: true, disablePadding: false, label: t('financialISR.electronicTransactionIndicatorDestinationNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverride3', numeric: true, disablePadding: false, label: t('financialISR.rejectOverride3', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverride12', numeric: true, disablePadding: false, label: t('financialISR.rejectOverride12', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverride20', numeric: true, disablePadding: false, label: t('financialISR.rejectOverride20', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideA', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideA', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideB', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideB', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideC', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideC', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideG', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideG', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideJ', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideJ', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideK', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideK', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideN', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideN', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverrideW', numeric: true, disablePadding: false, label: t('financialISR.rejectOverrideW', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCurrentSocialSecurityNumber', numeric: true, disablePadding: false, label: t('financialISR.studentsCurrentSocialSecurityNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'corrrectionAppliedAgainstTransactionNumber', numeric: true, disablePadding: false, label: t('financialISR.correctionAppliedAgainstTransactionNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'professionalJudgment', numeric: true, disablePadding: false, label: t('financialISR.professionalJudgement', { ns: 'financialAidHeadcells' }) },
        { id: 'applicationDataSourceTypeCode', numeric: true, disablePadding: false, label: t('financialISR.applicationDataSourceTypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'applicationRecvDT', numeric: true, disablePadding: false, label: t('financialISR.applicationReceivedDate', { ns: 'financialAidHeadcells' }) },
        { id: 'addressOnlyChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.addressOnlyChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'cpsPushedISIRFlag', numeric: true, disablePadding: false, label: t('financialISR.cpsPushedISIRFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'efcChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.efcChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'studentLastNameSSNChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.studentLastNameSSNChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectStatusChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.rejectStatusChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'sarcChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.sarcChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'verificationSelectionChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.verificationSelectionChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'computeNumber', numeric: true, disablePadding: false, label: t('financialISR.computeNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'sourceofCorrection', numeric: true, disablePadding: false, label: t('financialISR.sourceOfCorrection', { ns: 'financialAidHeadcells' }) },
        { id: 'duplicateSSNIndicator', numeric: true, disablePadding: false, label: t('financialISR.duplicateSSNIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'graduateFlag', numeric: true, disablePadding: false, label: t('financialISR.graduateFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'pellEligibility', numeric: true, disablePadding: false, label: t('financialISR.pellEligibility', { ns: 'financialAidHeadcells' }) },
        { id: 'transactionProcessedDate', numeric: true, disablePadding: false, label: t('financialISR.transactionProcessedDate', { ns: 'financialAidHeadcells' }) },
        { id: 'applicationType', numeric: true, disablePadding: false, label: t('financialISR.applicationType', { ns: 'financialAidHeadcells' }) },
        { id: 'applicationTypeUID', numeric: true, disablePadding: false, label: t('financialISR.applicationTypeUID', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectReasonCodes', numeric: true, disablePadding: false, label: t('financialISR.rejectReasonCodes', { ns: 'financialAidHeadcells' }) },
        { id: 'reprocessedReasonCode', numeric: true, disablePadding: false, label: t('financialISR.reprocessedReasonCodes', { ns: 'financialAidHeadcells' }) },
        { id: 'sarcFlag', numeric: true, disablePadding: false, label: t('financialISR.sarcflag', { ns: 'financialAidHeadcells' }) },
        { id: 'automaticZeroEFC', numeric: true, disablePadding: false, label: t('financialISR.automaticZeroEFC', { ns: 'financialAidHeadcells' }) },
        { id: 'simplifiedNeedsTest', numeric: true, disablePadding: false, label: t('financialISR.simplifiedNeedsTest', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsCalculatedTaxStatus', numeric: true, disablePadding: false, label: t('financialISR.parentsCalculatedTaxStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsCalculatedTaxStatus', numeric: true, disablePadding: false, label: t('financialISR.studentsCalculatedTaxStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsAdditionalFinancialInformationTotalCalculatedbyCPS', numeric: true, disablePadding: false, label: t('financialISR.studentsAdditionalFinancialInformationtTotalCalculatedByCPS', { ns: 'financialAidHeadcells' }) },
        { id: 'taxedNonTaxedIncome', numeric: true, disablePadding: false, label: t('financialISR.taxedNonTaxedIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsAdditionalFinancialInformationTotalCaclulatedbyCPS', numeric: true, disablePadding: false, label: t('financialISR.parentsAdditionalFinancialInformationTotalCalculatedByCPS', { ns: 'financialAidHeadcells' }) },
        { id: 'parentTaxNonTaxedIncome', numeric: true, disablePadding: false, label: t('financialISR.parentTaxNonTaxedIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedCitizenship', numeric: true, disablePadding: false, label: t('financialISR.assumedCitizenship', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedstudentsMaritalStatus', numeric: true, disablePadding: false, label: t('financialISR.assumedstudentsMaritalStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsAGI', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsAGI', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsUSTaxPaid', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsUSTaxPaid', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsIncomeFromWork', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsIncomeFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedSpousesIncomeFromWork', numeric: true, disablePadding: false, label: t('financialISR.assumedSpousesIncomeFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsAdditionalFinancialInformationTotal', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsAdditionalFinancialInformationTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'birthPrior', numeric: true, disablePadding: false, label: t('financialISR.birthPrior', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentIsMarriedRemarried', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentIsMarriedRemarried', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedHaveChildrenYouSupport', numeric: true, disablePadding: false, label: t('financialISR.assumedHaveChildrenYouSupport', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedHaveLegalDependentsOtherThanChildrenorSpouse', numeric: true, disablePadding: false, label: t('financialISR.assumedHaveLegalDependentsOtherThanChildrenOrSpouse', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsNumberinFamily', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsNumberInFamily', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentsNumberinCollege', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentsNumberInCollege', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedHomelessYouthSchoolDetermined', numeric: true, disablePadding: false, label: t('financialISR.assumedHomelessYouthSchoolDetermined', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedHomelessYouthHUDProgramDetermined', numeric: true, disablePadding: false, label: t('financialISR.assumedHomelessYouthHUDProgramDetermined', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedAtRiskofHomelessness', numeric: true, disablePadding: false, label: t('financialISR.assumedAtRiskOfHomelessness', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsMaritalStatus', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsMaritalStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedFathersStepFathersSSN', numeric: true, disablePadding: false, label: t('financialISR.assumedFathersStepFathersSSN', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedMothersStepMothersSSN', numeric: true, disablePadding: false, label: t('financialISR.assumedMothersStepMothersSSN', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsNumberinFamily', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsNumberInFamily', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsNumberinCollege', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsNumberInCollege', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsAGI', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsAGI', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsUSTaxPaid', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsUSTaxPaid', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedFathersStepFathersIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.assumedFathersStepFathersIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedMothersStepMothersIncomeEarnedFromWork', numeric: true, disablePadding: false, label: t('financialISR.assumedMothersStepMothersIncomeEarnedFromWork', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsAdditionalFinancialInformationTotal', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsAdditionalFinancialInformationTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'primaryEFC', numeric: true, disablePadding: false, label: t('financialISR.primaryEFC', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth9', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth9', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryEFC', numeric: true, disablePadding: false, label: t('financialISR.secondaryEFC', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth9', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth9', { ns: 'financialAidHeadcells' }) },
        { id: 'signatureRejectEFC', numeric: true, disablePadding: false, label: t('financialISR.signatureRejectEFC', { ns: 'financialAidHeadcells' }) },
        { id: 'primaryEFCType', numeric: true, disablePadding: false, label: t('financialISR.primaryEFCType', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryEFCType', numeric: true, disablePadding: false, label: t('financialISR.secondaryEFCType', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth1', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth1', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth2', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth2', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth3', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth3', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth4', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth4', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth5', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth5', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth6', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth6', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth7', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth7', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth8', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth8', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth10', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth10', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth11', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth11', { ns: 'financialAidHeadcells' }) },
        { id: 'altEFCMonth12', numeric: true, disablePadding: false, label: t('financialISR.altEFCMonth12', { ns: 'financialAidHeadcells' }) },
        { id: 'lenInMonths', numeric: true, disablePadding: false, label: t('financialISR.lengthInMonths', { ns: 'financialAidHeadcells' }) },
        { id: 'altPrimaryEFC', numeric: true, disablePadding: false, label: t('financialISR.altPrimaryEFC', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth1', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth1', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth2', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth2', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth3', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth3', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth4', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth4', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth5', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth5', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth6', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth6', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth7', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth7', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth8', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth8', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth10', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth10', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth11', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth11', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAlternateMonth12', numeric: true, disablePadding: false, label: t('financialISR.secondaryAlternateMonth12', { ns: 'financialAidHeadcells' }) },
        { id: 'totalIncome', numeric: true, disablePadding: false, label: t('financialISR.totalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'allowanceAgainstTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.allowanceAgainstTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'stateandOtherTaxAllowance', numeric: true, disablePadding: false, label: t('financialISR.stateAndOtherTaxAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'employmentAllowance', numeric: true, disablePadding: false, label: t('financialISR.employmentAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'incomeProtectAllowance', numeric: true, disablePadding: false, label: t('financialISR.incomeProtectAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'availableIncome', numeric: true, disablePadding: false, label: t('financialISR.availableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'contributionFromAvailableIncome', numeric: true, disablePadding: false, label: t('financialISR.contributionFromAvailableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'descretionaryNetWorth', numeric: true, disablePadding: false, label: t('financialISR.descretionaryNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'netWorth', numeric: true, disablePadding: false, label: t('financialISR.netWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'assetProtectAllowance', numeric: true, disablePadding: false, label: t('financialISR.assetProtectAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsContributionFromAssets', numeric: true, disablePadding: false, label: t('financialISR.parentsContributionFromAssets', { ns: 'financialAidHeadcells' }) },
        { id: 'adjustedAvailableIncome', numeric: true, disablePadding: false, label: t('financialISR.adjustedAvailableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIncomeContribution', numeric: true, disablePadding: false, label: t('financialISR.studentIncomeContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'totalStudentContribution', numeric: true, disablePadding: false, label: t('financialISR.totalStudentContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'totalParentContribution', numeric: true, disablePadding: false, label: t('financialISR.totalParentContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'parentsContribution', numeric: true, disablePadding: false, label: t('financialISR.parentsContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.studentsTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsAllowanceAgainstTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.studentsAllowanceAgainstTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentContribution', numeric: true, disablePadding: false, label: t('financialISR.parentContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'studentsDiscretionaryNetWorth', numeric: true, disablePadding: false, label: t('financialISR.studentsDiscretionaryNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'studentAssetContribution', numeric: true, disablePadding: false, label: t('financialISR.studentAssetContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'totalFisapIncome', numeric: true, disablePadding: false, label: t('financialISR.totalFisapIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAllowancesAgainstTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryAllowancesAgainstTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'stateTaxAllowance', numeric: true, disablePadding: false, label: t('financialISR.stateTaxAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'employAllowance', numeric: true, disablePadding: false, label: t('financialISR.employAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryIncomeProtectionAllowance', numeric: true, disablePadding: false, label: t('financialISR.secondaryIncomeProtectionAllowance', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAvailableIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryAvailableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryContributionFromAvailableIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryContributionFromAvailableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryDiscretionaryNetWorth', numeric: true, disablePadding: false, label: t('financialISR.secondaryDiscretionaryNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryNetWorth', numeric: true, disablePadding: false, label: t('financialISR.secondaryNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAssetProtectionAllowances', numeric: true, disablePadding: false, label: t('financialISR.secondaryAssetProtectionAllowances', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryParentsContributionFromAssets', numeric: true, disablePadding: false, label: t('financialISR.secondaryParentsContributionFromAssets', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryAdjustedAvailableIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryAdjustedAvailableIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryTotalStudentsContribution', numeric: true, disablePadding: false, label: t('financialISR.secondaryTotalStudentsContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryTotalParentsContribution', numeric: true, disablePadding: false, label: t('financialISR.secondaryTotalParentsContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryParentsContribution', numeric: true, disablePadding: false, label: t('financialISR.secondaryParentsContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryStudentsTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryStudentstotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryStudentsAllowanceAgainstTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryStudentsAllowanceAgainstTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryStudentsDependentIncomeContribution', numeric: true, disablePadding: false, label: t('financialISR.secondaryStudentsDependentIncomeContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryStudentsDiscretionaryNetWorth', numeric: true, disablePadding: false, label: t('financialISR.secondaryStudentsDiscretionaryNetWorth', { ns: 'financialAidHeadcells' }) },
        { id: 'contributionFromAssets', numeric: true, disablePadding: false, label: t('financialISR.contributionFromAssets', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryFisapTotalIncome', numeric: true, disablePadding: false, label: t('financialISR.secondaryFisapTotalIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'correctionFlags', numeric: true, disablePadding: false, label: t('financialISR.correctionFlags', { ns: 'financialAidHeadcells' }) },
        { id: 'highlightFlags', numeric: true, disablePadding: false, label: t('financialISR.highlightFlags', { ns: 'financialAidHeadcells' }) },
        { id: 'fafsaDataVerifyFlags', numeric: true, disablePadding: false, label: t('financialISR.fafsaDataVerifyFlags', { ns: 'financialAidHeadcells' }) },
        { id: 'dhsMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.dhsMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'secondaryDHSMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.secondaryDHSMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'dhsVerificationNumber', numeric: true, disablePadding: false, label: t('financialISR.dhsVerificationNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPostScreeningReasonCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsPostScreeningReasonCode', { ns: 'financialAidHeadcells' }) },
        { id: 'fathersStepFathersSSNMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.fathersStepFathersSSNMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'mothersStepMothersSSNMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.mothersStepMothersSSNMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'selectiveServiceMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.selectiveServiceMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'selectiveServiceRegistrationFlag', numeric: true, disablePadding: false, label: t('financialISR.selectiveServiceRegistrationFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'ssaCitizenshipFlag', numeric: true, disablePadding: false, label: t('financialISR.ssaCitizenshipFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'ssnMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.ssnMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'vaMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.vaMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'departmentofDefenseMatchFlag', numeric: true, disablePadding: false, label: t('financialISR.departmentOfDefenseMatchFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'departmentofDefenseParentDateofDeath', numeric: true, disablePadding: false, label: t('financialISR.departmentOfDefenseParentDateOfDeath', { ns: 'financialAidHeadcells' }) },
        { id: 'commentCodes', numeric: true, disablePadding: false, label: t('financialISR.commentCodes', { ns: 'financialAidHeadcells' }) },
        { id: 'electronicFederalSchoolCodeIndicator', numeric: true, disablePadding: false, label: t('financialISR.electronicFederalSchoolCodeIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'electronicTransactionIndicatorFlag', numeric: true, disablePadding: false, label: t('financialISR.electronicTransactionIndicatorFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'multiSchoolCodeFlags', numeric: true, disablePadding: false, label: t('financialISR.multiSchoolCodeFlags', { ns: 'financialAidHeadcells' }) },
        { id: 'verificationTrackingFlag', numeric: true, disablePadding: false, label: t('financialISR.verificationTrackingFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'verificationCode', numeric: true, disablePadding: false, label: t('financialISR.verifyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsTransactionNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsTransactionNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDatabaseResultsFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDatabaseResultsFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsseogOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsSEOGOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsseogOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsSEOGOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPerkinsOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsPerkinsOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPerkinsOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsPerkinsOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantOverPaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantOverPaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantOverPaymentContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantOverPaymentContact', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDefaultedLoanFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDefaultedLoanFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDischargedLoanFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDischargedLoanFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsFraudLoanFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsFraudLoanFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoanSatisfactoryRepaymentFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoanSatisfactoryRepaymentFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsActiveBankruptcyFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsActiveBankruptcyFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantLoanConversionFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantLoanConversionFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateSubsidizedOutstandingPrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateSubsidizedOutstandingPrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateUnSubsidizedOutstandingPrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateUnSubsidizedOutstandingPrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateCombinedOutstandingPrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateCombinedOutstandingPrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateUnallocatedConsolidatedOutstandingPrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateUnallocatedConsolidatedOutstandingPrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateTEACHLoanPrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateTEACHLoanPrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateSubsidizedPendingDisbursement', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateSubsidizedPendingDisbursement', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateUnSubsidizedPendingDisbursement', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateUnSubsidizedPendingDisbursement', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateCombinedPendingDisbursement', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateCombinedPendingDisbursement', { ns: 'financialAidHeadcells' }) },
        { id: 'dlSubsidizedTotal', numeric: true, disablePadding: false, label: t('financialISR.dlSubsidizedTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'dlUnSubsidizedTotal', numeric: true, disablePadding: false, label: t('financialISR.dlUnSubsidizedTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateCombinedTotal', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateCombinedTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsUnallocatedConsolidatedTotal', numeric: true, disablePadding: false, label: t('financialISR.nsldsUnallocatedConsolidatedTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachLoanTotal', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHLoanTotal', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPerkinsCumulativeDisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsPerkinsCumulativeDisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPerkinsCurrentYearDisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsPerkinsCurrentYearDisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateTEACHUndergraduatePostBaccalaureateDisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateTEACHUndergraduatePostBaccalaureateDisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateTEACHGraduateDisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateTEACHGraduateDisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDefaultedLoanChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDefaultedLoanChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsFraudLoanChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsFraudLoanChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDischargedLoanChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDischargedLoanChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoanSatisfactoryRepaymentChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoanSatisfactoryRepaymentChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsActiveBankruptcyChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsActiveBankruptcyChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGranttoLoanConversionChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGranttoLoanConversionChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsOverPaymentsChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsOverPaymentsChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAggregateLoanChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAggregateLoanChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPerkinsLoanChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsPerkinsLoanChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellPaymentChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellPaymentChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgPaymentChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGPaymentChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalPellFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalPellFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalLoansFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalLoansFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalACGFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalACGFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalNationalSMARTGrantFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalNationalSMARTGrantFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalTEACHGrantFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalTEACHGrantFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDirectLoanMasterPromNoteFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDirectLoanMasterPromNoteFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDirectLoanPLUSMasterPromNoteFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDirectLoanPLUSMasterPromNoteFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsDirectLoanGraduatePLUSMasterPromNoteFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsDirectLoanGraduatePLUSMasterPromNoteFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsUnderGraduateSubsidizedLoanLimitFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsUnderGraduateSubsidizedLoanLimitFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsUnderGraduateCombinedLoanLimitFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsUnderGraduateCombinedLoanLimitFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsGraduateSubsidizedLoanLimitFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsGraduateSubsidizedLoanLimitFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsGraduateCombinedLoanLimitFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsGraduateCombinedLoanLimitFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellLifetimeLimit', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellLifetimeLimit', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellLifetimeEligibilityUsed', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellLifetimeEligibilityUsed', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsUnusualEnrollmentHistory', numeric: true, disablePadding: false, label: t('financialISR.nsldsUnusualEnrollmentHistory', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSequenceNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSequenceNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellVerificationFlag1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellVerificationFlag1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsefC1', numeric: true, disablePadding: false, label: t('financialISR.nsldsEFC1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSchoolCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSchoolCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellTransactionNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellTransactionNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellLastUpdateDate1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellLastUpdateDate1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellScheduledAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellScheduledAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'pellPaid01', numeric: true, disablePadding: false, label: t('financialISR.pellPaid01', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellPercentScheduledAwardUsedbyAwardYear1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellPercentScheduledAwardUsedbyAwardYear1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalEligibilityIndicator1', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalEligibilityIndicator1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSequenceNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSequenceNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellVerificationFlag2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellVerificationFlag2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsefC2', numeric: true, disablePadding: false, label: t('financialISR.nsldsEFC2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSchoolCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSchoolCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellTransactionNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellTransactionNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellLastUpdateDate2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellLastUpdateDate2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellScheduledAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellScheduledAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'pellPaid02', numeric: true, disablePadding: false, label: t('financialISR.pellPaid02', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellPercentScheduledAwardUsedbyAwardYear2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellPercentScheduledAwardUsedbyAwardYear2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalEligibilityIndicator2', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalEligibilityIndicator2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSequenceNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSequenceNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellVerificationFlag3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellVerificationFlag3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsefC3', numeric: true, disablePadding: false, label: t('financialISR.nsldsEFC3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellSchoolCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellSchoolCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellTransactionNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellTransactionNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellLastUpdateDate3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellLastUpdateDate3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellScheduledAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellScheduledAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'pellPaid03', numeric: true, disablePadding: false, label: t('financialISR.pellPaid03', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellPercentScheduledAwardUsedbyAwardYear3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellPercentScheduledAwardUsedbyAwardYear3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsPellAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsPellAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsAdditionalEligibilityIndicator3', numeric: true, disablePadding: false, label: t('financialISR.nsldsAdditionalEligibilityIndicator3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSequenceNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSequenceNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSchoolCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSchoolCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTransactionNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTransactionNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgLastUpdateDate1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGLastUpdateDate1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgScheduledAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGScheduledAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'acgPaid01', numeric: true, disablePadding: false, label: t('financialISR.ACGPaid01', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgPercentEligibilityUsedbyScheduledAward1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGPercentEligibilityUsedbyScheduledAward1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAcademicYearLevel1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAcademicYearLevel1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgEligibilityCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGEligibilityCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgHighSchoolProgramCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGHighSchoolProgramCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgcodSequenceCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGCODSequenceCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardYear1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardYear1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTotalPercentEligibilityUsedbyAcademicYearLevel1', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTotalPercentEligibilityUsedbyAcademicYearLevel1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSequenceNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSequenceNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSchoolCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSchoolCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTransactionNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTransactionNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgLastUpdateDate2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGLastUpdateDate2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgScheduledAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGScheduledAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'acgPaid02', numeric: true, disablePadding: false, label: t('financialISR.ACGPaid02', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgPercentEligibilityUsedbyScheduledAward2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGPercentEligibilityUsedbyScheduledAward2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAcademicYearLevel2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAcademicYearLevel2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgEligibilityCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGEligibilityCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgHighSchoolProgramCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGHighSchoolProgramCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgCODSequenceCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGCODSequenceCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardYear2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardYear2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTotalPercentEligibilityUsedbyAcademicYearLevel2', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTotalPercentEligibilityUsedbyAcademicYearLevel2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSequenceNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSequenceNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgSchoolCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGSchoolCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTransactionNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTransactionNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgLastUpdateDate3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGLastUpdateDate3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgScheduledAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGScheduledAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'acgPaid03', numeric: true, disablePadding: false, label: t('financialISR.ACGPaid03', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsACGPercentEligibilityUsedbyScheduledAward3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGPercentEligibilityUsedbyScheduledAward3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAcademicYearLevel3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAcademicYearLevel3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgEligibilityCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGEligibilityCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgHighSchoolProgramCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGHighSchoolProgramCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgcodSequenceCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGCODSequenceCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgAwardYear3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGAwardYear3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsacgTotalPercentEligibilityUsedbyAcademicYearLevel3', numeric: true, disablePadding: false, label: t('financialISR.nsldsACGTotalPercentEligibilityUsedbyAcademicYearLevel3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSequenceNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSequenceNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSchoolCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSchoolCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTransactionNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTransactionNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantLastUpdateDate1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantLastUpdateDate1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantScheduledAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantScheduledAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'smartPaid01', numeric: true, disablePadding: false, label: t('financialISR.smartPaid01', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAcademicYearLevel1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAcademicYearLevel1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantClassificationofInstructionalProgramsCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantClassificationOfInstructionalProgramsCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantCODSequenceCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantCODSequenceCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardYear1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardYear1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTotalPercentEligibililtyUsedbyAcademicYearLevel1', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTotalPercentEligibilityUsedbyAcademicYearLevel1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSequenceNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSequenceNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSchoolCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSchoolCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTransactionNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTransactionNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantLastUpdateDate2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantLastUpdateDate2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantScheduledAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantScheduledAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'smartPaid02', numeric: true, disablePadding: false, label: t('financialISR.smartPaid02', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAcademicYearLevel2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAcademicYearLevel2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantClassificationOfInstructionalProgramsCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantClassificationOfInstructionalProgramsCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantCODSequenceCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantCODSequenceCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardYear2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardYear2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTotalPercentEligibililtyUsedbyAcademicYearLevel2', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTotalPercentEligibilityUsedbyAcademicYearLevel2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSequenceNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSequenceNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantSchoolCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantSchoolCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTransactionNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTransactionNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantLastUpdateDate3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantLastUpdateDate3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantScheduledAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantScheduledAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'smartPaid03', numeric: true, disablePadding: false, label: t('financialISR.smartPaid03', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantPercentEligibilityUsedbyScheduledAward2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAcademicYearLevel3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAcademicYearLevel3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantClassificationOfInstructionalProgramsCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantClassificationOfInstructionalProgramsCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantCODSequenceCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantCODSequenceCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantAwardYear3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantAwardYear3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsNationalSMARTGrantTotalPercentEligibililtyUsedbyAcademicYearLevel3', numeric: true, disablePadding: false, label: t('financialISR.nsldsNationalSMARTGrantTotalPercentEligibilityUsedbyAcademicYearLevel3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSequenceNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSequenceNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSchoolCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSchoolCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantTransactionNumber1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantTransactionNumber1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachcodGrantLastUpdateDate1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHCODGrantLastUpdateDate1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantScheduledAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantScheduledAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'teachPaid01', numeric: true, disablePadding: false, label: t('financialISR.teachPaid01', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardAmount1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardAmount1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAcademicYearLevel1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAcademicYearLevel1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantCODSequenceCode1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantCODSequenceCode1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardYear1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardYear1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantLoanConversionFlag1', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantLoanConversionFlag1', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSequenceNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSequenceNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSchoolCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSchoolCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantTransactionNumber2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantTransactionNumber2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachcodGrantLastUpdateDate2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHCODGrantLastUpdateDate2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantScheduledAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantScheduledAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'teachPaid02', numeric: true, disablePadding: false, label: t('financialISR.teachPaid02', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardAmount2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardAmount2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAcademicYearLevel2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAcademicYearLevel2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantCODSequenceCode2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantCODSequenceCode2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardYear2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardYear2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantLoanConversionFlag2', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantLoanConversionFlag2', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSequenceNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSequenceNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantSchoolCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantSchoolCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantTransactionNumber3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantTransactionNumber3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachCODGrantLastUpdateDate3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHCODGrantLastUpdateDate3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantScheduledAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantScheduledAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'teachPaid03', numeric: true, disablePadding: false, label: t('financialISR.teachPaid03', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardAmount3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardAmount3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAcademicYearLevel3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAcademicYearLevel3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantCODSequenceCode3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantCODSequenceCode3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantAwardYear3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantAwardYear3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsteachGrantLoanConversionFlag3', numeric: true, disablePadding: false, label: t('financialISR.nsldsTEACHGrantLoanConversionFlag3', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6SequenceNumber', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6SequenceNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6TypeCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6TypeCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6ChangeFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6ChangeFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6ProgramCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6ProgramCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6NetAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6NetAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6CurrentStatusCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6CurrentStatusCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6CurrentStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6CurrentStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6AggregatePrincipalBalance', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6AggregatePrincipalBalance', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6PrincipalBalanceDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6PrincipalBalanceDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6BeginDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6BeginDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6EndDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6EndDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6GuarantyAgencyCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6GuarantyAgencyCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6ContactType', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6ContactType', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6SchoolCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6SchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6ContactCode', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6ContactCode', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6GradeLevel', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6GradeLevel', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6AdditionalUnsubsidizedFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6AdditionalUnsubsidizedFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6CapitalizedInterestFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6CapitalizedInterestFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6DisbursementAmount', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6DisbursementAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6DisbursementDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6DisbursementDate', { ns: 'financialAidHeadcells' }) },
        { id: 'priorDebt', numeric: true, disablePadding: false, label: t('financialISR.priorDebt', { ns: 'financialAidHeadcells' }) },
        { id: 'batchGUID', numeric: true, disablePadding: false, label: t('financialISR.batchGUID', { ns: 'financialAidHeadcells' }) },
        { id: 'commentGUID', numeric: true, disablePadding: false, label: t('financialISR.commentGUID', { ns: 'financialAidHeadcells' }) },
        { id: 'gradeLevelInCollege', numeric: true, disablePadding: false, label: t('financialISR.gradeLevelInCollege', { ns: 'financialAidHeadcells' }) },
        { id: 'yearIndicator', numeric: true, disablePadding: false, label: t('financialISR.yearIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'highSchoolName', numeric: true, disablePadding: false, label: t('financialISR.highSchoolName', { ns: 'financialAidHeadcells' }) },
        { id: 'highSchoolCity', numeric: true, disablePadding: false, label: t('financialISR.highSchoolCity', { ns: 'financialAidHeadcells' }) },
        { id: 'highSchoolState', numeric: true, disablePadding: false, label: t('financialISR.highSchoolState', { ns: 'financialAidHeadcells' }) },
        { id: 'highSchoolCode', numeric: true, disablePadding: false, label: t('financialISR.highSchoolCode', { ns: 'financialAidHeadcells' }) },
        { id: 'highSchoolFlag', numeric: true, disablePadding: false, label: t('financialISR.highSchoolFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'dependentStudentIncomeContribution', numeric: true, disablePadding: false, label: t('financialISR.dependentStudentIncomeContribution', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsIraqAndAfghanistanServiceGrantFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsIraqandAfghanistanServiceGrantFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsIraqandAfghanistanServiceGrantContact', numeric: true, disablePadding: false, label: t('financialISR.nsldsIraqandAfghanistanServiceGrantContact', { ns: 'financialAidHeadcells' }) },
        { id: 'parentAssetThresholdExceeded', numeric: true, disablePadding: false, label: t('financialISR.parentAssetThresholdExceeded', { ns: 'financialAidHeadcells' }) },
        { id: 'studentAssetThresholdExceeded', numeric: true, disablePadding: false, label: t('financialISR.studentAssetThresholdExceeded', { ns: 'financialAidHeadcells' }) },
        { id: 'rejectOverride21', numeric: true, disablePadding: false, label: t('financialISR.rejectOverride21', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedStudentAssetThresholdExceeded', numeric: true, disablePadding: false, label: t('financialISR.assumedStudentAssetThresholdExceeded', { ns: 'financialAidHeadcells' }) },
        { id: 'assumedParentsAssetThresholdExceeded', numeric: true, disablePadding: false, label: t('financialISR.assumedParentsAssetThresholdExceeded', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsSubsidizedUsageLimitAppliesFlag', numeric: true, disablePadding: false, label: t('financialISR.nsldsSubsidizedUsageLimitAppliesFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsSubsidizedLoanEligibilityUsed', numeric: true, disablePadding: false, label: t('financialISR.nsldsSubsidizedLoanEligibilityUsed', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan1SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan1SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan2SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan2SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan3SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan3SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan4SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan4SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan5SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan5SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6ConfirmedLoanSubsidyStatus', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6ConfirmedLoanSubsidyStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'nsldsLoan6SubsidyStatusDate', numeric: true, disablePadding: false, label: t('financialISR.nsldsLoan6SubsidyStatusDate', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSTypeOfTaxReturn', numeric: true, disablePadding: false, label: t('financialISR.studentIRSTypeOfTaxReturn', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSExemptions', numeric: true, disablePadding: false, label: t('financialISR.studentIRSExemptions', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSEducationCredits', numeric: true, disablePadding: false, label: t('financialISR.studentIRSEducationCredits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSIRAPayments', numeric: true, disablePadding: false, label: t('financialISR.studentIRSIraPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSInterestIncome', numeric: true, disablePadding: false, label: t('financialISR.studentIRSInterestIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSIRADistributions', numeric: true, disablePadding: false, label: t('financialISR.studentIRSIraDistributions', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSUntaxedPensions', numeric: true, disablePadding: false, label: t('financialISR.studentIRSUntaxedPensions', { ns: 'financialAidHeadcells' }) },
        { id: 'studentIRSTaxReturnFilingStatus', numeric: true, disablePadding: false, label: t('financialISR.studentIRSTaxReturnFilingStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSTypeOfTaxReturn', numeric: true, disablePadding: false, label: t('financialISR.parentIRSTypeOfTaxReturn', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSExemptions', numeric: true, disablePadding: false, label: t('financialISR.parentIRSExemptions', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSEducationCredits', numeric: true, disablePadding: false, label: t('financialISR.parentIRSEducationCredits', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSIRAPayments', numeric: true, disablePadding: false, label: t('financialISR.parentIRSIraPayments', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSInterestIncome', numeric: true, disablePadding: false, label: t('financialISR.parentIRSInterestIncome', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSIRADistributions', numeric: true, disablePadding: false, label: t('financialISR.parentIRSIraDistributions', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSUntaxedPensions', numeric: true, disablePadding: false, label: t('financialISR.parentIRSUntaxedPensions', { ns: 'financialAidHeadcells' }) },
        { id: 'parentIRSTaxReturnFilingStatus', numeric: true, disablePadding: false, label: t('financialISR.parentIRSTaxReturnFilingStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'cfhIndicator', numeric: true, disablePadding: false, label: t('financialISR.cfhIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'irsStudentFiledSchedule1DataFieldFlag', numeric: true, disablePadding: false, label: t('financialISR.irsStudentFiledSchedule1DataFieldFlag', { ns: 'financialAidHeadcells' }) },
        { id: 'irsParentFiledScheduled1DataFieldFlag', numeric: true, disablePadding: false, label: t('financialISR.irsParentFiledSchedule1DataFieldFlag', { ns: 'financialAidHeadcells' }) },

    ]);

    const [commentcodesforISR] = useState([
        { id: 'select', numeric: true, disablePadding: false, label: t('massStudentRegistration.select', { ns: 'registrationHeadCells' }) },
        { id: 'commentCode', numeric: true, disablePadding: false, label: t('commentcodesforISR.commentCode', { ns: 'financialAidHeadcells' }) },
        { id: 'comment', numeric: true, disablePadding: false, label: t('commentcodesforISR.comment', { ns: 'financialAidHeadcells' }) },
        { id: 'resolved', numeric: true, disablePadding: false, label: t('commentcodesforISR.resolved', { ns: 'financialAidHeadcells' }) },
        { id: 'note', numeric: true, disablePadding: false, label: t('commentcodesforISR.note', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('commentcodesforISR.updateUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'uPdateTime', numeric: true, disablePadding: false, label: t('commentcodesforISR.uPdateTime', { ns: 'financialAidHeadcells' }) },
    ])
    const [customImportFields] = useState([
        { id: 'fieldName', numeric: true, disablePadding: false, label: t('customImportFields.fieldName', { ns: 'financialAidHeadcells' }) },
        { id: 'fieldValue', numeric: true, disablePadding: false, label: t('customImportFields.fieldValue', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUSerID', numeric: true, disablePadding: false, label: t('customImportFields.insertUSerID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('customImportFields.insertTime', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUSerID', numeric: true, disablePadding: false, label: t('customImportFields.updateUSerID', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('customImportFields.updateTime', { ns: 'financialAidHeadcells' }) },
        { id: 'financialISARUID', numeric: true, disablePadding: false, label: t('customImportFields.financialISARUID', { ns: 'financialAidHeadcells' }) },
        { id: 'fieldNameID', numeric: true, disablePadding: false, label: t('customImportFields.fieldNameID', { ns: 'financialAidHeadcells' }) },
        { id: 'finISARCustomID', numeric: true, disablePadding: false, label: t('customImportFields.finISARCustomID', { ns: 'financialAidHeadcells' }) },
    ])

    const [formValues, setFormValues] = useState(initialValues);

    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        onSubmit: (values) => {

        },
    });
    const classes = useStyles();
    const [showAddData, setShowAddData] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);

    const onClose = (value) => {
        getGridOneData()
        addDatas(false);
        setShowAddData(value);
        setShowCustomReport(false);
        setShowAddIsirdetails(false);
        setShowCustomAddIsirdetails(false);
        setShowISIRFullReport(false)
        setSelectedRow3('')
        DefaultTable.resetTable("customISIRRec")
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    };

    const getGridOneData = () => {
        setIsLoading1(true)
        getApi(`${isir_financial_grid1}/${formik?.values?.filtertoPackage}/${selectedSelected.studentUID}`).then(resp => {
            setIsLoading1(false)
            setTableRow(resp.data.data)
        }).catch(() => {
            setIsLoading1(false)
        })
    }

    useEffect(() => {
        if(selectedStatusRow){

            getApi(`${isir_select_items}/${selectedSelected.studentUID}/${selectedStatusRow.academicYearUID}`).then(resp => {
                resp.data.data.filterToPackageList.push({
                    "uniqueId": -1,
                    "displayText": "All",
                    "isSelected": false
                },)
                setDropdown(resp.data.data)
            })
            getGridOneData()
        }
    }, [])

    useEffect(() => {
        setSelectedRow1('')
        setFullReportSelectedRow('')
        DefaultTable.resetTable("batchesBilling")
        getGridOneData()
    }, [formik?.values?.filtertoPackage])

    const handleSelectRow1 = (row) => {
        console.log("selectedRow1", row)
        setSelectedRow1(row)
        let fullReportRow = getFullReport(row)
        setFullReportSelectedRow(fullReportRow)
        setIsLoading2(true)
        setIsLoading3(true)
        getApi(`${isir_financial_grid_2_3}/${row?.financialISARUID}/${selectedSelected.studentUID}/false`).then(resp => {
            setIsLoading2(false)
            setIsLoading3(false)
            let temp = resp.data.data.commentsCodesList.map(el => {
                el.select = false
                return el
            })
            setTableRow2(temp)
            setTableRow3(resp.data.data.customImportFieldsList)
        }).catch(() => {
            setIsLoading2(false)
            setIsLoading3(false)
        })
    }
    const handleSelectRow2 = (row) => {
        setSelectedRow2(row)
        console.log("selectedRow2", row)
    }

    const handleSelectRow3 = (row) => {
        setSelectedRow3(row)
        console.log("selectedRow3", row)
    }

    const getFullReport = (row) => {
        let fullReport = {}
        for (let [key, value] of Object.entries(row)) {
            let item = financialISR.find(o => o.id.toLowerCase().trim() === key.toLowerCase().trim());
            item == null ? fullReport[key] = value : fullReport[item.label] = value
        }
        return fullReport
    }

    const handleDelete1 = (row) => {
        deleteApi(`${isir_financial}/${selectedRow1.financialISARUID}/false`).then(resp => {
            if (resp.data.data) {
                console.log(resp.data.data)
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                getGridOneData()
                setSelectedRow1('')
                setFullReportSelectedRow('')
                DefaultTable.resetTable("batchesBilling")
            }
        })
    };

    const handleDelete3 = (row) => {
        deleteApi(`${isir_financial_custom}/${selectedRow3.finISARCustomID}`).then(resp => {
            if (resp.data.data) {
                console.log(resp.data.data)
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                setSelectedRow3('')
                DefaultTable.resetTable("customISIRRec")
                handleSelectRow1(selectedRow1)
            }
        })

    };
    const [showPopup, setShowPopup] = useState(false)

    const setResolved = () => {
        const selectedCourses = tableRow2.filter(row => row.select)
        if (selectedCourses.length == 0) {
            setShowPopup(true)
            return
        }
        const payload = {}
        payload.iDs = selectedCourses.map(el => {
            return el.id
        })
        payload.isNewTable = false;

        postApi(isir_set_comment_resolved, payload).then(resp => {
            toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
            // setShowPopup(true)
            handleSelectRow1(selectedRow1)
        })
    }
    const handleEdit1 = () => {
        setShowAddData(true);
        setShowAddIsirdetails(true)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowCustomReport(false)
    }

    const handleEdit3 = () => {
        setShowAddData(true);
        setShowAddIsirdetails(false)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowCustomReport(true)
    }

    const onFullDetailsClick = () => {
        setShowAddData(true);
        setShowAddIsirdetails(false)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowCustomReport(false)
        setShowISIRFullReport(true)
    }

    const onISIRAdd = () => {
        setShowAddData(true);
        setShowAddIsirdetails(true)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowCustomReport(false)
        setSelectedRow1('')
        setFullReportSelectedRow('')
        DefaultTable.resetTable("batchesBilling")
    }

    const onAddCustomPage = () => {
        setShowAddData(true);
        setShowAddIsirdetails(false)
        setShowCustomAddIsirdetails(false)
        addDatas(true)
        setShowCustomReport(true)
        setSelectedRow3('')
        DefaultTable.resetTable("customISIRRec")
    }

    const handleCellClick = (cellClick, id, value, row, index) => {
        // console.error(id); headLabelID
        // console.error(value); value of that column
        // console.error(row); whole json
        // console.error(index); index of the selected row
        const newArr = tableRow2.map((v, i) => {
            if (i === index) {
                if (id === 'select') {
                    v[id] = !value
                }
            }
            return v;
        })
        setTableRow2(newArr)
    }

    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;

    const [statusAccess, setStatusAccess] = useState({
        maintenance: 2,
    })
    useEffect(() => {


        let finStatusAccess = nestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === maintenance && v.moduleorder === 4
        )

        setStatusAccess({
            ...statusAccess,
            maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [statusAccess])
    //RBA Ends here
    return (
        <>
            <GenericModal
                open={showPopup}
                title={"Please select a row to resolve"}
                onClose={setShowPopup}
            />
            {!showAddData ?
                <>
                    <form onSubmit={formik.handleSubmit} className={classes.root}>
                        <Card>
                            <CardContent className={"headerTitle"}>
                                <HeaderTitle variant={'subHeading'}
                                    headerTitle={t("tabLabels.isirHistorical", { ns: "financialAid" })}
                                    addData={onISIRAdd}
                                    disableButton={(statusAccess.maintenance !== 2) ? true : false}
                                    buttonText={t("buttonLabel.add", { ns: "financialAid" })}
                                    plus={false}
                                />
                                <ButtonWrapper className="ButtonDistribute">

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="button"
                                        onClick={onFullDetailsClick}
                                        disabled={selectedRow1 ? false : true}
                                    >
                                        {t("commonLabel.fullDetails", { ns: "financialAid" })}

                                    </Button>
                                    {/* <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="button"
                                        disabled={selectedRow1 ? false : true}
                                    >
                                        {t("commonLabel.fullISIRReport", { ns: "financialAid" })}

                                    </Button> */}
                                </ButtonWrapper>
                                <FormGroup>
                                    <SelectInput
                                        label={t("commonLabel.filtertoPackage", { ns: "financialAid" })}
                                        labelId={"filtertoPackage"}
                                        formikValue={formik.values.filtertoPackage}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropdown.filterToPackageList }}
                                    />
                                </FormGroup>
                                <DefaultTable
                                    isLoading={isLoading1}
                                    trackingLabel={"batchesBilling"}
                                    headCells={financialISR}
                                    rows={tableRow}
                                    pagination={true}
                                    onRowSelect={handleSelectRow1}
                                    onDelete={handleDelete1}
                                    onEdit={handleEdit1}
                                    deleteDisable={accessFlag}
                                />
                            </CardContent>
                        </Card>
                        <Splitter />
                        <Card>
                            <CardContent className={"headerTitle"}>
                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'}
                                        headerTitle={t("commonLabel.commentCodesforselectedISIRrecord", { ns: "financialAid" })}
                                        addData={() => {
                                            setShowAddData(false);
                                            addDatas(false)
                                            setShowCustomAddIsirdetails(false)
                                            setShowAddIsirdetails(false)

                                            setResolved()
                                        }}
                                        buttonText={t("buttonLabel.setAlltoResolved", { ns: "financialAid" })}
                                        disableButton={(statusAccess.maintenance !== 2) ? true : false}
                                    />
                                </FormGroup>
                                <DefaultTable
                                    isLoading={isLoading2}
                                    headCells={commentcodesforISR}
                                    rows={tableRow2}
                                    pagination={true}
                                    // onRowSelect={handleSelectRow2}
                                    isLookup={true}
                                    onCellClick={handleCellClick}
                                />
                            </CardContent>
                        </Card>
                        <Splitter />
                        {/* <Card>
                            <CardContent>
                                <HeaderTitle variant={'subHeading'}
                                    headerTitle={t("commonLabel.customImportFields", { ns: "financialAid" })}
                                    buttonText={"Add custom Record"}
                                    addData={onAddCustomPage}
                                    disabled={selectedRow1 == '' ? 'disabled' : ''}
                                />

                                <DefaultTable
                                    isLoading={isLoading3}
                                    trackingLabel={"customISIRRec"}
                                    headCells={customImportFields}
                                    rows={tableRow3}
                                    pagination={true}
                                    onRowSelect={handleSelectRow3}
                                    onDelete={handleDelete3}
                                    onEdit={handleEdit3}
                                />
                            </CardContent>
                        </Card> */}
                    </form>
                </> : (showAddIsirdetails) ? <AddISIRDetails accessFlag={accessFlag} selectedRow1={selectedRow1} dropdown={dropdown} addDatas={addDatas} onClose={onClose} FinancialStatusUID={formik.values.filtertoPackage} />
                    : (showAddCustomIsirdetails) ? <AddCustomISRRecord accessFlag={accessFlag} dropdown={dropdown} addDatas={addDatas} onClose={onClose} />
                        // : (showCustomReport) ? <AddCustomImportField selectedRow1={selectedRow1} selectedRow3={selectedRow3} onClose={onClose} dropdown={dropdown} />
                        : (showISIRFullReport) && <ShowISIRHistoricalReport selectedRow={fullReportSelectedRow} financialISIR={financialISR} onClose={onClose} />

            }
        </>
    );
};
export default IsirHistorical; 