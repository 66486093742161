import { UPDATE_TABLE, ACCESS_LOCK, RESET_TABLE, UPDATE_PAGINATION} from "./types";

export const updateTable = (value) => async (dispatch) => {
    await dispatch({
        type: UPDATE_TABLE,
        payload: value
    });
};
export const resetTable = (value) => async (dispatch) => {
    await dispatch({
        type: RESET_TABLE,
        payload: value
    });
};

export const accessLock = (value) => async (dispatch) => {
    await dispatch({
        type: ACCESS_LOCK,
        payload: value
    });
};

export const updatePagination = (value) => async (dispatch) => {
    await dispatch({
        type: UPDATE_PAGINATION,
        payload: value
    });
};