import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PopupHeader from '../../../../../pages/common-page/popupHeader';
import { Button, Card, CardContent, Collapse } from '@material-ui/core';
import { SelectInput, TextAreaInput, TextInput, DateInput, RadioInput, TextLookUpInput, TextAutoInput } from '../../../../../components/Forms/index';
import { FormGroup, ButtonWrapperFilter, useStyles, Splitter } from '../../../../../styles/common-styles';
import Tooltips from '../../../../../utils/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { add_working_to_do } from '../../../../../services/navigation_folder/admissions';
import SearchStudent from '../../../../../components/PopupPage/searchStudent';
import * as yup from 'yup';
import { deleteApi, getApi, postApi } from '../../../../../services/apiBase';
import { delete_StudentNote, student_info_working_to_do, student_notes_smartgrid_data } from '../../../../../services/apiURL';
import { toastMessage } from '../../../../../utils/Message/toasts';
import ElementsDataGrid from '../../../../../utils/DataGrid/ElementsDataGrid';
import AddNotebutton from '../../../../../components/PopupPage/addNotebutton';
import HeaderTitle from '../../../../../utils/HeaderTitle/HeaderTitle';
import { ArrowForwardIos } from '@material-ui/icons';
import { updateAccessSelector } from '../../../../../redux/common/roleAccess/selectors';
import { useSelector } from 'react-redux';
import { workingToDo } from '../../../../../helper/constants';

export const Addtodo = ({ onClose, Dropdown, selectedRow1, toggleMoreOption2, setToggleMoreOption2 }) => {
    const [selectedRadioValue, setSelectedRadioValue] = useState('0');
    const [showAddData, setShowAddData] = useState(false);

    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    }, []);
    const { t } = useTranslation();

    const validationSchema = yup.object({
        studentName: yup
            .string()
            .required(t('validationMessages.isRequired',
                { what: t('commonLabel.studentName') }
            )),
        dueDate: yup
            .date().nullable()
            .required(t('validationMessages.isRequired',
                { what: t('commonLabel.activityDueDate') }
            )),
        term: yup
            .number()
            .min(1, t('validationMessages.isRequired',
                { what: t('commonLabel.term') }
            ))
            .required(t('validationMessages.isRequired',
                { what: t('commonLabel.term') }
            )),
        activity: yup
            .string()
            .required(t('validationMessages.isRequired',
                { what: t('commonLabel.activity') }
            )),
    })
    const [formValues, setFormValues] = useState(initialValues)
    // const [toggleMoreOption2, setToggleMoreOption2] = useState(true);

    const initialValues = {
        activityID: 0,
        studentUID: 0,
        dueDate: null,
        term: "",
        statusId: "",
        activity: "",
        studentName: "",
        notes: "",
        completionDate: null,
        emailTemplateId: 0,
        documentNameId: 0,
        documentName: "",
        emailTemplate: "",
        studentId: '',
        phone1: '',
        phone2: '',
        workPhone: '',
        email1: '',
        email2: '',
        activityTypeId: '0',
        fax: ''
    }
    const formik = useFormik({
        initialValues: formValues || initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const displayText = Dropdown.documentNameDrop.filter(val => val.uniqueId == formik.values.documentName)[0]?.displayText
            const payloadRequest = {
                "activityID": (selectedRow1) ? selectedRow1.studentActivityID : 0,
                "studentUID": parseInt(values.studentUID),
                "activityDueDate": values.dueDate,
                "term": values.term,
                "statusId": values.activity,
                "activity": values.activityTypeId,
                "studentName": values.studentName,
                "notes": values.notes,
                "completionDate": values.completionDate,
                "emailTemplateId": (values.emailTemplate === "") ? 0 : values.emailTemplate,
                "documentNameId": (values.documentName === "") ? 0 : values.documentName,
                "docName": (displayText === undefined) ? "" : displayText
            }

            postApi(student_info_working_to_do, payloadRequest).then((res) => {
                // if (!saveAddFlag) {
                //     if (selectedRow1 === '') {
                //         toastMessage(t('popupMessages.successIcon', { ns: 'translation' }), t('popupMessages.success', { ns: 'translation' }),
                //             t('popupMessages.successfullyAdded', { ns: 'translation' }))
                //     }
                //     else {
                //         toastMessage(t('popupMessages.successIcon', { ns: 'translation' }), t('popupMessages.success', { ns: 'translation' }),
                //             t('popupMessages.successfullyUpdated', { ns: 'translation' }))
                //     }
                // }
                // else {
                if (selectedRow1 === '') {
                    toastMessage(t('popupMessages.successIcon', { ns: 'translation' }), t('popupMessages.success', { ns: 'translation' }),
                        t('popupMessages.successfullyAdded', { ns: 'translation' }))
                    onClose('');
                }
                else {
                    toastMessage(t('popupMessages.successIcon', { ns: 'translation' }), t('popupMessages.success', { ns: 'translation' }),
                        t('popupMessages.successfullyUpdated', { ns: 'translation' }))
                    onClose('');
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    })
    const [addNotes] = useState([
        { id: 'noteDate', numeric: true, disablePadding: false, label: 'Note Date' },
        { id: 'user', numeric: true, disablePadding: false, label: 'User' },
        { id: 'viewerAccess', numeric: true, disablePadding: false, label: 'Viewer Access' },
        { id: 'subject', numeric: true, disablePadding: false, label: 'Subject' },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: 'Insert User ID' },
        { id: 'insertTime', numeric: true, disablePadding: false, label: 'Insert Time' },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: 'Update User ID' },
        { id: 'updateTime', numeric: true, disablePadding: false, label: 'Update Time' }
    ])
    const handleStudentlookUp = () => {
        setShowAddData(true);
        setShowStudentLookup(true);
    };
    const handleLookupClose = (value) => {
        setShowAddData(value);
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    };
    const setStudent = (value) => {
        formik.setFieldValue("studentName", `${value.lastName}, ${value.firstName} ${value.middleName}`)
        formik.setFieldValue("studentUID", `${value.studentUid}`)
        formik.setFieldValue("studentId", `${value.studentId}`)
    }

    const handleRadioDynamicField = (e) => {
        const { value } = e.target
        setSelectedRadioValue(value)
        if (value == "0") {
            formik.setFieldValue("activityTypeId", "0")
        }
        // else if (value == "1") {
        //     formik.setFieldValue("activityTypeId", "1")
        // } else if (value === "2") {
        //     formik.setFieldValue("activityTypeId", "2")
        // } else if (value === "-1") {
        //     formik.setFieldValue("activityTypeId", "-1")
        // }
    }
    const classes = useStyles();
    useEffect(() => {
        if (selectedRow1) {
            setFormValues({
                ...selectedRow1,
                studentUID: selectedRow1.studentUID,
                dueDate: selectedRow1.activityDueDate,
                term: selectedRow1.termCalendarID,
                activity: selectedRow1.statusID,
                studentName: `${selectedRow1.lastName}, ${selectedRow1.firstname} ${selectedRow1.middleName}`,
                documentNameId: selectedRow1.documentUID,
                emailTemplateId: selectedRow1.emailRefID,
                studentId: selectedRow1.studentID,
                workPhone: selectedRow1.workPhone1,
                email1: selectedRow1.email1,
                email2: selectedRow1.email2,
                fax: selectedRow1.fax1,
                activityTypeId: selectedRow1.activityTypeID.toString()
            })
        }
    }, [])

    useEffect(() => {
        if (selectedRow1) {
            getStudentNote();
        }
    }, [toggleMoreOption2])

    const getStudentNote = () => {
        setSelectedRow(true);
        getApi(`${student_notes_smartgrid_data}/${selectedRow1.studentUID}`).then((resp) => {
            setTableRow1(resp.data.data)
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })
    }
    const [showAddNotes, setShowAddNotes] = useState(false);
    const [showStudentLookup, setShowStudentLookup] = useState(false);
    //student_notes add
    const handleAdd1 = () => {
        setShowAddNotes(true)
        setSelectedRow("");
        setShowStudentLookup(false)
        setShowAddData(true);

    }
    const onClose1 = (value) => {
        setShowAddData(value);
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
        getStudentNote();
    }
    const [tableRow1, setTableRow1] = useState([]);
    const [selectedRow, setSelectedRow] = useState('')
    const handleSelectedRow1 = (value) => {
        setSelectedRow(value);
    }
    const handleEditRow1 = () => {
        setShowAddNotes(true);
        setShowAddData(true);
        setShowStudentLookup(false)
    }
    const handleView = () => {
        setShowAddNotes(true);
        setShowAddData(true);
        setShowStudentLookup(false)
    }
    const [isLoading, setIsLoading] = useState(false);
    const handleDeleteRow1 = (value) => {
        if (selectedRow) {
            deleteApi(`${delete_StudentNote}/${selectedRow.studentNotesId}`).then((res) => {
                getStudentNote();
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
            }).catch((err) => console.log(err))
        }
    }
    const handleNotesClick = () => {
        setToggleMoreOption2(true)
    }
    const handleAddClick = () => {
        formik.submitForm();
    }

    const { access } = useSelector(updateAccessSelector)
    const { subDeepNestedParentLevel } = access;
    const [workingToDoAccess, setWorkingToDAccess] = useState({
        workingToDo: 2,
    })
    useEffect(() => {

        let workingToDoAccess = subDeepNestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === workingToDo && v.moduleorder === 1
        )

        setWorkingToDAccess({
            ...workingToDoAccess,
            workingToDo: (workingToDoAccess.length > 0) ? workingToDoAccess[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (workingToDoAccess.workingToDo < 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [workingToDoAccess])
    //Role Based Access implementation ends here

    return (
        <>
            {(!showAddData) ?
                <>
                    <PopupHeader title="Working Set" subTitle="Student Information" help={true} navigationList={add_working_to_do} onClose={onClose} />
                    <Card className={classes.root}>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <FormGroup>
                                    <TextLookUpInput
                                        mandatory={true}
                                        label={t('commonLabel.studentName')}
                                        labelId="studentName"
                                        disabled={true}
                                        formikValue={formik.values.studentName}
                                        handlelookUp={handleStudentlookUp}
                                        errors={formik.errors.studentName}
                                        touched={formik.touched.studentName}
                                    />
                                    <TextInput
                                        label={t('studentLabel.studentId')}
                                        labelId="studentId"
                                        disabled
                                        formikValue={formik.values.studentId}
                                        formikChange={formik.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label={t('commonLabel.phone1')}
                                        labelId="phone1"
                                        disabled
                                        formikValue={formik.values.phone1}
                                        maxlength={'20'}
                                        formikChange={formik.handleChange}
                                    />
                                    <TextInput
                                        label={t('commonLabel.phone2')}
                                        labelId='phone2'
                                        disabled
                                        formikValue={formik.values.phone2}
                                        maxlength={'20'}
                                        formikChange={formik.handleChange}
                                    />
                                    <TextInput
                                        label={t('commonLabel.workPhone')}
                                        labelId='workphone'
                                        disabled
                                        formikValue={formik.values.workPhone}
                                        maxlength={'20'}
                                        formikChange={formik.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        label={t('commonLabel.fax')}
                                        labelId="fax"
                                        disabled
                                        formikValue={formik.values.fax}
                                        maxlength={'20'}
                                        formikChange={formik.handleChange}
                                    />
                                    <TextInput
                                        label={t('commonLabel.email1')}
                                        labelId='email1'
                                        disabled
                                        formikValue={formik.values.email1}
                                        maxlength={'50'}
                                        formikChange={formik.handleChange}
                                    />
                                    <TextInput
                                        label={t('commonLabel.email2')}
                                        labelId="email2"
                                        disabled
                                        formikValue={formik.values.email2}
                                        maxlength={'50'}
                                        formikChange={formik.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <DateInput
                                        label={t('commonLabel.activityDueDate')}
                                        labelId={'dueDate'}
                                        mandatory={true}
                                        formik={formik}
                                        errors={formik.errors.dueDate}
                                        touched={formik.touched.dueDate}
                                        defaultValue={formik.values.dueDate}
                                        disabled={accessFlag}
                                    />
                                    <TextAutoInput
                                        mandatory={true}
                                        label={t('commonLabel.term')}
                                        labelId='term'
                                        formik={formik}
                                        errors={formik.errors.term}
                                        touched={formik.touched.term}
                                        DropdownData={Dropdown.termDrop}
                                        disabled={accessFlag}
                                    />
                                    <SelectInput
                                        mandatory={true}
                                        label={t('commonLabel.activity')}
                                        labelId='activity'
                                        errors={formik.errors.activity}
                                        touched={formik.touched.activity}
                                        formikValue={formik.values.activity}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.activityEventDrop }}
                                        disabled={accessFlag}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextAreaInput
                                        label={t("commonLabel.notes")}
                                        labelId={'notes'}
                                        formikChange={formik.handleChange}
                                        formikValue={formik.values.notes}
                                        isDoubled
                                        minRows={10}
                                        disabled={accessFlag}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <DateInput
                                        label={t('commonLabel.completionDate')}
                                        labelId={'completionDate'}
                                        defaultValue={formik.values.completionDate}
                                        formik={formik}
                                        disabled={accessFlag}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <RadioInput
                                        label={t('commonLabel.activityType')}
                                        labelId='activityTypeId'
                                        formikValue={formik.values.activityTypeId}
                                        formikChange={handleRadioDynamicField}
                                        isDoubled={true}
                                        disabled={accessFlag}
                                        list={[
                                            {
                                                label: t('commonLabel.todo'),
                                                value: '0',
                                            }
                                        ]} />
                                </FormGroup>
                                <FormGroup>
                                    {selectedRadioValue === "1" &&
                                        <SelectInput
                                            label={t('commonLabel.documentName')}
                                            labelId={'documentName'}
                                            formikValue={formik.values.documentName}
                                            formikChange={formik.handleChange}
                                            isDoubled
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentNameDrop }}
                                        />
                                    }
                                    {selectedRadioValue === "2" &&
                                        <SelectInput
                                            label={t('commonLabel.emailTemplate')}
                                            labelId='emailTemplate'
                                            formikValue={formik.values.emailTemplate}
                                            formikChange={formik.handleChange}
                                            isDoubled
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.emailTemplateDrop }}
                                        />
                                    }
                                </FormGroup>
                                <div style={{ display: 'flex' }}>
                                    <Tooltips flag={(selectedRow1 !== '') ? true : false}
                                        row={(selectedRow1 !== '') ? {
                                            insertUserID: selectedRow1.insertUserID,
                                            insertTime: selectedRow1.insertTime,
                                            updateUserID: selectedRow1.updateUSerID,
                                            updateTime: selectedRow1.updateTime
                                        } : ''} />
                                    <ButtonWrapperFilter>
                                        {/* {(selectedRow1) &&
                                            <Button className="BgYellowOrange search" variant="contained" size="large" type="reset" onClick={handleNotesClick} >
                                                {t('buttonLabel.notes')}
                                            </Button>
                                        } */}
                                        {!accessFlag &&
                                            <Button className='BgYellowOrange search' variant="contained" size="large" type="submit" onclick={handleAddClick}
                                            >
                                                {(selectedRow1) ? t('buttonLabel.update') : t('buttonLabel.add')}
                                            </Button>
                                        }
                                        <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={() => onClose('')}>
                                            {t('buttonLabel.cancel')}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                    <Splitter height={"30px"} />

                    {/* Notes section */}
                    <>
                        {selectedRow1 &&
                            <Card>
                                <CardContent>
                                    <p style={{ display: 'flex' }}>
                                        <span style={{ flex: '1' }} className="searchLanding">{t('commonLabel.studentNotes')}</span>
                                        <ArrowForwardIos
                                            className={`${toggleMoreOption2 ? 'active' : ''} arrow`}
                                            onClick={() => { setToggleMoreOption2(!toggleMoreOption2) }}
                                        />
                                    </p>
                                    <Collapse in={toggleMoreOption2} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <HeaderTitle buttonText={t('buttonLabel.addNotes')}
                                            // addData={handleAdd1} disableButton={accessFlag} 
                                            />
                                        </CardContent>
                                        <ElementsDataGrid
                                            trackingLabel={"addNotes"}
                                            headCells={addNotes}
                                            rows={tableRow1}
                                            pagination={tableRow1.length > 0}
                                            isLoading={isLoading}
                                            dataGridToolbar={true}
                                            isLookup={false}
                                            onAdd={handleAdd1}
                                            onEdit={handleEditRow1}
                                            onDelete={handleDeleteRow1}
                                            onView={handleView}
                                            viewAccess={workingToDoAccess.workingToDo !== 2}
                                            addEditAccess={workingToDoAccess.workingToDo === 2}
                                            dataGridColumnMenus={true}
                                            onRowSelect={handleSelectedRow1}
                                            deleteDisable={accessFlag}
                                        />
                                    </Collapse>
                                </CardContent>
                            </Card>
                        }
                    </>
                </>

                : (showStudentLookup) ? <SearchStudent onLookupClose={handleLookupClose} setStudent={setStudent} />
                    : (showAddNotes) && <AddNotebutton onClose1={onClose1} selectedRow={selectedRow} selectedRow1={selectedRow1} accessFlag={accessFlag} studentDetails={{
                        studentname: (`${formik.values.studentName}`),
                        studentId: formik.values.studentId
                    }} />}
        </>
    )
}