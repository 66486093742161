import { SEARCH_FACULTY, SELECTED_PROFILE,
     SEARCH_ORGANIZATION, STUDENT_LOOKUP 
} from './types'

const initialState = {
    allFacultyData: {
        allFaculty: [],
        allProspects: [],
        selectedProfile: {},
        orgSearch: [],
        studentLookup: []
    }
};

const searchDataFacultyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_FACULTY:
            return {
                ...state,
                allFacultyData: {
                    ...state.allFacultyData,
                    allFaculty: [...payload]
                }
            };
        case SELECTED_PROFILE:
            return {
                ...state,
                allFacultyData: {
                    ...state.allFacultyData,
                    selectedProfile: { ...payload }
                }
            };
        case SEARCH_ORGANIZATION:
            return {
                ...state,
                allFacultyData: {
                    ...state.allFacultyData,
                    orgSearch: [...payload]
                }
            };
        case STUDENT_LOOKUP:
            return {
                ...state,
                allFacultyData: {
                    ...state.allFacultyData,
                    studentLookup: [...payload]
                }
            };
        default:
            return state;
    }
};

export default searchDataFacultyReducer;