import React, { useState } from 'react';
import { Dialog, Grid,  DialogTitle, DialogContent, TextField, Button, LinearProgress, Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import BrandingColors from '../../../../styles/color';
import { useDropzone } from 'react-dropzone';
import { CheckBoxInput } from '../../../../components/Forms'
import { FormInputWrapper, ButtonWrapperFilter } from '../../../../styles/common-styles'
import { makeStyles } from '@material-ui/core';
import { getApi, postApi, deleteApi } from '../../../../services/apiBase'
import {  upload_doc, get_doc, document_tracking } from '../../../../services/apiURL';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../helper';
import { toastMessage } from '../../../../utils/Message/toasts';
import GenericModal from '../../../../components/Modal/GenericModal';
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 100 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
const StyledTitle = styled(BootstrapDialogTitle)`
    background-color : ${BrandingColors.white};
    color: ${BrandingColors.darkBlack};
    display: flex;
    button{
        color: ${BrandingColors.darkBlack};
        padding: 0;
        border-radius: 0;
        background-color : ${BrandingColors.white};
        &:hover{
        background-color : ${BrandingColors.white};
        }
    }
    span{
        flex:1 1 0;
        line-height: 1.5;
        font-size: 1.5em;
    }
    svg{
        font-size: 1.5em;
    }
`
const DialogWrapper = styled.div`
    min-width: 600px;
`

const buttonWrap = {
    justifyContent: 'left',
    marginLeft: '-30px'
};
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '11.5px 14px'
        },
        '$ .buttonWrap': {
            justifyContent: 'left',
            marginLeft: '-30px'
        },
        '& .MuiInputBase-root.Mui-disabled': {
            background: `${BrandingColors.lightGreyCustom}`,
            color: `${BrandingColors.darkBlack}`,
        },
    }
}));
const DocumentUploadPopup = (props) => {
    const { t } = useTranslation();
    const { onClose, popupDatas, studentData, accessFlag } = props;
    const { popupHeaderTitle, openPopup, selectedTableRow } = popupDatas;
    const [fileName, setFileName] = React.useState("");
    const [docName, setDocName] = React.useState("");
    const [docDate, setDocDate] = React.useState(null);
    const [documents, setDocument] = React.useState([]);
    const [displayPortal, setDisplay] = React.useState(false);
    const [disableFlag, setDisableFlag] = React.useState(true);
    const [uploadFlag, setUploadFlag] = React.useState(false);
    const [uploadCompletedFlag, setUploadCompleteFlag] = React.useState(false);

    const onDrop = React.useCallback((acceptedFiles) => {
        setFileName(acceptedFiles[0].name)
        setDocument(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })
        ))
        setUploadFlag(false);
    }, [])
    const classes = useStyles();
    React.useEffect(() => {
        setDocName(selectedTableRow.docName);
        setDocDate(selectedTableRow.docDate);
        if (selectedTableRow.documentImage) {
            getApi(`${get_doc}/${selectedTableRow.docTrackId}`).then((resp) => {
                let { fileName, displayInPortal, image, contentType } = resp.data.data;
                setFileName(fileName);
                setDisplay(displayInPortal)
                const byteCharacters = atob(image);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: contentType });
                let objectURL = URL.createObjectURL(blob);
                setDocument([{
                    preview: objectURL,
                    type: contentType,
                    name: fileName
                }]);
                setUploadFlag(true)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [])
    React.useEffect(() => {
        setDocument([])
    }, [popupDatas])
    React.useEffect(() => {
        documents.length > 0 ? setDisableFlag(false) : setDisableFlag(true)
    }, [documents])
    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, multiple: false });
    const downloadFile = () => {
        let url = "";
        const link = document.createElement('a');
        link.href = documents[0].preview;
        link.setAttribute(
            'download',
            `${documents[0].name}`,
        );
        document.body.appendChild(link);
        window.open(link, "_blank");
    }
    const [showPopup, setShowPopup]=useState(false)
    const uploadFile = () => {
        if (fileName === ''){
            setShowPopup(true)
        }
        else{
        let formdata = new FormData();
        formdata.append('uploadingFileObj', documents[0]);
        postApi(`${upload_doc}?docTrackId=${selectedTableRow.docTrackId}&displayInPortal=${displayPortal}`, formdata).then((res) => {
            onClose(false);
            toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUploaded'));
        }).catch((err) => {
            console.log(err);
        })
      }
    }
    const deleteDoc = () => {
        selectedTableRow.docTrackId && deleteApi(`${document_tracking}/${selectedTableRow.docTrackId}/${selectedTableRow.studentUid}?deleteOnlyImage=true`).then((res) => {
            setDocument([]);
            setFileName('');
            setUploadFlag(false);
            toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        }).catch(err => {
            console.log(err);
        })

    }
    return (
        <>
        <GenericModal title={'FileName is required'} open={showPopup} onClose={setShowPopup}
        buttonText={t('buttonLabel.ok')}/>
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={openPopup}
            maxWidth={'md'}
        >
            <StyledTitle id="customized-dialog-title" onClose={onClose}>
                <span>{popupHeaderTitle}</span>
            </StyledTitle>

            <DialogContent dividers>
                <DialogWrapper>
                    <Grid container my={2}>
                        <Grid item lg={8} md={6} >
                            Document : {docName}
                        </Grid>
                        <Grid item lg={4} md={6}>
                            Date : {formatDate(docDate)}
                        </Grid>
                    </Grid>
                    <Grid container my={2}>
                        <Grid item lg={8} md={6} >
                            <CheckBoxInput label={t('commonLabel.displayInPortal')} labelId={'displayPortal'} checked={displayPortal} formikValue={displayPortal} formikChange={(e) => { setDisplay(!displayPortal) }} isDoubled={true} />
                        </Grid>
                    </Grid>
                </DialogWrapper>
                <DialogWrapper>
                    <div style={{ display: 'flex', marginLeft: '-30px' }}>
                        <input
                            style={{ display: 'none' }}
                            {...getInputProps()}
                        />
                        <ButtonWrapperFilter>
                            <Button className="BgLightBlue" variant="string" onClick={open} >
                                {t('buttonLabel.browse')}
                            </Button>
                        </ButtonWrapperFilter>
                        <FormInputWrapper isTripple className={'tripplesize'} >
                            <TextField disabled placeholder='No File Selected' id="fileName" type="text" value={fileName} variant="outlined" fullWidth className={classes.root} />
                        </FormInputWrapper>
                    </div >
                    <Box fullWidth sx={{ my: 2 }}>
                        <ButtonWrapperFilter style={buttonWrap}>
                            <Button disabled={(uploadFlag)||(accessFlag)} aria-label={t('buttonLabel.upload')} className={uploadFlag ? 'disabled' : 'BgYellowOrange'} variant="contained" size="large" onClick={uploadFile}  >
                                {t('buttonLabel.upload')}
                            </Button>
                            <Button disabled={(accessFlag)} aria-label={t('buttonLabel.viewDocument')} className='BgLightBlue' variant="contained" onClick={downloadFile}>
                                {t('buttonLabel.viewDocument')}
                            </Button>
                            <Button disabled={(accessFlag)} aria-label={t('buttonLabel.delete')} className='BgLightBlue' variant="contained" onClick={deleteDoc}>
                                {t('buttonLabel.delete')}
                            </Button>
                        </ButtonWrapperFilter>
                    </Box>

                </DialogWrapper>

            </DialogContent>
        </Dialog>
        </>
    );
}
export default React.memo(DocumentUploadPopup)