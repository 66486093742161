import {
    GET_OPTION_TAB,
    GET_SELECTION_TAB,
    RESET_STATEMENTS_MODULE_DATA
} from './types'

const initialState = {
    optionTab: {
        selectOption: "Single",
        statementSource: "Billing",
        statementDetail: "Term",
        pendingAwards: "No",
        statementFormat: "Standard",
        detailinWhatTerm: [],
        arType: [],
        aging: false,
        basicSchedule: false,
        detailedSchedule: false,
        detailDateFrom: null,
        detailDateTo: null,
        termFrom: 0,
        termTo: 0,
        statementNote: '',
        dueDate: null,
        agingFromDate: null,
        scheduleTerm: ''
    },
    selectionTab: {
        selectaStudent: "",
        studentName: "",
        billingCategory: [],
        campus: [],
        collegeLevel: [],
        costType: [],
        refundType: [],
        nameRangeFrom: "",
        nameRangeTo: "",
        activityDateFrom: null,
        activityDateTo: null,
        billingAllClear: "All",
        includeStudentsHold: "Yes",
        useStatusCriteriaFrom: "Latest",
        cohortGroups: [],
        studentsRegistered: "Official",
        showOnlyStudentsRegisteredTerms: [],
      }
};

const statementsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_OPTION_TAB:
            return {
                ...state,
                optionTab: payload
            };
        case GET_SELECTION_TAB:
            return {
                ...state,
                selectionTab: payload 
            };
        case RESET_STATEMENTS_MODULE_DATA:
            return initialState;
        
        default:
            return state;
    }
};

export default statementsReducer;