import {
    GET_PUBLISHER_EVENT,
    GET_PROPERTIES,
    GET_INTEGRATION_PUB_SETTINGS

} from './types';
const initialState = {
    publisherEvent: "",
    properties: "",
    integrationPubSettings: ""
};
const integrationPlatformReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PUBLISHER_EVENT:
            return {
                ...state, publisherEvent: payload
            }
        case GET_PROPERTIES:

            return {
                ...state, properties: payload
            }

        case GET_INTEGRATION_PUB_SETTINGS:

            return {
                ...state, integrationPubSettings: payload
            }

        default:
            return state;
    }
}
export default integrationPlatformReducer