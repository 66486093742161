import React, { useRef, useState, useEffect } from 'react';
import { Button, TextField, InputLabel, TextareaAutosize, FormControlLabel, Radio, RadioGroup, FormLabel, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormEmailInputWrapper, ButtonWrapperFilter } from '../../styles/common-styles';
import Box from '@mui/material/Box';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addAddressSelector } from '../../redux/admisssion/prospects/addAddress/selectors';
import { postApi } from '../../services/apiBase';
import { prospect_email } from '../../services/apiURL';
import GenericModal from './GenericModal';

const BoxWrapper = styled(Box)`
    svg{
        cursor: pointer;
    }
`
const EmailTemplate = (props) => {
    const { t } = useTranslation("translation", "facultyManager");
    const { onClose } = props;
    const tableRows = useSelector(addAddressSelector);
    const [allToEmails, setAllToEmails] = useState([])
    const emailSchema = yup.string().email("");
    const validationSchema = yup.object({
        fromEmail: yup
            .string('Enter email address')
            .email('Enter a valid email')
            .required('Email is required'),
        toEmail:
            yup.string()
                .test(
                    'len',
                    (t('validationMessages.validInput', { what: t('commonLabel.email') })),
                    (val) => {
                        if (val) {
                            const emails = val.split(";").map(email => email.trim());
                            const areValid = emails.every(email => emailSchema.isValidSync(email));
                            if (areValid) {
                                return true
                            }
                            return false;
                        }
                        return true;
                    }
                ),
        subject: yup
            .string('Enter your Subject')
            .required('Subject is required'),
    });
    useEffect(() => {
        const activeAddress = tableRows.address.filter(row => row.active === 1);
        let arrEmails = [];

        activeAddress.forEach((emailAddress) => {

            if (emailAddress.email1 != null && emailAddress.email1.length > 0) {
                arrEmails.push(emailAddress.email1);
            }
            if (emailAddress.email2 != null && emailAddress.email2.length > 0) {
                arrEmails.push(emailAddress.email2);
            }
            if (emailAddress.email3 != null && emailAddress.email3.length > 0) {
                arrEmails.push(emailAddress.email3);
            }
        }
        );

        let uniqueEmails = [...new Set(arrEmails)];
        //console.log("uniqueEmails:", uniqueEmails);

        // uniqueEmails.map((emailAddress) => {
        //     allToEmails = allToEmails + emailAddress + '; ';
        // });
        setAllToEmails(uniqueEmails)
    }, [])
    useEffect(() => {
        if (allToEmails.length > 0) {
            let allToEmailsString = '';
            allToEmails.map((emailAddress) => {
                allToEmailsString = allToEmailsString + emailAddress + ';';
            });
            formik.setFieldValue("toEmail", allToEmailsString)
        }

    }, [allToEmails])

    const formik = useFormik({
        initialValues: {
            fromEmail: 'camsenterprise.support@thesiscloud.com',
            toEmail: '',
            importance: '1',
            subject: '',
            body: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append('emailFrom', values.fromEmail);
            formData.append('recipients', values.toEmail);
            formData.append('subject', values.subject);
            formData.append('importance', values.importance);
            formData.append('body', values.body);
            formData.append('UploadingFileObj', attachFile ?  attachFile: null);

            postApi(`${prospect_email}`, formData).then((res) => {
                setSuccessFlag(true)
            }).catch((err) => {
                console.log(err);
            })

        }
    });
    const textArea = useRef(null);
    const [attachFile, setAttachfile] = useState([])
    //const [attachImage, setImagefile] = useState([])
    const [successFlag, setSuccessFlag] = useState(false);

    const handleFile = (event) => {
        setAttachfile(event.target.files[0].name !== 'undefined' && event.target.files[0])
    }
    const handleClose =()=>{
        setSuccessFlag(true)
        onClose(false)
    }
    return (
        <>
            {successFlag && <GenericModal
                title={t('commonLabel.thefollowingMessageHasNotBeenSent')}
                open={successFlag}
                onClose={setSuccessFlag}
                buttonText="Return"
                onButtonPress={handleClose}
            >
                <div className={"genericModalChildrenText"} style={{wordBreak: 'break-all'}}>
                    <p>
                        {t('commonLabel.from')}: {formik.values.fromEmail}
                    </p>
                    <p>
                        {t('commonLabel.to')}: {formik.values.toEmail}
                    </p>
                    <p>
                        {t('commonLabel.subject')}: {formik.values.subject}
                    </p>
                    <p>
                        {t('commonLabel.attachment')}:  {attachFile.length > 0 && attachFile[0].name}
                    </p>
                    <p>
                        {t('commonLabel.body')}:  {formik.values.body}
                    </p>
                </div>

            </GenericModal>
            }
            {(allToEmails.length === 0) ?

                <Typography variant="body2" color="text.secondary">
                    <span style={{ color: 'red' }}>{t('commonLabel.noEmailActiveAddressPresent')}</span>
                </Typography>

                :

                <form onSubmit={formik.handleSubmit} autoComplete="off" >
                    <FormEmailInputWrapper>
                        <InputLabel htmlFor="fromEmail">{t('commonLabel.from')}</InputLabel>
                        <TextField
                            fullWidth
                            id="fromEmail"
                            name="fromEmail"
                            variant="outlined"
                            type="email"
                            value={formik.values.fromEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.fromEmail && Boolean(formik.errors.fromEmail)}
                            helperText={formik.touched.fromEmail && formik.errors.fromEmail}
                        />
                    </FormEmailInputWrapper>
                    <FormEmailInputWrapper>
                        <InputLabel htmlFor="toEmail">{t('commonLabel.to')}</InputLabel>
                        <TextField
                            fullWidth
                            id="toEmail"
                            name="toEmail"
                            variant="outlined"
                            value={formik.values.toEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.toEmail && Boolean(formik.errors.toEmail)}
                            helperText={formik.touched.toEmail && formik.errors.toEmail}
                        />
                    </FormEmailInputWrapper>
                    <FormEmailInputWrapper>
                        <InputLabel htmlFor="subject">{t('commonLabel.subject')}</InputLabel>
                        <TextField
                            fullWidth
                            id="subject"
                            name="subject"
                            type="text"
                            variant="outlined"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                        />
                    </FormEmailInputWrapper>
                    <FormEmailInputWrapper >
                        <RadioGroup className={'radio-group'} row aria-label="active" name="active" value={formik.values.importance} onChange={(event) => { formik.setFieldValue("importance", event.target.value); }} >
                            <FormLabel style={{ alignSelf: "center" }} component="legend">{t('commonLabel.importance')}</FormLabel>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControlLabel value="2" control={<Radio />} label={t('commonLabel.high')} />
                            <FormControlLabel style={{ marginLeft: "24px" }} value="1" control={<Radio />} label={t('commonLabel.normal')} />
                            <FormControlLabel style={{ marginLeft: "24px" }} value="0" control={<Radio />} label={t('commonLabel.low')} />
                        </RadioGroup>
                    </FormEmailInputWrapper>
                    <FormEmailInputWrapper >
                        <InputLabel htmlFor="subject" className={`textareaEmail`}>{t('commonLabel.message')}</InputLabel>
                        <TextareaAutosize
                            aria-label="minimum height"
                            maxRows={10}
                            minRows={8}
                            name="body"
                            onChange={formik.handleChange}
                            value={formik.values.body}
                            style={{ width: '100%', padding: '16px' }}
                            ref={textArea}
                        />
                    </FormEmailInputWrapper>
                    <BoxWrapper
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '24px',
                            width: '100%',
                            border: (theme) => `1px solid ${theme.palette.divider}`,
                            borderRadius: 1,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                                m: 1.5,
                            },
                            '& hr': {
                                mx: 0.5,
                            },
                        }}
                    >
                        {/* <FormatSizeOutlinedIcon />
                <FontDownloadOutlinedIcon />
                <FormatBoldIcon onClick={handleBoldText}/>
                <FormatItalicIcon onClick={handleItalicText}/>
                <FormatUnderlinedIcon onClick={handleUnderlineText}/> */}
                        <input type="file" id="handleFiles" style={{ display: 'none' }} onChange={handleFile} />
                        <InputLabel htmlFor="handleFiles"><AttachFileIcon /></InputLabel>
                        {/* <input type="file" accept="image/*" id="handleImages" style={{ display: 'none' }} onChange={handleImage} />
                        <InputLabel htmlFor="handleImages"><ImageIcon /></InputLabel> */}
                    </BoxWrapper>
                    {attachFile && attachFile.name}
                    <ButtonWrapperFilter>
                        <Button aria-label={'Send'} className='BgYellowOrange' variant="contained" size="large" type="submit">
                            {t('buttonLabel.send')}
                        </Button>
                        <Button onClick={onClose} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                            {t('buttonLabel.cancel')}
                        </Button>
                    </ButtonWrapperFilter>
                </form>

            }
        </>
    )
}

export default EmailTemplate;