import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { FormGroup, ButtonWrapperFilter, useStyles, Splitter, ButtonWrapper } from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { SelectInput, TextInput } from "../../../../components/Forms";
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import AddAwardDetails from "./addAwardDetails";
import AddFinancialAidAwardDetailEntry from "./addFinancialAidAwardDetailEntry";
import { CODHistory } from "./codHistory";
import { useHistory } from "react-router-dom";
import { award_tab, more_tab_financial_awards, delete_awards_finincialawardId, award_select_items, user_details } from "../../../../services/apiURL";
import { getApi, deleteApi } from '../../../../services/apiBase';
import { toastMessage } from '../../../../utils/Message/toasts';
import currencyPipe, { parseNumber, formNumber } from '../../../../helper/currency';
import GenericModal from "../../../../components/Modal/GenericModal";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";


const Award = ({ getSelectedRow, addDatas, selectedSelected, selectedStatusRow, fyDropDown }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);

    const [selectedAwards, setSelectedAwards] = useState('')
    const [selectedRow, setSelectedRow] = useState({})

    const [showAddAwarddetails, setShowAddAwarddetails] = useState(false);
    const [showAddAwardEntry, setShowAddAwardEntry] = useState(false);
    const [showAddCODHistory, setShowAddCODHistory] = useState(false);

    const [dropDown, setDropDown] = useState({
        "terms": [],
        "awardType": [],
        "awardStatus": [],
        "creditStatus": [],
        "code1": [],
        "code2": [],
        "showOnStatementList": [],
        "studentAccepted": [],
        "campus": []
    })
    const [financialAwardsHeadCells] = useState([
        { id: 'textTerm', numeric: true, disablePadding: false, label: t('financialAwards.term', { ns: 'financialAidHeadcells' }) },
        { id: 'distributionDate', numeric: true, disablePadding: false, date: true, label: t('financialAwards.distributionDate', { ns: 'financialAidHeadcells' }) },
        { id: 'lock', numeric: true, disablePadding: false, label: t('financialAwards.lock', { ns: 'financialAidHeadcells' }) },
        { id: 'awardType', numeric: true, disablePadding: false, label: t('financialAwards.awardType', { ns: 'financialAidHeadcells' }) },
        { id: 'awardStatus', numeric: true, disablePadding: false, label: t('financialAwards.awardStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'amount', numeric: true, disablePadding: false, label: t('financialAwards.amount', { ns: 'financialAidHeadcells' }) },
        { id: 'estimatedAmount', numeric: true, disablePadding: false, label: t('financialAwards.distAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'originalAmount', numeric: true, disablePadding: false, label: t('financialAwards.origAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'creditStatus', numeric: true, disablePadding: false, label: t('financialAwards.creditStatus', { ns: 'financialAidHeadcells' }) },
        { id: 'awardCodeOne', numeric: true, disablePadding: false, label: t('financialAwards.awardCodeOne', { ns: 'financialAidHeadcells' }) },
        { id: 'description', numeric: true, disablePadding: false, label: t('financialAwards.description', { ns: 'financialAidHeadcells' }) },
        { id: 'awardCodeTwo', numeric: true, disablePadding: false, label: t('financialAwards.awardCodeTwo', { ns: 'financialAidHeadcells' }) },
        { id: 'dateCheckSigned', numeric: true, disablePadding: false, label: t('financialAwards.dateCheckSigned', { ns: 'financialAidHeadcells' }) },
        { id: 'showOnBillingStatement', numeric: true, disablePadding: false, label: t('financialAwards.showOnBillingStatement', { ns: 'financialAidHeadcells' }) },
        { id: 'billingBatchName', numeric: true, disablePadding: false, label: t('financialAwards.billingBatchName', { ns: 'financialAidHeadcells' }) },
        { id: 'transId', numeric: true, disablePadding: false, label: t('financialAwards.transID', { ns: 'financialAidHeadcells' }) },
        { id: 'transTypeId', numeric: true, disablePadding: false, label: t('financialAwards.transTypeId', { ns: 'financialAidHeadcells' }) },
        { id: 'termCalendarId', numeric: true, disablePadding: false, label: t('financialAwards.termCalenderId', { ns: 'financialAidHeadcells' }) },
        { id: 'transStatusId', numeric: true, disablePadding: false, label: t('financialAwards.transStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'term', numeric: true, disablePadding: false, label: t('financialAwards.term', { ns: 'financialAidHeadcells' }) },
        { id: 'code1Id', numeric: true, disablePadding: false, label: t('financialAwards.code1ID', { ns: 'financialAidHeadcells' }) },
        { id: 'code2Id', numeric: true, disablePadding: false, label: t('financialAwards.code2ID', { ns: 'financialAidHeadcells' }) },
        { id: 'creditStatusId', numeric: true, disablePadding: false, label: t('financialAwards.creditStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'referenceNo', numeric: true, disablePadding: false, label: t('financialAwards.referenceNo', { ns: 'financialAidHeadcells' }) },
        { id: 'packageId', numeric: true, disablePadding: false, label: t('financialAwards.packageID', { ns: 'financialAidHeadcells' }) },
        { id: 'effectiveTransDate', numeric: true, disablePadding: false, label: t('financialAwards.effectiveTransDate', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('financialAwards.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('financialAwards.insertTime', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('financialAwards.updateUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('financialAwards.updateTime', { ns: 'financialAidHeadcells' }) },
        { id: 'billingLinkId', numeric: true, disablePadding: false, label: t('financialAwards.billingLinkID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialAwardId', numeric: true, disablePadding: false, label: t('financialAwards.financialAwardId', { ns: 'financialAidHeadcells' }) },
        { id: 'studentId', numeric: true, disablePadding: false, label: t('financialAwards.studentID', { ns: 'financialAidHeadcells' }) },
        { id: 'studentSsn', numeric: true, disablePadding: false, label: t('financialAwards.studentSSN', { ns: 'financialAidHeadcells' }) },
        { id: 'studentUid', numeric: true, disablePadding: false, label: t('financialAwards.studentUID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialStatusId', numeric: true, disablePadding: false, label: t('financialAwards.financialStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'requiredCredits', numeric: true, disablePadding: false, label: t('financialAwards.requiredCredits', { ns: 'financialAidHeadcells' }) },
        { id: 'studentAccepted', numeric: true, disablePadding: false, label: t('financialAwards.studentAccepted', { ns: 'financialAidHeadcells' }) },
        { id: 'studentAcceptedTime', numeric: true, disablePadding: false, label: t('financialAwards.studentAcceptedTime', { ns: 'financialAidHeadcells' }) },
        { id: 'awardMethodology', numeric: true, disablePadding: false, label: t('financialAwards.awardMethodology', { ns: 'financialAidHeadcells' }) },
        { id: 'awardMethodologyId', numeric: true, disablePadding: false, label: t('financialAwards.awardMethodologyID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialLoanId', numeric: true, disablePadding: false, label: t('financialAwards.financialLoanID', { ns: 'financialAidHeadcells' }) },
        { id: 'originationFeeAmount', numeric: true, disablePadding: false, label: t('financialAwards.originationFeeAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'interestRebateAmount', numeric: true, disablePadding: false, label: t('financialAwards.interestRebateAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'campus', numeric: true, disablePadding: false, label: t('financialAwards.campus', { ns: 'financialAidHeadcells' }) },
        { id: 'campusId', numeric: true, disablePadding: false, label: t('financialAwards.campusID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialCodheaderId', numeric: true, disablePadding: false, label: t('financialAwards.financialCODHeaderID', { ns: 'financialAidHeadcells' }) },
        { id: 'baseCurrencyAmount', numeric: true, disablePadding: false, label: t('financialAwards.baseCurrencyAmount', { ns: 'financialAidHeadcells' }) },
        { id: 'baseCurrencyEstimated', numeric: true, disablePadding: false, label: t('financialAwards.baseCurrencyEstimated', { ns: 'financialAidHeadcells' }) },
        { id: 'calendarYear', numeric: true, disablePadding: false, label: t('financialAwards.calendarYear', { ns: 'financialAidHeadcells' }) },
        
    ]);
    const [financialAwardsHistoryHeadCells] = useState([
        { id: 'adjustmentDate', numeric: true, disablePadding: false, date: true, label: t('financialAwards.adjustmentDate', { ns: 'financialAidHeadcells' }) },
        { id: 'amount', numeric: true, disablePadding: false, label: t('financialAwards.amount', { ns: 'financialAidHeadcells' }) },
        { id: 'adjComment', numeric: true, disablePadding: false, label: t('financialAwards.adjustmentComment', { ns: 'financialAidHeadcells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('financialAwards.insertUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('financialAwards.insertTime', { ns: 'financialAidHeadcells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('financialAwards.updateUserID', { ns: 'financialAidHeadcells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('financialAwards.updateTime', { ns: 'financialAidHeadcells' }) },
        { id: 'billingLinkId', numeric: true, disablePadding: false, label: t('financialAwards.billingLinkID', { ns: 'financialAidHeadcells' }) },


    ]);
    const [awardHeader] = useState([
        { id: 'awardType', numeric: true, disablePadding: false, label: t('awardHeader.awardType', { ns: 'financialAidHeadcells' }) },
        { id: 'financialAwardNumber', numeric: true, disablePadding: false, label: t('awardHeader.financialAwardNumber', { ns: 'financialAidHeadcells' }) },
        { id: 'codFinancialAwardID', numeric: true, disablePadding: false, label: t('awardHeader.codFinancialAwardID', { ns: 'financialAidHeadcells' }) },
        { id: 'awardTypeID', numeric: true, disablePadding: false, label: t('awardHeader.awardTypeID', { ns: 'financialAidHeadcells' }) },
        { id: 'originationFeePercent', numeric: true, disablePadding: false, label: t('awardHeader.originationFeePercent', { ns: 'financialAidHeadcells' }) },
        { id: 'interestRebatePercent', numeric: true, disablePadding: false, label: t('awardHeader.interestRebatePercent', { ns: 'financialAidHeadcells' }) },
        { id: 'abilityToBenefitCode', numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitCode', { ns: 'financialAidHeadcells' }) },
        { id: 'abilityToBenefitTestAdministratorCode', numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitTestAdministratorCode', { ns: 'financialAidHeadcells' }) },
        { id: 'abilityToBenefitTestCode', numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitTestCode', { ns: 'financialAidHeadcells' }) },
        { id: 'abilityToBenefitCompletionDate', date: true, numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitCompletionDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'abilityToBenefitState', numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitState', { ns: 'financialAidHeadcells' }) },
        { id: 'abilityToBenefitStateID', numeric: true, disablePadding: false, label: t('awardHeader.abilityToBenefitStateID', { ns: 'financialAidHeadcells' }) },
        { id: 'programOfStudy', numeric: true, disablePadding: false, label: t('awardHeader.programOfStudy', { ns: 'financialAidHeadcells' }) },
        { id: 'preprofessionalCourseworkIndicator', numeric: true, disablePadding: false, label: t('awardHeader.preProfessionalCourseWorkIndicator', { ns: 'financialAidHeadcells' }) },
        { id: 'derivedAbilityToBenefit', numeric: true, disablePadding: false, label: t('awardHeader.derivedAbilityToBenefit', { ns: 'financialAidHeadcells' }) },
        { id: 'academicYearBeginDate', numeric: true, date: true, disablePadding: false, label: t('awardHeader.academicYearBeginDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'academicYearEndDate', numeric: true, date: true, disablePadding: false, label: t('awardHeader.academicYearEndDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'financialAidYearBeginDate', numeric: true, date: true, disablePadding: false, label: t('awardHeader.financialaidYearBeginDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'financialAidYearEndDate', numeric: true, date: true, disablePadding: false, label: t('awardHeader.financialaidYearEndDate', { ns: 'financialAidHeadcells' }), dateTime: true },
        { id: 'weeksProgramsAcademicYear', numeric: true, disablePadding: false, label: t('awardHeader.weeksProgramsAcademicYear', { ns: 'financialAidHeadcells' }) },
        { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('awardHeader.financialStatusID', { ns: 'financialAidHeadcells' }) },
        { id: 'financialHeaderID', numeric: true, disablePadding: false, label: t('awardHeader.financialHeaderID', { ns: 'financialAidHeadcells' }) },
        { id: 'campus', numeric: true, disablePadding: false, label: t('awardHeader.campus', { ns: 'financialAidHeadcells' }) },
        { id: 'campusID', numeric: true, disablePadding: false, label: t('awardHeader.campusID', { ns: 'financialAidHeadcells' }) },
        { id: 'codAwardType', numeric: true, disablePadding: false, label:  t('awardHeader.codAwardType', { ns: 'financialAidHeadcells' }) },

    ])

    const initialValues = {
        filtertoPackage: selectedStatusRow?.financialStatusID,
        remainingNeed: "",
    };
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: (values) => { },
    });
    const [tableRow, setTableRow] = useState({
        financialAwardsRow: [],
        awardHeaderRow: [],
        financialAwardHistoryDetails: []
    });
    const handleRowSelect = (row) => {
        setSelectedAwards(row);
        const rows = tableRow.financialAwardHistoryDetails.filter(v => v.financialAwardId === row.financialAwardId)
        DefaultTable.resetTable("financialAwardsHistory")
        rows.length > 0 ? setFinancialAwardHistory(rows) : setFinancialAwardHistory([])
    }

    const [financialAwards, setFinancialAwards] = useState([])
    const [awardHeaderRows, setAwardHeaderRows] = useState([])
    const [financialAwardHistory, setFinancialAwardHistory] = useState([]);
    const [userDetails, setUserDetails] = useState({})
    const getFinancialAwards = (selectedSelected) => {
        const studentUId = selectedSelected.studentUID;
        Promise.all([getApi(`${award_tab}/${studentUId}`), getApi(`${more_tab_financial_awards}/${studentUId}`)])
            .then((resp) => {
                setTableRow({
                    ...tableRow,
                    awardHeaderRow: resp[0].data.data,
                    financialAwardsRow: resp[1].data.data.getFinancialAwardDetails,
                    /*financialAwardsRow: resp[1].data.data.getFinancialAwardDetails.map((row) => ({
                        ...row,
                        calendarYear: row.calendarYear === 0 ? "" : row.calendarYear
                    })),
                    */
                    financialAwardHistoryDetails: resp[1].data.data.getFinancialAwardHistioryDetails
                })
                if (formik.values.filtertoPackage === -1) {
                    setFinancialAwards(resp[1].data.data.getFinancialAwardDetails);
                    setAwardHeaderRows([])
                    return
                }
                if (resp[0].data.data?.length > 0) {
                    const updatedRows = resp[0].data.data?.filter(row => row.financialStatusID === formik.values.filtertoPackage);
                    setAwardHeaderRows(updatedRows)
                } else {
                    setAwardHeaderRows([])
                }

                if (resp[1].data.data.getFinancialAwardDetails?.length > 0) {
                    const updatedRows = resp[1].data.data.getFinancialAwardDetails?.filter(row => row.financialStatusId === formik.values.filtertoPackage);
                    setFinancialAwards(updatedRows);
                } else {
                    setFinancialAwards([]);
                }

                //updateTables(formik.values.filtertoPackage)
            })
    }
    const transform = (val) => {
        return currencyPipe(val, { symbol: '', decimal: '.', separator: ',', precision: 2 }).format();
    }
    const classes = useStyles();
    const [showAddData, setShowAddData] = useState(false);
    useEffect(() => {
        getFinancialAwards(selectedSelected);
        selectedStatusRow?.remainingNeed && formik.setFieldValue("remainingNeed", transform(selectedStatusRow?.remainingNeed))
        getApi(`${award_select_items}`).then((resp) => {
            const { terms, awardType, awardStatus, creditStatus, code1, code2, showOnStatementList, studentAccepted, campus } = resp.data.data;
            setDropDown({
                ...dropDown,
                "terms": terms,
                "awardType": awardType,
                "awardStatus": awardStatus,
                "creditStatus": creditStatus,
                "code1": code1,
                "code2": code2,
                "showOnStatementList": showOnStatementList,
                "studentAccepted": studentAccepted,
                "campus": campus
            })


        }).catch((err) => {
            console.log(err);
        })
        getApi(user_details).then((resp) => {
            const { data } = resp.data;
            setUserDetails(data);
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    const onClose = (value) => {
        getFinancialAwards(selectedSelected)
        addDatas(false);
        setShowAddData(value);
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    };
    const handleDeleteAwards = () => {
        deleteApi(`${delete_awards_finincialawardId}/${selectedAwards.financialAwardId}`).then(() => {
            toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
            onClose('');
            getFinancialAwards(selectedSelected);
        }).catch(err => {
            console.log(err)
        })
        // DefaultTable.resetTable('batchesBillingAwards')
        DefaultTable.resetTable('financialAwards')

    }
    const handleDelete = () => { }

    const handleEditAward = () => {
        setShowAddData(true);
        setShowAddAwarddetails(true)
        addDatas(true)
        setShowAddAwardEntry(false);
        setShowAddCODHistory(false);
    }
    const handleAdd = () => {
        setSelectedAwards("");
        setShowAddData(true);
        setShowAddAwarddetails(true)
        addDatas(true)
        setShowAddAwardEntry(false);
        setShowAddCODHistory(false);
    }
    const handleEditCodAward = () => {
        setShowAddData(true);
        setShowAddAwarddetails(false)
        addDatas(true)
        setShowAddAwardEntry(true);
        setShowAddCODHistory(false);
    }
    const handleSelectedRow = (val) => {
        setSelectedRow(val)
    }
    const [confirmPopup, setConfirmPopup] = useState(false)
    const handleDeletion = () => {
        setConfirmPopup(true)
    }
    const handleFilterToPackageChange = (e) => {
        formik.setFieldValue("filtertoPackage", e.target.value);
        updateTables(e.target.value);
    }
    const updateTables = (value) => {
        const rows = tableRow?.financialAwardsRow;
        const rowsHeader = tableRow?.awardHeaderRow;
        DefaultTable.resetTable('financialAwards');
        DefaultTable.resetTable('batchesBilling');
        if (value == -1) {
            setFinancialAwards(rows);
            setAwardHeaderRows([])
            return
        }
        const updatedRows = rows.filter(row => row.financialStatusId === value);
        const updatedRowsHeader = rowsHeader.filter(row => row.financialStatusID === value);
        setFinancialAwards(updatedRows);
        setAwardHeaderRows(updatedRowsHeader)
    }
    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;

    const [statusAccess, setStatusAccess] = useState({
        maintenance: 2,
    })
    useEffect(() => {


        let finStatusAccess = nestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === maintenance && v.moduleorder === 4
        )

        setStatusAccess({
            ...statusAccess,
            maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [statusAccess])
    //Checking Change prospect access Ends here
    return (
        <>
            {(selectedAwards.lock === false) ?
                <GenericModal
                    title={t('popupMessages.confirmDeleteHighlighted')}
                    open={confirmPopup}
                    onClose={setConfirmPopup}
                    buttonText={t('buttonLabel.yes')}
                    button2Text={t('buttonLabel.no')}
                    onButtonPress={handleDeleteAwards}
                /> : <GenericModal
                    title={t('popupMessages.awardIsLocked')}
                    open={confirmPopup}
                    onClose={setConfirmPopup}
                />}
            {!showAddData ?
                <>
                    <form onSubmit={formik.handleSubmit} className={classes.root}>
                        <Card>
                            <CardContent className={"headerTitle"}>
                                <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.financialAwards", { ns: "financialAid" })} />
                                <ButtonWrapper className="ButtonDistribute">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        disabled={selectedAwards === ""}
                                        onClick={() => {
                                            setShowAddData(true);
                                            setShowAddAwarddetails(false)
                                            addDatas(true)
                                            setShowAddAwardEntry(false);
                                            setShowAddCODHistory(true);
                                        }}
                                    >
                                        {t("buttonLabel.viewCODHistory", { ns: "financialAid" })}
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        onClick={handleAdd}
                                        disabled={accessFlag}
                                    >
                                        <span className="plus">+</span>{" "}
                                        {t("buttonLabel.add", { ns: "financialAid" })}
                                    </Button>
                                </ButtonWrapper>
                                <FormGroup>
                                    <SelectInput
                                        label={t("commonLabel.filtertoPackage", { ns: "financialAid" })}
                                        labelId={"filtertoPackage"}
                                        formikValue={formik.values.filtertoPackage}
                                        formikChange={handleFilterToPackageChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: fyDropDown }}
                                    />
                                </FormGroup>
                                <DefaultTable
                                    trackingLabel={"financialAwards"}
                                    headCells={financialAwardsHeadCells}
                                    rows={financialAwards}
                                    pagination={true}
                                    onRowSelect={handleRowSelect}
                                    customAction={true}
                                    onCustomDelete={handleDeletion}
                                    onEdit={handleEditAward}
                                    onDelete={handleDeletion}
                                    deleteDisable={accessFlag}

                                />
                                <Splitter height={"15px"} />
                                <FormGroup style={{ justifyContent: "flex-end" }}>
                                    <TextInput
                                        label={t("commonLabel.remainingNeed", { ns: "financialAid" }) + " " + "$"}
                                        labelId="remainingNeed"
                                        formikChange={formik.handleChange}
                                        formikValue={formik.values.remainingNeed}
                                        disabled
                                    />
                                </FormGroup>
                                <DefaultTable
                                    trackingLabel={"financialAwardsHistory"}
                                    headCells={financialAwardsHistoryHeadCells}
                                    rows={financialAwardHistory}
                                    pagination={true}
                                    isLookup={true}
                                />
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent className={"headerTitle"}>
                                <FormGroup>
                                    <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codAwardHeader", { ns: "financialAid" })} />
                                </FormGroup>
                                <DefaultTable
                                    // trackingLabel={"batchesBilling"}
                                    headCells={awardHeader}
                                    rows={awardHeaderRows}
                                    pagination={true}
                                    onRowSelect={handleSelectedRow}
                                    // onDelete={handleDelete}
                                    onEdit={handleEditCodAward}
                                />
                            </CardContent>
                        </Card>
                        <ButtonWrapperFilter>
                            <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={() => { dispatch(getSelectedProfiles({})); history.push('/financial-aid/student-search'); }}>
                                {t('buttonLabel.cancel')}
                            </Button>
                        </ButtonWrapperFilter>
                    </form>
                </>
                : (showAddCODHistory) ? <CODHistory addDatas={addDatas} onClose={onClose} selectedSelected={selectedSelected} selectedAwards={selectedAwards} />
                    : (showAddAwarddetails) ? <AddAwardDetails accessFlag={accessFlag} addDatas={addDatas} selectedSelected={selectedSelected}
                        selectedAwards={selectedAwards} setSelectedAwards={setSelectedAwards} onClose={onClose} userDetails={userDetails}
                        fyDropDown={fyDropDown} dropDown={dropDown} selectedStatusRow={selectedStatusRow} financialAwards={tableRow?.financialAwardsRow} awardRows={tableRow.awardHeaderRow}
                        filtertoPackage={formik.values.filtertoPackage} />
                        : (showAddAwardEntry) && <AddFinancialAidAwardDetailEntry accessFlag={accessFlag} addDatas={addDatas} onClose={onClose} selectedSelected={selectedSelected} selectedRow={selectedRow} />

            }
        </>
    );
};
export default Award; 