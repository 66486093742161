const BrandingColors = {
    yellowOrange : '#6AC5DA',
    brightOrange : '#6AC5DA',
    redOrange : '#FB5801',
    brightTeal : '#3DB5D2',
    lightTeal : '#A601DB',
    greyTeal : '#A789BE',
    lightGrey : '#868585',
    mediumGrey : '#8A8092',
    darkGreyFont : '#424B5C',
    darkGrey : '#494A52',
    darkBlack : '#000000',
    white : '#FFFFFF',
    lightOrangeCustom : '#FFF3E1',
    lightGreyCustom : '#F6F6F6',
    hoverLightGreyCustom: '#EEF2F3',
    fontOrangeCustom :'#6AC5DA',
    hoverOrangeCustom: '#E6F5F9',
    lightBluetooltipBg: '#EEF9FF',
    lightBluetooltipBorder: '#47A8F5',
    errorColor: '#F44336',
    customGrey:"#EEF2F3",
    customGreen:"#008000",
    customBlue: "#0000FF",
    lightBgOrange: "#6AC5DA",
    lightBgOrangeBRD: "#6AC5DA",
    redColor: "#FB5801",
    btnTxtBlue: "#0A66C2",
    btnHoverBlue: "#CDECF3",
    btnTextDisabled: "#646464",
    btnPrimaryDisabled: "#EEEEEE",
    disableGreyFont: "#555454",
    backgroundGrey: "#F3f7f8",
    lightGreySidebar: "#E0F7E1",
    greensidebarbg: "#f9fff9",
    blkSideBarFont: "#424B5C",
    hoverBlue: "#E6F5F9",
    texthover: "#079EC2",
    textBg: "#E6FBFC",
    textBord: "#079EC2",
    lightGreyFont: "#868585",
    lightBlueFont: "#0A66C2",
    hoverlightBlueFont: "#0A66C2",
    disablelightGreyFont: "#A7A8A9",
    linkTextFont: "#3C66C4"
};
export default BrandingColors;