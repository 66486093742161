import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles } from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_counselling } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { getApi, postApi, putApi } from '../../../../services/apiBase';
import { counseling_select_items, save_exitcouseling, update_exitcouseling } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';

const AddEntranceExit = ({ onClose, selectedRow, selectedCounselingRow, selectedSelected, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();
    const initialValues = {
        counselingType: "",
        counselingSequenceNumber: "",
        counselingException: false,
        counselingComplete: false,
        counselingCompletionDate: null,
        counselingExpirationDate: null,
    };
    const [Dropdown, setDropdown] = useState({
        "counselingTypes": []
    })
    const [formValues, setFormValues] = useState(initialValues);

    const formik = useFormik({
        initialValues: formValues || initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payloadRequest =
            {
                "ownerUID": selectedSelected.studentUID,
                "counselingType": values.counselingType,
                "csn": Number(values.counselingSequenceNumber) ? Number(values.counselingSequenceNumber) : null,
                "entranceCounseling": values.counselingComplete,
                "counselingException": values.counselingException,
                "counselingCompletionDate": values.counselingCompletionDate,
                "counselingExpirationDate": values.counselingExpirationDate
            }
            if (selectedCounselingRow) {
                putApi((`${update_exitcouseling}/${selectedCounselingRow.id}`), payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    onClose(false)
                }).catch((err) => {
                    console.log(err)
                })
            }
            else {
                postApi(save_exitcouseling, payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
                    onClose(false)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }

    });

    useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
        getApi(counseling_select_items).then((resp) => {
            const { counselingTypes } = resp.data.data;
            setDropdown({
                ...Dropdown,
                "counselingTypes": counselingTypes
            })
        }).catch((err) => {
            console.log(err);
        })
        if (selectedCounselingRow) {
            setFormValues({
                ...formValues,
                counselingType: (selectedCounselingRow.counselingType) ? selectedCounselingRow.counselingType : "",
                counselingSequenceNumber: Number(selectedCounselingRow.csn) ? Number(selectedCounselingRow.csn) : null,
                counselingException: (selectedCounselingRow.counselingException) ? selectedCounselingRow.counselingException : false,
                counselingComplete: (selectedCounselingRow.entranceCounseling) ? selectedCounselingRow.entranceCounseling : false,
                counselingCompletionDate: (selectedCounselingRow.counselingCompletionDate) ? selectedCounselingRow.counselingCompletionDate : null,
                counselingExpirationDate: (selectedCounselingRow.counselingExpirationDate) ? selectedCounselingRow.counselingExpirationDate : null
            })
        }
    }, [])

    return (
        <>
            <PopupHeader
                title={
                    selectedCounselingRow == ""
                        ? (t('commonLabel.addCounseling', { ns: "financialAid" }))
                        : (t('commonLabel.updateCounseling', { ns: "financialAid" }))
                }
                navigationList={financialAid_counselling}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput isDoubled
                                label={(t('commonLabel.counselingType', { ns: "financialAid" }))}
                                labelId="counselingType"
                                formikValue={formik.values.counselingType}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.counselingTypes }}

                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.counselingSequenceNumber', { ns: "financialAid" }))}
                                labelId="counselingSequenceNumber"
                                formikValue={formik.values.counselingSequenceNumber}
                                formikChange={formik.handleChange}
                                maxlength={"40"}
                                onlyNumeric={true}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <CheckBoxInput isDoubled
                                label={(t('commonLabel.counselingComplete', { ns: "financialAid" }))}
                                labelId="counselingComplete"
                                formikValue={formik.values.counselingComplete}
                                formikChange={formik.handleChange}
                                checked={formik.values.counselingComplete}
                                disabled={accessFlag}
                            />
                            <CheckBoxInput isDoubled
                                label={(t('commonLabel.counselingException', { ns: "financialAid" }))}
                                labelId={"counselingException"}
                                formikValue={formik.values.counselingException}
                                formikChange={formik.handleChange}
                                checked={formik.values.counselingException}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <DateInput isDoubled
                                label={(t('commonLabel.counselingCompletionDate', { ns: "financialAid" }))}
                                labelId={"counselingCompletionDate"}
                                defaultValue={formik.values.counselingCompletionDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                            <DateInput isDoubled
                                label={(t('commonLabel.counselingExpirationDate', { ns: "financialAid" }))}
                                labelId="counselingExpirationDate"
                                defaultValue={formik.values.counselingExpirationDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                        </FormGroup>

                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips flag={(selectedCounselingRow) ? true : false} row={
                                {
                                    insertUserID: selectedCounselingRow.insertUserID,
                                    insertTime: selectedCounselingRow.insertTime,
                                    updateUserID: selectedCounselingRow.updateUserID,
                                    updateTime: selectedCounselingRow.uPdateTime
                                }
                            } />

                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={accessFlag}
                                >
                                    {selectedCounselingRow == ""
                                        ? t("buttonLabel.add", { ns: "financialAid" })
                                        : t("buttonLabel.update", { ns: "financialAid" })}
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onClose("")}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};
export default AddEntranceExit; 
