import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Card, CardContent,  Button } from '@material-ui/core';
import PopupHeader from '../../../common-page/popupHeader';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import { SelectInput, DateInput } from '../../../../components/Forms';
import { FormGroup, useStyles, ButtonWrapperFilter } from '../../../../styles/common-styles';
import { useTranslation } from 'react-i18next';
import { getApi, postApi } from '../../../../services/apiBase'
import { load_colleges_doc, document_tracking, prospect_document_tracking, load_colleges_doclist } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';
import * as yup from 'yup';
import { admissions_loadDocSequence } from '../../../../services/navigation_folder/admissions'

const LoadDocSequence = ({ setIsloading, setTableRow, module, Dropdown, selectedStudent, selectedDoc, setShowAddData, setLoadSequence }) => {
    const { t } = useTranslation(['translation','tableHeadCells']);
    const headCells = [
        { id: 'group', numeric: true, disablePadding: false, label: t('students.group',{ ns: 'tableHeadCells' })},
        { id: 'docName', numeric: true, disablePadding: false, label: t('students.docName',{ ns: 'tableHeadCells' }) },
        { id: 'docDescription', numeric: true, disablePadding: false, label: t('students.docDescription',{ ns: 'tableHeadCells' })},
        { id: 'defStatus', numeric: true, disablePadding: false, label: t('students.defStatus',{ ns: 'tableHeadCells' }) },
        { id: 'daysBetween', numeric: true, disablePadding: false, label: t('students.daysBetween',{ ns: 'tableHeadCells' })},
        { id: 'activeFlag', numeric: true, disablePadding: false, label: t('students.activeFlag',{ ns: 'tableHeadCells' }) },
        { id: 'internal', numeric: true, disablePadding: false, label: t('students.internal',{ ns: 'tableHeadCells' }) },
    ];    
    const [loadCollegesRow, setLoadCollegesRow] = useState([])
    const [ldLoading, setldLoading] = useState(false);
    const classess = useStyles();
    const username = localStorage.getItem("username");
    const validationSchema = yup.object({
        documentDate: yup
            .date()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.documentDate') }))
            .nullable()
            .max(new Date('12/12/2100')) // date max range 2100
            .min(new Date('12/12/2000'))
            .typeError(''),
        documentGrp: yup.string().required(t('validationMessages.isRequired', { what: t('commonLabel.documentGroup') }))
    });
    const formik = useFormik({
        initialValues: {
            documentGrp: '',
            documentDate: new Date(),
        },
        validationSchema,
        onSubmit: (values) => {
            
            let payloadRequest = [];
            if (loadCollegesRow.length > 0) {
                setIsloading(true);
                loadCollegesRow.map((row) => {

                    const addDaysBetweenToDate = (date, daysToAdd) => {
                        var newDate = new Date(date);
                        newDate.setDate(newDate.getDate() + daysToAdd);
                        return newDate;
                    }

                    let payload =
                    {
                        "docTrackId": 0,
                        "studentUid": selectedStudent.prospectId,
                        "docDate": addDaysBetweenToDate(values.documentDate, row.daysBetween),
                        "docNameId": row.docNameId,
                        "docDescription": row.docDescription,
                        "docStatusId": row.defStatusId,
                        "comments": "",
                        "reference": "",
                        "compDate": null,
                        "userDefinedFieldId": 0,
                        "finAidYearSeq": 0,
                        "docGroupID": row.groupId,
                        "locationId": module.moduleId,
                        "admissionGpagroupId": 0,
                        "internal": row.internal,
                        "isProspectDoc": true,
                        "userId": username
                    }
                    payloadRequest.push(payload);
                })
                postApi(`${document_tracking}?requestFrom=DOCSEQ`, payloadRequest).then((res) => {
                    onCancel();
                    getApi(`${prospect_document_tracking}/${selectedStudent.prospectId}/${-1}`).then((resp) => {
                        setTableRow(resp.data.data);
                        setIsloading(false);
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'))
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
    })
    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    },[])
    useEffect(() => {
        if (formik.values.documentGrp) {
            setldLoading(true);
            getApi(`${load_colleges_doclist}/${formik.values.documentGrp}`).then((resp) => {
                setLoadCollegesRow(resp.data.data);
                setldLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [formik.values.documentGrp])
    const onCancel = () => {
        setLoadSequence(false)
        setShowAddData(true)
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    }
    return (
        <>
            <PopupHeader profileData={{profileName: selectedStudent.prospectName, profileId: selectedStudent.prospectId, Id: true }} title={t('commonLabel.loadDocumenSequence')} onClose={onCancel} navigationList={admissions_loadDocSequence} />

            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput mandatory={true} isDoubled label={t('commonLabel.documentGroup')} labelId={"documentGrp"} formikValue={formik.values.documentGrp} formikChange={formik.handleChange}
                                errors={formik.errors.documentGrp}
                                touched={formik.touched.documentGrp}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentGroup }}
                            />
                            <DateInput isDoubled label={t('commonLabel.documentDate')} labelId={"documentDate"} formik={formik} mandatory={true} errors={formik.errors.documentDate} touched={formik.touched.documentDate} defaultValue={formik.values.documentDate} />
                        </FormGroup>
                    </CardContent>
                    <Card>
                        <CardContent>
                            <DefaultTable trackingLabel={"loadDocSequence"} isLoading={ldLoading} headCells={headCells} rows={loadCollegesRow} isLookup={true} pagination={true} />
                        </CardContent>
                    </Card>

                    <CardContent>
                        <div style={{ display: 'flex' }}>
                            <ButtonWrapperFilter className="activityButton">
                                <Button aria-label={'Save'} className='BgYellowOrange' variant="contained" size="large" type="submit">
                                    {t('buttonLabel.ok')}
                                </Button>
                                <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={onCancel}>
                                    {t('buttonLabel.cancel')}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    )
}

export default LoadDocSequence;