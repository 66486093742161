import { GET_CRITERIA, GET_MULTI_STUDENT, GET_REPORTS_DATA, GET_STUDENT_STATUS } from "./types";

const initialState = {
    formValues: {
        criteria: "",
        multiStudent: "",
        studentStatus: "",
        reportData: ""
    },
};
const awardLetterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERIA:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    criteria: payload
                }
            }
        case GET_MULTI_STUDENT:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    multiStudent: payload
                }
            }
        case GET_STUDENT_STATUS:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    studentStatus: payload
                }
            }
        case GET_REPORTS_DATA:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    reportData: payload
                }
            }

        default:
            return state;
    }
};
export default awardLetterReducer;