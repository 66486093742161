import React, { useState, useEffect } from 'react';
import PopupHeader from '../../pages/common-page/popupHeader';
import { Button, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import { FormGroup, ButtonWrapperFilter, useStyles } from '../../styles/common-styles'
import DefaultTable from '../../utils/Table/DefaultTable';
import { TextInput, SelectGroupInput } from '../Forms';
import { getApi, postApi } from '../../services/apiBase';
import { student_lookup, student_dropdown } from '../../services/apiURL';
import { useSelector, useDispatch } from "react-redux";
import { searchDataSelector } from '../../redux/common/search-data/selectors';
import { getStudentLookup } from '../../redux/common/search-data/action';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { search_student_lookup } from '../../services/navigation_folder/admissions';
import GenericModal from '../../components/Modal/GenericModal';

const SearchStudent = ({ onLookupClose, setStudent, selectedRow }) => {
    const { t } = useTranslation(['translation', 'tableHeadCells', 'registration']);
    const [alertPopup, setAlertPopup] = useState(false);
    const [selectedRowValue, setSelectedRow] = useState('');
    const [highSchool] = useState([
        { id: 'studentUid', numeric: true, disablePadding: false, label: t('students.studentUId', { ns: 'tableHeadCells' }) },
        { id: 'studentId', numeric: true, disablePadding: false, label: t('students.studentId', { ns: 'tableHeadCells' }) },
        { id: 'lastName', numeric: true, disablePadding: false, label: t('students.lastName', { ns: 'tableHeadCells' }) },
        { id: 'firstName', numeric: true, disablePadding: false, label: t('students.firstName', { ns: 'tableHeadCells' }) },
        { id: 'middleName', numeric: true, disablePadding: false, label: t('students.middleName', { ns: 'tableHeadCells' }) },
        { id: 'preferredName', numeric: true, disablePadding: false, label: t('students.preferredName', { ns: 'tableHeadCells' }) },
        { id: 'maidenName', numeric: true, disablePadding: false, label: t('students.maidenName', { ns: 'tableHeadCells' }) },
        { id: 'studentSsn', numeric: true, disablePadding: false, label: t('students.studentSsn', { ns: 'tableHeadCells' }) },
        { id: 'accessCampus', numeric: true, disablePadding: false, label: t('students.accessCampus', { ns: 'tableHeadCells' }) },
        { id: 'billingAllClear', numeric: true, disablePadding: false, label: t('students.billingAllClear', { ns: 'tableHeadCells' }) },
        { id: 'academicAlert', numeric: true, disablePadding: false, label: t('students.academicAlert', { ns: 'tableHeadCells' }) },
        { id: 'businessHold', numeric: true, disablePadding: false, label: t('students.businessHold', { ns: 'tableHeadCells' }) },
        { id: 'expCohort', numeric: true, disablePadding: false, label: t('students.expCohort', { ns: 'tableHeadCells' }) },
        { id: 'expCohortId', numeric: true, disablePadding: false, label: t('students.expCohortId', { ns: 'tableHeadCells' }) },
        { id: 'campusId', numeric: true, disablePadding: false, label: t('students.campusId', { ns: 'tableHeadCells' }) },
        { id: 'nationalId', numeric: true, disablePadding: false, label: t('students.nationalId', { ns: 'tableHeadCells' }) },
        { id: 'colorCode', numeric: true, disablePadding: false, label: t('students.colorCode', { ns: 'tableHeadCells' }) },
    ])
    const classess = useStyles();
    //Getting contact details
    const { allData } = useSelector(searchDataSelector);
    const [tableRow, setTableRow] = useState([])
    const dispatch = useDispatch();
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        setTableRow(allData.studentLookup);
    }, [allData.studentLookup])

    const [Dropdown, setDropdown] = useState({
        "cohortRefs": []
    })
    //API Call for dropdown
    useEffect(() => {
        getApi(student_dropdown).then((resp) => {
            const { cohortRefs } = resp.data.data
            setDropdown({
                ...Dropdown,
                cohortRefs: cohortRefs
            })
        }).catch((err) => {
            console.log(err);
        })
        return () => {
            dispatch(getStudentLookup([]))
        }
    }, [])

    //Getting Prospect Id
    const history = useHistory();
    useEffect(() => {
        if (history.location.state) {
            const { studentUID } = history.location.state.selectedRow
            formik.setFieldValue('studentUId', studentUID);
        }
    }, [])
    const formik = useFormik({
        initialValues: {
            "studentId": "",
            "lastName": "",
            "firstName": "",
            "maidenName": "",
            "nationalId": "",
            "expCohortId": 0,
            "studentUId": 0
        },
        onSubmit: (values) => {
            const payloadRequest = {
                ...values
            }
            setIsloading(true);
            postApi(`${student_lookup}`, payloadRequest).then((resp) => {
                const { isSuccess, data } = resp.data
                if (isSuccess) {
                    setIsloading(false);
                    dispatch(getStudentLookup(data))
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    });
    const onBack = (value) => {
        onLookupClose(value)

    }
    const handleRowSelect = (value) => {
        
        if (value.businessHold >0) {
            setAlertPopup(true)
        }
       
        else {
            onBack(false)
            value && setStudent(value)
        }
        setSelectedRow(value)
    }
    const onButton = (value) => {
        onBack(false)
        selectedRowValue && setStudent(selectedRowValue)
    }
    React.useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
        setAlertPopup(false);
    }, [])
    return (
        <>
            {(alertPopup) && <GenericModal
                title={t('commonLabel.studenthasBusinesshold')}
                open={alertPopup}
                onClose={setAlertPopup}
                buttonText={t('buttonLabel.ok')}
                onButtonPress={onButton}

            />}
            <PopupHeader title={t('transferLabel.searchStudent', { ns: 'registration' })} navigationList={search_student_lookup} onClose={onBack} />
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                <Card>
                    <CardContent>
                        <FormGroup >

                            <TextInput label={t('studentLabel.studentId')} labelId={'studentId'} formikValue={formik.values.studentId} formikChange={formik.handleChange} />
                            {/* <TextInput label={t('studentLabel.studentSSN')} labelId={'ssn'} formikValue={formik.values.ssn} maxlength={'11'} formikChange={formik.handleChange} /> */}
                            <TextInput label={t('commonLabel.nationalId')} maxlength={'10'} labelId={"nationalId"} formikValue={formik.values.nationalId} formikChange={formik.handleChange} />

                        </FormGroup>
                        <FormGroup>
                            <TextInput label={t('commonLabel.lastName')} maxlength={'60'} labelId={"lastName"} formikValue={formik.values.lastName} formikChange={formik.handleChange} />
                            <TextInput label={t('commonLabel.firstName')} maxlength={'60'} labelId={"firstName"} formikValue={formik.values.firstName} formikChange={formik.handleChange} />
                            <TextInput label={t('commonLabel.maidenName')} maxlength={'60'} labelId={"maidenName"} formikValue={formik.values.maidenName} formikChange={formik.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <SelectGroupInput label={t('commonLabel.expcohort')} labelId={'expCohortId'} formikValue={formik.values.expCohortId} formikChange={formik.handleChange} dropdownData={{ optionLists: Dropdown.cohortRefs, key: 'displayText', value: 'uniqueId', groupLabel: 'activeFlag', groupOne: 'Active', groupTwo: 'Inactive' }} />
                        </FormGroup>
                        <ButtonWrapperFilter>
                            <Button className='BgYellowOrange search' variant="contained" size="large" type="submit" >
                                {t('buttonLabel.search')}
                            </Button>
                            <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={e => { formik.resetForm(); dispatch(getStudentLookup([])) }}>
                                {t('buttonLabel.reset')}
                            </Button>
                            <Button className="BgLightBlue search" variant="contained" size="large" onClick={() => onBack('')}>
                                {t('buttonLabel.cancel')}
                            </Button>
                        </ButtonWrapperFilter>
                    </CardContent>
                </Card>
            </form>
            <DefaultTable trackingLabel={"searchStudent"} headCells={highSchool} rows={tableRow} pagination={true} isLookup={true} onRowSelect={handleRowSelect} isLoading={isLoading} />
        </>
    )
}

export default SearchStudent;