
/**
 * Formats the value in form of (111) 111-1111
 * @param {boolean} useSymbol - format with formatphonenumber symbol
 * @returns {Number}
 */
export const formatSSN = (ssn) => {
    if (ssn) {
        let removeHiphens = ssn.includes('-') ? ssn.replace(/-/g, '') : ssn;
        let filteredNumber = ('' + removeHiphens).replace(/\D/g, '');
        let formatedSSN = filteredNumber.replace(/^(\d{3})(\d{2})(\d{4}).*/, "$1-$2-$3");
        return formatedSSN;
    }


    return "";
}

/**
 * Formats the value as per country code
 * @param {string, pattern} useSymbol - format with regex
 * @returns {string}
 */
export const formatSSNVisibility = (ssn, pattern) => {
    if (ssn) {
        if (pattern.length > 0 && ssn.length > 8) {
            let removeHiphens = ssn.includes('-') ? ssn.replace(/-/g, '') : ssn;
            let filteredNumber = ('' + removeHiphens).replace(/[^\d*]/g, '');
            let formatedSSN = filteredNumber.substring(0, pattern[0]) + '-' + filteredNumber.substring(pattern[0], pattern[1]) + '-' + filteredNumber.substring(pattern[1], pattern[2]);
            return formatedSSN;
        } else {
            return ssn;
        }
    }
    return "";
}

/**
 * convert (111) 111-1111 to 1111111111
 * @param {string} useSymbol - format with formPhoneNumber symbol
 * @returns {String}
 */
export const formSSNNumber = (value) => {
    return ((value).replace(/-/g, '')).toString();
}