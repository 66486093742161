import { SAVE_CRITERIA_SEARCH, RESET_CRITERIA_MODULE_DATA } from './types'

const initialState = {
    criteriaTab: {
        initialMajor: [],
        accessCampus: [],
        term: [],
        admissionsCounselor: [],
        activityDateFrom: null,
        activityDateTo: null,
        applicantStatus: [],
        applicantEvent: [],
        initialStatus: [],
        activityEvent: [],
        addressType: 287,
        cancelled: 0,
    },
};

const criteriReportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_CRITERIA_SEARCH:
            return {
                ...state,
                criteriaTab: payload
            };
        case RESET_CRITERIA_MODULE_DATA:
            return initialState;

        default:
            return state;
    }
};

export default criteriReportReducer;