import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_studentmpn } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { getApi, postApi, putApi } from '../../../../services/apiBase';
import { mpn_select_items, save_mpn, update_mpn } from "../../../../services/apiURL";
import { toastMessage } from '../../../../utils/Message/toasts';
import { useSelector } from "react-redux";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";


const AddStudentMPN = ({ onClose, selectedSelected, selectedCODRow, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();

    const initialValues = {
        addStudentMasterPromissoryNote: "",
        updateStudentMasterPromissoryNote: "",
        indicators: "",
        codAwardTypes: "",
        mpnID: "",
        statusCodes: "",
        mpnLinkIndicator: false,
        mpnExpirationDate: null,
    };

    const [Dropdown, setDropdown] = useState({
        "indicators":[],
        "statusCodes":[],
        "codAwardTypes":[]
    })
    const [formValues, setFormValues] = useState(initialValues);

   
    const formik = useFormik({
        initialValues: formValues || initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payloadRequest =
                {
                    "ownerUID": selectedSelected.studentUID,
                    "mpnIndicator": values.indicators,
                    "mpnid": values.mpnID,
                    "mpnStatusCode": values.statusCodes,
                    "mpnLinkIndicator": values.mpnLinkIndicator,
                    "mpnExpDate": values.mpnExpirationDate,
                    "codAwardType": values.codAwardTypes
                }
        if (selectedCODRow) {
            putApi((`${update_mpn}/${selectedCODRow.id}`), payloadRequest).then((res) => {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                onClose(false)
            }).catch((err) => {
                console.log(err)
            })
        }
        else {
            postApi(save_mpn, payloadRequest).then((res) => {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
                onClose(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    });


    React.useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
    }, []);

 
    useEffect(() => {
        getApi(mpn_select_items).then((resp) => {
          const { indicators, codAwardTypes, statusCodes } = resp.data.data;
          setDropdown({
            ...Dropdown,
              "indicators": indicators,
              "codAwardTypes":codAwardTypes ? codAwardTypes:[],
              "statusCodes":statusCodes,    
          })
          }).catch((err) => {
              console.log(err);
          })
          if (selectedCODRow !== '') {
            setFormValues({
                ...formValues,
                // addStudentMasterPromissoryNote: "",
                // updateStudentMasterPromissoryNote: "",
                indicators:(selectedCODRow.mpnIndicator) ? selectedCODRow.mpnIndicator : "" ,
                codAwardTypes: (selectedCODRow.codAwardType) ? selectedCODRow.codAwardType : "" ,
                mpnID: (selectedCODRow.mpnid) ? selectedCODRow.mpnid : "",
                statusCodes: (selectedCODRow.mpnStatusCode) ? selectedCODRow.mpnStatusCode : "" ,  
                mpnLinkIndicator: (selectedCODRow.mpnLinkIndicator) ? selectedCODRow.mpnLinkIndicator : false,  
                mpnExpirationDate: (selectedCODRow.mpnExpDate) ? selectedCODRow.mpnExpDate : null ,
           })
        }
      
    }, [])
    return (
        <>
            <PopupHeader
                title={
                    selectedCODRow == ""
                        ? (t('commonLabel.AddStudentMPN', { ns: "financialAid" }))
                        : (t('commonLabel.UpdateStudentMPN', { ns: "financialAid" }))
                }
                navigationList={financialAid_studentmpn}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput isDoubled
                                label={(t('commonLabel.indicators', { ns: "financialAid" }))}
                                labelId={"indicators"}
                                formikValue={formik.values.indicators}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.indicators }}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.mpnID', { ns: "financialAid" }))}
                                labelId="mpnID"
                                formikValue={formik.values.mpnID}
                                formikChange={formik.handleChange}
                                maxlength={"23"}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>

                            <SelectInput isDoubled
                                label={(t('commonLabel.statusCodes', { ns: "financialAid" }))}
                                labelId="statusCodes"
                                formikValue={formik.values.statusCodes}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.statusCodes }}
                            />
                            <CheckBoxInput isDoubled
                                label={(t('commonLabel.mpnLinkIndicator', { ns: "financialAid" }))}
                                labelId="mpnLinkIndicator"
                                formikValue={formik.values.mpnLinkIndicator}
                                formikChange={formik.handleChange}
                                checked={formik.values.mpnLinkIndicator}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <DateInput isDoubled
                                label={(t('commonLabel.mpnExpirationDate', { ns: "financialAid" }))}
                                labelId="mpnExpirationDate"
                                defaultValue={formik.values.mpnExpirationDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                            <SelectInput isDoubled
                                label={(t('commonLabel.codAwardTypes', { ns: "financialAid" }))}
                                labelId="codAwardTypes"
                                formikValue={formik.values.codAwardTypes}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.codAwardTypes }}
                            />
                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips flag={(selectedCODRow) ? true : false} row={
                                {
                                    insertUserID: selectedCODRow.insertUserID,
                                    insertTime: selectedCODRow.insertTime,
                                    updateUserID: selectedCODRow.updateUserID,
                                    updateTime: selectedCODRow.updateTime
                                }
                            } />
                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={accessFlag}
                                >
                                 {selectedCODRow == ""
                        ? t("buttonLabel.add", { ns: "financialAid" })
                        : t("buttonLabel.update", { ns: "financialAid" })}
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={onClose}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};
export default AddStudentMPN; 
