import React from 'react';
import { FormInputWrapper } from '../../styles/common-styles'
import { FormControl,Checkbox, FormControlLabel, FormHelperText, Tooltip} from '@material-ui/core';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { IndeterminateCheckBox } from '@material-ui/icons';

/**
 * @param {{
 * label: string,
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * disabled:boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * checked: Boolean,
 * addCheckBoxClass: String 'Add custom class to adjust checkbox'
 * }} props 
 */


export const CheckBoxInput = (props) => {
    const { label,indeterminate, labelId, formikValue, formikChange, displayDynamicValue, isDoubled, errors, touched, addCheckBoxClass, disabled, checked, customStyle,isTripple, title, onTooltip} = props;
    return (
            <FormInputWrapper style={customStyle} className={isDoubled ? 'doubled' : null} isTripple={isTripple ? isTripple : null} isDoubled={isDoubled ? isDoubled : null}>
                <div className={(disabled) ? `disabled ${addCheckBoxClass}` : addCheckBoxClass} >
                <FormControl component="fieldset" className="checkboxInput pointeEvents"> 
                    <Tooltip title={onTooltip ? onTooltip : ""} arrow >           
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankOutlinedIcon fontSize="large" />}
                                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                                    name={labelId}
                                    color="primary"
                                    disabled={disabled}
                                    checked={checked}
                                    onChange={formikChange} value={formikValue}
                                    title={title}
                                    indeterminate={indeterminate}
                                    indeterminateIcon={<IndeterminateCheckBox fontSize='large' />}
                                />
                            }
                            label={label}
                        />
                    </Tooltip>
                </FormControl>
                {displayDynamicValue && <span className={'displayValue'}>{displayDynamicValue}</span>}
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
                </div>
            </FormInputWrapper>
    )
}

export const CheckBoxInputFilter = (props) => {
    const { label, labelId, formikValue, formikChange, isDoubled, errors, touched, checked,disabled } = props;
    return (
        <FormInputWrapper isDoubled={isDoubled ? isDoubled : null}>
            <FormControl component="fieldset" class="pointeEvents">
                <FormControlLabel 
                    control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankOutlinedIcon fontSize="large" />}
                            checkedIcon={<CheckBoxIcon fontSize="large" />}
                            name={labelId}
                            color="primary"
                            onChange={formikChange} value={formikValue}
                            checked={checked}
                            disabled={disabled}
                        />
                    }
                    label={label}
                />
            </FormControl>
            {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
        </FormInputWrapper>
    )
}