export const ADD_STUDENT_LIST = 'ADD_STUDENT_LIST';
export const ADD_COURSE_LIST = 'ADD_COURSE_LIST';
export const ADD_FILTERED_COURSE_LIST = 'ADD_FILTERED_COURSE_LIST';
export const ADD_SELECTED_COURSE_LIST = 'ADD_SELECTED_COURSE_LIST';
export const ADD_FROM_COURSE_LIST = 'ADD_FROM_COURSE_LIST';
export const ADD_STUDENT_SELECTION_LIST = 'ADD_STUDENT_SELECTION_LIST';
export const ADD_TO_COURSE_LIST = 'ADD_TO_COURSE_LIST';
export const ADD_DROP_COURSE_LIST = 'ADD_DROP_COURSE_LIST';
export const ADD_DROP_STUDENT_LIST = 'ADD_DROP_STUDENT_LIST';
export const ADD_DROP_COURSE_COURSE_LIST = 'ADD_DROP_COURSE_COURSE_LIST';
export const ADD_DROP_COURSE_STUDENT_LIST = 'ADD_DROP_COURSE_STUDENT_LIST';   
export const ADD_VIEW_STUDENT_LIST = 'ADD_VIEW_STUDENT_LIST';
export const ADD_VIEW_COURSE_LIST = 'ADD_VIEW_COURSE_LIST';
export const UPDATE_VIEW_TERM = 'UPDATE_VIEW_TERM';
export const UPDATE_REGISTER_TERM = 'UPDATE_REGISTER_TERM';
export const UPDATE_MOVE_TERM = 'UPDATE_MOVE_TERM';
export const UPDATE_DROP_COURSE_TERM = 'UPDATE_DROP_COURSE_TERM';
export const UPDATE_DROP_STUDENT_TERM = 'UPDATE_DROP_STUDENT_TERM'; 
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_MOVE_SELECT_ALL = 'UPDATE_MOVE_SELECT_ALL';
export const UPDATE_DROP_SELECT_ALL_STUDENTS = "UPDATE_DROP_SELECT_ALL_STUDENTS"
export const UPDATE_DROP_SELECT_ALL_COURSES = "UPDATE_DROP_SELECT_ALL_COURSES"
export const UPDATE_DROP_STUDENT_DATE = "UPDATE_DROP_STUDENT_DATE"
export const UPDATE_DROP_COURSE_DATE = "UPDATE_DROP_COURSE_DATE"