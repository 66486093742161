import React, { useState, useEffect } from "react";

import { Button, Card, CardContent, TextareaAutosize } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput, RadioInput } from "../../../../components/Forms";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles,
    Splitter,
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { financialAid_isir } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { isir_financial } from "../../../../services/apiURL";
import { postApi } from "../../../../services/apiBase";
import { toastMessage } from "../../../../utils/Message/toasts";
import currencyPipe, { formNumber, parseNumber } from "../../../../helper/currency";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import GenericModal from "../../../../components/Modal/GenericModal";

const AddISIRSAIDetails = ({ onClose, selectedRow1, dropdown, FinancialStatusUID, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();

    const transform = (val, isSymbol = true) => {
        return currencyPipe(val, { symbol: isSymbol ? "$" : "", decimal: '.', separator: ',', precision: 2 }).format();
    }
    const initCurrencyFields = (value = 0) => {
        return transform(parseNumber(value))
    }
    const onSwipeCurrency = (e, isSymbol = true) => {
        const { name, value } = e.target;
        const amount = transform(parseNumber(value), isSymbol);
        formik.setFieldValue(name, amount);
    }
    const initialValues = {
        "financialISARUID": 0,
        "applicationRecvDT": null,
        "verificationCode": "",
        "applicationTypeUID": 0,
        "housingStatusID": 0,
        "dependentStatus": "Dependent",
        "primaryEFC": '',
        "secondaryEFC": '',
        altPrimaryEFC10Months: '',
        "altEFCMonth1": 0,
        "altEFCMonth2": 0,
        "altEFCMonth3": 0,
        "altEFCMonth4": 0,
        "altEFCMonth5": 0,
        "altEFCMonth6": 0,
        "altEFCMonth7": 0,
        "altEFCMonth8": 0,
        "altEFCMonth9": 0,
        "altEFCMonth10": 0,
        "altEFCMonth11": 0,
        "altEFCMonth12": 0,
        "transactionID": 0,
        "numFamilyMembers": 0,
        "nsldsPellLifetimeLimit": "",
        "nsldsPellLifetimeEligibilityUsed": "",
        "nsldsSubsidizedLoanEligibilityUsed": "",
        "totalFisapIncome": initCurrencyFields(0),
        "taxedNonTaxedIncome": initCurrencyFields(0),
        "parentFamilyMembers": 0,
        "income": initCurrencyFields(0),
        "parentTaxNonTaxedIncome": initCurrencyFields(0),
        "parentGrossIncome": initCurrencyFields(0),
        "totalIncome": initCurrencyFields(0),
        "descretionaryNetWorth": initCurrencyFields(0),
        "availableIncome": initCurrencyFields(0),
        "allowanceAgainstTotalIncome": initCurrencyFields(0),
        "stateTaxAllowance": initCurrencyFields(0),
        "employAllowance": initCurrencyFields(0),
        "incomeProtectAllowance": initCurrencyFields(0),
        "assetProtectAllowance": initCurrencyFields(0),
        "contributionFromAssets": initCurrencyFields(0),
        "adjustedAvailableIncome": initCurrencyFields(0),
        "totalParentContribution": initCurrencyFields(0),
        "parentContribution": initCurrencyFields(0),
        "studentIncomeContribution": initCurrencyFields(0),
        "studentAssetContribution": initCurrencyFields(0),
        "totalStudentContribution": initCurrencyFields(0),
        "priorDebt": initCurrencyFields(0),
        "commentCodes": "",
        "enrollmentStatusID": 0,
        "interestInLoans": 0,
        //Radios
        "automaticZeroEFC": "false",
        "enrolledGradProf": "false",
        "previousBachelorsDegree": "false",
        "birthPrior": "false",
        //Checkboxes
        "activeFlag": false,
        "lock": false,
        "pellEligibility": false,
        "nsldsSubsidizedUsageLimitAppliesFlag": "",
        "parentFoodStampBenifits": false,
        "parentFreeRedLunch": false,
        "parentSupSecurityIncome": false,
        "parentTANF": false,
        "parentWIC": false,
        //This is the filter to package dropdown value in isir.js
        financialStatusUID: FinancialStatusUID,
        sai: 0,
        provisionalSAI: 0,
        saiFormula: "",
        dependencyModel: "",
        studentConsSig: "",
        studentSpouseConsSig: "",
        studentConsSigDate: null,
        studentSpouseConsSigDate: null,
        parentsConsSig: "",
        parentsSpousePartnerConsSig: "",
        parentConsSigDate: null,
        parentsSpousePartnerConsSigDate: null,
        dependentStatus: "Dependent",
    }

    const [formValues, setFormValues] = useState(initialValues)
    const [showPopup, setShowPopup] = useState(false)
    const onClosePopup = () => {
        onSwipeCurrency({
            target: {
                value: 0,
                name: "nsldsPellLifetimeEligibilityUsed"
            }
        }, false)
        setShowPopup(false)
    }
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            const payload = {
                ...values,
                "primaryEFC": values.primaryEFC == '' ? 0 : values.primaryEFC,
                "secondaryEFC": values.secondaryEFC == '' ? 0 : values.secondaryEFC,
                "altEFCMonth1": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth2": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth3": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth4": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth5": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth6": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth7": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth8": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth9": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth10": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth11": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "altEFCMonth12": values.altPrimaryEFC10Months == '' ? 0 : values.altPrimaryEFC10Months,
                "totalFisapIncome": formNumber(values.totalFisapIncome),
                "taxedNonTaxedIncome": formNumber(values.taxedNonTaxedIncome),
                "parentFamilyMembers": (values.parentFamilyMembers),
                "income": formNumber(values.income),
                "parentTaxNonTaxedIncome": formNumber(values.parentTaxNonTaxedIncome),
                "parentGrossIncome": formNumber(values.parentGrossIncome),
                "totalIncome": formNumber(values.totalIncome),
                "descretionaryNetWorth": formNumber(values.descretionaryNetWorth),
                "availableIncome": formNumber(values.availableIncome),
                "allowanceAgainstTotalIncome": formNumber(values.allowanceAgainstTotalIncome),
                "stateTaxAllowance": formNumber(values.stateTaxAllowance),
                "employAllowance": formNumber(values.employAllowance),
                "incomeProtectAllowance": formNumber(values.incomeProtectAllowance),
                "assetProtectAllowance": formNumber(values.assetProtectAllowance),
                "contributionFromAssets": formNumber(values.contributionFromAssets),
                "adjustedAvailableIncome": formNumber(values.adjustedAvailableIncome),
                "totalParentContribution": formNumber(values.totalParentContribution),
                "parentContribution": formNumber(values.parentContribution),
                "studentIncomeContribution": formNumber(values.studentIncomeContribution),
                "studentAssetContribution": formNumber(values.studentAssetContribution),
                "totalStudentContribution": formNumber(values.totalStudentContribution),
                "priorDebt": formNumber(values.priorDebt),
                //Radios
                "automaticZeroEFC": values.automaticZeroEFC == "true" ? "Yes" : "No",
                "enrolledGradProf": values.enrolledGradProf == "true" ? "Yes" : "No",
                "previousBachelorsDegree": values.previousBachelorsDegree == "true" ? "Yes" : "No",
                "birthPrior": values.birthPrior == "true" ? "Yes" : "No",
                //checkboxes
                "activeFlag": values.activeFlag,
                "lock": values.lock,
                "nsldsSubsidizedUsageLimitAppliesFlag": values.nsldsSubsidizedUsageLimitAppliesFlag == true ? 'Y' : '0',
                "pellEligibility": values.pellEligibility,
                "parentFoodStampBenifits": values.parentFoodStampBenifits,
                "parentFreeRedLunch": values.parentFreeRedLunch,
                "parentSupSecurityIncome": values.parentSupSecurityIncome,
                "parentTANF": values.parentTANF,
                "parentWIC": values.parentWIC,
                "nsldsPellLifetimeEligibilityUsed": formNumber(values.nsldsPellLifetimeEligibilityUsed).toString(),
                // "sai": parseInt(values.sai),
                // "provisionalSAI": parseInt(values.provisionalSAI),
                // "saiFormula": values.saiFormula,
                // "dependencyModel": values.dependencyModel,
                // "studentConsent": values.studentConsSig,
                // "studentSpouseConsent": values.studentSpouseConsSig,
                // "studentConsentDate": values.studentConsSigDate,
                // "studentConsentDate": values.studentSpouseConsSigDate,
                // "parentConsent": values.parentsConsSig,
                // "parentSpousePartnerConsent": values.parentsSpousePartnerConsSig,
                // "parentSpousePartnerConsentDate": values.parentsSpousePartnerConsSigDate,
                // "parentConsentDate": values.parentConsSigDate
            }

            if (FinancialStatusUID == -1) {
                for (let v of dropdown.filterToPackageList) {
                    if (v.uniqueId !== -1) {
                        payload.financialStatusUID = v.uniqueId
                        break
                    }
                }
            }
            postApi(isir_financial, payload).then(resp => {
                if (selectedRow1) {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    payload.nsldsSubsidizedUsageLimitAppliesFlag = payload.nsldsSubsidizedUsageLimitAppliesFlag == 'Y' ? true : false
                    DefaultTable.updateSelectedRow("batchesBilling", payload)
                } else {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                }
                onClose(false);
            })
        }
    });

    useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
        if (selectedRow1) {
            setFormValues({
                ...selectedRow1,
                altPrimaryEFC10Months: selectedRow1.altEFCMonth1,
                "automaticZeroEFC": selectedRow1.automaticZeroEFC == "Yes" ? "true" : "false",
                "enrolledGradProf": selectedRow1.enrolledGradProf == "Yes" ? "true" : "false",
                "previousBachelorsDegree": selectedRow1.previousBachelorsDegree == "Yes" ? "true" : "false",
                "birthPrior": selectedRow1.birthPrior == "Yes" ? "true" : "false",
                "totalFisapIncome": transform(selectedRow1.totalFisapIncome),
                "taxedNonTaxedIncome": transform(selectedRow1.taxedNonTaxedIncome),
                "parentFamilyMembers": selectedRow1.parentFamilyMembers,
                "income": transform(selectedRow1.income),
                "parentTaxNonTaxedIncome": transform(selectedRow1.parentTaxNonTaxedIncome),
                "parentGrossIncome": transform(selectedRow1.parentGrossIncome),
                "totalIncome": transform(selectedRow1.totalIncome),
                "descretionaryNetWorth": transform(selectedRow1.descretionaryNetWorth),
                "availableIncome": transform(selectedRow1.availableIncome),
                "allowanceAgainstTotalIncome": transform(selectedRow1.allowanceAgainstTotalIncome),
                "stateTaxAllowance": transform(selectedRow1.stateTaxAllowance),
                "employAllowance": transform(selectedRow1.employAllowance),
                "incomeProtectAllowance": transform(selectedRow1.incomeProtectAllowance),
                "assetProtectAllowance": transform(selectedRow1.assetProtectAllowance),
                "contributionFromAssets": transform(selectedRow1.contributionFromAssets),
                "adjustedAvailableIncome": transform(selectedRow1.adjustedAvailableIncome),
                "totalParentContribution": transform(selectedRow1.totalParentContribution),
                "parentContribution": transform(selectedRow1.parentContribution),
                "studentIncomeContribution": transform(selectedRow1.studentIncomeContribution),
                "studentAssetContribution": transform(selectedRow1.studentAssetContribution),
                "totalStudentContribution": transform(selectedRow1.totalStudentContribution),
                "priorDebt": transform(selectedRow1.priorDebt),
                "nsldsPellLifetimeEligibilityUsed": transform(selectedRow1.nsldsPellLifetimeEligibilityUsed, false)
            })
        }
    }, []);

    return (
        <>
            <GenericModal
                open={showPopup}
                onClose={onClosePopup}
                title={"Value should be between 0 and 9999"}
            />
            <PopupHeader
                title={
                    selectedRow1 == ""
                        ? (t('commonLabel.addISIRDetails', { ns: "financialAid" }))
                        : (t('commonLabel.updateISIRDetails', { ns: "financialAid" }))
                }
                navigationList={financialAid_isir}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <DateInput
                                label={(t('commonLabel.applicationReceivedDate', { ns: "financialAid" }))}
                                labelId={"applicationRecvDT"}
                                defaultValue={formik.values.applicationRecvDT}
                                formik={formik}
                                disabled={accessFlag}
                            />
                            <SelectInput
                                label={(t('commonLabel.applicationType', { ns: "financialAid" }))}
                                labelId={"applicationTypeUID"}
                                formikValue={formik.values.applicationTypeUID}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                dropdownData={{
                                    key: "displayText",
                                    value: "uniqueId",
                                    optionLists: dropdown.applicationTypeList,
                                }}
                            />
                            <TextInput
                                label={(t('commonLabel.verificationCode', { ns: "financialAid" }))}
                                labelId={"verificationCode"}
                                formikValue={formik.values.verificationCode}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                maxlength={'3'}
                            />
                            <SelectInput
                                dropdownData={{
                                    key: "displayText",
                                    value: "uniqueId",
                                    optionLists: dropdown.housingStatusList,
                                }}
                                label={(t('commonLabel.housingStatus', { ns: "financialAid" }))}
                                labelId={"housingStatusID"}
                                disabled={accessFlag}
                                formikValue={formik.values.housingStatusID}
                                formikChange={formik.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={(t('commonLabel.sai', { ns: "financialAid" }))}
                                labelId="sai"
                                disabled={accessFlag}
                                formikValue={formik.values.sai}
                                formikChange={formik.handleChange}
                            />
                            <TextInput
                                label={(t('commonLabel.provisionalSAI', { ns: "financialAid" }))}
                                labelId="provisionalSAI"
                                disabled={accessFlag}
                                formikValue={formik.values.provisionalSAI}
                                formikChange={formik.handleChange}
                                onlyNumeric={true}
                            />
                            <SelectInput
                                 dropdownData={[]}
                                label={(t('commonLabel.dependentStatus', { ns: "financialAid" }))}
                                labelId="dependentStatus"
                                disabled={accessFlag}
                                formikValue={formik.values.dependentStatus}
                                formikChange={formik.handleChange}
                            />
                             <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.saiFormula', { ns: "financialAid" }))}
                                labelId="saiFormula"
                                disabled={accessFlag}
                                formikValue={formik.values.saiFormula}
                                formikChange={formik.handleChange}
                            />
                           
                        </FormGroup>
                        <FormGroup>
                            <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.dependencyModel', { ns: "financialAid" }))}
                                labelId="dependencyModel"
                                disabled={accessFlag}
                                formikValue={formik.values.dependencyModel}
                                formikChange={formik.handleChange}
                            />
                            <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.studentConsSig', { ns: "financialAid" }))}
                                labelId="studentConsAndSig"
                                disabled={accessFlag}
                                formikValue={formik.values.studentConsAndSig}
                                formikChange={formik.handleChange}
                            />
                            <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.studentSpouseConsSig', { ns: "financialAid" }))}
                                labelId="studentSpouseConsAndSig"
                                disabled={accessFlag}
                                formikValue={formik.values.studentSpouseConsAndSig}
                                formikChange={formik.handleChange}
                            />
                            <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.parentConsSig', { ns: "financialAid" }))}
                                labelId="parentConsAndSig"
                                disabled={accessFlag}
                                formikValue={formik.values.parentConsAndSig}
                                formikChange={formik.handleChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <SelectInput
                                dropdownData={[]}
                                label={(t('commonLabel.parentsSpousePartnerConsSig', { ns: "financialAid" }))}
                                labelId="parentsSpousePartnerConsAndSig"
                                disabled={accessFlag}
                                formikValue={formik.values.parentsSpousePartnerConsAndSig}
                                formikChange={formik.handleChange}
                            />
                             <DateInput
                                label={(t('commonLabel.studentConsSigDate', { ns: "financialAid" }))}
                                labelId={"studentConsAndSigDate"}
                                defaultValue={formik.values.studentConsAndSigDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                             <DateInput
                                label={(t('commonLabel.studentSpouseConsSigDate', { ns: "financialAid" }))}
                                labelId={"studentSpouseConsAndSigDate"}
                                defaultValue={formik.values.studentSpouseConsAndSigDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                             <DateInput
                                label={(t('commonLabel.parentConsSigDate', { ns: "financialAid" }))}
                                labelId={"parentConsAndSigDate"}
                                defaultValue={formik.values.parentConsAndSigDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <DateInput
                                label={(t('commonLabel.parentSpousePartnerConsSigDate', { ns: "financialAid" }))}
                                labelId={"parentSpousePartnerConsAndSigDate"}
                                defaultValue={formik.values.parentSpousePartnerConsAndSigDate}
                                formik={formik}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <RadioInput isDoubled
                                label={(t('commonLabel.prevBachelorsDegree', { ns: "financialAid" }))}
                                labelId={"previousBachelorsDegree"}
                                formikValue={formik.values.previousBachelorsDegree}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                list={[{
                                    label: t('buttonLabel.yes', { ns: "financialAid" }),
                                    value: "true",
                                },
                                {
                                    label: t('buttonLabel.no', { ns: "financialAid" }),
                                    value: "false",
                                },
                                ]}
                            />
                            <RadioInput isDoubled
                                label={(t('commonLabel.birthPrior', { ns: "financialAid" }))}
                                labelId={"birthPrior"}
                                disabled={accessFlag}
                                formikValue={formik.values.birthPrior}
                                formikChange={formik.handleChange}
                                list={[{
                                    label: t('buttonLabel.yes', { ns: "financialAid" }),
                                    value: "true",
                                },
                                {
                                    label: t('buttonLabel.no', { ns: "financialAid" }),
                                    value: "false",
                                },
                                ]}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={(t('commonLabel.totalFISAPIncome', { ns: "financialAid" }))}
                                labelId="totalFisapIncome"
                                disabled={accessFlag}
                                formikValue={formik.values.totalFisapIncome}
                                formikChange={formik.handleChange}

                                onBlur={onSwipeCurrency}
                            />
                            <TextInput
                                label={(t('commonLabel.numberofFamilyMembers', { ns: "financialAid" }))}
                                labelId="numFamilyMembers"
                                formikValue={formik.values.numFamilyMembers}
                                formikChange={formik.handleChange}
                                onlyNumeric
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={(t('commonLabel.transactionNumber', { ns: "financialAid" }))}
                                labelId="transactionID"
                                formikValue={formik.values.transactionID}
                                formikChange={formik.handleChange}
                                disabled
                            />
                            <CheckBoxInput
                                label={(t('commonLabel.activeISIRRecord', { ns: "financialAid" }))}
                                labelId={"activeFlag"}
                                formikValue={formik.values.activeFlag}
                                formikChange={formik.handleChange}
                                checked={formik.values.activeFlag}
                                disabled={accessFlag}
                            />

                            <CheckBoxInput
                                label={(t('commonLabel.lock', { ns: "financialAid" }))}
                                labelId={"lock"}
                                formikValue={formik.values.lock}
                                formikChange={formik.handleChange}
                                checked={formik.values.lock}
                                disabled={accessFlag}
                            />


                        </FormGroup>
                        <FormGroup>
                            <CheckBoxInput
                                label={(t('commonLabel.pellEligibility', { ns: "financialAid" }))}
                                labelId={"pellEligibility"}
                                formikValue={formik.values.pellEligibility}
                                formikChange={formik.handleChange}
                                checked={formik.values.pellEligibility}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={(t('commonLabel.pellLifetimeEligibilityUsed', { ns: "financialAid" }))}
                                labelId="nsldsPellLifetimeEligibilityUsed"
                                formikValue={formik.values.nsldsPellLifetimeEligibilityUsed}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                // 
                                onBlur={(e) => formNumber(e.target.value) > 9999.99 ? setShowPopup(true) : onSwipeCurrency(e, false)}
                            />
                            <SelectInput
                                dropdownData={{
                                    key: "displayText",
                                    value: "uniqueId",
                                    optionLists: dropdown.pellLifeTimeList,
                                }}
                                label={(t('commonLabel.pellLifetimeLimit', { ns: "financialAid" }))}
                                labelId="nsldsPellLifetimeLimit"
                                disabled={accessFlag}
                                formikValue={formik.values.nsldsPellLifetimeLimit}
                                formikChange={formik.handleChange}
                            />
                            <CheckBoxInput
                                label={(t('commonLabel.subsidizedUsageEligibilityUsed', { ns: "financialAid" }))}
                                labelId={"nsldsSubsidizedUsageLimitAppliesFlag"}
                                formikValue={formik.values.nsldsSubsidizedUsageLimitAppliesFlag}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                checked={formik.values.nsldsSubsidizedUsageLimitAppliesFlag}
                            />
                            <TextInput
                                label={(t('commonLabel.subsidizedLoanEligibilityUsed', { ns: "financialAid" }))}
                                labelId="nsldsSubsidizedLoanEligibilityUsed"
                                formikValue={formik.values.nsldsSubsidizedLoanEligibilityUsed}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                                maxValue={999.999}
                            />
                        </FormGroup>

                        <Splitter height={"10px"} />

                        <HeaderTitle variant={'subHeading'} headerTitle={(t('commonLabel.incomeInformation', { ns: "financialAid" }))} />

                        <FormGroup>
                            <TextInput
                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.TaxedNonTaxedIncome', { ns: "financialAid" }))}
                                labelId="taxedNonTaxedIncome"
                                formikValue={formik.values.taxedNonTaxedIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput
                                label={(t('commonLabel.parentFamilyMembers', { ns: "financialAid" }))}
                                labelId="parentFamilyMembers"
                                formikValue={formik.values.parentFamilyMembers}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.income', { ns: "financialAid" }))}
                                labelId="income"
                                formikValue={formik.values.income}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.ParentTaxedNonTaxedIncome', { ns: "financialAid" }))}
                                labelId="parentTaxNonTaxedIncome"
                                formikValue={formik.values.parentTaxNonTaxedIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.parentGrossIncome', { ns: "financialAid" }))}
                                labelId="parentGrossIncome"
                                formikValue={formik.values.parentGrossIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.totalIncome', { ns: "financialAid" }))}
                                labelId="totalIncome"
                                formikValue={formik.values.totalIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.discretionaryNetWorth', { ns: "financialAid" }))}
                                labelId="descretionaryNetWorth"
                                formikValue={formik.values.descretionaryNetWorth}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.availableIncome', { ns: "financialAid" }))}
                                labelId="availableIncome"
                                formikValue={formik.values.availableIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.allowanceAgainstTotalInc', { ns: "financialAid" }))}
                                labelId="allowanceAgainstTotalIncome"
                                formikValue={formik.values.allowanceAgainstTotalIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.stateTaxAllowance', { ns: "financialAid" }))}
                                labelId="stateTaxAllowance"
                                formikValue={formik.values.stateTaxAllowance}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.employeeAllowance', { ns: "financialAid" }))}
                                labelId="employAllowance"
                                formikValue={formik.values.employAllowance}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.incomeProtectAllowance', { ns: "financialAid" }))}
                                labelId="incomeProtectAllowance"
                                formikValue={formik.values.incomeProtectAllowance}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>

                        <FormGroup>
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.assetProtectAllowance', { ns: "financialAid" }))}
                                labelId="assetProtectAllowance"
                                formikValue={formik.values.assetProtectAllowance}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.contributionFromAssets', { ns: "financialAid" }))}
                                labelId="contributionFromAssets"
                                formikValue={formik.values.contributionFromAssets}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.adjustedAvailableIncome', { ns: "financialAid" }))}
                                labelId="adjustedAvailableIncome"
                                formikValue={formik.values.adjustedAvailableIncome}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.totalParentContribution', { ns: "financialAid" }))}
                                labelId="totalParentContribution"
                                formikValue={formik.values.totalParentContribution}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.parentContribution', { ns: "financialAid" }))}
                                labelId="parentContribution"
                                formikValue={formik.values.parentContribution}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.studentIncomeContribution', { ns: "financialAid" }))}
                                labelId="studentIncomeContribution"
                                formikValue={formik.values.studentIncomeContribution}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.studentAssetContribution', { ns: "financialAid" }))}
                                labelId="studentAssetContribution"
                                formikValue={formik.values.studentAssetContribution}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.totalStudentContribution', { ns: "financialAid" }))}
                                labelId="totalStudentContribution"
                                formikValue={formik.values.totalStudentContribution}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput

                                onBlur={onSwipeCurrency}
                                label={(t('commonLabel.priorDebt', { ns: "financialAid" }))}
                                labelId="priorDebt"
                                formikValue={formik.values.priorDebt}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}

                            />
                        </FormGroup>
                        <p>{(t('commonLabel.commentCodes', { ns: "financialAid" }))}</p>
                        <Splitter height={"5px"} />

                        <FormGroup>
                            <TextareaAutosize
                                aria-label="minimum height"
                                maxRows={10}
                                minRows={3}
                                maxLength={80}
                                name="commentCodes"
                                onChange={formik.handleChange}
                                value={formik.values.commentCodes}
                                disabled={accessFlag}
                                style={{
                                    width: "100%",
                                    padding: "16px",
                                    boxSizing: "border-box",
                                    height: "auto",
                                    overflow: "auto",
                                }}
                            />
                        </FormGroup>
                        <FormGroup>

                            <SelectInput
                                dropdownData={{
                                    key: "displayText",
                                    value: "uniqueId",
                                    optionLists: dropdown.enrollmentStatusList,
                                }}
                                label={(t('commonLabel.enrollmentStatus', { ns: "financialAid" }))}
                                labelId="enrollmentStatusID"
                                formikValue={formik.values.enrollmentStatusID}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <SelectInput
                                dropdownData={{
                                    key: "displayText",
                                    value: "uniqueId",
                                    optionLists: dropdown.interestedInLoansList,
                                }}
                                label={(t('commonLabel.interestedInLoans', { ns: "financialAid" }))}
                                labelId="interestInLoans"
                                formikValue={formik.values.interestInLoans}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <CheckBoxInput
                                label={(t('commonLabel.parentFoodStampBenefits', { ns: "financialAid" }))}
                                labelId={"parentFoodStampBenifits"}
                                formikValue={formik.values.parentFoodStampBenifits}
                                formikChange={formik.handleChange}
                                checked={formik.values.parentFoodStampBenifits}
                                disabled={accessFlag}
                            />
                            <CheckBoxInput
                                label={(t('commonLabel.parentFreeorReducedPriceLunch', { ns: "financialAid" }))}
                                labelId={"parentFreeRedLunch"}
                                formikValue={formik.values.parentFreeRedLunch}
                                formikChange={formik.handleChange}
                                checked={formik.values.parentFreeRedLunch}
                                disabled={accessFlag}
                            />

                        </FormGroup>
                        <FormGroup>
                            <CheckBoxInput
                                label={(t('commonLabel.parentSupplementalSecurityIncome', { ns: "financialAid" }))}
                                labelId={"parentSupSecurityIncome"}
                                formikValue={formik.values.parentSupSecurityIncome}
                                formikChange={formik.handleChange}
                                checked={formik.values.parentSupSecurityIncome}
                                disabled={accessFlag}
                            />

                            <CheckBoxInput
                                label={(t('commonLabel.parentTANF', { ns: "financialAid" }))}
                                labelId={"parentTANF"}
                                formikValue={formik.values.parentTANF}
                                formikChange={formik.handleChange}
                                checked={formik.values.parentTANF}
                                disabled={accessFlag}
                            />
                            <CheckBoxInput
                                label={(t('commonLabel.parentWIC', { ns: "financialAid" }))}
                                labelId={"parentWIC"}
                                formikValue={formik.values.parentWIC}
                                formikChange={formik.handleChange}
                                checked={formik.values.parentWIC}
                                disabled={accessFlag}
                            />

                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips flag={(selectedRow1 !== '') ? true : false} row={(selectedRow1 !== '') ? {
                                insertUserID: selectedRow1.insertUserID,
                                insertTime: selectedRow1.insertTime,
                                updateUserID: selectedRow1.updateUserID,
                                updateTime: selectedRow1.updateTime
                            } : ''} />
                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={accessFlag}
                                >
                                    {selectedRow1 == "" ? "Add" : "Update"}
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onClose("")}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};
export default AddISIRSAIDetails;  
