import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { FormInputWrapper } from '../../styles/common-styles'
import { InputLabel, Tooltip } from '@material-ui/core';
import { useState } from "react";

export const RichTextEditor = (props) => {
    const { customStyle, textAreaHeight, label, labelId, formikChange, formikValue, onTooltip, disabled } = props;
    const [isTooltip, setTooltip] = useState(true);
    // Quill modules configuration
    const modules = {
        toolbar: [
            [{ 'font': ['Lato,sans-serif'] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    };

    return (
        <Tooltip title={onTooltip && isTooltip ? onTooltip : ""} arrow >
        <FormInputWrapper style={customStyle}>
            <InputLabel htmlFor={labelId}>{label}</InputLabel>
            <ReactQuill
                theme='snow'
                // formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                // modules={modules}
                readOnly={disabled}
                onChange={formikChange}
                value={formikValue}
                onFocus={() => setTooltip(false)}
                onBlur={() => setTooltip(true)}
                style={{ height: textAreaHeight ? textAreaHeight : '100%', backgroundColor: disabled ? '#efefef' : '' }}
            />
        </FormInputWrapper>
        </Tooltip>
    );
};