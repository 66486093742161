import { Button, Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ButtonWrapperFilter, useStyles } from "../../../../styles/common-styles";
import PopupHeader from "../../../common-page/popupHeader";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import ExportComponent from "../../../../utils/Export/ExportComponent";

const ShowISIRReport = ({ onClose, selectedRow }) => {
    const { t } = useTranslation(['financialAid', 'financialAidHeadcells']);
    const classes = useStyles();
    const [customImportFields] = useState([
        { id: 'key', numeric: false, disablePadding: false, label: ""},
        { id: 'value', numeric: false, disablePadding: false, label: "" },
    ])
    const getFile = (U) => {
        var X = new XMLHttpRequest();
        X.open("GET", U, false);
        X.send();
        return X.responseText;
      };

    const handlePrint = () =>{
        const tempData ={
            reportName: "ISIR Full Detail",
            fullDetails: tableRows
        }
        let HTMLString = getFile(`${window.location.origin}/ReportHTML/FinancialAid/maintenance/FullIsirReport/ISIRReport/ISIRFullDetailReport/index.html`);
        let HTMLCss = getFile(`${window.location.origin}/ReportHTML/FinancialAid/maintenance/FullIsirReport/ISIRReport/ISIRFullDetailReport/index.css`);
        ExportComponent(HTMLString, HTMLCss, tempData);
    }
    const [tableRows, setTableRows] = useState([])
   
    useEffect(() => {
        let tableRows = []
        
        // For all keys that aren't the ISIR data, add a row
        for (let [key, value] of Object.entries(selectedRow)) {
            
            if (key.toUpperCase() !== "ISIRData".toUpperCase()) {
                tableRows.push({
                    key: key[0].toUpperCase() + key.slice(1),
                    value
                })
            }
        }

        // Now parse the ISIR as JSON and add a row for each data point
        var isirData = JSON.parse(selectedRow.isirData);

        for (let [key, value] of Object.entries(isirData)) {
            tableRows.push({
                key: key[0].toUpperCase() + key.slice(1),
                value
            });             
        }
        setTableRows(tableRows)
    }, [selectedRow]);

    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <PopupHeader
                        title={"Full ISIR Report"}
                        navigationList={[]}
                        onClose={onClose}
                    />
                    <DefaultTable
                        headCells={customImportFields}
                        rows={tableRows}
                        isLookup={true}
                        pagination={true}
                    />
                    <ButtonWrapperFilter>
                        <Button
                            className="BgYellowOrange search"
                            variant="contained"
                            size="large"
                            type="submit"
                            onClick={handlePrint}
                        >
                            {t("buttonLabel.print", { ns: "financialAid" })}
                        </Button>
                        <Button
                            className="BgLightBlue search"
                            variant="contained"
                            size="large"
                            type="reset"
                            onClick={()=>onClose(false)}
                        >
                            {t("buttonLabel.cancel")}
                        </Button>
                    </ButtonWrapperFilter>
                </CardContent>
            </Card>
        </>
    )
}

export default ShowISIRReport