import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { tools_crsevaluationsetup } from '../../../services/navigation_folder/tools';
import { Splitter } from '../../../styles/common-styles';
import { Breadcrumb, BreadcrumbLink } from '../../../utils/Breadcrumbs/Breadcrumbs';
import HeaderTitle from '../../../utils/HeaderTitle/HeaderTitle';
import NormalTabs from '../../../utils/ScrollableTab/ScrollableTabs';
import FormList from './tabs/formlist';
import MCItems from './tabs/mcitems';
import Question from './tabs/question';
const CrsEvaluationSetup = () => {
    const { t } = useTranslation(["translation", "tools"]);
    const [showTab, setShowTab] = useState(false);
    const [tabSwitch, setTabSwitch] = useState(true);
    const [errorMsg] = useState('Please select the Form');
    const [disableFlagMC, setDisableFlagMC] = useState(true);
    const [selectedQuestion, setSelectedQuestion] = useState('');

    const addData = (value) => {
        setShowTab(value);
    };


    useEffect(() => {
        if (selectedQuestion) {
            setDisableFlagMC(false)
            return;
        }
        setDisableFlagMC(true)
    }, [selectedQuestion])

    const [tabValue] = useState([
        {
            text: t("tabLabels.formlist", { ns: "tools" }),
            component: <FormList addData={addData} setTabSwitch={setTabSwitch} tabIndex={0} />,
        },
        {
            text: t("tabLabels.question", { ns: "tools" }),
            component: <Question addData={addData} tabIndex={1} setSelectedQuestion={setSelectedQuestion} />,
        },
        {
            text: t("tabLabels.mcitems", { ns: "tools" }),
            component: <MCItems addData={addData} tabIndex={2} disableFlag={disableFlagMC} />,
        }
    ]);

    const [tabValues, setTabValues] = useState(tabValue)

    useEffect(() => {
        setTabValues([
            {
                text: t("tabLabels.formlist", { ns: "tools" }),
                component: <FormList addData={addData} setTabSwitch={setTabSwitch} tabIndex={0} />,
            },
            {
                text: t("tabLabels.question", { ns: "tools" }),
                component: <Question addData={addData} tabIndex={1} setSelectedQuestion={setSelectedQuestion} />,
            },
            {
                text: t("tabLabels.mcitems", { ns: "tools" }),
                component: <MCItems addData={addData} tabIndex={2} disableFlag={disableFlagMC} />,
            }
        ])
    }, [disableFlagMC])

    return (
        <>
            {!showTab &&
                <>
                    <Breadcrumb>
                        {[...tools_crsevaluationsetup].map(({ name, isLink, path }, index) => (
                            <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} />
                        ))}
                    </Breadcrumb>
                    <HeaderTitle headerTitle={t("commonLabels.courseEvaluation", { ns: "tools" })} help={true} />
                </>}
            <NormalTabs
                TabLabels={tabValues}
                variant="fullWidth"
                showTab={showTab}
                tabSwitch={tabSwitch}
                alertMsg={errorMsg}
            />
        </>
    )
}
export default CrsEvaluationSetup;