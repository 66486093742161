import {
    GET_FINES, GET_INFO,GET_OPTION
} from './types';
const initialState = {
    // creditTransactions: null,
    // debitTransactions: null,
    // pendingAidTransactions: null,
    // batchTransactionDate: null,
    // selectDateType: "Transaction",
    // previousInterestChange: "No",
    // interestPercentagetoUse: 0.01,
    // batchPostingTerm: 0,
    // interestTransactionDoc: -1,
    // excludeNonBillingTransactions: false,
    // pendingFinancialAid: "No",
    // billingCategory: 0,
    // minimumBalancetoEvaluate: 0.01,
    // // minimumBalancetoEvaluate:  initCurrencyFields(false, 0.01),
    latePaymentFine: {},
    latePaymentInfo:{},
    optionsTab: {
        detailDateFrom:null,
        detailDateTo: null,
        termFrom: 0,
        termTo: 0,
        showPendingAwards:'No',
        selectOptions: "Monthly",
        statementSource: "Billing",
        showStatementDetails: "TransDate",
        statementFormat: "1000~Standard~reports\\toolsBillingStudentFines_101.rpt~toolsBillingStudentFines_101.asp"


    },

}

const latePaymentFineReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case GET_FINES: {
            return {
                ...state,
                latePaymentFine: { ...payload }
            };
        }

        case GET_INFO: {
            return {
                ...state,
                latePaymentInfo: { ...payload }
            };
        }
        case GET_OPTION:
            return {
                ...state,
                optionsTab: {
                    ...state.optionsTab, [payload['id']]: payload['value']
                }
            };

       
        default:
            return state;
    }
}

export default latePaymentFineReducer;

