import { GET_CRITERIA_TAB} from './types'
const initialState = {
    criteriaTab: {
        documentID: 0,
        schemaVersion: 8
        
    },
   
};

const codexportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
            };
       
        default:
            return state;
    }
};

export default codexportReducer;