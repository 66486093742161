import {
    GET_SELECTED_DOCUMENT,
    GET_STUDENTS_IN_DOCUMENTS,
    GET_LOOKUP_SPEEDE_DOCUMENT,
    UPDATE_IMPORT_DETAILS,
    UPDATE_COURSE_PARAMETERS
} from './types'
const initialState = {
    source: {
        selectedDocument: '',
        lookupSpeedeDocument: '',
        studentsInDocument: [],
        importDetails: ""
    },
    acadamic: {
        courseParameters: ''
    }
};
const importSpeedeCollegeReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case UPDATE_COURSE_PARAMETERS:
            return {
                ...state,
                acadamic: {
                    ...state.acadamic, courseParameters: payload
                }

            };
        case GET_SELECTED_DOCUMENT:
            return {
                ...state,
                source: {
                    ...state.source, selectedDocument: payload
                }

            };
        case GET_STUDENTS_IN_DOCUMENTS:
            return {
                ...state,
                source: {
                    ...state.source, studentsInDocument: payload
                }

            };

        case UPDATE_IMPORT_DETAILS:
            return {
                ...state,
                source: {
                    ...state.source, importDetails: payload
                }

            };

        case GET_LOOKUP_SPEEDE_DOCUMENT:
            return {
                ...state,
                source: {
                    ...state.source, lookupSpeedeDocument: payload
                }

            };
        default:
            return state;
    }
}
export default importSpeedeCollegeReducer