import { PAYLOAD_DATA, REPORT_DROPDOWN_DATA } from './types'
const initialState = {
    formValues: {
        term: {
            term1: "",
            term2: "",
            includeWithdrawn: false,
            mustHaveAid: false,
            minFullTimeCredits: null,
            minTwoTermCredits: null,
            minTermGPA: null,
            minCumGPA: null
        },
        termText: {
            term1Text: "",
            term2Text: ""
        }
    },
};
const updateEnrollmentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PAYLOAD_DATA:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    term: payload
                }
            }
        case REPORT_DROPDOWN_DATA:

            return {
                ...state, formValues: {
                    ...state.formValues,
                    termText: payload
                }
            }

        default:
            return state;
    }
};
export default updateEnrollmentReducer;