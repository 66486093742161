import React from 'react';
import { toast } from "react-toastify";
import i18n from "i18next";

const Msg = ({ title, text }) => (
    <>
        {text && <span>{text}</span>}
    </>
)

const type = { info: toast.TYPE.INFO, success: toast.TYPE.SUCCESS, warning: toast.TYPE.WARNING, error: toast.TYPE.ERROR };

export const toastMessage = (tag, title, text) => {
    toast(<Msg title={title} text={text} />, {
        type: type[tag]
    });
}

export const showAddSuccessToast = () => {
    const successIcon = i18n.t("popupMessages.successIcon");
    const successHeader = i18n.t("popupMessages.success");
    const successMessage = i18n.t("popupMessages.successfullyAdded");

    toastMessage(successIcon, successHeader, successMessage);
}

export const showUpdateSuccessToast = () => {
    const successIcon = i18n.t("popupMessages.successIcon");
    const successHeader = i18n.t("popupMessages.success");
    const successMessage = i18n.t("popupMessages.successfullyUpdated");

    toastMessage(successIcon, successHeader, successMessage);
}

export const showDeleteSuccessToast = () => {
    const successIcon = i18n.t("popupMessages.successIcon");
    const successHeader = i18n.t("popupMessages.success");
    const successMessage = i18n.t("popupMessages.successfullyDeleted");

    toastMessage(successIcon, successHeader, successMessage);
}

export const showEnableDisableSuccessToast = (originalIsActive) => {
    const successIcon = i18n.t("popupMessages.successIcon");
    const successHeader = i18n.t("popupMessages.success");
    const successMessage = originalIsActive === "Yes"
        ? i18n.t("popupMessages.successfullyDisabled")
        : i18n.t("popupMessages.successfullyEnabled");
        
    toastMessage(successIcon, successHeader, successMessage);
}

export const showErrorToast = (errorMessage) => {
    const errorIcon = i18n.t("popupMessages.errorIcon");
    const errorHeader = i18n.t("popupMessages.error");
    
    toastMessage(errorIcon, errorHeader, errorMessage);
}