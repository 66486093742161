import { P_LIFETIME } from './types'
const initialState = {
   
};
const saveLifetimeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case P_LIFETIME:
            return {
                ...payload
            }
        default:
            return state;
    }
}
export default saveLifetimeReducer