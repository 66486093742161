import { ADD_PREVNAME, DELETE_PREVNAME, ADD_STUDENT_PREVNAME, DELETE_STUDENT_PREVNAME } from './type'

const initialState = {
    prevName: [],
    studentPrevName:[]
};

const prevNameReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_PREVNAME:
            return {
                ...state,
                prevName: [...payload]
            }
        case DELETE_PREVNAME:
            return {
                ...state,
                prevName: state.prevName.filter(item => !payload.includes(item.id.toString()))
            }
        case ADD_STUDENT_PREVNAME:
            return {
                ...state,
                studentPrevName: [...payload]
            }
        case DELETE_STUDENT_PREVNAME:
            return {
                ...state,
                studentPrevName: state.studentPrevName.filter(item => !payload.includes(item.id.toString()))
            }
        default:
            return state;
    }
};

export default prevNameReducer;