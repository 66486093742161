import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { TextInput, SelectInput, RadioInput, CheckBoxInput, MultipleSelectInput, MultipleSelectInputWithSelectAll, GroupMultipleSelectInput } from '../../components/Forms';
import { FormGroup, useStyles, ButtonWrapperFilter } from '../../styles/common-styles'

import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, editAddress } from '../../redux/admisssion/prospects/addAddress/action';
import { addAddressSelector } from '../../redux/admisssion/prospects/addAddress/selectors';
import { updateFormStatus } from '../../redux/common/formValueChange/action';
import { getApi } from '../../services/apiBase'
import { addInfo_dropdown } from '../../services/apiURL';
import { scrollIntoViewHelper } from '../../helper';

import GenericModal from '../../components/Modal/GenericModal';
import Tooltips from '../../utils/Tooltip/Tooltip';



const CopyRooms = (props) => {
    const { t } = useTranslation(["studentLife"]);
    const classes = useStyles();
    const { onClose, selectedRow, selectedIndex, type, handleCopyChanges, popupHeaderTitle, selectedName, floorDetails } = props;
    const dispatch = useDispatch();
    const classess = useStyles();
    const [roomValuesChecked, setRoomValuesChecked] = useState(false);
    const [dropDownValuesCheck, setDropDownValuesCheck] = useState(false);

    const checkBoxs = [
        "selectall",
        "roomType",
        "roomArea",
        "roomOccupancy",
        "roomBeds",
        "residentTypes",
        "gender",
        "roomProgram",
        "emotionalSupportAnimal",
        "roomStatus",
        "active",
        "amenities",
        "totalRoomKeys",
        "roomKeyCodes",
        "housingStaff",
        "wheelchairAccess",
        "yearClassification",
        "transfers",
        "athletics",
        "gpa",
        "age"
    ]
    const [formValues, setFormValues] = useState({
        copyFromRoom: selectedName,
        copyToRooms: [],
        selectall: "",
        roomType: "",
        roomArea: "",
        roomOccupancy: "",
        roomBeds: "",
        residentTypes: "",
        gender: "",
        roomProgram: "",
        emotionalSupportAnimal: "",
        roomStatus: "",
        active: "",
        amenities: "",
        totalRoomKeys: "",
        roomKeyCodes: "",
        housingStaff: "",
        wheelchairAccess: "",
        yearClassification: "",
        transfers: "",
        athletics: "",
        gpa: "",
        age: "",
        copyDropdownList: selectedRow
    });

    const [toolTipStatus, setToolTipStatus] = React.useState('');
    const validationSchema = yup.object({
    });

    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCopyChanges(formik.values);
            onClose();
        }
    });
    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        formik.setFieldValue("selectall", checked);
        formik.setFieldValue("roomType", checked);
        formik.setFieldValue("roomArea", checked);
        formik.setFieldValue("roomOccupancy", checked);
        formik.setFieldValue("roomBeds", checked);
        formik.setFieldValue("residentTypes", checked);
        formik.setFieldValue("gender", checked);
        formik.setFieldValue("roomProgram", checked);
        formik.setFieldValue("emotionalSupportAnimal", checked);
        formik.setFieldValue("roomStatus", checked);
        formik.setFieldValue("active", checked);
        formik.setFieldValue("amenities", checked);
        formik.setFieldValue("totalRoomKeys", checked);
        formik.setFieldValue("roomKeyCodes", checked);
        formik.setFieldValue("housingStaff", checked);
        formik.setFieldValue("wheelchairAccess", checked);
        formik.setFieldValue("yearClassification", checked);
        formik.setFieldValue("transfers", checked);
        formik.setFieldValue("athletics", checked);
        formik.setFieldValue("gpa", checked);
        formik.setFieldValue("age", checked);
    }


    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.checked);
        if (!e.target.checked) formik.setFieldValue("selectall", false);
    }

    function hasAtLeastOneTrue(obj) {
        return Object.values(obj).some(value => value === true);
    }

    useEffect(() => {
        setRoomValuesChecked(hasAtLeastOneTrue(formik.values));
        setDropDownValuesCheck(formik?.values?.copyToRooms.length > 0);
    }, [formik?.values])
    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.root}>
                <Card>
                    <CardContent>
                        <Typography component="h4" variant="h8" style={{ marginBottom: "30px", fontSize: "smaller" }}>{t('commonLabel.copyInfo')}</Typography>
                        <FormGroup>
                            <TextInput
                                labelId="copyFromRoom"
                                label={t('roomsLabel.copyFromRoom')}
                                formikValue={formik.values.copyFromRoom}
                                formik={formik}
                                errors={formik.errors.copyFromRoom}
                                touched={formik.touched.copyFromRoom}
                                disabled={true}
                                formikChange={formik.handleChange}
                                onTooltip={t('roomsLabel.copyFromRoom')}
                            />
                            {floorDetails.propertyType === 1 ?
                                <GroupMultipleSelectInput
                                    mandatory={true}
                                    customStyle={{
                                        width: "25%",
                                        marginRight: "0px"
                                    }}
                                    label={t('roomsLabel.copyToRooms')}
                                    labelId={'copyToRooms'}
                                    selectAllText={'All Rooms'}
                                    formik={formik}
                                    list={selectedRow}
                                    onTooltip={t('roomsLabel.copyToRooms')}
                                /> :
                                <MultipleSelectInput
                                    mandatory={true}
                                    customStyle={{
                                        width: "25%",
                                        marginRight: "0px"
                                    }}
                                    label={t('roomsLabel.copyToRooms')}
                                    labelId={'copyToRooms'}
                                    selectAllText={'All Rooms'}
                                    formik={formik}
                                    list={selectedRow}
                                    onTooltip={t('roomsLabel.copyToRooms')}
                                />}
                        </FormGroup>
                    </CardContent>
                    <hr></hr>
                    <CardContent>
                        <Typography component="h3" variant="h7" style={{ marginBottom: "10px", fontSize: "larger" }}>{t('commonLabel.roomDetails')}</Typography>
                        <CheckBoxInput
                            label={'Room'}
                            labelId='selectall'
                            formikValue={formik.values.selectall}
                            formikChange={handleSelectAll}
                            isDoubled
                            checked={formik.values.selectall}
                            displayDynamicValue={selectedName}
                        />
                        <FormGroup style={{ padding: "20px" }}>
                            <CheckBoxInput
                                label={t('roomsLabel.roomType')}
                                labelId='roomType'
                                formikValue={formik.values.roomType}
                                formikChange={handleChange}
                                isDoubled
                                checked={formik.values.roomType}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.roomArea')}
                                labelId='roomArea'
                                formikValue={formik.values.roomArea}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomArea}
                            />
                            <CheckBoxInput
                                label={t('roomsLabel.occupancy')}
                                labelId='roomOccupancy'
                                formikValue={formik.values.roomOccupancy}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomOccupancy}
                            />
                            <CheckBoxInput
                                label={t('roomsLabel.beds')}
                                labelId='roomBeds'
                                formikValue={formik.values.roomBeds}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomBeds}
                            />
                        </FormGroup>
                        <FormGroup style={{ width: '772px', padding: "20px" }}>
                            <CheckBoxInput
                                label={t('roomsLabel.roomStatus')}
                                labelId='roomStatus'
                                formikValue={formik.values.roomStatus}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomStatus}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.active')}
                                labelId='active'
                                formikValue={formik.values.active}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.active}
                            />
                            <CheckBoxInput
                                label={t('roomsLabel.emotionalSupportAnimal')}
                                labelId='emotionalSupportAnimal'
                                formikValue={formik.values.emotionalSupportAnimal}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.emotionalSupportAnimal}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.totalRoomKeys')}
                                labelId='totalRoomKeys'
                                formikValue={formik.values.totalRoomKeys}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.totalRoomKeys}

                            />



                        </FormGroup>
                        <FormGroup style={{ width: '772px', padding: "20px" }}>

                            <CheckBoxInput
                                label={t('roomsLabel.roomKeyCodes')}
                                labelId='roomKeyCodes'
                                formikValue={formik.values.roomKeyCodes}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomKeyCodes}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.amenities')}
                                labelId='amenities'
                                formikValue={formik.values.amenities}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.amenities}
                            />

                            <CheckBoxInput
                                label={t('roomsLabel.housingStaff')}
                                labelId='housingStaff'
                                formikValue={formik.values.housingStaff}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.housingStaff}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.residentTypes')}
                                labelId='residentTypes'
                                formikValue={formik.values.residentTypes}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.residentTypes}
                            />

                        </FormGroup>
                        <FormGroup style={{ width: '772px', padding: "20px" }}>


                            <CheckBoxInput
                                label={t('roomsLabel.gender')}
                                labelId='gender'
                                formikValue={formik.values.gender}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.gender}
                            />
                            <CheckBoxInput
                                label={t('roomsLabel.program')}
                                labelId='roomProgram'
                                formikValue={formik.values.roomProgram}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.roomProgram}
                            />


                            <CheckBoxInput
                                label={t('roomsLabel.yearClassification')}
                                labelId='yearClassification'
                                formikValue={formik.values.yearClassification}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.yearClassification}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.gpa')}
                                labelId='gpa'
                                formikValue={formik.values.gpa}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.gpa}

                            />

                        </FormGroup>
                        <FormGroup style={{ width: '772px', padding: "20px" }}>
                            <CheckBoxInput
                                label={t('roomsLabel.athletics')}
                                labelId='athletics'
                                formikValue={formik.values.athletics}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.athletics}

                            />
                            <CheckBoxInput
                                label={t('roomsLabel.age')}
                                labelId='age'
                                formikValue={formik.values.age}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.age}

                            />

                            <CheckBoxInput
                                label={t('roomsLabel.transfers')}
                                labelId='transfers'
                                formikValue={formik.values.transfers}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.transfers}

                            />

                            <CheckBoxInput
                                label={t('roomsLabel.wheelchairAccessible')}
                                labelId='wheelchairAccess'
                                formikValue={formik.values.wheelchairAccess}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.wheelchairAccess}

                            />
                        </FormGroup>
                        <hr></hr>
                        <FormGroup></FormGroup>
                        <FormGroup>
                            <ButtonWrapperFilter>
                                <Button onClick={() => { dispatch(updateFormStatus(false)); onClose(); }} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                                    {t('buttonLabel.cancel')}
                                </Button>
                                <Button aria-label={'Copy'} className='BgYellowOrange' variant="contained" size="large" type="submit" disabled={!(roomValuesChecked && dropDownValuesCheck)} >
                                    {t('buttonLabel.copy')}
                                </Button>
                            </ButtonWrapperFilter>
                        </FormGroup>
                    </CardContent>
                </Card>
            </form>

        </>
    )
}

export default CopyRooms;


