import { GET_STUDENT_SELECTION, SET_MULTIPLE_STUDENT_SELECTION_CRITERIA, GET_CRITERIA, SET_PRINT_CRITERIA, RESET} from './types'

const initialState = {
    studentSelection : {
        StudentSelection: 'Single',
        StudentID: '',
        studentUID: 0,
        StudentName: '',
        IncludeStudentsonHold: 0,
        StudentLevel: '',
        StudentsRegisteredinTermFrom: 0,
        StudentsRegisteredinTermTo: 0,
        StudentstobeGraduatedinTermFrom: 0,
        StudentstobeGraduatedinTermTo: 0,
        Campus: [],
        NonTraditional: [],
        CollegeLevel: [],
        Advisor: [],
        StudentStatusTerm: [],
        AcademicStatus: [],
        FinancialAidinTerm: [],
        StatusCohortGroup: [],
        ExtracurricularActivity: []
    },
    multipleStudentSelectionCriteria: {
        LastNameFrom: '',
        LastNameTo: ''
    },
    printCriteria: {
        TranscriptPurposeId: 0,
        StudentAddressTypeId: 0,
        Name: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zip: '',
        Country: ''
    },
    criteria:{
        TranscriptFormat: "2~reports\\RegTranscriptDoubleColumnV5.rpt~REGTranscriptBYORDoubleColumnV5.asp",
        ShowAcademicStatus: false,
        ShowPreviousGrade: false,
        ShowTranscriptComments: false,
        CoursesUpToAndIncludingTerm:0,
        SummarizeTransferCredit: false,
        TranscriptType: 'official',
        include:'allCourses',
        CoursesinGPAGrouping:[],
        showAllDegrees:false,
        transcriptFormatText: "",
        termText: "",
        templateID:""
    }
};

const transcriptReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STUDENT_SELECTION:
            return {
                ...state,
                studentSelection: { ...payload }
            };
        case SET_MULTIPLE_STUDENT_SELECTION_CRITERIA:
            return {
                ...state,
                multipleStudentSelectionCriteria: { ...payload }
            };
        case GET_CRITERIA:
            return {
                ...state,
                criteria: { ...payload }
            };
        case SET_PRINT_CRITERIA:
            return {
                ...state,
                printCriteria: { ...payload }
            };
        case RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
};

export default transcriptReducer;