import React from 'react';
import {
    DataGridPro,
    GridEventListener,
    GridToolbarContainer,
    useGridApiContext,
    gridVisibleSortedRowIdsSelector,
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
    HideGridColMenuItem,
    GridColumnsMenuItem
} from '@mui/x-data-grid-pro';
import setLicenseKey from './DataGridLicense'
import { ReactComponent as GridColumnsIcon } from '../../assests/icons/Grid_column.svg'
import { ReactComponent as GridFilterIcon } from '../../assests/icons/Grid_filter.svg'
import { ReactComponent as GridExportIcon } from '../../assests/icons/Grid_export.svg'
import AddIcon from '@mui/icons-material/Add';
import { Checkbox } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import CustomLoader from '../../components/Loader/Loader';
import GenericModal from '../../components/Modal/GenericModal'
import BrandingColors from '../../styles/color';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
    gridVisibleSortedRowIdsSelector(apiRef);

const ElementsDataGridToolbar = (props) => {
    const { selectionModel, selectionModelIds, onAdd, disableAdd, onEdit, onDelete, onView, viewAccess, addEditAccess, rows, trackingLabel, selectionModelRow } = props;
    const apiRef = useGridApiContext();

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    const buttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small'
    };
    const disabledIcon = !selectionModel || selectionModel.length !== 1

    return (
        <GridToolbarContainer>
            {onDelete && addEditAccess &&
                <Button
                    {...buttonBaseProps}
                    startIcon={
                        <DeleteOutlineOutlinedIcon color={disabledIcon ? "disabled" : ""}
                            style={{ pointerEvents: disabledIcon ? 'none' : "" }} />
                    }
                    onClick={() => setConfirmOpen(true)}
                    sx={{
                        '&.Mui-disabled': {
                            background: 'none !important'
                        }
                    }}
                    disabled={disabledIcon}
                >
                </Button>}
            {selectionModel?.length >= 1 && onEdit && addEditAccess &&
                <Button
                    {...buttonBaseProps}
                    startIcon={<EditOutlinedIcon color={disabledIcon ? "disabled" : ""}
                        style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                    onClick={(e) => {
                        onEdit(selectionModelRow[0]);
                    }}
                    sx={{
                        '&.Mui-disabled': {
                            background: 'none !important',
                        }
                    }}
                    disabled={disabledIcon}
                >
                    {/* (multiSelect) && handleClick(e, selectionModelIds, parseInt(selectionModel[0]));  */}
                </Button>
            }
            {addEditAccess && <Button
                {...buttonBaseProps}
                startIcon={<AddIcon color={disableAdd ? "disabled" : ""} />}
                onClick={onAdd}
                disabled={disableAdd}
                sx={{
                    '&.Mui-disabled': {
                        background: 'none !important',
                    }
                }}
            ></Button>}
            {selectionModel?.length >= 1 && onView && viewAccess &&
                <Button
                    {...buttonBaseProps}
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    onClick={(e) => { onView(selectionModelRow[0]) }}
                ></Button>
            }
            <Button
                {...buttonBaseProps}
                startIcon={<GridExportIcon />}
                onClick={() => handleExport({ getRowsToExport: getFilteredRows, fileName: trackingLabel })} >
            </Button>
            <GridToolbarFilterButton
                componentsProps={{
                    button: {
                        startIcon: (
                            <GridFilterIcon />
                        )
                    }
                }}
            />
            <GridToolbarColumnsButton startIcon={<GridColumnsIcon />} />
            <GenericModal
                open={confirmOpen}
                title={"Are you sure you want to delete? "}
                buttonText={"Yes"}
                button2Text={"No"}
                onButtonPress={() => {
                    // setConfirmOpen(false);
                    //New Changes starts- Row Selection
                    onDelete(selectionModelRow);
                    //New Changes ends- Row Selection
                    //handleClick(e, selectionModelIds, selectionModel);
                    // handlePaginationOnDelete();
                }}
                onClose={setConfirmOpen}
            />

        </GridToolbarContainer >
    );
};

const ElementsColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
        <GridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
        >
            <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
        </GridColumnMenuContainer>
    )
}

export default function ElementsDataGrid(props) {

    const { isLoading,
        multiSelect,
        headCells,
        rows,
        pagination,
        onAdd,
        onDelete,
        onEdit,
        onView,
        viewAccess,
        addEditAccess,
        isLookup,
        onRowSelect, actionFlag, trackingLabel, dataGridToolbar, dataGridColumnMenus, initialColumn, disableAdd } = props;

    setLicenseKey()

    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(20)
    const maxVisibleRows = 8

    const dgColumns = [
        ...(!isLookup && !multiSelect
            ? [{
                field: "(row checkbox)",
                headerName: "",
                disableColumnMenu: true,
                disableExport: true,
                sortable: false,
                resizable: false,
                hideable: false,
                filterable: false,
                width: 50,
                renderCell: (params) => (
                    <Checkbox
                        checked={rowChecked != null && params != null && rowChecked[0] === params.id}
                        value={params != null ? params.id : null}
                    />)
            }]
            : []
        ),
        ...headCells.map(headCell => ({
            field: headCell.id,
            headerName: headCell.label,
            disableColumnMenu: !dataGridColumnMenus,
            flex: 1
        }))
    ]

    const dgRows = rows.map((row, index) => ({
        id: index,
        ...row
    }))

    const displayToolbar = dataGridToolbar ? "block" : "none"

    let rowChecked;

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        (onRowSelect) && onRowSelect(params.row)
    }

    const handleRowDoubleClick: GridEventListener<'rowDoubleClick'> = (params) => {
        if (!actionFlag) {
            (onEdit) && onEdit(params.row);
        }
    }

    const [selectionModel, setSelectionModel] = React.useState(rowChecked);
    const [selectionModelIds, setSelectionModelIds] = React.useState(rowChecked);
    const [deletedModelIds, setDeletedModelIds] = React.useState([]);
    rowChecked = selectionModel;
    //New Changes starts- Row Selection
    const [selectionModelRow, setSelectionModelRow] = React.useState([]);
    //New Changes Ends- Row Selection

    return (
        <>
            {isLoading ? <CustomLoader /> : ""}
            <DataGridPro
                components={{
                    Toolbar: ElementsDataGridToolbar,
                    ColumnMenu: ElementsColumnMenu
                }}
                componentsProps={{
                    toolbar: {
                        selectionModel,
                        selectionModelIds,
                        onAdd,
                        onEdit,
                        onDelete,
                        onView,
                        viewAccess,
                        addEditAccess,
                        rows,
                        trackingLabel,
                        selectionModelRow,
                        disableAdd
                    },
                    panel: {
                        sx: {

                        }
                    },
                    basePopper: {
                        sx: {
                            '&.MuiDataGrid-menu .MuiPaper-root': {
                                width: '188px',
                                backgroundColor: '~FFFFFF',
                                border: '1px solid rgba(210, 210, 210, 0.58)',
                                boxShadow: '0px 0px 6px rgba(211, 211, 211, 0.74)',
                                borderRadius: '3px',
                                '& .MuiDataGrid-menuList': {
                                    '& .MuiMenuItem-root': {
                                        display: 'block-flex',
                                        paddingLeft: '16px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#646569'
                                    },
                                    '& .MuiMenuItem-root:hover': {
                                        color: '#000000',
                                        backgroundColor: '#FBF4E5'
                                    }
                                }
                            },
                            '&.MuiDataGrid-panel .MuiPaper-root': {
                                '& .MuiDataGrid-panelHeader': {
                                    padding: '16px 16px 0px 16px',
                                    '& .MuiInputLabel-standard': {
                                        color: '#646569',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    },
                                    '& .MuiInput-input': {
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    }
                                },
                                '& .MuiDataGrid-panelContent': {
                                    padding: '16px 16px 16px 16px',
                                    '& .MuiSwitch-sizeSmall': {
                                        width: '60px'
                                    },
                                    '& .MuiSwitch-switchBase': {
                                        color: '#96989A'
                                    },
                                    '& .MuiSwitch-switchBase+.MuiSwitch-track': {
                                        backgroundColor: '#E2E2E2',
                                        opacity: '1',
                                        transform: 'translateX(-16px)'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#0A66C2'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                        backgroundColor: '#E3ECF5',
                                        opacity: '1'
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        color: '#646569',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    },
                                    '& .MuiDataGrid-filterForm': {
                                        '& .MuiIconButton-sizeSmall:hover': {
                                            backgroundColor: '#FBF4E5'
                                        },
                                        '& .MuiDataGrid-filterFormColumnInput, .MuiDataGrid-filterFormOperatorInput': {
                                            marginRight: '16px'
                                        },
                                        '& .MuiInputLabel-standard': {
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            color: '#646569'
                                        },
                                        '& .MuiInput-input': {
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#646569'
                                        },
                                        '& .MuiNativeSelect-select': {
                                            paddingLeft: '2px',
                                            paddingRight: '24px',
                                            minWidth: '16px'
                                        }
                                    },
                                    "& .MuiDataGrid-columnsPanel > div:first-child": {
                                        display: 'none'
                                    }
                                },
                                '& .MuiDataGrid-panelFooter': {
                                    padding: '0px 16px 16px 16px',
                                    '& .MuiButton-root': {
                                        backgroundColor: '#E3ECF5',
                                        color: '#0A66C2',
                                        border: '1px solid #E3ECF5',
                                        borderRadius: '4px',
                                        padding: '0px 10px 0px 1px',
                                        minHeight: '28px',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        textTransform: 'capitalize'
                                    },
                                }
                            }
                        }
                    },
                    pagination: {
                        SelectProps: {
                            MenuProps: {
                                sx: {
                                    '.MuiList-padding': {
                                        padding: '1px 1px 1px 1px'
                                    },
                                    '.MuiTablePagination-menuItem': {
                                        display: 'block-flex',
                                        paddingLeft: '8px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#646569'
                                    },
                                    '.MuiTablePagination-menuItem:hover, .MuiTablePagination-menuItem.Mui-selected:hover': {
                                        color: '#000000',
                                        backgroundColor: '#FBF4E5',
                                        border: '1px solid #0A66C2',
                                    },
                                    '.MuiTablePagination-menuItem.Mui-selected': {
                                        backgroundColor: '#FFFFFF'
                                    }
                                }
                            }
                        }
                    }
                }}
                localeText={{
                    toolbarColumns: "",
                    toolbarFilters: ""
                }}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}

                disableMultipleSelection={!multiSelect}
                checkboxSelection={multiSelect}

                columns={dgColumns}
                rows={dgRows}

                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                initialState={initialColumn}
                pagination={pagination}
                hideFooterRowCount={!pagination}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    const selectedIndexes = new Set(newSelectionModel)
                    const selectedRows = dgRows.filter((row) =>
                        selectedIndexes.has(row.id)
                    )
                    let ids = []
                    selectedRows.forEach((element) => {
                        ids.push(Object.values(element)[1])
                    })
                    setSelectionModelIds(ids);
                    //New Changes starts- Row Selection
                    setSelectionModelRow(selectedRows)
                    //New Changes ends- Row Selection
                }}

                sx={{
                    width: '100%',
                    height: 160 + Math.min(maxVisibleRows, Math.min(pageSize, dgRows.length + 1)) * 52,
                    minHeight: 250,
                    background: '#FFFFFF',
                    border: '1px solid rgba(210, 210, 210, 0.58)',
                    boxShadow: '0px 0px 6px rgba(211, 211, 211, 0.74)',
                    borderRadius: '3px',
                    '.MuiDataGrid-toolbarContainer': {
                        display: `${displayToolbar}`,
                        padding: '16px 16px 16px 16px',
                        '& .MuiButton-root': {
                            float: 'right',
                            minWidth: '38px',
                            minHeight: '38px',
                            '& .MuiButton-startIcon': {
                                color: '#646569'
                            },
                            '& .MuiButton-iconSizeSmall': {
                                marginLeft: '-2px',
                                marginRight: '-2px'
                            }
                        },
                        '& .MuiButton-root:hover': {
                            backgroundColor: "#FBF4E5",
                            borderRadius: "50px",
                            height: "38px",
                            width: "38px",
                            '& svg': {
                                filter: 'brightness(0) saturate(100%)'
                            }
                        },
                    },
                    '.MuiDataGrid-columnHeaders': {
                        padding: '0px 16px 0px 16px',
                        borderBottomColor: `${BrandingColors.brightOrange}`
                    },
                    '.MuiDataGrid-iconButtonContainer, .MuiDataGrid-menuIcon': {
                        '.MuiIconButton-root': {
                            height: '24px',
                            width: '24px',
                        },
                        '.MuiIconButton-root:hover': {
                            backgroundColor: '#FBF4E5',
                            '& svg': {
                                filter: 'brightness(0) saturate(100%)'
                            }
                        }
                    },
                    '.MuiDataGrid-main': {
                        padding: '0px 16px 0px 16px'
                    },
                    '.MuiDataGrid-columnHeader': {
                        boxSizing: 'border-box'
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'flex-start'
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        color: '#646569',
                        fontSize: '14px',
                        fontWeight: '700'
                    },
                    '.MuiDataGrid-cellContent': {
                        color: '#646569',
                        fontSize: '14px',
                        fontWeight: '400'
                    },
                    '.MuiDataGrid-row.Mui-selected': {
                        backgroundColor: `${BrandingColors.hoverOrangeCustom}`,
                        '& .MuiDataGrid-cell': {
                            '& .MuiDataGrid-cellContent': {
                                color: '#000000'
                            }
                        }
                    },
                    '.MuiDataGrid-rowCount': {
                        float: 'right'
                    },
                    '.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: '#E6F5F9'
                    },
                    '[data-testid="CheckBoxOutlineBlankIcon"]': {
                        color: '#646569'
                    },
                    '.MuiMenu-paper .MuiMenu-list': {
                        fontWeight: '700',
                        display: 'inline-block'
                    },
                    '.MuiDataGrid-footerContainer': {
                        padding: '10px 16px 16px 16px',
                        '& .MuiDataGrid-selectedRowCount, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select': {
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#646569'
                        },
                        '& .MuiTablePagination-select:hover, .MuiTablePagination-select:focus': {
                            backgroundColor: '#FBF4E5'
                        },
                        '& .MuiIconButton-sizeMedium': {
                            height: '44px',
                            width: '44px'
                        },
                        '& .MuiIconButton-sizeMedium:hover': {
                            backgroundColor: '#FBF4E5'
                        }
                    },
                    '.MuiTablePagination-menuItem': {
                        display: 'block-flex'
                    }
                }}
            />
        </>
    )
}