import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles} from "../../../../styles/common-styles";
import { financialAid_costattendence } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../../pages/common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { getApi } from '../../../../services/apiBase';
import { get_costLabels } from "../../../../services/apiURL";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { toastMessage } from '../../../../utils/Message/toasts';
import { useSelector } from "react-redux";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";



const AddCostOfAttendence = ({ onClose, selectedSelected, selectedCOARow, setCOARow, COARow, coaIndex }) => {
  const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
  const classess = useStyles();
  const initialValues = {
    costLabelID: "",
    amount: "",
  };

  const [Dropdown, setDropdown] = useState({
    "costLabel": []
  })
  const [formValues, setFormValues] = useState(initialValues)
  useEffect(() => {
    getApi(`${get_costLabels}`).then((resp) => {
      const { costLabels } = resp.data.data;
      setDropdown({
        ...Dropdown,
        "costLabel": costLabels
      })
    }).catch((err) => {
      console.log(err);
    })

  }, [])



  const validationSchema = yup.object({
    costLabelID: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.costLabel', { ns: "financialAid" }) })),
    amount: yup
      .number()
      .typeError('')
      .min(1, t('status.amountMustBePositive', { ns: "financialAid" }))
      .required(t('validationMessages.isRequired', { what: t('commonLabel.amount', { ns: "financialAid" }) })),
  });

  useEffect(() => {
    if (selectedCOARow) {
      // const { sAPGovernmentalStatuses, governmentalSAPID, finAwardRefGovernmentalSAPID } = selectedCOARow;
      // formik.setFieldValue("finAwardRefGovernmentalSAPID", finAwardRefGovernmentalSAPID);
      // formik.setFieldValue("costLabel", costLabel);
    }
  }, [selectedCOARow])

  const formik = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let groupName = [];
      if (Dropdown.costLabel) {
        const { costLabel } = Dropdown
        groupName = (costLabel).filter(v => Number(values.costLabelID) === Number(v.uniqueId))
      }
      let govtGroupArray = {
        "costLabel": (groupName.length > 0) ? groupName[0].displayText : '',
        "costAmount": values.amount,
        "costLabelID": (Number(values.costLabelID)) ? Number(values.costLabelID) : 0,
        "financialStuCostID": selectedSelected.financialStuCostID || 0,
        "studentUID": selectedSelected.studentUID || 0,
        "financialStatusID": selectedSelected.financialStatusID || 0,
        "insertUserID": "",
        "insertTime": null,
        "updateUserID": "",
        "updateTime": null
      }
      if (!selectedCOARow) {
        setCOARow([...COARow, govtGroupArray])
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
      } else {
        let govtArr = COARow.map((v, i) => {
          let editedValue = { ...v };
          if (i === Number(coaIndex)) {
            editedValue = govtGroupArray
          }
          return editedValue;
        })
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
        DefaultTable.resetTable('COATable')
        setCOARow([...govtArr])
      }
      onClose(false)
    }
  });

  useEffect(() => {
    if (selectedCOARow) {
      setFormValues({
        ...formValues,
        "costLabelID": selectedCOARow.costLabelID,
        "amount": selectedCOARow.costAmount,
      })
    }
  }, [])


  // useEffect(() => {
  //   if (Dropdown.costLabel.length > 0) {
  //     let CostLabelName = Dropdown.costLabel.filter(v =>
  //       Number(v.uniqueId) === formik.values.costLabel
  //     )
  //     if (CostLabelName.length > 0) {
  //       setCostLabelName(CostLabelName[0].displayText)
  //     }
  //   }
  // }, [formik.values.costLabel, Dropdown])
  //Role Based Access implementation starts here
    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;
    const [costAccess, setCostAccess] = useState({
        maintenance: 2
    })
    useEffect(() => {
        let ChangeCostAccess = nestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === maintenance && v.moduleorder === 4
        )
        setCostAccess({
            ...costAccess,
            maintenance: (ChangeCostAccess.length > 0) ? ChangeCostAccess[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (costAccess.maintenance === 1) ? setAccessFlag(true) : setAccessFlag(false)
    }, [costAccess])
    //Role Based Access implementation ends here

  return (
    <>
      <PopupHeader
        title={selectedCOARow !== "" ? (t('commonLabel.updateCostofAttendance', { ns: "financialAid" })) : (t('commonLabel.addCostofAttendance', { ns: "financialAid" }))}
        navigationList={financialAid_costattendence}
        onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <FormGroup>
              <SelectInput
                mandatory={true}
                disabled={accessFlag}
                label={t("commonLabel.costLabel", { ns: "financialAid" })}
                labelId={"costLabelID"}
                formikValue={formik.values.costLabelID}
                formikChange={formik.handleChange}
                errors={formik.errors.costLabelID}
                touched={formik.touched.costLabelID}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.costLabel }}
              />
              <TextInput
                mandatory={true}
                disabled={accessFlag}
                label={t("commonLabel.amount", { ns: "financialAid" })}
                labelId="amount"
                formikValue={formik.values.amount}
                formikChange={formik.handleChange}
                errors={formik.errors.amount}
                touched={formik.touched.amount}
                onlyNumeric
              />
            </FormGroup>
          </CardContent>
          <CardContent>
            <div style={{ display: "flex" }}>
            <Tooltips flag={(selectedCOARow) ? true : false} row={
                                {
                                    insertUserID: selectedCOARow.insertUserID,
                                    insertTime: selectedCOARow.insertTime,
                                    updateUserID: selectedCOARow.updateUserID,
                                    updateTime: selectedCOARow.updateTime
                                }
              } />

              <ButtonWrapperFilter className="activityButton">
                <Button
                  className="BgYellowOrange search"
                  variant="contained"
                  size="large"
                  type="submit"
                disabled={accessFlag}
                >
                  {selectedCOARow == "" ? (t('buttonLabel.add', { ns: "financialAid" })) : (t('buttonLabel.update', { ns: "financialAid" }))}
                </Button>
                <Button
                  aria-label={"Cancel"}
                  className="BgLightBlue"
                  variant="contained"
                  size="large"
                  onClick={onClose}
                >
                  {t("buttonLabel.cancel", { ns: 'financialAid' })}
                </Button>
              </ButtonWrapperFilter>
            </div>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default AddCostOfAttendence;




