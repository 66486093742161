import { UPDATE_RUN_TAB, GET_DISABLED_TABS } from './types'

const initialState = {
    runTab: {
        evaluationTerm: 0,
        effectiveTerm: 0,
        loadProcess: '',
        processRunRows: [],
        saveAs: ''
    },
    disableTab: true

};
const sapChangesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case UPDATE_RUN_TAB:
            return {
                ...state,
                runTab: {
                    ...state.runTab, [payload['id']]: payload['value']
                }
            }
            case GET_DISABLED_TABS:
                return {
                    ...state,
                    disableTab: payload
                };
        default:
            return state;
    }
}
export default sapChangesReducer