import { ADD_COLLEGE, DELETE_COLLEGE, ADD_STUDENT_COLLEGE, DELETE_STUDENT_COLLEGE, SEARCH_COLLEGES } from './type'

const initialState = {
    college: [
    ],
    studentCollege: [
    ],
    searchColleges: [
    ]
};

const collegeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_COLLEGE:
            return {
                ...state,
                college: [...payload]
            }
        case DELETE_COLLEGE:
            return {
                ...state,
                college: [...state.college.filter(item => !payload.includes(item.id.toString()))]
            }
        case ADD_STUDENT_COLLEGE:
            return {
                ...state,
                studentCollege: [...payload]
            }
        case DELETE_STUDENT_COLLEGE:
            return {
                ...state,
                studentCollege: [...state.studentCollege.filter(item => !payload.includes(item.id.toString()))]
            }
        case SEARCH_COLLEGES:
            return {
                ...state,
                searchColleges: [...payload]
            }
        default:
            return state;
    }
};

export default collegeReducer;