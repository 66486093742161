export const prospect_search = [
    { name: 'Admissions', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Search Prospects', isLink: false }
];
export const prospect_screen = [
    { name: 'Admissions', path: { javascript: void (0) }, isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Add/Update Prospect', isLink: false }
];
export const prospect_addHighSchool = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'H.S./Scores', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update High School', isLink: false },
];
export const prospect_addTestScore = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'H.S./Scores', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Test', isLink: false },
];
export const prospect_addTestScore_addScore = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'H.S./Scores', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Score', isLink: false },
];
export const lookup_searchHighSchool = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Lookups', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'High School', isLink: false },
];
export const prospect_addActivity = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Activities', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Activity', isLink: false },
];
export const prospect_addSequence = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Activities', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Sequence', isLink: false },
];
export const prospect_addInterest = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Interests', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Interest', isLink: false },
];
export const prospect_addCollege = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Colleges', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update College', isLink: false },
];
export const lookup_searchCollege = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Lookups', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Colleges Names', isLink: false },
];
export const prospect_addContact = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Contacts', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Contact', isLink: false },
]
export const prospect_addPrevName = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Previous Name', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Previous Name', isLink: false },
]
export const prospect_addEthnicity = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Ethnicity', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Ethnicity', isLink: false },
]
export const prospect_addAward = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Awards', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Award', isLink: false },
]
export const prospect_addEmployer = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Employment', path: '/admissions/prospects/select-prospects/employment', isLink: true },
    { name: 'Add/Update Employer', isLink: false },
]
export const prospect_SearchCompany = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Employment', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Search Company', isLink: false },
]
export const prospect_addIdentifier = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Identifiers', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Identifier', isLink: false },
]
export const prospect_contact_addContact = [
    { name: 'Admissions', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Contacts', path: '/admissions/prospects/add-prospects', isLink: true },
    { name: 'Add/Update Contact ', isLink: false },
]
export const prospect_ProspectMerge = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-merge', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-merge', isLink: true },
    { name: 'Prospect Merge', isLink: false },
]


export const prospect_ProspectInternational = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'International', isLink: false },
]

export const prospect_International = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'International', isLink: false },
]

export const prospectInternational_AdmissionStatus = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'International', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Admission Status', isLink: false }
];
export const prospectInternational_highSchool = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'International', path: '/admissions/prospects/prospect-international', isLink: true },
    { name: 'Search High School', isLink: false }
];
export const prospect_citizenship = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-citizenship', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-citizenship', isLink: true },
    { name: 'Prospect Citizenship', isLink: false },
];
export const prospect_citizenship_addCitizenShip = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-citizenship', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-citizenship', isLink: true },
    { name: 'Citizenship', path: '/admissions/prospects/prospect-citizenship', isLink: true },
    { name: 'Add/Update Citizenship Detail', isLink: false }
];
export const prospect_prospectQuestions = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-questions', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-questions', isLink: true },
    { name: 'Prospect Questions', isLink: false }
];
export const prospect_prospectDocTracking = [
    { name: 'Admissions', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/select-prospects', isLink: true },
    { name: 'Prospect Document Tracking', isLink: false }
];

//Organization

export const search_organization = [
    { name: 'Admissions', path: '/admission/organization', isLink: true },
    { name: 'Organizations', path: '/admission/organization', isLink: true },
    { name: 'Search Organization', isLink: false }
];
export const add_organization = [
    { name: 'Admissions', path: '/admission/organization', isLink: true },
    { name: 'Organizations', path: '/admission/organization', isLink: true },
    { name: 'Add/Update Organization', isLink: false }
];
export const organization_addContact = [
    { name: 'Admissions', path: '/admission/organization', isLink: true },
    { name: 'Organizations', path: '/admission/organization', isLink: true },
    { name: 'Add/Update Contact', isLink: false }
];
export const organization_addActivity = [
    { name: 'Admissions', path: '/admission/organization', isLink: true },
    { name: 'Organizations', path: '/admission/organization', isLink: true },
    { name: 'Add/Update Activity', isLink: false }
]

//Lookups

export const lookups_letterSequence = [
    { name: 'Admissions', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Lookups', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Letter Sequences', isLink: false }
];

export const letterSequence_addSequenceGroup = [
    { name: 'Admissions', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Lookups', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Letter Sequences', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Add/Update Sequence Group', isLink: false }
];
export const letterSequence_addSequenceForGroup = [
    { name: 'Admissions', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Lookups', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Letter Sequences', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Add/Update Sequence', isLink: false }
];
export const letterSequence_deleteSequenceForGroup = [
    { name: 'Admissions', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Lookups', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Letter Sequences', path: '/admissions/lookups/letter-sequences', isLink: true },
    { name: 'Delete Sequence', isLink: false }
];
export const lookup_highSchool = [
    { name: 'Admissions', path: '/admission/lookups/highschool', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/highschool', isLink: true },
    { name: 'High School', isLink: false }
];
export const lookup_addHighSchool = [
    { name: 'Admissions', path: '/admission/lookups/highschool', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/highschool', isLink: true },
    { name: 'High School', path: '/admission/lookups/highschool', isLink: true },
    { name: 'Add/Update High School', isLink: false }
]
export const lookup_highSchool_addCode = [
    { name: 'Admissions', path: '/admission/lookups/highschool', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/highschool', isLink: true },
    { name: 'High School', path: '/admission/lookups/highschool', isLink: true },
    { name: 'Add/Update Code', isLink: false }
];
export const lookup_College = [
    { name: 'Admissions', path: '/admission/lookups/college', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/college', isLink: true },
    { name: 'College Names', isLink: false }
];
export const lookup_addCollege = [
    { name: 'Admissions', path: '/admission/lookups/college', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/college', isLink: true },
    { name: 'College Names', path: '/admission/lookups/college', isLink: true },
    { name: 'Add/Update College Name', isLink: false }
]
export const lookup_college_addCode = [
    { name: 'Admissions', path: '/admission/lookups/college', isLink: true },
    { name: 'Lookups', path: '/admission/lookups/college', isLink: true },
    { name: 'College Names', path: '/admission/lookups/college', isLink: true },
    { name: 'Add/Update Code', isLink: false }
];
export const admissions_DocumentTracking = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const admissions_addDocTracking = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects', isLink: true },
    { name: 'Document Tracking', path: '/admissions/prospects/document-tracking', isLink: true },
    { name: 'Add/Update Document Tracking', isLink: false }
];
export const admissions_loadDocSequence = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects', isLink: true },
    { name: 'Document Tracking', path: '/admissions/prospects/document-tracking', isLink: true },
    { name: 'Load Document Sequence', isLink: false }
];
export const admissions_loadColleges = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects', isLink: true },
    { name: 'Document Tracking', path: '/admissions/prospects/document-tracking', isLink: true },
    { name: 'Load Colleges', isLink: false }
];
//Students

export const search_student = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Search Student', isLink: false }
];

export const load_prospects = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Change Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Load Prospect', isLink: false },


];
export const student_screen = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Add/Update Student', isLink: false }
];

export const organization_search_lookup = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Organization', path: '/admissions/organization', isLink: true },
    { name: 'Search Organization', isLink: false }
];


export const student_addGPA = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Add/Update GPAGroup', isLink: false }
];

export const student_addHighSchool = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'High School/Scores', path: '/admissions/students/select-student', isLink: true },
    { name: 'Add/Update High School', isLink: false },
];

export const student_addTestScore = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'High School/Scores', path: '/admissions/students/select-student', isLink: true },
    { name: 'Add/Update Test Score', isLink: false },
];
export const student_addTestScore_addScore = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'High School/Scores', path: '/admissions/students/select-student', isLink: true },
    { name: 'Add/Update Score', isLink: false },
];

export const student_addActivity = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Activities', path: '/admissions/students/select-student/activities', isLink: true },
    { name: 'Add/Update Activity', isLink: false },
];
export const student_addSequence = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Activities', path: '/admissions/students/select-student/activities', isLink: true },
    { name: 'Add/Update Sequence', isLink: false },
];
export const student_addInterest = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Interests', path: '/admissions/students/select-student/interests', isLink: true },
    { name: 'Add/Update Interest', isLink: false },
];
export const student_addCollege = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Colleges', path: '/admissions/students/select-student/colleges', isLink: true },
    { name: 'Add/Update College', isLink: false },
];

export const student_addDegree = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Degrees', path: '/admissions/students/select-student/degrees', isLink: true },
    { name: 'Add/Update Degree', isLink: false },

];
export const student_addPrevName = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Previous Name', path: '/admissions/students/select-student/prev-name', isLink: true },
    { name: 'Add/Update Previous Name', isLink: false },
]
export const student_addRelations = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-student', isLink: true },
    { name: 'Relations', path: '/admissions/students/select-student/relations', isLink: true },
    { name: 'Add/Update Relations', isLink: false },
]
export const student_addFerpa = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-students', isLink: true },
    { name: 'FERPA', path: '/admissions/students/select-student/ferpa', isLink: true },
    { name: 'Add/Update FERPA', isLink: false },
]
export const student_addEthnicity = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-students', isLink: true },
    { name: 'Ethnicity', path: '/admissions/students/select-student/ethnicity', isLink: true },
    { name: 'Add/Update Ethnicity', isLink: false },
]
export const student_addEmployer = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-students', isLink: true },
    { name: 'Employment', path: '/admissions/students/select-student/employment', isLink: true },
    { name: 'Add/Update Employer', isLink: false },
]
export const student_addIdentifier = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Student', path: '/admissions/students/select-students', isLink: true },
    { name: 'Identifiers', path: '/admissions/students/select-students/identifiers', isLink: true },
    { name: 'Add/Update Identifier', isLink: false },
]

export const document_tracking_loadDocSequence = [
    { name: 'Admissions', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Load Document Sequence', isLink: false }
];

export const document_tracking_addDocumentTracking = [
    { name: 'Admissions', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Add/Update Document Tracking', isLink: false }
];
export const document_tracking_loadColleges = [
    { name: 'Admissions', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Load Colleges', isLink: false }
];
export const evaluation_applicant = [
    { name: 'Admissions', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Applicant', isLink: false },
];
export const evaluation_applicant_addCoureDetail = [
    { name: 'Admissions', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Applicant', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Add/Update Course Detail', isLink: false },

]
export const evaluation_applicant_loadPreRequisiteSequence = [
    { name: 'Admissions', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Applicant', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Load Prerequisite Sequence', isLink: false },
]
export const evaluation_applicant_addSummary = [
    { name: 'Admissions', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Applicant', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Add/Update Summary', isLink: false },
]
export const evaluation_applicant_addStudentCollege = [
    { name: 'Admissions', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Applicant', path: '/admissions/students/evaluations/applicant', isLink: true },
    { name: 'Add/Update Student College', isLink: false },
]
export const evaluation_setup = [
    { name: 'Admissions', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/setup', isLink: true },
    { name: 'Setup', isLink: false },
];
export const evaluation_setup_addSubject = [
    { name: 'Admissions', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/setup', isLink: true },
    { name: 'Setup', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Add/Update Subject', isLink: false },

];

export const evaluation_setup_addSubjectGrouping = [
    { name: 'Admissions', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/setup', isLink: true },
    { name: 'Setup', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Add/Update Subject Grouping', isLink: false },
];
export const evaluation_setup_addSequence = [
    { name: 'Admissions', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/setup', isLink: true },
    { name: 'Setup', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Add/Update Sequence', isLink: false },
]
export const evaluation_setup_addSemester = [
    { name: 'Admissions', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Students', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Evaluations', path: '/admissions/students/evaluations/setup', isLink: true },
    { name: 'Setup', path: '/admissions/students/evaluation/setup', isLink: true },
    { name: 'Add/Update Semester Type', isLink: false },
]
export const student_sevis_navigation = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/student-sevis', isLink: true },
    { name: 'SEVIS', isLink: false }
];
export const student_sevis_addDependent = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/student-sevis', isLink: true },
    { name: 'SEVIS', path: '/admissions/students/student-sevis', isLink: true },
    { name: 'Add/Update Dependent', isLink: false }

];
export const laptop_tracking_details = [
    { name: 'Admissions', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Laptop Tracking', isLink: false }
];
export const laptop_tracking_details_addEquipment = [
    { name: 'Admissions', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Laptop Tracking', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Add/Update Equipment', isLink: false }
];
export const laptop_tracking_details_addEquipmentItem = [
    { name: 'Admissions', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Laptop Tracking', path: '/admissions/students/laptop-tracking', isLink: true },
    { name: 'Add/Update Equipment Item', isLink: false }
];
export const student_risk = [
    { name: 'Admissions', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Students', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Student Risk', isLink: false }
];
export const student_risk_addRiskIndicator = [
    { name: 'Admissions', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Students', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Student Risk', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Add/Update Risk Indicator', isLink: false }

];
export const student_risk_addRiskIndicator_note = [
    { name: 'Admissions', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Students', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Student Risk', path: '/admissions/students/student-risk', isLink: true },
    { name: 'Add/Update Risk Indicator Note', isLink: false }

];
export const student_studentDocumentTracking = [
    { name: 'Admissions', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Students', path: '/admissions/students/document-tracking', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const student_byor = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Reports', path: '/admissions/students/student-reports', isLink: true },
    { name: 'BYOR', isLink: false }

];
export const reports_document_tracking = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Reports', path: '/admissions/students/student-reports', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const reports_working_to_do = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Reports', path: '/admissions/students/student-reports', isLink: true },
    { name: 'Working To-Do', isLink: false },

];
export const add_working_to_do = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Reports', path: '/admissions/students/student-reports', isLink: true },
    { name: 'Working To Do Data', isLink: false }
];
export const admissions_addNotes = [
    { name: 'Admissions', path: '/admissions/students', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Reports', path: '/admissions/students/student-reports', isLink: true },
    { name: 'Add/Update Student notes', isLink: false }
];
export const search_student_lookup = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Lookups', path: '/admissions/lookups', isLink: true },
    { name: 'Search Student', isLink: false },
]

export const student_addContact = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Students', path: '/admissions/students/select-students', isLink: true },
    { name: 'Contacts', path: '/admissions/students/select-students/contacts', isLink: true },
    { name: 'Add/Update Contact', isLink: false },
]
export const student_SearchCompany = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Search Students', path: '/admissions/students/select-students', isLink: true },
    { name: 'Employment', path: '/admissions/students/select-students/employment', isLink: true },
    { name: 'Search Company', isLink: false },
]
export const student_studentInternational = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student International', isLink: false },
]
export const studentInternational_AdmissionStatus = [
    { name: 'Admissions', path: '/admissions/students/select-students', isLink: true },
    { name: 'Students', path: '/admissions/students/student-international', isLink: true },
    { name: 'Students International', path: '/admissions/students/student-international', isLink: true },
    { name: 'Admission Status', isLink: false }
];
export const studentInternational_highSchool = [
    { name: 'Admissions', path: '/admissions/prospects/select-students', isLink: true },
    { name: 'Students', path: '/admissions/students/student-international', isLink: true },
    { name: 'Students International', path: '/admissions/students/student-international', isLink: true },
    { name: 'Search High School', isLink: false }
];

export const search_contact = [
    { name: 'Admissions', path: '/admission/contacts', isLink: true },
    { name: 'Contacts', path: '/admission/contacts', isLink: true },
    { name: 'Search Contact', isLink: false }
];

export const admission_students_reports_addNoteButton = [
    { name: 'Home', path: '/dashboard', isLink: true },
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-students', isLink: true },
    { name: 'Reports', path: '/admissions', isLink: true },
    { name: 'Working To Do Data', isLink: false }
];

export const admission_student_reports_findProspect = [
    { name: 'Home', path: '/dashboard', isLink: true },
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-students', isLink: true },
    { name: 'Reports', path: '/admissions', isLink: true },
    { name: 'Working To Do Data', isLink: false }
];

export const admission_addProspect_selectAddress = [
    { name: 'Home', path: '/dashboard', isLink: true },
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Add Prospect', isLink: false }
];

export const admission_student_notes = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Notes', isLink: false }
];
export const student_student_status = [
    { name: 'Admissions', path: '/admissions/students/select-student', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Student Status', isLink: false }
];
export const admission_studentStatus_add = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Student Status', path: '/admissions/student-status', isLink: true },
    { name: 'Add/Update Student Status', isLink: false }

]
export const admission_addnotes = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Notes', path: '/admissions/students/student-notes', isLink: true },
    { name: 'Add/Update Notes', isLink: false }
];
export const admission_addUserList = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Student Notes', path: '/admissions/students/student-notes', isLink: true },
    { name: 'Groups', path: '/admissions/students/student-notes/groups', isLink: true },
    { name: 'User List Criteria', isLink: false }
];
export const academic_admission_student_program = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Student Status', path: '/admissions/student-status', isLink: true },
    { name: 'Add/Update Student Programs', isLink: false }
];
export const admission_studentStaus_addExtracurricula = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Student Status', path: '/admissions/student-status', isLink: true },
    { name: 'Add/Update Extra Curricular Activities', isLink: false }

]
export const academic_admission_student_status = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students/select-student', isLink: true },
    { name: 'Student Status', path: '/admissions/student-status', isLink: true },
    { name: 'Update Student Status', isLink: false }
]
export const admission_change_address = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Change Address', isLink: false }
];
export const admission_change_address_copy = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Change Address', path: '/admissions/students/change-address', isLink: true },
    { name: 'Copy Address', isLink: false }
];
export const admission_add_address = [
    { name: 'Admissions', path: '/admissions', isLink: true },
    { name: 'Students', path: '/admissions/students', isLink: true },
    { name: 'Change Address', path: '/admissions/students/change-address', isLink: true },
    { name: 'Add/Update Address', isLink: false }
];
export const prospect_ProspectNotes = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-notes', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-notes', isLink: true },
    { name: 'Prospect Notes', isLink: false },
];
export const add_prospect_ProspectNotes = [
    { name: 'Admissions', path: '/admissions/prospects/prospect-notes', isLink: true },
    { name: 'Prospects', path: '/admissions/prospects/prospect-notes', isLink: true },
    { name: 'Prospect Notes', isLink: false },
    { name: 'Add/Update Prospect Note Entry', isLink: false },

]
