import React from 'react';
import ReactDOM from 'react-dom';
import Handlebars from "handlebars";
import { Card, CardContent } from '@material-ui/core';

export const RenderComponent = ({ template, templateData }) => {

    const Aftertemplate = Handlebars.compile(template);
    // Handlebars.registerHelper("each", function (context, options) {
    //     var ret = "";
    //     for (var i = 0, j = context.length; i < j; i++) {
    //         ret = ret + options.fn(context[i].termList);
    //     }
    //     return ret;
    // });

    return (
        <>
            <Card>
                <CardContent>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: Aftertemplate(templateData) }}></div>
                        <div dangerouslySetInnerHTML={{ __html: `<style> @page {@top-right {content: "Page " counter(pageNumber);}} @media print{ @page :header {display: none} .print{display:none;}}.print{background-color: #F9B61D;color: #000000;border-color:#F9B61D; min-height: 36px; padding: 0px 12px 0px 12px; vertical-align: middle; min-width: 150px; text-transform: none; height: 42px; box-shadow: none; margin-bottom: 13px; margin-right: 102px; float: right; font-family: Lato-regular,sans-serif; font-weight: 600;}</style><button class="print" onclick=" window.scroll({top: 0,left: 0}) ; window.print();">Export to PDF</button>` }}></div>
                    </div>
                </CardContent>
            </Card >

        </>
    )
}


const ExportComponent = (templateHtml, templateCss, templateData) => {
    let templateArray = templateHtml.split('<head>');
    let cssPart = `<head><style>${[templateCss]}</style></head>`;
    let mergedTemplate = templateArray[0].concat(cssPart, templateArray[1])
    if (templateData !== "") {
        let myWindow = window.open("", "MsgWindow", "location=1,status=1,scrollbars=1, width=1150,height=800");
        ReactDOM.render(<RenderComponent
            template={mergedTemplate}
            templateData={templateData}
        />,
            myWindow.document.body);
    }

}

export default ExportComponent;