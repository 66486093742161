import { GET_PACKAGE, GET_PACKAGEID, UPDATE_PACKAGE_LIST } from './types';

const initialState = {
    academicYear: -1,
    financialAidYear: null,
    TableData:[],
    packageId: {},
    packageList: [],


}

const packageReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_PACKAGE:
            return payload

            case GET_PACKAGEID: {
                return {
                    ...state,
                    packageId: { ...payload }
                }
            }
            case UPDATE_PACKAGE_LIST:
                return {
                    ...state, 
                    packageList: state.packageList.concat(payload)
                }
       
        default:
            return state;
    }
}

export default packageReducer;

