import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles,
  Splitter,
  ButtonWrapper,
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import AddUserdefinedfields from "./adduserdefinedfields";
import AddReport from "./addreport";
import { more_tab, more_tab_billing, more_tab_address, more_tab_userdefined_select } from "../../../../services/apiURL";
import { getApi } from '../../../../services/apiBase';

const More = ({ getSelectedRow, addDatas, selectedSelected }) => {
  const onClose = (value) => {
    addDatas(false);
    setShowAddData(value);
    document.getElementsByTagName("html")[0].classList.remove("popup-Page");
  };

  const [showAddData, setShowAddData] = useState(false);

  const [showAcademicProgress, setShowAcademicProgress] = useState(false);

  const [showBilling, setShowBilling] = useState(false);

  const [showReports, setShowReports] = useState(false);

  const [showAddress, setShowAddress] = useState(false);

  const [showUserdefinedFields, setShowUserdefinedFields] = useState(false);

  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);

  const initialValues = {
    academicProgress:""
  };

  const [academicProgresstable] = useState([
    { id: 'textTerm', numeric: true, disablePadding: false, label: t('academiProgresstable.term', { ns: 'financialAidHeadcells' }) },
    { id: 'gpaGrouping', numeric: true, disablePadding: false, label: t('academiProgresstable.gpaGrouping', { ns: 'financialAidHeadcells' }) },
    { id: 'major', numeric: true, disablePadding: false, label: t('academiProgresstable.major', { ns: 'financialAidHeadcells' }) },
    { id: 'termTranscriptCompRate', numeric: true, disablePadding: false, label: t('academiProgresstable.termTranscriptCompRate', { ns: 'financialAidHeadcells' }) },
    { id: 'termCreditsCompRate', numeric: true, disablePadding: false, label: t('academiProgresstable.termCreditsCompRate', { ns: 'financialAidHeadcells' }) },
    { id: 'cumTranscriptCompRate', numeric: true, disablePadding: false, label: t('academiProgresstable.cumTranscriptCompRate', { ns: 'financialAidHeadcells' }) },
    { id: 'cumCreditsCompRate', numeric: true, disablePadding: false, label: t('academiProgresstable.cumCreditsCompRate', { ns: 'financialAidHeadcells' }) },
    { id: 'credits', numeric: true, disablePadding: false, label: t('academiProgresstable.credits', { ns: 'financialAidHeadcells' }) },
    { id: 'attHours', numeric: true, disablePadding: false, label: t('academiProgresstable.attHours', { ns: 'financialAidHeadcells' }) },
    { id: 'termGPA', numeric: true, disablePadding: false, label: t('academiProgresstable.termGPA', { ns: 'financialAidHeadcells' }) },
    { id: 'cumCredits', numeric: true, disablePadding: false, label: t('academiProgresstable.cumCredits', { ns: 'financialAidHeadcells' }) },
    { id: 'cumAttemptedHours', numeric: true, disablePadding: false, label: t('academiProgresstable.cumAttemptedHours', { ns: 'financialAidHeadcells' }) },
    { id: 'cumEarnedHours', numeric: true, disablePadding: false, label: t('academiProgresstable.cumEarnedHours', { ns: 'financialAidHeadcells' }) },
    { id: 'cumGPA', numeric: true, disablePadding: false, label: t('academiProgresstable.cumGPA', { ns: 'financialAidHeadcells' }) },
    { id: 'academicStatus', numeric: true, disablePadding: false, label: t('academiProgresstable.academicStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'registerStatus', numeric: true, disablePadding: false, label: t('academiProgresstable.registerStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'enrollmentStatus', numeric: true, disablePadding: false, label: t('academiProgresstable.enrollmentStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'gpaGroupID', numeric: true, disablePadding: false, label: t('academiProgresstable.gpaGroupID', { ns: 'financialAidHeadcells' }) },
  ]);

  const [billingtable] = useState([
    { id: 'term', numeric: true, disablePadding: false, label: t('billingtable.term', { ns: 'financialAidHeadcells' }) },
    { id: 'transdoc', numeric: true, disablePadding: false, label: t('billingtable.transDoc', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAid', numeric: true, disablePadding: false, label: t('billingtable.financialAid', { ns: 'financialAidHeadcells' }) },
    { id: 'debits', numeric: true, disablePadding: false, label: t('billingtable.debits', { ns: 'financialAidHeadcells' }) },
    { id: 'credits', numeric: true, disablePadding: false, label: t('billingtable.credits', { ns: 'financialAidHeadcells' }) },
    { id: 'balance', numeric: true, disablePadding: false, label: t('billingtable.balance', { ns: 'financialAidHeadcells' }) },

  ]);

  const [adress] = useState([
    { id: 'addressType', numeric: true, disablePadding: false, label: t('adress.addressType', { ns: 'financialAidHeadcells' }) },
    { id: 'activeFlag', numeric: true, disablePadding: false, label: t('adress.activeFlag', { ns: 'financialAidHeadcells' }) },
    { id: 'address1', numeric: true, disablePadding: false, label: t('adress.address1', { ns: 'financialAidHeadcells' }) },
    { id: 'town', numeric: true, disablePadding: false, label: t('adress.town', { ns: 'financialAidHeadcells' }) },
    { id: 'city', numeric: true, disablePadding: false, label: t('adress.city', { ns: 'financialAidHeadcells' }) },
    { id: 'state', numeric: true, disablePadding: false, label: t('adress.state', { ns: 'financialAidHeadcells' }) },
    { id: 'address2', numeric: true, disablePadding: false, label: t('adress.address2', { ns: 'financialAidHeadcells' }) },
    { id: 'address3', numeric: true, disablePadding: false, label: t('adress.address3', { ns: 'financialAidHeadcells' }) },
    { id: 'county', numeric: true, disablePadding: false, label: t('adress.county', { ns: 'financialAidHeadcells' }) },
    { id: 'zipCode', numeric: true, disablePadding: false, label: t('adress.zipCode', { ns: 'financialAidHeadcells' }) },
    { id: 'country', numeric: true, disablePadding: false, label: t('adress.country', { ns: 'financialAidHeadcells' }) },
    { id: 'phone1', numeric: true, disablePadding: false, label: t('adress.phone1', { ns: 'financialAidHeadcells' }) },
    { id: 'phone2', numeric: true, disablePadding: false, label: t('adress.phone2', { ns: 'financialAidHeadcells' }) },
    { id: 'workPhone1', numeric: true, disablePadding: false, label: t('adress.workPhone', { ns: 'financialAidHeadcells' }) },
    { id: 'fax1', numeric: true, disablePadding: false, label: t('adress.fax1', { ns: 'financialAidHeadcells' }) },
    { id: 'email1', numeric: true, disablePadding: false, label: t('adress.email1', { ns: 'financialAidHeadcells' }) },
    { id: 'email2', numeric: true, disablePadding: false, label: t('adress.email2', { ns: 'financialAidHeadcells' }) },
    { id: 'email3', numeric: true, disablePadding: false, label: t('adress.email3', { ns: 'financialAidHeadcells' }) },
    { id: 'responsibleParty', numeric: true, disablePadding: false, label: t('adress.responsibleParty', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('adress.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('adress.insertTime', { ns: 'financialAidHeadcells' }) },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('adress.updateUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('adress.updateTime', { ns: 'financialAidHeadcells' }) },
    { id: 'mobilePhone', numeric: true, disablePadding: false, label: t('adress.mobilePhone', { ns: 'financialAidHeadcells' }) },
    { id: 'internationalFlag', numeric: true, disablePadding: false, label: t('adress.internationalFlag', { ns: 'financialAidHeadcells' }) },
    { id: 'accountID', numeric: true, disablePadding: false, label: t('adress.accountID', { ns: 'financialAidHeadcells' }) },
  ]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const classess = useStyles();
  const [tableRow, setTableRow] = useState({
    acedamicProgressRow: [],
    billingRow: [],
    addressRow: []
  });
  const [Dropdown, setDropdown] = useState({
    "brandonTest1Drop": [],
    "brandonTest2Drop": [],
    "brandonTest3Drop": []
  })
  useEffect(() => {
    let studentUId = selectedSelected.studentUID;
    Promise.all([getApi(`${more_tab}/${studentUId}`), getApi(`${more_tab_billing}/${studentUId}`), getApi(`${more_tab_address}/${studentUId}`)])
      .then((resp) => {
        setTableRow({
          ...tableRow,
          acedamicProgressRow: resp[0].data.data,
          billingRow: resp[1].data.data,
          addressRow: resp[2].data.data
        })
      })
    getApi(more_tab_userdefined_select).then((resp) => {
      const { userDefGlossary1, userDefGlossary2, userDefGlossary3 } = resp.data.data
      setDropdown({
        ...Dropdown,
        "brandonTest1Drop": userDefGlossary1,
        "brandonTest2Drop": userDefGlossary2,
        "brandonTest3Drop": userDefGlossary3,
      })
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  const academicProgress = (values) => {
    setShowAcademicProgress(true);
    setShowBilling(false);
    setShowReports(false); 
    setShowAddress(false);
    // setShowEmail(false);
    setShowUserdefinedFields(false);
    formik.setFieldValue("academicProgress","academicProgress")
  };

  const billing = (values) => {
    setShowBilling(true);
    setShowAcademicProgress(false);
    setShowReports(false); 
    setShowAddress(false);
    // setShowEmail(false);
    setShowUserdefinedFields(false);
    formik.setFieldValue("academicProgress","Billing")
  };

  const reports = (values) => {

    setShowAddData(true);
    setShowBilling(false);
    setShowAcademicProgress(false);
    setShowReports(true);
    setShowAddress(false);
    // setShowEmail(false);
    setShowUserdefinedFields(false);
    addDatas(true);
    formik.setFieldValue("academicProgress","Reports")
  };

  const address = (values) => {
    setShowBilling(false);
    setShowAcademicProgress(false);
    setShowReports(false);
    setShowAddress(true);
    // setShowEmail(false);
    setShowUserdefinedFields(false);
    formik.setFieldValue("academicProgress","Address")
  };

  const email = (value) => {
    setShowAddData(true);
    setShowBilling(false);
    setShowAcademicProgress(false);
    setShowReports(false);
    setShowAddress(false);
    setShowUserdefinedFields(true);
    addDatas(true);
  };
  

  return (
    <>
      {!showAddData ? (
        <>

          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={classess.root}
          >
            <Card>
              <CardContent className={"headerTitle"}>
                <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.moreInformation", { ns: "financialAid" })} />
                <FormGroup>
                  <Grid container className={"jc"}>
                    <CardContent>
                      <ButtonWrapper>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={academicProgress}
                        >
                          {t("buttonLabel.academicProgress", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={billing}
                        >
                          {t("buttonLabel.billing", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={reports}
                        >
                          {t("buttonLabel.report", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={address}
                        >
                          {t("buttonLabel.address", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={email}
                        >
                          {t("buttonLabel.userDefinedFields", { ns: "financialAid" })}
                        </Button>
                      </ButtonWrapper>
                    </CardContent>
                  </Grid>
                </FormGroup>
                {showAcademicProgress ? (
                  <HeaderTitle variant={'subHeading'}
                    headerTitle={t("commonLabel.academicProgress", {
                      ns: "financialAid",
                    })}
                  />
                ) : showBilling ? (
                  <HeaderTitle variant={'subHeading'}
                    headerTitle={t("commonLabel.billing", {
                      ns: "financialAid",
                    })}
                  />                        
                ) : showAddress && (
                  <HeaderTitle variant={'subHeading'}
                    headerTitle={t("commonLabel.address", {
                      ns: "financialAid",
                    })}
                  />
                )}
                {showAcademicProgress ? (
                  <CardContent>
                  <DefaultTable
                    headCells={academicProgresstable}
                    rows={tableRow.acedamicProgressRow}
                    pagination={true}
                    onRowSelect={getSelectedRow}
                  />
                  <Splitter height={"10px"} />
                  <div style={{ display: "flex" }}>
                    <ButtonWrapperFilter>
                      <Button
                        aria-label={"Cancel"}
                        className="BgLightBlue"
                        variant="contained"
                        size="large"
                        onClick={() => onClose("")}
                      >
                        {t("buttonLabel.cancel", {
                          ns: "financialAid",
                        })}
                        </Button>
                    </ButtonWrapperFilter>
                  </div>
                </CardContent>
                ) : showBilling ? (
                  <CardContent>
                    <DefaultTable
                      // trackingLabel={"batchesBilling"}
                      headCells={billingtable}
                      rows={tableRow.billingRow}
                      pagination={true}
                      onRowSelect={getSelectedRow}
                    />
                    <Splitter height={"10px"} />
                    <div style={{ display: "flex" }}>
                      <ButtonWrapperFilter>
                        <Button
                          aria-label={"Cancel"}
                          className="BgLightBlue"
                          variant="contained"
                          size="large"
                          onClick={() => onClose("")}
                        >
                          {t("buttonLabel.cancel", {
                            ns: "financialAid",
                          })} </Button>
                      </ButtonWrapperFilter>
                    </div>
                  </CardContent>
                ) : showAddress && (
                  <CardContent>
                    <DefaultTable
                      // trackingLabel={"batchesBilling"}
                      headCells={adress}
                      rows={tableRow.addressRow}
                      pagination={true}
                      onRowSelect={getSelectedRow}
                    />
                    <Splitter height={"10px"} />
                    <div style={{ display: "flex" }}>
                      <ButtonWrapperFilter>
                        <Button
                          aria-label={"Cancel"}
                          className="BgLightBlue"
                          variant="contained"
                          size="large"
                          onClick={() => onClose("")}
                        >
                          {t("buttonLabel.cancel", {
                            ns: "financialAid",
                          })}
                        </Button>
                      </ButtonWrapperFilter>
                    </div>
                  </CardContent>
                )}
              </CardContent>
            </Card>
          </form>
        </>
      )
        : showReports ? (
          < AddReport onClose={onClose} addDatas={addDatas} />
        ) :
          showUserdefinedFields && (
            < AddUserdefinedfields onClose={onClose} addDatas={addDatas} Dropdown={Dropdown} selectedSelected={selectedSelected} />)

      }
    </>
  );
};

export default More;
