import {
    GET_CRITERIA_DATA,
    GET_RECORDS_DATA,
    GET_REPORTS_DATA
} from './types';


const initialState = {
    criteria: "",
    records: "",
    reports: "",
};

const Form1098TReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERIA_DATA:
            return {
                ...state,
                criteria: {...payload}

            }
        case GET_RECORDS_DATA:
            return {
                ...state,
                records: { ...payload }
            }
        case GET_REPORTS_DATA: {
            return {
                ...state,
                reports: { ...payload }
            };
        }

        default:
            return state;
    }

};
export default Form1098TReducer;