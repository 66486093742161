import { P_SINGLE, SET_SINGLE_PACKAGE_CRITERIA } from './types'
//const initialState = {

//};

const initialState = {
    singlePackageCriteria:
    {
        academicYear: "",
        financialAidYear: "",
        packageEnrollmentIntensity: "",
        packageAsLoad: "",
        directCost: "",
        distributionForAwardType: "",
        updateExistingStatusRecords: false,
        useSAI: true,
        rowCount: undefined,
        totalDistributionPercent: undefined
    }
};

const singlePackageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case P_SINGLE:
            return {
                ...payload
            }
        case SET_SINGLE_PACKAGE_CRITERIA:
            return {
                ...state,
                singlePackageCriteria: payload
            }
        default:
            return state;
    }
}
export default singlePackageReducer;