import React, { useState, useEffect, useRef } from 'react';
import { FormInputWrapper } from '../../styles/common-styles'
import { InputLabel, TextField, FormHelperText, TextareaAutosize, Checkbox } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import GenericModal from '../Modal/GenericModal';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { phoneInputMask, phoneMaskingPatternWise, phoneKeyInputMask } from '../../helper/formatePhone';
import { formatSSNVisibility } from '../../helper/formatSSN';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * handlelookUp: Function, 
 * isDoubled?: Boolean,
 * disabled: Boolean,
 * errors?: formik.errors.labelId,
 * touched?: formik.touched.labelId,
 * mandatory: Boolean,
 * iconHide: Boolean,
 * formik: Function
 * isIconDisabled: Boolean
 * }} props 
 */

export const MultipleSearchSelectInput = (props) => {
    const { t } = useTranslation();
    let inputElement = useRef();
    const { onCustomChange, label, labelId, formik, DropdownData, mandatory, errors, touched, isDoubled, disabled, customStyle, iconHide, isTripple, formikValue } = props;
    const [optionTList, setOptionTList] = React.useState([]);
    const [labelValue, setLabelValue] = useState('');
    const [defaultValue, setDefaultValue] = useState([]);
    const [isAlert, setAlert] = useState(false);
    const [termName, setTermName] = useState(null);
    let inputRef;
    const getTOptions = () => {
        let options = [];
        for (let key in DropdownData) {
            options.push({ id: DropdownData[key].uniqueId, label: DropdownData[key].displayText })
        }
        return options;
    }
    useEffect(() => {
        setOptionTList(getTOptions());
    }, [DropdownData])

    const handleButtonPress = () => {
        setAlert(false);
        inputRef.focus();
    }

    useEffect(() => {
        if (DropdownData.length > 0) {
            const displayText = DropdownData.filter(value => value.uniqueId == formik.values[labelId])[0]?.displayText;
            displayText ? setLabelValue(displayText) : setLabelValue("");
            setDefaultValue([DropdownData[1]]);
        }
    }, [formik.values[labelId], DropdownData])
    return (
        <>
            {isAlert &&
                <GenericModal title={t('commonLabel.termNotFoundAlert', { what: termName })}
                    open={isAlert} onClose={setAlert}
                    onButtonPress={handleButtonPress}
                    buttonText={t('buttonLabel.ok')}
                />
            }

            <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} isTripple={isTripple ? isTripple : null} className={isDoubled ? 'doubled' : ''}>
                <InputLabel htmlFor="labelValue">{label}<span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></InputLabel>
                <Autocomplete
                    multiple
                    id="labelValue"
                    disableClearable
                    options={DropdownData}
                    openOnFocus
                    limitTags={3}
                    disableCloseOnSelect
                    onChange={(event, newValue) => {
                        formik.setFieldValue(labelId, (newValue.length > 0) ? newValue.map(e => e.uniqueId) : []);
                        onCustomChange && onCustomChange(event, newValue);
                    }}
                    getOptionLabel={(option) => option?.displayText}
                    defaultValue={formikValue}
                    // inputValue={labelValue}
                    disabled={disabled}
                    onInputChange={(event, newInputValue) => {
                        setLabelValue(newInputValue);
                    }}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li {...props} key={option.uniqueId}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.displayText}
                            </li>
                        );
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={errors && touched ? true : false}
                            disabled={disabled}
                            name={labelId}
                            inputRef={input => {
                                inputRef = input;
                            }}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (!iconHide) && <InputAdornment position="start"><span style={{ cursor: 'pointer', color: '#0000008a' }} className="material-icons-outlined">
                                    info
                                </span></InputAdornment>
                            }} />}
                /> 
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}
            </FormInputWrapper>
        </>

    )
}