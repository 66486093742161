import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles,
  ButtonWrapper,
} from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../components/Forms";
import { useHistory } from "react-router-dom";
import AddDocuments from "./addDocuments";
import AddLoadDocSequence from "./addLoadDocSequence";
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import { getApi, deleteApi } from "../../../../services/apiBase";
import { get_documents, document_tracking } from "../../../../services/apiURL";
import { toastMessage } from '../../../../utils/Message/toasts';
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";

const Documents = ({ addDatas, selectedSelected, selectedStatusRow, fyDropDown }) => {
  const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
  const dispatch = useDispatch();
  const initialValues = {
    filtertoPackage: selectedStatusRow.financialAidYearUID
  };
  const [documentData] = useState([
    { id: 'docDate', numeric: true, disablePadding: false, label: t('document.docDate', { ns: 'financialAidHeadcells' }), dateTime: 'true' },
    { id: 'docName', numeric: true, disablePadding: false, label: t('document.docName', { ns: 'financialAidHeadcells' }) },
    { id: 'docDescription', numeric: true, disablePadding: false, label: t('document.docDescription', { ns: 'financialAidHeadcells' }) },
    { id: 'docStatus', numeric: true, disablePadding: false, label: t('document.docStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'comments', numeric: true, disablePadding: false, label: t('document.comments', { ns: 'financialAidHeadcells' }) },
    { id: 'reference', numeric: true, disablePadding: false, label: t('document.reference', { ns: 'financialAidHeadcells' }) },
    { id: 'compDate', numeric: true, disablePadding: false, label: t('document.compDate', { ns: 'financialAidHeadcells' }),dateTime:'true' },
    { id: 'userDefinedField', numeric: true, disablePadding: false, label: t('document.userDefinedField', { ns: 'financialAidHeadcells' }) },
    { id: 'location', numeric: true, disablePadding: false, label: t('document.location', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('document.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('document.insertTime', { ns: 'financialAidHeadcells' }) },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('document.updateUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('document.updateTime', { ns: 'financialAidHeadcells' }) },
    { id: 'internal', numeric: true, disablePadding: false, label: t('document.internal', { ns: 'financialAidHeadcells' }) },

  ]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {

    },
  });
  let history = useHistory();
  const classes = useStyles();
  const [showAddData, setShowAddData] = useState(false);
  const [showAddDoc, setShowAddDoc] = useState(false);
  const [showLoadSeq, setShowLoadSeq] = useState(false);
  const [tableRow, setTableRow] = useState({
    financialAwardsRow: [],
  });
  const [financialAwards, setFinancialAwards] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getDocument = (selectedSelected) => {
    if (selectedSelected.studentUID) {
      const studentUId = selectedSelected.studentUID
      setIsLoading(true)
      getApi(`${get_documents}/${studentUId}`).then((resp) => {
        setTableRow({
          ...tableRow,
          financialAwardsRow: resp.data.data
        })
        setIsLoading(false)
        if ( Number(formik.values.filtertoPackage) === -1) {
          setFinancialAwards(resp.data.data);
          setIsLoading(false)
          return
        }
        if (resp.data.data.length > 0) {
          const updatedRows = resp.data.data?.filter(row => row.finAidYearID === Number(formik.values.filtertoPackage));
          setFinancialAwards(updatedRows);

        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }
  useEffect(() => {
    getDocument(selectedSelected)
  }, [selectedSelected])
  
  useEffect(() => {
    const rows = tableRow?.financialAwardsRow;
    DefaultTable.resetTable('documentDataFinancial');
    if (formik.values.filtertoPackage == -1) {
      setFinancialAwards(rows);
      return;
    }
    const updatedRows = rows.filter(row => row.finAidYearID === formik.values.filtertoPackage);
    setFinancialAwards(updatedRows);
  }, [formik.values.filtertoPackage])

  const onClose = () => {
    addDatas(false);
    setShowAddData(false);
    getDocument(selectedSelected)
    document.getElementsByTagName("html")[0].classList.remove("popup-Page");

  };
  const handleEdit = (val) => {
    setShowAddData(val);
    setShowAddDoc(val)
    addDatas(true)
    setShowLoadSeq(false)
  }
  const handleDelete = () => {
    setIsLoading(true)
    deleteApi(`${document_tracking}/${selectedDoc.docTrackID}/${selectedDoc.studentUID}`).then((res) => {
      if (res.data.isSuccess) {
        toastMessage(t('popupMessages.successIcon'),t('popupMessages.success'),t('popupMessages.successfullyDeleted'))
        getDocument(selectedSelected)
      }
    }).catch((err) => {
      console.log(err)
      setIsLoading(false)
    })
  }
  const [selectedDoc, setSelectedDoc] = useState({})
  const handleRowSelect = (val) => {
    setSelectedDoc(val)
  }

  const handleAddData = (val) => {
    setShowAddData(true);
    setShowAddDoc(true)
    addDatas(true)
    setShowLoadSeq(false)
    setSelectedDoc('')
  }
  // Role based access control redux
  const { access } = useSelector(updateAccessSelector)
  const { nestedParentLevel } = access;

  const [statusAccess, setStatusAccess] = useState({
    maintenance: 2,
  })
  useEffect(() => {


    let finStatusAccess = nestedParentLevel.filter((v, i) =>
      v.menuItemName.trim() === maintenance && v.moduleorder === 4
    )

    setStatusAccess({
      ...statusAccess,
      maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
    })

  }, [access])
  const [accessFlag, setAccessFlag] = useState(false)
  useEffect(() => {
    (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
  }, [statusAccess])
  //Checking Change prospect access Ends here


  return (

    <>
      {!showAddData ?
        <>
          <form onSubmit={formik.handleSubmit} className={classes.root}>
            <Card>

              <CardContent className={"headerTitle"}>
                <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.financialDocuments", { ns: "financialAid" })} />
                <ButtonWrapper className="ButtonDistribute">
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    type="button"
                    disabled={accessFlag}
                    onClick={() => {
                      setShowAddData(true);
                      setShowAddDoc(false)
                      addDatas(true)
                      setShowLoadSeq(true)
                    }}
                  >
                    {t("buttonLabel.loadDocSequence", { ns: "financialAid" })}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    type="button"
                    disabled={accessFlag}
                    onClick={handleAddData}
                  >
                    <span className="plus">+</span>{" "}
                    {t("buttonLabel.addDocument", { ns: "financialAid" })}
                  </Button>
                </ButtonWrapper>
                <FormGroup>
                  <SelectInput
                    label={t("commonLabel.filtertoPackage", { ns: "financialAid" })}
                    labelId={"filtertoPackage"}
                    formikValue={formik.values.filtertoPackage}
                    formikChange={formik.handleChange}
                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: fyDropDown }}
                  />
                </FormGroup>
                <DefaultTable
                  trackingLabel={"documentDataFinancial"}
                  headCells={documentData}
                  rows={financialAwards}
                  pagination={true}
                  onRowSelect={handleRowSelect}
                  isLoading={isLoading}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  deleteDisable={accessFlag}
                />
              </CardContent>

            </Card>

            <ButtonWrapperFilter>
              <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={() => { dispatch(getSelectedProfiles({})); history.push('/financial-aid/student-search'); }}>
                {t('buttonLabel.cancel')}
              </Button>
            </ButtonWrapperFilter>
          </form>

        </> : (showAddDoc) ? <AddDocuments accessFlag={accessFlag} addDatas={addDatas} onClose={onClose} selectedDoc={selectedDoc} selectedStatusRow={selectedStatusRow} filtertoPackage={formik.values.filtertoPackage} selectedSelected={selectedSelected} fyDropDown={fyDropDown} />
          : (showLoadSeq) && <AddLoadDocSequence addDatas={addDatas} onClose={onClose} setFinancialAwards={setFinancialAwards} selectedSelected={selectedSelected} setTableRow={setTableRow} tableRow={tableRow} selectedStatusRow={selectedStatusRow} filtertoPackage={formik.values.filtertoPackage}/>}
    </>
  );
};
export default Documents; 