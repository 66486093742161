import { UPDATE_CRITERIA_TAB,
    UPDATE_PROSPECT_GRID,
    UPDATE_ACTIVITY_TAB,
    UPDATE_DISABLED_TABS } from './types'
const initialState = {
    criteriaTab: {},
    prospectTab: [],
    activityTab: {},
    disableTab: true
};
const addProspectActivityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, 
                    ...payload
                }
            };
        case UPDATE_PROSPECT_GRID:
            return {
                ...state,
                prospectTab: payload
            };
        case UPDATE_ACTIVITY_TAB:
            return {
                ...state,
                activityTab: {
                    ...state.activityTab, 
                    ...payload
                }
            };
            case UPDATE_DISABLED_TABS:
                return {
                    ...state,
                    disableTab: payload
                };
        default:
            return state;
    }
}
export default addProspectActivityReducer;