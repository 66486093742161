import React from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, RadioInput } from "../../../../components/Forms";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_automation } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from 'yup';

const AutomatonOptions = ({ onClose, selectedRow }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();

    const initialValues = {
        selectAutomationType: "",
        addAsNewAward: "",
        overwriteAwardAmounts: "",
        selectACustomRoutine: "",
        automationOptions:""
    };

    const validationSchema = yup.object({
        selectAutomationType: yup
            .string()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.selectAutomationType', { ns: "financialAid" }) })),
        addAsNewAward: yup
            .string()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.addAsNewAward', { ns: "financialAid" }) })),
        overwriteAwardAmounts: yup
            .string()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.overwriteAwardAmounts', { ns: "financialAid" }) })),
        selectACustomRoutine: yup
            .string()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.selectACustomRoutine', { ns: "financialAid" }) })),
    });


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
        }

    });

    React.useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
    }, []);
    return (
        <>
            <PopupHeader
                title={(t('commonLabel.automationOptions', { ns: "financialAid" }))}
                navigationList={financialAid_automation}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                 <SelectInput isDoubled
                                mandatory
                                label={t("commonLabel.selectAutomationType", { ns: "financialAid" })}
                                labelId="selectAutomationType"
                                errors={formik.errors.selectAutomationType}
                                touched={formik.touched.selectAutomationType}
                                formikValue={formik.values.selectAutomationType}
                                formikChange={formik.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <RadioInput
                                label={t("commonLabel.overwriteAwardAmounts", { ns: "financialAid" })}
                                isDoubled={true}
                                labelId={"overwriteAwardAmounts"}
                                list={[
                                    {
                                        value: "false"
                                    }
                             ]}
                                errors={formik.errors.overwriteAwardAmounts}
                                touched={formik.touched.overwriteAwardAmounts}
                            />
                        </FormGroup>
                        <FormGroup>
                            <RadioInput
                                label={t("commonLabel.addAsNewAward", { ns: "financialAid" })}
                                isDoubled={true}
                                labelId={"addAsNewAward"}
                                list={[
                                    {

                                        value: "false",
                                    }

                                ]}
                                errors={formik.errors.addAsNewAward}
                                touched={formik.touched.addAsNewAward}
                                mandatory
                            />
                        </FormGroup>
                        <FormGroup>
                     <SelectInput isDoubled
                                mandatory
                                label={t("commonLabel.selectACustomRoutine", { ns: "financialAid" })}
                                labelId="selectACustomRoutine"
                                errors={formik.errors.selectACustomRoutine}
                                touched={formik.touched.selectACustomRoutine}
                                formikValue={formik.values.selectACustomRoutine}
                                formikChange={formik.handleChange}
                            />
                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips />
                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    {"Process"}
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={() => onClose("")}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};
export default AutomatonOptions; 
