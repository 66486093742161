import {UPDATE_FORM_STATUS, UPDATE_MANDATORY_FIELDS, UPDATE_DRAWER,UPDATE_SIDEBAR_ITEMS} from './types'

export const updateFormStatus = (formStatus) => async (dispatch) => {
    await dispatch({
        type: UPDATE_FORM_STATUS,
        payload: formStatus
    });
};

export const updateMandatory = (mandatoryFields) => async (dispatch) => {
    await dispatch({
        type: UPDATE_MANDATORY_FIELDS,
        payload: mandatoryFields
    });
};

export const updateDrawer = (value) => async (dispatch) => {
    await dispatch({
        type: UPDATE_DRAWER,
        payload: value
    });
};

export const updateSideBarItems = (value) => async (dispatch) => {
    await dispatch({
        type: UPDATE_SIDEBAR_ITEMS,
        payload: value
    });
};