import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Routewrapper } from './route/index';
import './styles/index.css';
import './styles/toast-styles.css';
import './styles/responsive.css';
/// import { PublicClientApplication } from "@azure/msal-browser";
/// import { MsalProvider } from "@azure/msal-react";
/// import { msalConfig } from "./services/authConfig";
import './services/i18n';

//redux imports
import { Provider } from 'react-redux';
import store from './redux/store';

// interceptors for all request and reponse
import('./services/api.interceptors').then(({ executeInterceptors }) => executeInterceptors());

/// const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(

  /// <Provider store={store}>
  ///   <MsalProvider instance={msalInstance}>
  ///     <Routewrapper />
  ///   </MsalProvider>
  /// </Provider>,
  <Provider store={store}>
      <Routewrapper />
  </Provider>,
  document.getElementById('root')
);
