import { SAVE_SELECTED, ADD_REVISION_TERM_ID,ADD_SELECTED_GRID_ROW } from './type'

const initialState = {
    gridRow: {}
};

const degreeAuditReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_SELECTED:
            return {
                ...state,
                ...payload
            }
        case ADD_REVISION_TERM_ID:
            return {
                ...state,
                ...payload
            }
        case ADD_SELECTED_GRID_ROW:
            return {
                ...state,
                gridRow: {...payload}
            }
        default:
            return state;
    }
};

export default degreeAuditReducer;