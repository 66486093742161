import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import BrandingColors from '../../styles/color';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@material-ui/core';
import {  useDispatch } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    '& .MuiTabs-flexContainer':{
      display: "block"
    },
    '& .MuiTabs-root':{
      borderBottom: `1px solid ${BrandingColors.brightOrange}`,
      padding: "20px 20px 0 20px"
    },
    '& .MuiTabScrollButton-horizontal.Mui-disabled': {
      display: 'none'
    }, '& .MuiTabs-indicator': {
      backgroundColor: "transparent"
    },
    '& .MuiTab-textColorPrimary': {
      textTransform: 'none',
      padding: '0 16px',
      whiteSpace: 'nowrap',
      color: BrandingColors.darkGreyFont,
      fontFamily: 'FiraSans-Regular',
      fontWeight: '600',
      fontSize: '14px',
      '&.Mui-selected': {
        padding: '0 15px',
        color: BrandingColors.darkBlack,
        background: BrandingColors.hoverBlue,
        border: `1px solid ${BrandingColors.textBord}`,
        borderBottom: '0px',
        fontFamily: 'FiraSans-Regular',
        fontWeight: '600',
        fontSize: '14px'
      },
      '@media (min-width: 1920px)':{
        fontSize: '18px'
      },
      
    },
    '& .hide': {
      display: 'none'
    },
    '& .show': {
      display: 'block',
      position: 'relative',
      zIndex: 999
    },
    '& .MuiCardContent-root':{
      padding: '20px'
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, showTabs, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={(!showTabs) ? 'shadow' : 'non-shadow'}
      style={{
        borderRadius: 0,
        marginTop: 0,
        position: 'relative',
        zIndex: 0, boxShadow:  'none'
      }}
    >
      {/* commenting the condition for to load all the html */}
      {value === index && (
      <Box >
        {children}
      </Box>
     )} 
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NormalTabs = ({ TabLabels, variant, showTab, handleModify, tabIndex,setTabIndex}) => {

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    if (handleModify) handleModify(newValue);
    setValue(newValue)
    setTabIndex && setTabIndex(newValue)
  };
  const classes = useStyles();
  const [showTabs, setShowTabs] = useState(false);
   useEffect(() => {
    setShowTabs(showTab);
  }, [showTab])
  useEffect(()=>{
    (tabIndex || tabIndex==0) && setValue(tabIndex)
  },[tabIndex])

  useEffect(() => {
    let isScrollableTab = document.getElementsByClassName('MuiTabScrollButton-root');
    if (isScrollableTab.length) {
      document.getElementsByTagName('html')[0].classList.add('scroll-tab-page');
      document.getElementsByTagName('html')[0].classList.remove('not-scroll-tab-page');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('scroll-tab-page');
      document.getElementsByTagName('html')[0].classList.add('not-scroll-tab-page');
    }
  }, [])


  return (
    <>
      <div className={classes.root}>
        {(!showTabs) &&
          <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }} className={'show'}>
            <Tabs value={value}
              onChange={handleChange}
              variant={variant}
              aria-label="visible arrows tabs example"
              scrollButtons
              allowScrollButtonsMobile
            >
              {TabLabels.map((v, i) => {
                return <Tab label={v.text} {...a11yProps(i)} key={i} disabled={v.component.props.disableFlag}/>
              })}
            </Tabs>
          </Box>
        }
      </div>
      {TabLabels.map((v, i) => {
        return <TabPanel value={value} key={i} index={i} showTabs={showTabs}>
          {v.component}
        </TabPanel>
      })}
    </>
  );
}

export default NormalTabs;