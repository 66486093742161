import { ADD_ADDRESS, DELETE_ADDRESS, EDIT_ADDRESS, RESET_ADDRESS, GET_ADDRESS } from './type'

const initialState = {
    address: [
    ]
};

const addressReset = initialState.address;

const facultyAddressReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_ADDRESS:
            return {
                ...state,
                address: [...payload]
            }
        case GET_ADDRESS:
            return {
                ...state,
                address: [...payload]
            }
        case DELETE_ADDRESS:
            return {
                ...state,
                address: state.address.filter((item, ind) =>
                    payload[0].indexOf(ind.toString()) == -1
                )
            }
        case EDIT_ADDRESS:
            return {
                ...state,
                address: state.address.map((v, i) => {
                    let editedValue = { ...v };
                    if (i === parseInt(payload.ind)) {
                        editedValue = payload.data
                    }
                    return editedValue;
                })
            }
        case RESET_ADDRESS:
            return {
                ...state,
                address: [...addressReset]
            }
        default:
            return state;
    }
};

export default facultyAddressReducer;