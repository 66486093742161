import { UPDATE_CRITERIA_TAB, UPDATE_AUDIT_TAB, UPDATE_STUDENT_PROCESS_TAB } from './types'
const initialState = {
    criteriaTab: {
        formValue: {
            "firstTimeEnrollment": false,
            "termID": [],
            "majorsID": [],
            "minorsID": [],
            "gpaGroupsID": [],
            "enrollmentStatusID": [],
            "studentRegisteredID": 0,
            "expectedTermsID": [],
            "degreesID": [],
            "degreesEarnedID": 0,
            "transferDegreeID": 0,
            "degreeTypeID": []
        }
    },
    auditTab: {
        revisionTerm: 0,
        auditRows: [],
        selectAuditRow: {}
    },
    studentProcess: {
        studentRows: [],
        selectedStudent: {}
    }
};
const massAddAuditReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_AUDIT_TAB:
            return {
                ...state,
                auditTab: {
                    ...state.auditTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_STUDENT_PROCESS_TAB:
            return {
                ...state,
                studentProcess: {
                    ...state.studentProcess, [payload['id']]: payload['value']
                }
            };
        default:
            return state;
    }
}
export default massAddAuditReducer;