import { ADD_CITIZENSHIP, DELETE_CITIZENSHIP } from './type'

const initialState = {
    citizenship: [
        {
            "id":1,
            "index":1,
            "citizenCountry": "ACT Scholarship",
            "taxSsnID": "10/21/2016",
            "citizenNationalId" : "2,000.00",
            "citizenAlternateIdType":"Good Act Scholarship",
            "citizenAlternateId":"SP-50",
            "citizenEffDate":"Deepraj Maurya",
        },
    ]
};

const citizenshipReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_CITIZENSHIP:
            return {
                ...state,
                citizenship: [...state.citizenship, ...payload]
            }
        case DELETE_CITIZENSHIP:
            return {
                ...state,
                citizenship: [...state.citizenship.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default citizenshipReducer;