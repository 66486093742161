import React, { useState, useEffect } from 'react';
import { Button, Card, Collapse, CardContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { Breadcrumb, BreadcrumbLink } from '../../../../utils/Breadcrumbs/Breadcrumbs';
import { useFormik } from 'formik';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import * as yup from 'yup';
import { FormGroup, ButtonWrapperFilter, useStyles } from '../../../../styles/common-styles'
import HeaderTitle from '../../../../utils/HeaderTitle/HeaderTitle';
import { useDispatch, useSelector } from 'react-redux';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import { useHistory } from 'react-router';
import { getApi, postApi } from '../../../../services/apiBase';
import { SelectGroupInput } from '../../../../components/Forms/Select';
import GenericModal from '../../../../components/Modal/GenericModal';
import { prospect_url, prospect_dropdown, addInfo,load_prospect_dropdown, load_prospect } from '../../../../services/apiURL';
import { useTranslation } from 'react-i18next';
import { TextInput, DateInput } from '../../../../components/Forms';
import ProspectCopyDialog from '../../../../utils/Popup/prospectCopyDialog';
import { loadProspectsSelector } from '../../../../redux/admisssion/prospects/load-prospects/selectors';
import { resetState, updateLoadProspect } from '../../../../redux/admisssion/prospects/load-prospects/action';
import { load_prospects } from '../../../../services/navigation_folder/admissions';
const LoadProspect = () => {
    const { t } = useTranslation(['translation', 'tableHeadCells']);
    const { loadProspects,completeFlag,selectedProspects,payloadArray} = useSelector(loadProspectsSelector);
    const [toggleMoreOption, setToggleMoreOption] = useState(false);
    const [dateError, setDateError] = useState('');
    const [dateError1, setDateError1] = useState('');
    const [dateError2, setDateError2] = useState('');
    const [dateError3, setDateError3] = useState('');
    const [appFeeValue, setAppFeeValue] = useState('null')
    const [abortFlag, setAbortFlag] = useState(false);
    const [isLoading, setIsloading] = useState(false)
    const [count, setCount] = useState(1);
    const dispatch = useDispatch();
    const history = useHistory();
   
    const [formInvalidAlerts, setFormInvalidAlerts] = useState({ errShow: false, errMessage: '' });
    const [popupData, setPopupData] = useState({
        dropdownData:{
            "transferStatus":[],
            "transferType":[]
        },
        data: [],
        open: false,
        action:loadStudent
    });
    const headCells = [
        { id: 'checked', numeric: true, disablePadding: false, label: t('prospects.selectForLoad', { ns: 'tableHeadCells' }) },
        { id: 'prospectId', numeric: true, disablePadding: false, label: t('prospects.prospectId', { ns: 'tableHeadCells' }) },
        { id: 'prospectName', numeric: true, disablePadding: false, label: t('prospects.prospectName', { ns: 'tableHeadCells' }) },
        { id: 'status', numeric: true, disablePadding: false, label: t('prospects.status', { ns: 'tableHeadCells' }) },
        { id: 'studentSSN', numeric: true, disablePadding: false, label: t('prospects.studentSSN', { ns: 'tableHeadCells' }), ssn: true },
        { id: 'nationalId', numeric: true, disablePadding: false, label: t('prospects.nationalId', { ns: 'tableHeadCells' }) },
        { id: 'maidenName', numeric: true, disablePadding: false, label: t('prospects.maidenName', { ns: 'tableHeadCells' }) },
    ];
    const headCells2 = [
        { id: 'loadResult', numeric: true, disablePadding: false, label: t('prospects.loadResult', { ns: 'tableHeadCells' }) },
        { id: 'checked', numeric: true, disablePadding: false, label: t('prospects.selectForLoad', { ns: 'tableHeadCells' }) },
        { id: 'prospectId', numeric: true, disablePadding: false, label: t('prospects.prospectId', { ns: 'tableHeadCells' }) },
        { id: 'prospectName', numeric: true, disablePadding: false, label: t('prospects.prospectName', { ns: 'tableHeadCells' }) },
        { id: 'status', numeric: true, disablePadding: false, label: t('prospects.status', { ns: 'tableHeadCells' }) },
        { id: 'studentSSN', numeric: true, disablePadding: false, label: t('prospects.studentSSN', { ns: 'tableHeadCells' }), ssn: true },
        { id: 'nationalId', numeric: true, disablePadding: false, label: t('prospects.nationalId', { ns: 'tableHeadCells' }) },
        { id: 'maidenName', numeric: true, disablePadding: false, label: t('prospects.maidenName', { ns: 'tableHeadCells' }) },
    ];


    const [cohortDrop, setCohortDrop] = useState([]);
    const [confirmPopUp, setConfirmPop] = useState(false);
    const [loadStudentDrop,setLoadStudentDrop]= useState({
        "transferStatus":[],
        "transferType":[]
    })
    //API Call for dropdown
    useEffect(() => {
        getApi(prospect_dropdown).then((resp) => {
            setCohortDrop(resp.data.data)
        }).catch((err) => {
            console.log(err);
        })
        getApi(load_prospect_dropdown).then((resp) => {
            let {transferType ,transferStatus} = resp.data.data
            setLoadStudentDrop({
                ...loadStudentDrop,
                "transferStatus": transferStatus?.length> 0 ? transferStatus: [],
                "transferType": transferType?.length> 0 ? transferType: []
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    useEffect(() => {
        if (abortFlag) return;
        if (count != 1 && count <= payloadArray.length) {
            loadStudent(payloadArray[count - 1])
        } else {
            setCount(1)
            if (count != 1) {
                dispatch(updateLoadProspect({id:"completeFlag", value:true}));
                setIsloading(false)
            }
        }
    }, [count])
    const validationSchema = yup.object({
        email: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email') })),
        entryDateFrom: yup
            .date().nullable()
            .max(new Date(t('validationMessages.maxDate'))), // date max range 2100
        entryDateTo: yup
            .date().nullable()
            .max(new Date(t('validationMessages.maxDate'))), // date max range 2100
        onlineAppSubmitDateFrom: yup
            .date().nullable()
            .max(new Date(t('validationMessages.maxDate'))), // date max range 2100
        onlineAppSubmitDateTo: yup
            .date().nullable()
            .max(new Date(t('validationMessages.maxDate'))), // date max range 2100
        // ssn: yup
        //     .string()
        //     .matches(/^[\+]?[0-9]{3}?[-\.]?[0-9]{2}[-\.]?[0-9]{4}$/im, t('validationMessages.validInput', { what: t('prospectLabel.prospectSSN') }))
    });

    const formik = useFormik({
        initialValues: {
            "prospectId": 0,
            "lastName": "",
            "firstName": "",
            "city": "",
            "state": "",
            "zipCode": "",
            "maiden": "",
            "nationalId": "",
            "email": "",
            "entryDateFrom": null,
            "entryDateTo": null,
            "onlineAppSubmitDateFrom": null,
            "onlineAppSubmitDateTo": null,
            "expCohortId": 0,
            "applicationFeePaid": null
        },
        validationSchema,
        onSubmit: (values) => {
            // let newRecord = removeBlankField(values);
            setIsloading(true);
            values["prospectLoad"]= true;
            dispatch(resetState())
            postApi(prospect_url, values).then((resp) => {
                const results = resp.data.data;
                results.forEach(row => row["selectForLoad"] = false)
                dispatch(updateLoadProspect({id:"loadProspects", value:results}));
                DefaultTable.resetTable("prospectSearch3")
                setToggleMoreOption(false)
                setIsloading(false)
            }).catch((err) => {
                setIsloading(false)
                console.log(err)
            })
            //api
            // setFormSearchValue(values);
        }
    });

    const classes = useStyles();

    const resetForm = () => {
        formik.resetForm();
        setAppFeeValue('null');
        formik.setFieldValue("applicationFeePaid", null)
        dispatch(resetState())
    }
    const handleRadioOnchange = (e) => {
        const { value } = e.target
        setAppFeeValue(value)
        if (value == "null") {
            formik.setFieldValue("applicationFeePaid", null)
        } else if (value == "true") {
            formik.setFieldValue("applicationFeePaid", true)
        } else if (value === "false") {
            formik.setFieldValue("applicationFeePaid", false)
        }
    }

    const handleProspectID = (e) => {
        const { value } = e.target
        if (value == '') {
            formik.setFieldValue("prospectId", 0)
        } else {
            formik.setFieldValue("prospectId", parseInt(value))
        }
    }
    const handleCellClick = (cellClick, id, value, row, index) => {
        const newArr = loadProspects.map((v, i) => {
            if (i === index) {
                if (id === 'checked') {
                    v[id] = !value;
                }
            }
            return v;
        });
        dispatch(updateLoadProspect({id:"loadProspects",value:newArr}));
    }
    const handleClose = () => {
        setPopupData({ ...popupData, open: false })
    }
    const handleLoad =()=>{
        if(completeFlag){
            setFormInvalidAlerts({ errShow: true, errMessage: t('validationMessages.operationCompleted') });
            return
        }
        const selectedProspects2 = loadProspects.filter(row=> row.checked);
        dispatch(updateLoadProspect({id:"loadProspects", value:selectedProspects2}));
        dispatch(updateLoadProspect({id:"selectedProspects", value:selectedProspects2}));
        if(selectedProspects2.length>0){
            setPopupData({
                dropdownData:loadStudentDrop,
                data: selectedProspects2,
                open: true,
                action:loadStudentConfirm
            })
           
        }else{
            setFormInvalidAlerts({ errShow: true, errMessage: t('validationMessages.noProspectsWereSelected') });
        }
        
    }

    const loadStudentConfirm =()=>{
        setConfirmPop(true);
    }
    const loadStudent = (payload) => {
        
        if (abortFlag) return;
        setIsloading(true)
        postApi(load_prospect, payload).then((resp) => {
            let responseArrayLocal = loadProspects;
            let {status,errorMessage,prospectId}=  resp.data.data;
            responseArrayLocal.forEach((response)=>{
                if(response.prospectId === prospectId){
                    response["error"] = errorMessage;
                    response["loadResult"] = status;
                    response["status"] = payload.statusText;
                }
            })
            dispatch(updateLoadProspect({id:"selectedProspects", value:responseArrayLocal}));
            setCount(count + 1);
        }).catch((err) => {
            setCount(count + 1);
        })
    }
    
    const onCancel =()=>{
        dispatch(resetState())
        history.push('/admissions/students/select-student');
    }
    return (
        <>
            {
                <GenericModal
                title={t('commonLabel.confirmCopyOfSelectedProspects')}
                   open={confirmPopUp} onClose={setConfirmPop}
                    buttonText={t('buttonLabel.yes')}
                    button2Text={t('buttonLabel.no')}
                    onButtonPress={()=>{loadStudent(payloadArray[0])}}
                    
                />
            }

            {formInvalidAlerts['errShow'] &&
                <GenericModal
                    open={formInvalidAlerts['errShow']}
                    onClose={() => setFormInvalidAlerts({ errShow: false, errMessage: '' })}
                    error={formInvalidAlerts}
                    buttonText='Ok'
                />}
            {popupData?.open && <ProspectCopyDialog
                popupData={popupData} onClose={handleClose}
            />}
            <Breadcrumb>
                {[...load_prospects].map(({ name, isLink, path }, index) => (
                    <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} />
                ))}
            </Breadcrumb>
            <HeaderTitle headerTitle={t('commonLabel.loadProspect')} help={true} />

            <Card className={classes.root}>
                <CardContent>
                    <p style={{ display: 'flex' }}>
                        <span style={{ flex: '1' }} className="searchLanding">{t('buttonLabel.search')}</span>
                        <ArrowForwardIos
                            className={`${toggleMoreOption ? 'active' : ''} arrow`}
                            onClick={() => { setToggleMoreOption(!toggleMoreOption) }}
                        />
                    </p>
                    <br></br>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <FormGroup onClick={() => setToggleMoreOption(true)} onFocus={() => setToggleMoreOption(true)}>
                                <TextInput label={t('prospectLabel.prospectId')} labelId='prospectIDs' formikValue={formik.values.prospectIDs} formikChange={formik.handleChange} onBlur={handleProspectID}/>
                                <TextInput label={t('commonLabel.lastName')} labelId='lastName' formikValue={formik.values.lastName} formikChange={formik.handleChange} />
                                <TextInput label={t('commonLabel.firstName')} labelId='firstName' formikValue={formik.values.firstName} formikChange={formik.handleChange} maxLength={'60'} />
                                <TextInput label={t('commonLabel.maidenName')} labelId='maiden' formikValue={formik.values.maiden} formikChange={formik.handleChange} maxLength={'60'} />

                        </FormGroup>
                        <Collapse in={toggleMoreOption} timeout="auto" unmountOnExit>
                            <FormGroup>
                                    <TextInput label={t('commonLabel.city')} labelId='city' formikValue={formik.values.city} formikChange={formik.handleChange} maxLength={'60'} />
                                    <TextInput label={t('commonLabel.state')} labelId='state' formikValue={formik.values.state} formikChange={formik.handleChange} maxLength={'50'} />
                                    <TextInput label={t('commonLabel.zipCode')} labelId='zipcode' formikValue={formik.values.zipcode} formikChange={formik.handleChange} maxLength={'10'} />
                            </FormGroup>
                            <FormGroup>
                                {/* <TextInput label={t('prospectLabel.prospectSSN')} labelId='ssn' formikValue={formik.values.ssn} formikChange={formik.handleChange} maxlength={9} /> */}
                                <TextInput label={t('commonLabel.nationalId')} labelId='nationalId' formikValue={formik.values.nationalId} formikChange={formik.handleChange} maxLength={'10'} />
                                <TextInput
                                    label={t('commonLabel.email')}
                                    labelId="email"
                                    formikValue={formik.values.email}
                                    formikChange={formik.handleChange}
                                    errors={formik.errors.email}
                                    touched={formik.touched.email}
                                    maxlength={'50'}
                                />
                                <SelectGroupInput label={t('commonLabel.cohort')} labelId={'expCohortId'} formikValue={formik.values.expCohortId} formikChange={formik.handleChange} dropdownData={{ optionLists: cohortDrop, key: 'displayText', value: 'uniqueId', groupLabel: 'activeFlag', groupOne: 'Active', groupTwo: 'Inactive' }} />
                            </FormGroup>
                            <FormGroup className="jc-fs">
                                <FormControl component="fieldset">
                                    <RadioGroup className={'radio-group'} row aria-label="Online-Fee-Paid" name="applicationFeePaids" value={appFeeValue} onChange={handleRadioOnchange} >
                                        <FormLabel component="legend">{t('commonLabel.onlineApplicationFeesPaid')}</FormLabel>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <FormControlLabel value={"null"} control={<Radio color="primary" />} label={t('commonLabel.all')} />
                                        <FormControlLabel value={"true"} control={<Radio color="primary" />} label={t('commonLabel.yes')} />
                                        <FormControlLabel value={"false"} control={<Radio color="primary" />} label={t('commonLabel.no')} />
                                    </RadioGroup>
                                </FormControl>
                            </FormGroup>
                            <FormGroup className="jc-fs">
                                <DateInput
                                    label={t('commonLabel.fromDate')}
                                    labelId={'entryDateFrom'}
                                    formik={formik}
                                    maxDate={new Date(t('validationMessages.maxDate'))}
                                    defaultValue={formik.values.entryDateFrom}
                                    maxDateMessage={t('validationMessages.greaterThanDate', { what: t('commonLabel.fromDate') })}
                                    onError={(errMsg) => setDateError(errMsg)}
                                />
                                <DateInput
                                    label={t('commonLabel.toDate')}
                                    labelId={'entryDateTo'}
                                    formik={formik}
                                    maxDate={new Date(t('validationMessages.maxDate'))}
                                    defaultValue={formik.values.entryDateFrom ? formik.values.entryDateTo : formik.values.entryDateFrom}
                                    minDate={formik.values.entryDateFrom}
                                    maxDateMessage={t('validationMessages.greaterThanDate', { what: t('commonLabel.toDate') })}
                                    minDateMessage={t('validationMessages.lessThan', { from: t('commonLabel.fromDate'), to: t('commonLabel.toDate') })}
                                    onError={(errMsg) => setDateError1(errMsg)}
                                />
                            </FormGroup>
                            <FormGroup className="jc-fs">
                                <DateInput
                                    label={t('commonLabel.onlineApplicationSubmitDateFrom')}
                                    labelId={'onlineAppSubmitDateFrom'}
                                    formik={formik}
                                    maxDate={new Date(t('validationMessages.maxDate'))}
                                    defaultValue={formik.values.onlineAppSubmitDateFrom}
                                    maxDateMessage={t('validationMessages.greaterThanDate', { what: t('commonLabel.onlineApplicationSubmitDateFrom') })}
                                    onError={(errMsg) => setDateError2(errMsg)}
                                />
                                <DateInput
                                    label={t('commonLabel.toDate')}
                                    labelId={'onlineAppSubmitDateTo'}
                                    formik={formik}
                                    maxDate={new Date(t('validationMessages.maxDate'))}
                                    defaultValue={formik.values.onlineAppSubmitDateFrom ? formik.values.onlineAppSubmitDateTo : formik.values.onlineAppSubmitDateFrom}
                                    minDate={formik.values.onlineAppSubmitDateFrom}
                                    maxDateMessage={t('validationMessages.greaterThanDate', { what: t('commonLabel.toDate') })}
                                    minDateMessage={t('validationMessages.lessThan', { from: t('commonLabel.onlineApplicationSubmitDateFrom'), to: t('commonLabel.toDate') })}
                                    onError={(errMsg) => setDateError3(errMsg)}
                                />
                            </FormGroup>
                            <ButtonWrapperFilter>
                                <Button className={'BgYellowOrange search'} variant="contained" size="large" type="submit" disabled={(dateError || dateError1 || dateError2 || dateError3) ? true : false}>
                                    {t('buttonLabel.search')}
                                </Button>
                                <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={resetForm}>
                                    {t('buttonLabel.reset')}
                                </Button>
                            </ButtonWrapperFilter>
                        </Collapse>
                    </form>
                </CardContent>
                </Card>
                <HeaderTitle headerTitle={t('commonLabel.searchResults')} variant={'subHeading'} />
                <Card>
                {!completeFlag ?
                 <DefaultTable trackingLabel={"prospectSearch3"} isLoading={isLoading} multiSelect={false} headCells={headCells} rows={loadProspects} pagination={true} isLookup={true} onCellClick={handleCellClick} />
                 :
                 <DefaultTable trackingLabel={"prospectSearch3"} isLoading={isLoading} multiSelect={false} headCells={headCells2} rows={selectedProspects} pagination={true} isLookup={true}  />
                }
                <CardContent>
                    <ButtonWrapperFilter>
                        <Button className={'BgYellowOrange search'} variant="contained" size="large" onClick={handleLoad}>
                            {t('buttonLabel.load')}
                        </Button>
                        <Button onClick={onCancel} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                            {t('buttonLabel.cancel')}
                        </Button>
                    </ButtonWrapperFilter>
                </CardContent>
            </Card>
        </>
    )
}

export default React.memo(LoadProspect);