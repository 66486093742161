import { ADD_DEGREE, DELETE_DEGREE,ADD_MEMBER } from './type'

const initialState = {
    degree: [
    ],
    committeMember: []
};

const degreeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_DEGREE:
            return {
                ...state,
                degree: [...payload]
            }
        case ADD_MEMBER:
            return {
                ...state,
                committeMember: [...payload]
            }
        case DELETE_DEGREE:
            return {
                ...state,
                degree: [...state.degree.filter(item => !payload.includes(item.sn.toString()))]
            }
        default:
            return state;
    }
};

export default degreeReducer;