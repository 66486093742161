export const COPY_PROPERTIES = 'COPY_PROPERTIES';
export const ENABLE_COPY = 'ENABLE_COPY';
export const GET_STEP_ONE_DATA = 'GET_STEP_ONE_DATA';
export const GET_FLOOR_DATA = 'GET_FLOOR_DATA';
export const SET_PROPERTY_ID = 'SET_PROPERTY_ID';
export const COPY_PROPERTY = 'COPY_PROPERTY';
export const VIEW_PROPERTY = 'VIEW_PROPERTY';
export const EDIT_PROPERTY = 'EDIT_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const STEP1_SAVED = 'STEP1_SAVED';
export const STEP2_SAVED = 'STEP2_SAVED';
export const STEP3_SAVED = 'STEP3_SAVED';
