import { ADD_EMPLOYMENT, DELETE_EMPLOYMENT, ADD_STUDENT_EMPLOYMENT, SEARCH_EMPLOYER } from './type'

const initialState = {
    employment: [
    ],
    studentEmployment: [
    ],
    employer: [
    ]
};

const employmentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_EMPLOYMENT:
            return {
                ...state,
                employment: [...payload]
            }
        case DELETE_EMPLOYMENT:
            return {
                ...state,
                employment: [...state.employment.filter(item => !payload.includes(item.id.toString()))]
            }
        case ADD_STUDENT_EMPLOYMENT:
            return {
                ...state,
                studentEmployment: [...payload]
            }
        case SEARCH_EMPLOYER:
            return {
                ...state,
                employer: [...payload]
            }
        default:
            return state;
    }
};

export default employmentReducer;