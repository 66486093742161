import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@material-ui/core';
import { FormGroup, useStyles, ButtonWrapperFilter, FormInputWrapper, Splitter } from '../../styles/common-styles'
import { TextInput, DateInput } from '../Forms'
import { useFormik } from 'formik';
import PopupHeader from '../../pages/common-page/popupHeader'
import { useTranslation } from 'react-i18next';
import AddRadioGroup from './add-radio-group';
import { admissions_addNotes } from '../../services/navigation_folder/admissions';
import { postApi, putApi } from '../../services/apiBase';
import { add_StudentNote } from '../../services/apiURL';
import { toastMessage } from '../../utils/Message/toasts';
import Tooltips from '../../utils/Tooltip/Tooltip';

const AddNotebutton = ({ onClose, selectedRow, accessFlag, selectedRow1, onClose1, studentDetails }) => {
    const { t } = useTranslation(['translation', 'financialAid']);
    const [showAddData, setShowAddData] = useState(false);
    const [gropuAccess, setGroupAccess] = useState('');
    const [selectedRadioValue, setSelectedRadioValue] = useState(selectedRow !== '' ? selectedRow.viewerAccess : 'Public');
    const [formValues, setFormValues] = useState({
        noteDate: new Date(),
        user: `${selectedRow1.insertUserID}`,
        student: studentDetails.studentname,
        studentId: `${selectedRow1.studentID}`,
        subject: '',
        groupAccess: '',
        viewerAccess: '',
        privateAccess: '',
        publicAccess: '',
        note: '',
        accessID: 'Public'
    });
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            const payload = {
                "studentNoteId": (selectedRow) ? selectedRow.studentNotesId : 0,
                "studentUID": selectedRow1.studentUID,
                "noteDate": values.noteDate,
                "access": selectedRadioValue,
                "subject": values.subject,
                "note": values.note,
                "groupAccess": gropuAccess,

            }
            if (selectedRow === '') {
                postApi(add_StudentNote, payload).then((res) => {
                    toastMessage(t('popupMessages.successIcon', { ns: 'translation' }), t('popupMessages.success', { ns: 'translation' }),
                        t('popupMessages.successfullyAdded', { ns: 'translation' }))
                    onClose1('');
                }).catch((error) => {
                    console.log(error);
                });
            }
            else {
                putApi(add_StudentNote, payload).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    onClose1('');
                }).catch((error) => {
                    console.log(error);
                });
            }

        }
    })

    const handleBack = () => {
        onClose1(false)
    }
    const onCancel = (value) => {
        setShowAddData(value)
        onClose1(value);
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
    }
    const onGoingBack = (value) => {
        setShowAddData(value)
    }
    const classes = useStyles();
    const setAddRadioGroup = (value) => {
        setShowAddData(value);
    }

    const handleRadioDynamicField = (e) => {
        const { value } = e.target
        setSelectedRadioValue(value)
        if (value === "Group") {
            formik.setFieldValue("accessID", "Group");
            setAddRadioGroup(true);
        } else if (value === "Private") {
            formik.setFieldValue("accessID", "Private")
        } else if (value === "Public") {
            formik.setFieldValue("accessID", "Public")
        }
    }
    useEffect(() => {
        if (selectedRow !== '') {
            setFormValues({
                ...selectedRow,
                accessID: selectedRow.viewerAccess,
                user: selectedRow.user,
                student: `${selectedRow.lastName
                    }, ${selectedRow.firstName}`,
                studentId: selectedRow.studentId

            });
            formik.setFieldValue("accessID", selectedRow.viewerAccess)
        }
    }, []);
    return (
        <>
            {(!showAddData) ?
                <>
                    <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.root}>
                        <Card>
                            <CardContent>
                                <PopupHeader
                                    title={(selectedRow !== '') ? t('commonLabel.updateNotes', { ns: 'financialAid' })
                                        : t('commonLabel.addNotes', { ns: 'financialAid' })}
                                    navigationList={admissions_addNotes} onClose={onCancel} />
                                <FormGroup>
                                    <DateInput
                                        labelId="noteDate"
                                        label={t('commonLabel.date', { ns: "financialAid" })}
                                        defaultValue={formik.values.noteDate}
                                        formikValue={formik.values.noteDate}
                                        formikChange={formik.handleChange}
                                        disabled
                                    />
                                    <TextInput
                                        labelId="user"
                                        label={t('commonLabel.user')}
                                        formikValue={formik.values.user}
                                        formikChange={formik.handleChange}
                                        disabled
                                    />
                                    <TextInput
                                        labelId="student"
                                        label={t('commonLabel.studentName')}
                                        formikValue={formik.values.student}
                                        formikChange={formik.handleChange}
                                        disabled
                                    />
                                    <TextInput
                                        labelId="studentId"
                                        label={t('commonLabel.studentId')}
                                        formikValue={formik.values.studentId}
                                        formikChange={formik.handleChange}
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput
                                        labelId="subject"
                                        label={t('commonLabel.subject', { ns: "financialAid" })}
                                        formikValue={formik.values.subject}
                                        formikChange={formik.handleChange}
                                        disabled={accessFlag}
                                        isDoubled
                                    />
                                </FormGroup>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="accessID"
                                        value={selectedRadioValue}
                                        //onChange={(e) => handleRadioDynamicField(e)}
                                        onClick={(e) => handleRadioDynamicField(e)}
                                    >
                                        <FormControlLabel
                                            value="Group" control={<Radio disabled={accessFlag} />} label="Group" />
                                        <FormControlLabel
                                            value="Private" control={<Radio disabled={accessFlag} />} label="Private Access" />
                                        <FormControlLabel
                                            value="Public" control={<Radio disabled={accessFlag} />} label="Public Access" />
                                    </RadioGroup>
                                </FormControl>
                                <Splitter />
                                <FormGroup>
                                    <FormInputWrapper style={{ width: '100%' }} >
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            maxRows={10}
                                            minRows={6}
                                            name="note"
                                            onChange={formik.handleChange}
                                            disabled={accessFlag}
                                            value={formik.values.note}
                                            style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                                        />
                                    </FormInputWrapper>
                                </FormGroup>
                            </CardContent>
                            <CardContent>
                                <div style={{ display: 'flex' }}>
                                    <Tooltips flag={(selectedRow !== '') ? true : false} row={(selectedRow !== '') ? {
                                        insertUserID: selectedRow.insertUserId,
                                        insertTime: selectedRow.insertTime,
                                        updateUserID: selectedRow.updateUserId,
                                        updateTime: selectedRow.updateTime
                                    } : ''} />
                                    <ButtonWrapperFilter>
                                        {!accessFlag && <Button disabled={accessFlag} aria-label={t('buttonLabel.add')} className='BgYellowOrange search' variant="contained" type="submit">
                                            {(selectedRow !== '') ? t('buttonLabel.update') : t('buttonLabel.add')}
                                        </Button>}
                                        <Button
                                            aria-label={t('buttonLabel.cancel')}
                                            className="BgLightBlue"
                                            variant="contained"
                                            size="large"
                                            onClick={handleBack}
                                        >
                                            {t('buttonLabel.cancel')}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </div>
                            </CardContent>
                        </Card>
                    </form>
                </> : < AddRadioGroup accessFlag={accessFlag} onClose={onGoingBack} setAddRadioGroup={setAddRadioGroup} setGroupAccess={setGroupAccess} gropuAccess={gropuAccess} selectedRow={selectedRow} />
            }
        </>
    );
}
export default AddNotebutton;