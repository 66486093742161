import { SEARCH_STUDENTS, SELECTED_PROFILE, SEARCH_ORGANIZATION, STUDENT_LOOKUP,SET_INITIAL_GPA,SET_ACTIVATED_GPA } from './types'


export const setInitailaGPA = (ActivatedGPA) => async (dispatch) => {
    await dispatch({
        type: SET_INITIAL_GPA,
        payload: { ...ActivatedGPA }
    });
};

export const setActivatedGPA = (ActivatedGPA) => async (dispatch) => {
    await dispatch({
        type: SET_ACTIVATED_GPA,
        payload: { ...ActivatedGPA }
    });
};

export const getSearchStudents = (allStudents) => async (dispatch) => {
    await dispatch({
        type: SEARCH_STUDENTS,
        payload: [...allStudents]
    });
};

export const getSelectedProfiles = (profile) => async (dispatch) => {
    await dispatch({
        type: SELECTED_PROFILE,
        payload: { ...profile }
    });
};

export const getSearchOrg = (org) => async (dispatch) => {
    await dispatch({
        type: SEARCH_ORGANIZATION,
        payload: [...org]
    });
};

export const getStudentLookup = (data) => async (dispatch) => {
    await dispatch({
        type: STUDENT_LOOKUP,
        payload: [...data]
    });
};