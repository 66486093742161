import { UPDATE_STUDENT1_TAB, UPDATE_STUDENT2_TAB } from './types'
const initialState = {
    student1Tab:"",
    student2Tab:""
};
const studentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_STUDENT1_TAB:
            return {
                ...state,
                student1Tab: {...(state.student1Tab),...payload}
                
            };
        case UPDATE_STUDENT2_TAB:
            return {
                ...state,
                student2Tab: {...(state.student2Tab),...payload}
                
            };
        default:
            return state;
    }
}
export default studentReducer