import { GET_RECORDS, GET_SELECTED_RECORD, GET_LOAD_PARAMS } from './types'


const initialState = {
    records: "",
    selectedRecord: "",
    loadParameter: {},
};

const updateT4AFormStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_RECORDS:
            return {
                ...state,
                records: [...payload]

            }
        case GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRecord: { ...payload }
            }
        case GET_LOAD_PARAMS: {
            return {
                ...state,
                loadParameter: { ...payload }
            };
        }

        default:
            return state;
    }

};
export default updateT4AFormStatusReducer;