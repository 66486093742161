import { FORM_LIST_SELECTED, QUESTION_LIST_SELECTED } from './types'

export const getSelectedFormList = (value) => async (dispatch) => {
    await dispatch({
        type: FORM_LIST_SELECTED,
        payload: value
    });
};

export const getSelectedQuestionList = (value) => async (dispatch) => {
    await dispatch({
        type: QUESTION_LIST_SELECTED,
        payload: value
    });
};