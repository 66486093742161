import { GET_ANWSER, SEARCH_ALL_QUESTIONS,SEARCH_UNANS_QUESTIONS,GET_QUESTION } from './type'

const initialState = {
    prospectQuestions: [],
    unansweredQuestions: [],
    currentAnswers:[],
    currentQuestion:[]
};

const questionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_ALL_QUESTIONS:
            return {
                ...state,
                prospectQuestions: [...payload]
            };
        case SEARCH_UNANS_QUESTIONS:
            console.log({
                ...state,
                unansweredQuestions: [...payload]
        })
            return {
                ...state,
                unansweredQuestions: [...payload]
        };
        case GET_ANWSER:
            return {
                ...state,
                currentAnswers: [...payload]
        };
        case GET_QUESTION:
            return {
                ...state,
                currentQuestion: [...payload]
        };

        default:
            return state;
    }
};

export default questionReducer;