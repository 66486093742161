import { GET_TRANSACTION_TYPE } from './types';

const intialState = {
    getTransactionData: {},
}
const maintananceReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_TRANSACTION_TYPE:
            return { ...state, getTransactionData: payload }
       
        default:
            return state;
    }
}

export default maintananceReducer;

