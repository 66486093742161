import {
    ADD_TEST_SCORE,
    DELETE_TEST_SCORE,
    ADD_STUDENT_TEST_SCORE,
    DELETE_STUDENT_TEST_SCORE,
    ADD_SCORE,
    DELETE_SCORE,
    ADD_STUDENT_SCORE,
    DELETE_STUDENTT_SCORE,

} from './type'

const initialState = {
    test: [],
    StudentTest: [],
    score: [],
    studentScore: []
};

const testScoreReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_TEST_SCORE:
            return {
                ...state,
                test: [...payload]
            }
        case DELETE_TEST_SCORE:
            return {
                ...state,
                test: [...state.test.filter(item => !payload.includes(item.testName))]
            }
        case ADD_STUDENT_TEST_SCORE:
            return {
                ...state,
                StudentTest: [...payload]
            }
        case DELETE_STUDENT_TEST_SCORE:
            return {
                ...state,
                StudentTest: [...state.StudentTest.filter(item => !payload.includes(item.testName))]
            }
        case ADD_SCORE:
            return {
                ...state,
                score: [...payload]
            }
        case DELETE_SCORE:
            return {
                ...state,
                score: [...state.test.filter(item => !payload.includes(item.testName))]
            }
        case ADD_STUDENT_SCORE:
            return {
                ...state,
                studentScore: [...payload]
            }
        case DELETE_STUDENTT_SCORE:
            return {
                ...state,
                studentScore: [...state.StudentTest.filter(item => !payload.includes(item.testName))]
            }
        default:
            return state;
    }
};

export default testScoreReducer;