import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles,
  ButtonWrapper,Splitter
} from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next"
import PopupHeader from "../../../common-page/popupHeader";
import { financialAid_codHistory } from "../../../../services/navigation_folder/financialAid";
import {loan_Export_History_Grid,cod_Export_Awards_Grid,cod_disb_export_history_Grid,cod_disb_import_history_Grid,cod_import_award_history_Grid,loan_import_history } from "../../../../services/apiURL";
import { getApi } from '../../../../services/apiBase'; 
import { TextInput } from "../../../../components/Forms";
import { formatDate } from "../../../../helper";

import currencyPipe from '../../../../helper/currency';


export const CODHistory = ({ selectedRow, getSelectedRow, addDatas, onClose,selectedSelected,selectedAwards }) => {
  const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);

  const [showDynamicTableLoanExport, setShowDynamicTableLoanExport] = useState(false);

  const [showDynamicTablLoanImpHistory, setShowDynamicTablLoanImpHistory] = useState(false);

  const [showCodExportAwards, setShowCodExportAwards] = useState(false);

  const [showCodImportAwards, setShowCodImportAwards] = useState(false);

  const [showCodXportDisb, setShowCodXportDisb] = useState(false);

  const initialValues = {
    awardName:""
  };
  const transform = (val) => {
    return currencyPipe(val, { symbol: '$', decimal: '.', separator: ',', precision: 2 }).format();
  }

  const [viewloanexporthistory] = useState([
    { id: 'lastName', numeric: true, disablePadding: false, label: t('viewloanexporthistory.lastName', { ns: 'financialAidHeadcells' }) },
    { id: 'firstName', numeric: true, disablePadding: false, label: t('viewloanexporthistory.firstName', { ns: 'financialAidHeadcells' }) },
    { id: 'middleName', numeric: true, disablePadding: false, label: t('viewloanexporthistory.middleName', { ns: 'financialAidHeadcells' }) },
    { id: 'codAwardType', numeric: true, disablePadding: false, label: t('viewloanexporthistory.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYear', numeric: true, disablePadding: false, label: t('viewloanexporthistory.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAidYear', numeric: true, disablePadding: false, label: t('viewloanexporthistory.financialAidYear', { ns: 'financialAidHeadcells' }) },
    { id: 'collegeLevel', numeric: true, disablePadding: false, label: t('viewloanexporthistory.collegeLevel', { ns: 'financialAidHeadcells' }) },
    { id: 'codStudentLevelCode', numeric: true, disablePadding: false, label: t('viewloanexporthistory.codStudentLevelCode', { ns: 'financialAidHeadcells' }) },
    { id: 'awardKey', numeric: true, disablePadding: false, label: t('viewloanexporthistory.awardKey', { ns: 'financialAidHeadcells' }) },
    { id: 'documentID', numeric: true, disablePadding: false, label: t('viewloanexporthistory.documentID', { ns: 'financialAidHeadcells' }) },
    //{ id: 'exchangeRate', numeric: true, disablePadding: false, label: t('viewloanexporthistory.exchangeRate', { ns: 'financialAidHeadcells' }) },
    { id: 'studentUID', numeric: true, disablePadding: false, label: t('viewloanexporthistory.studentUID', { ns: 'financialAidHeadcells' }) },
    { id: 'codLoanID', numeric: true, disablePadding: false, label: t('viewloanexporthistory.codLoanID', { ns: 'financialAidHeadcells' }) },
    { id: 'awardTypeID', numeric: true, disablePadding: false, label: t('viewloanexporthistory.awardTypeID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('viewloanexporthistory.financialStatusID', { ns: 'financialAidHeadcells' }) },

  ])

  const [viewcodexportawardhistory] = useState([
    { id: 'lastName', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.lastName', { ns: 'financialAidHeadcells' }) },
    { id: 'firstName', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.firstName', { ns: 'financialAidHeadcells' }) },
    { id: 'middleName', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.middleName', { ns: 'financialAidHeadcells' }) },
    { id: 'codAwardType', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: 'awardType', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.awardType', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYear', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAwardYear', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialAwardYear', { ns: 'financialAidHeadcells' }) },
    { id: 'cpsTransactionNumber', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.cpsTransactionNumber', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAwardAmount', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialYearAwardAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAwardNumber', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialAwardNumber', { ns: 'financialAidHeadcells' }) },
    { id: 'codFinancialAwardID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.codFinancialAwardId', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAwardCreateDate', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialAwardCreateDate', { ns: 'financialAidHeadcells' }) },
    { id: 'dependencyStatusCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.dependencyStatusCode', { ns: 'financialAidHeadcells' }) },
    { id: 'attendenceCost', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.attendenceCost', { ns: 'financialAidHeadcells' }) },
    { id: 'enrollmentDate', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.enrollmentDate', { ns: 'financialAidHeadcells' }) },
    { id: 'verificationStatusCoode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.verificationStatusCoode', { ns: 'financialAidHeadcells' }) },
    { id: 'studentUID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.studentUID', { ns: 'financialAidHeadcells' }) },
    { id: 'codStatusID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.codStatusID', { ns: 'financialAidHeadcells' }) },
    { id: 'documentID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.documentID', { ns: 'financialAidHeadcells' }) },
    { id: 'borrowerID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.borrowerID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialCODHeaderID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialCODHeaderID', { ns: 'financialAidHeadcells' }) },
    { id: 'entranceCounselling', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.entranceCounselling', { ns: 'financialAidHeadcells' }) },
    { id: 'eligibilityPaymentReasonCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.eligibilityPaymentReasonCode', { ns: 'financialAidHeadcells' }) },
    { id: 'rigorousHighSchoolProgramCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.rigorousHighSchoolProgramCode', { ns: 'financialAidHeadcells' }) },
    { id: 'additionalUnsusidizedEligibilityIndicator', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.additionalUnsusidizedEligibilityIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'additionalEligibiltyIndicator', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.additionalEligibiltyIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'studentDefaultOverPayCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.studentDefaultOverPayCode', { ns: 'financialAidHeadcells' }) },
    { id: 'borrowerDefaultOverPayCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.borrowerDefaultOverPayCode', { ns: 'financialAidHeadcells' }) },
    { id: 'collegeLevelID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.collegeLevelID', { ns: 'financialAidHeadcells' }) },
    { id: 'collegeLevel', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.collegeLevel', { ns: 'financialAidHeadcells' }) },
    { id: 'codStudentLevelCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.codStudentLevelCode', { ns: 'financialAidHeadcells' }) },
    { id: 'awardTypeID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.awardTypeID', { ns: 'financialAidHeadcells' }) },
    { id: 'hppaIndicator', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.hppaIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'abilityToBenefitCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.abilityToBenefitCode', { ns: 'financialAidHeadcells' }) },
    { id: 'abilityToBenefittestCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.abilityToBenefittestCode', { ns: 'financialAidHeadcells' }) },
    { id: 'abilityToBenefittestAdminstrtorCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.abilityToBenefittestAdminstrtorCode', { ns: 'financialAidHeadcells' }) },
    { id: 'abilityToBenefitCompletionCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.abilityToBenefitCompletionCode', { ns: 'financialAidHeadcells' }) },
    { id: 'abilityToBenefitStateCode', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.abilityToBenefitStateCode', { ns: 'financialAidHeadcells' }) },
    { id: 'programofStudy', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.programofStudy', { ns: 'financialAidHeadcells' }) },
    { id: 'professionalCourseWorkIndicator', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.professionalCourseWorkIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.financialStatusID', { ns: 'financialAidHeadcells' }) },
    { id: 'originationFeePercent', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.originationFeePercent', { ns: 'financialAidHeadcells' }) },
    { id: 'interestRebatePercent', numeric: true, disablePadding: false, label: t('viewcodexportawardhistory.interestRebatePercent', { ns: 'financialAidHeadcells' }) },
  ])


  const [coddisbexporthistory] = useState([
    { id: 'lastName', numeric: true, disablePadding: false, label: t('coddisbexporthistory.lastName', { ns: 'financialAidHeadcells' }) },
    { id: 'firstName', numeric: true, disablePadding: false, label: t('coddisbexporthistory.firstName', { ns: 'financialAidHeadcells' }) },
    { id: 'middleName', numeric: true, disablePadding: false, label: t('coddisbexporthistory.middleName', { ns: 'financialAidHeadcells' }) },
    { id: 'codAwardType', numeric: true, disablePadding: false, label: t('coddisbexporthistory.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: 'awardType', numeric: true, disablePadding: false, label: t('coddisbexporthistory.awardType', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYear', numeric: true, disablePadding: false, label: t('coddisbexporthistory.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'financialYear', numeric: true, disablePadding: false, label: t('coddisbexporthistory.financialYear', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementAmount', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementDate', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementDate', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementReleaseIndicator', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementReleaseIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementSequenceNumber', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementSequenceNumber', { ns: 'financialAidHeadcells' }) },
    { id: 'firstDisbursementIndicator', numeric: true, disablePadding: false, label: t('coddisbexporthistory.firstDisbursementIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementNetAmount', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementNetAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'disburseFeeAmount', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disburseFeeAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'interestRebateAmount', numeric: true, disablePadding: false, label: t('coddisbexporthistory.interestRebateAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'disbursementNumber', numeric: true, disablePadding: false, label: t('coddisbexporthistory.disbursementNumber', { ns: 'financialAidHeadcells' }) },
    { id: 'documentID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.documentID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAwardID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.financialAwardID', { ns: 'financialAidHeadcells' }) },
    { id: 'codDisbursementID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.codDisbursementID', { ns: 'financialAidHeadcells' }) },
    { id: 'studentUID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.studentUID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.financialStatusID', { ns: 'financialAidHeadcells' }) },
    { id: 'campus', numeric: true, disablePadding: false, label: t('coddisbexporthistory.campus', { ns: 'financialAidHeadcells' }) },
    { id: 'campusID', numeric: true, disablePadding: false, label: t('coddisbexporthistory.campusID', { ns: 'financialAidHeadcells' }) },
    { id: 'campusCode', numeric: true, disablePadding: false, label: t('coddisbexporthistory.campusCode', { ns: 'financialAidHeadcells' }) },
    { id: 'campusDerived', numeric: true, disablePadding: false, label: t('coddisbexporthistory.campusDerived', { ns: 'financialAidHeadcells' }) },
    { id: 'paymentPeriodStartDate', numeric: true, disablePadding: false, label: t('coddisbexporthistory.paymentPeriodStartDate', { ns: 'financialAidHeadcells' }) },


  ])
  const [viewloanimporthistory] = useState([
    { id: 'dlloanInformationId', numeric: true, disablePadding: false, label: t('viewloanimporthistory.dlLoanInformationID', { ns: 'financialAidHeadcells' }) },
    { id: 'codimportId', numeric: true, disablePadding: false, label: t('viewloanimporthistory.codImportID', { ns: 'financialAidHeadcells' }) },
    { id: 'ssn', numeric: true, disablePadding: false, label: t('viewloanimporthistory.ssn', { ns: 'financialAidHeadcells' }) },
    { id: 'awardKey', numeric: true, disablePadding: false, label: t('viewloanimporthistory.awardKey', { ns: 'financialAidHeadcells' }) },
    { id: 'originationFeePercent', numeric: true, disablePadding: false, label: t('viewloanimporthistory.originationFeePercent', { ns: 'financialAidHeadcells' }) },
    { id: 'interestRebatePercent', numeric: true, disablePadding: false, label: t('viewloanimporthistory.interestRebatePercent', { ns: 'financialAidHeadcells' }) },
    { id: 'promissoryNotePrintCode', numeric: true, disablePadding: false, label: t('viewloanimporthistory.promissoryPrintNoteCode', { ns: 'financialAidHeadcells' }) },
    { id: 'disclosureStatementPrintCode', numeric: true, disablePadding: false, label: t('viewloanimporthistory.disclosureStatementPrintCode', { ns: 'financialAidHeadcells' }) },
    { id: 'studentLevelCode', numeric: true, disablePadding: false, label: t('viewloanimporthistory.studentLevelCode', { ns: 'financialAidHeadcells' }) },
    { id: 'publishedPgmLengthYears', numeric: true, disablePadding: false, label: t('viewloanimporthistory.publishedpgmLengthYears', { ns: 'financialAidHeadcells' }) },
    { id: 'publishedPgmLengthMonths', numeric: true, disablePadding: false, label: t('viewloanimporthistory.publishedpgmLengthMonths', { ns: 'financialAidHeadcells' }) },
    { id: 'publishedPgmLengthWeeks', numeric: true, disablePadding: false, label: t('viewloanimporthistory.publishedpgmLengthWeeks', { ns: 'financialAidHeadcells' }) },
    { id: 'weeksProgramsAcademicYear', numeric: true, disablePadding: false, label: t('viewloanimporthistory.weeksProgramsAcademicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'specialPrograms', numeric: true, disablePadding: false, label: t('viewloanimporthistory.specialPrograms', { ns: 'financialAidHeadcells' }) },
    { id: 'programCredentialLevel', numeric: true, disablePadding: false, label: t('viewloanimporthistory.programCredentialLevel', { ns: 'financialAidHeadcells' }) },

  ]);

  const [viewcodimportsawardhistory] = useState([
    { id: "codresponseAwardsId", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.codResponseAwardsId', { ns: 'financialAidHeadcells' }) },
    { id: "codimportId", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.codImportsID', { ns: 'financialAidHeadcells' }) },
    { id: "ssn", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.ssn', { ns: 'financialAidHeadcells' }) },
    { id: "codawardType", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: "awardKey", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.awardKey', { ns: 'financialAidHeadcells' }) },
    { id: "financialawardYear", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.financialAwardYear', { ns: 'financialAidHeadcells' }) },
    { id: "cpstransactionNumber", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.cpsTransactionNumber', { ns: 'financialAidHeadcells' }) },
    { id: "financialAwardAmount", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.financialAwardAmount', { ns: 'financialAidHeadcells' }) },
    { id: "attendenceCost", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.attendenceCost', { ns: 'financialAidHeadcells' }) },
    { id: "enrollmentDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.enrollmentDate', { ns: 'financialAidHeadcells' }) },
    { id: "responseCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.responseCode', { ns: 'financialAidHeadcells' }) },
    { id: "totalEligibiltyUsed", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.totalEligibiltyUsed', { ns: 'financialAidHeadcells' }) },
    { id: "scheduledGrant", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.scheduledGrant', { ns: 'financialAidHeadcells' }) },
    { id: "lifeTimeeligibilityUsed", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.lifeTimeeligibilityUsed', { ns: 'financialAidHeadcells' }) },
    { id: "dependencyStatusCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.dependencyStatusCode', { ns: 'financialAidHeadcells' }) },
    { id: "financialAwardNumber", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.financialAwardNumber', { ns: 'financialAidHeadcells' }) },
    { id: "codfinancialAwardId", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.codFinancialAwardID', { ns: 'financialAidHeadcells' }) },
    { id: "financialAwardCreateDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.financialAwardCreateDate', { ns: 'financialAidHeadcells' }) },
    { id: "responseErrorCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.responseErrorCode', { ns: 'financialAidHeadcells' }) },
    { id: "responseErrorField", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.responseErrorField', { ns: 'financialAidHeadcells' }) },
    { id: "responseErrorValue", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.responseErrorValue', { ns: 'financialAidHeadcells' }) },
    { id: "empnIndicator", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.empnIndicator', { ns: 'financialAidHeadcells' }) },
    { id: "mpnid", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.mpnID', { ns: 'financialAidHeadcells' }) },
    { id: "mpnstatusCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.mpnStatusCode', { ns: 'financialAidHeadcells' }) },
    { id: "mpnlinkIndicator", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.mpnLinkIndicator', { ns: 'financialAidHeadcells' }) },
    { id: "mpnexpirationDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.mpnExpirationDate', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerBirthDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerBirthDate', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerSsn", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerBirthSSN', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerLastName", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerLastName', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerFirstName", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerFirstName', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerMiddleInitial", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerMiddleInitial', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerAddressLine", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerAddressLine', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerCity", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerCity', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerStateProvinceCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerStateProvinceCode', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerPostalCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerPostalCode', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerPhoneNumber", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerPhoneNumber', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerEmailAdress", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerEmailAdress', { ns: 'financialAidHeadcells' }) },
    { id: "borrowerCitizenshipStatus", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.borrowerCitizenshipStatus', { ns: 'financialAidHeadcells' }) },
    { id: "applicationId", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.applicationId', { ns: 'financialAidHeadcells' }) },
    { id: "creditDecisionStatus", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditDecisionStatus', { ns: 'financialAidHeadcells' }) },
    { id: "creditDecisionDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditDecisionDate', { ns: 'financialAidHeadcells' }) },
    { id: "creditBalanceOption", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditBalanceOption', { ns: 'financialAidHeadcells' }) },
    { id: "creditDecisionExipirationDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditDecisionExipirationDate', { ns: 'financialAidHeadcells' }) },
    { id: "originalCreditDecisionStatus", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.originalCreditDecisionStatus', { ns: 'financialAidHeadcells' }) },
    { id: "creditAppealStatus", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditAppealStatus', { ns: 'financialAidHeadcells' }) },
    { id: "endorserAmount", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.endorserAmount', { ns: 'financialAidHeadcells' }) },
    { id: "schoolCreditBalanceOption", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.schoolCreditBalanceOption', { ns: 'financialAidHeadcells' }) },
    { id: "creditActionChoice", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditActionChoice', { ns: 'financialAidHeadcells' }) },
    { id: "creditOverrideCode", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditOverrideCode', { ns: 'financialAidHeadcells' }) },
    { id: "previousApplicationID", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.previousApplicationID', { ns: 'financialAidHeadcells' }) },
    { id: "unKnownLoanAmount", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.unKnownLoanAmount', { ns: 'financialAidHeadcells' }) },
    { id: "sixMonthDefermentOption", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.sixMonthDefermentOption', { ns: 'financialAidHeadcells' }) },
    { id: "maximumLoanIndicator", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.maximumLoanIndicator', { ns: 'financialAidHeadcells' }) },
    { id: "defermentOption", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.defermentOption', { ns: 'financialAidHeadcells' }) },
    { id: "applicationLoanAmount", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.applicationLoanAmount', { ns: 'financialAidHeadcells' }) },
    { id: "applicationSubmissionReason", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.applicationSubmissionReason', { ns: 'financialAidHeadcells' }) },
    { id: "applicationCompleteDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.applicationCompleteDate', { ns: 'financialAidHeadcells' }) },
    { id: "awardRangeRequestedStartDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.awardRangeRequestedStartDate', { ns: 'financialAidHeadcells' }) },
    { id: "awardRangeRequestedEndDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.awardRangeRequestedEndDate', { ns: 'financialAidHeadcells' }) },
    { id: "calculatedPublishedpgmLengthYears", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.calculatedPublishedpgmLengthYears', { ns: 'financialAidHeadcells' }) },
    { id: "anticipatedSubUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.anticipatedSubUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "actualSubUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.actualSubUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "academicYearLength", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.academicYearLength', { ns: 'financialAidHeadcells' }) },
    { id: "loanPeriodLength", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.loanPeriodLength', { ns: 'financialAidHeadcells' }) },
    { id: "maxSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.maxSubEligibilityPeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remainingmaxSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.remainingmaxSubEligibilityPeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remaininganticipatedSubUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.remaininganticipatedSubUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "sumanticipatedSubUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.sumanticipatedSubUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "AnticipatedNonCredTeacherCertSuBUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.AnticipatedNonCredTeacherCertSuBUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "actualNonCredTeacherCertSuBUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.actualNonCredTeacherCertSuBUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remaingactualNonCredTeacherCertSuBUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.remaingactualNonCredTeacherCertSuBUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remainingAnticipatedNonCredTeacherCertSuBUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.remainingAnticipatedNonCredTeacherCertSuBUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "sumAnticipatedNonCredTeacherCertSuBUsagePeriod", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.sumAnticipatedNonCredTeacherCertSuBUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "creditActionStatus", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditActionStatus', { ns: 'financialAidHeadcells' }) },
    { id: "reconsiderationEligible", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.reconsiderationEligible', { ns: 'financialAidHeadcells' }) },
    { id: "plusCounsellingCompleted", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.plusCounsellingCompleted', { ns: 'financialAidHeadcells' }) },
    { id: "plusCounsellingCompleteDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.plusCounsellingCompleteDate', { ns: 'financialAidHeadcells' }) },
    { id: "plusCounsellingDException", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.plusCounsellingDException', { ns: 'financialAidHeadcells' }) },
    { id: "creditsRequirementsMet", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.creditsRequirementsMet', { ns: 'financialAidHeadcells' }) },
    { id: "endorerApproved", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.endorerApproved', { ns: 'financialAidHeadcells' }) },
    { id: "plusCounsellingExpirationDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.plusCounsellingExpirationDate', { ns: 'financialAidHeadcells' }) },
    { id: "informedBorrowingComplete", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.informedBorrowingComplete', { ns: 'financialAidHeadcells' }) },
    { id: "informedBorrowingCompletetionDate", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.informedBorrowingCompletetionDate', { ns: 'financialAidHeadcells' }) },
    { id: "informedBorrowingID", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.informedBorrowingID', { ns: 'financialAidHeadcells' }) },
    { id: "realtionShipToStudent", numeric: true, disablePadding: false, label: t('viewcodimportsawardhistory.realtionShipToStudent', { ns: 'financialAidHeadcells' }) },

  ]);

  const [coddisbimporthistory] = useState([
    { id: "codresponseDisbursementId", numeric: true, disablePadding: false, label: t('coddisbimporthistory.codResponseDisbursementID', { ns: 'financialAidHeadcells' }) },
    { id: "codimportId", numeric: true, disablePadding: false, label: t('coddisbimporthistory.codImportID', { ns: 'financialAidHeadcells' }) },
    { id: "ssn", numeric: true, disablePadding: false, label: t('coddisbimporthistory.ssn', { ns: 'financialAidHeadcells' }) },
    { id: "codfinancialAwardId", numeric: true, disablePadding: false, label: t('coddisbimporthistory.codfinancialAwardID', { ns: 'financialAidHeadcells' }) },
    { id: "codawardType", numeric: true, disablePadding: false, label: t('coddisbimporthistory.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: "number", numeric: true, disablePadding: false, label: t('coddisbimporthistory.number', { ns: 'financialAidHeadcells' }) },
    { id: "disbursementAmount", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disbursementAmount', { ns: 'financialAidHeadcells' }) },
    { id: "disbursementDate", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disbursementDate', { ns: 'financialAidHeadcells' }) },
    { id: "disbursementReleaseIndicator", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disbursementReleaseIndicator', { ns: 'financialAidHeadcells' }) },
    { id: "disbursementSequenceNumber", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disbursementSequenceNumber', { ns: 'financialAidHeadcells' }) },
    { id: "firstDisbursementIndicator", numeric: true, disablePadding: false, label: t('coddisbimporthistory.firstDisbursementIndicator', { ns: 'financialAidHeadcells' }) },
    { id: "disbursementNetAmount", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disbursementNetAmount', { ns: 'financialAidHeadcells' }) },
    { id: "disburseFeeAmount", numeric: true, disablePadding: false, label: t('coddisbimporthistory.disburseFeeAmount', { ns: 'financialAidHeadcells' }) },
    { id: "interestRebateAmount", numeric: true, disablePadding: false, label: t('coddisbimporthistory.interestRebateAmount', { ns: 'financialAidHeadcells' }) },
    { id: "responseCode", numeric: true, disablePadding: false, label: t('coddisbimporthistory.responseCode', { ns: 'financialAidHeadcells' }) },
    { id: "errorGuid", numeric: true, disablePadding: false, label: t('coddisbimporthistory.errorGUID', { ns: 'financialAidHeadcells' }) },
    { id: "financialAwardId", numeric: true, disablePadding: false, label: t('coddisbimporthistory.financialAwardID', { ns: 'financialAidHeadcells' }) },
    { id: "enrollmentStatus", numeric: true, disablePadding: false, label: t('coddisbimporthistory.enrollmentStatus', { ns: 'financialAidHeadcells' }) },
    { id: "programCipcode", numeric: true, disablePadding: false, label: t('coddisbimporthistory.programCIPCode', { ns: 'financialAidHeadcells' }) },

  ]);
   const [coddisbimporthistoryerrors] = useState([
    { id: "errorGuid", numeric: true, disablePadding: false, label: t('coddisbimporthistoryerrors.errorGuid', { ns: 'financialAidHeadcells' }) },
    { id: "responseErrorCode", numeric: true, disablePadding: false, label: t('coddisbimporthistoryerrors.responseErrorCode', { ns: 'financialAidHeadcells' }) },
   { id: "responseErrorField", numeric: true, disablePadding: false, label: t('coddisbimporthistoryerrors.responseErrorField', { ns: 'financialAidHeadcells' }) },
  ])

   const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => { },
  });
  const [tableRow, setTableRow] = useState({
    loanExportHistoryRow: [],
    viewLoanImportHistoryRow:[],
    codExportAwardHistoryRow: [],
    codDisbExportHistoryRow: [],
    viewCodImportsAwardHistoryRow:[],
    codDisbImportHistoryRow:[],
    codDisbImportHistoryErrorsRow:[]
  });
  useEffect(() => {
    let financialAwardId = selectedAwards.financialAwardId;
    const {textTerm,distributionDate,awardType,amount} = selectedAwards;
    formik.setFieldValue("awardName",`${textTerm} ${formatDate(distributionDate)} ${awardType} ${transform(amount)}`)
    Promise.all([getApi(`${loan_Export_History_Grid}/${financialAwardId}`), getApi(`${cod_Export_Awards_Grid}/${financialAwardId}`),getApi(`${cod_disb_export_history_Grid}/${financialAwardId}`),getApi(`${cod_disb_import_history_Grid}/${financialAwardId}`),getApi(`${cod_import_award_history_Grid}/${financialAwardId}`),getApi(`${loan_import_history}/${financialAwardId}`) ])
    .then((resp)=>{
      setTableRow({
        ...tableRow,
        loanExportHistoryRow: resp[0].data.data,
        codExportAwardHistoryRow: resp[1].data.data,
        codDisbExportHistoryRow: resp[2].data.data,
        codDisbImportHistoryRow:resp[3].data.data.codResponseDisbursementList,
        codDisbImportHistoryErrorsRow:resp[3].data.data.codResponseDisbursementErrorsList,
        viewCodImportsAwardHistoryRow:resp[4].data.data,
        viewLoanImportHistoryRow:resp[5].data.data
      })
    })
   
  }, [])

  const classes = useStyles();

  const handleDelete = () => { };
  
  const LoanexportHistory = (value) => {
    setShowDynamicTableLoanExport(true);
    setShowDynamicTablLoanImpHistory(false);
    setShowCodExportAwards(false);
    setShowCodImportAwards(false);
    setShowCodXportDisb(false);
  };



  const LoanImportHistory = (value) => {
    setShowDynamicTableLoanExport(false);
    setShowDynamicTablLoanImpHistory(true);
    setShowCodExportAwards(false);
    setShowCodImportAwards(false);
    setShowCodXportDisb(false);
  };

  const CodExportAwards = (value) => {
    setShowDynamicTableLoanExport(false);
    setShowDynamicTablLoanImpHistory(false);
    setShowCodExportAwards(true);
    setShowCodImportAwards(false);
    setShowCodXportDisb(false);

  };

  const CodImportAwards = (value) => {
    setShowDynamicTableLoanExport(false);
    setShowDynamicTablLoanImpHistory(false);
    setShowCodExportAwards(false)
    setShowCodImportAwards(true);
    setShowCodXportDisb(false);

  };

  const CodExportDisb = (value) => {
    setShowDynamicTableLoanExport(false);
    setShowDynamicTablLoanImpHistory(false);
    setShowCodExportAwards(false);
    setShowCodImportAwards(false);
    setShowCodXportDisb(true);

  };

  const CodImportDisb = (value) => {
    setShowDynamicTableLoanExport(false);
    setShowDynamicTablLoanImpHistory(false);
    setShowCodExportAwards(false)
    setShowCodImportAwards(false);
    setShowCodXportDisb(false);

  };


  return (
    <>
      <PopupHeader
        title={t("commonLabel.codHistory", { ns: "financialAid" })}

        navigationList={financialAid_codHistory}
        onClose={onClose}
      />
      <form className={classes.root}>

        <Card>
          <CardContent className={"headerTitle"}>
          <FormGroup>
              <TextInput
                disabled
                label={t("commonLabel.awardName", { ns: "financialAid" })}
                labelId="awardName"
                formikValue={formik.values.awardName}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Grid container className={"jc"}>
                <CardContent>
                  <ButtonWrapper>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={LoanexportHistory}
                    >
                      {t("buttonLabel.loanExportHistory", { ns: "financialAid" })}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={LoanImportHistory}
                    >
                      {t("buttonLabel.loanImportHistory", { ns: "financialAid" })}

                    </Button>
                    <Button
                      className="linkButton"
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={CodExportAwards}
                    >
                      {t("buttonLabel.codExportAwards", { ns: "financialAid" })}

                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"            
                     onClick={CodImportAwards}
                    >
                      {t("buttonLabel.codImportAwards", { ns: "financialAid" })}

                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={CodExportDisb}
                    >
                      {t("buttonLabel.codExportDisb", { ns: "financialAid" })}

                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={CodImportDisb}
                    >
                      {t("buttonLabel.codImportDisb", { ns: "financialAid" })}

                    </Button>
                  </ButtonWrapper>
                </CardContent>
              </Grid>
            </FormGroup>
            {showDynamicTableLoanExport  ?
              (
                    <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.loanExportHistory", { ns: "financialAid" })}  />               
              ) :

              (showDynamicTablLoanImpHistory  ?
                (
                      <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.loanImportHistory", { ns: "financialAid" })} />                    
                )
                :
                showCodExportAwards  ? (               
                      <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codExportAwards", { ns: "financialAid" })} />             
                                       )
                  :
                  showCodImportAwards  ? (              
                        <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codImportAwards", { ns: "financialAid" })} />                      
                                          )
                    :
                    showCodXportDisb  ? (                   
                          <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codExportDisb", { ns: "financialAid" })}/>                         
                                        )
                      : (                
                            <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codImportDisb", { ns: "financialAid" })} />                           
                        )
              )}
            {showDynamicTableLoanExport  ? (
              <DefaultTable
                trackingLabel={"batchesBilling"}
                headCells={viewloanexporthistory}
                rows={tableRow.loanExportHistoryRow}
                pagination={true}
                onRowSelect={getSelectedRow}
                onDelete={handleDelete}
              />
            ) :

              (showDynamicTablLoanImpHistory  ? (
                <DefaultTable
                  trackingLabel={"batchesBilling"}
                  headCells={viewloanimporthistory}
                  rows={tableRow.viewLoanImportHistoryRow}
                  pagination={true}
                  onRowSelect={getSelectedRow}
                  onDelete={handleDelete}
                />
              ) :
                showCodExportAwards  ? (

                  <DefaultTable
                    trackingLabel={"batchesBilling"}
                    headCells={viewcodexportawardhistory}
                    rows={tableRow.codExportAwardHistoryRow}
                    pagination={true}
                    onRowSelect={getSelectedRow}
                    onDelete={handleDelete}
                  />
                ) :

                  showCodImportAwards  ? (
                    <DefaultTable
                      trackingLabel={"batchesBilling"}
                      headCells={viewcodimportsawardhistory}
                      rows={tableRow.viewCodImportsAwardHistoryRow}
                      pagination={true}
                      onRowSelect={getSelectedRow}
                      onDelete={handleDelete}
                    />
                  ) :
                    showCodXportDisb  ? (
                      <DefaultTable
                        trackingLabel={"batchesBilling"}
                        headCells={coddisbexporthistory}
                        rows={tableRow.codDisbExportHistoryRow}
                        pagination={true}
                        onRowSelect={getSelectedRow}
                        onDelete={handleDelete}
                      />
                    ) :(
                      <>
                      <DefaultTable
                        trackingLabel={"batchesBilling"}
                        headCells={coddisbimporthistory}
                        rows={tableRow.codDisbImportHistoryRow}
                        pagination={true}
                        onRowSelect={getSelectedRow}
                        onDelete={handleDelete}
                      />
                      <Splitter height={'40px'} />
                      <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codDisbImportHistoryErrors", { ns: "financialAid" })} />                           
                    <DefaultTable
                        trackingLabel={"batchesBilling"}
                        headCells={coddisbimporthistoryerrors}
                        rows={tableRow.codDisbImportHistoryErrorsRow}
                        pagination={true}
                        onRowSelect={getSelectedRow}
                        onDelete={handleDelete}
                        />
                        </>
              )
              )}
          </CardContent>
        </Card>
        <ButtonWrapperFilter>
          <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={() => onClose(false)}>
            {t('buttonLabel.cancel')}
          </Button>
        </ButtonWrapperFilter>
      </form>
    </>
  );
};
