import { UPDATE_CRITERIA_TAB, UPDATE_NSLCDATA_TAB, RESET_FROM_CRITERIA_TAB } from './types'
const initialState = {
    criteriaTab:"",
    nslcDataTab:"",
    resetFromNslcTab:""
};
const nslcReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {...(state.criteriaTab),...payload}
                
            };
        case UPDATE_NSLCDATA_TAB:
            return {
                ...state,
                nslcDataTab: {...(state.nslcDataTab),...payload}
                
            };
            case RESET_FROM_CRITERIA_TAB:
            return {
                ...state,
                resetFromNslcTab: payload
                
            };
        default:
            return state;
    }
}
export default nslcReducer