import Swal from 'sweetalert2';
import i18next from 'i18next';

export const successPopup = (title = '') => {
    return Swal.fire({
        icon: 'success',
        title: 'Successfully saved',
        showConfirmButton: true,
        confirmButtonColor: '#F9861D',
        confirmButtonText: i18next.t('buttonLabel.ok')
    });
}
export const deletePopup = (title = '', text = '') => {
    return Swal.fire({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F9861D',
        cancelButtonColor: '#0000FF',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
    });
}
export const errorPopup = () => {
    return Swal.fire({
        icon: i18next.t('popupErrorMessages.errorIcon'),
        title: i18next.t('popupErrorMessages.404Title'),
        text: i18next.t('popupErrorMessages.404Text'),
        confirmButtonColor: '#FF9900',
    });
}

/// note: it returns then => res=> verify ok/confirm button => res.confirmed? true: false