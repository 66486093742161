import { COPY_MEAL_PLAN, EDIT_MEAL_PLAN, VIEW_MEAL_PLAN, ADD_MEAL_PLAN } from './types'

const initialState = {
    copyMealPlan: ''
};

const mealPlanReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case COPY_MEAL_PLAN:
            return {
                ...state,
                mealPlan: payload,
                formMode:type
            };
        case VIEW_MEAL_PLAN:
            return {
                ...state,
                mealPlan: payload,
                formMode: type
            };
        case EDIT_MEAL_PLAN:
            return {
                ...state,
                mealPlan: payload,
                formMode: type
            };
        case ADD_MEAL_PLAN:
            return {
                ...state,
                mealPlan: null,
                formMode: type
            };

        default:
            return state;
    }
}
export default mealPlanReducer