import { SAVE_TERM,SAVE_TABLE_ROW } from './types'

const initialState = {
    term: 0,
    tableRow: []
};

const gradesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_TERM:
            return {
                ...state,
                term: payload
            };
        case SAVE_TABLE_ROW:
            return {
                ...state,
                tableRow: [...payload]
            };
        default:
            return state;
    }
};

export default gradesReducer;