import React, { useEffect, useState } from 'react';
import { makeStyles, InputLabel, TextareaAutosize, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { FormGroup, FormInputWrapper, ButtonWrapperFilter } from '../../styles/common-styles'
import BrandingColors from '../../styles/color';
import { useDispatch } from 'react-redux';
import { ask_Question, get_Answers } from '../../services/apiURL';
import { getApi, postApi } from '../../services/apiBase';
import { getCurrentAnswer, getUnansweredQuestions } from '../../redux/admisssion/prospects/addQuestions/action';
import { addQuestionSelector } from '../../redux/admisssion/prospects/addQuestions/selectors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root,& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            padding: '0px'
        },
        '& .MuiRadio-root .MuiSvgIcon-root': {
            fontSize: '1em'
        },
        '& .MuiRadio-root.Mui-checked': {
            color: `${BrandingColors.darkBlack}`
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: `${BrandingColors.darkBlack}`
        },
        '& .sendButton': {
            background: `${BrandingColors.yellowOrange}`,
            padding: "4px 48px",
            margin: "12px 10px",
            fontSize: "45px",
            cursor: 'pointer',
            position: "relative",
            color: `${BrandingColors.white}`
        },
        '& .helperText': {
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
            color: `${BrandingColors.darkBlack}`
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl >textarea ': {
            padding: "1.65% 14px",
        },

    }
}));
const AddQuestion = (props) => {
    const { t } = useTranslation();
    const { onClose, selectedRow, selectedIndex, popupHeaderTitle,searchMethod } = props;
    const { currentQuestion,unansweredQuestions } = useSelector(addQuestionSelector);
    const [isLoading, setIsloading] = useState(false)
    const dispatch = useDispatch();
    const classess = useStyles();
    const username = localStorage.getItem("username");
    const initialValues = {
        question: "",
        answer: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            let payload;
            if (popupHeaderTitle == "Required Entry") {
                payload = {
                    "prospectId": selectedRow.prospectId,
                    "questionId": 0,
                    "discussion": values.question,
                    "userId": username,
                    "ownerId": 0
                }
            } else if (popupHeaderTitle == "Give Answer") {
                payload = {
                    "prospectId": selectedRow.prospectID,
                    "questionId": selectedRow.id,
                    "discussion": values.answer,
                    "userId": username,
                    "ownerId": selectedRow.ownerID
                }
            }
            postApi(ask_Question, payload).then((resp) => {
                setIsloading(false)
                if (popupHeaderTitle == "Give Answer") {
                    getApi(`${get_Answers}${selectedRow.id}`).then((resp) => {
                        dispatch(getCurrentAnswer(resp.data.data))
                        searchMethod();
                    }).catch((err) => {
                        console.log(err);
                    })
                }
                // const newTableRows = unansweredQuestions.filter(emp => emp.id !== currentQuestion[0].id);
                // dispatch(getUnansweredQuestions(newTableRows))
            }).catch((err) => {
                setIsloading(false)
                console.log(err)
            })
            onClose()


        }
    });
    useEffect(() => {
        if (popupHeaderTitle == "Edit Answer") {
            formik.setFieldValue("answer", selectedRow.discussion);
            formik.setFieldValue("question", currentQuestion[0].discussion);
        }
        else if (popupHeaderTitle == "Give Answer") {
            formik.setFieldValue("question", selectedRow.discussion);
        }

    }, [selectedRow])
    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                <FormGroup>
                    <FormInputWrapper style={{ width: "100%" }} >
                        <InputLabel htmlFor="question">{t('commonLabel.question')} </InputLabel>
                        <TextareaAutosize
                            disabled={!(popupHeaderTitle == "Required Entry")}
                            aria-label="minimum height"
                            maxRows={10}
                            minRows={6}
                            name="question"
                            onChange={formik.handleChange}
                            value={formik.values.question}
                            style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                        />
                    </FormInputWrapper>
                </FormGroup>
                {!(popupHeaderTitle == "Required Entry") && <FormGroup>
                    <FormInputWrapper style={{ width: "100%" }} >
                        <InputLabel htmlFor="answer">{t('commonLabel.answer')}</InputLabel>
                        <TextareaAutosize
                            disabled={!(popupHeaderTitle == "Give Answer")}
                            aria-label="minimum height"
                            maxRows={10}
                            minRows={6}
                            name="answer"
                            onChange={formik.handleChange}
                            value={formik.values.answer}
                            style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                        />
                    </FormInputWrapper>
                </FormGroup>}
                <ButtonWrapperFilter>
                    <Button disabled={popupHeaderTitle == "Edit Answer"} className={popupHeaderTitle == "Edit Answer" ? 'disabled' : 'BgYellowOrange'} aria-label={'Send'} variant="contained" size="large" type="submit">
                        Send
                    </Button>
                    <Button mr={4} onClick={onClose} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                        Cancel
                    </Button>
                </ButtonWrapperFilter>
            </form>
        </>
    )
}

export default AddQuestion;


