import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput, TextAreaInput } from "../../../../components/Forms";
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_userDefinedFields } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import * as yup from 'yup';
import { createYupSchema, parseNumber, scrollIntoViewHelper } from "../../../../helper";
import currencyPipe, { formNumber } from "../../../../helper/currency";
import { get_userdefined_data, userdefined_update, user_defined_setup_table } from "../../../../services/apiURL";
import { getApi, putApi } from "../../../../services/apiBase";
import { toastMessage } from "../../../../utils/Message/toasts";
import { maintenance } from "../../../../helper/constants";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { useSelector } from "react-redux";

const AddUserdefinedfields = ({ onClose, selectedRow, Dropdown, selectedSelected }) => {
  const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
  const classess = useStyles();
  const initCurrencyFields = (value = 0) => {
    return transform(parseNumber(value))
  }
  const [schema, setSchema] = useState({});
  const onSwipeCurrency = (e) => {
    const { name, value } = e.target;
    const amount = transform(parseNumber(value));
    formik.setFieldValue(name, amount);
  }
  const transform = (val) => {
    return currencyPipe(val, { symbol: '$', decimal: '.', separator: ',', precision: 2 }).format();
  }
  const [fieldName, setFieldName] = useState({
    "brandonTest1": "",                    
    "brandonTest2": "",                    
    "brandonTest3": "",                  
    "brandonTest": "",               
    "tianaTest1": "",               
    "userDefFreeFormat3": "",               
    "finAidDate": "",                 
    "userDefDate2": "",            
    "userDefDate3": "",              
    "userDefReal1": "",          
    "userDefReal2": "",             
    "userDefReal3": "",            
    "userDefinedMoney1": "",            
    "userDefinedMoney2": "",             
    "userDefinedMoney3": "",           
    "thirdPartyPackage": "",          
    "userDefBit2": "",            
    "userDefBit3": "",            
    "userDefNote1": "",           
    "userDefNote2": "",             
    "userDefNote3": ""            
  })

  const initialValues = {
    brandonTest1: 0,
    brandonTest2: 0,
    brandonTest3: 0,
    finAidDate: null,
    userDefReal1: 0,
    userDefinedMoney1: initCurrencyFields(0),
    thirdPartyPackage: '',
    userDefNote1: "",
    userDefBit2: '',
    userDefNote2: "",
    userDefNote3: "",
    userDefDate3: null,
    userDefDate2: null,
    userDefBit3: "",
    brandonTest: '',
    tianaTest1: '',
    userDefFreeFormat3: '',
    userDefReal2: 0,
    userDefReal3: 0,
    userDefinedMoney2: initCurrencyFields(0),
    userDefinedMoney3: initCurrencyFields(0)

  };
  const [formValues, setFormValues] = useState('')
  const validationSchema = yup.object({
    brandonTest1:
      yup.number()
        .min(1, t('validationMessages.isRequired', { what: t('commonLabel.brandonTest1', { ns: "financialAid" }) }))
        .required(t('validationMessages.isRequired', { what: t('commonLabel.brandonTest1', { ns: "financialAid" }) })),
    brandonTest: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.brandonTest', { ns: "financialAid" }) })),
    finAidDate: yup
      .date().nullable()
      .required(t("validationMessages.isRequired", { what: t("commonLabel.finAidDate", { ns: "financialAid" }), })),
    userDefReal1: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.userDefReal1', { ns: "financialAid" }) })),
    userDefinedMoney1: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.userDefMoney1', { ns: "financialAid" }) })),
    thirdPartyPackage: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.thirdPartyPackage', { ns: "financialAid" }) })),
    userDefNote1: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.userDefNote1', { ns: "financialAid" }) })),
    userDefBit2: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.userDefBit2', { ns: "financialAid" }) })),

  });

  const [userDefinedData, setUserDefinedData] = useState({})
  useEffect(() => {
    getApi(`${get_userdefined_data}/${selectedSelected.studentUID}`).then((resp) => {
      setFormValues(resp.data.data)
      setUserDefinedData(resp.data.data)
    }).catch((err) => {
      console.log(err);
    })
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
    getApi(`${user_defined_setup_table}/${Number(6)}`).then((resp) => {
      const { data } = resp.data
      console.log(data, "----data--");
      //Setting up the field validation on top of api response
      setFieldName({
        ...fieldName,
        "brandonTest1": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary1')[0].fieldStyleRequired,
          id: 'brandonTest1',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary1')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'select'
        },
        "brandonTest2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary2')[0].fieldStyleRequired,
          id: 'brandonTest2',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary2')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'select'
        },
        "brandonTest3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefGlossary3')[0].fieldStyleRequired,
          id: 'brandonTest3',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary3')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefGlossary3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'select'
        },
        "brandonTest": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat1')[0].fieldStyleRequired,
          id: 'brandonTest',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "tianaTest1": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat2')[0].fieldStyleRequired,
          id: 'tianaTest1',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefFreeFormat3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat3')[0].fieldStyleRequired,
          id: 'userDefFreeFormat3',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefFreeFormat3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "finAidDate": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldStyleRequired,
          id: 'finAidDate',
          validationType: "date",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "max",
              params: [new Date(t('validationMessages.maxDate')), `${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "typeError",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'date'
        },
        "userDefDate2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefDate2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefDate2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefDate2')[0].fieldStyleRequired,
          id: 'userDefDate2',
          validationType: "date",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "max",
              params: [new Date(t('validationMessages.maxDate')), `${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "typeError",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'date'
        },
        "userDefDate3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefDate3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefDate3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefDate3')[0].fieldStyleRequired,
          id: 'userDefDate3',
          validationType: "date",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "max",
              params: [new Date(t('validationMessages.maxDate')), `${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            },
            {
              type: "typeError",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefDate1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'date'
        },
        "userDefReal1": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefReal1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefReal1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefReal1')[0].fieldStyleRequired,
          id: 'userDefReal1',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefReal1')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefReal1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefReal2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefReal2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefReal2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefReal2')[0].fieldStyleRequired,
          id: 'userDefReal2',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefReal2')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefReal2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefReal3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefReal3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefReal3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefReal3')[0].fieldStyleRequired,
          id: 'userDefReal3',
          validationType: "number",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefReal3')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefReal3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefinedMoney1": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefMoney1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefMoney1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefMoney1')[0].fieldStyleRequired,
          id: 'userDefinedMoney1',
          validationType: "currency",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefMoney1')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefMoney1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefinedMoney2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefMoney2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefMoney2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefMoney2')[0].fieldStyleRequired,
          id: 'userDefinedMoney2',
          validationType: "currency",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefMoney2')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefMoney2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "userDefinedMoney3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefMoney3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefMoney3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefMoney3')[0].fieldStyleRequired,
          id: 'userDefinedMoney3',
          validationType: "currency",
          validations: [
            {
              type: "min",
              params: [1, `${data.filter(v => v.fieldPhysicalName === 'UserDefMoney3')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefMoney3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'text'
        },
        "thirdPartyPackage": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefBit1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefBit1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefBit1')[0].fieldStyleRequired,
          id: 'thirdPartyPackage',
          validationType: "bool",
          validations: [
            {
              type: "oneOf",
              params: [[true], `${data.filter(v => v.fieldPhysicalName === 'UserDefBit1')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefBit1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'checkbox'
        },
        "userDefBit2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefBit2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefBit2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefBit2')[0].fieldStyleRequired,
          id: 'userDefBit2',
          validationType: "bool",
          validations: [
            {
              type: "oneOf",
              params: [[true], `${data.filter(v => v.fieldPhysicalName === 'UserDefBit2')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefBit2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'checkbox'
        },
        "userDefBit3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefBit3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefBit3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefBit3')[0].fieldStyleRequired,
          id: 'userDefBit3',
          validationType: "bool",
          validations: [
            {
              type: "oneOf",
              params: [[true], `${data.filter(v => v.fieldPhysicalName === 'UserDefBit3')[0].fieldDisplayName} is required`]
            },
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefBit3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'checkbox'
        },
        "userDefNote1": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefNote1')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefNote1')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefNote1')[0].fieldStyleRequired,
          id: 'userDefNote1',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefNote1')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'textarea'
        },
        "userDefNote2": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefNote2')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefNote2')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefNote2')[0].fieldStyleRequired,
          id: 'userDefNote2',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefNote2')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'textarea'
        },
        "userDefNote3": {
          label: data.filter(v => v.fieldPhysicalName === 'UserDefNote3')[0].fieldDisplayName,
          isShow: data.filter(v => v.fieldPhysicalName === 'UserDefNote3')[0].fieldStyleDisplay,
          isRequired: data.filter(v => v.fieldPhysicalName === 'UserDefNote3')[0].fieldStyleRequired,
          id: 'userDefNote3',
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [`${data.filter(v => v.fieldPhysicalName === 'UserDefNote3')[0].fieldDisplayName} is required`]
            }
          ],
          field: 'textarea'
        }
      })

    }).catch((err) => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    if (fieldName.brandonTest1) {
      let fieldRequired = Object.values(fieldName).filter(v => v.isRequired && v.isShow)
      setSchema(yup.object().shape(fieldRequired.reduce(createYupSchema, {})))
    }
  }, [fieldName])
  
  const formik = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payloadRequest = {
        "systemUID": selectedSelected.studentUID,
        "camsModuleID": 6,
        "brandonTest1": values.brandonTest1,   // 1
        "brandonTest2": values.brandonTest2,   // 2
        "brandonTest3": values.brandonTest3,   // 3
        "brandonTest": values.brandonTest,     // 4
        "tianaTest1": values.tianaTest1,       // 5
        "userDefFreeFormat3": values.userDefFreeFormat3,  // 6
        "finAidDate": values.finAidDate,       // 7
        "userDefDate2": values.userDefDate2,   // 8
        "userDefDate3": values.userDefDate3,   // 9
        "userDefReal1": values.userDefReal1,   // 10
        "userDefReal2": values.userDefReal2,   // 11
        "userDefReal3": values.userDefReal3,   // 12
        "userDefMoney1": formNumber(values.userDefinedMoney1), // 13
        "userDefMoney2": formNumber(values.userDefinedMoney2), // 14
        "userDefMoney3": formNumber(values.userDefinedMoney3), // 15
        "thirdPartyPackage": values.thirdPartyPackage == '' ? false : true, // 16
        "userDefBit2": values.userDefBit2 == '' ? false : true,   // 17
        "userDefBit3": values.userDefBit3 == '' ? false : true,   // 18
        "userDefNote1": values.userDefNote1,                      // 19
        "userDefNote2": values.userDefNote2,                      // 20
        "userDefNote3": values.userDefNote3                       // 21
      }
      console.log(values)
      putApi(`${userdefined_update}`, payloadRequest).then((resp) => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
        onClose('');
      }).catch((err) => {
        console.log(err);
      })
    }

  });

  // useEffect(() => {
  //   formik.setFieldValue("userDefinedMoney1", userDefinedData ? transform(userDefinedData.userDefMoney1) : formik.values.userDefinedMoney1)
  //   formik.setFieldValue("userDefinedMoney2", userDefinedData ? transform(userDefinedData.userDefMoney2) : formik.values.userDefinedMoney2)
  //   formik.setFieldValue("userDefinedMoney3", userDefinedData ? transform(userDefinedData.userDefMoney3) : formik.values.userDefinedMoney3)
  // }, [userDefinedData])

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      scrollIntoViewHelper(formik.errors);
    }
  }, [formik]);
  // Role based access control redux
  const { access } = useSelector(updateAccessSelector)
  const { nestedParentLevel } = access;

  const [statusAccess, setStatusAccess] = useState({
    maintenance: 2,
  })
  useEffect(() => {


    let finStatusAccess = nestedParentLevel.filter((v, i) =>
      v.menuItemName.trim() === maintenance && v.moduleorder === 4
    )

    setStatusAccess({
      ...statusAccess,
      maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
    })

  }, [access])
  const [accessFlag, setAccessFlag] = useState(false)
  useEffect(() => {
    (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
  }, [statusAccess])
  //RBA Ends here


  return (
    <>
      <PopupHeader
        title=
        {
          t("commonLabel.updateUserDefinedFields", {
            ns: "financialAid",
          })
        }
        navigationList={financialAid_userDefinedFields}
        onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
          <CardContent>
            {fieldName.brandonTest1.isShow &&
              <FormGroup>
                <SelectInput
                  isDoubled
                  label={fieldName.brandonTest1.label}
                  labelId="brandonTest1"
                  formikValue={formik.values.brandonTest1}
                  formikChange={formik.handleChange}
                  mandatory={fieldName.brandonTest1.isRequired}
                  errors={(fieldName.brandonTest1.isRequired) ? formik.errors.brandonTest1 : ''}
                  touched={(fieldName.brandonTest1.isRequired) ? formik.touched.brandonTest1 : ''}
                  disabled={accessFlag}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.brandonTest1Drop }}
                />
              </FormGroup>
            }
            {fieldName.brandonTest2.isShow &&
              <FormGroup>
                <SelectInput
                  isDoubled
                  label={fieldName.brandonTest2.label}
                  labelId="brandonTest2"
                  formikValue={formik.values.brandonTest2}
                  formikChange={formik.handleChange}
                  disabled={accessFlag}
                  mandatory={fieldName.brandonTest2.isRequired}
                  errors={(fieldName.brandonTest2.isRequired) ? formik.errors.brandonTest2 : ''}
                  touched={(fieldName.brandonTest2.isRequired) ? formik.touched.brandonTest2 : ''}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.brandonTest2Drop }}
                />
              </FormGroup>
            }
            {fieldName.brandonTest3.isShow &&
              <FormGroup>
                <SelectInput
                  isDoubled
                  label={t("commonLabel.brandonTest3", { ns: "financialAid" })}
                  labelId="brandonTest3"
                  formikValue={formik.values.brandonTest3}
                  formikChange={formik.handleChange}
                  disabled={accessFlag}
                  mandatory={fieldName.brandonTest3.isRequired}
                  errors={(fieldName.brandonTest3.isRequired) ? formik.errors.brandonTest3 : ''}
                  touched={(fieldName.brandonTest3.isRequired) ? formik.touched.brandonTest3 : ''}
                  dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.brandonTest3Drop }}
                />
              </FormGroup>
            }
            {fieldName.brandonTest.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  label={fieldName.brandonTest.label}
                  labelId="brandonTest"
                  formikValue={formik.values.brandonTest}
                  formikChange={formik.handleChange}
                  disabled={accessFlag}
                  mandatory={fieldName.brandonTest.isRequired}
                  errors={(fieldName.brandonTest.isRequired) ? formik.errors.brandonTest : ''}
                  touched={(fieldName.brandonTest.isRequired) ? formik.touched.brandonTest : ''}
                />
              </FormGroup>
            }
            {fieldName.tianaTest1.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.tianaTest1.label}
                  labelId="tianaTest1"
                  formikValue={formik.values.tianaTest1}
                  formikChange={formik.handleChange}
                  mandatory={fieldName.tianaTest1.isRequired}
                  errors={(fieldName.tianaTest1.isRequired) ? formik.errors.tianaTest1 : ''}
                  touched={(fieldName.tianaTest1.isRequired) ? formik.touched.tianaTest1 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefFreeFormat3.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefFreeFormat3.label}
                  labelId="userDefFreeFormat3"
                  formikValue={formik.values.userDefFreeFormat3}
                  formikChange={formik.handleChange}

                  mandatory={fieldName.userDefFreeFormat3.isRequired}
                  errors={(fieldName.userDefFreeFormat3.isRequired) ? formik.errors.userDefFreeFormat3 : ''}
                  touched={(fieldName.userDefFreeFormat3.isRequired) ? formik.touched.userDefFreeFormat3 : ''}
                />
              </FormGroup>
            }
            {fieldName.finAidDate.isShow &&
              <FormGroup>
                <DateInput
                  isDoubled
                  label={fieldName.finAidDate.label}
                  labelId="finAidDate"
                  disabled={accessFlag}
                  defaultValue={formik.values.finAidDate}
                  formik={formik}
                  mandatory={fieldName.finAidDate.isRequired}
                  errors={(fieldName.finAidDate.isRequired) ? formik.errors.finAidDate : ''}
                  touched={(fieldName.finAidDate.isRequired) ? formik.touched.finAidDate : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefDate2.isShow &&
              <FormGroup>
                <DateInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefDate2.label}
                  labelId="userDefDate2"
                  defaultValue={formik.values.userDefDate2}
                  formik={formik}
                  mandatory={fieldName.userDefDate2.isRequired}
                  errors={(fieldName.userDefDate2.isRequired) ? formik.errors.userDefDate2 : ''}
                  touched={(fieldName.userDefDate2.isRequired) ? formik.touched.userDefDate2 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefDate3.isShow &&
              <FormGroup>
                <DateInput
                  isDoubled
                  label={fieldName.userDefDate3.label}
                  labelId="userDefDate3"
                  disabled={accessFlag}
                  defaultValue={formik.values.userDefDate3}
                  formik={formik}
                  mandatory={fieldName.userDefDate3.isRequired}
                  errors={(fieldName.userDefDate3.isRequired) ? formik.errors.userDefDate3 : ''}
                  touched={(fieldName.userDefDate3.isRequired) ? formik.touched.userDefDate3 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefReal1.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefReal1.label}
                  labelId="userDefReal1"
                  formikValue={formik.values.userDefReal1}
                  formikChange={formik.handleChange}
                  onlyNumeric
                  mandatory={fieldName.userDefReal1.isRequired}
                  errors={(fieldName.userDefReal1.isRequired) ? formik.errors.userDefReal1 : ''}
                  touched={(fieldName.userDefReal1.isRequired) ? formik.touched.userDefReal1 : ''}

                />
              </FormGroup>
            }
            {fieldName.userDefReal2.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  onlyNumeric
                  disabled={accessFlag}
                  label={fieldName.userDefReal2.label}
                  labelId="userDefReal2"
                  formikValue={formik.values.userDefReal2}
                  formikChange={formik.handleChange}
                  mandatory={fieldName.userDefReal2.isRequired}
                  errors={(fieldName.userDefReal2.isRequired) ? formik.errors.userDefReal2 : ''}
                  touched={(fieldName.userDefReal2.isRequired) ? formik.touched.userDefReal2 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefReal3.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  onlyNumeric
                  disabled={accessFlag}
                  label={fieldName.userDefReal3.label}
                  labelId="userDefReal3"
                  formikValue={formik.values.userDefReal3}
                  formikChange={formik.handleChange}
                  mandatory={fieldName.userDefReal3.isRequired}
                  errors={(fieldName.userDefReal3.isRequired) ? formik.errors.userDefReal3 : ''}
                  touched={(fieldName.userDefReal3.isRequired) ? formik.touched.userDefReal3 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefinedMoney1.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefinedMoney1.label}
                  labelId="userDefinedMoney1"
                  formikValue={formik.values.userDefinedMoney1}
                  formikChange={formik.handleChange}
                  onBlur={onSwipeCurrency}
                  mandatory={fieldName.userDefinedMoney1.isRequired}
                  errors={(fieldName.userDefinedMoney1.isRequired) ? formik.errors.userDefinedMoney1 : ''}
                  touched={(fieldName.userDefinedMoney1.isRequired) ? formik.touched.userDefinedMoney1 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefinedMoney2.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefinedMoney2.label}
                  labelId="userDefinedMoney2"
                  formikValue={formik.values.userDefinedMoney2}
                  formikChange={formik.handleChange}
                  onBlur={onSwipeCurrency}
                  mandatory={fieldName.userDefinedMoney2.isRequired}
                  errors={(fieldName.userDefinedMoney2.isRequired) ? formik.errors.userDefinedMoney2 : ''}
                  touched={(fieldName.userDefinedMoney2.isRequired) ? formik.touched.userDefinedMoney2 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefinedMoney3.isShow &&
              <FormGroup>
                <TextInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.userDefinedMoney3.label}
                  labelId="userDefinedMoney3"
                  formikValue={formik.values.userDefinedMoney3}
                  formikChange={formik.handleChange}
                  onBlur={onSwipeCurrency}
                  mandatory={fieldName.userDefinedMoney3.isRequired}
                  errors={(fieldName.userDefinedMoney3.isRequired) ? formik.errors.userDefinedMoney3 : ''}
                  touched={(fieldName.userDefinedMoney3.isRequired) ? formik.touched.userDefinedMoney3 : ''}
                />
              </FormGroup>
            }
            {fieldName.thirdPartyPackage.isShow &&
              <FormGroup>
                <CheckBoxInput
                  isDoubled
                  disabled={accessFlag}
                  label={fieldName.thirdPartyPackage.label}
                  labelId="thirdPartyPackage"
                  formikValue={formik.values.thirdPartyPackage}
                  formikChange={formik.handleChange}
                  checked={formik.values.thirdPartyPackage}
                  mandatory={fieldName.thirdPartyPackage.isRequired}
                  errors={(fieldName.thirdPartyPackage.isRequired) ? formik.errors.thirdPartyPackage : ''}
                  touched={(fieldName.thirdPartyPackage.isRequired) ? formik.touched.thirdPartyPackage : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefBit2.isShow &&
              <FormGroup>
                <CheckBoxInput
                  isDoubled
                  label={fieldName.userDefBit2.label}
                  labelId="userDefBit2"
                  disabled={accessFlag}
                  formikValue={formik.values.userDefBit2}
                  formikChange={formik.handleChange}
                  checked={formik.values.userDefBit2}
                  mandatory={fieldName.userDefBit2.isRequired}
                  errors={(fieldName.userDefBit2.isRequired) ? formik.errors.userDefBit2 : ''}
                  touched={(fieldName.userDefBit2.isRequired) ? formik.touched.userDefBit2 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefBit3.isShow &&
              <FormGroup>
                <CheckBoxInput
                  isDoubled
                  label={fieldName.userDefBit3.label}
                  labelId="userDefBit3"
                  disabled={accessFlag}
                  formikValue={formik.values.userDefBit3}
                  formikChange={formik.handleChange}
                  checked={formik.values.userDefBit3}
                  mandatory={fieldName.userDefBit3.isRequired}
                  errors={(fieldName.userDefBit3.isRequired) ? formik.errors.userDefBit3 : ''}
                  touched={(fieldName.userDefBit3.isRequired) ? formik.touched.userDefBit3 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefNote1.isShow &&
              <FormGroup>
                <TextAreaInput isDoubled
                  label={fieldName.userDefNote1.label}
                  labelId='userDefNote1'
                  disabled={accessFlag}
                  formikChange={formik.handleChange}
                  formikValue={formik.values.userDefNote1}
                  mandatory={fieldName.userDefNote1.isRequired}
                  errors={(fieldName.userDefNote1.isRequired) ? formik.errors.userDefNote1 : ''}
                  touched={(fieldName.userDefNote1.isRequired) ? formik.touched.userDefNote1 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefNote2.isShow &&
              <FormGroup>
                <TextAreaInput isDoubled
                  label={fieldName.userDefNote2.label}
                  labelId='userDefNote2'
                  disabled={accessFlag}
                  formikChange={formik.handleChange}
                  formikValue={formik.values.userDefNote2}
                  mandatory={fieldName.userDefNote2.isRequired}
                  errors={(fieldName.userDefNote2.isRequired) ? formik.errors.userDefNote2 : ''}
                  touched={(fieldName.userDefNote2.isRequired) ? formik.touched.userDefNote2 : ''}
                />
              </FormGroup>
            }
            {fieldName.userDefNote3.isShow &&
              <FormGroup>
                <TextAreaInput isDoubled
                  label={fieldName.userDefNote3.label}
                  labelId='userDefNote3'
                  disabled={accessFlag}
                  formikChange={formik.handleChange}
                  formikValue={formik.values.userDefNote3}
                  mandatory={fieldName.userDefNote3.isRequired}
                  errors={(fieldName.userDefNote3.isRequired) ? formik.errors.userDefNote3 : ''}
                  touched={(fieldName.userDefNote3.isRequired) ? formik.touched.userDefNote3 : ''}
                />
              </FormGroup>
            }

            <div style={{ display: "flex" }}>
              <ButtonWrapperFilter>
                <Button
                  className="BgYellowOrange Search"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={accessFlag}
                >
                  {t('buttonLabel.update')}
                </Button>
                <Button
                  aria-label={"Cancel"}
                  className="BgLightBlue"
                  variant="contained"
                  size="large"
                  onClick={() => onClose("")}
                >
                  {t('buttonLabel.cancel')}
                </Button>
              </ButtonWrapperFilter>
            </div>
          </CardContent>
      </form>
    </>
  );
};
export default AddUserdefinedfields;   
