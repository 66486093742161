import { Button, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioInput, TextAreaInput, TextInput } from '../../../../components/Forms';
import { ButtonWrapperFilter, FormGroup, useStyles } from '../../../../styles/common-styles';
import * as yup from 'yup'
import PopupHeader from '../../../common-page/popupHeader';
import { tools_crsevaluationsetup_addForm } from '../../../../services/navigation_folder/tools';
import { crs_eval_master } from '../../../../services/apiURL';
import { postApi, putApi } from '../../../../services/apiBase';
import { toastMessage } from '../../../../utils/Message/toasts';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import Tooltips from '../../../../utils/Tooltip/Tooltip';

const Add = ({ onClose, selectedRow,accessFlag }) => {
    const { t } = useTranslation(["translation", "tools"]);
    const initialValues = {
        active: 'yes',
        formName: "",
        description: "",
        maxQuestions: 0,
        formId: "",
        numQuestions: 0,
    }
    const validationSchema = yup.object({
        formName: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.formName', { ns: "tools" }) })),
        description: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.description', { ns: "tools" }) })),
        maxQuestions: yup.number()
            .test(
                'len',
                t('validationMessages.pleaseCheck', { what: t("commonLabels.maximumNumberOfQuestions", { ns: "tools" }) }),
                (value) => value > 0
            ),
        active: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.active', { ns: "tools" }) }))
    })
    const [formValues, setFormValues] = useState(initialValues)
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const payload = {
                "formId": (!selectedRow) ? 0 : selectedRow.formId,
                "formName": values.formName,
                "active": values.active === 'yes' ? 'Yes' : 'No',
                "numQuestions": values.numQuestions,
                "description": values.description,
                "maxQuestions": values.maxQuestions
            }
            if (!selectedRow) {
                postApi(`${crs_eval_master}`, payload).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                    onClose(false)
                    DefaultTable.resetTable('formlist')
                }).catch((err) => {
                    console.log(err)
                })
            }
            else {
                putApi(`${crs_eval_master}`, payload).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    onClose(false)
                    DefaultTable.updateSelectedRow('formlist', values)
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
    })

    const classes = useStyles();
    React.useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
        if (selectedRow) {
            setFormValues({
                ...selectedRow,
                active: selectedRow.active === 'Yes' ? 'yes' : 'no'
            })
        }
        else {
            setFormValues(initialValues)
        }
    }, [])
    return (
        <>
            <PopupHeader title={(selectedRow) ? t('commonLabels.updateForm', { ns: "tools" }) : t('commonLabels.addForm', { ns: "tools" })} onClose={onClose} navigationList={tools_crsevaluationsetup_addForm} autoComplete="off" />
            <form onSubmit={formik.handleSubmit} className={classes.root}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <TextInput isDoubled disabled
                                labelId="formId"
                                label={t('commonLabels.formId', { ns: "tools" })}
                                formikValue={formik.values.formId}
                                formikChange={formik.handleChange}
                            />
                            <TextInput isDoubled disabled
                                labelId="numQuestions"
                                label={t('commonLabels.numofquos', { ns: "tools" })}
                                formikValue={formik.values.numQuestions}
                                formikChange={formik.handleChange} onlyNumeric
                            />
                        </FormGroup>
                        <FormGroup>
                            <RadioInput list={[{ label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' }]}
                                labelId='active'
                                label={t('commonLabels.active', { ns: 'tools' })}
                                formikValue={formik.values.active}
                                formikChange={formik.handleChange}
                                isDoubled={true}
                                disabled={accessFlag}
                                errors={formik.errors.active}
                                touched={formik.touched.active} />
                            <TextInput
                                label={t("commonLabels.formName", { ns: "tools" })}
                                labelId={"formName"}
                                formikValue={formik.values.formName}
                                formikChange={formik.handleChange}
                                isDoubled={true}
                                mandatory={true}
                                errors={formik.errors.formName}
                                touched={formik.touched.formName}
                                maxlength={'50'}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextAreaInput isDoubled
                                label={t("commonLabels.description", { ns: "tools" })}
                                labelId={'description'}
                                formikChange={formik.handleChange}
                                formikValue={formik.values.description}
                                mandatory={true}
                                errors={formik.errors.description}
                                touched={formik.touched.description}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.maxnumofque", { ns: "tools" })}
                                labelId={"maxQuestions"}
                                formikValue={formik.values.maxQuestions}
                                formikChange={formik.handleChange}
                                isDoubled={true}
                                mandatory={true}
                                errors={formik.errors.maxQuestions}
                                touched={formik.touched.maxQuestions}
                                onlyNumeric
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <div style={{ display: 'flex' }}>
                            <Tooltips flag={selectedRow} row={(selectedRow) ? {
                                insertUserID: selectedRow.insertUserId,
                                insertTime: selectedRow.insertTime,
                                updateUserID: selectedRow.updateUserId,
                                updateTime: selectedRow.updateTime
                            } : ''} />
                            <ButtonWrapperFilter>
                                <Button
                                    className='BgYellowOrange search'
                                    variant="contained"
                                    size="large"
                                    disabled={accessFlag}
                                    type="submit"
                                >
                                    {(!selectedRow) ? t('buttonLabels.add', { ns: "tools" }) : t('buttonLabels.update', { ns: "tools" })}
                                </Button>
                                <Button
                                    className="BgLightBlue search"
                                    variant="contained"
                                    size="large"
                                    type="reset"
                                    onClick={() => onClose('')}
                                >
                                    {t("buttonLabels.cancel", { ns: "tools" })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>

    )
}
export default Add