export const UPDATE_BATCH_ROWS = 'UPDATE_BATCH_ROWS';
export const UPDATE_SELECTED_BATCH = 'UPDATE_SELECTED_BATCH';
export const UPDATE_STUDENT_ROWS = 'UPDATE_STUDENT_ROWS';
export const UPDATE_SELECTED_STUDENT = 'UPDATE_SELECTED_STUDENT';
export const GET_CALCULATE = 'GET_CALCULATE'
export const GET_NONSTUDENT = 'GET_NONSTUDENT'
export const UPDATE_NON_STUDENT = 'UPDATE_NON_STUDENT'
export const GET_SUMMARY_CALCULATE = 'GET_SUMMARY_CALCULATE' 
export const UPDATE_NON_STUDENT_TAB = 'UPDATE_NON_STUDENT_TAB'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const UPDATE_EXCHANGE_RATES = 'UPDATE_EXCHANGE_RATES'
export const RESET_STATE = 'RESET_STATE'





