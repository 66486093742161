import React, { useState, useEffect } from "react";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput, TextLookUpInput } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles } from "../../../../styles/common-styles";
import { getApi, postApi, putApi } from '../../../../services/apiBase';
import { credit_select_item, save_creditplus, update_creditplus } from "../../../../services/apiURL";
import { toastMessage } from '../../../../utils/Message/toasts';
import { useTranslation } from "react-i18next";
import { financialAid_creditplus } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import GenericModal from '../../../../components/Modal/GenericModal';
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import StudentContactLookup from "./studentContactLookup";
import * as yup from 'yup';
import { scrollIntoViewHelper } from "../../../../helper";

const AddCreditPlusInfo = ({ onClose, selectedCreditplusRow, selectedSelected, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();
    const initialValues = {
        academicYears: 0,
        borrowerLastName: "",
        borrowerFirstName: "",
        borrowerMiddleName: "",
        studentisBorrower: false,
        creditDecisionStatuses: "",
        creditDecisionDate: null,
        originalCreditDecisionStatus: "",
        creditBalanceDate: null,
        creditDecisionExpirationDate: null,
        creditBalanceOptions: "",
        creditOverrideCodes: "",
        creditAppealStatuses: "",
        endorserAmount: null,
        schoolCreditBalanceOption: false,
        creditActionChoices: "",
        reconsiderationEligible: false,
        creditRequirementsMet: false,
        endorserApproved: false,
        applicationID: "",
        creditActionStatuses: "",
        previousApplicationID: "",
        unknownLoanAmount: false,
        sixMonthDefermentOption: false,
        maximumLoanIndicator: false,
        defermentOption: false,
        applicationLoanAmount: null,
        applicationSubmissionReason: "",
        applicationCompleteDate: null,
        awardRangeRequestedStartDate: null,
        awardRangeRequestedEndDate: null,
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [openAlert, setOpenAlert] = useState(false);

    const validationSchema = yup.object({
        borrowerLastName: yup
            .string()
            .when("studentisBorrower", {
                is: false,
                then: yup.string().required(t('status.borrowerMandatoryInfo', { ns: "financialAid" }))
            }),
        academicYears: yup
            .string()
            .required(t('validationMessages.isRequired', {
                what: t('commonLabel.academicYears', { ns: "financialAid" })
            }))
    });



    const formik = useFormik({
        initialValues: formValues || initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if ((formik.values.borrowerLastName === "") && (!formik.values.studentisBorrower)) {
                setOpenAlert(true)
            }
            let payloadRequest =
            {
                "studentUID": selectedSelected.studentUID,
                "ownerID": (values.studentisBorrower) ? Number(selectedSelected.studentUID) : Number(values.ownerID),
                "academicYearID": values.academicYears,
                "creditDecisionStatus": values.creditDecisionStatuses,
                "creditDecisionDate": values.creditDecisionDate,
                "originalCreditDecisionStatus": values.originalCreditDecisionStatus,
                "creditBalanceOption": values.creditBalanceOptions,
                "creditDecisionExpirationDate": values.creditDecisionExpirationDate,
                "creditAppealStatus": values.creditAppealStatuses,
                "endorserAmount": Number(values.endorserAmount) || null,
                "schoolCreditBalanceOption": values.schoolCreditBalanceOption,
                "creditActionChoice": values.creditActionChoices,
                "creditOverrideCode": values.creditOverrideCodes,
                "creditActionStatus": (values.creditActionStatuses) ? values.creditActionStatuses : '',
                "reconsiderationEligible": values.reconsiderationEligible,
                "creditRequirementsMet": values.creditRequirementsMet,
                "endorserApproved": values.endorserApproved,
                "applicationID": values.applicationID,
                "previousApplicationID": values.previousApplicationID,
                "unKnownLoanAmount": values.unknownLoanAmount,
                "sixMonthDefermentOption": values.sixMonthDefermentOption,
                "maximumLoanIndicator": values.maximumLoanIndicator,
                "defermentOption": values.defermentOption,
                "applicationLoanAmount": (values.applicationLoanAmount) ? Number(values.applicationLoanAmount) : null,
                "applicationSubmissionReason": values.applicationSubmissionReason,
                "applicationCompleteDate": values.applicationCompleteDate,
                "awardRangeRequestedStartDate": values.awardRangeRequestedStartDate,
                "awardRangeRequestedEndDate": values.awardRangeRequestedEndDate
            }
            if (selectedCreditplusRow) {
                putApi((`${update_creditplus}/${selectedCreditplusRow.id}`), payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    onClose(false)
                }).catch((err) => {
                    console.log(err)
                })
            }
            else {
                postApi(save_creditplus, payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
                    onClose(false)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }

    });

    const [Dropdown, setDropdown] = useState({
        "academicYears": [],
        "creditDecisionStatuses": [],
        "creditActionStatuses": [],
        "creditBalanceOptions": [],
        "creditOverrideCodes": [],
        "creditAppealStatuses": [],
        "creditActionChoices": [],
    })
    const [showStudentContactLookup, setShowStudentContactLookup] = useState(false);
    const [showAddData, setShowAddData] = useState(false);



    useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
        getApi(credit_select_item).then((resp) => {
            const { academicYears, creditDecisionStatuses, creditActionStatuses, creditBalanceOptions,
                creditOverrideCodes, creditAppealStatuses, creditActionChoices } = resp.data.data;
            setDropdown({
                ...Dropdown,
                "academicYears": academicYears,
                "creditDecisionStatuses": creditDecisionStatuses,
                "creditActionStatuses": creditActionStatuses,
                "creditBalanceOptions": creditBalanceOptions,
                "creditOverrideCodes": creditOverrideCodes,
                "creditAppealStatuses": creditAppealStatuses,
                "creditActionChoices": creditActionChoices
            })
        }).catch((err) => {
            console.log(err);
        })
        if (selectedCreditplusRow !== '' && selectedSelected !== '') {
            const { ownerID } = selectedCreditplusRow;
            const { studentUID } = selectedSelected;
            setFormValues({
                ...formValues,
                ownerID: selectedCreditplusRow.ownerID,
                academicYears: (selectedCreditplusRow.academicYearID) ? selectedCreditplusRow.academicYearID : 0,
                borrowerLastName: (selectedCreditplusRow.contactLast) ? selectedCreditplusRow.contactLast : "",
                borrowerFirstName: (selectedCreditplusRow.contactFirst) ? selectedCreditplusRow.contactFirst : "",
                borrowerMiddleName: (selectedCreditplusRow.contactMiddle) ? selectedCreditplusRow.contactMiddle : "",
                studentisBorrower: (Number(ownerID) === Number(studentUID)) ? true : false,
                creditDecisionStatuses: (selectedCreditplusRow.creditDecisionStatus) ? selectedCreditplusRow.creditDecisionStatus : "",
                creditDecisionDate: (selectedCreditplusRow.creditDecisionDate) ? selectedCreditplusRow.creditDecisionDate : null,
                originalCreditDecisionStatus: (selectedCreditplusRow.originalCreditDecisionStatus) ? selectedCreditplusRow.originalCreditDecisionStatus : "",
                creditDecisionExpirationDate: (selectedCreditplusRow.creditDecisionExpirationDate) ? selectedCreditplusRow.creditDecisionExpirationDate : null,
                creditBalanceOptions: (selectedCreditplusRow.creditBalanceOption) ? selectedCreditplusRow.creditBalanceOption : "",
                creditOverrideCodes: (selectedCreditplusRow.creditOverrideCode) ? selectedCreditplusRow.creditOverrideCode : "",
                creditAppealStatuses: (selectedCreditplusRow.creditAppealStatus) ? selectedCreditplusRow.creditAppealStatus : "",
                endorserAmount: (selectedCreditplusRow.endorserAmount) ? selectedCreditplusRow.endorserAmount : null,
                schoolCreditBalanceOption: (selectedCreditplusRow.schoolCreditBalanceOption) ? selectedCreditplusRow.schoolCreditBalanceOption : false,
                creditActionChoices: (selectedCreditplusRow.creditActionChoice) ? selectedCreditplusRow.creditActionChoice : "",
                reconsiderationEligible: (selectedCreditplusRow.reconsiderationEligible) ? selectedCreditplusRow.reconsiderationEligible : false,
                creditRequirementsMet: (selectedCreditplusRow.creditRequirementsMet) ? selectedCreditplusRow.creditRequirementsMet : false,
                endorserApproved: (selectedCreditplusRow.endorserApproved) ? selectedCreditplusRow.endorserApproved : false,
                applicationID: (selectedCreditplusRow.applicationID) ? selectedCreditplusRow.applicationID : "",
                creditActionStatuses: (selectedCreditplusRow.creditActionStatus) ? selectedCreditplusRow.creditActionStatus : "",
                previousApplicationID: (selectedCreditplusRow.previousApplicationID) ? selectedCreditplusRow.previousApplicationID : "",
                unknownLoanAmount: (selectedCreditplusRow.unKnownLoanAmount) ? selectedCreditplusRow.unKnownLoanAmount : false,
                sixMonthDefermentOption: (selectedCreditplusRow.sixMonthDefermentOption) ? selectedCreditplusRow.sixMonthDefermentOption : false,
                maximumLoanIndicator: (selectedCreditplusRow.maximumLoanIndicator) ? selectedCreditplusRow.maximumLoanIndicator : false,
                defermentOption: (selectedCreditplusRow.defermentOption) ? selectedCreditplusRow.defermentOption : false,
                applicationLoanAmount: (selectedCreditplusRow.applicationLoanAmount) ? selectedCreditplusRow.applicationLoanAmount : null,
                applicationSubmissionReason: (selectedCreditplusRow.applicationSubmissionReason) ? selectedCreditplusRow.applicationSubmissionReason : "",
                applicationCompleteDate: (selectedCreditplusRow.applicationCompleteDate) ? selectedCreditplusRow.applicationCompleteDate : null,
                awardRangeRequestedStartDate: (selectedCreditplusRow.awardRangeRequestedStartDate) ? selectedCreditplusRow.awardRangeRequestedStartDate : null,
                awardRangeRequestedEndDate: (selectedCreditplusRow.awardRangeRequestedEndDate) ? selectedCreditplusRow.awardRangeRequestedEndDate : null,
            })
        }

    }, [selectedSelected, selectedCreditplusRow])

    const handleStudentlookUp = () => {
        // addDatas(true)
        setShowAddData(true);
        setShowStudentContactLookup(true)
    }
    const setBarrowerValue = (value) => {
        formik.setFieldValue("borrowerLastName", `${value.contactLast}`)
        formik.setFieldValue("borrowerFirstName", `${value.contactFirst}`)
        formik.setFieldValue("borrowerMiddleName", `${value.contactMiddle}`)
        formik.setFieldValue("ownerID", `${value.contactId}`)
    }

    const onLookupClose = () => {
        setShowAddData(false);
    }

    useEffect(() => {
        //Checking if form is submitted or not 
        if (!formik.isSubmitting) return;
        //Checking the input if it has error and passing the error object {name:"name"} to scroll helper
        if (Object.keys(formik.errors).length > 0) {
            scrollIntoViewHelper(formik.errors);
        }
    }, [formik]);

    return (
        <>
            {!showAddData ?
                <>
                    <PopupHeader
                        title={
                            selectedCreditplusRow == ""
                                ? (t('commonLabel.addCreditplusAppInfo', { ns: "financialAid" }))
                                : (t('commonLabel.updateCreditplusAppInfo', { ns: "financialAid" }))
                        }
                        navigationList={financialAid_creditplus}
                        onClose={onClose}
                    />
                    <form className={classess.root} onSubmit={formik.handleSubmit}>
                        <Card>
                            <CardContent>
                                <FormGroup>
                                    <SelectInput isDoubled
                                        label={(t('commonLabel.academicYears', { ns: "financialAid" }))}
                                        labelId={"academicYears"}
                                        formikValue={formik.values.academicYears}
                                        formikChange={formik.handleChange}
                                        errors={formik.errors.academicYears}
                                        touched={formik.touched.academicYears}
                                        mandatory
                                        disabled={accessFlag}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.academicYears }}
                                    />
                                </FormGroup>
                                <HeaderTitle variant={'subHeading'} headerTitle={(t('commonLabel.borrower', { ns: "financialAid" }))} />
                                <FormGroup>
                                    {(!formik.values.studentisBorrower) &&
                                        <>
                                            <TextInput
                                                label={(t('commonLabel.contactLast', { ns: "financialAid" }))}
                                                labelId="borrowerLastName"
                                                formikChange={formik.handleChange}
                                                formikValue={formik.values.borrowerLastName}
                                                errors={formik.errors.borrowerLastName}
                                                touched={formik.touched.borrowerLastName}
                                                mandatory
                                                disabled
                                            />
                                            <TextInput
                                                label={(t('commonLabel.contactFirst', { ns: "financialAid" }))}
                                                labelId="borrower"
                                                formikValue={formik.values.borrowerFirstName}
                                                formikChange={formik.handleChange}
                                                disabled
                                            />
                                            <TextLookUpInput
                                                label={(t('commonLabel.contactMiddle', { ns: "financialAid" }))}
                                                labelId='borrowerMiddleName'
                                                formikChange={formik.handleChange}
                                                formikValue={formik.values.borrowerMiddleName}
                                                handlelookUp={handleStudentlookUp}
                                                disabled
                                            />
                                        </>
                                    }
                                    <CheckBoxInput
                                        label={(t('commonLabel.studentisBorrower', { ns: "financialAid" }))}
                                        labelId={"studentisBorrower"}
                                        formikValue={formik.values.studentisBorrower}
                                        formikChange={formik.handleChange}
                                        disabled={accessFlag}
                                        checked={formik.values.studentisBorrower}
                                        title={(t('status.studentBorrowerInfo', { ns: "financialAid" }))}
                                    />
                                </FormGroup>
                                <HeaderTitle variant={'subHeading'} headerTitle={(t('commonLabel.creditInformation', { ns: "financialAid" }))} />
                                <FormGroup>
                                    <SelectInput isDoubled
                                        label={(t('commonLabel.creditDecisionStatuses', { ns: "financialAid" }))}
                                        labelId="creditDecisionStatuses"
                                        formikValue={formik.values.creditDecisionStatuses}
                                        formikChange={formik.handleChange}
                                        disabled={accessFlag}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditDecisionStatuses }}
                                    />
                                    <DateInput isDoubled
                                        label={(t('commonLabel.creditDecisionDate', { ns: "financialAid" }))}
                                        labelId={"creditDecisionDate"}
                                        defaultValue={formik.values.creditDecisionDate}
                                        formik={formik}
                                        disabled={accessFlag}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.originalCreditDecisionStatus', { ns: "financialAid" }))}
                                        labelId="originalCreditDecisionStatus"
                                        formikValue={formik.values.originalCreditDecisionStatus}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditDecisionStatuses }}
                                    />
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditBalanceOptions', { ns: "financialAid" }))}
                                        labelId="creditBalanceOptions"
                                        formikValue={formik.values.creditBalanceOptions}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditBalanceOptions }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <DateInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditDecisionExpirationDate', { ns: "financialAid" }))}
                                        labelId={"creditDecisionExpirationDate"}
                                        defaultValue={formik.values.creditDecisionExpirationDate}
                                        formik={formik}
                                    />
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditAppealStatuses', { ns: "financialAid" }))}
                                        labelId="creditAppealStatuses"
                                        formikValue={formik.values.creditAppealStatuses}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditAppealStatuses }}
                                    />

                                </FormGroup>
                                <FormGroup>

                                    <TextInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.endorserAmount', { ns: "financialAid" }))}
                                        labelId="endorserAmount"
                                        formikValue={formik.values.endorserAmount}
                                        formikChange={formik.handleChange}
                                        maxlength={"14"}
                                        onlyNumeric={true}
                                    />
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.schoolCreditBalanceOption', { ns: "financialAid" }))}
                                        labelId={"schoolCreditBalanceOption"}
                                        formikValue={formik.values.schoolCreditBalanceOption}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.schoolCreditBalanceOption}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditActionChoices', { ns: "financialAid" }))}
                                        labelId="creditActionChoices"
                                        formikValue={formik.values.creditActionChoices}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditActionChoices }}
                                    />
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditOverrideCodes', { ns: "financialAid" }))}
                                        labelId="creditOverrideCodes"
                                        formikValue={formik.values.creditOverrideCodes}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditOverrideCodes }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <SelectInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditActionStatuses', { ns: "financialAid" }))}
                                        labelId="creditActionStatuses"
                                        formikValue={formik.values.creditActionStatuses}
                                        formikChange={formik.handleChange}
                                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.creditActionStatuses }}
                                    />
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.reconsiderationEligible', { ns: "financialAid" }))}
                                        labelId={"reconsiderationEligible"}
                                        formikValue={formik.values.reconsiderationEligible}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.reconsiderationEligible}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.creditRequirementsMet', { ns: "financialAid" }))}
                                        labelId={"creditRequirementsMet"}
                                        formikValue={formik.values.creditRequirementsMet}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.creditRequirementsMet}
                                    />
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.endorserApproved', { ns: "financialAid" }))}
                                        labelId={"endorserApproved"}
                                        formikValue={formik.values.endorserApproved}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.endorserApproved}
                                    />
                                </FormGroup>
                                <HeaderTitle variant={'subHeading'} headerTitle={(t('commonLabel.plusApplicationInformation', { ns: "financialAid" }))} />
                                <FormGroup>
                                    <TextInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.applicationID', { ns: "financialAid" }))}
                                        labelId="applicationID"
                                        formikValue={formik.values.applicationID}
                                        formikChange={formik.handleChange}
                                        maxlength={"10"}
                                        onlyNumeric
                                    />
                                    <TextInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.previousApplicationID', { ns: "financialAid" }))}
                                        labelId="previousApplicationID"
                                        formikValue={formik.values.previousApplicationID}
                                        formikChange={formik.handleChange}
                                        maxlength={"10"}
                                        onlyNumeric
                                    />

                                </FormGroup>
                                <FormGroup>

                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.unknownLoanAmount', { ns: "financialAid" }))}
                                        labelId={"unknownLoanAmount"}
                                        formikValue={formik.values.unknownLoanAmount}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.unknownLoanAmount}
                                    />
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.sixMonthDefermentOption', { ns: "financialAid" }))}
                                        labelId={"sixMonthDefermentOption"}
                                        formikValue={formik.values.sixMonthDefermentOption}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.sixMonthDefermentOption}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.maximumLoanIndicator', { ns: "financialAid" }))}
                                        labelId={"maximumLoanIndicator"}
                                        formikValue={formik.values.maximumLoanIndicator}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.maximumLoanIndicator}
                                    />
                                    <CheckBoxInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.defermentOption', { ns: "financialAid" }))}
                                        labelId={"defermentOption"}
                                        formikValue={formik.values.defermentOption}
                                        formikChange={formik.handleChange}
                                        checked={formik.values.defermentOption}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.applicationLoanAmount', { ns: "financialAid" }))}
                                        labelId="applicationLoanAmount"
                                        formikValue={formik.values.applicationLoanAmount}
                                        formikChange={formik.handleChange}
                                        maxlength={"15"}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.applicationSubmissionReason', { ns: "financialAid" }))}
                                        labelId="applicationSubmissionReason"
                                        formikValue={formik.values.applicationSubmissionReason}
                                        formikChange={formik.handleChange}
                                        maxlength={"30"}
                                    />
                                    <DateInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.applicationCompleteDate', { ns: "financialAid" }))}
                                        labelId={"applicationCompleteDate"}
                                        defaultValue={formik.values.applicationCompleteDate}
                                        formik={formik}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <DateInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.awardRangeRequestedStartDate', { ns: "financialAid" }))}
                                        labelId={"awardRangeRequestedStartDate"}
                                        defaultValue={formik.values.awardRangeRequestedStartDate}
                                        formik={formik}
                                    />
                                    <DateInput isDoubled
                                        disabled={accessFlag}
                                        label={(t('commonLabel.awardRangeRequestedEndDate', { ns: "financialAid" }))}
                                        labelId={"awardRangeRequestedEndDate"}
                                        defaultValue={formik.values.awardRangeRequestedEndDate}
                                        formik={formik}
                                    />
                                </FormGroup>
                            </CardContent>
                            <CardContent>
                                <div style={{ display: "flex" }}>
                                    <Tooltips flag={(selectedCreditplusRow) ? true : false} row={
                                        {
                                            insertUserID: selectedCreditplusRow.insertUserID,
                                            insertTime: selectedCreditplusRow.insertTime,
                                            updateUserID: selectedCreditplusRow.updateUserID,
                                            updateTime: selectedCreditplusRow.updateTime
                                        }
                                    } />

                                    <ButtonWrapperFilter className="activityButton">
                                        <Button
                                            className="BgYellowOrange search"
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            disabled={accessFlag}
                                        >
                                            {selectedCreditplusRow == "" ? (t('buttonLabel.add', { ns: "financialAid" }))
                                                : (t('buttonLabel.update', { ns: "financialAid" }))}
                                        </Button>
                                        <Button
                                            aria-label={"Cancel"}
                                            className="BgLightBlue"
                                            variant="contained"
                                            size="large"
                                            onClick={onClose}
                                        >
                                            {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </div>
                            </CardContent>
                        </Card>
                    </form>
                </>
                : (showStudentContactLookup) && <StudentContactLookup onClose={onLookupClose} selectedSelected={selectedSelected} setBarrowerValue={setBarrowerValue} />}

            <GenericModal
                open={openAlert}
                title={t("status.borrowerMandatoryInfo", { ns: 'financialAid' })}
                onClose={setOpenAlert} />
        </>
    );
};
export default AddCreditPlusInfo; 
