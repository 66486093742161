import { ADD_PROSPECT_INFO, SELECTED_PROSPECT_NOTES } from './type'

const initialState = {
    prospect: {
        addInformation: []
    },
    prospectNotes: {
        selectedProspectNotes: ''
    }
};

const prospectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_PROSPECT_INFO:
            return {
                ...state,
                prospect: {
                    ...state.prospect,
                    addInformation: [...payload]
                }
            }
        case SELECTED_PROSPECT_NOTES:
            return {
                ...state,
                prospectNotes: {
                    ...state.prospectNotes,
                    selectedProspectNotes: payload
                }
            }
        default:
            return state;
    }
};

export default prospectReducer;