import { ADD_AWARD, DELETE_AWARD } from './type'

const initialState = {
    award: []
};

const awardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_AWARD:
            return {
                ...state,
                award: [...payload]
            }
        case DELETE_AWARD:
            return {
                ...state,
                award: [...state.award.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default awardReducer;