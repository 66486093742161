import { SEARCH_USER, SEARCH_USER_DATA, SELECTED_USER } from './types'

const initialState = {
    CAMSUser: '',
    CAMSUserData: [],
    selectedUser: ''
};

const userAdminstrationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_USER:
            return {
                ...state,
                CAMSUser: payload
            };
        case SEARCH_USER_DATA:
            return {
                ...state,
                CAMSUserData: payload
            };
        case SELECTED_USER:
            return {
                ...state,
                selectedUser: payload
            };
        default:
            return state;
    }
}
export default userAdminstrationReducer