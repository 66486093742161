import { ADD_CONTACT, SEARCH_CONTACT, ADD_STUDENT_CONTACT, SEARCH_STUDENT_CONTACT, UPDATE_SEARCH_CONTACT } from './type'

const initialState = {
    contact: [
    ],
    studentContact: [
    ],
    searchContact: [
    ],
    searchStudentContact:[
    ],
    updateSearchValue:[
    ]
};

const contactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_CONTACT:
            return {
                ...state,
                contact: [...payload]
            }
        case SEARCH_CONTACT:
            return {
                ...state,
                searchContact:  [...payload]
            }
        case ADD_STUDENT_CONTACT:
            return {
                ...state,
                studentContact: [...payload]
            }
        case SEARCH_STUDENT_CONTACT:
            return {
                ...state,
                searchStudentContact: [...payload]
            }
        case UPDATE_SEARCH_CONTACT:
                return {
                    ...state,
                    updateSearchValue: [...payload]
                }
        default:
            return state;
    }
};

export default contactReducer;