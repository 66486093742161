import { Button, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput, TextInput } from '../../../../components/Forms';
import { ButtonWrapperFilter, FormGroup, useStyles } from '../../../../styles/common-styles';
import * as yup from 'yup'
import PopupHeader from '../../../common-page/popupHeader';
import { tools_crsevaluationsetup, tools_crsevaluationsetup_Mcitems } from '../../../../services/navigation_folder/tools';
import Tooltips from '../../../../utils/Tooltip/Tooltip';
import { crs_eval_master_question_Dropdown, crs_eval_master_mcitem } from '../../../../services/apiURL';
import { postApi, putApi, getApi } from '../../../../services/apiBase';
import { toastMessage } from '../../../../utils/Message/toasts';
import DefaultTable from '../../../../utils/Table/DefaultTable';

const AddMcItems = ({ onClose, selectedRow, accessFlag}) => {
    const { t } = useTranslation(["translation", "tools"]);

    const initialValues = {
        "mcResponseID": '',
        "mcGroupID": 0,
        "comment": "",
        "mC1Resp": "",
        "mC1Weight": "",
        "mC2Resp": "",
        "mC2Weight": "",
        "mC3Resp": "",
        "mC3Weight": "",
        "mC4Resp": "",
        "mC4Weight": "",
        "mC5Resp": "",
        "mC5Weight": "",
        "mC6Resp": "",
        "mC6Weight": "",
        "mC7Resp": "",
        "mC7Weight": "",
        "mC8Resp": "",
        "mC8Weight": "",
        "mC9Resp": "",
        "mC9Weight": "",
        "mC10Resp": "",
        "mC10Weight": ""
    }
    const validationSchema = yup.object({
        mcGroupID: yup.number()
            .min(1, t('validationMessages.isRequired', { what: t('commonLabels.group', { ns: "tools" }) }))
            .required(t('validationMessages.isRequired', { what: t('commonLabels.group', { ns: "tools" }) })),
        comment: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.comment', { ns: "tools" }) })),
    })
    const [formValues, setFormValues] = useState(initialValues)
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let payloadRequest = {
                "mcResponseID": (values.mcResponseID) ? Number(values.mcResponseID) : 0,
                "mcGroupID": (values.mcGroupID) ? Number(values.mcGroupID) : 0,
                "comment": values.comment,
                "mC1Resp": values.mC1Resp,
                "mC1Weight": (values.mC1Weight) ? Number(values.mC1Weight) : 0,
                "mC2Resp": values.mC2Resp,
                "mC2Weight": (values.mC2Weight) ? Number(values.mC2Weight) : 0,
                "mC3Resp": values.mC3Resp,
                "mC3Weight": (values.mC3Weight) ? Number(values.mC3Weight) : 0,
                "mC4Resp": values.mC4Resp,
                "mC4Weight": (values.mC4Weight) ? Number(values.mC4Weight) : 0,
                "mC5Resp": values.mC5Resp,
                "mC5Weight": (values.mC5Weight) ? Number(values.mC5Weight) : 0,
                "mC6Resp": values.mC6Resp,
                "mC6Weight": (values.mC6Weight) ? Number(values.mC6Weight) : 0,
                "mC7Resp": values.mC7Resp,
                "mC7Weight": (values.mC7Weight) ? Number(values.mC7Weight) : 0,
                "mC8Resp": values.mC8Resp,
                "mC8Weight": (values.mC8Weight) ? Number(values.mC8Weight) : 0,
                "mC9Resp": values.mC9Resp,
                "mC9Weight": (values.mC9Weight) ? Number(values.mC9Weight) : 0,
                "mC10Resp": values.mC10Resp,
                "mC10Weight": (values.mC10Weight) ? Number(values.mC10Weight) : 0
            }
            if (selectedRow) {
                putApi(`${crs_eval_master_mcitem}`, payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                    onClose(false)
                    DefaultTable.updateSelectedRow('mcItems', values)
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                postApi(`${crs_eval_master_mcitem}`, payloadRequest).then((res) => {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                    onClose(false)
                    DefaultTable.resetTable('mcItems')
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
    })
    const classes = useStyles();
    React.useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    }, [])

    const [Dropdown, setDropdown] = useState({
        "multipleChoiceGroup": [],
    })
    useEffect(() => {
        getApi(crs_eval_master_question_Dropdown).then((resp) => {
            const {
                multipleChoiceGroup
            } = resp.data.data
            setDropdown({
                ...Dropdown,
                "multipleChoiceGroup": (multipleChoiceGroup) ? multipleChoiceGroup : [],
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        if (selectedRow) {
            setFormValues({
                ...selectedRow,
                "mC1Weight": (selectedRow.mC1Weight) ? Number(selectedRow.mC1Weight) : "",
                "mC2Weight": (selectedRow.mC2Weight) ? Number(selectedRow.mC2Weight) : "",
                "mC3Weight": (selectedRow.mC3Weight) ? Number(selectedRow.mC3Weight) : "",
                "mC4Weight": (selectedRow.mC4Weight) ? Number(selectedRow.mC4Weight) : "",
                "mC5Weight": (selectedRow.mC5Weight) ? Number(selectedRow.mC5Weight) : "",
                "mC6Weight": (selectedRow.mC6Weight) ? Number(selectedRow.mC6Weight) : "",
                "mC7Weight": (selectedRow.mC7Weight) ? Number(selectedRow.mC7Weight) : "",
                "mC8Weight": (selectedRow.mC8Weight) ? Number(selectedRow.mC8Weight) : "",
                "mC9Weight": (selectedRow.mC9Weight) ? Number(selectedRow.mC9Weight) : "",
                "mC10Weight": (selectedRow.mC10Weight) ? Number(selectedRow.mC10Weight) : ""
            })
        }
    }, [selectedRow])


    return (
        <>
            <PopupHeader title={(selectedRow) ? "Update Question Type" : "Add Question Type"} onClose={onClose} navigationList={tools_crsevaluationsetup_Mcitems} />

            <form onSubmit={formik.handleSubmit} className={classes.root}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <TextInput
                                isDoubled
                                disabled
                                labelId="mcResponseID"
                                label={t('commonLabels.item', { ns: "tools" })}
                                formikValue={formik.values.mcResponseID}
                                formikChange={formik.handleChange}
                            />
                            <SelectInput
                                isDoubled
                                disabled={accessFlag}
                                labelId="mcGroupID"
                                label={t('commonLabels.group', { ns: "tools" })}
                                formikValue={formik.values.mcGroupID}
                                formikChange={formik.handleChange}
                                mandatory={true}
                                errors={formik.errors.mcGroupID}
                                touched={formik.touched.mcGroupID}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.multipleChoiceGroup }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.comment", { ns: "tools" })}
                                labelId={"comment"}
                                formikValue={formik.values.comment}
                                formikChange={formik.handleChange}
                                isDoubled={true}
                                mandatory={true}
                                errors={formik.errors.comment}
                                touched={formik.touched.comment}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.response1", { ns: "tools" })}
                                labelId={"mC1Resp"}
                                formikValue={formik.values.mC1Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC1Weight"}
                                formikValue={formik.values.mC1Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                label={t("commonLabels.response6", { ns: "tools" })}
                                labelId={"mC6Resp"}
                                formikValue={formik.values.mC6Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC6Weight"}
                                formikValue={formik.values.mC6Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.response2", { ns: "tools" })}
                                labelId={"mC2Resp"}
                                formikValue={formik.values.mC2Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC2Weight"}
                                formikValue={formik.values.mC2Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                label={t("commonLabels.response7", { ns: "tools" })}
                                labelId={"mC7Resp"}
                                formikValue={formik.values.mC7Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC7Weight"}
                                formikValue={formik.values.mC7Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.response3", { ns: "tools" })}
                                labelId={"mC3Resp"}
                                formikValue={formik.values.mC3Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC3Weight"}
                                formikValue={formik.values.mC3Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                label={t("commonLabels.response8", { ns: "tools" })}
                                labelId={"mC8Resp"}
                                formikValue={formik.values.mC8Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC8Weight"}
                                formikValue={formik.values.mC8Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.response4", { ns: "tools" })}
                                labelId={"mC4Resp"}
                                formikValue={formik.values.mC4Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC4Weight"}
                                formikValue={formik.values.mC4Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                label={t("commonLabels.response9", { ns: "tools" })}
                                labelId={"mC8Resp"}
                                formikValue={formik.values.mC8Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC8Weight"}
                                formikValue={formik.values.mC8Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                label={t("commonLabels.response5", { ns: "tools" })}
                                labelId={"mC5Resp"}
                                formikValue={formik.values.mC5Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC5Weight"}
                                formikValue={formik.values.mC5Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                            <TextInput
                                label={t("commonLabels.response10", { ns: "tools" })}
                                labelId={"mC10Resp"}
                                formikValue={formik.values.mC10Resp}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <TextInput
                                label={t("commonLabels.weight", { ns: "tools" })}
                                labelId={"mC10Weight"}
                                formikValue={formik.values.mC10Weight}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                onlyNumeric
                            />
                        </FormGroup>
                        <div style={{ display: 'flex' }}>
                            <Tooltips crsEvalFlag={true} flag={selectedRow} row={(selectedRow) ? {
                                insertUserID: selectedRow.insertUserId,
                                insertTime: selectedRow.insertTime,
                                updateUserID: selectedRow.updateUserId,
                                updateTime: selectedRow.updateTime
                            } : ''} />
                            <ButtonWrapperFilter>
                                <Button
                                    className='BgYellowOrange search'
                                    variant="contained"
                                    size="large"
                                    disabled={accessFlag}
                                    type="submit"
                                >
                                    {(selectedRow) ? t('buttonLabels.update', { ns: "tools" }) : t('buttonLabels.add', { ns: "tools" })}
                                </Button>
                                <Button
                                    className="BgLightBlue search"
                                    variant="contained"
                                    size="large"
                                    type="reset"
                                    onClick={() => onClose('')}
                                >
                                    {t("buttonLabels.cancel", { ns: "tools" })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>

    )
}
export default AddMcItems