export const RESET_HOUSING_PERIOD_STATE = "RESET_HOUSING_PERIOD_STATE";
export const SET_SEARCH_HOUSING_PERIODS_RESULTS = "SET_SEARCH_HOUSING_PERIODS_RESULTS";
export const SET_HOUSING_PERIOD_BASIC_DETAILS = "SET_HOUSING_PERIOD_BASIC_DETAILS";
export const CLEAR_HOUSING_PERIOD = "CLEAR_HOUSING_PERIOD";
export const DELETE_HOUSING_PERIOD = "DELETE_HOUSING_PERIOD";
export const DELETE_HOUSING_PERIOD_SUCCESS = "DELETE_HOUSING_PERIOD_SUCCESS";
export const DELETE_HOUSING_PERIOD_ERROR = "DELETE_HOUSING_PERIOD_ERROR";
export const ENABLE_DISABLE_HOUSING_PERIOD = "ENABLE_DISABLE_HOUSING_PERIOD";
export const ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS = "ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS";
export const ENABLE_DISABLE_HOUSING_PERIOD_ERROR = "ENABLE_DISABLE_HOUSING_PERIOD_ERROR";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS = "LOAD_HOUSING_PERIOD_SELECT_ITEMS";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS = "LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR = "LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR";
export const SEARCH_HOUSING_PERIODS = "SEARCH_HOUSING_PERIODS";
export const SEARCH_HOUSING_PERIODS_SUCCESS = "SEARCH_HOUSING_PERIODS_SUCCESS";
export const SEARCH_HOUSING_PERIODS_ERROR = "SEARCH_HOUSING_PERIODS_ERROR";
export const LOAD_HOUSING_PERIOD = "LOAD_HOUSING_PERIOD";
export const LOAD_HOUSING_PERIOD_SUCCESS = "LOAD_HOUSING_PERIOD_SUCCESS";
export const LOAD_HOUSING_PERIOD_ERROR = "LOAD_HOUSING_PERIOD_ERROR";
export const INITIALIZE_NEW_HOUSING_PERIOD = "INITIALIZE_NEW_HOUSING_PERIOD";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS = "SAVE_HOUSING_PERIOD_BASIC_DETAILS";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS = "SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR = "SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR";
export const SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP = "SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES = "INITIALIZE_HOUSING_PERIOD_PROPERTIES";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS = "INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR = "INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD = "GET_PROPERTIES_FOR_HOUSING_PERIOD";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS = "GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR = "GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR";
export const LOAD_MANAGE_CHARGES_ERROR="LOAD_MANAGE_CHARGES_ERROR";

// Actions for the Manage Properties > Edit Housing Period Property page
export const LOAD_HOUSING_PERIOD_PROPERTY = "LOAD_HOUSING_PERIOD_PROPERTY";
export const LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_ERROR";
export const CLEAR_HOUSING_PERIOD_PROPERTY = "CLEAR_HOUSING_PERIOD_PROPERTY";
export const CLEAR_HOUSING_PERIOD_PROPERTY_LIST = "CLEAR_HOUSING_PERIOD_PROPERTY_LIST";
export const SAVE_HOUSING_PERIOD_PROPERTY = "SAVE_HOUSING_PERIOD_PROPERTY";
export const SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS = "SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const SAVE_HOUSING_PERIOD_PROPERTY_ERROR = "SAVE_HOUSING_PERIOD_PROPERTY_ERROR";
export const LOAD_MANAGE_CHARGES="LOAD_MANAGE_CHARGES";
export const LOAD_MANAGE_CHARGES_SUCCESS="LOAD_MANAGE_CHARGES_SUCCESS";
export const SAVE_MANAGE_CHARGES="SAVE_MANAGE_CHARGES";
export const SAVE_MANAGE_CHARGES_SUCCESS="SAVE_MANAGE_CHARGES_SUCCESS";
export const LOAD_MANAGE_CHARGES_SELECT_ITEMS="LOAD_MANAGE_CHARGES_SELECT_ITEMS";
export const LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS = "LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS";
export const LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR = "LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR";



// Actions for the Manage Properties > Applications page
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR";
export const INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION = "INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";
export const CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION = "CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION"


//Actions for Housing Period applications for processing
export const LOAD_HOUSING_PERIOD_APPLICATIONS ="LOAD_HOUSING_PERIOD_APPLICATIONS";
export const LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS = "LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS";
export const LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR = "LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR";
export const SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS = "SEARCH_HOUSING_PERIOD_APPLICATIONS_SUBMISSIONS";
export const SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS = "SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS";
export const SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR = "SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR";
export const LOAD_HOUSING_PROPERTIES_SUCCESS="LOAD_HOUSING_PROPERTIES_SUCCESS";
export const LOAD_HOUSING_PROPERTIES_ERROR="LOAD_HOUSING_PROPERTIES_ERROR";
export const LOAD_HOUSING_PROPERTIES = "LOAD_HOUSING_PROPERTIES";

export const ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";
export const ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const ADD_HOUSING_PERIOD_PROPERTY_APPLICATION = "ADD_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION = "DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";

//Actions for the Manage Properties > Room ASSIGNMENT Publish Dates
export const INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE  = "INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE = "LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS= "LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR= "LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR";
export const INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE = 'INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE = 'LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS = 'LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS';
export const LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR = 'LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR';
export const SET_ROOM_ASSIGNMENT_PUBLISH_DATE = 'SET_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE = 'SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE = 'EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const SAVE_EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE = 'SAVE_EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE';
export const DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY = 'DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY';
export const SET_HOUSING_PERIOD_BREADCRUMB = 'SET_HOUSING_PERIOD_BREADCRUMB';

//Actions for the Manage Properties > Room Assigment Publish Dates
export const INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE  = "INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE = "LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_SUCCESS= "LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_SUCCESS";
export const LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_ERROR= "LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_ERROR";
export const SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR = "SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR";