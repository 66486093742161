import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import BrandingColors from '../../styles/color';
import DefaultTable from '../../utils/Table/DefaultTable';
import { useStyles } from '../../styles/common-styles'
import { useTranslation } from 'react-i18next';
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const StyledTitle = styled(BootstrapDialogTitle)`
    background-color : ${BrandingColors.white};
    color: ${BrandingColors.darkBlack};
    border-bottom: 2px solid ${BrandingColors.fontOrangeCustom};
    display: flex;
    padding: 16px 16px 10px 16px !important;
    button{
        color: ${BrandingColors.darkBlack};
        padding: 0;
        border-radius: 0;
        height:24px;
    }
    span{
        flex:1 1 0;
        line-height: 2;
    }
`
const DialogWrapper = styled.div`
    min-width: 825px;
`
const AlertsModal = (props) => {
    const { onClose, popupData, children } = props;
    const { popupHeaderTitle, alertsData, open, tableData, studentId, studentName } = popupData;
    const classess = useStyles();
    const { t } = useTranslation();
    return (
        <div>
            <Dialog
                onClose={onClose}
                maxWidth={'lg'}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classess.root}
            >
                <StyledTitle id="customized-dialog-title" onClose={onClose}>
                    <span>{popupHeaderTitle}</span>
                </StyledTitle>

                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-slide-description">
                        {studentName && <span className="studentName"> {studentName} ID: {studentId}</span>}
                    </DialogContentText>
                    <DialogWrapper>{

                        tableData.map((value, key) => {
                            return (
                                <>
                                    <Typography className="headerTitle">{value.tableName}</Typography>
                                    <DefaultTable trackingLabel={"AlertModal"} isLookup={true} headCells={value.headcells} rows={alertsData[value.data]} pagination={true} />
                                    <br />
                                </>
                            )
                        })

                    }
                        {children}
                    </DialogWrapper>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default React.memo(AlertsModal);
