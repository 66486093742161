import { GET_TRANSFER_TAB } from './types'
import { GET_NARRATIVE_TAB } from './types'

const initialState = {
    transferTab: {
        "srAcademicID": 0,
        "studentUID": 0,
        "lastName": "",
        "firstName": "",
        "termSeq": "",
        "term": "",
        "department": "",
        "course": "",
        "courseType": "",
        "section": "",
        "courseName": "",
        "collegeName": "",
        "credits": 0,
        "grade": "",
        "lastUpdate": "2022-05-20T09:27:43.408Z",
        "effectsTermGPA": "",
        "effectsCumGPA": "",
        "category": "",
        "matchDept": "",
        "matchCourse": "",
        "matchCourseType": "",
        "matchCourseName": "",
        "gradePoint": 0,
        "creditFactor": 0,
        "termEarned": 0,
        "cumulativeEarned": 0,
        "termGPAHours": 0,
        "cumulativeGPAHours": 0,
        "mGrade": "",
        "mPoint": 0,
        "showGradeReport": "",
        "showTranscript": "",
        "overallGradePoint": 0,
        "overallCreditFctr": 0,
        "showAdvisorTrans": "",
        "gpaGrouping": "",
        "registrationStatus": "",
        "clockHours": 0,
        "startDate": "2022-05-20T09:27:43.408Z",
        "completionDate": "2022-05-20T09:27:43.408Z",
        "previousGrade": "",
        "startInputDate": "2022-05-20T09:27:43.408Z",
        "completionInputDate": "2022-05-20T09:27:43.408Z",
        "ignoreRepeats": "",
        "repeat": "",
        "transcriptAttHrs": 0,
        "transcriptErnHrs": "",
        "termCalendarID": 0,
        // "showAdvisorTranscript": 0,
        "srOfferID": 0,
        "collegeID": 0,
        "categoryID": 0,
        "matchSRMasterID": 0,
        "gpaGroupID": 0,
        "facultyID": 0,
        "gradeCatalogID": 0,
        "includeInCollegeLevel": "",
        "includeInAudit": "",
        "insertUserID": "",
        "insertTime": "2022-05-20T09:27:43.408Z",
        "updateUserID": "",
        "updateTime": "2022-05-20T09:27:43.408Z",
        "includeHopeGPA": true,
        "writingIntensive": true,
        "honors": true,
        "hopeTermGPAHrs": 0,
        "hopeCumGPAHrs": 0,
        "hopeTermGP": 0,
        "hopeCumGP": 0,
        "incHopeTermGPA": true,
        "incHopeCumGPA": true,
        "cipCode": "",
        "numberGradeFinal": 0,
        "numberGradeMidTerm": 0,
        "sapTotalHours": true,
        "excludeFinAid": false,
        "withdrawReason": "",
        "excludeWIthdrawReason": "",
        "withdrawReasonID": 0,
        "excludeWithdrawReasonID": 0,
        "locationName": "",
        "locationID": 0
    }, 
    narrativeTab: {
        "studentUID": 0,
        "studentID": "",
        "srAcademicID": 0,
        "department": "",
        "courseID": "",
        "courseType": "",
        "section": "",
        "narrative": "",
        "startInputDate": "2022-05-24T06:38:57.349Z",
        "completionInputDate": "2022-05-24T06:38:57.349Z",
        "workLoadStatus": "",
        "lastDayOfAttendance": "2022-05-24T06:38:57.349Z",
        "termCalendarID": 0,
        "srOfferID": 0,
        "gradeCatalogID": 0,
        "accessCampusID": 0
    }
};

const transferReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_TRANSFER_TAB:
            return {
                ...state,
                transferTab: { ...initialState.transferTab, ...payload }
            };
        case GET_NARRATIVE_TAB:
            return {
                ...state,
                studentSelection: { ...initialState.narrativeTab, ...payload }
            };
        default:
            return state;
    }
};

export default transferReducer;