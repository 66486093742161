import React, { useState, useEffect } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { FormGroup, useStyles} from "../../../../styles/common-styles";
import { financialAid_maintenance } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../../pages/common-page/popupHeader";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { getApi} from '../../../../services/apiBase';
import { student_contact_url } from '../../../../services/apiURL';



const StudentContactLookup = ({ addDatas, onClose, getSelectedRow, selectedSelected, setBarrowerValue }) => {
  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);
  const classess = useStyles();
  const initialValues = {
    contactsAssociatedWithStudent: ""
  };

  const [contactLookup] = useState([
    { id: 'contactId', numeric: true, disablePadding: false, label: t('contactLookup.contactId', { ns: 'financialAidHeadcells' }) },
    { id: 'contactType', numeric: true, disablePadding: false, label: t('contactLookup.contactType', { ns: 'financialAidHeadcells' }) },
    { id: 'contactTypeId', numeric: true, disablePadding: false, label: t('contactLookup.contactTypeID', { ns: 'financialAidHeadcells' }) },
    { id: 'institution', numeric: true, disablePadding: false, label: t('contactLookup.institution', { ns: 'financialAidHeadcells' }) },
    { id: 'salutation', numeric: true, disablePadding: false, label: t('contactLookup.salutation', { ns: 'financialAidHeadcells' }) },
    { id: 'title', numeric: true, disablePadding: false, label: t('contactLookup.title', { ns: 'financialAidHeadcells' }) },
    { id: 'contactLast', numeric: true, disablePadding: false, label: t('contactLookup.contactLast', { ns: 'financialAidHeadcells' }) },
    { id: 'contactFirst', numeric: true, disablePadding: false, label: t('contactLookup.contactFirst', { ns: 'financialAidHeadcells' }) },
    { id: 'contactMiddle', numeric: true, disablePadding: false, label: t('contactLookup.contactMiddle', { ns: 'financialAidHeadcells' }) },
    { id: 'suffix', numeric: true, disablePadding: false, label: t('contactLookup.suffix', { ns: 'financialAidHeadcells' }) },
    { id: 'address1', numeric: true, disablePadding: false, label: t('contactLookup.address1', { ns: 'financialAidHeadcells' }) },
    { id: 'address2', numeric: true, disablePadding: false, label: t('contactLookup.address2', { ns: 'financialAidHeadcells' }) },
    { id: 'address3', numeric: true, disablePadding: false, label: t('contactLookup.address3', { ns: 'financialAidHeadcells' }) },
    { id: 'town', numeric: true, disablePadding: false, label: t('contactLookup.town', { ns: 'financialAidHeadcells' }) },
    { id: 'city', numeric: true, disablePadding: false, label: t('contactLookup.city', { ns: 'financialAidHeadcells' }) },
    { id: 'county', numeric: true, disablePadding: false, label: t('contactLookup.county', { ns: 'financialAidHeadcells' }) },
    { id: 'state', numeric: true, disablePadding: false, label: t('contactLookup.state', { ns: 'financialAidHeadcells' }) },
    { id: 'stateId', numeric: true, disablePadding: false, label: t('contactLookup.stateID', { ns: 'financialAidHeadcells' }) },
    { id: 'zipCode', numeric: true, disablePadding: false, label: t('contactLookup.zipCode', { ns: 'financialAidHeadcells' }) },
    { id: 'country', numeric: true, disablePadding: false, label: t('contactLookup.country', { ns: 'financialAidHeadcells' }) },
    { id: 'countryId', numeric: true, disablePadding: false, label: t('contactLookup.countryId', { ns: 'financialAidHeadcells' }) },
    { id: 'phone1', numeric: true, disablePadding: false, label: t('contactLookup.phone1', { ns: 'financialAidHeadcells' }) },
    { id: 'phone2', numeric: true, disablePadding: false, label: t('contactLookup.phone2', { ns: 'financialAidHeadcells' }) },
    { id: 'fax1', numeric: true, disablePadding: false, label: t('contactLookup.fax1', { ns: 'financialAidHeadcells' }) },
    { id: 'email1', numeric: true, disablePadding: false, label: t('contactLookup.email1', { ns: 'financialAidHeadcells' }) },
    { id: 'email2', numeric: true, disablePadding: false, label: t('contactLookup.email2', { ns: 'financialAidHeadcells' }) },
    { id: 'email3', numeric: true, disablePadding: false, label: t('contactLookup.email3', { ns: 'financialAidHeadcells' }) },
    { id: 'taxIdnumber', numeric: true, disablePadding: false, label: t('contactLookup.taxIDNumber', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserId', numeric: true, disablePadding: false, label: t('contactLookup.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('contactLookup.insertTime', { ns: 'financialAidHeadcells' }) },
    { id: 'updateUserId', numeric: true, disablePadding: false, label: t('contactLookup.updateUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('contactLookup.updateTime', { ns: 'financialAidHeadcells' }) },
    { id: 'mobilePhone', numeric: true, disablePadding: false, label: t('contactLookup.mobilePhone', { ns: 'financialAidHeadcells' }) },
    { id: 'birthDate', numeric: true, disablePadding: false, label: t('contactLookup.birthDate', { ns: 'financialAidHeadcells' }) },
    { id: 'countryOfCitizenshipId', numeric: true, disablePadding: false, label: t('contactLookup.countryOfCitizenshipID', { ns: 'financialAidHeadcells' }) },
    { id: 'countryOfCitizenship', numeric: true, disablePadding: false, label: t('contactLookup.countryOfCitizenship', { ns: 'financialAidHeadcells' }) },
    { id: 'internationalFlag', numeric: true, disablePadding: false, label: t('contactLookup.internationalFlag', { ns: 'financialAidHeadcells' }) },
    { id: 'campus', numeric: true, disablePadding: false, label: t('contactLookup.campus', { ns: 'financialAidHeadcells' }) },
    { id: 'campusId', numeric: true, disablePadding: false, label: t('contactLookup.campusID', { ns: 'financialAidHeadcells' }) },

  ])

  const [contactRow, setContactRow] = useState([])
  const [isLoading, setIsloading] = useState(false);
  const [borrower, setBorrower] = useState([])
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => { },
  });

  const handleRowSelect = (value) => {
    setBorrower(value)
    setBarrowerValue(value);
    onClose(false)
  }

  useEffect(() => {
    if (selectedSelected.studentUID) {
      setIsloading(true)
      getApi(`${student_contact_url}/${selectedSelected.studentUID}`).then((res) => {
        setContactRow(res.data.data)
        setIsloading(false)
      }).catch((err) => console.log(err))
    }
  }, [])

  return (
    <>
      <PopupHeader title={(t('commonLabel.contactsAssociatedWithStudent', { ns: "financialAid" }))} navigationList={financialAid_maintenance} onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <FormGroup>
              <DefaultTable
                trackingLabel={"batchesBilling"}
                headCells={contactLookup}
                rows={contactRow}
                isLoading={isLoading}
                pagination={true}
                onRowSelect={handleRowSelect}
                onDoubleClick={() => {
                  setBarrowerValue(borrower);
                  onClose(false)
                }}
                isLookup={true}
              />
            </FormGroup>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default StudentContactLookup;


