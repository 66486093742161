import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import styled from 'styled-components';
import BrandingColors from '../../styles/color';
import { useDispatch } from 'react-redux';
import { getSelectedProfiles } from '../../redux/common/search-data/action';
import { useHistory } from 'react-router-dom';
import { BreadcrumbName } from '../../styles/common-styles';

const Anchor = styled.div`
    a,p{
      color: ${BrandingColors.btnTxtBlue};
      text-decoration: none;
      font-size: 0.75rem;
      cursor: pointer;
      font-family: 'Lato-regular', sans-serif;
      @media (min-width: 1920px) {
        font-size: 16px;
        line-height: 1;
      }
    }
    p{
      color: ${BrandingColors.darkGreyFont};
    }
`;

export const Breadcrumb = ({ children, profileData, profileName, termId, selectedBatch, selectedLedger }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ flexGrow: 1, color: `${BrandingColors.darkGreyFont}` }}>
        {children}
      </Breadcrumbs>
      {
        ((window.location.hash === "#/admissions/students/add-students")
          || (window.location.hash === "#/admissions/prospects/add-prospects")
          || (window.location.hash === '#/admissions/students/document-tracking')
          || (window.location.hash === '#/admissions/students/student-sevis')
          || (window.location.hash === '#/admissions/prospects/prospect-international') ||
          (window.location.hash === '#/admissions/students/student-access') ||
          (window.location.hash === '#/admissions/prospects/prospect-citizenship') ||
          (window.location.hash === '#/admissions/students/laptop-tracking') ||
          (window.location.hash === '#/admissions/students/student-risk') ||
          (window.location.hash === '#/admissions/students/evaluations/applicant') ||
          (window.location.hash === '#/registration/academic/transfer') ||
          (window.location.hash === '#/registration/academic/register/register-parameters')) ||
          (window.location.hash === '#/registration/academic/unofficial/parameters') ||
          (window.location.hash === '#/registration/academic/transcript/maintenance') ||
          (window.location.hash === '#/registration/academic/degrees') ||
          (window.location.hash === '#/registration/degree-audit/student-evaluation') ||
          (window.location.hash === '#/financial-aid/student-notes') ||
          (window.location.hash === '#/financial-aid/holds') ||
          (window.location.hash === '#/registration/degree-audit/student-evaluation') ||
          (window.location.hash === '#/billing/change-address') ||
          (window.location.hash === '#/billing/maintenance') ||
          (window.location.hash === '#/billing/cashier-entry') ||
          (window.location.hash === '#/financial-aid/student-status')||
          (window.location.hash === '#/billing/payment-plans/maintenance') ||
          (window.location.hash === '#/financial-aid/maintenance') ||
          (window.location.hash === '#/tools/export/registration/speede-college-transcript')||
          (window.location.hash === '#/admissions/students/student-status')||
          (window.location.hash === '#/registration/academic/student-status')||
          (window.location.hash === '#/admission/students/change-address')||
          (window.location.hash === '#/registration/academic/change-address') ||
          (window.location.hash === '#/system-manager/faculty/add-faculty')||
          (window.location.hash === '#/financial-aid/document-tracking')||
          (window.location.hash === '#/registration/academic/document-tracking')||
          (window.location.hash === '#/system-manager/faculty/add-faculty') ||
          (window.location.hash === '#/registration/academic/student-notes') ||
          (window.location.hash === '#/admissions/students/student-notes') ||
          (window.location.hash === '#/system-manager/faculty/add-faculty')||
          (window.location.hash === '#/admissions/prospects/prospect-notes')||
          (window.location.hash === '#/admissions/prospects/document-tracking')||
          (window.location.hash === '#/billing/document-tracking')||
          (window.location.hash === '#/billing/holds') ||
          (window.location.hash === '#/registration/academic/holds')
          // (window.location.hash === '#/billing/billing-accounting/post-options/accounts-payable')

          ?
          (profileData && profileData.profileName && profileData.profileId) && !profileName &&
          <BreadcrumbName >{`${profileData?.profileName} ID: ${profileData?.profileId}`}</BreadcrumbName>
          :
          ''
      }
      {
        profileName &&
        <BreadcrumbName >{`${profileName}`}</BreadcrumbName>
      }
      {
        termId &&
        <BreadcrumbName >&nbsp;{`Term: ${termId}`}</BreadcrumbName>
      }
      {
        selectedBatch?.batchName &&
        <BreadcrumbName >&nbsp;{`${selectedBatch?.batchName}: ${selectedBatch?.comment}`}</BreadcrumbName>
      }

      {/* {
        selectedLedger?.ledgerBatchName &&
        <span >&nbsp;{`${selectedLedger?.ledgerBatchName}`}</span>
      } */}

    </div>
  );
}

export const BreadcrumbLink = ({ name, isLink, path, onHyperAction }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onInnerHyperActions = (e) => {
    e.preventDefault();
    if (e.target.innerText === "Prospects") {
      dispatch(getSelectedProfiles({}))
      history.push(path)
    }
    if (e.target.innerText === "Search Student") {
      dispatch(getSelectedProfiles({}))
      history.push(path)
    }
    if (name !== 'Admissions' && name !== 'Prospects' && name !== 'Students') {
      onHyperAction && onHyperAction();
    }
  }

  return (
    <>
      <Anchor>
        {(isLink) ?
          <a onClick={(e) => onInnerHyperActions(e)}>{name}</a>
          :
          <Typography style={{ height: '16px', cursor: 'default' }}>{name}</Typography>
        }
      </Anchor>

    </>
  )
}
