import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput,  TextLookUpInput, } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles } from "../../../../styles/common-styles";
import { financialAid_borroweraward } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../../pages/common-page/popupHeader";
import { getApi } from '../../../../services/apiBase';
import { borrower_select_item } from "../../../../services/apiURL";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import StudentContactLookup from "./studentContactLookup";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { toastMessage } from '../../../../utils/Message/toasts';


const AddBorrowerDetails = ({ onClose, selectedSelected, selectBorrower, setBorrowerRow, BorrowerRow, borrowIndex,selectedStatusRow, accessFlag }) => {
  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);

  const classess = useStyles();

  const initialValues = {
    "awardTypes": '',
    "borrowerforStudent": null,
    "awardTypeID": 0,
    "borrowerID": 0,
    "financialStatusID": selectedStatusRow.financialStatusID,
    "studentUID": selectedSelected.studentUID,
    "financialBorrowerID": 0,
    "insertUSerID": "",
    "insertTime": null,
    "updateUserID": "",
    "updateTime": null
  };


  const [showAddData, setShowAddData] = useState(false);
  const [formValues, setFormValues] = useState(initialValues)
  const [Dropdown, setDropdown] = useState({
    "awardTypes": []
  })

  const validationSchema = yup.object({
    awardTypes: yup
      .string()
      .required(t('validationMessages.isRequired', { what: t('commonLabel.awardTypes', { ns: "financialAid" }) })),
    borrowerforStudent: yup
    .string()
    .ensure() // Transforms undefined and null values to an empty string.
    .test('Only Empty?', t('validationMessages.isRequired', { what: t('commonLabel.borrowerforStudent', { ns: "financialAid" }) }), (value) => {
      const isValid = value.split(' ').join('').length !== 0;
      return isValid;
    })
  });

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.borrowerforStudent == "") {
        // setOpenAlert(true);
      }
      else {
        let groupName = [];
        if (Dropdown.awardTypes) {
          const { awardTypes } = Dropdown
          groupName = (awardTypes).filter(v => Number(values.awardTypes) === Number(v.uniqueId))
        }
        let govtGroupArray = {
          "awardType": (groupName.length > 0) ? groupName[0].displayText : '',
          "borrower": values.borrowerforStudent,
          "awardTypeID": values.awardTypes,
          "borrowerID": values.borrowerID || 0,
          "financialStatusID": values.financialStatusID || 0,
          "studentUID": values.studentUID || 0,
          "financialBorrowerID": values.financialBorrowerID || 0,
          "insertUSerID": values.insertUSerID,
          "insertTime": values.insertTime,
          "updateUserID": values.updateUserID,
          "updateTime": values.updateTime
        }
        if (!selectBorrower) {
          setBorrowerRow([...BorrowerRow, govtGroupArray])
          toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));

        } else {
          let govtArr = BorrowerRow.map((v, i) => {
            let editedValue = { ...v };
            if (i === Number(borrowIndex)) {
              editedValue = govtGroupArray
            }
            return editedValue;
          })
          toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
          DefaultTable.resetTable('BorrowerTable')
          setBorrowerRow([...govtArr])
        }
        onClose(false)
      }
    },
  });

  // const BorrowerFunc = (values) => {
  //   let BorrowerArray = [];
  //   let scheduleObj = {};
  //   scheduleObj = {
  //     "awardType": awardName,
  //     "borrower": values.borrowerforStudent,
  //     "awardTypeID": values.awardTypes,
  //     "borrowerID": barrowerData.contactId,
  //     "financialStatusID": 0,
  //     "studentUID": selectedSelected.studentUID,
  //     "financialBorrowerID": 0,
  //     "insertUSerID": "",
  //     "insertTime": "",
  //     "updateUserID": "",
  //     "updateTime": ""
  //   }
  //   BorrowerArray.push(scheduleObj)
  //   setBorrowerRow(BorrowerArray)
  //   onClose(false);
  // }

  const handleStudentlookUp = () => {
    setShowAddData(true);
  };

  const handleLookupClose = (value) => {
    setShowAddData(false);
  };

  const setBarrowerValue = (value) => {
    formik.setFieldValue("borrowerID",value.contactId)
    formik.setFieldValue("borrowerforStudent", `${(value.contactLast === "") ? "" : (value.contactLast + ',')} ${value.contactFirst ? value.contactFirst : ""}`)
    // formik.setFieldValue("borrowerforStudent", `${value ? value.contactLast : ""} ${value ? ',' : ''} ${value ? value.contactFirst : ""}`)
  }
  useEffect(() => {
    if (selectBorrower !== '') {
      setFormValues({
        ...formValues,
        awardTypes: selectBorrower.awardTypeID,
        borrowerforStudent: selectBorrower.borrower,
        financialStatusID: selectBorrower.financialStatusID,
        financialBorrowerID: selectBorrower.financialBorrowerID,
        borrowerID: selectBorrower.borrowerID,
        insertUSerID: selectBorrower.insertUSerID,
        insertTime: selectBorrower.insertTime,
        updateUserID: selectBorrower.updateUserID,
        updateTime: selectBorrower.updateTime,
        studentUID: selectBorrower.studentUID
      })
    }
  }, [])

  useEffect(() => {
    if (selectedSelected.studentUID) {
      getApi(`${borrower_select_item}/${selectedSelected.studentUID}`).then((resp) => {
        const { awardTypes } = resp.data.data;
        setDropdown({
          ...Dropdown,
          "awardTypes": awardTypes
        })
      }).catch((err) => {
        console.log(err);
      })
    }

  }, [])

  useEffect(() => {
    if (Dropdown.awardTypes.length > 0) {
      let AwardTypeName = Dropdown.awardTypes.filter(v =>
        Number(v.uniqueId) === formik.values.awardTypes
      )
      if (AwardTypeName.length > 0) {
        // setAwardName(AwardTypeName[0].displayText)
      }
    }
  }, [formik.values.awardTypes, Dropdown])


  return (
    <>
      {!showAddData ? (
        <>
          <PopupHeader
            title={
              selectBorrower !== ""
                ? t("commonLabel.updateBorrowerPerAwardType", {
                  ns: "financialAid",
                })
                : t("commonLabel.addBorrowerPerAwardType", {
                  ns: "financialAid",
                })
            }
            navigationList={financialAid_borroweraward}
            onClose={onClose}

          />
          <form className={classess.root} onSubmit={formik.handleSubmit}>
            <Card>
              <CardContent>
                <FormGroup>
                  <SelectInput
                    mandatory={true}
                    disabled={accessFlag}
                    label={t("commonLabel.awardTypes", { ns: "financialAid" })}
                    labelId={"awardTypes"}
                    formikValue={formik.values.awardTypes}
                    formikChange={formik.handleChange}
                    errors={formik.errors.awardTypes}
                    touched={formik.touched.awardTypes}
                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.awardTypes }} />
                  <TextLookUpInput
                    label={t("commonLabel.borrowerforStudent", {
                      ns: "financialAid",
                    })}
                    labelId="borrowerforStudent"
                    formikValue={formik.values.borrowerforStudent}
                    formikChange={formik.handleChange}
                    handlelookUp={handleStudentlookUp}
                    mandatory={true}
                    errors={formik.errors.borrowerforStudent}
                    touched={formik.touched.borrowerforStudent}
                    disabled={true}
                  />
                </FormGroup>
              </CardContent>
              <CardContent>
                <div style={{ display: "flex" }}>
                  <Tooltips flag={(selectBorrower) ? true : false} row={
                    {
                      insertUserID: selectBorrower.insertUSerID,
                      insertTime: selectBorrower.insertTime,
                      updateUserID: selectBorrower.updateUserID,
                      updateTime: selectBorrower.updateTime
                    }
                  } />

                  <ButtonWrapperFilter className="activityButton">
                    <Button
                      className="BgYellowOrange search"
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={accessFlag}
                    >
                      {selectBorrower == ""
                        ? t("buttonLabel.add", { ns: "financialAid" })
                        : t("buttonLabel.update", { ns: "financialAid" })}
                    </Button>
                    <Button
                      aria-label={"Cancel"}
                      className="BgLightBlue"
                      variant="contained"
                      size="large"
                      onClick={onClose}
                    >
                      {t("buttonLabel.cancel", { ns: "financialAid" })}
                    </Button>
                  </ButtonWrapperFilter>
                </div>
              </CardContent>
            </Card>
          </form>
        </>
      ) : (
        <StudentContactLookup
          onClose={handleLookupClose}
          // onClose={onClose}
          setBarrowerValue={setBarrowerValue}
          selectedSelected={selectedSelected}
        />
      )
      }
    </>
  );
};

export default AddBorrowerDetails;
