import {
    GET_ACADYEAR,
    GET_ACADYEAR_LIMIT,
    GET_PKG_TERM,
    GET_PKG_LIMIT,
    GET_SELECTED_ACADYEAR,
    GET_ACADEMIC_YEAR_ID,
    GET_FINANCIAL_AID_YEAR_LIMIT,
    GET_OVER_TERM,
    GET_TERM_LIMIT,
    GET_SELECTED_FA_YEAR,
    GET_BUDGET,
    GET_ISIRCC
} from './types';


const initialState = {
    academicYears: {
        acadYear: [],
        acadYearLimit: [],
        selectedAcadYear: ''
    },
    financialAidYears: {
        AcademicYearID: [],
        financialAidYearLimits: [],
        selectedFinancialYear: ''
    },
    packageTerms: {
        pkgTerm: [],
        pkgLimit: []
    },
    term: {
        overTerm: [],
        termLimit:[]

    },
    budgets: {
        budgetId:[]

    },
    ISIRCC: {
        isirccData:[]
    }
}

const yearlySetupReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_ACADYEAR:
            return {
                ...state,
                academicYears: {
                    ...state.academicYears, acadYear: payload
                }
            }
        case GET_ACADYEAR_LIMIT:
            return {
                ...state,
                academicYears: {
                    ...state.academicYears, acadYearLimit: payload
                }
            }
        case GET_SELECTED_ACADYEAR:
            return {
                ...state,
                academicYears: {
                    ...state.academicYears, selectedAcadYear: payload
                }
            }
        case GET_SELECTED_FA_YEAR:
            return {
                ...state,
                financialAidYears: {
                    ...state.financialAidYears, selectedFinancialYear: payload
                }
            }

        case GET_ACADEMIC_YEAR_ID:
            return {
                ...state,
                financialAidYears: {
                    ...state.financialAidYears, AcademicYearID: payload
                }
            }
        case GET_FINANCIAL_AID_YEAR_LIMIT:
            return {
                ...state,
                financialAidYears: {
                    ...state.financialAidYears, financialAidYearLimits: payload
                }
            }

        case GET_PKG_TERM:
            return {
                ...state,
                packageTerms: {
                    ...state.packageTerms, pkgTerm: payload
                }
            }
        case GET_PKG_LIMIT:
            return {
                ...state,
                packageTerms: {
                    ...state.packageTerms, pkgLimit: payload
                }
            }
            case GET_OVER_TERM:
                return {
                    ...state,
                    term: {
                        ...state.term, overTerm: payload
                    }
                }
                case GET_TERM_LIMIT:
                    return {
                        ...state,
                        term: {
                            ...state.term, termLimit: payload
                        }
                    }
                    case GET_BUDGET:
                        return {
                            ...state,
                            term: {
                                ...state.budgets, budgetId: payload
                            }
                        }
                     case GET_ISIRCC:
                        return {
                            ...state,
                            ISIRCC: {
                                ...state.ISIRCC, isirccData: payload
                            }
                        }
                 
        default:
            return state;
    }

}

export default yearlySetupReducer;