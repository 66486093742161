import {
    GET_OUTPUT_TYPE,
    UPDATE_OUTPUT_TYPE,
    GENERAL_TABLE,
    GET_GENERAL_LEDGER_POST,
    GET_GENERAL_LEDGER_CHECK_POST,
    GET_LOAD_PARAMS,
    GET_POST_TRANSCATION,
    GET_ACCOUNT_PAYABLE__OPTIONS,
    GET_ACCOUNT_PAYABLE__POSTINFO,
    GET_LOAD_PARAMS_ACCOUNT_PAYABLE_POST,
    GET_RECREATE_ACCOUNT_PAYABLE_HISTORY,
    GET_LOAD_PARAMS_PAYABLE

} from './types';
const intialState = {
    generalLedger: {
        glOutputType: null
    },
    options: {
        isSuccess: false
    },
    post: [{}],
    recreate: {
        glOutputType: null,
        isOutputTypeUpdated: false,
        postTranscation: [],
    },
    generalLedgerTable: [],
    loadParameter: {
    },
    accountPayablePostLoadParameter: {
    },
    accountPayable: {
        options: {},
        postInfo: {}
    },
    recreateAccountPayable: {
        history: [],
       
    },
    accountPayableLoadParameter:{}

}

const accountingReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_OUTPUT_TYPE:
            return { ...state, recreate: payload }
        case UPDATE_OUTPUT_TYPE:
            return {
                ...state,
                recreate: {
                    ...state.recreate, glOutputType: payload.ledgerBatchName, isOutputTypeUpdated: true
                }
            }
        case GET_GENERAL_LEDGER_POST:
            return {
                ...state, post: payload
            }

        case GET_POST_TRANSCATION: {
            return {
                ...state,
                recreate: {
                    ...state.recreate, postTranscation: payload
                }
            }
        }
      
        case GET_ACCOUNT_PAYABLE__OPTIONS: {
            return {
                ...state,
                accountPayable: {
                    ...state.accountPayable, options: payload
                }
            }

        }
        case GET_ACCOUNT_PAYABLE__POSTINFO: {
            return {
                ...state,
                accountPayable: {
                    ...state.accountPayable, postInfo: payload
                }
            }
        }

        case GET_GENERAL_LEDGER_CHECK_POST: {
            return {
                ...state, post: payload.isSuccess
            }
        }

        case GET_LOAD_PARAMS: {
            return {
                ...state,
                loadParameter: { ...payload }
            };
        }

        case GET_LOAD_PARAMS_ACCOUNT_PAYABLE_POST: {
            return {
                ...state,
                accountPayablePostLoadParameter: { ...payload }
            };
        }
        case GET_RECREATE_ACCOUNT_PAYABLE_HISTORY: {
            return {
                ...state,
                recreateAccountPayable: {
                    ...state.recreateAccountPayable, history: payload
                }
            };
        }
        case GENERAL_TABLE:
            return {
                ...state,
                generalLedgerTable: [...payload]
            };
            case GET_LOAD_PARAMS_PAYABLE: {
                return {
                    ...state,
                    accountPayableLoadParameter: { ...payload }
                };
            }
        default:
            return state;
    }
}

export default accountingReducer;

