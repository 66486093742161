import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbLink } from '../../../../utils/Breadcrumbs/Breadcrumbs';
import HeaderTitle from '../../../../utils/HeaderTitle/HeaderTitle';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import { Card, CardContent, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput, TextInput } from '../../../../components/Forms';
import { FormGroup, ButtonWrapper, useStyles, ButtonWrapperFilter } from '../../../../styles/common-styles';
import AddDocTracking from './AddDocTracking';
import LoadDocSequence from './LoadDocSequence';
import LoadColleges from './LoadColleges';
import DocumentUploadPopup from './documentUpload';
import { useTranslation } from 'react-i18next';
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import { getApi, deleteApi } from '../../../../services/apiBase'
import { document_tracking, student_doc_dropdown, student_college_url, prospect_document_tracking, college_url } from '../../../../services/apiURL';
import GenericModal from '../../../../components/Modal/GenericModal';
import { toastMessage } from '../../../../utils/Message/toasts';
import { admissions_DocumentTracking, Admissions_DocumentTracking, student_studentDocumentTracking } from '../../../../services/navigation_folder/admissions'
import { updateAccessSelector } from '../../../../redux/common/roleAccess/selectors';
import { documentTracking } from '../../../../helper/constants';
const DocumentTracking = ({ selectedRow, SetSelectedSelected }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showAddData, setShowAddData] = useState(true);
    const [showLoadColleges, setShowColleges] = useState(false);
    const [showLoadSequence, setLoadSequence] = useState(false);
    const [showAddDoc, setAddDoc] = useState(false);
    const [tableRow, setTableRow] = useState([]);
    const [collegeFlag, setCollegeFlag] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [gpaGrp, setGpaGrp] = useState('');
    const [selectedDoc, setSelectedDoc] = useState({});
    const [admurl, setAdmurl] = useState(false)
    const [regurl, setRegurl] = useState(false)
    const [finurl, setFinUrl] = useState(false)

    const [popupDatas, setPopupDatas] = useState({
        popupHeaderTitle: '',
        popupTemplate: '',
        openPopup: false,
        selectedTableRow: []
    });
    const [Dropdown, setDropdown] = useState({
        "documentGroup": [],
        "documentNames": [],
        "status": [],
        "admissionGPAGroup": [],
        "userDefinedFields": []
    })
    const [module, setModule] = useState({
        moduleName: "", moduleId: ""
    })
    const { t } = useTranslation(['translation', 'tableHeadCells']);
    const classes = useStyles();
    const headCells = [
        { id: 'location', numeric: true, disablePadding: false, label: t('students.location', { ns: 'tableHeadCells' }) },
        { id: 'docDate', numeric: true, disablePadding: false, label: t('students.docDate', { ns: 'tableHeadCells' }), date: true },
        { id: 'docName', numeric: true, disablePadding: false, label: t('students.docName', { ns: 'tableHeadCells' }) },
        { id: 'docDescription', numeric: true, disablePadding: false, label: t('students.docDescription', { ns: 'tableHeadCells' }) },
        { id: 'docStatus', numeric: true, disablePadding: false, label: t('students.docStatus', { ns: 'tableHeadCells' }) },
        { id: 'comments', numeric: true, disablePadding: false, label: t('students.comments', { ns: 'tableHeadCells' }) },
        { id: 'reference', numeric: true, disablePadding: false, label: t('students.reference', { ns: 'tableHeadCells' }) },
        { id: 'compDate', numeric: true, disablePadding: false, label: t('students.compDate', { ns: 'tableHeadCells' }), date: true },
        { id: 'documentImage', numeric: true, disablePadding: false, label: t('students.documentImage', { ns: 'tableHeadCells' }) },
        { id: 'userDefinedField', numeric: true, disablePadding: false, label: t('students.userDefinedField', { ns: 'tableHeadCells' }) },
        { id: 'finAidYearSeq', numeric: true, disablePadding: false, label: t('students.finAidYearSeq', { ns: 'tableHeadCells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('students.insertUserID', { ns: 'tableHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('students.insertTime', { ns: 'tableHeadCells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('students.updateUserID', { ns: 'tableHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('students.updateTime', { ns: 'tableHeadCells' }) },
        { id: 'admissionGpagroupId', numeric: true, disablePadding: false, label: t('students.admissionGpagroupId', { ns: 'tableHeadCells' }) },
        { id: 'admissionGpagroup', numeric: true, disablePadding: false, label: t('students.admissionGpagroup', { ns: 'tableHeadCells' }) },
        { id: 'internal', numeric: true, disablePadding: false, label: t('students.internal', { ns: 'tableHeadCells' }) },
    ];

    useEffect(() => {
        if (selectedRow.prospectId) {
            setIsloading(true);
            getApi(`${document_tracking}/admissions`).then((resp) => {
                const { uniqueId, displayText } = resp.data.data;
                setModule({ ...module, "moduleName": displayText, "moduleId": uniqueId });
                getApi(`${student_doc_dropdown}?studentUID=${selectedRow.prospectId}&docTrackModuleId=${uniqueId}`).then((resp) => {
                    const { documentGroup, documentNames, status, userDefinedFields, admissionGPAGroup } = resp.data.data;
                    setDropdown({
                        ...Dropdown,
                        "documentGroup": documentGroup,
                        "documentNames": documentNames,
                        "status": status,
                        "userDefinedFields": userDefinedFields,
                        "admissionGPAGroup": admissionGPAGroup,
                    })
                    setGpaGrp(-1);
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err);
            })

        }
    }, [selectedRow])
    useEffect(() => {
        if (selectedRow.prospectId && (gpaGrp !== '' || gpaGrp === 0)) {
            getApi(`${prospect_document_tracking}/${selectedRow.prospectId}/${gpaGrp}`).then((resp) => {
                setTableRow(resp.data.data);
                DefaultTable.resetTable('documentTracking')
                setIsloading(false);
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setIsloading(false);
        }
    }, [gpaGrp])
    const handleChange = (e) => {
        setGpaGrp(e.target.value)
    }
    const handleDeleteRow = () => {
        setIsloading(true);
        selectedDoc.docTrackId && deleteApi(`${document_tracking}/${selectedDoc.docTrackId}/${selectedRow.prospectId}`).then((res) => {
            getApi(`${prospect_document_tracking}/${selectedRow.prospectId}/${gpaGrp}`).then((resp) => {
                setTableRow(resp.data.data);
                setIsloading(false);
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                DefaultTable.resetTable('documentTracking')
            }).catch((err) => {
                console.log(err);
            })

        }).catch(err => {
            console.log(err);
            setIsloading(false);
        })
    }
    const handleEditRow = () => {
        setShowAddData(false);
        setAddDoc(true);
    }
    const setCollege = () => {
        getApi(`${college_url}/${selectedRow.prospectId}`).then((res) => {
            if (res.data.data.length > 0) {
                setShowAddData(false);
                setShowColleges(true);
            } else {
                setCollegeFlag(true);
            }
        }).catch((err) => console.log(err))

    }
    const setAddDocTrack = () => {
        setSelectedDoc({});
        setShowAddData(false);
        setAddDoc(true);
    }
    const setLoadSeq = () => {
        setShowAddData(false);
        setLoadSequence(true);
    }
    const uploadAction = (row) => {
        setPopupDatas({
            popupHeaderTitle: t('commonLabel.uploadDocument'),
            openPopup: true,
            selectedTableRow: row
        });
    }
    const handleDocClose = () => {
        setPopupDatas({ ...popupDatas, openPopup: false })
        setIsloading(true);
        getApi(`${prospect_document_tracking}/${selectedRow.prospectId}/${-1}`).then((resp) => {
            setTableRow(resp.data.data);
            setIsloading(false);
        }).catch((err) => {
            console.log(err);
        })
    }
    const goBack = () => {
        setShowColleges(false);
        setLoadSequence(false);
        setShowAddData(false);
        setAddDoc(false);
        admurl ? history.push("/admissions/students/student-search") : regurl ? history.push("/registration/student-search") : finurl && history.push("/financial-aid/student-search")
        dispatch(getSelectedProfiles({}))
    }
    const { access } = useSelector(updateAccessSelector)
    const { subNestedParentLevel } = access;
    const [statusAccess, setStatusAccess] = useState({
        documentTracking: 2,
    })
    useEffect(() => {
        
            let changestudentStatus = subNestedParentLevel.filter((v, i) => v.menuItemName.trim() === documentTracking && v.moduleorder === 1
            )
            setStatusAccess({
                ...statusAccess,
                documentTracking: (changestudentStatus.length > 0) ? changestudentStatus[0].menuAccess : 2,
            })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (statusAccess.documentTracking !== 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [statusAccess])


    return (
        <>
            {(showAddData) ?
                <>
                    <Breadcrumb profileData={{ profileName: selectedRow.prospectName, profileId: selectedRow.prospectId, Id: true }}>
                        {[...admissions_DocumentTracking].map(({ name, isLink, path }, index) => (
                            <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} />
                        ))}
                    </Breadcrumb>
                    <HeaderTitle headerTitle={t('commonLabel.studentDocumentTracking')} help={true} buttonText={t('prospectLabel.findProspect')} plus={false} url={"/admissions/prospects/prospect-search" }/>
                    <Card className={classes.root}>
                        <CardContent>
                            <FormGroup>
                                <TextInput
                                    label={t('commonLabel.documentTrackingModule')}
                                    labelId={'module'}
                                    formikValue={module.moduleName}
                                    isDoubled={true}
                                    disabled={true}
                                />
                                <SelectInput
                                    label={t('commonLabel.programGPAGroup')}
                                    labelId={'gpaGrp'}
                                    disabled={accessFlag}
                                    formikValue={gpaGrp}
                                    formikChange={handleChange}
                                    isDoubled
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.admissionGPAGroup }}
                                />
                            </FormGroup>
                        </CardContent>
                    </Card>
                    <CardContent>
                        <ButtonWrapper>
                            <Button disabled={accessFlag} color="primary" variant="contained" size="large" type="submit" onClick={setLoadSeq}>
                                <span className="plus">+</span> {t('buttonLabel.loadDocumentSequence')}
                            </Button>
                            <Button disabled={accessFlag} color="primary" variant="contained" size="large" type="submit" onClick={setCollege}>
                                <span className="plus">+</span>{t('buttonLabel.loadColleges')}
                            </Button>
                            <Button disabled={accessFlag} color="primary" variant="contained" size="large" type="submit" onClick={setAddDocTrack}>
                                <span className="plus">+</span> {t('buttonLabel.addDocumentTracking')}
                            </Button>
                        </ButtonWrapper>
                    </CardContent>
                    <Card className={classes.root}>
                        <DefaultTable trackingLabel={"documentTracking"} isLoading={isLoading} headCells={headCells} rows={tableRow} isLookup={false} pagination={true} deleteDisable={accessFlag} onDelete={handleDeleteRow} onEdit={handleEditRow} uploadAction={uploadAction} onRowSelect={setSelectedDoc} />
                        <CardContent>
                            <ButtonWrapperFilter >
                                <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={goBack}>
                                    {t('buttonLabel.cancel')}
                                </Button>
                            </ButtonWrapperFilter>
                        </CardContent>
                    </Card>

                </> :
                (showAddDoc) ?
                    <AddDocTracking accessFlag={accessFlag} setIsloading={setIsloading} setTableRow={setTableRow} module={module} Dropdown={Dropdown} selectedStudent={selectedRow} selectedDoc={selectedDoc} setShowAddData={setShowAddData} setAddDoc={setAddDoc} /> :
                    (showLoadSequence) ? <LoadDocSequence setIsloading={setIsloading} setTableRow={setTableRow} module={module} Dropdown={Dropdown} selectedStudent={selectedRow} selectedDoc={selectedDoc} setShowAddData={setShowAddData} setLoadSequence={setLoadSequence} />
                        : showLoadColleges && <LoadColleges setIsloading={setIsloading} setTableRow={setTableRow} module={module} Dropdown={Dropdown} selectedStudent={selectedRow} selectedDoc={selectedDoc} setShowAddData={setShowAddData} setShowColleges={setShowColleges} />
            }
            <GenericModal infoMessage={t('popupMessages.noPreviousCollegesRecords')}
                open={collegeFlag} onClose={setCollegeFlag}
                buttonText={t('buttonLabel.ok')}
            />
            {popupDatas.openPopup && <DocumentUploadPopup accessFlag={accessFlag} popupDatas={popupDatas} onClose={handleDocClose} setPopupDatas={setPopupDatas} setIsloading={setIsloading} setTableRow={setTableRow} />}
        </>
    )
}

export default DocumentTracking;