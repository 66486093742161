import {
    SAVE_SELECTED_NEW_RECORDS,
    SAVE_SELECTED_EXACT_RECORDS,
    SAVE_TEST_REF_ID,
    SAVE_SELECTED_Partial_RECORDS,
    SAVE_SELECTED_Fuzzy_RECORDS,
    BACK_TO_IMPORT,
    SAVE_IMPORT,
    SAVE_STORED_PROCEDURES
} from './types'
const initialState = {
    testScoreRefId: 0,
    selectedNewRecords: {},
    exactSelectedNewRecords: {},
    partialRecordsSelectedNewRecords: {},
    storedProcedures: {storeProceduresBeforeImport: [], storeProceduresAfterImport: []},
    fuzzyRecordsSelectedNewRecords: {},
    backToImport: [],
    testScoreImport: {}

};
const testScoreIpmortReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_STORED_PROCEDURES:
            return {
                ...state,
                storedProcedures: payload

            };
        case SAVE_IMPORT:
            return {
                ...state,
                testScoreImport: payload

            };
        case SAVE_TEST_REF_ID:
            return {
                ...state,
                testScoreRefId: payload

            };

        case SAVE_SELECTED_NEW_RECORDS:
            return {
                ...state,
                selectedNewRecords: payload

            };
        case SAVE_SELECTED_EXACT_RECORDS:
            return {
                ...state,
                exactSelectedNewRecords: payload

            };
        case SAVE_SELECTED_Partial_RECORDS:
            return {
                ...state,
                partialRecordsSelectedNewRecords: payload

            };
        case SAVE_SELECTED_Fuzzy_RECORDS:
            return {
                ...state,
                fuzzyRecordsSelectedNewRecords: payload

            };
        case BACK_TO_IMPORT:
            return {
                ...state,
                backToImport: payload

            };

        default:
            return state;
    }
}
export default testScoreIpmortReducer;