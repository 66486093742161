import {SEARCH_ALL_QUESTIONS,SEARCH_UNANS_QUESTIONS,GET_ANWSER,GET_QUESTION } from './type'


export const getAllQuestions = (allQuestions) => async (dispatch) => {
    await dispatch({
        type: SEARCH_ALL_QUESTIONS,
        payload: [...allQuestions]
    });
};
export const getUnansweredQuestions = (allQuestions) => async (dispatch) => {
    await dispatch({
        type: SEARCH_UNANS_QUESTIONS,
        payload: [...allQuestions]
    });
};
export const getCurrentAnswer = (answers) => async (dispatch) => {
    await dispatch({
        type: GET_ANWSER,
        payload: [...answers]
    });
};
export const getCurrentQuestion = (question) => async (dispatch) => {
    await dispatch({
        type: GET_QUESTION,
        payload: [question]
    });
};


