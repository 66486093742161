import { Card, CardContent } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import HeaderTitle from '../../../../utils/HeaderTitle/HeaderTitle'
import Add from './addFormList';
import { deleteApi, getApi } from '../../../../services/apiBase';
import { crs_eval_master } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';
import TabContext from '../../../../utils/ScrollableTab/tab-helpers/TabContext';
import { useStyles } from '../../../../styles/common-styles';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getSelectedFormList } from '../../../../redux/tools/CRSEvalMaster/mass-add-audit/action';
import { updateAccessSelector } from '../../../../redux/common/roleAccess/selectors';
import { evaluationSetup } from "../../../../helper/constants";
const FormList = ({ addData, setTabSwitch, tabIndex }) => {
    const { t } = useTranslation(["translation", "toolsHeadCells", "tools"]);
    const dispatch = useDispatch()
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;
    const [formList] = useState([
        { id: 'formId', numeric: true, disablePadding: false, label: t('formList.formId', { ns: 'toolsHeadCells' }) },
        { id: 'formName', numeric: true, disablePadding: false, label: t('formList.formName', { ns: 'toolsHeadCells' }) },
        { id: 'active', numeric: true, disablePadding: false, label: t('formList.active', { ns: 'toolsHeadCells' }) },
        { id: 'numQuestions', numeric: true, disablePadding: false, label: t('formList.numQuestion', { ns: 'toolsHeadCells' }) },
        { id: 'description', numeric: true, disablePadding: false, label: t('formList.description', { ns: 'toolsHeadCells' }) },
        { id: 'maxQuestions', numeric: true, disablePadding: false, label: t('formList.maxQuestion', { ns: 'toolsHeadCells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('formList.insertUserId', { ns: 'toolsHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('formList.insertTime', { ns: 'toolsHeadCells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('formList.updateUser', { ns: 'toolsHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('formList.updateTime', { ns: 'toolsHeadCells' }) },
    ])
    const [tableRow, settableRow] = useState([])
    const [showAddData, setShowAddData] = useState(false)
    const [selectedRow, setSelectedRow] = useState('');
    const handleEditRow = (value) => {
        setShowAddData(value);
        addData(true)
    }
    const [isLoading, setIsLoading] = useState(false)
    const getData = () => {
        setIsLoading(true)
        getApi(`${crs_eval_master}`).then((resp) => {
            settableRow(resp.data.data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
        })
    }
    const handleDeleteRow = () => {
        setIsLoading(true);
        if (selectedRow?.formId) {
            deleteApi(`${crs_eval_master}/${selectedRow?.formId}`).then((resp) => {
                if (resp.data.isSuccess) {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                    DefaultTable.resetTable("formlist");
                    getData();
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
        }
    }
    const handleAdd = (value) => {
        setShowAddData(value);
        setSelectedRow('');
        addData(true)
    }

    const handleRowSelect = (val) => {
        setSelectedRow(val)
    }
    const onClose = (value) => {
        setShowAddData(value);
        addData(false)
        getData()
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
    }
    useEffect(() => {
        getData()
    }, [])

    const classes = useStyles();

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
        },

    })
    const { getCurrentTabsFormSnapshot } = useContext(TabContext);

    useEffect(() => {
        getCurrentTabsFormSnapshot(tabIndex, formik)
    }, [])

    useEffect(() => {
        if (selectedRow !== '') {
            setTabSwitch(false)
            dispatch(getSelectedFormList(selectedRow))
            return;
        }
        dispatch(getSelectedFormList(''))
        setTabSwitch(true)
    }, [selectedRow])
    const [evaluationSetupAccess, setEvaluationSetupAccess] = useState({
        evaluationSetup : 2,  
       })
       useEffect(() => {
         let evaluationSetupAccess = nestedParentLevel.filter((v, i) =>   
           v.menuItemName.trim() === evaluationSetup && v.moduleorder === 15  
         )  
         setEvaluationSetupAccess({ 
           ...evaluationSetupAccess, 
           evaluationSetup: (evaluationSetupAccess.length > 0) ? evaluationSetupAccess[0].menuAccess : 2,  
         })
       }, [access])   
       const [accessFlag, setAccessFlag] = useState(false)  
       useEffect(() => { 
         (evaluationSetupAccess.evaluationSetup === 1) ? setAccessFlag(true) : setAccessFlag(false) 
       }, [evaluationSetupAccess])
            //Role Based Access implementation ends here

    return (
        <>
            {(!showAddData) ?
                <>
                    <form onSubmit={formik.handleSubmit} className={classes.root}>
                        <Card>
                            <CardContent>
                                <HeaderTitle variant='subHeading' headerTitle={t('commonLabels.formSetup', { ns: 'tools' })} buttonText={t('buttonLabel.add')} addData={handleAdd}  disableButton={evaluationSetupAccess.evaluationSetup !== 2 ? true : false} />
                                <DefaultTable uniqueKey={'formId'} trackingLabel={"formlist"} headCells={formList} rows={tableRow} onEdit={handleEditRow} pagination={true} onDelete={handleDeleteRow} isLoading={isLoading} onRowSelect={handleRowSelect} 
                                  deleteDisable={evaluationSetupAccess.evaluationSetup !== 2 ? true : false} />
                            </CardContent>
                        </Card>
                    </form>
                </> :
                <Add onClose={onClose} accessFlag={accessFlag} selectedRow={selectedRow} />
            }
        </>
    )
}
export default FormList