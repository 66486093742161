import { Button, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput, TextAreaInput, TextInput } from '../../../../components/Forms';
import { ButtonWrapperFilter, FormGroup, useStyles } from '../../../../styles/common-styles';
import * as yup from 'yup'
import PopupHeader from '../../../common-page/popupHeader';
import { tools_crsevaluationsetup_add } from '../../../../services/navigation_folder/tools';
import { crs_eval_master_question_Dropdown, crs_eval_master_question } from '../../../../services/apiURL';
import { postApi, putApi, getApi } from '../../../../services/apiBase';
import { toastMessage } from '../../../../utils/Message/toasts';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import Tooltips from '../../../../utils/Tooltip/Tooltip';
import GenericModal from '../../../../components/Modal/GenericModal';

const AddQuestion = ({ onClose, selectedRow, selectedRowForm,accessFlag }) => {
    const { t } = useTranslation(["translation", "tools"]);

    const initialValues = {
        question: "",
        questionType: "",
        minimum: "",
        maximum: "",
        questionId: "",
        questionCategory: "",
        mcResponseID: 0
    }
    const validationSchema = yup.object({
        questionType: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.questionType', { ns: "tools" }) })),
        question: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.question', { ns: "tools" }) })),

    })
    const validationSchemaTwo = yup.object({
        questionType: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.questionType', { ns: "tools" }) })),
        question: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.question', { ns: "tools" }) })),
        minimum: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.minimum', { ns: "tools" }) })),
        maximum: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabels.maximum', { ns: "tools" }) }))
    })
    const [validationSchemas, setvalidationSchemas] = useState(validationSchema);
    const [formValues, setFormValues] = useState(initialValues)
    const [alertMax, setALertMax] = useState(false)
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: validationSchemas,
        onSubmit: (values) => {
            let payloadRequest = {
                "questionID": (values.questionId) ? values.questionId : 0,
                "formID": selectedRowForm.formId,
                "questionText": values.question,
                "isNarr": (values.questionType === 'Narrative') ? true : false,
                "isMC": (values.questionType === 'MultipleChoice') ? true : false,
                "isNum": (values.questionType === 'Numeric') ? true : false,
                "numberMin": (values.minimum) ? values.minimum : 0,
                "numberMax": (values.maximum) ? values.maximum : 0,
                "sectionHeading": (values.questionType === 'SectionHeading') ? true : false,
                "crsEvalQuestCategoryID": (values.questionCategory) ? values.questionCategory : 0
            }

            if (Number(selectedRowForm.maxQuestions) < Number(selectedRowForm.numQuestions)) {
                setALertMax(true)
            } else {
                setALertMax(false)
                if (selectedRow) {
                    putApi(`${crs_eval_master_question}`, payloadRequest).then((res) => {
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                        onClose(false)
                        DefaultTable.updateSelectedRow('QuestionSetup', {
                            ...payloadRequest,
                            "isNarr": (values.questionType === 'Narrative') ? 'Yes' : 'No',
                            "isMC": (values.questionType === 'MultipleChoice') ? 'Yes' : 'No',
                            "isNum": (values.questionType === 'Numeric') ? 'Yes' : 'No',
                            "sectionHeading": (values.questionType === 'SectionHeading') ? 'Yes' : 'No'
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
                } else {
                    postApi(`${crs_eval_master_question}`, payloadRequest).then((res) => {
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                        onClose(false)
                        DefaultTable.resetTable('QuestionSetup')
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            }
        },
    })

    useEffect(() => {
        if (formik.values.questionType === 'Numeric') {
            setvalidationSchemas(validationSchemaTwo)
        } else {
            setvalidationSchemas(validationSchema)
        }
    }, [formik.values.questionType])

    const [Dropdown, setDropdown] = useState({
        "questionCategory": [],
        "questionType": []
    })
    useEffect(() => {
        getApi(crs_eval_master_question_Dropdown).then((resp) => {
            const {
                questionCategory,
                questionType
            } = resp.data.data
            setDropdown({
                ...Dropdown,
                "questionCategory": (questionCategory) ? questionCategory : [],
                "questionType": (questionType) ? questionType : []
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const classes = useStyles();

    React.useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    }, [])

    useEffect(() => {
        if (selectedRow) {
            const { isNarr,
                isMC,
                isNum,
                sectionHeading } = selectedRow
            setFormValues({
                ...formValues,
                question: selectedRow.questionText,
                questionType: (isNarr === 'Yes') ? 'Narrative' :
                    (isMC === 'Yes') ? 'MultipleChoice' :
                        (isNum === 'Yes') ? 'Numeric' :
                            (sectionHeading === 'Yes') ? 'SectionHeading' : '',
                minimum: selectedRow.numberMin,
                maximum: selectedRow.numberMax,
                questionId: selectedRow.questionID,
                questionCategory: selectedRow.crsEvalQuestCategoryID,
                mcResponseID: selectedRow.mcResponseID
            })
        }

    }, [selectedRow])

    return (
        <>
            <GenericModal
                title={"The maximum number of questions has been reached."}
                open={alertMax}
                onClose={setALertMax}
                buttonText={t('buttonLabel.ok')}
            />
            <PopupHeader title={(selectedRow) ? "Update Question" : "Add Question"} onClose={onClose} navigationList={tools_crsevaluationsetup_add} />
            <form onSubmit={formik.handleSubmit} className={classes.root}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <TextInput isDoubled disabled
                                labelId="questionId"
                                label={t('commonLabels.questionId', { ns: "tools" })}
                                formikValue={formik.values.questionId}
                                formikChange={formik.handleChange}
                            />
                            <SelectInput isDoubled
                            disabled={accessFlag}
                                labelId="questionType"
                                label={t('commonLabels.questionType', { ns: "tools" })}
                                formikValue={formik.values.questionType}
                                formikChange={formik.handleChange}
                                mandatory={true}
                                errors={formik.errors.questionType}
                                touched={formik.touched.questionType}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.questionType }}
                            />
                        </FormGroup>
                        {formik.values.questionType === 'Numeric' &&
                            <FormGroup>
                                <TextInput
                                    label={t("commonLabels.minimum", { ns: "tools" })}
                                    labelId={"minimum"}
                                    formikValue={formik.values.minimum}
                                    formikChange={formik.handleChange}
                                    mandatory={true}
                                    errors={formik.errors.minimum}
                                    touched={formik.touched.minimum}
                                    onlyNumeric={true}
                                    disabled={accessFlag}
                                />
                                <TextInput
                                    label={t("commonLabels.maximum", { ns: "tools" })}
                                    labelId={"maximum"}
                                    formikValue={formik.values.maximum}
                                    formikChange={formik.handleChange}
                                    mandatory={true}
                                    errors={formik.errors.maximum}
                                    touched={formik.touched.maximum}
                                    onlyNumeric={true}
                                    disabled={accessFlag}
                                />
                            </FormGroup>
                        }
                        <FormGroup>
                            <SelectInput
                                label={t("commonLabels.questionCategory", { ns: "tools" })}
                                labelId={"questionCategory"}
                                disabled={accessFlag}
                                formikValue={formik.values.questionCategory}
                                formikChange={formik.handleChange}
                                isDoubled={true}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.questionCategory }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextAreaInput isDoubled
                                label={t("commonLabels.question", { ns: "tools" })}
                                labelId={'question'}
                                formikChange={formik.handleChange}
                                formikValue={formik.values.question}
                                mandatory={true}
                                errors={formik.errors.question}
                                touched={formik.touched.question}
                                disabled={accessFlag}
                            />
                        </FormGroup>
                        <div style={{ display: 'flex' }}>
                            <Tooltips crsEvalFlag={true} flag={selectedRow} row={(selectedRow) ? {
                                insertUserID: selectedRow.insertUserId,
                                insertTime: selectedRow.insertTime,
                                updateUserID: selectedRow.updateUserId,
                                updateTime: selectedRow.updateTime
                            } : ''} />
                            <ButtonWrapperFilter>
                                <Button
                                    className='BgYellowOrange search'
                                    variant="contained"
                                    size="large"
                                    disabled={accessFlag}
                                    type="submit"
                                >
                                    {(selectedRow) ? t('buttonLabels.update', { ns: "tools" }) : t('buttonLabels.add', { ns: "tools" })}
                                </Button>
                                <Button
                                    className="BgLightBlue search"
                                    variant="contained"
                                    size="large"
                                    type="reset"
                                    onClick={() => onClose('')}
                                >
                                    {t("buttonLabels.cancel", { ns: "tools" })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>

    )
}
export default AddQuestion