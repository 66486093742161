import {
    GET_TERM
} from './types';
const intialState = {
    transferTerms: [],
    awardTypes:[],
    awardStatuses: [],
    creditStatuses: [],
    finAidCode1s: [],
    finAidCode2s: [],
    registrationStatus: [],
    distributionDateFrom: null,
    distributionDateTo: null,
    documentsMustbeComplete: "no",
    studentAccepted: "",
    finAidYears: null,
    incStudentswNoDocs: "yes",
    singleStudentID:"",
    StudentName: "",
    studentUId:""
}

const termReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_TERM:
            return payload
       
        default:
            return state;
    }
}

export default termReducer;

