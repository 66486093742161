import { SAVE_CRITERIA_SEARCH, RESET_CRITERIA_MODULE_DATA } from './types'

export const getCriteriaSearchData = (value) => async (dispatch) => {
    await dispatch({
        type: SAVE_CRITERIA_SEARCH,
        payload: value
    });
};


export const resetCriteriaCard = (value) => async (dispatch) => {
    await dispatch({
        type: RESET_CRITERIA_MODULE_DATA,
        payload: value
    });
};
