
const defaults = {
    symbol: '$',
    separator: ',',
    decimal: '.',
    errorOnInvalid: false,
    precision: 2,
    pattern: '!#',
    negativePattern: '-!#',
    format,
    fromCents: false
};
//==> symbol options $ ¥ €

const round = v => Math.round(v);
const pow = p => Math.pow(10, p);
const rounding = (value, increment) => round(value / increment) * increment;

const groupRegex = /(\d)(?=(\d{3})+\b)/g;
const vedicRegex = /(\d)(?=(\d\d)+\d\b)/g;

/**
 * Create a new instance of currencyPipe.js
 * @param {number|string|currencyPipe} value
 * @param {object} [opts]
 */
//  € $ £ R
export const formNumber = (value) => {
    // regex to check $11,22.00
    let regex = /^[-]*[$]*[€]*[£]*[R]*\d+(\,\d+)*(\.\d+)*$/;

    return regex.test(value) ? Number((value).replace(/[€ $ £ R,]/g, '')) : Number(value);
}
export const parseNumber = (value) => {
    let regex = /^[-]*[$]*[€]*[£]*[R]*\d+(\.\d+)*$/;
    let parsedValue = (value).toString();
    // regex to check commas
    if ((/^(.*?(\,\b)[^$]*)$/).test(value)) {
        parsedValue = (value).replace(/,/ig, '');
    }
    let symbols = ['€', '$', '£', 'R'];
    let regexNumber = regex.test(parsedValue);
    if (regexNumber) {
        if ((parsedValue).indexOf('$') >= 0) {
            parsedValue = Number((parsedValue).replace(/[$]/g, ''));
        } else if ((parsedValue).indexOf('€') >= 0) {
            parsedValue = Number((parsedValue).replace(/[€]/g, ''));
        } else if ((parsedValue).indexOf('£') >= 0) {
            parsedValue = Number((parsedValue).replace(/[£]/g, ''));
        } else if ((parsedValue).indexOf('R') >= 0) {
            parsedValue = Number((parsedValue).replace(/[R]/g, ''));
        } else {
            parsedValue = Number(parsedValue);
        }
    } else {
        parsedValue = 0;
    }
    return parsedValue;
}

function currencyPipe(value, opts) {
    let that = this;

    if (!(that instanceof currencyPipe)) {
        return new currencyPipe(value, opts);
    }
    let settings = Object.assign({}, defaults, opts)
        , precision = pow(settings.precision)
        , v = parse(value, settings);

    that.intValue = v;
    that.value = v / precision;

    // Set default incremental value
    settings.increment = settings.increment || (1 / precision);

    // Support vedic numbering systems
    // see: https://en.wikipedia.org/wiki/Indian_numbering_system
    if (settings.useVedic) {
        settings.groups = vedicRegex;
    } else {
        settings.groups = groupRegex;
    }

    // Intended for internal usage only - subject to change
    this._settings = settings;
    this._precision = precision;
}

function parse(value, opts, useRounding = true) {
    let v = 0
        , { decimal, errorOnInvalid, precision: decimals, fromCents } = opts
        , precision = pow(decimals)
        , isNumber = typeof value === 'number'
        , iscurrencyPipe = value instanceof currencyPipe;

    if (iscurrencyPipe && fromCents) {
        return value.intValue;
    }

    if (isNumber || iscurrencyPipe) {
        v = iscurrencyPipe ? value.value : value;
    } else if (typeof value === 'string') {
        let regex = new RegExp('[^-\\d' + decimal + ']', 'g')
            , decimalString = new RegExp('\\' + decimal, 'g');
        v = value
            .replace(/\((.*)\)/, '-$1')    // allow negative e.g. (1.99)
            .replace(regex, '')            // replace any non numeric values
            .replace(decimalString, '.');  // convert any decimal values
        v = v || 0;
    } else {
        if (errorOnInvalid) {
            throw Error('Invalid Input');
        }
        v = 0;
    }

    if (!fromCents) {
        v *= precision;                  // scale number to integer value
        v = v.toFixed(4);                // Handle additional decimal for proper rounding.
    }

    return useRounding ? round(v) : v;
}

/**
 * Formats a currencyPipe object
 * @param currencyPipe
 * @param {object} [opts]
 */
function format(currencyPipe, settings) {
    let { pattern, negativePattern, symbol, separator, decimal, groups } = settings
        , split = ('' + currencyPipe).replace(/^-/, '').split('.')
        , dollars = split[0]
        , cents = split[1];

    return (currencyPipe.value >= 0 ? pattern : negativePattern)
        .replace('!', symbol)
        .replace('#', dollars.replace(groups, '$1' + separator) + (cents ? decimal + cents : ''));
}

currencyPipe.prototype = {
    /**
     * Formats the value as a string according to the formatting settings.
     * @param {boolean} useSymbol - format with currencyPipe symbol
     * @returns {string}
     */
    format(options) {
        let { _settings } = this;

        if (typeof options === 'function') {
            return options(this, _settings);
        }

        return _settings.format(this, Object.assign({}, _settings, options));
    },

    /**
     * Formats the value as a string according to the formatting settings.
     * @returns {string}
     */
    toString() {
        let { intValue, _precision, _settings } = this;
        return rounding(intValue / _precision, _settings.increment).toFixed(_settings.precision);
    },

    /**
     * Value for JSON serialization.
     * @returns {float}
     */
    toJSON() {
        return this.value;
    }

};

export default currencyPipe;