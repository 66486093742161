import { UPDATE_PLAN_MAP_ROWS, UPDATE_SELECTED_PLAN_MAP, UPDATE_CRITERIA, UPDATE_PLAN_SETUP, UPDATE_CRITERIA_SELECTED,UPDATE_PROCESS_ROWS } from './types'

const initialState = {
    planMap: {
        planMapRows: [],
        selectedPlan: {}
    },
    criteria: {
        accessCampus: [],
        term: [],
        studentRows: [],
        selectedCriteria: {}
    },
    planSetup: {},
    process: {
        processRows: []
    }
};

const alterPlanReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_PLAN_MAP_ROWS:
            return {
                ...state,
                planMap: {
                    ...state.planMap, planMapRows: payload
                }
            }
        case UPDATE_SELECTED_PLAN_MAP:
            return {
                ...state,
                planMap: {
                    ...state.planMap, selectedPlan: payload
                }
            }
        case UPDATE_CRITERIA:
            const { id, value } = payload
            return {
                ...state,
                criteria: {
                    ...state.criteria, [id]: value
                }
            }
        case UPDATE_CRITERIA_SELECTED:
            return {
                ...state,
                criteria: {
                    ...state.criteria, selectedCriteria: payload
                }
            }
        case UPDATE_PLAN_SETUP:
            return {
                ...state,
                planSetup: payload
            }
        case UPDATE_PROCESS_ROWS:
            return {
                ...state,
                process: {
                    ...state.process, processRows: payload
                }
            }
        default:
            return state;
    }
};

export default alterPlanReducer;