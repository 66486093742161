import { UPDATE_AWARD_ROWS, UPDATE_JOB_ROWS, UPDATE_WORKER_ROWS,WORK_STUDY_CONFIG } from './types'
const initialState = {
    jobsTab: {
        jobRows: [],
        selectedJob: {},
        term: 0,
    },
    assignAwardsTab: {
        awardRows: [],
        selectedAward: {}
    },
    workersTab: {
        workerRows: [],
        selectedWorker: {},
        timeSheetRows:[],
        selectedTimeSheet:{}
    },
    workstudyMaintenanceConfig:{}
}; 
const maintenanceFinancialReducer = (state = initialState, { type, payload }) => {
    
    switch (type) {
        case UPDATE_JOB_ROWS:
            return {
                ...state,
                jobsTab: {
                    ...state.jobsTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_AWARD_ROWS:
            return {
                ...state,
                assignAwardsTab: {
                    ...state.assignAwardsTab, [payload['id']]: payload['value']
                }
            }
        case UPDATE_WORKER_ROWS:
            return {
                ...state,
                workersTab: {
                    ...state.workersTab, [payload['id']]: payload['value']
                }
            };
            case WORK_STUDY_CONFIG:
            return {
                ...state,
                workstudyMaintenanceConfig: payload
            }
        default:
            return state;
    }
}
export default maintenanceFinancialReducer