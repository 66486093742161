import { SAVE_CRITERIA_TAB, SAVE_MULTISTUDENT_TAB, SAVE_REPORT_TAB, RESET_GRADECARD_MODULE_DATA } from './types'

const initialState = {
    criteriaTab: {
        term: "",
        studentSelection: "Single",
        studentId: "",
        studentName: "",
        gpaGrouping: [],
        printMultiAddressGradeCard: ""
    },
    multistudentTab: {
        studentNameRangeFrom: "",
        studentNameRangeTo: "",
        includeStudentOnHold: "",
        billingAllClear: "",
        studentLevel: "",
        course: "",
        courseEndDateFrom: null,
        courseEndDateTo: null,
        collegeLevel: [],
        refundGroup: [],
        studentStatus: [],
        extActivities: [],
        advisor: [],
        cohortGroup: [],
        campus: [],
        attendanceType: [],
        courseID: 0,
    },
    reportTab: {
        gradeType: "",
        reportType: "",
        addressType: "",
        message: "",
    }
};

const gradesReportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: payload
            };
        case SAVE_MULTISTUDENT_TAB:
            return {
                ...state,
                multistudentTab: payload
            };
        case SAVE_REPORT_TAB:
            return {
                ...state,
                reportTab: payload
            };
        case RESET_GRADECARD_MODULE_DATA:
            return initialState;

        default:
            return state;
    }
};

export default gradesReportReducer;