import {
    GET_INTEREST
} from './types';
const initialState = {
    creditTransactions: null,
    debitTransactions: null,
    pendingAidTransactions: null,
    batchTransactionDate: null,
    selectDateType: "Transaction",
    previousInterestChange: "No",
    interestPercentagetoUse: 0.01,
    batchPostingTerm: 0,
    interestTransactionDoc: -1,
    excludeNonBillingTransactions: false,
    pendingFinancialAid: "No",
    billingCategory: 0,
    minimumBalancetoEvaluate: 0.01,
    // minimumBalancetoEvaluate:  initCurrencyFields(false, 0.01),
    // interestTabValues: {
    // },
}

const billingProcessInterestReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_INTEREST:
            return payload
       
        default:
            return state;
    }
}

export default billingProcessInterestReducer;

