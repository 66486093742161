import { ADD_RELATIONS, DELETE_RELATIONS } from './type'

const initialState = {
    Relations: []
};

const RelationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_RELATIONS:
            return {
                ...state,
                Relations: [...payload]
            }
        case DELETE_RELATIONS:
            return {
                ...state,
                Relations: [...state.Relations.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default RelationsReducer;