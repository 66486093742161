import { UPDATE_OFFERING_FORM,UPDATE_OFFERING_TABLE,RESET_STATE } from './type'

const initialState = {
    offeringTab: {
        formValues:{
            termId: 0,
            courseName: "",
            courseDepartment: "",
            courseNumber: "",
            courseType: ""
        },
        offeringRows:[]
    }
};

const dataRangeEntryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_OFFERING_FORM:
            return {
                ...state,
                offeringTab:{
                    ...state.offeringTab,formValues: payload
                }
            }
            case UPDATE_OFFERING_TABLE:
            return {
                ...state,
                offeringTab:{
                    ...state.offeringTab,offeringRows: payload
                }
            }
            case RESET_STATE:
                return initialState
                
        default:
            return state;
    }
};

export default dataRangeEntryReducer;