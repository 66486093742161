import React, { useState, useEffect } from "react";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput} from "../../../../components/Forms";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles
} from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import { financialAid_usagelimits } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from 'yup';
import { add_award_usage_limit, update_award_usage_limit, award_usage_select_item } from "../../../../services/apiURL";
import { postApi, putApi, getApi } from "../../../../services/apiBase";
import { toastMessage } from "../../../../utils/Message/toasts";
import GenericModal from '../../../../components/Modal/GenericModal';


const AddAwardUsageLimits = ({ onClose, selectedRow, selectedFinStatusID, selectedStatusRow, selectedAwardRow, accessFlag }) => {
    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
    const classess = useStyles();
    const [openAlert, setOpenAlert] = useState(false);
    const [textData, setTextData] = useState('');
    const [formValues, setFormValues] = useState(initialValues)
    const initialValues = {
        codAwardTypes: "",
        addAwardUsageLimits: "",
        updateAwardUsageLimits: "",
        subsidizedUsageLimitApplies: false,
        sumActualSubUsagePeriods: "",
        sumActualNonCredTeacherCertSubUsagePeriods: "",
        anticipatedSubUsagePeriod: "",
        actualSubUsagePeriod: "",
        academicYearLength: "",
        loanPeriodLength: "",
        maxSubEligibilityPeriod: "",
        remainingActualSubEligibilityPeriod: "",
        remainingAnticipatedSubEligibilityPeriod: "",
        sumAnticipatedSubUsagePeriods: "",
        anticipatedNonCredTeacherCertSubUsagePeriod: "",
        actualNonCredTeacherCertSubUsagePeriod: "",
        remainingActualNonCredTeacherCertSubEligibilityPeriod: "",
        remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod: "",
        sumAnticipatedNonCredTeacherCertSubUsagePeriods: "",
        awardUsageLimits: "",
        calculatedPublishedPgmLengthYears: ""
    };

    const validationSchema = yup.object({
        codAwardTypes: yup
            .string()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.codAwardTypes', { ns: "financialAid" }) })),

    });
    const formik = useFormik({
        initialValues: formValues || initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if ((Object.keys(selectedAwardRow).length) <= 0) {
                const payload = {
                    "financialStatusID": selectedStatusRow.financialStatusID,
                    "codAwardType": values.codAwardTypes,
                    "subsidizedUsageLimitApplies": values.subsidizedUsageLimitApplies,
                    "sumActualSubUsagePeriods": Number(values.sumActualSubUsagePeriods),
                    "sumActualNonCredTeacherCertSubUsagePeriods": Number(values.sumActualNonCredTeacherCertSubUsagePeriods),
                    "calculatedPublishedPgmLengthYears": Number(values.calculatedPublishedPgmLengthYears),
                    "anticipatedSubUsagePeriod": Number(values.anticipatedSubUsagePeriod),
                    "actualSubUsagePeriod":  Number(values.actualSubUsagePeriod),
                    "academicYearLength": Number(values.academicYearLength),
                    "loanPeriodLength": Number(values.loanPeriodLength),
                    "maxSubEligibilityPeriod": Number(values.maxSubEligibilityPeriod),
                    "remainingActualSubEligibilityPeriod": Number(values.remainingActualSubEligibilityPeriod),
                    "remainingAnticipatedSubEligibilityPeriod": Number(values.remainingAnticipatedSubEligibilityPeriod),
                    "sumAnticipatedSubUsagePeriods": Number(values.sumAnticipatedSubUsagePeriods),
                    "anticipatedNonCredTeacherCertSubUsagePeriod": Number(values.anticipatedNonCredTeacherCertSubUsagePeriod),
                    "actualNonCredTeacherCertSubUsagePeriod": Number(values.actualNonCredTeacherCertSubUsagePeriod),
                    "remainingActualNonCredTeacherCertSubEligibilityPeriod": Number(values.remainingActualNonCredTeacherCertSubEligibilityPeriod),
                    "remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod": Number(values.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod),
                    "sumAnticipatedNonCredTeacherCertSubUsagePeriods": Number(values.sumAnticipatedNonCredTeacherCertSubUsagePeriods)

                }
                postApi(`${add_award_usage_limit}`, payload).then((resp) => {
                    if (resp.data.statusCode == 400) {
                        setOpenAlert(true);
                        setTextData(resp.data.data)
                    } else {
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
                        onClose(false);
                    }
                }).catch((err) => {
                    console.log(err);
                })
            }
            else {
                let payload = {
                    "financialStatusID": selectedFinStatusID.financialStatusID,
                    "codAwardType": values.codAwardTypes,
                    "subsidizedUsageLimitApplies": values.subsidizedUsageLimitApplies,
                    "sumActualSubUsagePeriods": Number(values.sumActualSubUsagePeriods),
                    "sumActualNonCredTeacherCertSubUsagePeriods": Number(values.sumActualNonCredTeacherCertSubUsagePeriods),
                    "calculatedPublishedPgmLengthYears": Number(values.calculatedPublishedPgmLengthYears),
                    "anticipatedSubUsagePeriod": Number(values.anticipatedSubUsagePeriod),
                    "actualSubUsagePeriod": Number(values.actualSubUsagePeriod),
                    "academicYearLength": Number(values.academicYearLength),
                    "loanPeriodLength": Number(values.loanPeriodLength),
                    "maxSubEligibilityPeriod": Number(values.maxSubEligibilityPeriod),
                    "remainingActualSubEligibilityPeriod": Number(values.remainingActualSubEligibilityPeriod),
                    "remainingAnticipatedSubEligibilityPeriod": Number(values.remainingAnticipatedSubEligibilityPeriod),
                    "sumAnticipatedSubUsagePeriods": Number(values.sumAnticipatedSubUsagePeriods),
                    "anticipatedNonCredTeacherCertSubUsagePeriod": Number(values.anticipatedNonCredTeacherCertSubUsagePeriod),
                    "actualNonCredTeacherCertSubUsagePeriod": Number(values.actualNonCredTeacherCertSubUsagePeriod),
                    "remainingActualNonCredTeacherCertSubEligibilityPeriod": Number(values.remainingActualNonCredTeacherCertSubEligibilityPeriod),
                    "remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod": Number(values.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod),
                    "sumAnticipatedNonCredTeacherCertSubUsagePeriods": Number(values.sumAnticipatedNonCredTeacherCertSubUsagePeriods)
                }
                putApi(`${update_award_usage_limit}/${selectedAwardRow.id}`, payload).then((resp) => {
                    if (resp.data.statusCode == 400) {
                        setOpenAlert(true);
                        setTextData(resp.data.data)
                    } else {
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
                        onClose(false);
                    }
                }).catch((err) => {
                    console.log(err);
                })
            }

        }

    });
    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
        if ((Object.keys(selectedAwardRow).length) > 0) {
            setFormValues({
                ...selectedAwardRow,
                "codAwardTypes": selectedAwardRow.codAwardType,
                "subsidizedUsageLimitApplies": selectedAwardRow.subsidizedUsageLimitApplies,
                "sumActualSubUsagePeriods": (selectedAwardRow.sumActualSubUsagePeriods) ? selectedAwardRow.sumActualSubUsagePeriods : '',
                "sumActualNonCredTeacherCertSubUsagePeriods": (selectedAwardRow.sumActualNonCredTeacherCertSubUsagePeriods) ? selectedAwardRow.sumActualNonCredTeacherCertSubUsagePeriods : '',
                "calculatedPublishedPgmLengthYears": (selectedAwardRow.calculatedPublishedPgmLengthYears) ? selectedAwardRow.calculatedPublishedPgmLengthYears : '',
                "anticipatedSubUsagePeriod": (selectedAwardRow.anticipatedSubUsagePeriod) ? selectedAwardRow.anticipatedSubUsagePeriod : '',
                "actualSubUsagePeriod": (selectedAwardRow.actualSubUsagePeriod) ? selectedAwardRow.actualSubUsagePeriod : '',
                "academicYearLength": (selectedAwardRow.academicYearLength) ? selectedAwardRow.academicYearLength : '',
                "loanPeriodLength": (selectedAwardRow.loanPeriodLength) ? selectedAwardRow.loanPeriodLength : '',
                "maxSubEligibilityPeriod": (selectedAwardRow.maxSubEligibilityPeriod) ? selectedAwardRow.maxSubEligibilityPeriod : '',
                "remainingActualSubEligibilityPeriod": (selectedAwardRow.remainingActualSubEligibilityPeriod) ? selectedAwardRow.remainingActualSubEligibilityPeriod : '',
                "remainingAnticipatedSubEligibilityPeriod": (selectedAwardRow.remainingAnticipatedSubEligibilityPeriod) ? selectedAwardRow.remainingAnticipatedSubEligibilityPeriod : '',
                "sumAnticipatedSubUsagePeriods": (selectedAwardRow.sumAnticipatedSubUsagePeriods) ? selectedAwardRow.sumAnticipatedSubUsagePeriods : '',
                "anticipatedNonCredTeacherCertSubUsagePeriod": (selectedAwardRow.anticipatedNonCredTeacherCertSubUsagePeriod) ? selectedAwardRow.anticipatedNonCredTeacherCertSubUsagePeriod : '',
                "actualNonCredTeacherCertSubUsagePeriod": (selectedAwardRow.actualNonCredTeacherCertSubUsagePeriod) ? selectedAwardRow.actualNonCredTeacherCertSubUsagePeriod : '',
                "remainingActualNonCredTeacherCertSubEligibilityPeriod": (selectedAwardRow.remainingActualNonCredTeacherCertSubEligibilityPeriod) ? selectedAwardRow.remainingActualNonCredTeacherCertSubEligibilityPeriod : '',
                "remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod": (selectedAwardRow.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod) ? selectedAwardRow.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod : '',
                "sumAnticipatedNonCredTeacherCertSubUsagePeriods": (selectedAwardRow.sumAnticipatedNonCredTeacherCertSubUsagePeriods) ? selectedAwardRow.sumAnticipatedNonCredTeacherCertSubUsagePeriods : '',
                "financialStatusID": selectedStatusRow.financialStatusID,
                "id": selectedStatusRow.id,

            })
        }

    }, [])



    const [Dropdown, setDropdown] = useState({
        "codAwardTypes": []
    })

    React.useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
        getApi(award_usage_select_item).then((resp) => {
            const { codAwardTypes } = resp.data.data;
            setDropdown({
                ...Dropdown,
                "codAwardTypes": codAwardTypes,
            })
        }).catch((err) => {
            console.log(err);
        })

    }, []);
    return (
        <>
            <PopupHeader
                title={
                    ((Object.keys(selectedAwardRow).length) <= 0)
                        ? (t('commonLabel.addAwardUsageLimits', { ns: "financialAid" }))
                        : (t('commonLabel.updateAwardUsageLimits', { ns: "financialAid" }))
                }
                navigationList={financialAid_usagelimits}
                onClose={onClose}
            />
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput isDoubled
                                mandatory
                                label={(t('commonLabel.codAwardTypes', { ns: "financialAid" }))}
                                labelId={"codAwardTypes"}
                                formikValue={formik.values.codAwardTypes}
                                formikChange={formik.handleChange}
                                errors={formik.errors.codAwardTypes}
                                touched={formik.touched.codAwardTypes}
                                disabled={accessFlag}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.codAwardTypes }}
                            />
                        </FormGroup>
                        <HeaderTitle variant={'subHeading'} headerTitle={(t('commonLabel.awardUsageLimits', { ns: "financialAid" }))} />
                        <FormGroup>
                            <CheckBoxInput isDoubled
                                label={(t('commonLabel.subsidizedUsageLimitApplies', { ns: "financialAid" }))}
                                labelId={"subsidizedUsageLimitApplies"}
                                formikValue={formik.values.subsidizedUsageLimitApplies}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                                checked={formik.values.subsidizedUsageLimitApplies}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.sumActualSubUsagePeriods', { ns: "financialAid" }))}
                                labelId="sumActualSubUsagePeriods"
                                formikValue={formik.values.sumActualSubUsagePeriods}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'5'}
                            />

                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.sumActualNonCredTeacherCertSubUsagePeriods', { ns: "financialAid" }))}
                                labelId="sumActualNonCredTeacherCertSubUsagePeriods"
                                formikValue={formik.values.sumActualNonCredTeacherCertSubUsagePeriods}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'5'}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.calculatedPublishedPgmLengthYears', { ns: "financialAid" }))}
                                labelId="calculatedPublishedPgmLengthYears"
                                formikValue={formik.values.calculatedPublishedPgmLengthYears}
                                formikChange={formik.handleChange}
                                maxValue={999.999}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'7'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.anticipatedSubUsagePeriod', { ns: "financialAid" }))}
                                labelId="anticipatedSubUsagePeriod"
                                formikValue={formik.values.anticipatedSubUsagePeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'5'}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.actualSubUsagePeriod', { ns: "financialAid" }))}
                                labelId="actualSubUsagePeriod"
                                formikValue={formik.values.actualSubUsagePeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'5'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.academicYearLength', { ns: "financialAid" }))}
                                labelId="academicYearLength"
                                formikValue={formik.values.academicYearLength}
                                formikChange={formik.handleChange}
                                onlyNumeric={true}
                                maxValue={9999}
                                disabled={accessFlag}
                                maxlength={'4'}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.loanPeriodLength', { ns: "financialAid" }))}
                                labelId="loanPeriodLength"
                                formikValue={formik.values.loanPeriodLength}
                                formikChange={formik.handleChange}
                                onlyNumeric={true}
                                maxValue={9999.999}
                                disabled={accessFlag}
                                maxlength={'8'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.maxSubEligibilityPeriod', { ns: "financialAid" }))}
                                labelId="maxSubEligibilityPeriod"
                                formikValue={formik.values.maxSubEligibilityPeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                onlyNumeric={true}
                                maxlength={'5'}
                                disabled={accessFlag}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.remainingActualSubEligibilityPeriod', { ns: "financialAid" }))}
                                labelId="remainingActualSubEligibilityPeriod"
                                formikValue={formik.values.remainingActualSubEligibilityPeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'5'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.remainingAnticipatedSubEligibilityPeriod', { ns: "financialAid" }))}
                                labelId="remainingAnticipatedSubEligibilityPeriod"
                                formikValue={formik.values.remainingAnticipatedSubEligibilityPeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                minValue={-999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'6'}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.sumAnticipatedSubUsagePeriods', { ns: "financialAid" }))}
                                labelId="sumAnticipatedSubUsagePeriods"
                                formikValue={formik.values.sumAnticipatedSubUsagePeriods}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'5'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.anticipatedNonCredTeacherCertSubUsagePeriod', { ns: "financialAid" }))}
                                labelId="anticipatedNonCredTeacherCertSubUsagePeriod"
                                formikValue={formik.values.anticipatedNonCredTeacherCertSubUsagePeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.actualNonCredTeacherCertSubUsagePeriod', { ns: "financialAid" }))}
                                labelId="actualNonCredTeacherCertSubUsagePeriod"
                                formikValue={formik.values.actualNonCredTeacherCertSubUsagePeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                minValue={-999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'6'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.remainingActualNonCredTeacherCertSubEligibilityPeriod', { ns: "financialAid" }))}
                                labelId="remainingActualNonCredTeacherCertSubEligibilityPeriod"
                                formikValue={formik.values.remainingActualNonCredTeacherCertSubEligibilityPeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                minValue={-999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'6'}
                            />
                            <TextInput isDoubled
                                label={(t('commonLabel.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod', { ns: "financialAid" }))}
                                labelId="remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod"
                                formikValue={formik.values.remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                minValue={-999.9}
                                onlyNumeric={true}
                                disabled={accessFlag}
                                maxlength={'6'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput isDoubled
                                label={(t('commonLabel.sumAnticipatedNonCredTeacherCertSubUsagePeriods', { ns: "financialAid" }))}
                                labelId="sumAnticipatedNonCredTeacherCertSubUsagePeriods"
                                formikValue={formik.values.sumAnticipatedNonCredTeacherCertSubUsagePeriods}
                                formikChange={formik.handleChange}
                                maxValue={999.9}
                                disabled={accessFlag}
                                onlyNumeric={true}
                                maxlength={'5'}
                            />
                        </FormGroup>

                    </CardContent>
                    <CardContent>
                        <div style={{ display: "flex" }}>
                            <Tooltips flag={(selectedAwardRow) ? true : false} row={
                                {
                                    insertUserID: selectedAwardRow.insertUserID,
                                    insertTime: selectedAwardRow.insertTime,
                                    updateUserID: selectedAwardRow.updateUserID,
                                    updateTime: selectedAwardRow.updateTime
                                }
                            } />

                            <ButtonWrapperFilter className="activityButton">
                                <Button
                                    className="BgYellowOrange search"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={accessFlag}
                                >
                                    {((Object.keys(selectedAwardRow).length) <= 0)
                                        ? (t('buttonLabel.add', { ns: "financialAid" }))
                                        : (t('buttonLabel.update', { ns: "financialAid" }))
                                    }
                                </Button>
                                <Button
                                    aria-label={"Cancel"}
                                    className="BgLightBlue"
                                    variant="contained"
                                    size="large"
                                    onClick={onClose}
                                >
                                    {t("buttonLabel.cancel", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
            <GenericModal
                open={openAlert}
                title={textData}
                onClose={setOpenAlert} />
        </>
    );
};

export default AddAwardUsageLimits; 
