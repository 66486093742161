import { ADD_GPAGroup, DELETE_GPAGroup } from './type'

const initialState = {
    GPAGroup: [
    ]
};

const GPAGroupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_GPAGroup:
            return {
                ...state,
                GPAGroup: [...payload]
            }
        case DELETE_GPAGroup:
            return {
                ...state,
                GPAGroup: [...state.GPAGroup.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default GPAGroupReducer;