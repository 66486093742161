import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { FormGroup, ButtonWrapperFilter, useStyles, ButtonWrapper, } from "../../../../styles/common-styles";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../components/Forms";
import AddFinancialAidStatusDetails from "./addFinancialAidStatusDetails";
import AddStudentMPN from "./addstudentmpn";
import AddEntranceExit from "./addentranceexitcounselling";
import AddCreditPlusInfo from "./addcreditplusinfo";
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import { useHistory } from "react-router-dom";
import AutomatonOptions from "./automationOption";
import AddAwardUsageLimits from "./addawardsusagelimits";
import { toastMessage } from '../../../../utils/Message/toasts';
import { getApi, deleteApi } from '../../../../services/apiBase';
import { status_gridList, mpn_gridList, counselings_gridList, creditapp_gridList, awardusage_gridList, informed_borrowing_gridList, delete_financialStatus_details, delete_financialStatus_mpn, delete_financialStatus_counseling, delete_financialStatus_CreditPlus, delete_award_usage_limit } from "../../../../services/apiURL";
import { updateAccessSelector } from "../../../../redux/common/roleAccess/selectors";
import { maintenance } from "../../../../helper/constants";

const initialValues = {
  "academicYear": -1
};

export const Status = ({ setStatusFyDropDown, addDatas, selectedSelected, onClose, selectedStatusRow, setSelectedStatusRow, setFyDropDown }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells']);
  //   const [showDynamicTable, setShowDynamicTable] = useState(false);
  const [showDynamicTablempn, setShowDynamicTablempn] = useState(true);
  const [showDynamicTableexit, setShowDynamicTableexit] = useState(false);
  const [showDynamicTableappinfo, setShowDynamicTableappinfo] = useState(false);
  const [showDynamicTableusagelimits, setShowDynamicTableusagelimits] = useState(false);
  const [showStudentMPN, setShowStudentMPN] = useState(false);
  const [showEntranceExit, setShowEntranceExit] = useState(false);
  const [showCreditPlusinfo, setShowCreditPlusinfo] = useState(false);
  const [showAwardUsagelimits, setShowAwardUsagelimits] = useState(false);
  const [statusRow, setStatusRow] = useState([]);
  const [CODRow, setCODRow] = useState([]);
  const [infoRow, setInfoRow] = useState([]);
  const [CreditRow, setCreditRow] = useState([]);
  const [AwardRow, setAwardRow] = useState([]);
  const [CounselingRow, setCounselingRow] = useState([]);


  const [Dropdown, setDropdown] = useState({
    "academicYear": [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCODRow, setIsLoadingCODRow] = useState(false);
  const [selectedCODRow, setSelectedCODRow] = useState('');
  const [selectedCounselingRow, setSelectedCounselingRow] = useState('');
  const [selectedCreditplusRow, setSelectedCreditplusRow] = useState('');
  // const [selectedAwardUsageRow,setSelectedAwardUsageRow]= useState('');
  const [filterArray, setFilterArray] = useState([]);


  const [financialStatus] = useState([
    { id: 'financialAidYear', numeric: true, disablePadding: false, label: t('financialStatus.financialAidYear', { ns: 'financialAidHeadcells' }) },
    { id: 'packagedforLoad', numeric: true, disablePadding: false, label: t('financialStatus.pakagedforLoad', { ns: 'financialAidHeadcells' }) },
    { id: 'directCostType', numeric: true, disablePadding: false, label: t('financialStatus.directCostType', { ns: 'financialAidHeadcells' }) },
    { id: 'packageStatus', numeric: true, disablePadding: false, label: t('financialStatus.packageStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'totalDirectCosts', toFixed: true, numeric: true, disablePadding: false, label: t('financialStatus.coa', { ns: 'financialAidHeadcells' }) },
    { id: 'remainingNeed', toFixed: true, numeric: true, disablePadding: false, label: t('financialStatus.remainingNeed', { ns: 'financialAidHeadcells' }) },
    { id: 'need', toFixed: true, numeric: true, disablePadding: false, label: t('financialStatus.need', { ns: 'financialAidHeadcells' }) },
    { id: 'verificationCode', numeric: true, disablePadding: false, label: t('financialStatus.verifyCode', { ns: 'financialAidHeadcells' }) },
    { id: 'packageAwardTotal', toFixed: true, numeric: true, disablePadding: false, label: t('financialStatus.packageAwardTotal', { ns: 'financialAidHeadcells' }) },
    { id: 'primaryEFC', toFixed: true, numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.primaryEFC', { ns: 'financialAidHeadcells' }) },
    { id: 'secondaryEFC', toFixed: true, numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.seconadryEFC', { ns: 'financialAidHeadcells' }) },
    { id: 'altPrimaryEFC', toFixed: true, numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.altPrimaryEFC', { ns: 'financialAidHeadcells' }) },
    { id: 'packageEnrollmentIntensity', numeric: true, disablePadding: false, label: t('financialStatus.packageEnrollmentIntensity', { ns: 'financialAidHeadcells' }) },
    // SAI grid fields
    { id: 'useSAI', numeric: true, textAlignR: true, disablePadding: false, label: t('Use SAI')},
    { id: 'sai', numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.SAI', {ns: 'financialAidHeadcells'})},
    { id: 'provisionalSAI', numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.provisionalSAI', {ns: "financialAidHeadcells"})},
    { id: 'saiFormula', numeric: true, disablePadding: false, label: t('financialStatus.saiFormula', {ns: "financialAidHeadcells"})},
    { id: 'dependencyModel', numeric: true, disablePadding: false, label: t('financialStatus.dependencyModel', {ns: "financialAidHeadcells"})},
    { id: 'studentConsent', numeric: true, disablePadding: false, label: t('financialStatus.studentConsAndSig', {ns: "financialAidHeadcells"})},
    { id: 'studentconsentDate', numeric: true, disablePadding: false, label: t('financialStatus.studentConsAndSigDate', {ns: "financialAidHeadcells"}), dateTime: true},
    { id: 'studentSpouseConsent', numeric: true, disablePadding: false, label: t('financialStatus.studentSpouseConsAndSig', {ns: "financialAidHeadcells"})},
    { id: 'studentSpouseConsentDate', numeric: true, disablePadding: false, label: t('financialStatus.studentSpouseConsAndSigDate', {ns: "financialAidHeadcells"}), dateTime: true},
    { id: 'parentConsent', numeric: true, disablePadding: false, label: t('financialStatus.parentConsAndSig', {ns: "financialAidHeadcells"})},
    { id: 'parentConsentDate', numeric: true, disablePadding: false, label: t('financialStatus.parentConsAndSigDate', {ns: "financialAidHeadcells"}), dateTime: true},
    { id: 'parentSpousePartnerConsent', numeric: true, disablePadding: false, label: t('financialStatus.parentSpousePartnerConsAndSig', {ns: "financialAidHeadcells"})},
    { id: 'parentSpousePartnerConsentDate', numeric: true, disablePadding: false, label: t('financialStatus.parentSpousePartnerConsAndSigDate', {ns: "financialAidHeadcells"}), dateTime: true},
    { id: 'automaticZeroEFC', numeric: true, disablePadding: false, label: t('financialStatus.automaticZeroEFC', { ns: 'financialAidHeadcells' }) },
    { id: 'bachelorsDegree', numeric: true, disablePadding: false, label: t('financialStatus.bachelorsDegree', { ns: 'financialAidHeadcells' }) },
    { id: 'enrolledGradProf', numeric: true, disablePadding: false, label: t('financialStatus.enrolledGradProf', { ns: 'financialAidHeadcells' }) },
    { id: 'fisapTotalIncome', toFixed: true, numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.fisapTotalIncome', { ns: 'financialAidHeadcells' }) },
    { id: 'dependentStatus', numeric: true, disablePadding: false, label: t('financialStatus.dependentStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'initialPackageDate', numeric: true, disablePadding: false, label: t('financialStatus.initialPackageDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'lastPackageDate', numeric: true, disablePadding: false, label: t('financialStatus.lastPackageDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'sendAwardLetter', numeric: true, disablePadding: false, label: t('financialStatus.sendAwardLetter', { ns: 'financialAidHeadcells' }) },
    { id: 'documentsComplete', numeric: true, disablePadding: false, label: t('financialStatus.documentsComplete', { ns: 'financialAidHeadcells' }) },
    { id: 'studentID', numeric: true, disablePadding: false, label: t('financialStatus.studentID', { ns: 'financialAidHeadcells' }) },
    { id: 'studentSSN', numeric: true, disablePadding: false, label: t('financialStatus.studentSSN', { ns: 'financialAidHeadcells' }) },
    { id: 'birthDate', numeric: true, disablePadding: false, label: t('financialStatus.birthDate', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYear', numeric: true, disablePadding: false, label: t('financialStatus.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYearUID', numeric: true, disablePadding: false, label: t('financialStatus.academicYearUID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialAidYearUID', numeric: true, disablePadding: false, label: t('financialStatus.financialAidYearUID', { ns: 'financialAidHeadcells' }) },
    { id: 'financialStatusID', numeric: true, disablePadding: false, label: t('financialStatus.financialStatusID', { ns: 'financialAidHeadcells' }) },
    { id: 'packageAsLoadUID', numeric: true, disablePadding: false, label: t('financialStatus.packageAsLoadUID', { ns: 'financialAidHeadcells' }) },
    { id: 'directCostTypeUID', numeric: true, disablePadding: false, label: t('financialStatus.directCostTypeUId', { ns: 'financialAidHeadcells' }) },
    { id: 'packageStatusUID', numeric: true, disablePadding: false, label: t('financialStatus.packagestatusUID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('financialStatus.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'studentUID', numeric: true, disablePadding: false, label: t('financialStatus.studentUID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('financialStatus.insertTime', { ns: 'financialAidHeadcells' }) },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('financialStatus.updateUserUID', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('financialStatus.updateTime', { ns: 'financialAidHeadcells' }) },
    { id: 'percentPrimaryEFC', toFixed: true, numeric: true, disablePadding: false, label: t('financialStatus.percentPrimaryEFC', { ns: 'financialAidHeadcells' }) },
    { id: 'packageDescription', numeric: true, disablePadding: false, label: t('financialStatus.packageDiscription', { ns: 'financialAidHeadcells' }) },
    { id: 'packageID', numeric: true, disablePadding: false, label: t('financialStatus.packageID', { ns: 'financialAidHeadcells' }) },
    { id: 'acadYearStartDT', numeric: true, disablePadding: false, label: t('financialStatus.acadYearStartDT', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'acadYearEndDT', numeric: true, disablePadding: false, label: t('financialStatus.acadYearEndDT', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'finAidYearStartDT', numeric: true, disablePadding: false, label: t('financialStatus.financialAidYearStartDT', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'finAidYearEndDT', numeric: true, disablePadding: false, label: t('financialStatus.financialAidYearEndDT', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'primaryBorrower', numeric: true, disablePadding: false, label: t('financialStatus.primaryBorrower', { ns: 'financialAidHeadcells' }) },
    { id: 'primaryBorrowerID', numeric: true, disablePadding: false, label: t('financialStatus.primaryBorrowerID', { ns: 'financialAidHeadcells' }) },
    { id: 'lockPackaging', numeric: true, disablePadding: false, label: t('financialStatus.lockPackaging', { ns: 'financialAidHeadcells' }) },
    { id: 'verificationStatus', numeric: true, disablePadding: false, label: t('financialStatus.verificationStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'resolved', numeric: true, disablePadding: false, label: t('financialStatus.resolved', { ns: 'financialAidHeadcells' }) },
    { id: 'note', numeric: true, disablePadding: false, label: t('financialStatus.note', { ns: 'financialAidHeadcells' }) },
    { id: 'acgEligibilityPaymentReasonCode', numeric: true, disablePadding: false, label: t('financialStatus.acgeEligibiltyPaymentReasonCode', { ns: 'financialAidHeadcells' }) },
    { id: 'rigorousHighschoolProgramCode', numeric: true, disablePadding: false, label: t('financialStatus.rigorousHighschoolProgramCode', { ns: 'financialAidHeadcells' }) },
    { id: 'borrowerDefaultOverPayCode', numeric: true, disablePadding: false, label: t('financialStatus.borrowerDefaultOverPayCode', { ns: 'financialAidHeadcells' }) },
    { id: 'studentDefaultOverPayCode', numeric: true, disablePadding: false, label: t('financialStatus.studentDefaultOverPayCode', { ns: 'financialAidHeadcells' }) },
    { id: 'verificationTrackingFlag', numeric: true, disablePadding: false, label: t('financialStatus.verificationTrackingFlag', { ns: 'financialAidHeadcells' }) },
    { id: 'nsldsPellLifetimeEligibilityUsed', numeric: true, disablePadding: false, label: t('financialStatus.nsldsPellLifetimeEligibiltyUsed', { ns: 'financialAidHeadcells' }) },
    { id: 'nsldsPellLifetimeLimit', numeric: true, disablePadding: false, label: t('financialStatus.nsldsPellLifetimeLimit', { ns: 'financialAidHeadcells' }) },
    { id: 'coA9Month', toFixed: true, currencyPipe: true, numeric: true, textAlignR: true, disablePadding: false, label: t('financialStatus.coa9Month', { ns: 'financialAidHeadcells' }) },

  ]);


  const [studentMPN] = useState([
    { id: 'id', numeric: true, disablePadding: false, label: t('studentMPN.id', { ns: 'financialAidHeadcells' }) },
    { id: 'ownerUID', numeric: true, disablePadding: false, label: t('studentMPN.ownerUID', { ns: 'financialAidHeadcells' }) },
    { id: 'mpnIndicator', numeric: true, disablePadding: false, label: t('studentMPN.mpnIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'mpnid', numeric: true, disablePadding: false, label: t('studentMPN.mpnID', { ns: 'financialAidHeadcells' }) },
    { id: 'mpnStatusCode', numeric: true, disablePadding: false, label: t('studentMPN.mpnStatusCode', { ns: 'financialAidHeadcells' }) },
    { id: 'mpnLinkIndicator', numeric: true, disablePadding: false, label: t('studentMPN.mpnLinkIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'mpnExpDate', numeric: true, disablePadding: false, label: t('studentMPN.mpnExpirationDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'codAwardType', numeric: true, disablePadding: false, label: t('studentMPN.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('studentMPN.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('studentMPN.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('studentMPN.updateUserId', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('studentMPN.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },

  ])
  const [studentCounselling] = useState([
    { id: 'id', numeric: true, disablePadding: false, label: t('studentCounselling.id', { ns: 'financialAidHeadcells' }) },
    { id: 'ownerUID', numeric: true, disablePadding: false, label: t('studentCounselling.ownerUID', { ns: 'financialAidHeadcells' }) },
    { id: 'counselingType', numeric: true, disablePadding: false, label: t('studentCounselling.counsellingType', { ns: 'financialAidHeadcells' }) },
    { id: 'csn', numeric: true, disablePadding: false, label: t('studentCounselling.csn', { ns: 'financialAidHeadcells' }) },
    { id: 'entranceCounseling', numeric: true, disablePadding: false, label: t('studentCounselling.counselingComplete', { ns: 'financialAidHeadcells' }) },
    { id: 'counselingCompletionDate', numeric: true, disablePadding: false, label: t('studentCounselling.counselingCompletionDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'counselingExpirationDate', numeric: true, disablePadding: false, label: t('studentCounselling.counselingExpirationDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'counselingException', numeric: true, disablePadding: false, label: t('studentCounselling.counsellingException', { ns: 'financialAidHeadcells' }) },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('studentCounselling.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('studentCounselling.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('studentCounselling.updateUserId', { ns: 'financialAidHeadcells' }) },
    { id: 'uPdateTime', numeric: true, disablePadding: false, label: t('studentCounselling.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },


  ])
  const [creditPlusAppInfo] = useState([
    { id: 'academicYear', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: 'contactLast', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.contactLast', { ns: 'financialAidHeadcells' }) },
    { id: 'contactFirst', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.contactFirst', { ns: 'financialAidHeadcells' }) },
    { id: 'contactMiddle', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.contactMiddle', { ns: 'financialAidHeadcells' }) },
    { id: 'creditDecisionStatus', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditDecisionStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'creditDecisionDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditDecisionDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'creditBalanceOption', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditBalanceOption', { ns: 'financialAidHeadcells' }) },
    { id: 'creditDecisionExpirationDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditDecisionExpirationDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'originalCreditDecisionStatus', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.originalCreditDecisionStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'creditAppealStatus', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditAppealStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'endorserAmount', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.endorserAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'schoolCreditBalanceOption', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.schoolCreditBalanceOption', { ns: 'financialAidHeadcells' }) },
    { id: 'creditActionChoice', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditActionChoice', { ns: 'financialAidHeadcells' }) },
    { id: 'creditOverrideCode', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditOverRideCode', { ns: 'financialAidHeadcells' }) },
    { id: 'applicationID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.applicationId', { ns: 'financialAidHeadcells' }) },
    { id: 'previousApplicationID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.previousApplicationId', { ns: 'financialAidHeadcells' }) },
    { id: 'unKnownLoanAmount', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.unknownLoanAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'sixMonthDefermentOption', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.sixMonthDefermentOption', { ns: 'financialAidHeadcells' }) },
    { id: 'maximumLoanIndicator', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.maximumLoanIndicator', { ns: 'financialAidHeadcells' }) },
    { id: 'defermentOption', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.defermentOption', { ns: 'financialAidHeadcells' }) },
    { id: 'applicationLoanAmount', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.applicationLoanAmount', { ns: 'financialAidHeadcells' }) },
    { id: 'applicationSubmissionReason', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.applicationSubmissionReason', { ns: 'financialAidHeadcells' }) },
    { id: 'applicationCompleteDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.applicationCompleteDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'awardRangeRequestedStartDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.awardRangeRequestedStartDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'awardRangeRequestedEndDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.awardRangeRequestedEndDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'insertUserID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: 'insertTime', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'updateUserID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.updateUserUID', { ns: 'financialAidHeadcells' }) },
    { id: 'updateTime', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'id', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.id', { ns: 'financialAidHeadcells' }) },
    { id: 'ownerID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.ownerID', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYearID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.academicYearID', { ns: 'financialAidHeadcells' }) },
    { id: 'academicYearStartingDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.academicYearStartingDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'academicYearEndingDate', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.academicYearEndingDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: 'creditActionStatus', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditActionStatus', { ns: 'financialAidHeadcells' }) },
    { id: 'reconsiderationEligible', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.reconsiderationEligible', { ns: 'financialAidHeadcells' }) },
    { id: 'creditRequirementsMet', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.creditRequirementMet', { ns: 'financialAidHeadcells' }) },
    { id: 'endorserApproved', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.endorserApproved', { ns: 'financialAidHeadcells' }) },
    { id: 'studentUID', numeric: true, disablePadding: false, label: t('creditPlusAppInfo.studentUID', { ns: 'financialAidHeadcells' }) },


  ]);

  const [awardUsageLimit] = useState([
    { id: "id", numeric: true, disablePadding: false, label: t('awardUsageLimit.id', { ns: 'financialAidHeadcells' }) },
    { id: "codAwardType", numeric: true, disablePadding: false, label: t('awardUsageLimit.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: "subsidizedUsageLimitApplies", numeric: true, disablePadding: false, label: t('awardUsageLimit.subsidizedUsageLimitApplies', { ns: 'financialAidHeadcells' }) },
    { id: "sumActualSubUsagePeriods", numeric: true, disablePadding: false, label: t('awardUsageLimit.sumActualSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "sumActualNonCredTeacherCertSubUsagePeriods", numeric: true, disablePadding: false, label: t('awardUsageLimit.sumActualNonCreditTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "calculatedPublishedPgmLengthYears", numeric: true, disablePadding: false, label: t('awardUsageLimit.calculatedPublishedPgmLengthYears', { ns: 'financialAidHeadcells' }) },
    { id: "anticipatedSubUsagePeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.anticipatedSubUsagePereiod', { ns: 'financialAidHeadcells' }) },
    { id: "actualSubUsagePeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.actualSubUsagePeriod', { ns: 'financialAidHeadcells' }) },
    { id: "academicYearLength", numeric: true, disablePadding: false, label: t('awardUsageLimit.academicYearLength', { ns: 'financialAidHeadcells' }) },
    { id: "loanPeriodLength", numeric: true, disablePadding: false, label: t('awardUsageLimit.loanPeriodLength', { ns: 'financialAidHeadcells' }) },
    { id: "maxSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.maxSubEligibiltyPeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remainingActualSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.remainingActualSubEligibiltyPeriod', { ns: 'financialAidHeadcells' }) },
    { id: "remainingAnticipatedSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.remainingAnticipatedSubEligibiltyPeriod', { ns: 'financialAidHeadcells' }) },
    { id: "sumAnticipatedSubUsagePeriods", numeric: true, disablePadding: false, label: t('awardUsageLimit.sumAnticipatedSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "anticipatedNonCredTeacherCertSubUsagePeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.anticipatedNonCredTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "actualNonCredTeacherCertSubUsagePeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.actualNonCredTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "remainingActualNonCredTeacherCertSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.remainingActualNonCredTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "remainingAnticipatedNonCredTeacherCertSubEligibilityPeriod", numeric: true, disablePadding: false, label: t('awardUsageLimit.remainingAnticipatedNonCredTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "sumAnticipatedNonCredTeacherCertSubUsagePeriods", numeric: true, disablePadding: false, label: t('awardUsageLimit.sumAnticipatedNonCredTeacherCertSubUsagePereiods', { ns: 'financialAidHeadcells' }) },
    { id: "insertUserID", numeric: true, disablePadding: false, label: t('awardUsageLimit.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: "insertTime", numeric: true, disablePadding: false, label: t('awardUsageLimit.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: "updateUserID", numeric: true, disablePadding: false, label: t('awardUsageLimit.updateUserUID', { ns: 'financialAidHeadcells' }) },
    { id: "updateTime", numeric: true, disablePadding: false, label: t('awardUsageLimit.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },

  ]);

  const [informedBorrowingInfo] = useState([
    { id: "academicYear", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.academicYear', { ns: 'financialAidHeadcells' }) },
    { id: "informedBorrowingComplete", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.informedBorrowingComplete', { ns: 'financialAidHeadcells' }) },
    { id: "informedBorrowingCompletionDate", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.informedBorrowingCompletionDate', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: "informedBorrowingID", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.informedBorrowingId', { ns: 'financialAidHeadcells' }) },
    { id: "codAwardType", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.codAwardType', { ns: 'financialAidHeadcells' }) },
    { id: "insertUserID", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.insertUserID', { ns: 'financialAidHeadcells' }) },
    { id: "insertTime", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.insertTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: "updateUserID", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.updateUserUID', { ns: 'financialAidHeadcells' }) },
    { id: "updateTime", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.updateTime', { ns: 'financialAidHeadcells' }), dateTime: true },
    { id: "academicYearID", numeric: true, disablePadding: false, label: t('informedBorrowingInfo.academicYearID', { ns: 'financialAidHeadcells' }) },

  ]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => { },
  });
  let history = useHistory();
  const classes = useStyles();
  const [showAddData, setShowAddData] = useState(false);
  const [showAddTransDoc, setShowAddTransDoc] = useState(false);
  const [showAutomationOption, setShowAutomationOption] = useState(false);
  const [selectedAwardRow, setSelectedAwardRow] = useState(false);

  const onCloseFinanacialStatus = (value) => {
    document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    setShowAddData(false);
    setShowAddTransDoc(false)
    setShowStudentMPN(false)
    setShowAutomationOption(false)
    setShowEntranceExit(false)
    setShowCreditPlusinfo(false)
    setShowAwardUsagelimits(false)
    addDatas(false)
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
    // DefaultTable.resetTable("statusTable")
    getCreditData();
    getCounselingData();
    getAwardUsageData();
    getInfoData();
    getFinancialData();
    getData();
    // setSelectedStatusRow('')
    formik.setFieldValue("academicYear", -1);

  }

  const handleStatusRowEdit = () => {
    addDatas(true);
    setShowAddTransDoc(true)
    setShowAddData(true);
  }

  const handleStatusRowDelete = () => {
    setIsLoading(true)
    deleteApi(`${delete_financialStatus_details}/${selectedStatusRow.financialStatusID}`).then((res) => {
      if (res.data.isSuccess) {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'))
        getFinancialData()
        DefaultTable.resetTable("statusTable")
      }
    }).catch((err) => {
      console.log(err)
      setIsLoading(false)
    })
  }

  const handleDeleteMPN = () => {
    if (selectedCODRow.id) {
      deleteApi(`${delete_financialStatus_mpn}/${selectedCODRow.id}`).then(() => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        getData();
        DefaultTable.resetTable("mpnTable")
      }).catch(err => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }

  const handleDeleteCounseling = () => {
    if (selectedCounselingRow.id) {
      deleteApi(`${delete_financialStatus_counseling}/${selectedCounselingRow.id}`).then(() => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
        getCounselingData();
        DefaultTable.resetTable("counselingTable")
      }).catch(err => {
        console.log(err)
      })
    }
  }

  const handleDeleteCreditPlus = () => {
    deleteApi(`${delete_financialStatus_CreditPlus}/${selectedCreditplusRow.id}`).then(() => {
      toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
      getCreditData();
      DefaultTable.resetTable("creditTable")
    }).catch(err => {
      console.log(err)
    })
  }

  const handleDeleteAwardUsageLimits = () => {
    deleteApi(`${delete_award_usage_limit}/${selectedFinStatusID.id}`).then(() => {
      toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
      getAwardUsageData();
      DefaultTable.resetTable("awardUsageTable")
    }).catch(err => {
      console.log(err)
    })
  }


  const studentMpin = (value) => {
    setShowDynamicTablempn(true);
    setShowDynamicTableexit(false);
    setShowDynamicTableappinfo(false);
    setShowDynamicTableusagelimits(false);
    // setShowStudentMPN(true);
    getData();
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
  };

  const entranceExitCounselling = (value) => {
    setShowDynamicTablempn(false);
    setShowDynamicTableexit(true);
    setShowDynamicTableappinfo(false);
    setShowDynamicTableusagelimits(false);
    getCounselingData();
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
  };

  const creditplusappinfo = (value) => {
    setShowDynamicTablempn(false);
    setShowDynamicTableexit(false)
    setShowDynamicTableappinfo(true);
    setShowDynamicTableusagelimits(false);
    // setShowCreditPlusinfo(true);
    getCreditData();
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
  };

  const awardsusagelimits = (value) => {
    setShowDynamicTablempn(false);
    setShowDynamicTableexit(false);
    setShowDynamicTableappinfo(false);
    setShowDynamicTableusagelimits(true);
    getAwardUsageData();
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
  };

  const informedborrowinginfo = (value) => {
    setShowDynamicTablempn(false);
    setShowDynamicTableexit(false)
    setShowDynamicTableappinfo(false);
    setShowDynamicTableusagelimits(false);
    getInfoData();
    DefaultTable.resetTable("mpnTable")
    DefaultTable.resetTable("counselingTable")
    DefaultTable.resetTable("creditTable")
    DefaultTable.resetTable("awardUsageTable")
  };

  const handleSelectedRow = (value) => {
    setSelectedStatusRow(value);
  }

  const [selectedFinStatusID, setselectedFinStatusID] = useState('');

  const handleAwardSelectedRow = (value) => {
    setSelectedAwardRow(value);
    setselectedFinStatusID(value);
    // DefaultTable.resetTable('awardUsageTable')

  }
  const handleAwardEditRow = (value) => {
    setShowAddData(true)
    addDatas(true)
    setShowAwardUsagelimits(value)
  }

  const handleRowSelect = (value) => {
    setSelectedCODRow(value);
  }
  const handleExtraCounselingRowSelect = (value) => {
    setSelectedCounselingRow(value);
  }

  const handleCreditplusRowSelect = (value) => {
    setSelectedCreditplusRow(value);
  }

  // const handleAwardUsageRowSelect = (value)=> {
  //   setSelectedAwardUsageRow(value);
  // }


  const handleEditRow = () => {
    setShowAddData(true)
    setShowStudentMPN(true)
    addDatas(true);
  }

  const handleExtraCounselingEditRow = () => {
    setShowAddData(true)
    setShowEntranceExit(true)
    addDatas(true);
  }

  const handleCreditplusEditRow = () => {
    setShowAddData(true)
    setShowCreditPlusinfo(true)
    addDatas(true);
  }

  useEffect(() => {
    getFinancialData();
    getCreditData();
    getCounselingData();
    getAwardUsageData();
    getData();
    // setSelectedStatusRow('')
  }, [selectedSelected.studentUID])

  useEffect(() => {
    if (selectedStatusRow) {
      getAwardUsageData()
    }
  }, [selectedStatusRow])


  const getFinancialData = () => {
    if (selectedSelected.studentUID) {
      setIsLoading(true);
      getApi(`${status_gridList}/${selectedSelected.studentUID}`).then((resp) => {
        const { statuses, academicYears } = resp.data.data;
        setStatusRow(statuses)
        setFilterArray(statuses)
        setDropdown({
          "academicYear": academicYears
        })
        setIsLoading(false);
        // DefaultTable.resetTable("statusTable")
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
    }
  }

  const getCreditData = () => {
    if (selectedSelected.studentUID) {
      setIsLoadingCODRow(true);
      getApi(`${creditapp_gridList}/${selectedSelected.studentUID}`).then((resp) => {
        setCreditRow(resp.data.data)
        setIsLoadingCODRow(false);
      }).catch((err) => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }

  const getCounselingData = () => {
    if (selectedSelected.studentUID) {
      setIsLoadingCODRow(true);
      getApi(`${counselings_gridList}/${selectedSelected.studentUID}`).then((resp) => {
        setCounselingRow(resp.data.data)
        setIsLoadingCODRow(false);
      }).catch((err) => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }
  const getData = () => {
    if (selectedSelected.studentUID) {
      setIsLoadingCODRow(true);
      getApi(`${mpn_gridList}/${selectedSelected.studentUID}`).then((resp) => {
        setCODRow(resp.data.data)
        setIsLoadingCODRow(false);
      }).catch((err) => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }

  const getAwardUsageData = () => {
    if (selectedStatusRow.financialStatusID) {
      setIsLoadingCODRow(true);
      getApi(`${awardusage_gridList}/${selectedStatusRow.financialStatusID}`).then((resp) => {
        setAwardRow(resp.data.data)
        setIsLoadingCODRow(false);
      }).catch((err) => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }

  const getInfoData = () => {
    if (selectedSelected.studentUID) {
      setIsLoadingCODRow(true);
      getApi(`${informed_borrowing_gridList}/${selectedSelected.studentUID}`).then((resp) => {
        setInfoRow(resp.data.data)
        setIsLoadingCODRow(false);
      }).catch((err) => {
        console.log(err);
        setIsLoadingCODRow(false);
      })
    }
  }

  const handleAcademicYearChange = (e) => {
    setIsLoading(true);
    formik.handleChange(e);
    let academicYearID = e.target.value;
    if (academicYearID !== -1) {
      let academicYearArray = Dropdown.academicYear.filter(v =>
        Number(v.uniqueId) === Number(academicYearID)
      )
      let academicYearFilterData = filterArray.filter(v =>
        (v.academicYear) === academicYearArray[0].displayText
      )
      if (academicYearFilterData) {
        setStatusRow(academicYearFilterData);
        setIsLoading(false);
        let fyArray = []
        for (var i = 0; i < academicYearFilterData.length; i++) {
          let fyObj = {};
          fyObj = {
            "uniqueId": academicYearFilterData[i].financialAidYearUID ? academicYearFilterData[i].financialAidYearUID : 0,
            "displayText": academicYearFilterData[i].financialAidYear ? academicYearFilterData[i].financialAidYear : 0,
            "isSelected": false
          }
          fyArray.push(fyObj)
        }
        let fyObj2 = {};
        fyObj2 = {
          "uniqueId": -1,
          "displayText": "All",
          "isSelected": false
        }
        fyArray.push(fyObj2)
        setFyDropDown(fyArray)

        //Status Dropdown
        let fyArrayAllStatus = []
        for (var i = 0; i < academicYearFilterData.length; i++) {
          let fyObj1 = {};
          fyObj1 = {
            "uniqueId": academicYearFilterData[i] ? academicYearFilterData[i].financialStatusID : 0,
            "displayText": academicYearFilterData[i].financialAidYear ? academicYearFilterData[i].financialAidYear : 0,
            "isSelected": false
          }
          fyArrayAllStatus.push(fyObj1)
        }
        let fyObjStatus = {};
        fyObjStatus = {
          "uniqueId": -1,
          "displayText": "All",
          "isSelected": false
        }
        fyArrayAllStatus.push(fyObjStatus)
        setStatusFyDropDown(fyArrayAllStatus)
      }
      else {
        setStatusRow([]);
        setIsLoading(false);
      }
    }
    else {
      let fyArrayAll = []
      for (var i = 0; i < filterArray.length; i++) {
        let fyObj1 = {};
        fyObj1 = {
          "uniqueId": (filterArray) ? filterArray[i].financialAidYearUID : 0,
          "displayText": (filterArray) ? filterArray[i].financialAidYear : 0,
          "isSelected": false
        }
        fyArrayAll.push(fyObj1)
      }
      let fyObj2 = {};
      fyObj2 = {
        "uniqueId": -1,
        "displayText": "All",
        "isSelected": false
      }
      fyArrayAll.push(fyObj2)
      setFyDropDown(fyArrayAll)
      setStatusRow(filterArray);
      setIsLoading(false);

      //Status Dropdown
      let fyArrayAllStatus = []
      for (var i = 0; i < filterArray.length; i++) {
        let fyObj1 = {};
        fyObj1 = {
          "uniqueId": (filterArray) ? filterArray[i].financialStatusID : 0,
          "displayText": (filterArray) ? filterArray[i].financialAidYear : 0,
          "isSelected": false
        }
        fyArrayAllStatus.push(fyObj1)
      }
      let fyObjStatus = {};
      fyObjStatus = {
        "uniqueId": -1,
        "displayText": "All",
        "isSelected": false
      }
      fyArrayAllStatus.push(fyObjStatus)
      setStatusFyDropDown(fyArrayAllStatus)
    }
  }

  useEffect(() => {
    let fyArrayAll = []
    for (var i = 0; i < filterArray.length; i++) {
      let fyObj1 = {};
      fyObj1 = {
        "uniqueId": (filterArray) ? filterArray[i].financialAidYearUID : 0,
        "displayText": (filterArray) ? filterArray[i].financialAidYear : 0,
        "isSelected": false
      }
      fyArrayAll.push(fyObj1)
    }
    let fyObj2 = {};
    fyObj2 = {
      "uniqueId": -1,
      "displayText": "All",
      "isSelected": false
    }
    fyArrayAll.push(fyObj2)
    setFyDropDown(fyArrayAll)

    //Status Dropdown
    let fyArrayAllStatus = []
    for (var i = 0; i < filterArray.length; i++) {
      let fyObj1 = {};
      fyObj1 = {
        "uniqueId": (filterArray) ? filterArray[i].financialStatusID : 0,
        "displayText": (filterArray) ? filterArray[i].financialAidYear : 0,
        "isSelected": false
      }
      fyArrayAllStatus.push(fyObj1)
    }
    let fyObjStatus = {};
    fyObjStatus = {
      "uniqueId": -1,
      "displayText": "All",
      "isSelected": false
    }
    fyArrayAllStatus.push(fyObjStatus)
    setStatusFyDropDown(fyArrayAllStatus)
  }, [filterArray])

  // Checking Change prospect access starts here
  // Role based access control redux
  const { access } = useSelector(updateAccessSelector)
  const { nestedParentLevel } = access;

  const [statusAccess, setStatusAccess] = useState({
    maintenance: 2,
  })
  useEffect(() => {


    let finStatusAccess = nestedParentLevel.filter((v, i) =>
      v.menuItemName.trim() === maintenance && v.moduleorder === 4
    )

    setStatusAccess({
      ...statusAccess,
      maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
    })

  }, [access])
  const [accessFlag, setAccessFlag] = useState(false)
  useEffect(() => {
    (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
  }, [statusAccess])
  //Checking Change prospect access Ends here


  return (

    <>
      {!showAddData ?

        <>
          <form onSubmit={formik.handleSubmit} className={classes.root}>
            <Card>

              <CardContent className={"headerTitle"}>
                <FormGroup>
                  <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.financialStatus", { ns: "financialAid" })} />
                  <Grid container className={"jc"}>
                    <ButtonWrapper className="ButtonDistribute">
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={accessFlag}
                        onClick={() => {
                          setShowAddData(true);
                          setShowAddTransDoc(true)
                          addDatas(true)
                          setSelectedStatusRow('');
                        }}>
                        <span className="plus">+</span>{" "}
                        {t("buttonLabel.add", { ns: "financialAid" })}
                      </Button>
                    </ButtonWrapper>
                  </Grid>
                </FormGroup>
                <FormGroup>
                  <SelectInput
                    label={t("commonLabel.academicYear", { ns: "financialAid" })}
                    labelId={"academicYear"}
                    formikValue={formik.values.academicYear}
                    formikChange={handleAcademicYearChange}
                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.academicYear }}
                  />
                </FormGroup>
                <DefaultTable
                  trackingLabel={"statusTable"}
                  headCells={financialStatus}
                  rows={statusRow}
                  isLoading={isLoading}
                  pagination={true}
                  onRowSelect={handleSelectedRow}
                  onEdit={handleStatusRowEdit}
                  onDelete={handleStatusRowDelete}
                  deleteDisable={accessFlag}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent className={"headerTitle"}>
                <FormGroup>
                  <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.cod", { ns: "financialAid" })} />
                  <Grid container className={"jc"}>
                    <CardContent>
                      <ButtonWrapper className="ButtonDistribute">
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="button"
                          onClick={studentMpin}
                        >
                          {t("commonLabel.studentMPN", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="button"
                          onClick={entranceExitCounselling} >
                          {t("buttonLabel.viewEntranceExitCounseling", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="button"
                          onClick={creditplusappinfo}
                        >
                          {t("buttonLabel.viewCreditPlusAppInfo", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="button"
                          onClick={awardsusagelimits}
                          disabled={statusRow.length == 0 ? true : false}
                        >
                          {t("buttonLabel.awardUsageLimits", { ns: "financialAid" })}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="button"
                          onClick={informedborrowinginfo}
                        >
                          {t("buttonLabel.informedBorrowingInformation", { ns: "financialAid" })}
                        </Button>
                      </ButtonWrapper>
                    </CardContent>
                  </Grid>
                </FormGroup>
                {(showDynamicTablempn) ? (
                  <CardContent>
                    <FormGroup>
                      <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.studentmPN", { ns: "financialAid" })}
                      />
                      <Grid container className={"jc"}>
                        <ButtonWrapper className="ButtonDistribute">
                          <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={accessFlag}
                            onClick={() => {
                              setShowAddData(true);
                              setShowStudentMPN(true);
                              setShowEntranceExit(false);
                              setShowCreditPlusinfo(false);
                              setShowAwardUsagelimits(false);
                              setShowAddTransDoc(false);
                              setShowAutomationOption(false)
                              addDatas(true);
                              setSelectedCODRow('');
                            }}
                          >
                            <span className="plus">+</span>{" "}
                            {t("buttonLabel.add", { ns: "financialAid" })}

                          </Button>
                        </ButtonWrapper>
                      </Grid>
                    </FormGroup>
                  </CardContent>
                )
                  :
                  (showDynamicTableexit) ? (
                    <CardContent>
                      <FormGroup>
                        <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.studentCounseling", { ns: "financialAid" })}
                        />
                        <Grid container className={"jc"}>
                          <ButtonWrapper className="ButtonDistribute">
                            <Button
                              color="primary"
                              variant="contained"
                              size="large"
                              type="submit"
                              disabled={accessFlag}
                              onClick={() => {
                                setShowAddData(true);
                                setShowEntranceExit(true);
                                setShowStudentMPN(false);
                                setShowAddTransDoc(false);
                                setShowCreditPlusinfo(false);
                                setShowAwardUsagelimits(false);
                                addDatas(true);
                                setSelectedCounselingRow('')
                              }}
                            >
                              <span className="plus">+</span>{" "}
                              {t("buttonLabel.add", { ns: "financialAid" })}
                            </Button>
                          </ButtonWrapper>
                        </Grid>
                      </FormGroup>
                    </CardContent>
                  )
                    :
                    (showDynamicTableappinfo) ? (
                      <CardContent>
                        <FormGroup>
                          <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.creditPlusApp", { ns: "financialAid" })}
                          />
                          <Grid container className={"jc"}>
                            <ButtonWrapper className="ButtonDistribute">
                              <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={accessFlag}
                                onClick={() => {
                                  setShowAddData(true);
                                  setShowAddTransDoc(false);
                                  setShowAutomationOption(false)
                                  setShowStudentMPN(false);
                                  setShowEntranceExit(false);
                                  setShowCreditPlusinfo(true);
                                  setShowAwardUsagelimits(false);
                                  addDatas(true);
                                  setSelectedCreditplusRow('')
                                }}
                              >
                                <span className="plus">+</span>{" "}
                                {t("buttonLabel.add", { ns: "financialAid" })}
                              </Button>
                            </ButtonWrapper>
                          </Grid>
                        </FormGroup>
                      </CardContent>
                    )
                      :
                      (showDynamicTableusagelimits) ? (
                        <CardContent>
                          <FormGroup>
                            <HeaderTitle variant={'subHeading'} headerTitle={t("buttonLabel.awardUsageLimits", { ns: "financialAid" })}
                            />
                            <Grid container className={"jc"}>
                              <ButtonWrapper className="ButtonDistribute">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="large"
                                  type="submit"
                                  onClick={() => {
                                    setShowAddData(true);
                                    setShowStudentMPN(false);
                                    setShowEntranceExit(false);
                                    setShowCreditPlusinfo(false);
                                    setShowAwardUsagelimits(true);
                                    setShowAddTransDoc(false);
                                    setShowAutomationOption(false)
                                    addDatas(true);
                                    setSelectedAwardRow('')
                                  }}
                                  disabled={(accessFlag) || (selectedStatusRow === "")}
                                >
                                  <span className="plus">+</span>{" "}
                                  {t("buttonLabel.add", { ns: "financialAid" })}
                                </Button>
                              </ButtonWrapper>
                            </Grid>
                          </FormGroup>
                        </CardContent>
                      )
                        : (
                          <CardContent>
                            <HeaderTitle variant={'subHeading'} headerTitle={t("buttonLabel.informedBorrowingInformationView", { ns: "financialAid" })}
                            />
                          </CardContent>
                        )}

                {(showDynamicTablempn) ? (
                  <DefaultTable
                    trackingLabel={"mpnTable"}
                    headCells={studentMPN}
                    rows={CODRow}
                    isLoading={isLoadingCODRow}
                    pagination={true}
                    onRowSelect={handleRowSelect}
                    onEdit={handleEditRow}
                    onDelete={handleDeleteMPN}
                    deleteDisable={accessFlag}
                  />
                ) :
                  (showDynamicTableexit) ? (

                    <DefaultTable
                      trackingLabel={"counselingTable"}
                      headCells={studentCounselling}
                      rows={CounselingRow}
                      isLoading={isLoadingCODRow}
                      pagination={true}
                      onRowSelect={handleExtraCounselingRowSelect}
                      onEdit={handleExtraCounselingEditRow}
                      onDelete={handleDeleteCounseling}
                      deleteDisable={accessFlag}
                    />
                  ) :

                    (showDynamicTableappinfo) ? (
                      <DefaultTable
                        trackingLabel={"creditTable"}
                        headCells={creditPlusAppInfo}
                        rows={CreditRow}
                        pagination={true}
                        onRowSelect={handleCreditplusRowSelect}
                        onEdit={handleCreditplusEditRow}
                        onDelete={handleDeleteCreditPlus}
                        deleteDisable={accessFlag}
                      />
                    )
                      : (showDynamicTableusagelimits) ? (

                        <DefaultTable
                          trackingLabel={"awardUsageTable"}
                          headCells={awardUsageLimit}
                          rows={AwardRow}
                          pagination={true}
                          onRowSelect={handleAwardSelectedRow}
                          onDelete={handleDeleteAwardUsageLimits}
                          onEdit={handleAwardEditRow}
                          deleteDisable={accessFlag}
                        />

                      ) : (

                        <DefaultTable
                          trackingLabel={"infoTable"}
                          headCells={informedBorrowingInfo}
                          rows={infoRow}
                          pagination={true}
                        />
                      )}

              </CardContent>
            </Card>
            <ButtonWrapperFilter>
              <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={() => { dispatch(getSelectedProfiles({})); history.push('/financial-aid/student-search'); }}>
                {t('buttonLabel.cancel')}
              </Button>
            </ButtonWrapperFilter>
          </form>
        </>
        : (showStudentMPN) ? <AddStudentMPN accessFlag={accessFlag} addDatas={addDatas} onClose={onCloseFinanacialStatus} selectedCODRow={selectedCODRow} selectedSelected={selectedSelected} />
          : (showAddTransDoc) ? <AddFinancialAidStatusDetails addDatas={addDatas} onClose={onCloseFinanacialStatus} selectedStatusRow={selectedStatusRow} selectedSelected={selectedSelected} />
            : (showAutomationOption) ? <AutomatonOptions addDatas={addDatas} onClose={onCloseFinanacialStatus} />
              : (showEntranceExit) ? <AddEntranceExit accessFlag={accessFlag} addDatas={addDatas} onClose={onCloseFinanacialStatus} selectedCounselingRow={selectedCounselingRow} selectedSelected={selectedSelected} />
                : (showCreditPlusinfo) ? <AddCreditPlusInfo accessFlag={accessFlag} addDatas={addDatas} onClose={onCloseFinanacialStatus} selectedCreditplusRow={selectedCreditplusRow} selectedSelected={selectedSelected} />
                  : (showAwardUsagelimits) && <AddAwardUsageLimits accessFlag={accessFlag} selectedFinStatusID={selectedFinStatusID} addDatas={addDatas} onClose={onCloseFinanacialStatus} selectedStatusRow={selectedStatusRow} selectedAwardRow={selectedAwardRow}
                  />}
    </>
  );
};
