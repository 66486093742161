import { GET_BUDGET } from './types';

const initialState = {
    academicYear: -1,
    financialAidYear: null,
    budgetData:[]
}

const budgetReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_BUDGET:
            return payload
       
        default:
            return state;
    }
}

export default budgetReducer;

