export const GET_ACADYEAR = 'GET_ACADYEAR';
export const GET_ACADYEAR_LIMIT = 'GET_ACADYEAR_LIMIT';
export const GET_PKG_LIMIT = 'GET_PKG_LIMIT';
export const GET_PKG_TERM = 'GET_PKG_TERM';
export const GET_SELECTED_ACADYEAR = 'GET_SELECTED_ACADYEAR';
export const GET_ACADEMIC_YEAR_ID = 'GET_ACADEMIC_YEAR_ID';
export const GET_FINANCIAL_AID_YEAR_LIMIT = 'GET_FINANCIAL_AID_YEAR_LIMIT';
export const GET_OVER_TERM = 'GET_OVER_TERM';
export const GET_TERM_LIMIT = 'GET_TERM_LIMIT';
export const GET_SELECTED_FA_YEAR = 'GET_SELECTED_FA_YEAR';
export const GET_BUDGET = 'GET_BUDGET';
export const GET_ISIRCC = 'GET_ISIRCC';




