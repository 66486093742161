import { UPDATE_COUNTIES, UPDATE_COUNTIES_CODE, UPDATE_COUNTY_VALUES, VERIFY_CODE_TYPE } from './types'

const initialState = {
    countiesRow: [],
    countiesCodesRow: [],
    countyValues: {
        "countryId": 0,
        "stateId": 0,
        "county": ''
    },
    codeTypeMessage: 'Please enter valid Code.Please check the minlength and maxlength of Code'
};
const locationLookupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_COUNTIES:
            return {
                ...state,
                countiesRow: [...payload]
            };
        case UPDATE_COUNTIES_CODE:
            return {
                ...state,
                countiesCodesRow: [...payload]
            };
        case UPDATE_COUNTY_VALUES:
            return {
                ...state,
                countyValues: { ...payload }
            };
        case VERIFY_CODE_TYPE:
            return {
                ...state,
                codeTypeMessage: payload 
            };
        default:
            return state;
    }
}
export default locationLookupReducer