import React from 'react';
import { Box } from "@material-ui/core"
const InfoMessage = ({ label,width,children }) => {
    return (
        <>
            <Box
                component="div" sx={{ p: 2, m: 0.8, border: "2px solid #3DB5D2 ", boxShadow: "0px 2px lightgrey", display: "flex", width:width? width: '90%' }}>
                <span style={{ color: "#3DB5D2", letterSpacing: "10px" }} className="material-icons-outlined" >
                    info
                </span>
                <span style={{ color: "#3DB5D2", p: 2, m: 2 }}>
                    {label}
                    {children && children}
                </span>
            </Box>
        </>
    )
}
export default InfoMessage;