import { UPDATE_FORM_STATUS, UPDATE_RECORD } from './types'

const initialState = {
    formValues: {
        calenderYearToReport: "",
        filerAccountNumber: "",
        schoolType: 1,
        firstTermInCalenderYear: 0,
        lastTermInCalenderYear: 0,
        reportedBy: "Standard",
        displayFilerAccountNumber: "",
        schoolTypelabel: 'University',
        FlyingSchooorClub: 1,
        FlyingSchooorClubLabel:''
    },
    record: []
};

const updateT2202FormStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_FORM_STATUS:
            return {
                ...state,
                formValues: payload
            };
        case UPDATE_RECORD:
            return {
                ...state,
                record: payload
            };
        default:
            return state;
    }
};

export default updateT2202FormStatusReducer;