
import React, { useEffect, useState, useRef } from 'react';
import { alpha } from '@mui/material/styles';
import { Checkbox, Radio, Typography, Paper, Button, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Pagination } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {  makeStyles } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { useTableStyles } from '../../styles/common-styles'
import CustomizedDialogs from '../../utils/Popup/popup';
import styled from 'styled-components';
import BrandingColors from '../../styles/color';
import CustomLoader from '../../components/Loader/Loader';
import { formatDate, formatTime, formatTimeOnly, formatTimeWithoutSeconds } from '../../helper';
import { formatPhoneNumber, getPhoneMaskPattern, phoneInputMask } from '../../helper/formatePhone';
import GenericModal from '../../components/Modal/GenericModal'
import { deletePopup } from '../Message/messagePopup';
import { searchProspectsSelector } from '../../redux/admisssion/prospects/search-prospects/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatSSN } from '../../helper/formatSSN';
import { formatToFixed, formatToFixed3 } from '../../helper/formatToFixed';
import { formatToRoundoff } from '../../helper/formatToRoundoff';
import { updateTableSelector } from '../../redux/common/tableTracker/selectors';
import { updatePagination, updateTable } from '../../redux/common/tableTracker/action';
import currencyPipe from '../../helper/currency';
import { updateUserSelector } from '../../redux/common/login/selectors';
import { TextInput } from '../../components/Forms';
import { useFormik } from 'formik';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { RiceBowl } from '@mui/icons-material';
import { Opacity } from '@material-ui/icons';

const ButtonWrap = styled.div`
   & button{
    width: 100px;
    padding: 6px;
    text-transform: capitalize;
    color:${BrandingColors.yellowOrange};
    :first-child{
        color:${BrandingColors.white};
        background: ${BrandingColors.yellowOrange};
        :hover{
            background: ${BrandingColors.yellowOrange}; 
        }
    }
    }
  }
`

const useStyles = makeStyles({
    disabledRow:{
        backgroundColor: '#f0f0f0',
        color:'#a0a0a0',
        pointerEvents:'none',
        opacity:0.6
    }
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator, order, orderBy, dateFlag) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    if (orderBy !== 'updateTime' && !dateFlag) {
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    } else {
        let dateA, dateB;
        const distantFuture = new Date(8640000000000000);
        return array.map((el) => el).sort((a, b) => {
            dateA = a[orderBy] ? new Date(a[orderBy]) : distantFuture
            dateB = b[orderBy] ? new Date(b[orderBy]) : distantFuture

            if (order === 'desc') {
                if (b[orderBy] === null) return -1;
                if (a[orderBy] === null) return 1;
                return dateB.getTime() - dateA.getTime()
            } else {
                if (a[orderBy] === null) return -1;
                if (b[orderBy] === null) return 1;
                return dateA.getTime() - dateB.getTime()
            }
        })
    }
}

function EnhancedTableHead(props) {
    const { onSelectAllClick,trackingLabel, sortingFlag, rowsPerPage, headCells, order, orderBy, numSelected, rowCount, onRequestSort, isLookup, actionFlag, multiSelect, selectAll, uploadAction, isSnLabel, snLableValue, uploadTitle ,cbActionFlag} =
        props;
    const createSortHandler = (property, date, dateTime) => (event) => {
        onRequestSort(event, property, date, dateTime);
    };
    const { t } = useTranslation();
    const sortingFlags = (sortingFlag === false) ? false : true
    return (
        <TableHead>
            <TableRow>
                {!isLookup && <TableCell padding={isSnLabel ? 'checkbox' : 'normal'} >
                    {isSnLabel && <strong style={{ whiteSpace: 'nowrap', paddingRight: (trackingLabel === 'studentsearchDel1') ? '20px' :'0'}}>{snLableValue}</strong>}
                </TableCell>}
                {/* <TableCell>
                    <strong>{'S.NO'}</strong>
                </TableCell> */}
                {uploadAction && <TableCell  >
                    {<strong style={{ whiteSpace: 'nowrap', margin: "10px" }}>{uploadTitle ? uploadTitle : "Action"}</strong>}
                </TableCell>}
                {headCells.map((headCell) => (
                    // <Resizeable
                    //     key={headCell.id}
                    //     padding={headCell.disablePadding ? 'none' : 'normal'}
                    //     sortDirection={orderBy === headCell.id ? order : false}
                    //     height={'100vh'}
                    // >

                    (sortingFlags) ?
                        <TableCell
                            key={headCell.id}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                // active={orderBy === headCell.id}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id, headCell.date, headCell.dateTime)}
                            >
                                <p style={{
                                    fontFamily: 'FiraSans-Medium, sans-serif',
                                    whiteSpace: 'nowrap',
                                    textAlign: (headCell.textAlignC) ? 'center' : 'left',
                                    width: '100%'
                                }}>
                                    {headCell.label}
                                </p>
                            </TableSortLabel>
                        </TableCell> :
                        <TableCell
                            key={headCell.id}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            <p style={{
                                fontFamily: 'FiraSans-Medium, sans-serif',
                                whiteSpace: 'nowrap',
                                textAlign: (headCell.textAlignC) ? 'center' : 'left',
                                width: '100%'
                            }}>
                                {headCell.label}
                            </p>
                        </TableCell>
                    // </Resizeable>
                ))}
                {actionFlag && <TableCell>
                    {<strong style={{ whiteSpace: 'nowrap' }}>Action</strong>}
                </TableCell>}
                {cbActionFlag && <TableCell>
                    {<strong style={{ whiteSpace: 'nowrap' }}></strong>}
                </TableCell>}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { rowsPerPage,
        currentPage, editDisable, deleteDisable, numSelected, rowSelected, handleClick, onDelete, onEdit, onCopy, rows, multiSelect, isLookup, onCustomAction, handlePaginationOnDelete, tableName, deleteMessage, onCustomDelete, singleDelete } = props;
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [rowSelecteds, setRowSelecteds] = React.useState([]);
    const [rowSelectedIndex, setRowSelectedIndex] = React.useState([]);
    let { testLock } = useSelector(updateTableSelector)
    const { t } = useTranslation();
    //Splitting Row first column value and and row index of the table
    //rowSelected => [2381] , last digit will represent index of the row, here it is '1'
    React.useEffect(() => {
        let rowSelect;
        let rowSelectIndex;
        if (rowSelected.length > 0) {
            rowSelect = rowSelected.map((v, i) => {
                return rowSelected[i].slice(0, -1)
            })
            rowSelectIndex = rowSelected.map((v, i) => {
                return rowSelected[i].split('').pop()
            })
        }

        setRowSelecteds(rowSelect);
        if (rowSelectIndex) {
            setRowSelectedIndex((Number(rowSelectIndex[0]) + (rowsPerPage * (currentPage - 1))).toString());
        }
    }, [rowSelected])

    const confirmDelete = (e) => {
        deletePopup(t('popupMessages.warningTitle')).then(res => {
            if (res.isConfirmed) {
                onDelete(rowSelecteds, rowSelectedIndex);
                handleClick(e, rowSelecteds, rowSelectedIndex);
                let selectedInput = document.querySelectorAll("input[type=radio]:checked");
                selectedInput[0].checked = 0;
            }
        })
    }
    return (
        <>
            {(numSelected > -1) ? (
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        ...(numSelected > 0 && {
                            bgcolor: (theme) =>
                                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                        }),
                    }}
                    className={(numSelected > 0) ? 'tableToolbar' : 'tableToolbar whiteclass'}
                >

                    {(numSelected > -1 && !tableName) && (
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
                        </Typography>
                    )}
                    {(tableName == "mergeList") && (
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            Master Row Selected
                        </Typography>
                    )}
                    {(numSelected > 0) && (
                        <>
                            {/* onEdit and onDelete we are passing row first column and selected row index and rows(all data) */}
                            {onCopy && <FileCopyOutlinedIcon className={(numSelected > 1 || editDisable) ? 'disable' : ''} onClick={(e) => {
                                onCopy(rowSelecteds, rows, rowSelectedIndex);
                                { (multiSelect) && handleClick(e, rowSelecteds, parseInt(rowSelectedIndex[0])); }
                            }} />}
                            {onEdit && <EditOutlinedIcon className={(numSelected > 1 || editDisable) ? 'disable' : ''} onClick={(e) => {
                                onEdit(rowSelecteds, rows, rowSelectedIndex);
                                { (multiSelect) && handleClick(e, rowSelecteds, parseInt(rowSelectedIndex[0])); }
                            }} />}
                            {testLock && testLock?.lock ? '' : onDelete && <DeleteOutlineOutlinedIcon className={((numSelected > 1 && singleDelete) || deleteDisable) ? 'disable' : ''} onClick={(e) => {
                                if (onCustomAction == false) {
                                    setConfirmOpen(true)
                                    return
                                }
                                onCustomDelete && onCustomDelete(rowSelecteds, rowSelectedIndex)
                            }
                            }
                            />}

                            <GenericModal
                                open={confirmOpen}
                                icon={true}
                                title={deleteMessage ? deleteMessage : t('popupMessages.warningTitle')}
                                buttonText={t("commonLabel.yes")}
                                button2Text={t("commonLabel.no")}
                                onButtonPress={(e) => {
                                    setConfirmOpen(false);
                                    onDelete(rowSelecteds, rowSelectedIndex);
                                    if (rows.length > 5) {
                                        handlePaginationOnDelete()
                                    }
                                    // handleClick(e, rowSelecteds, rowSelectedIndex);
                                    let selectedInput = document.querySelectorAll("input[type=radio]:checked");
                                    selectedInput[0].checked = 0;

                                }}
                                onClose={setConfirmOpen}
                            />
                        </>
                    )}
                    {(numSelected < 1) && (
                        <>
                            {/* onEdit and onDelete we are passing row first column and selected row index and rows(all data) */}
                            {onCopy && <FileCopyOutlinedIcon className={'disable'} />}
                            {onEdit && <EditOutlinedIcon className={'disable'} />}
                            {testLock && testLock?.lock ? '' : onDelete && <DeleteOutlineOutlinedIcon className={'disable'} />}

                        </>
                    )}
                </Toolbar>
            ) : null}
        </>
    );
};

export default function EnhancedTable(props) {

    const { tabTracker, tablePagination } = useSelector(updateTableSelector);
    const { defaultValueRowsperPage, rowsPerPage } = tablePagination;
    const [order, setOrder] = React.useState('asc');
    // Need to add a default value
    const [orderBy, setOrderBy] = React.useState("");
    const dispatch = useDispatch();
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(defaultValueRowsperPage);
    const [rowIndex, setRowIndex] = React.useState(0);
    const [mergeFlag, setMergeFlag] = useState({ flag: false, title: "", rows: [] })
    const { t } = useTranslation();



    const [popupData, setPopupData] = useState({
        popupHeaderTitle: '',
        popupTemplate: '',
        open: false,
        selectedTableRow: []
    });

    const [selectedRowForTabTracker, setSelectedRowForTabTracker] = useState('')


    const { allowProspects } = useSelector(searchProspectsSelector);
    const { isLoading,
        multiSelect,
        headCells,
        rows,
        setRows,
        pagination,
        onDelete,
        onEdit,
        onCopy,
        isLookup,
        setPopup,
        actionTitle,
        tooltip,
        onRowSelect,
        setMerge,
        setModify,
        actionFlag,
        cbActionFlag,
        disabledActionButton,
        onRowActionClick,
        setWarning,
        uploadAction,
        uploadTitle,
        customAction,
        onCellClick,
        setSelectedValues,
        selectedValues,
        customStyle,
        tableName,
        trackingLabel,
        onDoubleClick,
        sortingFlag,
        selectAll, onAllRowSelect,
        editDisable,
        deleteDisable,
        isCellDisabled = false,
        colorData,
        onRightClick, deleteMessage, onCustomDelete,
        uniqueKey,
        onRowMultiSelect,
        singleDelete,
        isSnLabel,
        snLableValue,
        displayMessage,
        getDisableRadio,
        singleSelectCheckbox
    } = props;
    const onCustomAction = customAction ? customAction : false
    const columnData = headCells[0].id;
    const classes = useTableStyles();
    const rowClasses =  useStyles();
    const [dateFlag, setdateFlag] = useState(false);



    const handleRequestSort = (event, property, date, dateTime) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (date || dateTime) {
            setdateFlag(true)
        } else {
            setdateFlag(false)
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n, i) => n[columnData].toString() + i);
            if (setMerge) {
                if (newSelecteds.length <= 2) {
                    setMerge(newSelecteds)
                    setSelected(newSelecteds);
                    return
                } else {
                    setWarning({ flag: true, title: t('popupMessages.cannotAddMoreThanTwoInMergeList'), buttonText: "Yes", buttonText2: "No" })
                    return
                }

            }
            if (setModify) {
                if (newSelecteds.length <= 1) {
                    setModify(newSelecteds)
                    setSelected(newSelecteds);
                    return
                } else {
                    setWarning({ flag: true, title: "Cannot add more than one in Modify List" })
                    return
                }
            }
            setSelected(newSelecteds);
            onAllRowSelect && onAllRowSelect(rows)
            return true;
        }
        setSelected([]);
        if (setMerge) setMerge([])
    };

    const handleClick = (event, name, index, row, columnName) => {
        let newSelected = [];
        let indexValue;
        if (Array.isArray(name)) {
            name.map((v, i) => {
                if (Array.isArray(index)) {
                    indexValue = index[i]
                } else {
                    indexValue = index
                }
                const selectedIndex = selected.indexOf(name[i].toString() + indexValue);
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selected, name.toString() + indexValue);
                } else if (selectedIndex === i) {
                    newSelected = newSelected.concat(selected.slice(name.length));
                } else if (selectedIndex === selected.length - 1) {
                    newSelected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selected.slice(0, selectedIndex),
                        selected.slice(selectedIndex + 1),
                    );
                }

                return true;
            })
        }
        if (setMerge) {

            if (newSelected.length <= 2) {
                if (newSelected.includes(name + index)) {
                    if (row.studentUID && allowProspects) {
                        setMergeFlag((prev) => {
                            return { ...prev, flag: true, title: t('commonLabel.prospectHasBeenCopiedToAStudent'), rows: newSelected }
                        })
                        return
                    }
                    if (row.studentUID) {
                        setWarning({ flag: true, title: t('commonLabel.prospectHasBeenCopiedToAStudent') })
                        return
                    }
                }

                setMerge(newSelected)
                setSelected(newSelected);
                setSelectedValues && dispatch(setSelectedValues(newSelected))
                return
            } else {
                setWarning({ flag: true, title: t('popupMessages.cannotAddMoreThanTwoInMergeList') })
                return
            }

        }
        if (setModify) {
            if (newSelected.length <= 1) {
                setModify(newSelected)
                setSelected(newSelected);
                setSelectedValues && dispatch(setSelectedValues(newSelected))
                return
            } else {
                setWarning({ flag: true, title: "Cannot add more than one in Modify List" })
                return
            }
        }
        setSelected(newSelected);
        setSelectedValues && dispatch(setSelectedValues(newSelected))
        if (newSelected.length > 0) {
            let selectedMultiRow = newSelected.map(v => {
                return v.slice(0, -indexValue.toString().length)
            })
            let selectedMultiRows = []
            rows.map(v => {
                return selectedMultiRow.filter(val => {
                    if (v[columnName].toString() === val) {
                        selectedMultiRows.push(v)
                    }
                })
            })
            onRowMultiSelect && onRowMultiSelect(selectedMultiRows)
        }
    };

    const handleSingleClick = (event, name, index,) => {
        let newSelected = (uniqueKey) ? [name.toString()] : [name.toString() + index];
        setSelected(newSelected)
        // For tracking selected row on unmount
        setSelectedRowForTabTracker(newSelected)
        setSelectedValues && dispatch(setSelectedValues(newSelected))
        if (setModify) {
            if (newSelected.length <= 1) {
                setModify(newSelected)
                setSelected(newSelected);
                setSelectedValues && dispatch(setSelectedValues(newSelected))
                return
            } else {
                setWarning({ flag: true, title: "Cannot add more than one in Modify List" })
                return
            }
        }

    }

    const handleAction = (row, actionTitle, index) => {
        setPopupData({
            popupHeaderTitle: actionTitle,
            popupTemplate: actionTitle,
            open: true,
            selectedIndex: index,
            selectedTableRow: row
        });
    }
    const handleCBAction = (row, actionTitle, index) => {
        onRowActionClick(row, actionTitle, index);
    }



    const [currentPage, setCurrentPage] = useState(1)
    const [showingFrom, setShowingFrom] = useState((currentPage * rowsPerPage) - rowsPerPage + 1)
    const [showingTo, setShowingTo] = useState(currentPage * rowsPerPage)

    const handleChangeRowsPerPage = (event, page = 0) => {
        if (page) {
            handleChange(event, page)
            // setRowsPerPage(parseInt(event.target.value, 10));
            dispatch(updatePagination({
                rowsPerPage: parseInt(event.target.value, 10)
            }))
        } else {
            // const newPage = Math.min(Math.ceil(rowsPerPage / Number(event.target.value) * currentPage), Math.ceil(rows.length / Number(event.target.value)))
            // handleChange(event, newPage)
            // setRowsPerPage(parseInt(event.target.value, 10));
            dispatch(updatePagination({
                rowsPerPage: parseInt(event.target.value, 10)
            }))
        }
        // const { value } = event.target;
        // dispatch(updatePagination({
        //     defaultValueRowsperPage: value,
        //     rowsPerPage: value
        // }))
    };

    const setMergRecords = () => {
        if (mergeFlag.rows.length > 0) {
            setMerge(mergeFlag.rows)
            setSelected(mergeFlag.rows);
            setSelectedValues && dispatch(setSelectedValues(mergeFlag.rows))
            setMergeFlag({ flag: false, title: "", rows: [] })
        }

    }
    useEffect(() => {
        // update showingFrom and showingTo whenever 1. currentPage is changed, or 2. Number of rows change (due to delete)
        setShowingFrom((currentPage * rowsPerPage) - rowsPerPage + 1)
        if (rows.length) setShowingTo(Math.min(currentPage * rowsPerPage, rows.length))
        else setShowingTo(currentPage * rowsPerPage)

    }, [currentPage, rows.length, rowsPerPage])

    const handleChange = (event, page) => {
        setPage(page - 1);
        setCurrentPage(page)
        formik.setFieldValue('jumpTopage', page);
    };

    const handlePaginationOnDelete = (e) => {
        if ((rows.length - 1) % rowsPerPage === 0) {
            // if on last page, then only go to previous
            if (currentPage === Math.ceil(rows.length / rowsPerPage)) {
                handleChange(e, currentPage - 1)
            }
        }
    }

    const isSelected = (columnData) => selected.indexOf(columnData) !== -1;

    const handleClose = () => {
        setPopupData({ ...popupData, open: false })
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleAddDoc = (row) => {
        uploadAction(row);
    }
    // Dynamic width calculation
    const ref = useRef();

    const [width, setWidth] = useState('100%')
    const [spanWidth, setSpanWidth] = useState('100%');
    const [rowL, setRowL] = useState(0);
    useEffect(() => {
        let temp = 0, secMax = 0;
        if ((rowL !== rows.length) && rows.length > 0) {
            if (headCells.length >= 4) {
                const longestText = [...rows.map((row) => {
                    const arr = [...headCells.map((headCell) => {
                        if (typeof row[headCell.id] === 'string') {
                            if (temp < row[headCell.id].length) {
                                secMax = temp;
                                temp = row[headCell.id].length;
                                return secMax;
                            }
                        }
                    })
                    ]
                    return [...arr]
                })];

                setRowL(rows.length);
                let widthMax = Math.max(...longestText.map(it => Math.max(...it.filter(v => v != undefined))))
                let setMaxWidth = widthMax > 50 ? widthMax : 100;
                let offset = ref.current.offsetWidth ? ref.current.offsetWidth : 1090;
                setWidth(offset + (setMaxWidth * 3))
                setSpanWidth(trackingLabel === 'access' || trackingLabel === 'addRole' ? '90%' : '165px');
            }
        }
        if (rows.length < 6) {
            setPage(0)
        }
    }, [rows]);


    // Table Tracker ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const [selectedRowFullData, setSelectedRowFullData] = useState({})
    const tracker = trackingLabel
    const stateRef = useRef();

    useEffect(() => {
        trackingLabel && updateDefaultTable()  // internal state update
        return () => {
            if (!trackingLabel) {
                return
            }
            tabTracker[`${tracker}`] = { // redux state update
                ...tabTracker[`${tracker}`],
                rowsPerPage: rowsPerPage,
                currentPage: stateRef.current.currentPage,
                selectedRowForTabTracker: stateRef.current.selectedRowForTabTracker,
                selectedRow: stateRef.current.selectedRow,

            }
            dispatch(updateTable(tabTracker))
        }
    }, [])

    const updateDefaultTable = () => {  // internal state update
        if (tabTracker[`${tracker}`] && Object.keys(tabTracker[`${tracker}`]).length && trackingLabel) {
            // handleChangeRowsPerPage({
            //     target: {
            //         value: tabTracker[`${tracker}`].rowsPerPage
            //     }
            // }, tabTracker[`${tracker}`].currentPage)
            setSelected(tabTracker[`${tracker}`].selectedRowForTabTracker)

            setSelectedRowForTabTracker(tabTracker[`${tracker}`].selectedRowForTabTracker)

            setSelectedRowFullData(tabTracker[`${tracker}`].selectedRow)

            onRowSelect && Object.keys(tabTracker[`${tracker}`].selectedRow).length && onRowSelect(tabTracker[`${tracker}`].selectedRow)
        }
    }
    //  DefaultTable.updateSelectedRow("masterCourse1", values) //trackingLabel, formikValues addMasterCourse.js
    EnhancedTable.updateSelectedRow = (tracker = "", values) => {
        // NEED TO recalculate selected
        const tracker2 = tracker ? tracker : trackingLabel
        tabTracker[`${tracker2}`] = {
            ...tabTracker[`${tracker2}`],
            selectedRow: values
        }
        // update selectedRow in redux

        dispatch(updateTable(tabTracker))
    }

    useEffect(() => {
        if (trackingLabel) {
            stateRef.current = {  // ref state upadte
                ...stateRef.current,
                rowsPerPage,
                currentPage,
                selectedRowForTabTracker,
                selected,
                selectedRow: selectedRowFullData
            }
        }
    }, [rowsPerPage, currentPage, selectedRowForTabTracker, selected, selectedRowFullData])

    useEffect(() => {
        if (trackingLabel && tabTracker[`${tracker}`]) {
            updateDefaultTable()  //internal state update
            stateRef.current = {  // ref state upadte
                ...stateRef.current,
                rowsPerPage: tabTracker[`${tracker}`]?.rowsPerPage,
                currentPage: tabTracker[`${tracker}`]?.currentPage,
                selectedRowForTabTracker: tabTracker[`${tracker}`]?.selectedRowForTabTracker,
                selected: tabTracker[`${tracker}`]?.selectedRowForTabTracker,
                selectedRow: tabTracker[`${tracker}`]?.selectedRow
            }
        }

        if (trackingLabel === 'tabRegister2' || trackingLabel === 'tabRegister1') {
            document.getElementsByTagName('html')[0].classList.add('register-Page');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('register-Page');
        }

    }, [trackingLabel && tabTracker[`${tracker}`]])

    EnhancedTable.resetTable = (trackingLabel) => {
        tabTracker[`${trackingLabel}`] = {
            ...tabTracker[`${trackingLabel}`],
            rowsPerPage: 20,
            currentPage: 1,
            selectedRowForTabTracker: [],
            selected: [],
            selectedRow: {}
        }
        setSelected([])
        setCurrentPage(1)
        formik.setFieldValue('jumpTopage', 1)
        formik.handleSubmit();
        dispatch(updateTable(tabTracker))
    }
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    useEffect(() => {
        if (selectedValues) {
            if (selectedValues.length > 0) {
                setSelected(selectedValues)
                return
            }
            setSelected([])
        }
    }, [selectedValues])
    const transform = (val) => {
        if (!val) return;
        return currencyPipe(parseInt(val), { symbol: '', decimal: '', separator: ',', precision: 0 }).format();
    }
    const transformSymbol = (val) => {
        // if (!val) return;
        return currencyPipe(parseInt(val), { symbol: '$', decimal: '.', separator: ',', precision: 2 }).format();
    }
    const transformSymbolDollar = (val) => {
        return currencyPipe(parseInt(val), { symbol: '$', decimal: '.', separator: ',', precision: 2 }).format();
    }
    const transformTwoDecimal = (val) => {
        if (!val) return;
        return currencyPipe(parseInt(val), { symbol: '', decimal: '.', separator: ',', precision: 2 }).format();
    }

    const formatToUSCurrency = (val) => {
        return parseFloat(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    //ELEMENTS CONFIGURATION
    const { configuration } = useSelector(updateUserSelector);
    const [getPhoneMaskingPattern, setMask] = useState(getPhoneMaskPattern('###.###.####********'));

    useEffect(() => {
        if (Object.keys(configuration).length) {
            setMask(getPhoneMaskPattern(configuration.camsConfig.domesticPhoneMask))
        }
    }, [configuration])


    const formik = useFormik({
        initialValues: {
            "jumpTopage": 1
        },
        onSubmit: (values) => {
            if (values.jumpTopage) {
                handleChange('', Number(values.jumpTopage))
            }
        }
    });

    const handleSubmitGo = (e) => {
        formik.handleSubmit(e);
    }

    const [checkClass, setCheckClass] = useState('')
    return (
        <>
            {mergeFlag.flag && <GenericModal infoMessage={t('commonLabel.allowProspectHasBeenCopiedToAStudent')}
                open={mergeFlag.flag}
                onClose={() => { setMergeFlag({ flag: false, title: "", rows: [] }) }}
                buttonText={t('buttonLabel.yes')}
                button2Text={t('buttonLabel.no')}
                onButtonPress={setMergRecords}
            />}
            <>
                <div className={classes.root}>
                    {isLoading ? <CustomLoader /> : ""}
                    <Paper className={classes.paper}>
                        {(!isLookup) && <EnhancedTableToolbar
                            multiSelect={multiSelect}
                            singleDelete={singleDelete}
                            numSelected={selected.length}
                            rowSelected={selected}
                            handleClick={handleClick}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onCopy={onCopy}
                            rows={rows}
                            isLookup={isLookup}
                            onCustomAction={onCustomAction}
                            handlePaginationOnDelete={handlePaginationOnDelete}
                            tableName={tableName}
                            editDisable={editDisable}
                            deleteDisable={deleteDisable}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage}
                            deleteMessage={deleteMessage}
                            onCustomDelete={onCustomDelete}
                        />}
                        <TableContainer style={{ overflowY: 'hidden' }}>
                            <Table
                                className={classes.table}
                                ref={ref}
                                // style={{ minWidth: width }}
                                style={customStyle ? customStyle : {}}
                            >
                                <EnhancedTableHead
                                    selectAll={selectAll}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    isLookup={isLookup}
                                    actionFlag={actionFlag}
                                    cbActionFlag={cbActionFlag}
                                    disabledActionButton={disabledActionButton}
                                    onRowActionClick={onRowActionClick}
                                    multiSelect={multiSelect}
                                    singleDelete={singleDelete}
                                    uploadAction={uploadAction}
                                    uploadTitle={uploadTitle}
                                    rowsPerPage={rowsPerPage}
                                    sortingFlag={sortingFlag}
                                    isSnLabel={isSnLabel}
                                    snLableValue={snLableValue}
                                    trackingLabel={trackingLabel}

                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                    {stableSort(rows, getComparator(order, orderBy), order, orderBy, dateFlag)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const str = (row[(uniqueKey) ? uniqueKey : headCells[0].id] === null || row[(uniqueKey) ? uniqueKey : headCells[0].id] === undefined) ?
                                                '' : row[(multiSelect) ? headCells[0].id : (uniqueKey) ? uniqueKey : headCells[0].id]
                                            const isItemSelected = isSelected((multiSelect) ? str.toString() + index : (uniqueKey) ? str.toString() : str.toString() + index);
                                            const labelId = `${trackingLabel}enhanced-table-checkbox-${index}`;
                                            return (

                                                <TableRow
                                                    hover
                                                    className={getDisableRadio && getDisableRadio(row) ? rowClasses.disabledRow : "" }
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                    onContextMenu={(event) => {
                                                        if (event.type === 'contextmenu') {
                                                            event.preventDefault();
                                                            (onRightClick) && onRightClick(event, row, index)
                                                        }
                                                    }}
                                                    onClick={(event) => {
                                                        if (event.detail === 1) {
                                                            setRowIndex(index);
                                                            { (onRowSelect) && onRowSelect(row) }
                                                            { (onRowSelect) && setSelectedRowFullData(row) }
                                                        }
                                                        else if (event.detail === 2) {
                                                            if (!editDisable) {
                                                                if (!actionFlag) { (onEdit) && onEdit([row[headCells[0].id]], rows, [index + (rowsPerPage * (currentPage - 1)).toString()]); }
                                                                (onDoubleClick) && onDoubleClick(row);
                                                            }
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    data-color={
                                                        (trackingLabel == 'batchesBilling') ?
                                                            (row['activeFlag'] == true) ? 'CourseBlue' : '' :
                                                            (trackingLabel == 'Package') ?
                                                                (row['awardStatus'] == "APPROVED") ? 'CourseTan' : '' :
                                                                (trackingLabel === 'tabRegister2') ?
                                                                    ((row['varCredits'] === 'Yes') ? 'CourseGreen' :
                                                                        (row['tan']) ? 'CourseTan' : '') :
                                                                    (trackingLabel === 'tabRegister1') ? ((row['waitingList'] === 'yes') && 'CourseBlue') :
                                                                        (trackingLabel === 'OfferingTabTable') ? ((row['ispresentSky']) && 'CourseBlue') :
                                                                            (trackingLabel === 'addCourses1') ? ((`${row['department']}${row['course']}${row['courseType']}` === colorData.department + colorData.course + colorData.courseType) && 'CourseBlue') :
                                                                                ''
                                                    }
                                                >

                                                    {!isLookup &&
                                                        <>
                                                            {(multiSelect) ?
                                                                <TableCell padding="checkbox" onClick={(event) => {
                                                                    handleClick(event, [row[headCells[0].id]], index, row, headCells[0].id);
                                                                }}>
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                                        checkedIcon={<CheckBoxIcon />}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                        id={`${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}
                                                                    />
                                                                </TableCell>
                                                                :
                                                                <TableCell padding="checkbox" >
                                                                    {/* <input checked={isItemSelected} name={headCells[headCells.length - 1].id} type="radio" id={`${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`} style={{ width: '20px', height: '20px', cursor: 'pointer' }} onClick={(event) => handleSingleClick(event, [row[headCells[0].id]], index)} /> */}
                                                                    <Radio sx={{ color: "black" }}
                                                                        checked={isItemSelected}
                                                                        name={(uniqueKey) ? `${row[uniqueKey].toString()}` : `${trackingLabel}${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}
                                                                        id={(uniqueKey) ? `${row[uniqueKey].toString()}` : `${trackingLabel}${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}
                                                                        style={{ width: '100%', height: '100%', cursor: 'pointer', padding: '0px 20px', justifyContent: 'flex-start' }}
                                                                        onClick={(event) => handleSingleClick(event, [row[(uniqueKey) ? uniqueKey : headCells[0].id]], index)}
                                                                    />
                                                                </TableCell>}
                                                        </>
                                                    }
                                                    {/* <TableCell>
                                                        <label htmlFor={(uniqueKey) ? `${row[uniqueKey].toString()}` : `${trackingLabel}${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}>
                                                            <span style={{ width: '100%', padding: '0px 24px 0px 10px' }}> {(index + 1) + (page * rowsPerPage)}</span>
                                                            <span style={{ width: '100%', padding: '0px 24px 0px 10px' }}></span>
                                                        </label>
                                                    </TableCell> */}
                                                    {(uploadAction) &&
                                                        <TableCell key={index} style={{ textAlign: 'center' }}>
                                                            <span className="material-icons-outlined" onClick={() => handleAddDoc(row)} style={{ color: (row['documentImage']) ? "#f40420" : "#000000" }}>
                                                                upload_file
                                                            </span>
                                                        </TableCell>
                                                    }
                                                    {/* {(true) &&
                                                        <TableCell key={index} style={{ textAlign: 'center' }}>
                                                           {index+1}
                                                        </TableCell>
                                                    } */}
                                                    {headCells.map((headCell, ind) => (
                                                        <TableCell key={ind} style={{ cursor: 'pointer' }}
                                                            sortDirection={orderBy === headCell.id ? order : false}
                                                            onClick={(event) => {
                                                                if (isCellDisabled) {

                                                                } else {
                                                                    if (onCellClick) {
                                                                        onCellClick(headCell.cellClick, headCell.id, row[headCell.id], row, (index + (rowsPerPage * (currentPage - 1))), event, row['sn'])
                                                                        if (headCell.id === 'select') {
                                                                            if (singleSelectCheckbox) {
                                                                                let lengthRow = rows.filter((v, i) => {
                                                                                    if (v['select']) {
                                                                                        return v
                                                                                    };
                                                                                })
                                                                                console.log(lengthRow);
                                                                                if (row[headCell.id]) {
                                                                                    if(lengthRow.length==1){
                                                                                        let newRows= [];
                                                                                        rows.forEach((row)=>{
                                                                                            if(lengthRow.length==1){
                                                                                                if(row?.genericUID==lengthRow[0]?.genericUID){
                                                                                                    row.disabled =false;
                                                                                                }else{
                                                                                                    row.disabled =true;
                                                                                                }
                                                                                            }
                                                                                            newRows.push(row)
                                                                                        })
                                                                                        setRows(newRows);
                                                                                    } else {
                                                                                        let newRows= [];
                                                                                        rows.forEach((row)=>{
                                                                                            row.disabled =true;
                                                                                            newRows.push(row)
                                                                                        })
                                                                                        setRows(newRows);
                                                                                    }
                                                                                } else {
                                                                                    let newRows= [];
                                                                                        rows.forEach((item)=>{
                                                                                            if(lengthRow.length==1){
                                                                                                if(item?.genericUID==lengthRow[0]?.genericUID){
                                                                                                    item.disabled =false;
                                                                                                }
                                                                                            }else{
                                                                                                if(item?.genericUID==row.genericUID){
                                                                                                    item.disabled =true;
                                                                                                }
                                                                                            }
                                                                                                
                                                                                            newRows.push(item)
                                                                                        })
                                                                                        setRows(newRows);
                                                                                }
                                                                                
                                                                            } else {
                                                                                if (row[headCell.id]) {
                                                                                    event.target.childNodes[0].parentElement.parentElement.parentElement.parentElement.classList.add('Checked-State')
                                                                                    event.target.childNodes[0].parentElement.parentElement.parentElement.parentElement.lastChild.lastChild.lastChild.classList.remove('btn-disable');

                                                                                } else {
                                                                                    event.target.childNodes[0].parentElement.parentElement.parentElement.parentElement.classList.remove('Checked-State')
                                                                                    event.target.childNodes[0].parentElement.parentElement.parentElement.parentElement.lastChild.lastChild.lastChild.classList.add('btn-disable');

                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            className={(headCell.Nodisplay) ? `${checkClass} hide` : `${checkClass}`}
                                                        >

                                                            {(typeof (row[headCell.id]) === 'boolean') ?
                                                                <label htmlFor={(uniqueKey) ? `${row[uniqueKey].toString()}` : `${trackingLabel}${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}>
                                                                    <span>
                                                                        <Checkbox
                                                                            color="primary"
                                                                            icon={<CheckBoxOutlineBlankOutlinedIcon style={{ padding: "0 20px", color: "#A7A8A9" }} />}
                                                                            checkedIcon={<CheckBoxIcon style={{ padding: "0 20px", color: "#A7A8A9" }} />}
                                                                            name={labelId}
                                                                            checked={row[headCell.id]}
                                                                            className="row-checkbox"
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </label>
                                                                : (headCell.id === 'radioSelect') ?
                                                                    <Radio sx={{ color: "black" }}
                                                                        checked={row[headCell.id] === "radioOn"}
                                                                        name={row[headCell.id] + ind}
                                                                        id={row[headCell.id] + ind}
                                                                        style={{ width: '20px', height: '20px', cursor: 'pointer', padding: '0px 24px' }}
                                                                    />
                                                                    :
                                                                    <label htmlFor={(uniqueKey) ? `${row[uniqueKey].toString()}` : `${trackingLabel}${headCells[headCells.length - 1].id.toString()}${index}${headCells.length.toString()}`}>
                                                                        {
                                                                            (trackingLabel === 'addRoleCourse') &&
                                                                            <span style={{ width: (row.fileName === "Header" && row.menuLevel !== 0) ? `10px` : (row.fileName === "Header" && row.menuLevel === 0) ? '0' : `20px` }} className={`space-${row.menuLevel}`}></span>
                                                                        }
                                                                        <span title={((row[headCell.id]) && row[headCell.id].length > 19 ? row[headCell.id] : '')} style={{ width: (trackingLabel === 'tabRegister1' || trackingLabel === 'tabRegister2' || headCell.textAlignC || headCell.textAlignR) ? '100%' : (row[headCell.id]) ? (row[headCell.id].length > 18) ? spanWidth : row[headCell.id].length * 15 : (trackingLabel === 'ImportedFileRecords' || trackingLabel === 'ExactMatchRecords' ) ? "50px": '100%', overflow: 'hidden', textOverflow: (window.innerWidth > 1919) ? 'initial' : 'ellipsis', padding: '0px 24px 0 3px', whiteSpace: (trackingLabel === 'addRole' || trackingLabel === 'users' || trackingLabel === 'access' || trackingLabel === 'addRoleCourse') ? 'break-spaces' : 'inherit', textAlign: (headCell.textAlignC) ? 'center' : (headCell.textAlignR) ? 'right' : 'left' }}>
                                                                            {
                                                                                (headCell.date) ?
                                                                                    (row[headCell.id] !== null) && formatDate(row[headCell.id]) :
                                                                                    (headCell.dateTime) ?
                                                                                        (row[headCell.id] === null) ? row[headCell.id] : `${formatTime(row[headCell.id])}` :
                                                                                        (headCell.Time) ?
                                                                                            (row[headCell.id] === null) ? row[headCell.id] : `${formatTimeOnly(row[headCell.id])}` :
                                                                                            (headCell.timeWithoutSeconds) ?
                                                                                                (row[headCell.id] === null) ? row[headCell.id] : `${formatTimeWithoutSeconds(row[headCell.id])}` :
                                                                                                (headCell.id === 'updateTime' || headCell.id === 'insertTime') ?
                                                                                                    (row[headCell.id] === null) ? row[headCell.id] : `${formatTime(row[headCell.id])}` :
                                                                                                    (headCell.phone) ?
                                                                                                        (row[headCell.id] === '') ? row[headCell.id] : `${phoneInputMask(row[headCell.id], getPhoneMaskingPattern)}` :
                                                                                                        (headCell.ssn) ?
                                                                                                            formatSSN(row[headCell.id]) :
                                                                                                            (headCell.toFixed) ?
                                                                                                                formatToFixed(row[headCell.id]) :
                                                                                                                (headCell.toFixedThreeDec) ? formatToFixed3(row[headCell.id]) :
                                                                                                                (headCell.toRoundoff) ? formatToRoundoff(row[headCell.id]) :
                                                                                                                    (headCell.currencyPipe) ? transform(row[headCell.id]) :
                                                                                                                        (headCell.currencyPipeWithSymbol) ? `$${(row[headCell.id] !== null) ? row[headCell.id].toFixed(2) : '0.00'}` :
                                                                                                                            (headCell.currencyPipeWithSymbol) ? `$${(row[headCell.id] !== null) ? row[headCell.id].toFixed(2) : row[headCell.id]}` :
                                                                                                                                (headCell.currencyTypeUS) ? formatToUSCurrency(row[headCell.id]) :
                                                                                                                                    (headCell.transformTwoDecimal) ? transformTwoDecimal(row[headCell.id]) :
                                                                                                                                        (headCell.TwoDecimal) ? row[headCell.id].toFixed(2) :
                                                                                                                                            (headCell.Nodisplay) ? '' : (headCell.id=="serialNumber") ? index+1 :  row[headCell.id]

                                                                            }
                                                                        </span>
                                                                    </label>
                                                            }
                                                        </TableCell>
                                                    ))
                                                    }
                                                    {(actionFlag) &&
                                                        <TableCell key={index} >
                                                            <ButtonWrap>
                                                                <Button className='BgYellowOrange search' variant="contained" size="small" type="submit" onClick={() => handleAction(row, actionTitle, index)}>
                                                                    {actionTitle}
                                                                </Button>
                                                            </ButtonWrap>
                                                        </TableCell>
                                                    }
                                                    {(cbActionFlag) &&
                                                        <TableCell key={index} >
                                                            <ButtonWrap>
                                                                <Button disabled={singleSelectCheckbox &&  row.disabled ? true :false} className={singleSelectCheckbox ? 'actionBtn BgYellowOrange search': 'actionBtn BgYellowOrange search btn-disable'} variant="contained" size="small" type="submit" onClick={() => handleCBAction(row, actionTitle, index)}>
                                                                    {actionTitle}
                                                                </Button>
                                                            </ButtonWrap>
                                                        </TableCell>
                                                    }

                                                    {/* {(tooltip) &&
                                                    <TableCell key={index} >
                                                        <Tooltips placement="left" iconSize="30px" flag="edit" row={row} />
                                                    </TableCell>
                                                } */}
                                                </TableRow>
                                            );
                                        })}
                                    {rows.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={headCells.length * 2} style={{ textAlign: 'left', paddingLeft: '30px' }}>
                                                {(displayMessage) ? displayMessage : "No Data Available"}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {(pagination && rows.length > 5) &&
                            <div style={{
                                padding: '8px 16px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }} spacing={2} >
                                Show
                                <select onChange={handleChangeRowsPerPage} style={{ margin: '0 10px' }}>
                                    {/* <option selected={rowsPerPage == 5} value="5">{5}</option>
                                    {<option selected={rowsPerPage == 10} value="10">10</option>} */}
                                    {<option selected={rowsPerPage == 20} value="20">20</option>}
                                    {<option selected={rowsPerPage == 50} value="50">50</option>}
                                    {<option selected={rowsPerPage == 100} value="100">100</option>}
                                </select>
                                {`Entries Showing ${showingFrom} - ${showingTo} of ${rows.length}`}
                                {<p class="jumpToPageParent">
                                    <form autoComplete="off">
                                        <span>Jump to Page:</span>
                                        <TextInput
                                            customStyle={{ width: 'auto', marginRight: '10px' }}
                                            labelId="jumpTopage"
                                            formikValue={formik.values.jumpTopage}
                                            formikChange={formik.handleChange}
                                            maxValue={Math.ceil(rows.length / rowsPerPage)}
                                            minValue={0}
                                            onlyNumeric
                                            errMsgCustom={`Please enter the valid page number from 1 to ${Math.ceil(rows.length / rowsPerPage)}`}
                                        />
                                        <button onClick={(e) => handleSubmitGo(e)} disabled={(formik.values.jumpTopage >= 1) ? false : true}>Go</button>
                                    </form>
                                </p>}
                                <Pagination count={Math.ceil(rows.length / rowsPerPage)} page={currentPage} size="small" onChange={handleChange} />
                            </div>
                        }
                    </Paper>
                    <CustomizedDialogs popupData={popupData} onClose={handleClose} setPopupData={setPopupData} />
                </div>
            </>
        </>
    );
}
