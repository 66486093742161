export const downloadFile = (fileName, fileByte, contentType) => {
    const byteCharacters = atob(fileByte);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    let objectURL = URL.createObjectURL(blob);
    let documentFile = {
        preview: objectURL,
        type: contentType,
        name: fileName
    }
    const link = document.createElement('a');
    link.href = documentFile.preview;
    link.setAttribute(
        'download',
        `${documentFile.name}`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}