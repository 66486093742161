import { UPDATE_ACADEMIC, UPDATE_FINANCIAL, UPDATE_FILE } from './types'
const initialState = {
    academicYearDetails: {},
    financialYearDetails: {
        financialYearId: [],
    },
    fileDetails: {
        document: [],
        fileName: ''
    },
};
const loadPellReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_ACADEMIC:
            return {
                ...state,
                academicYearDetails: { academicYearId: payload.academicYearId, ...payload }
            }
        case UPDATE_FINANCIAL:
            return {
                ...state,
                financialYearDetails: { financialYearId: payload.financialYearId, ...payload }
            }
        case UPDATE_FILE:
            return {
                ...state,
                fileDetails: { ...payload }
            }
        default:
            return state;
    }
}
export default loadPellReducer