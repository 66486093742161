import React, {  useState } from 'react';
import { makeStyles, InputLabel, TextField,  FormControl, Card, CardContent } from '@material-ui/core';
import { useFormik } from 'formik';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import InputAdornment from '@mui/material/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import { FormGroup, FormInputWrapper } from '../../styles/common-styles'
import BrandingColors from '../../styles/color';
import {  formatDate, formatTime } from '../../helper';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root,& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            padding: '0px'
        },
        '& .MuiRadio-root .MuiSvgIcon-root': {
            fontSize: '1em'
        },
        '& .MuiRadio-root.Mui-checked': {
            color: `${BrandingColors.darkBlack}`
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: `${BrandingColors.darkBlack}`
        },
        '& .marginRight36': {
            marginRight: "35px"
        },
        '& .note': {
            padding: "5px",
            minHeight: "150px",
        },
        '& .outerBox': {
            display: "flex",
            flexDirection: "column",
            border: "2px solid black",
        },
        '& .sendButton': {
            background: `${BrandingColors.yellowOrange}`,
            padding: "4px 48px",
            margin: "12px 10px",
            fontSize: "45px",
            cursor: 'pointer',
            position: "relative",
            color: `${BrandingColors.white}`
        },
        '& .helperText': {
            display: "flex",
            justifyContent: "flex-end",
            color: "grey",
            margin: "10px",
        },
        '& .date': {
            marginLeft: "25px",
        },
        '& .deleteIcon': {
            cursor: 'pointer',
            color:"#000000"
        },
        '& .MuiInputBase-root.Mui-disabled': {
            background: `${BrandingColors.lightGreyCustom}`,
            color: `${BrandingColors.darkBlack}`,
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl >textarea ': {
            padding: "1.65% 14px",
        },
        '& .textFieldClass': {
            width: "100%",
            marginRight: "20px",
            
        },
        '& .cardClass': {
            height: "230px",
            marginRight: "30px",
            padding: "20px",
            border: "1px solid gray",
            marginBottom: "25px",
            overflowY: "auto",
            width:"94%",
        },
        '& .text2': {
            width: "100%", borderBottom: "none"
        },
        '& .cardContent': {
           padding:"4px"
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: "none"
         }
    }
}));
const styleForTextField = {
    padding: '6px 14px',
}
const AddNote = (props) => {
    const [selectedEntryDate, setSelectedEntryDate,] = useState(new Date());
    const classess = useStyles();
    const [formValues, setFormValues] = useState('');
    const [notes, setNotes] = useState([]);
    const initialValues = {
        "entryDate": new Date(),
        "newNote": "",
        "editedDate":new Date()
    }
    const formik = useFormik({
        initialValues: formValues || initialValues,
        onSubmit: (values) => {
            if (values.newNote!="") {
                let newIndex = notes.length == 0 ? 1 : notes[notes.length - 1].index + 1;
                let newNotes = [...notes, { index: newIndex, note: formik.values.newNote, entryDate: formik.values.entryDate, editedDate: new Date() }];
                setNotes(newNotes);
                formik.resetForm();
            }

        }
    });
    //If editable row data tiggers, then below useEffect will execute

    const handleEntrtDate = (date) => {
        setSelectedEntryDate(date);
        formik.setFieldValue("entryDate", date);
    };
    const addNewNote = () => {
        formik.submitForm()
    }
    const deleteNote = (index) => {
        let newNotes = notes.filter(note => note.index !== index);
        setNotes(newNotes);
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                <FormGroup>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormInputWrapper >
                            <InputLabel htmlFor="entryDate">Entry Date</InputLabel>
                            <FormControl variant="outlined" fullWidth>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    name="entryDate"
                                    id="entryDate"
                                    value={selectedEntryDate}
                                    onChange={handleEntrtDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </FormControl>
                        </FormInputWrapper>
                    </MuiPickersUtilsProvider>
                </FormGroup>
                <FormGroup >
                    <FormInputWrapper style={{ width: "100%" }}>
                        <Card className="cardClass">
                            {notes.length == 0 && <CardContent  className="cardContent">
                                <TextField
                                    inputProps={{
                                        style: styleForTextField
                                    }}
                                    className="text2"
                                    disabled row={15} id="note" name="note" value="No Notes Available" multiline />
                            </CardContent>}
                            {notes.map((note) => (
                                    <CardContent key={note.index} className="cardContent">
                                        <TextField
                                            className="textFieldClass"
                                            disabled id="note" name="note" value={note.note}
                                            inputProps={{
                                                style: styleForTextField
                                            }}
                                            multiline />
                                        <div className="helperText">
                                            <span onClick={() => { deleteNote(note.index) }}  className="material-icons deleteIcon">
                                                delete_outline
                                            </span>
                                        </div>
                                        <div className="helperText">
                                            <span>{formatDate(note.editedDate)}</span> <span className="date">{formatTime(note.editedDate)}</span>
                                        </div>
                                    </CardContent>
                            ))}
                        </Card>
                    </FormInputWrapper>
                </FormGroup>
                <FormGroup >
                    <FormInputWrapper style={{ width: '100%' }}>
                        <TextField id="newDescription" name="newNote" onChange={formik.handleChange}

                            value={formik.values.newNote} multiline rows={3} variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="start"><span onClick={addNewNote} style={{}} className="material-icons-outlined sendButton">
                                    send
                                </span></InputAdornment>
                            }} />
                    </FormInputWrapper>
                </FormGroup>
                <br />
            </form>
        </>
    )
}

export default AddNote;


