import { SEARCH_STUDENTS, SELECTED_PROFILE, SEARCH_ORGANIZATION, STUDENT_LOOKUP, SET_INITIAL_GPA, SET_ACTIVATED_GPA } from './types'

const initialState = {
    allData: {
        allStudents: [],
        allProspects: [],
        selectedProfile: {},
        orgSearch: [],
        studentLookup: [],
        initialGPA: "",
        activatedGPA: "",
    }
};

const searchDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_STUDENTS:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    allStudents: [...payload]
                }
            };
        case SELECTED_PROFILE:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    selectedProfile: { ...payload }
                }
            };
        case SEARCH_ORGANIZATION:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    orgSearch: [...payload]
                }
            };
        case STUDENT_LOOKUP:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    studentLookup: [...payload]
                }
            };
        case SET_ACTIVATED_GPA:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    activatedGPA: { ...payload }
                }
            };
        case SET_INITIAL_GPA:
            return {
                ...state,
                allData: {
                    ...state.allData,
                    initialGPA: { ...payload }
                }
            };


        default:
            return state;
    }
};

export default searchDataReducer;