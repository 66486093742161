import { UPDATE_TABLE, ACCESS_LOCK, RESET_TABLE, UPDATE_PAGINATION } from "./types";


const initialState = {
    table: {},
    college: {},
    tabTracker: {},
    letterSequences: {},
    testLock: {lock: false},
    tablePagination: {
        defaultValueRowsperPage : 20,
        rowsPerPage: 20
    }
};

const updateTableReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_TABLE:
            return {
                ...state,
                tabTracker: payload
            };
        case RESET_TABLE:
            return {
                ...state,
                tabTracker: {}
            };
        case ACCESS_LOCK:
            return {
                ...state,
                testLock: payload
            };
        case UPDATE_PAGINATION:
                return {
                    ...state,
                    tablePagination: payload
                };
        default:
            return state;
    }
};

export default updateTableReducer;