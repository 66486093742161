import { Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../../../styles/common-styles";
import PopupHeader from "../../../common-page/popupHeader";
import DefaultTable from "../../../../utils/Table/DefaultTable";
import { Button } from '@material-ui/core';
import { ButtonWrapperFilter } from '../../../../styles/common-styles';
import { useTranslation } from 'react-i18next';
import ExportComponent from "../../../../utils/Export/ExportComponent";

const ShowISIRHistoricalReport = ({ onClose, selectedRow }) => {
    const { t } = useTranslation(['financialAid']);
    const classes = useStyles();
    const [customImportFields] = useState([
        { id: 'key', numeric: false, disablePadding: false, label: ""},
        { id: 'value', numeric: false, disablePadding: false, label: "" },
    ])
    const [tableRows, setTableRows] = useState([])
    const getFile = (U) => {
        var X = new XMLHttpRequest();
        X.open("GET", U, false);
        X.send();
        return X.responseText;
    };

    const fullDetailForm = () => {
        let HTML_STRING = getFile(
        `${window.location.origin}/ReportHTML/FinancialAid/maintenance/FullIsirReport/ISIRHistoricalReport/index.html`
        );
        let HTML_CSS = getFile(
        `${window.location.origin}/ReportHTML/FinancialAid/maintenance/FullIsirReport/ISIRHistoricalReport/index.css`
        );
        let templateData = {
            reportName : "ISIR Full Details",
            fullDetails : tableRows
        };
        ExportComponent(HTML_STRING, HTML_CSS, templateData);
    }

    useEffect(() => {
        let temp = []
        for (let [key, value] of Object.entries(selectedRow)) {
            temp.push({
                key: key[0].toUpperCase() + key.slice(1),
                value
            })
        }
        console.log(temp)
        setTableRows(temp)
    }, [])
    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <PopupHeader
                        title={"Full ISIR Historical Report"}
                        navigationList={[]}
                        onClose={onClose}
                    />
                    {/* <h1>H</h1>
                     */}
                    <DefaultTable
                        headCells={customImportFields}
                        rows={tableRows}
                        isLookup={true}
                        pagination={true}
                    />
                    <ButtonWrapperFilter>
                        <Button className='BgYellowOrange search' variant="contained" size="large" type="submit" onClick={fullDetailForm}>
                            {t('buttonLabel.print')}
                        </Button>
                        <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={()=>onClose(false)}>
                            {t('buttonLabel.cancel')}
                        </Button>
                    </ButtonWrapperFilter>
                </CardContent>
            </Card>
        </>
    )
}

export default ShowISIRHistoricalReport