import {
    GET_BALANCE

} from './types';
const intialState = {
    termBalanceAmount: 0,
    term: ""
}

const balanceReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_BALANCE:
            return payload
       
        default:
            return state;
    }
}

export default balanceReducer;

