import {
    GET_CRITERA_TAB,
    GET_REPORTS_TAB,
    RESET_SCHEDULE_MODULE_DATA
} from './types'

const initialState = {
    criteriaTab: {
        term: "",
        singleMultiple: "Single",
        changesinScheduleFrom: null,
        changesinScheduleTo: null,
        namesRangeFrom: "",
        nameTo: "",
        collegeLevel: "",
        advisor: "",
        campus: "",
        enrollementStatus: "",
        extracurricular: "",
        attendanceType: "",
        labelValue: "",
        studentId: "",
        studentName: ""
    },
    reportsTab: {
        message: "",
        addressType: 287,
        reportType: 1,
        scheduleforTheClassRoaster: "",
        courseID: 0
    }
};

const scheduleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERA_TAB:
            return {
                ...state,
                criteriaTab: payload
            };
        case GET_REPORTS_TAB:
            return {
                ...state,
                reportsTab: payload 
            };
        case RESET_SCHEDULE_MODULE_DATA:
            return initialState;
        
        default:
            return state;
    }
};

export default scheduleReducer;