import React from "react";
import PopupHeader from "../../../common-page/popupHeader";
import {
    Button,
    Card,
    CardContent,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
    FormGroup,
    ButtonWrapperFilter,
    useStyles} from "../../../../styles/common-styles";
import {
    SelectInput,

} from "../../../../components/Forms";
import { useTranslation } from "react-i18next";
import { financialAid_customISIR } from "../../../../services/navigation_folder/financialAid";


const AddCustomISRRecord = ({ onClose, accessFlag }) => {

    const { t } = useTranslation([
        "translation",
        "financialAid",
        "financialAidHeadcells",
    ]);

    const initialValues = {
        addCustomISRRecords: "",
        fieldName: "",
        fieldValue: ""

    };


    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values);
        },
    });
    const classess = useStyles();

    React.useEffect(() => {
        document.getElementsByTagName("html")[0].classList.add("popup-Page");
    }, []);
    return (
        <>
            <PopupHeader
                title={(t('commonLabel.addCustomISRRecords', { ns: "financialAid" }))}
                onClose={onClose}
                navigationList={financialAid_customISIR}

            />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className={classess.root}
            >
                <Card>
                    <CardContent>
                        <FormGroup>
                            <SelectInput
                                label={(t('commonLabel.fieldName', { ns: "financialAid" }))}
                                labelId="fieldName"
                                formikValue={formik.values.fieldName}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <SelectInput
                                label={(t('commonLabel.fieldValue', { ns: "financialAid" }))}
                                labelId="fieldValue"
                                formikValue={formik.values.fieldValue}
                                formikChange={formik.handleChange}
                                disabled={accessFlag}
                            />
                            <ButtonWrapperFilter  className='flexStart'>
                                <Button disabled={accessFlag} className="BgLightBlue" variant="contained" size="large" type="submit">
                                    {t("buttonLabel.add", { ns: 'financialAid' })}
                                </Button>
                            </ButtonWrapperFilter>
                        </FormGroup>
                    </CardContent>
                </Card>
            </form>
        </>

    );
};



export default AddCustomISRRecord;