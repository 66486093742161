import { GET_CRITERIA_TAB } from './types'
const initialState = {
    criteriaTab: ''
};
const powerfaidExportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: payload
            };
        default:
            return state;
    }
}
export default powerfaidExportReducer;