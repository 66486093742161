import { UPDATE_ROLE_ROWS, UPDATE_USERINROLE_ROWS, SELECTED_USERINROLE_ROWS } from './types'
const initialState = {
    roleTab: {
        roleRows: [],
        selectedRole: ""

    },
    userInRoleTab: {
        selectedUserInRole: [],
        userInRoleRows: [{
            selected: 'selected',
            lastName: 'lastname',
            firstName: 'first name',
            middleName: 'middle name',
            campus: 'campus',
            department: 'department',
        }],
    },
};
const courseManagementRolesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_ROLE_ROWS:
            return {
                ...state,
                roleTab: {
                    ...state.roleTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_USERINROLE_ROWS:
            return {
                ...state,
                userInRoleTab: {
                    ...state.userInRoleTab, [payload['id']]: payload['value']
                }
            };


        case SELECTED_USERINROLE_ROWS:
            return {
                ...state,
                userInRoleTab: {
                    ...state, selectedUserInRole: payload
                }

            };


        default:
            return state;
    }
}
export default courseManagementRolesReducer