import { UPDATE_LOAD_PROSPECT ,RESET_STATE} from './types'

const initialState = {
    loadProspects: [],
    completeFlag:false,
    selectedProspects:[],
    payloadArray:[],
    loadProspectFormValues:{
            transferTypeID:0,
            transferStatusID:0,
            chargeAppFees:true,
            impOnLineFeePaid:false,
            copyInterest:true,
            copyActivities:true,
            copyCompletedActivities:false,
            deleteActivities:false,
            deleteCompletedActivities:false,
            copyUserDefined:false,
            makeCurrentStudentGPAGroupActive:false,
            deleteProspect:false,
            copyNotes:false,
            notifyStudent:false
    }

}

const loadProspectsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_LOAD_PROSPECT:
            return {
                ...state,
                [payload.id]: payload.value
            }
        case RESET_STATE:
            return {
                ...state,
                loadProspects: [],
                completeFlag: false,
                selectedProspects: [],
                payloadArray: [],
            }
        default:
            return state;
    }
};

export default loadProspectsReducer;