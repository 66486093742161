import { GET_COSTCENTER_TAB } from './types'

const initialState = {
   termValue:0,
   costCenter:[]
};

const costCenterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_COSTCENTER_TAB:
            return {
                ...state,
                ...payload 
            };

        default:
            return state;
    }
};

export default costCenterReducer;