import {
    UPDATE_BATCH_ROWS, UPDATE_SELECTED_BATCH, UPDATE_STUDENT_ROWS, UPDATE_SELECTED_STUDENT, GET_CALCULATE, GET_NONSTUDENT, GET_SUMMARY_CALCULATE,
    UPDATE_NON_STUDENT_TAB, UPDATE_USER_DETAILS, RESET_STATE,UPDATE_EXCHANGE_RATES
} from './types'

const initialState = {
    userDetails: {},
    exchangeRates:{
        getExchangeRangeDetails:{},
        getCurrencyConfiguration:{}
    },
    batch: {
        batchRows: [],
        selectedBatch: {}
    },
    student: {
        studentRows: [],
        selectedStudentRow: {}
    },
    courseTable: [], // docsum
    summaryTable: [],
    nonStudent: {
        nonstudentRows: [],
        selectedNonStudentRow: {}
    },
    nonstudentTable: []
};

const batchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                userDetails: payload
            }
        case UPDATE_EXCHANGE_RATES:
            return {
                ...state,
                exchangeRates: payload
            }
        case UPDATE_BATCH_ROWS:
            return {
                ...state,
                batch: {
                    ...state.batch, batchRows: payload
                }
            }
        case UPDATE_SELECTED_BATCH:
            return {
                ...state,
                batch: {
                    ...state.batch, selectedBatch: payload
                }
            }
        case UPDATE_STUDENT_ROWS:
            return {
                ...state,
                student: {
                    ...state.student, studentRows: payload
                }
            }
        case UPDATE_SELECTED_STUDENT:
            return {
                ...state,
                student: {
                    ...state.student, selectedStudentRow: payload
                }
            }
        case GET_CALCULATE:
            return {
                ...state,
                courseTable: [...payload]
            };
        case GET_NONSTUDENT:
            return {
                ...state,
                nonstudentTable: [...payload]
            };
        case GET_SUMMARY_CALCULATE:
            return {
                ...state,
                summaryTable: [...payload]
            };
        case UPDATE_NON_STUDENT_TAB:
            const { id, value } = payload
            return {
                ...state,
                nonStudent: {
                    ...state.nonStudent, [id]: value
                }
            }
        case RESET_STATE:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
};

export default batchReducer;