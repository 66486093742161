import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import BrandingColors from '../../styles/color';
import { ButtonWrapperFilter } from '../../styles/common-styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .dialog': {
      padding: 24,
      minWidth: '320px'
    },
    '& #alert-dialog-title, & .icon-title-msg, & #alert-dialog-description': {
      fontSize: '0.875rem',
      fontWeight: 600,
      fontFamily: 'Lato-semi-bold',
      padding: 0,
      color: `${BrandingColors.darkGreyFont}`,
      '@media (min-width: 1920px)': {
        fontSize: '18px'
      }
    },
    '& .icon-title-msg': {
      marginLeft: '30px',
      '@media (min-width: 1920px)': {
        fontSize: '18px'
      }
    },
    '& .dialog button': {
      minWidth: '100px',
      height: 'auto',
      marginLeft: '15px',
      fontWeight: 600,
      fontFamily: 'Lato-semi-bold',
      color: `${BrandingColors.darkGreyFont}`,
      '&:hover': {
        backgroundColor: `${BrandingColors.brightTeal}`,
        color: `${BrandingColors.darkBlack}`
      },
      '@media (min-width: 1920px)': {
        fontSize: '18px'
      }
    }
  }
}))

const GenericModal = ({
  open,  //state
  title,
  subtitle,
  buttonText,
  button2Text,
  button3Text,
  icon,
  infoMessage,
  warningMessage,
  error,
  confirmTitle,
  onClose, //state setter
  onButtonPress, // on click buttonText
  onButton2Press, // on click button2Text
  onButton3Press, // on click button3Text
  content,
  children,
  disableButton1,
  dontRenderButtons // hides buttonOne
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const isIcon = icon ? icon : false;
  const hideLoader = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    onClose(false)
  }
  const handleButtonClick = () => {
    onButtonPress && onButtonPress()
    hideLoader()
  }
  const handleButton2Click = () => {
    onButton2Press && onButton2Press()
    hideLoader()
  }
  const handleButton3Click = () => {
    onButton3Press && onButton3Press()
    hideLoader()
  }
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={hideLoader}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
    >
      <div className={'dialog'}>
        <DialogTitle id="alert-dialog-title" className={''}>
          {title ? isIcon ? <span ><HelpOutlineIcon fontSize="large" style={{ color: '#F9861D', position: 'absolute', left: '25px', fontSize: '1.5rem' }} /><span className='icon-title-msg'>{title}</span></span> : title : ''}
          {warningMessage && <span ><ReportGmailerrorredOutlinedIcon fontSize="large" style={{ color: '#F44336', position: 'absolute', left: '25px', fontSize: '1.5rem' }} /><span className='icon-title-msg'>{warningMessage}</span> </span>}
          {infoMessage && <span ><InfoOutlinedIcon fontSize="large" style={{ color: '#0000FF', position: 'absolute', left: '25px', fontSize: '1.5rem' }} /><span className='icon-title-msg'>{infoMessage}</span>   </span>}
          {error && <span ><ErrorOutlineOutlinedIcon fontSize="large" style={{ color: '#F44336', position: 'absolute', left: '25px', fontSize: '1.5rem' }} /><span className='icon-title-msg'>{error?.errMessage}</span>  </span>}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subtitle ? subtitle : ""}
            {content ? content : ''}
            {/* if children is text, use className genericModalChildrenText -- works with p and li */}
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <ButtonWrapperFilter style={{ justifyContent: 'right' }}>
            {
              button2Text &&
              <Button onClick={handleButton2Click} autoFocus={true} className={"BgLightBlue"}>
                {button2Text ? button2Text : t('buttonLabel.cancel')}
              </Button>
            }
            {
              button3Text &&
              <Button onClick={handleButton3Click} autoFocus={true} className={"BgLightBlue"}>
                {button3Text ? button3Text : t('buttonLabel.cancel')}
              </Button>
            }
            {!dontRenderButtons &&
              <Button disabled={disableButton1 ? disableButton1 : false} onClick={handleButtonClick} className={'BgYellowOrange'}>
                {buttonText ? buttonText : t('buttonLabel.ok')}
              </Button>
            }
          </ButtonWrapperFilter>

        </DialogActions>
      </div>
    </Dialog>
  )
}

GenericModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  onClose: PropTypes.func
}


export default GenericModal
