import { SET_TAB_INDEX, UPDATE_RUN_LIST, DELETE_RUN_LIST, GET_RESULT_LIST, REDIRECT_TO_RESULT, REDIRECT_TO_VERIFY, GET_VERIFY_LIST, REFERESH_RUN_LIST, GET_LOAD_PARAMS } from './types'
const initialState = {
    runList: [],
    resultList: [],
    verifyList: [],
    isRedirectToResult: true,
    isRedirectToVerify: true,
    tabIndexValue: 0,
    loadParameter: {
    },
};
const statusChangesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case UPDATE_RUN_LIST:
            return {
                ...state, runList: state.runList.concat(payload)
            }

        case DELETE_RUN_LIST:
            return {
                ...state, runList: state.runList.filter(item => item !== payload)
                //state.runList.splice(state.runList.findIndex(item=>item.processName==='My Test'),1)

                //state.runList.splice(state.runList.indexOf(payload), 1)
            }

        case REFERESH_RUN_LIST:
            return {
                ...state, runList: payload
            }
        case GET_LOAD_PARAMS: {
            return {
                ...state,
                loadParameter: { ...payload }
            };
        }
        case GET_RESULT_LIST:
            return {
                ...state, resultList: payload
            }
        case GET_VERIFY_LIST:
            return {
                ...state, verifyList: payload
            }
        case REDIRECT_TO_RESULT:
            return {
                ...state, isRedirectToResult: payload
            }
        case SET_TAB_INDEX:
            return {
                ...state, tabIndexValue: payload
            }
        case REDIRECT_TO_VERIFY:
            return {
                ...state, isRedirectToVerify: payload
            }
        default:
            return state;
    }
}
export default statusChangesReducer