import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import BrandingColors from '../../styles/color';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { PromptDialog, SuccessPopup } from '../confirmPopup/ConfirmDialog';
import { updateFormStatusSelector } from '../../redux/common/formValueChange/selectors'
import { prospectData } from '../../redux/admisssion/prospects/addProspects/selectors'
import TabContext from './tab-helpers/TabContext'
import GenericModal from '../../components/Modal/GenericModal';
import { useTranslation } from 'react-i18next';
// import Tooltips from '../../utils/Tooltip/Tooltip';
// import Tooltip from '@mui/material/Tooltip';
import { Tooltip } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    '& .MuiTabs-flexContainer':{
      display: "block"
    },
    '& .MuiTabs-root':{
      borderBottom: `1px solid ${BrandingColors.brightOrange}`,
      padding: "20px 20px 0 20px"
    },
    '& .MuiTabScrollButton-horizontal.Mui-disabled': {
      display: 'none'
    }, '& .MuiTabs-indicator': {
      backgroundColor: "transparent"
    },
    '& .MuiTab-textColorPrimary': {
      textTransform: 'none',
      padding: '0 16px',
      whiteSpace: 'nowrap',
      color: BrandingColors.darkGreyFont,
      fontFamily: 'FiraSans-Regular',
      fontWeight: '600',
      fontSize: '14px',
      '&.Mui-selected': {
        padding: '0 15px',
        color: BrandingColors.darkBlack,
        background: BrandingColors.hoverBlue,
        border: `1px solid ${BrandingColors.textBord}`,
        borderBottom: '0px',
        fontFamily: 'FiraSans-Regular',
        fontWeight: '600',
        fontSize: '14px'
      },
      '@media (min-width: 1920px)':{
        fontSize: '18px'
      },
      
    },
    '& .hide': {
      display: 'none'
    },
    '& .show': {
      display: 'block',
      position: 'relative',
      zIndex: 999
    },
    '& .MuiCardContent-root':{
      padding: '20px'
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, formStatus, showTabs, questions, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={(!showTabs) ? 'shadow' : 'non-shadow'}
      style={{
        borderRadius: 0,
        marginTop: 0,
        position: 'relative',
        zIndex: questions ? null : 0,
        boxShadow: 'none'
      }}
    >
      {/* commenting the condition for to load all the html */}
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ScrollableTabsButtonVisible = ({ TabLabels, variant, showTab, tabSwitch, tabValue, alertMsg, parentDisabledConfirmPopup, onCBTabChange, questions = false }) => {

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [prevTab, setPrevNewTab] = React.useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();



  const { formStatus, mandatoryField } = useSelector(updateFormStatusSelector);
  const { prospect } = useSelector(prospectData);

  const [currentForm, setCurrentForm] = useState([])
  const [nextTab, setNextTab] = useState(0)
  const [previousTab, setPreviousTab] = useState(null)
  const [value, setValue] = React.useState(0); //currentTab, setCurrentTab

  const [formIsSaved, setFormIsSaved] = useState(true)
  const [flg, setFlg] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setPrevNewTab(value)
  }, [value])

  const [showTabs, setShowTabs] = useState(false);
  const [isCreate, setCreate] = useState(false)
  const [isLoad, setLoad] = useState(false)

  const [newValue, setNewValue] = useState(0);
  useEffect(() => {
    setShowTabs(showTab);
  }, [showTab])

  const handleChange = (event, newValue) => {
    setNextTab(newValue);
    setPreviousTab(value);
    onTabChange(newValue); //It will call the onTabChange event
    (onCBTabChange) && onCBTabChange();
  };

  const onTabChange = (newValue) => {
    let formToCheck = currentForm[value]
    // if currentTab === 0
    if (value === 0) {
      formToCheck.validateForm().then(errors => {
        if (Object.keys(errors).length) {
          // if(value==0){  //For first Tab just check the error and submit the form
          formToCheck.submitForm()
          return
          // } else {
          // setValue(newValue)
          // }

        } else if (parentDisabledConfirmPopup === true) {
          currentForm[value].submitForm();
          setValue(newValue)
          return
        } else if (!formIsSaved) {  //No error and checking something changed/dirty
          setConfirmOpen(true)
        } else if (tabSwitch) {
          setCreate(tabSwitch)
        } else if (tabValue) {
          setValue(tabValue)
        } else {
          setValue(newValue)
        }
      })
    }
    else if (!formIsSaved) {  //No error and checking something changed/dirty
      setConfirmOpen(true)
    } else {
      setValue(newValue)
    }
  }

  const classes = useStyles();

  const getCurrentTabsFormSnapshot = (index, form, ...args) => {
    currentForm[index] = form
    setCurrentForm(currentForm)
  }

  const onSave = () => {
    currentForm[value].submitForm();
    setValue(nextTab)
    setFormIsSaved(true)
  }

  useEffect(() => {
    let isScrollableTab = document.getElementsByClassName('MuiTabScrollButton-root');
    if (isScrollableTab.length) {
      document.getElementsByTagName('html')[0].classList.add('scroll-tab-page');
      document.getElementsByTagName('html')[0].classList.remove('not-scroll-tab-page');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('scroll-tab-page');
      document.getElementsByTagName('html')[0].classList.add('not-scroll-tab-page');
    }
  }, [])

  return (
    <>
      <div className={classes.root}>
        {(!showTabs) &&
          <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }} className={'show'}>
            <Tabs value={value}
              onChange={handleChange}
              variant={variant}
              aria-label="visible arrows tabs example"
              scrollButtons
              allowScrollButtonsMobile
            >
              {TabLabels.map((v, i) => {
                return <Tab label={v.text} {...a11yProps(i)} key={i} disabled={v.component.props.disableFlag} />
              })}
            </Tabs>
          </Box>
        }
      </div>
      {TabLabels.map((v, i) => {
        return (
          <TabContext.Provider key={i} value={{ getCurrentTabsFormSnapshot, value, nextTab, previousTab, formIsSaved, setFormIsSaved, handleChange }}>
            <TabPanel value={value} key={i} index={i} formStatus={formStatus} showTabs={showTabs} questions={questions}>
              {v.component}
            </TabPanel>
          </TabContext.Provider>
        )
      })}
      {/* <ConfirmDialog title="Do you want to save the changes?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => { onSave(); setConfirmOpen(false) }}
        onCancel={(e) => { }}
        btnTextOne={'Save'}
        btnTextTwo={'Cancel'} /> */}

      <GenericModal
        open={confirmOpen}
        title={t('popupMessages.confirmToSave')}
        buttonText={t('buttonLabel.save')}
        button2Text={t('buttonLabel.cancel')}
        onButtonPress={(e) => {
          onSave()
        }}
        onClose={() => { setConfirmOpen(false); }}
      />
      <PromptDialog title={t('validationMessages.mandatory')}
        open={prompt}
        setOpen={setPrompt}
        onConfirm={(e) => { }}
      />
      <SuccessPopup title={t('popupMessages.successfullyUpdated')}
        open={success}
        setOpen={setSuccess}
        confirmText={""}
        onConfirm={(e) => { setSuccess(false) }}
      />
      <GenericModal title={(alertMsg) ? alertMsg : ""}
        open={isCreate} onClose={setCreate}
        buttonText={t('buttonLabel.ok')}
      />
    </>
  );
}

export default ScrollableTabsButtonVisible;