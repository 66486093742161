import { FORM_LIST_SELECTED, QUESTION_LIST_SELECTED } from './types'

const initialState = {
    formList: '',
    questionList: ''
};

const crsEvalMasterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FORM_LIST_SELECTED:
            return {
                ...state,
                formList: payload
            };
        case QUESTION_LIST_SELECTED:
            return {
                ...state,
                questionList: payload
            };
        default:
            return state;
    }
}
export default crsEvalMasterReducer;