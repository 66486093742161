import React, { useState } from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import BrandingColors from '../../styles/color';
import { formatTime } from '../../helper';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTooltip-popper': {
            pointerEvents: 'auto',
            '& span.close': {
                cursor: 'pointer'
            }
        },
        '& .MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow,& .MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow': {
            width: '2.13em',
            height: '3em',
            marginLeft: '-2.13em',
            color: `${BrandingColors.lightBluetooltipBg}`
        },
        '& .MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow': {
            marginRight: '-2.13em',
        },
        '& .MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow::before,& .MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow::before': {
            border: `1px solid ${BrandingColors.lightBluetooltipBorder}`,
        },
        '& .MuiTooltip-tooltipPlacementRight,& .MuiTooltip-tooltipPlacementLeft': {
            textAlign: 'right',
            minWidth: '350px',
            background: `${BrandingColors.lightBluetooltipBg}`,
            color: `${BrandingColors.darkBlack}`,
            border: `1px solid ${BrandingColors.lightBluetooltipBorder}`,
            '& ul': {
                textAlign: 'left',
                '& li': {
                    display: 'flex',
                    paddingBottom: '10px',
                    '& span': {
                        width: '50%'
                    }
                }
            }
        }
    }
}));


const Tooltips = (props) => {
    const [open, setOpen] = useState(false);
    const { placement, iconSize, flag, row, voided, voidedFlag = false, crsEvalFlag = false } = props;
    const classes = useStyles();
    const list = row ? row : { insertUserID: "", insertTime: '', updateUserID: '', updateTime: '' }

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(() => setOpen(false), 2000);
    };

    return (
        <div className={classes.root}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement={placement ? placement : 'right'}
                arrow
                title={
                    <React.Fragment>
                        <span className={'close'} onClick={handleTooltipClose}>X</span>
                        <ul>
                            {(!voidedFlag && !crsEvalFlag) && <> <li><span>Created By : <strong>{list.insertUserID}</strong></span> <span>On : {list.insertTime && formatTime(list.insertTime)}</span></li>
                                <li><span>Changed By : <strong>{list.updateUserID}</strong></span> <span>On : {list.updateTime && formatTime(list.updateTime)}</span></li></>
                            }
                            {/* {voided && voided.map(item => (
                                <li><span style={{width:'100%'}}>{item.key} :  <strong>{item.value}</strong></span></li>
                            ))
                            } */}
                            {voided && <> <li><span>Voided By : <strong>{voided.whoVoided}</strong></span> <span>on : {voided.whenVoided && formatTime(voided.whenVoided)}</span></li>
                                <li><span>Voided Reason : <strong>{voided.whyVoided}</strong></span></li></>}

                            {crsEvalFlag && <>
                                <li>
                                    <span>Who Created : <strong>{list.insertUserID}</strong></span>
                                    <span>When Created : {list.insertTime && formatTime(list.insertTime)}</span>
                                </li>
                                <li>
                                    <span>Who Changed : <strong>{list.updateUserID}</strong></span>
                                    <span>When Changed : {list.updateTime && formatTime(list.updateTime)}</span>
                                </li>
                            </>
                            }

                        </ul>
                    </React.Fragment>
                }
            >
                <i onClick={handleTooltipOpen} className="material-icons-outlined" style={{ cursor: 'pointer', fontSize: `${iconSize ? iconSize : '40px'}`, border: '2px solid', borderRadius: '50%', color: `${(flag) ? BrandingColors.lightBluetooltipBorder : 'grey'}`, pointerEvents: `${(flag) ? 'auto' : 'none'}`, display: `${(flag) ? 'block' : 'none'}` }}>history_toggle_off</i>
            </Tooltip>
        </div>
    )
}

export default Tooltips;