import { UPDATE_TAB_VALUE} from './types'

const initialState = {
    tabValue: 0 
};

const updateTabValueReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_TAB_VALUE:
            return {
                ...state,
                tabValue: payload
            };
        default:
            return state;
    }
};

export default updateTabValueReducer;