import { COPY_AMENITY, EDIT_AMENITY, VIEW_AMENITY, ADD_AMENITY } from './types'

const initialState = {
    copyAmenity: ''
};

const amenitiesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case COPY_AMENITY:
            return {
                ...state,
                amenity: payload,
                formmode:type
            };
        case VIEW_AMENITY:
            return {
                ...state,
                amenity: payload,
                formmode: type
            };
        case EDIT_AMENITY:
            return {
                ...state,
                amenity: payload,
                formmode: type
            };
        case ADD_AMENITY:
            return {
                ...state,
                amenity: null,
                formmode: type
            };

        default:
            return state;
    }
}
export default amenitiesReducer