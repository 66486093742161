import { UPDATE_USER, GET_CONFIGURATION } from "./types";


const initialState = {
    user: {},
    configuration: {}
};

const updateUserReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_USER:
            return {
                ...state,
                user: payload
            };
        case GET_CONFIGURATION:
            return {
                ...state,
                configuration: payload
            };
        default:
            return state;
    }
};

export default updateUserReducer;