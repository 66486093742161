import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Card, CardContent, Button } from '@material-ui/core';
import PopupHeader from '../../../common-page/popupHeader';
import { SelectInput, DateInput, TextInput, CheckBoxInputFilter } from '../../../../components/Forms';
import { FormGroup,  useStyles, ButtonWrapperFilter, } from '../../../../styles/common-styles';
import { useTranslation } from 'react-i18next';
import { getApi, postApi } from '../../../../services/apiBase';
import { document_tracking, prospect_document_tracking, prospect_document_tracking_post} from '../../../../services/apiURL';
import { getDescription } from '../../../../helper/getDescription';
import { toastMessage } from '../../../../utils/Message/toasts';
import * as yup from 'yup';
import { admissions_loadColleges} from '../../../../services/navigation_folder/admissions'

const LoadColleges = ({setIsloading,setTableRow,module,Dropdown,selectedStudent,selectedDoc, setShowAddData, setShowColleges }) => {
    const { t } = useTranslation();
    const username = localStorage.getItem("username");
    const classess = useStyles();
    const validationSchema = yup.object({
        docDate: yup
            .date()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.documentDate') }))
            .nullable()
            .max(new Date('12/12/2100')) // date max range 2100
            .min(new Date('12/12/2000'))
            .typeError(''),
            docNameId: yup
            .number()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.documentName') })),
            docStatusId: yup
            .number()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.status') })),

    });
    const formik = useFormik({
        initialValues: {
            docDate: new Date(),
            docNameId:"",
            docDescription:'',
            docStatusId:"",
            internal:false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsloading(true);
            let payload =
            {
                "docTrackId": selectedDoc.docTrackId ? selectedDoc.docTrackId : 0,
                "prospectID": selectedStudent.prospectId ? selectedStudent.prospectId : 0,
                "docDate": values.docDate,
                "docNameId": values.docNameId,
                "docDescription": values.docDescription,
                "docStatusId": values.docStatusId,
                "comments": "",
                "reference":"",
                "compDate": null,
                "userDefinedFieldId": 0,
                "finAidYearSeq": 0,
                "docGroupID": 0,
                "locationId": module.moduleId,
                "admissionGpagroupId": 0,
                "internal": values.internal,
                "isProspectDoc": true,
                "userId":username
            }
            postApi(`${prospect_document_tracking_post}?requestFrom=LOADCOLLEGES`, [payload]).then((res) => {
                onCancel();
                getApi(`${prospect_document_tracking}/${selectedStudent.prospectId}/${-1}`).then((resp) => {
                    setTableRow(resp.data.data);
                    setIsloading(false);
                    toastMessage(t('popupMessages.successIcon'),t('popupMessages.success'),t('popupMessages.successfullyAdded'))
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err)
            })
        },
    })
    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    },[])
    useEffect(()=>{
        formik.setFieldValue("docDescription",getDescription(Dropdown.documentNames,formik.values.docNameId));
    },[formik.values.docNameId])
    const onCancel = () => {
        setShowColleges(false)
        setShowAddData(true)
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    }
    return (
        <>
            <PopupHeader profileData={{profileName: selectedStudent.prospectName, profileId: selectedStudent.prospectId, Id: true }} title={t('commonLabel.loadColleges')} onClose={onCancel} navigationList={admissions_loadColleges} />

            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                         <TextInput label={t('commonLabel.location')}  labelId={'module'}
                                    formikValue={module.moduleName}  disabled={true} />
                        <DateInput mandatory={true} label={t('commonLabel.documentDate')} labelId={"docDate"} formik={formik} defaultValue={formik.values.docDate} errors={formik.errors.docDate} touched={formik.touched.docDate} />
                        <SelectInput mandatory={true} label={t('commonLabel.documentName')} labelId={"docNameId"} formikValue={formik.values.docNameId} formikChange={formik.handleChange} 
                         dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentNames }}
                         errors={formik.errors.docNameId} touched={formik.touched.docNameId}
                        />
                        <TextInput label={t('commonLabel.description')} labelId={'docDescription'} formikValue={formik.values.docDescription} formikChange={formik.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput mandatory={true} label={t('commonLabel.status')} labelId={"docStatusId"} formikValue={formik.values.docStatusId} formikChange={formik.handleChange}
                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.status }}
                            errors={formik.errors.docStatusId} touched={formik.touched.docStatusId}
                            />
                            <CheckBoxInputFilter disabled={true} label={t('commonLabel.internal')} labelId={'internal'} formikValue={formik.values.internal} formikChange={formik.handleChange} />
                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <div style={{ display: 'flex' }}>
                            <ButtonWrapperFilter className="activityButton">
                                <Button  aria-label={'Save'} className='BgYellowOrange' variant="contained" size="large" type="submit">
                                {t('buttonLabel.ok')}
                                </Button>
                                <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={onCancel}>
                                {t('buttonLabel.cancel')}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    )
}

export default LoadColleges;