import { SEARCH_FACULTY, 
//     MERGE_PROSPECTS, REVIEW_PROSPECTS, LOAD_CITIZENSHIP, MASTER_RECORD, CHILD_RECORD, ALLOW_PROSPECTS
// ,SELECTED_MERGE_PROSPECTS,SELECTED_MASTER_PROSPECTS,SEARCH_PROSPECTS_MERGE 
} from './types'

const initialState = {
    allFaculty: [],
    // allProspectsMerge:[],
    // mergeList: [],
    // reviewList: [],
    // citizenship: [],
    // masterRecord: [],
    // childRecord: [],
    // allowProspects: false,
    // selectedMergeRows: [],
    // selectedMasterRecord:[],
};

const searchFacultyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_FACULTY:
            return {
                ...state,
                allFaculty: [...payload]
            };
        // case SEARCH_PROSPECTS_MERGE:
        //     return {
        //         ...state,
        //         allProspectsMerge: [...payload]
        //     };
        // case MERGE_PROSPECTS:
        //     return {
        //         ...state,
        //         mergeList: [...payload],
        //     };
        // case REVIEW_PROSPECTS:
        //     return {
        //         ...state,
        //         reviewList: [...payload],
        //     };
        // case SELECTED_MERGE_PROSPECTS:
        //     return {
        //         ...state,
        //         selectedMergeRows: [...payload],
        //     };
        //     case SELECTED_MASTER_PROSPECTS:
        //         return {
        //             ...state,
        //             selectedMasterRecord: [...payload],
        //         };
        // case LOAD_CITIZENSHIP:
        //     return {
        //         ...state,
        //         citizenship: [...payload],
        //     };
        // case MASTER_RECORD:
        //     return {
        //         ...state,
        //         masterRecord: [...payload],
        //     };
        // case CHILD_RECORD:
        //     return {
        //         ...state,
        //         childRecord: [...payload],
        //     };
        // case ALLOW_PROSPECTS:
        //     return {
        //         ...state,
        //         allowProspects: payload,
        //     };
        default:
            return state;
    }
};

export default searchFacultyReducer;