import {
    SAVE_SOURCE,
    SAVE_DOCUMENT,
    SAVE_DOCUMENT_ID,
    SAVE_COURSES,
    SAVE_TESTS,
    SAVE_EXPORT
} from './types'
const initialState = {
    source: {},
    document: {},
    documentId: 0,
    courses: {},
    tests: {},
    export: {}
};
const speedeCollegeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SAVE_SOURCE:
            return {
                ...state,
                source: payload
            };
        case SAVE_DOCUMENT:
            return {
                ...state,
                document: payload
            };
        case SAVE_DOCUMENT_ID:
            return {
                ...state,
                documentId: payload
            };
        case SAVE_COURSES:
            return {
                ...state,
                courses: payload
            };
        case SAVE_TESTS:
            return {
                ...state,
                tests: payload
            };

        case SAVE_EXPORT:
            return {
                ...state,
                export: payload
            };
        default:
            return state;
    }
}
export default speedeCollegeReducer;