import { UPDATE_AUTH_NAMES_LIST } from './types'

const initialState = {
    names: [],
    reduxTableData: [],
    formData:{}
};

const authNamesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_AUTH_NAMES_LIST:
            return {
                ...state,
                 ...payload
            }
           
        default:
            return state;
    }
};

export default authNamesReducer;