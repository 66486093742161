import { ADD_HIGH_SCHOOL, DELETE_HIGH_SCHOOL, DELETE_CODE, ADD_CODE, SEARCH_CRITERIA } from './type'

const initialState = {
    highSchool: [],
    code: [],
    searchCriteria: ""
};

const lookupsHighSchoolReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_HIGH_SCHOOL:
            return {
                ...state,
                highSchool: [...payload]
            }
        case DELETE_HIGH_SCHOOL:
            return {
                ...state,
                highSchool: [...state.highSchool.filter(item => !payload.includes(item.studentHighSchoolID.toString()))]
            }
        case ADD_CODE:
            return {
                ...state,
                code: [...payload]
            }
        case DELETE_CODE:
            return {
                ...state,
                code: [...state.code.filter(item => !payload.includes(item.codeID.toString()))]
            }
        case SEARCH_CRITERIA:
            return {
                ...state,
                searchCriteria:{...(state.searchCriteria),...payload}
            }
        default:
            return state;
    }
};

export default lookupsHighSchoolReducer;