import {
    GET_OFFERINGTAB_FORMS, GET_SEARCH_COURSES, GET_BOOKLIST,
    GET_WAITINGLIST,
    GET_COURSEFEE
} from './types'

const initialState = {
    offeringTabFormValues: {
        "termCalendarID": 0,
        "courseName": "",
        "department": "",
        "courseID": "",
        "courseType": "",
        "section": "",
        "campusID": "-1",
        "page": 1,
        "pageSize": 10000 //This is the temporary changes for bug-241590
    },
    courseTable: [],
    booklistTable: [],
    waitingListTable: [],
    courseFeesTable: []
};

const offeringMaintenanceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_OFFERINGTAB_FORMS:
            return {
                ...state,
                offeringTabFormValues: { ...payload }
            };
        case GET_SEARCH_COURSES:
            return {
                ...state,
                courseTable: [...payload]
            };
        case GET_BOOKLIST:
            return {
                ...state,
                booklistTable: [...payload]
            };
        case GET_WAITINGLIST:
            return {
                ...state,
                waitingListTable: [...payload]
            };
        case GET_COURSEFEE:
            return {
                ...state,
                courseFeesTable: [...payload]
            };
        default:
            return state;
    }
};

export default offeringMaintenanceReducer;