import { ADD_IDENTIFIER, DELETE_IDENTIFIER, ADD_STUDENT_IDENTIFIER,  DELETE_STUDENT_IDENTIFIER} from './type'

const initialState = {
    identifier: [
    ],
    studentIdentifier: [
    ]
};

const identifierReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_IDENTIFIER:
            return {
                ...state,
                identifier: [...payload]
            }
        case DELETE_IDENTIFIER:
            return {
                ...state,
                identifier: [...state.identifier.filter(item => !payload.includes(item.id.toString()))]
            }
        case ADD_STUDENT_IDENTIFIER:
            return {
                ...state,
                studentIdentifier: [...payload]
            }
        case DELETE_STUDENT_IDENTIFIER:
            return {
                ...state,
                studentIdentifier: [...state.studentIdentifier.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default identifierReducer;