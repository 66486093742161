import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import styled from 'styled-components';
import { Breadcrumb, BreadcrumbLink } from '../../utils/Breadcrumbs/Breadcrumbs';
import { useSelector } from 'react-redux';
import { searchDataSelector } from '../../redux/common/search-data/selectors';
import HeaderTitle from '../../utils/HeaderTitle/HeaderTitle';


const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0;
    span{
        flex:1 1 0;
    }
    .Icon{
        font-size: 1em;
        cursor: pointer;
    }
`
const SubTitle = styled.h2`
    margin-bottom:15px;
    font-size:18px;
    font-weight:600;
    @media (min-width:1920px) {
        font-size: 24px;
    }
`
const PopupHeader = (props) => {
    const { title, subTitle, navigationList, onClose,profileName,profileData, termText } = props;
    const { allData } = useSelector(searchDataSelector);
    
    return (
        <>
           {navigationList.length > 0 && <Breadcrumb profileData={profileData ? profileData : allData.selectedProfile} profileName={profileName} termId={termText} >
                {[...navigationList].map(({ name, isLink, path }, index) => (
                    <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} onHyperAction={()=>onClose(false)} />
                ))}
            </Breadcrumb>}
            
            <Title>
                <ArrowBackIosOutlinedIcon className={'leftIcon Icon'} onClick={()=>onClose(false)}/>
                <HeaderTitle headerTitle={title} help={true} />
                <CloseIcon className={'rightIcon Icon'} onClick={()=>onClose(false)}/>
            </Title>
           { subTitle && <SubTitle>{subTitle}</SubTitle>}
        </>
    )
}

export default PopupHeader;