import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Box, Paper, Typography,Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'
import BrandingColors from '../../styles/color';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CheckBoxInput, SelectInput } from '../../components/Forms';
import { ButtonWrapperFilter, FormGroup, useStyles } from '../../styles/common-styles';
import { Button } from '@material-ui/core';
import { updateLoadProspect } from '../../redux/admisssion/prospects/load-prospects/action';
import { useDispatch,useSelector } from 'react-redux';
import { loadProspectsSelector } from '../../redux/admisssion/prospects/load-prospects/selectors';
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const StyledTitle = styled(BootstrapDialogTitle)`
    background-color : ${BrandingColors.white};
    color: ${BrandingColors.darkBlack};
    border-bottom: 2px solid ${BrandingColors.fontOrangeCustom};
    display: flex;
    button{
        color: ${BrandingColors.darkBlack};
        padding: 0;
        border-radius: 0;
        height:24px;
    }
    span{
        flex:1 1 0;
        line-height: 2;
    }
`
const DialogWrapper = styled.div`
    min-width: 825px;
    min-height: 420px;
`

const ProspectCopyDialog = (props) => {
    const { onClose, popupData } = props;
    const { t } = useTranslation(['translation', 'registration', 'registrationHeadCells']);
    const classes = useStyles();
    const dispatch = useDispatch();
    const validationSchema = yup.object({
        transferTypeID: yup
            .number()
            .min(1, t('validationMessages.isRequired', { what: t('commonLabel.transferAsType') }))
            .required(t('validationMessages.isRequired', { what: t('commonLabel.transferAsType') })),
    });
    const { data, open,dropdownData ,action} = popupData;
    const {loadProspectFormValues} =useSelector(loadProspectsSelector)
    const formik = useFormik({
        initialValues: loadProspectFormValues,
        validationSchema:validationSchema,
        onSubmit: (values) => {
            const payloadArray =[];
            data.forEach((prospect)=>{
                let payload ={...values};
                payload["prospectID"] =prospect.prospectId;
                payload["userID"] =""
                payload["statusText"] = dropdownData.transferStatus.filter(v => v.uniqueId === Number(values.transferStatusID))[0].displayText;
                payloadArray.push(payload);

            })
            dispatch(updateLoadProspect({id:"loadProspectFormValues", value:values}));
            dispatch(updateLoadProspect({id:"payloadArray", value:payloadArray}));
            action()
            onClose(false);
        }
    });
    
    const onClose2 =(event, reason)=>{
        if (reason && reason == "backdropClick")
            return;
            onClose(false)
    }
   
    return (
        <div>
            <Dialog
                onClose={onClose2}
                maxWidth={'lg'}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <StyledTitle id="customized-dialog-title" onClose={onClose2} >
                    <span>{t('prospectLabel.prospectCopyOptions', { ns: 'translation' })}</span>
                </StyledTitle>
                <DialogContent dividers>
                    <DialogWrapper>
                    <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.root}>
                        <FormGroup>
                                <SelectInput
                                    isDoubled
                                    mandatory
                                    label={t('commonLabel.transferAsType', { ns: 'translation' })}
                                    labelId='transferTypeID'
                                    formikValue={formik.values.transferTypeID}
                                    formikChange={formik.handleChange}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropdownData.transferType }}
                                    errors={formik.errors.transferTypeID}
                                    touched={formik.touched.transferTypeID}
                                />
                                <SelectInput
                                isDoubled
                                    label={t('commonLabel.transferAsStatus', { ns: 'translation' })}
                                    labelId='transferStatusID'
                                    formikValue={formik.values.transferStatusID}
                                    formikChange={formik.handleChange}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropdownData.transferStatus }}
                                />
                            </FormGroup>
                            
                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.chargeApplicationFees', { ns: 'translation' })}
                                    labelId={'chargeAppFees'}
                                    formikValue={formik.values.chargeAppFees}
                                    checked={formik.values.chargeAppFees}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.importOnlineFeePaid', { ns: 'translation' })}
                                    labelId={'impOnLineFeePaid'}
                                    formikValue={formik.values.impOnLineFeePaid}
                                    checked={formik.values.impOnLineFeePaid}
                                    formikChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.copyInterest', { ns: 'translation' })}
                                    labelId={'copyInterest'}
                                    checked={formik.values.copyInterest}
                                    formikValue={formik.values.copyInterest}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.copyIncompleteActivities', { ns: 'translation' })}
                                    labelId={'copyActivities'}
                                    checked={formik.values.copyActivities}
                                    formikValue={formik.values.copyActivities}
                                    formikChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.copyCompleteActivities', { ns: 'translation' })}
                                    labelId={'copyCompletedActivities'}
                                    checked={formik.values.copyCompletedActivities}
                                    formikValue={formik.values.copyCompletedActivities}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.deleteIncompleteActivities', { ns: 'translation' })}
                                    labelId={'deleteActivities'}
                                    checked={formik.values.deleteActivities}
                                    formikValue={formik.values.deleteActivities}
                                    formikChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.deleteCompletedActivities', { ns: 'translation' })}
                                    labelId={'deleteCompletedActivities'}
                                    checked={formik.values.deleteCompletedActivities}
                                    formikValue={formik.values.deleteCompletedActivities}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.copyUserDefined', { ns: 'translation' })}
                                    labelId={'copyUserDefined'}
                                    checked={formik.values.copyUserDefined}
                                    formikValue={formik.values.copyUserDefined}
                                    formikChange={formik.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.keepCurrentStudent', { ns: 'translation' })}
                                    labelId={'makeCurrentStudentGPAGroupActive'}
                                    checked={formik.values.makeCurrentStudentGPAGroupActive}
                                    formikValue={formik.values.makeCurrentStudentGPAGroupActive}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.deleteProspect', { ns: 'translation' })}
                                    labelId={'deleteProspect'}
                                    checked={formik.values.deleteProspect}
                                    formikValue={formik.values.deleteProspect}
                                    formikChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.copyNotepad', { ns: 'translation' })}
                                    labelId={'copyNotes'}
                                    checked={formik.values.copyNotes}
                                    formikValue={formik.values.copyNotes}
                                    formikChange={formik.handleChange} />
                                <CheckBoxInput
                                    isDoubled
                                    label={t('commonLabel.notifyStudentofPortalAccess', { ns: 'translation' })}
                                    labelId={'notifyStudent'}
                                    checked={formik.values.notifyStudent}
                                    formikValue={formik.values.notifyStudent}
                                    formikChange={formik.handleChange} />
                            </FormGroup>
                                <ButtonWrapperFilter className='centerButton'>
                                    <Button className={'BgYellowOrange search'} variant="contained" size="large" type="submit">
                                        {t('buttonLabel.load')}
                                    </Button>
                                <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={onClose2}>
                                    {t('buttonLabel.cancel')}
                                </Button>
                                </ButtonWrapperFilter>
                        </form>
                    </DialogWrapper>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default React.memo(ProspectCopyDialog);