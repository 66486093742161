import { Card, CardContent, Button } from '@material-ui/core'
import { useFormik } from 'formik';
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import DefaultTable from '../../../../utils/Table/DefaultTable';
import { Splitter, useStyles, ButtonWrapperFilter, FormGroup } from '../../../../styles/common-styles';
import HeaderTitle from '../../../../utils/HeaderTitle/HeaderTitle';
import AddMcItems from './addMcItems';
import { SelectInput, TextInput, TextAreaInput } from '../../../../components/Forms';
import TabContext from '../../../../utils/ScrollableTab/tab-helpers/TabContext';
import { useSelector } from 'react-redux';
import { CRSEvalMasterSelector } from '../../../../redux/tools/CRSEvalMaster/mass-add-audit/selectors';
import { deleteApi, getApi, putApi } from '../../../../services/apiBase';
import { crs_eval_master_mcitem, crs_eval_master_question_Dropdown, crs_link } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';
import GenericModal from '../../../../components/Modal/GenericModal';
import { updateAccessSelector } from '../../../../redux/common/roleAccess/selectors';
import { evaluationSetup } from "../../../../helper/constants";

const MCItems = ({ addData, tabIndex }) => {
    const { t } = useTranslation(["translation", "toolsHeadCells", "tools"]);

    const classess = useStyles()
    const { access } = useSelector(updateAccessSelector)
    const { nestedParentLevel } = access;
    const { questionList } = useSelector(CRSEvalMasterSelector);

    const [mcItems] = useState([
        { id: 'mcResponseID', numeric: true, disablePadding: false, label: t('mcItems.mcResponse', { ns: 'toolsHeadCells' }) },
        { id: 'comment', numeric: true, disablePadding: false, label: t('mcItems.comment', { ns: 'toolsHeadCells' }) },
        { id: 'mC1Resp', numeric: true, disablePadding: false, label: t('mcItems.mc1Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC1Weight', numeric: true, disablePadding: false, label: t('mcItems.mc1Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC2Resp', numeric: true, disablePadding: false, label: t('mcItems.mc2Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC2Weight', numeric: true, disablePadding: false, label: t('mcItems.mc2Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC3Resp', numeric: true, disablePadding: false, label: t('mcItems.mc3Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC3Weight', numeric: true, disablePadding: false, label: t('mcItems.mc3Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC4Resp', numeric: true, disablePadding: false, label: t('mcItems.mc4Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC4Weight', numeric: true, disablePadding: false, label: t('mcItems.mc4Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC5Resp', numeric: true, disablePadding: false, label: t('mcItems.mc5Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC5Weight', numeric: true, disablePadding: false, label: t('mcItems.mc5Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC6Resp', numeric: true, disablePadding: false, label: t('mcItems.mc6Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC6Weight', numeric: true, disablePadding: false, label: t('mcItems.mc6Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC7Resp', numeric: true, disablePadding: false, label: t('mcItems.mc7Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC7Weight', numeric: true, disablePadding: false, label: t('mcItems.mc7Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC8Resp', numeric: true, disablePadding: false, label: t('mcItems.mc8Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC8Weight', numeric: true, disablePadding: false, label: t('mcItems.mc8Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC9Resp', numeric: true, disablePadding: false, label: t('mcItems.mc9Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC9Weight', numeric: true, disablePadding: false, label: t('mcItems.mc9Weight', { ns: 'toolsHeadCells' }) },
        { id: 'mC10Resp', numeric: true, disablePadding: false, label: t('mcItems.mc10Resp', { ns: 'toolsHeadCells' }) },
        { id: 'mC10Weight', numeric: true, disablePadding: false, label: t('mcItems.mc10Weight', { ns: 'toolsHeadCells' }) },
        { id: 'insertUserId', numeric: true, disablePadding: false, label: t('mcItems.insertUserId', { ns: 'toolsHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('mcItems.insertTime', { ns: 'toolsHeadCells' }) },
        { id: 'updateUserId', numeric: true, disablePadding: false, label: t('mcItems.updateUser', { ns: 'toolsHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('mcItems.updateTime', { ns: 'toolsHeadCells' }) },
    ])
    const initialValues = {
        multipleChoiceGroup: "",
        question: (questionList?.questionText) ? questionList.questionText : ''
    }
    const [formValues, setFormValues] = useState(initialValues)

    const formik = useFormik({
        initialValues: formValues,
        onSubmit: (values) => {
        },
    })

    const [tableRow, settableRow] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getData = () => {
        setIsLoading(true)
        getApi(`${crs_eval_master_mcitem}`).then((resp) => {
            settableRow(resp.data.data)
            setIsLoading(false)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const [showAddData, setShowAddData] = useState(false)
    const handleEditRow = (value) => {
        setShowAddData(value);
        addData(true)
    }
    const handleDeleteRow = () => {
        if (selectedRow?.mcResponseID) {
            setIsLoading(true);
            deleteApi(`${crs_eval_master_mcitem}/${selectedRow?.mcResponseID}`).then((resp) => {
                if (resp.data.isSuccess) {
                    toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                    DefaultTable.resetTable("mcItems");
                    getData();
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
        }
    }
    const handleAddMcItems = (value) => {
        setShowAddData(value);
        setSelectedRow('');
        addData(true)
    }
    const [selectedRow, setSelectedRow] = useState('');
    const onClose = (value) => {
        setShowAddData(value);
        addData(false)
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
        getData();
    }

    const {
        getCurrentTabsFormSnapshot
    } = useContext(TabContext);

    useEffect(() => {
        getCurrentTabsFormSnapshot(tabIndex, formik);
    }, []);

    const handleRowSelect = (val) => {
        setSelectedRow(val)
    }

    const [Dropdown, setDropdown] = useState({
        "multipleChoiceGroup": [],
    })
    useEffect(() => {
        getApi(crs_eval_master_question_Dropdown).then((resp) => {
            const {
                multipleChoiceGroup
            } = resp.data.data
            setDropdown({
                ...Dropdown,
                "multipleChoiceGroup": (multipleChoiceGroup) ? multipleChoiceGroup : [],
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const [linkAlerts, setLinkAlerts] = useState({ errShow: false, errMessage: [] });
    const [mcAlerts, setMCAlerts] = useState({ errShow: false, errMessage: [] });


    const handleLinkSelect = () => {
        if (selectedRow) {
            const { mcResponseID } = selectedRow
            const { questionID, isMC } = questionList
            if(isMC === 'Yes'){
                setLinkAlerts({ errShow: true, errMessage: [`Link Response ${mcResponseID} to Question ${questionID}`] })
            } else{
                setMCAlerts({ errShow: true, errMessage: [`You must select a multiple choice type question first.`] })
            }
        }
    }

    const handleLinkYes = () => {
        if (selectedRow) {
            const { mcResponseID } = selectedRow
            const { questionID } = questionList
            putApi(`${crs_link}/${questionID}/${mcResponseID}`).then((resp) => {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), 'Successfully Linked');
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const [evaluationSetupAccess, setEvaluationSetupAccess] = useState({
        evaluationSetup : 2,  
       })
       useEffect(() => {
         let evaluationSetupAccess = nestedParentLevel.filter((v, i) =>   
           v.menuItemName.trim() === evaluationSetup && v.moduleorder === 15  
         )  
         setEvaluationSetupAccess({ 
           ...evaluationSetupAccess, 
           evaluationSetup: (evaluationSetupAccess.length > 0) ? evaluationSetupAccess[0].menuAccess : 2,  
         })
       }, [access])   
       const [accessFlag, setAccessFlag] = useState(false)  
       useEffect(() => { 
         (evaluationSetupAccess.evaluationSetup === 1) ? setAccessFlag(true) : setAccessFlag(false) 
       }, [evaluationSetupAccess])
            //Role Based Access implementation ends here

    return (
        <>
            {(!showAddData) ?
                <>
                    <GenericModal
                        open={linkAlerts['errShow']}
                        onClose={() => setLinkAlerts({ errShow: false, errMessage: [] })}
                        buttonText='Yes'
                        button2Text='No'
                        onButtonPress={handleLinkYes}
                        onButton2Press={() => setLinkAlerts({ errShow: false, errMessage: [] })}
                    >
                        <section>
                            <p><strong>{linkAlerts.errMessage[0]}</strong></p>
                        </section>
                    </GenericModal>
                    <GenericModal
                        open={mcAlerts['errShow']}
                        onClose={() => setMCAlerts({ errShow: false, errMessage: [] })}
                        buttonText='Ok'
                    >
                        <section>
                            <p><strong>{mcAlerts.errMessage[0]}</strong></p>
                        </section>
                    </GenericModal>

                    <form onSubmit={formik.handleSubmit} className={classess.root}>
                        <Card>
                            <CardContent>
                                <HeaderTitle  disableButton={evaluationSetupAccess.evaluationSetup !== 2 ? true : false} headerTitle={t('commonLabels.multipleChoiceItemsSetup', { ns: 'tools' })} buttonText={t('buttonLabel.add')} addData={handleAddMcItems} variant={'subHeading'} />
                                <SelectInput
                                disabled={accessFlag}
                                    label={t("commonLabels.multipleChoiceGroup", { ns: "tools" })}
                                    labelId={"multipleChoiceGroup"}
                                    formikValue={formik.values.multipleChoiceGroup}
                                    formikChange={formik.handleChange}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.multipleChoiceGroup }}
                                />
                                <DefaultTable
                                    trackingLabel={"mcItems"}
                                    headCells={mcItems}
                                    rows={tableRow}
                                    onEdit={handleEditRow}
                                    pagination={true}
                                    onDelete={handleDeleteRow}
                                    isLoading={isLoading}
                                    onRowSelect={handleRowSelect}
                                    uniqueKey={'mcResponseID'}
                                    deleteDisable={evaluationSetupAccess.evaluationSetup !== 2 ? true : false}
                                />
                                <Splitter height="50px" />
                                <FormGroup className='align-center'>
                                    <TextAreaInput isDoubled disabled
                                        labelId="question"
                                        label={t('commonLabels.question', { ns: "tools" })}
                                        formikValue={formik.values.question}
                                        formikChange={formik.handleChange}
                                    />
                                    <ButtonWrapperFilter>
                                        <Button
                                            className='BgYellowOrange search'
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            disabled={(selectedRow) || !(accessFlag) ? false : true}
                                            onClick={handleLinkSelect}
                                        >
                                            {"Select"}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </FormGroup>
                            </CardContent>

                        </Card>
                    </form>
                </> :
                <AddMcItems onClose={onClose} accessFlag={accessFlag}  selectedRow={selectedRow} />
            }
        </>


    )
}
export default MCItems