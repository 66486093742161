import { ADD_MASTER_LIST } from './type'

const initialState = {
    masterList: []
};

const masterCourseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_MASTER_LIST:
            return {
                ...state,
                masterList: [...payload]
            }
        default:
            return state;
    }
};

export default masterCourseReducer;