export const GET_OUTPUT_TYPE = 'GET_OUTPUT_TYPE';
export const UPDATE_OUTPUT_TYPE = 'UPDATE_OUTPUT_TYPE';
export const GENERAL_TABLE ='GENERAL_TABLE';
export const GET_GENERAL_LEDGER_CHECK_POST = 'GET_GENERAL_LEDGER_CHECK_POST';
export const GET_GENERAL_LEDGER_SELECT_ITEMS = 'GET_GENERAL_LEDGER_SELECT_ITEMS';
export const GET_GENERAL_LEDGER_POST= 'GET_GENERAL_LEDGER_POST';
export const GET_LOAD_PARAMS= 'GET_LOAD_PARAMS';
export const GET_LOAD_PARAMS_ACCOUNT_PAYABLE_POST= 'GET_LOAD_PARAMS_ACCOUNT_PAYABLE_POST';
export const GET_ACCOUNT_PAYABLE__POST_INFO_SELECT_ITEMS= 'GET_ACCOUNT_PAYABLE__POST_INFO_SELECT_ITEMS';
export const GET_POST_TRANSCATION= 'GET_POST_TRANSCATION';
export const GET_ACCOUNT_PAYABLE__OPTIONS= 'GET_ACCOUNT_PAYABLE__OPTIONS';
export const GET_ACCOUNT_PAYABLE__POSTINFO= 'GET_ACCOUNT_PAYABLE__OPTIONS';
export const GET_RECREATE_ACCOUNT_PAYABLE_HISTORY="GET_RECREATE_ACCOUNT_PAYABLE_HISTORY";
export const GET_LOAD_PARAMS_PAYABLE = 'GET_LOAD_PARAMS_PAYABLE'

