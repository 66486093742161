import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, CardContent, TextareaAutosize } from "@material-ui/core";
import { useFormik } from "formik";
import {
  SelectInput,
  TextInput,
  CheckBoxInput,
  DateInput,
  TextAutoInput
} from "../../../../components/Forms";

import { FormGroup, ButtonWrapperFilter, useStyles, Splitter, } from "../../../../styles/common-styles";

import { useTranslation } from "react-i18next";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { financialAid_AddAwardDetails } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import * as yup from "yup";
import { getApi, postApi, putApi } from "../../../../services/apiBase";
import { award_select_items, award_type_details, award_valid_limits, add_awards } from "../../../../services/apiURL";
import currencyPipe, { parseNumber, formNumber } from '../../../../helper/currency';
import { toastMessage } from "../../../../utils/Message/toasts";
import GenericModal from "../../../../components/Modal/GenericModal";
import DefaultTable from '../../../../utils/Table/DefaultTable';
import moment from 'moment';
import CustomLoader from "../../../../components/Loader/Loader";

const AddAwardDetails = ({ onClose, selectedSelected, fyDropDown, selectedAwards, dropDown,  selectedStatusRow,financialAwards ,userDetails,awardRows, accessFlag}) => {
  const codAwardTypeArray = ["DLSubsidized", "DLUnsubsidized", "DLPLUS", "DLGraduatePLUS"];
  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);
  const [typeDetails, setTypeDetails] = useState({
    "awardReferenceGridList": [],
    "yearlyCODList": []
  })

  const [calendarYearDetails, setCalendarYearDetails] = useState({
    "calendarYearList": []
  })

    //Checking Super User and binding the Campus ID
    let superUser = localStorage.getItem('superUser')
    let campusID = localStorage.getItem('campusID')

    const [defaultValueCampus, setDefaultValueCampus] = useState(0)

  const classess = useStyles();
  const initCurrencyFields = (value = 0) => {
    return transform(parseNumber(value))
  }
  const transform = (val) => {
    return currencyPipe(val, { symbol: '', decimal: '.', separator: ',', precision: 2 }).format();
  }
  const onSwipeCurrency = (e) => {
    const { name, value } = e.target;
    const amount = transform(parseNumber(value));
    formik.setFieldValue(name, amount);
   
  }

  let campusDefaultValue = (superUser === 'N') ? Number(campusID) : defaultValueCampus;
  const initialValues = {
    sentToBilling: "",
    billingBatchName: "",
    effectiveDate: "",
    packagedinStatus: selectedStatusRow.financialStatusID,
    term: 0,
    distributionDate: new Date(),
    awardTypeID: "",
    transStatusID: "",
    amount: initCurrencyFields(0),
    distAmount: initCurrencyFields(0),
    description: "",
    reference: "",
    creditStatusID: "",
    code1ID: "",
    code2ID: "",
    showOnBillStatement: "No",
    checkSignedOn: null,
    requiredHours: 0,
    studentAccepted: 3,
    transID: "",
    lockAward: false,
    campus: (superUser === 'N') ? Number(campusID) : defaultValueCampus,
    paymentPeriodStartDate: null,
    paymentPeriodEndDate: null,
    additionalCODEligibilityAward: false,
    interestRebatePercent: "",
    originationFeePercent: "",
    originationFeeAmount: initCurrencyFields(0),
    interestRebateAmount: initCurrencyFields(0),
    calendarYear: ""
  };

  const validationSchema = yup.object({
    packagedinStatus: yup
      .number()
      .min(1,t("commonLabel.selectAStatusValueToPackage",{ ns: "financialAid" }))
      .required(t("validationMessages.isRequired", { what: t("commonLabel.packagedinStatus", { ns: "financialAid" }), })),
    term: yup.number()
      .min(1, t('validationMessages.isRequired', { what: t('commonLabel.term') }))
       .required(t('validationMessages.isRequired', { what: t('commonLabel.term') })),
    distributionDate: yup
      .date().nullable()
      .required(t("validationMessages.isRequired", { what: t("commonLabel.distributionDate", { ns: "financialAid" }), })),
 
    awardTypeID: yup
      .string("")
      .required(t("validationMessages.isRequired", { what: t("commonLabel.awardType", { ns: "financialAid" }), })),
 
    amount: yup
      .string("")
      .required(t("validationMessages.isRequired", { what: t("commonLabel.amount", { ns: "financialAid" }), })),

  });

  const validate = (values,props)=> {
    const errors = {}; 

    if (values.calendarYear.length == 0 && reqCalendarYear == true)
    {
        errors.calendarYear = "Calendar Year is required because COD Award Type (FWS or FSEOG) is selected";
    }

    return errors;
};

  const [formValues, setFormValues] = useState(initialValues);
  const [showDirectLoan, setDirectLoan] = useState(false);
  const [disAwrdType, setDisAwrdFlag] = useState(false);
  const [disAmtType, setDisAmtFlag] = useState(false);
  const [confirmPopup, setConfirmPop] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("$");
  const [toolTipStatus, setToolTipStatus] = React.useState('');
  const [disCODAEI, setDisCODAEIFlag] = useState(true);
  const [reqCalendarYear, setReqCalendarYear] = useState(false);
  
  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validate: validate,
    onSubmit: (values) => {
      let payload =
      {
        "financialAwardId": selectedAwards?.financialAwardId ? selectedAwards?.financialAwardId : 0,
        "financialStatusId": selectedStatusRow?.financialStatusID,
        "termCalendarId": values.term,
        "transTypeId": values.awardTypeID,
        "amount": formNumber(values.amount)
      }
      postApi(award_valid_limits, payload).then((resp) => {
        if (resp.data.data == "") {
          addOrUpdateAwards(values);
          return
        }
        setConfirmPop(true)
      }).catch((err) => {

      })
    },
  });

  

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
    if(dropDown.campus.length>0){
      setDefaultValueCampus(Number(dropDown.campus.filter(v => v.displayText == '<Select one>')[0].uniqueId))
    }
    setIsloading(true)
    getApi(`${award_type_details}`).then((resp) => {
      const { awardReferenceGridList, yearlyCODList } = resp.data.data;

      setTypeDetails({
        ...typeDetails,
        "awardReferenceGridList": awardReferenceGridList,
        "yearlyCODList": yearlyCODList
      })
      // Generate years from 2022 to the current year
      const calendarYearList = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        years.push({ uniqueId: "", displayText: "" });
        for (let year = currentYear + 1 ; year >= 2022; year--) {
          years.push({ uniqueId: year, displayText: year.toString() });
        }
        return years;
      };
      setCalendarYearDetails({
        ...calendarYearDetails,
        "calendarYearList": calendarYearList()
      })
      if (Object.keys(selectedAwards)?.length > 0) {
        setFormValues({
          ...formValues,
          sentToBilling: selectedAwards.sentToBilling,
          billingBatchName: selectedAwards.billingBatchName,
          effectiveDate: selectedAwards.effectiveTransDate,
          packagedinStatus: selectedAwards.financialStatusId,
          term: selectedAwards.termCalendarId,
          distributionDate: selectedAwards.distributionDate,
          awardTypeID: selectedAwards.transTypeId,
          transStatusID: selectedAwards.transStatusId,
          amount: transform(selectedAwards.amount),
          distAmount: transform(selectedAwards.estimatedAmount),
          description: selectedAwards.description,
          reference: selectedAwards.referenceNo,
          creditStatusID: selectedAwards.creditStatusId,
          code1ID: selectedAwards.code1Id,
          code2ID: selectedAwards.code2Id,
          showOnBillStatement: selectedAwards.showOnBillingStatement,
          checkSignedOn: selectedAwards.dateCheckSigned,
          requiredHours: selectedAwards.requiredCredits,
          studentAccepted:  (selectedAwards.studentAccepted == true) ? 1 : (selectedAwards.studentAccepted == false) ? 0 :  3 ,
          transID: selectedAwards.transId,
          lockAward: selectedAwards.lock,
          campus: selectedAwards.campusId,
          paymentPeriodStartDate: selectedAwards.paymentPeriodStartDate,
          paymentPeriodEndDate: selectedAwards.paymentPeriodEndDate,
          additionalCODEligibilityAward: selectedAwards.codaei,
          originationFeeAmount:selectedAwards.originationFeeAmount,
          interestRebateAmount:selectedAwards.interestRebateAmount,
          calendarYear: (selectedAwards.calendarYear  == 0 || selectedAwards.calendarYear == null) ? "" :  selectedAwards.calendarYear
        });
        
        const awardsReferenceData = awardReferenceGridList.filter(row => row.awardTypeID === selectedAwards.transTypeId);
        if (awardsReferenceData.length > 0) {
          if ((awardsReferenceData[0].codAwardType == "DLUnsubsidized") || (awardsReferenceData[0].codAwardType == "Pell")) {
                setDisCODAEIFlag(false);
          } else {
                setDisCODAEIFlag(true);
                formik.setFieldValue("additionalCODEligibilityAward", false);      
          }
          if ((awardsReferenceData[0].codAwardType == "FWS") || (awardsReferenceData[0].codAwardType == "FSEOG")) {
              setReqCalendarYear(true);              
          } else { setReqCalendarYear(false); }
        }

        (selectedAwards.sentToBilling === "Yes") ? setDisAwrdFlag(true) : setDisAwrdFlag(false)
        setToolTipStatus('edit');
        setIsloading(false)
      }else{
        if (financialAwards?.length > 0) {
          const sortedArrayOnInsertTime = financialAwards.sort(function (date1, date2) {
            return moment.utc(date1.insertTime).diff(moment.utc(date2.insertTime))
          });
          sortedArrayOnInsertTime.length > 0 && formik.setFieldValue("term", sortedArrayOnInsertTime[0].termCalendarId)
        }else{
          formik.setFieldValue("term",userDetails?.lastTerm);
        }
        setIsloading(false)
      }
    }).catch((err) => {
      setIsloading(false)
      console.log(err);
    })
  }, []);
  useEffect(()=>{
    if(formik.values.amount){
      updateDisAmount(formik.values.awardTypeID)
    }
    
  },[formik.values.amount])
  
  const updateDisAmount=(awardTypeID)=>{
      if(awardTypeID){
        let origAmount = formNumber(formik.values.amount)
        const awardsReferenceData = typeDetails?.awardReferenceGridList.filter(row => row.awardTypeID === awardTypeID);
        if (awardsReferenceData.length > 0) {
          if (awardsReferenceData[0].codAwardType!="" && codAwardTypeArray.includes(awardsReferenceData[0].codAwardType)) {
            setDirectLoan(true);
            setDisAmtFlag(true)

              const perCentRow =typeDetails.yearlyCODList.filter(row=> row.academicYearID === selectedStatusRow.academicYearUID && row.awardTypeID== awardTypeID)
              if(perCentRow.length>0){
                const{ interestRebatePercent, originationFeePercent } = perCentRow[0];
                setInterests(interestRebatePercent, originationFeePercent)
                return
              
              //Amount will be updated by backend
            }
            setInterests(0, 0);
          }else if(awardsReferenceData[0].codAwardType==""){
            const{ awardPercentage} = awardsReferenceData[0];
            let disAmt = ((awardPercentage/100) * origAmount).toFixed(2);
            formik.setFieldValue("distAmount",transform(disAmt));
            setDisAmtFlag(false)
          }else{
            formik.setFieldValue("distAmount",transform(origAmount));
            setDisAmtFlag(true)
          }

        
        }
      }
  }


  const setInterests = (interestRebatePercent, originationFeePercent) => {
    formik.setFieldValue("interestRebatePercent", interestRebatePercent);
    formik.setFieldValue("originationFeePercent", originationFeePercent)
  }
  const addOrUpdateAwards = (values=formik.values) => {
    if (!Object.keys(selectedAwards)?.length > 0) {
      if(window.location.href.indexOf('stagethesissmlite') > -1 || window.location.href.indexOf('demothesissmlite') > -1){
          if(values.campus == 36){
            values.campus = 1;
          }
          if(values.campus == 0){
            campusDefaultValue = 0;
          }

          
      }

      
      let payload = {
        "financialStatusId": values.packagedinStatus,
        "studentUid": selectedSelected.studentUID,
        "termCalendarId": values.term,
        "transDate": values.distributionDate,
        "transTypeId": values.awardTypeID,
        "transStatusId": values.transStatusID ? values.transStatusID : 0,
        "amount": formNumber(values.amount),
        "estimatedAmount": formNumber(values.distAmount),
        "description": values.description,
        "referenceNo": values.reference,
        "creditStatusId": values.creditStatusID ? values.creditStatusID : 0,
        "code1Id": values.code1ID ? values.code1ID : 0,
        "code2Id": values.code2ID ? values.code2ID : 0,
        "showOnBillingStatement": values.showOnBillStatement,
        "dateCheckSigned": values.checkSignedOn,
        "requiredCredits": values.requiredHours ? values.requiredHours : 0,
        "studentAccepted": values.studentAccepted,
        "transId": values.transID,
        "lock": values.lockAward,
        "campusId": values.campus ? values.campus : campusDefaultValue,
        "paymentPeriodStartDate": values.paymentPeriodStartDate,
        "paymentPeriodEndDate": values.paymentPeriodEndDate,
        "codaei": values.additionalCODEligibilityAward,
        "originationFeeAmount": formNumber(values.originationFeeAmount),
        "interestRebateAmount": formNumber(values.interestRebateAmount),
        "calendarYear": values.calendarYear ? values.calendarYear : 0
      }
      postApi(add_awards, payload).then((resp) => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'));
        DefaultTable.resetTable('financialAwards');
        onClose(false)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      if(window.location.href.indexOf('stagethesissmlite') > -1 || window.location.href.indexOf('demothesissmlite') > -1){
        if(values.campus == 36){
          values.campus = 1;
        }
        if(values.campus == 0){
          campusDefaultValue = 0;
        }

        
    }
      let payloadRequest = {
        "financialStatusId": values.packagedinStatus,
        "studentUid": selectedSelected.studentUID,
        "termCalendarId": values.term,
        "transDate": values.distributionDate,
        "transTypeId": values.awardTypeID,
        "transStatusId": values.transStatusID ? values.transStatusID : 0,
        "amount": formNumber(values.amount),
        "estimatedAmount": formNumber(values.distAmount),
        "description": values.description,
        "referenceNo": values.reference,
        "creditStatusId": values.creditStatusID ? values.creditStatusID : 0,
        "code1Id": values.code1ID ? values.code1ID : 0,
        "code2Id": values.code2ID ? values.code2ID : 0,
        "showOnBillingStatement": values.showOnBillStatement,
        "dateCheckSigned": values.checkSignedOn,
        "requiredCredits": values.requiredHours ? values.requiredHours : 0,
        "studentAccepted":  values.studentAccepted,
        "transId": String(values.transID),
        "lock": values.lockAward,
        "campusId": values.campus ? values.campus : campusDefaultValue,
        "paymentPeriodStartDate": values.paymentPeriodStartDate,
        "paymentPeriodEndDate": values.paymentPeriodEndDate,
        "codaei": values.additionalCODEligibilityAward,
        "originationFeeAmount":values.originationFeeAmount,
        "interestRebateAmount": values.interestRebateAmount,
        "interestRebatePercent": Number(values.interestRebatePercent),
        "originationFeePercent": Number(values.originationFeePercent),
        "calendarYear": values.calendarYear ? values.calendarYear : 0
      }
      console.log(payloadRequest)

      putApi(`${add_awards}/${selectedAwards?.financialAwardId}`, payloadRequest).then((resp) => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
        DefaultTable.resetTable('financialAwards'); 
        onClose(false);
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  const handleAwardTypeChange= (e)=>{
    if(e.target.value){
      formik.setFieldValue("awardTypeID",e.target.value)
      const awardsReferenceData = typeDetails?.awardReferenceGridList.filter(row => row.awardTypeID === e.target.value);
      if (awardsReferenceData.length > 0) {
        formik.setFieldValue("awardTypeID", awardsReferenceData[0].awardTypeID)
        formik.setFieldValue("description", awardsReferenceData[0].description)
        formik.setFieldValue("additionalCODEligibilityAward", false)
        if ((awardsReferenceData[0].codAwardType == "DLUnsubsidized") || (awardsReferenceData[0].codAwardType == "Pell")) {
          setDisCODAEIFlag(false);
        } else {
          setDisCODAEIFlag(true);
        }
        formik.setFieldValue("creditStatusID", awardsReferenceData[0].creditStatusID)
        formik.setFieldValue("transStatusID", awardsReferenceData[0].transStatusID)
        formik.setFieldValue("code1ID", awardsReferenceData[0].code1ID)
        formik.setFieldValue("code2ID", awardsReferenceData[0].code2ID)
        formik.setFieldValue("showOnBillStatement", awardsReferenceData[0].showOnBillStatement)
        updateDisAmount(e.target.value)
        if ((awardsReferenceData[0].codAwardType == "FWS") || (awardsReferenceData[0].codAwardType == "FSEOG")) {
            setReqCalendarYear(true);
        } else {
            setReqCalendarYear(false);
            //delete formik.errors.calendarYear;
            delete formik.touched.calendarYear;
        }
      }
    }
  }
  return (
    <>
     {isLoading ? <CustomLoader /> : ""}
      {confirmPopup && <GenericModal title={t('popupMessages.youWantToContinue',{ns:'translation'})}
        buttonText='Yes' button2Text='No' open={confirmPopup}
        onClose={setConfirmPop} help={true}
        onButtonPress={addOrUpdateAwards}
      />}
      <PopupHeader
        title={
          !Object.keys(selectedAwards)?.length > 0
            ? t("commonLabel.addAwardDetails", {
              ns: "financialAid",
            })
            : t("commonLabel.updateAwardDetails", {
              ns: "financialAid",
            })
        }
        navigationList={financialAid_AddAwardDetails}
        onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.billingReferences", { ns: "financialAid" })} />
            <FormGroup>
              <TextInput
                disabled
                label={t("commonLabel.sentToBilling", { ns: "financialAid" })}
                labelId="sentToBilling"
                formikValue={formik.values.sentToBilling}
                formikChange={formik.handleChange}
              />
              <TextInput
                disabled
                label={t("commonLabel.billingBatchName", { ns: "financialAid" })}
                labelId="billingBatchName"
                formikValue={formik.values.billingBatchName}
                formikChange={formik.handleChange}
              />
              <TextInput
                disabled
                label={t("commonLabel.effectiveDate", { ns: "financialAid" })}
                labelId="effectiveDate"
                formikValue={formik.values.effectiveDate}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <Splitter height={"10px"} />
            <HeaderTitle variant={'subHeading'}
              headerTitle={t(
                "commonLabel.financialAwardEntryDist", { ns: "financialAid" }
              )}
            />
            <FormGroup>
              <SelectInput
                label={t("commonLabel.packagedinStatus", { ns: "financialAid" })}
                labelId="packagedinStatus"
                formikValue={formik.values.packagedinStatus}
                errors={formik.errors.packagedinStatus}
                touched={formik.touched.packagedinStatus}
                disabled={accessFlag}
                formikChange={formik.handleChange} fyDropDown
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: fyDropDown }}
              />
            </FormGroup>
            <FormGroup>
              <TextAutoInput
                label={t('commonLabel.term')}
                labelId='term'
                formik={formik}
                DropdownData={dropDown.terms}
                errors={formik.errors.term}
                disabled={accessFlag}
                touched={formik.touched.term}
                mandatory
              />
              <DateInput
                label={t("commonLabel.distributionDate", { ns: "financialAid" })}
                labelId={"distributionDate"}
                errors={formik.errors.distributionDate}
                touched={formik.touched.distributionDate}
                defaultValue={formik.values.distributionDate}
                formik={formik}
                disabled={accessFlag}
                mandatory
              />
              <SelectInput
                label={t("commonLabel.awardType", { ns: "financialAid" })}
                labelId="awardTypeID"
                errors={formik.errors.awardTypeID}
                touched={formik.touched.awardTypeID}
                formikValue={formik.values.awardTypeID}
                formikChange={handleAwardTypeChange}
                mandatory
                disabled={disAwrdType || accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.awardType }}
              />
              <SelectInput
                label={t("commonLabel.awardStatus", { ns: "financialAid" })}
                labelId="transStatusID"
                formikValue={formik.values.transStatusID}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.awardStatus }}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                label={t("commonLabel.amount", { ns: "financialAid" })  + " "+ baseCurrency}
                labelId="amount"
                errors={formik.errors.amount}
                touched={formik.touched.amount}
                formikValue={formik.values.amount}
                formikChange={formik.handleChange}
                onBlur={onSwipeCurrency}
                disabled={accessFlag}
                maxValue={999999999.99}
                mandatory
                onlyNumeric 
              />
              <TextInput
                label={t("commonLabel.distAmount", { ns: "financialAid" }) + " "+ baseCurrency}
                labelId="distAmount"
                formikValue={formik.values.distAmount}
                formikChange={formik.handleChange}
                onBlur={onSwipeCurrency}
                maxValue={999999999.99}
                onlyNumeric 
                disabled={disAmtType || accessFlag}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                label={t("commonLabel.description", { ns: "financialAid" })}
                labelId="description"
                formikValue={formik.values.description}
                formikChange={formik.handleChange}
                maxlength={120}
                disabled={accessFlag}
              />
              <TextInput
                label={t("commonLabel.referenceNumber", { ns: "financialAid" })}
                labelId="reference"
                formikValue={formik.values.reference}
                formikChange={formik.handleChange}
                maxlength={50}
                disabled={accessFlag}
              />
              <SelectInput
                label={t("commonLabel.creditStatus", { ns: "financialAid" })}
                labelId="creditStatusID"
                formikValue={formik.values.creditStatusID}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.creditStatus }}
              />
              <SelectInput
                label={t("commonLabel.code1", { ns: "financialAid" })}
                labelId="code1ID"
                formikValue={formik.values.code1ID}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.code1 }}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                label={t("commonLabel.code2", { ns: "financialAid" })}
                labelId="code2ID"
                formikValue={formik.values.code2ID}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.code2 }}
              />
              <SelectInput
                label={t("commonLabel.showOnStatement", { ns: "financialAid" })}
                labelId="showOnBillStatement"
                formikValue={formik.values.showOnBillStatement}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.showOnStatementList }}
              />
              <DateInput
                label={t("commonLabel.checkSignedOn", { ns: "financialAid" })}
                labelId={"checkSignedOn"}
                defaultValue={formik.values.checkSignedOn || null}
                formik={formik}
                disabled={accessFlag}
              />
              <TextInput
                label={t("commonLabel.requiredHours", { ns: "financialAid" })}
                labelId="requiredHours"
                formikValue={formik.values.requiredHours}
                formikChange={formik.handleChange}
                onlyNumeric={true}
                disabled={accessFlag}
                minValue={0}
                maxValue={2147483647}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                label={t("commonLabel.studentAccepted", { ns: "financialAid" })}
                labelId="studentAccepted"
                formikValue={formik.values.studentAccepted}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.studentAccepted }}
              />
              <TextInput
                label={t("commonLabel.transID", { ns: "financialAid" })}
                labelId="transID"
                formikValue={formik.values.transID}
                formikChange={formik.handleChange}
                maxlength={50}
                disabled={accessFlag}
                onlyAlphanumeric
              />
              <CheckBoxInput
                label={t("commonLabel.lockAward", { ns: "financialAid" })}
                labelId={"lockAward"}
                formikValue={formik.values.lockAward}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                checked={formik.values.lockAward}
              />
              <SelectInput
                label={t("commonLabel.campus", { ns: "financialAid" })}
                labelId="campus"
                formikValue={formik.values.campus}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.campus }}
              />
            </FormGroup>
            <FormGroup>
              <DateInput
                label={(t('commonLabel.paymentPeriodStartDate', { ns: "financialAid" }))}
                labelId={"paymentPeriodStartDate"}
                defaultValue={formik.values.paymentPeriodStartDate}
                formik={formik}
                disabled={accessFlag}
              />
              <DateInput
                label={t("commonLabel.paymentPeriodEndDate", { ns: "financialAid" })}
                labelId={"paymentPeriodEndDate"}
                defaultValue={formik.values.paymentPeriodEndDate}
                formik={formik}
                disabled={accessFlag}
              />

              <SelectInput
                label={t("commonLabel.calendarYear", { ns: "financialAid" })}
                labelId="calendarYear"
                errors={formik.errors.calendarYear}
                touched={formik.touched.calendarYear}
                formikValue={formik.values.calendarYear}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: calendarYearDetails.calendarYearList }}
                mandatory={reqCalendarYear}
              />

              <div style={{display: disCODAEI ? 'none' : 'block'}}>
              <CheckBoxInput
                label={t("commonLabel.codAEI", { ns: "financialAid" })}
                labelId={"additionalCODEligibilityAward"}
                formikValue={formik.values.additionalCODEligibilityAward}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                checked={formik.values.additionalCODEligibilityAward}                
              />
              </div>

            </FormGroup>
          </CardContent>

          <CardContent style={{ display: showDirectLoan ? 'block' : 'none' }}>
            <>
              <HeaderTitle variant={'subHeading'}
                headerTitle={t(
                  "commonLabel.directLoanHeading", { ns: "financialAid" }
                )}
              />
              <FormGroup>
                <TextInput
                  label={t("commonLabel.originationFeePercent", { ns: "financialAid" })}
                  labelId="originationFeePercent"
                  formikValue={formik.values.originationFeePercent}
                  formikChange={formik.handleChange}
                  disabled
                />
                <TextInput
                  label={t("commonLabel.amount", { ns: "financialAid" }) + " "+ baseCurrency}
                  labelId="originationFeeAmount"
                  formikValue={formik.values.originationFeeAmount}
                  formikChange={formik.handleChange}
                  onBlur={onSwipeCurrency}
                  disabled
                />
                <TextInput
                  label={t("commonLabel.interestRebatePercent", { ns: "financialAid" })}
                  labelId="interestRebatePercent"
                  formikValue={formik.values.interestRebatePercent}
                  formikChange={formik.handleChange}
                  disabled
                />
                <TextInput
                  label={t("commonLabel.amount", { ns: "financialAid" }) + " "+  baseCurrency}
                  labelId="interestRebateAmount"
                  formikValue={formik.values.interestRebateAmount}
                  formikChange={formik.handleChange}
                  onBlur={onSwipeCurrency}
                  disabled
                />
              </FormGroup>
            </>
          </CardContent>
          <CardContent>
            <div style={{ display: "flex" }}>
              <Tooltips flag={(Object.keys(selectedAwards).length > 0) ? true : false} row={(Object.keys(selectedAwards).length) ? {
                insertUserID: selectedAwards.insertUserId,
                insertTime: selectedAwards.insertTime,
                updateUserID: selectedAwards.updateUserId,
                updateTime: selectedAwards.updateTime
              } : ''} />
              <ButtonWrapperFilter className="activityButton">
                <Button
                  className="BgYellowOrange search"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={accessFlag}
                >
                  {!Object.keys(selectedAwards)?.length > 0
                    ? t("buttonLabel.add", { ns: "financialAid" })
                    : t("buttonLabel.update", { ns: "financialAid" })}
                </Button>
                <Button
                  aria-label={"Cancel"}
                  className="BgLightBlue"
                  variant="contained"
                  size="large"
                  onClick={() => onClose("")}
                >
                  {t("buttonLabel.cancel", { ns: "financialAid" })}
                </Button>
              </ButtonWrapperFilter>
            </div>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
export default React.memo(AddAwardDetails);
