import { ADD_ACTIVITY, DELETE_ACTIVITY, ADD_STUDENT_ACTIVITY, DELETE_STUDENT_ACTIVITY, ADD_ACTIVITY_SEQUENCES, ADD_STUDENT_ACTIVITY_SEQUENCES } from './type'
const initialState = {
    activity: [
    ],
    activitySequences: [
    ],
    studentActivity: [
    ],
    studentActivitySequences: [
    ]
};

const activityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_ACTIVITY:
            return {
                ...state,
                activity: [...payload]
            }
        case ADD_ACTIVITY_SEQUENCES:
            return {
                ...state,
                activitySequences: [...payload]
            }
        case DELETE_ACTIVITY:
            return {
                ...state,
                activity: state.activity.filter((item, ind) =>
                    payload[0].indexOf(ind.toString()) == -1)
            }
        case ADD_STUDENT_ACTIVITY:
            return {
                ...state,
                studentActivity: [...payload]
            }
        case ADD_STUDENT_ACTIVITY_SEQUENCES:
            return {
                ...state,
                studentActivitySequences: [...payload]
            }
        case DELETE_STUDENT_ACTIVITY:
            return {
                ...state,
                studentActivity: state.studentActivity.filter((item, ind) =>
                    payload[0].indexOf(ind.toString()) == -1)
            }
        default:
            return state;
    }
};

export default activityReducer;