export const registration_search_catalogCourse = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Search Catalog Course', isLink: false }
];
export const registration_print_roaster = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Roster', path: '/registration/offering/roster', isLink: true },
    { name: 'Print Roster', isLink: false }
];
export const registration_print_offering = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Print Offering', isLink: false }
];
export const registration_Schedule_registration = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Schedule Registration', isLink: false }
]
export const registration_addSchedule_registration = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Schedule Registration', path: '/registration/schedule-registration', isLink: true },
    { name: 'Add/Update Schedule Registration', isLink: false }
];
export const registration_offering_maintenance = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', isLink: false }
];
export const registration_offering_maintenance_courseScheduling = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: ' Course Schedule Detail', isLink: false }

];
export const registration_offering_maintenance_addSchedule = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: ' Add/Update Course Schedule Detail', isLink: false }

];
export const registration_offering_maintenance_addMeetingRoomAndTime = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: ' Add/Update Flexible Room Schedule Detail', isLink: false }

];
export const registration_offering_maintenance_addFacultyDetail = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: ' Add/Update Faculty Schedule Detail', isLink: false }

];
export const registration_offering_maintenance_courseFacultyDetail = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: ' Add/Update Course Faculty Detail', isLink: false }

];
export const registration_offering_maintenance_courseBookList = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Book List', path: '/registration/offering/offering-maintenance/book-list', isLink: true },
    { name: 'Add/Update Book List', isLink: false }
];
export const registration_offering_maintenance_courseWaitingList = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Waiting List', path: '/registration/offering/offering-maintenance/waiting-list', isLink: true },
    { name: 'Add Student To Waiting List', isLink: false }
];
export const registration_offering_maintenance_courseFee = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Course Fees', path: '/registration/offering/offering-maintenance/course-fees', isLink: true },
    { name: 'Add/Update Course Fees', isLink: false }
];
export const registration_roomLookup = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Room Lookup Criteria', isLink: false }

];
export const registration_massStudentRegistration = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Mass Student Registration', path: '/registration/offering/mass-student-registration', isLink: false }
];
export const registration_student_status = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Status', path: '/registration/academic/student-status', isLink: false }
];
export const registration_student_notes = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Notes', path: '/registration/academic/student-notes', isLink: false }
];
export const add_registration_student_notes = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Notes', path: '/registration/academic/student-notes', isLink: false },
    { name: 'Add/Update Student Notes', path: '/registration/academic/student-notes', isLink: false }

];
export const registration_add_userList = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/Academics', isLink: true },
    { name: 'Student Notes', path: '/registration/Academics/student-notes', isLink: true },
    { name: 'Groups', path: '/registration/Academics/student-notes/groups', isLink: true },
    { name: 'User List Criteria', isLink: false }
];
export const registration_massStudentRegistration_course_filter = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Mass Student Registration', path: '/registration/offering/mass-student-registration', isLink: true },
    { name: 'Course Filter', path: '/registration/offering/mass-student-registration/course-filter', isLink: false }

];
export const registration_massStudentRegistration_course_selection = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Mass Student Registration', path: '/registration/offering/mass-student-registration', isLink: true },
    { name: 'Course Selection', path: '/registration/offering/mass-student-registration/course-selection', isLink: false }

];
export const registration_massStudentRegistration_begin_registration = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Mass Student Registration', path: '/registration/offering/mass-student-registration', isLink: true },
    { name: 'Begin Registration', path: '/registration/offering/mass-student-registration/begin-registration', isLink: false }

];
export const registration_massStudentRegistration_begin_student_selection = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Mass Student Registration', path: '/registration/offering/mass-student-registration', isLink: true },
    { name: 'Begin Registration', path: '/registration/offering/mass-student-registration/student-selection', isLink: false }

];
export const registration_academic_maintenance = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Maintenance', isLink: false }
];
export const registration_academic_maintenance_addCourseDetails = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Maintenance', path: '/registration/academic/maintenance', isLink: true },
    { name: 'Non-Traditional', path: '/registration/academic/maintenance/non-traditional', isLink: true },
    { name: 'Add/Update Courses', isLink: false }

];
export const registration_location_lookup = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Location Lookup', isLink: false }
];
export const registration_searchCohort = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Cohort Details', isLink: false }
];
export const registration_cross_listed = [
    { name: 'Registration', path: '/Registration', isLink: true },
    { name: 'Offering', path: '/Registration/Offering/Cross Listed Courses', isLink: true },
    { name: 'Cross Listed Courses', isLink: false },
];
export const registration_addcrosslisted = [
    { name: 'Registration', path: '/Registration', isLink: true },
    { name: 'Offering', path: '/Registration/Offering/Cross Listed Courses', isLink: true },
    { name: 'Cross Listed Courses', isLink: false },
    { name: ' Add/Update Cross Listed Courses', isLink: false },
];
export const registration_unofficial = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Unofficial', isLink: false }
];
export const registration_register = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Register', isLink: false }
];
export const registration_unofficial_addoption = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Add Grade Options', isLink: false }
];
export const registration_unofficial_setStudent = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Set Student', isLink: false }
];
export const registration_unofficial_addStudent = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Add Student ', isLink: false }
];
export const registration_unofficial_addStudent_transcrit_lookup = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Lookup', isLink: false }
];
export const registration_offering = [
    { name: 'Registration', path: '/Registrationl/offering', isLink: true },
    { name: 'Offering', path: '/Registration/offering/setup', isLink: true },
    { name: 'Setup', path: '/Registration/offering/setup/offerings', isLink: true },
    { name: 'Offerings', isLink: false }
];
export const registration_master = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Courses', isLink: false },
];
export const registration_addgrades = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Add Grades', isLink: false },
];
export const registration_addmastercourse = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Courses', path: '/registration/master-courses', isLink: true },
    { name: 'Add/Update Master Course', isLink: false },
];
export const registration_addmastercourse_corequisite_lookup = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Courses', path: '/registration/master-courses', isLink: true },
    { name: 'Add Master Course', path: '/registration/master-courses/add-master-courses', isLink: true },
    { name: 'CO-Requisite Course', isLink: false }
];
export const registration_addmastercourse_equivalent_lookup = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Courses', path: '/registration/master-courses', isLink: true },
    { name: 'Add Master Course', path: '/registration/master-courses/add-master-courses', isLink: true },
    { name: 'Equivalent Course', isLink: false }
];
export const registration_printoption = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'PtintOptions', isLink: false },
];
export const registration_addequivalent = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Courses', path: '/registration/master-courses', isLink: true },
    { name: 'Add/Update Equivalent', isLink: false },
];
export const master_course_lookup = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'Master Course Lookup', isLink: false },
];

export const Formula = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'CoRequisites', path: '/registration/master-courses/co-requisites', isLink: true },
    { name: 'Formula', isLink: false }
];
export const preFormula = [
    { name: 'Registration', path: '/registration/master-courses', isLink: true },
    { name: 'PreRequisites', path: '/registration/master-courses/pre-requisites', isLink: true },
    { name: 'Formula', isLink: false }
];
export const official_transcript = [
    { name: 'Registration', path: '/admissions/registration/academic/transcript/official', isLink: true },
    { name: 'Academics', path: '/admissions/registration/academic/transcript/official', isLink: true },
    { name: 'Transcripts', path: '/admissions/registration/academic/transcript/official', isLink: true },
    { name: 'Official Transcript', isLink: false }
];

export const advisor_transcript = [
    { name: 'Registration', path: '/admissions/registration/academic/transcript/advisor', isLink: true },
    { name: 'Academics', path: '/admissions/registration/academic/transcript/advisor', isLink: true },
    { name: 'Transcripts', path: '/admissions/registration/academic/transcript/advisor', isLink: true },
    { name: 'Advisor Transcript', isLink: false }
];

export const registration_academic_holds = [
    { name: 'Registration', path: '/registration/academic', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Holds', isLink: false }
];
export const registration_academic_add_holds = [
    { name: 'Registration', path: '/registration/academic', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Holds', path: '/registration/academic', isLink: true },
    { name: 'Add/Update Business Holds', isLink: false }
];
export const Grades_authorise = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Grades', path: '/registration/academic/grades', isLink: true },
    { name: 'Authorize Portal Grades', isLink: false },
];
export const registration_attendance = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Data Entry', isLink: false },

];
export const registration_attendance_update = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Data Entry', path: '/registration/attendance/data-entry', isLink: true },
    { name: 'Update Attendance Status', isLink: false },

];

export const registration_attendance_dateRange = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Date Range Entry', isLink: false },
];
export const registration_attendance_dateRange_add = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Date Range Entry', path: '/registration/attendance/data-range-entry', isLink: true },
    { name: 'Add/Update Attendance Status', isLink: false }
];
export const registration_dataRangeEntry_studentLookup = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Date Range Entry', path: '/registration/attendance/data-range-entry', isLink: true },
    { name: 'Add/Update Attendance Status', path: '/registration/attendance/data-range-entry/add', isLink: true },
    { name: 'Student Lookup', isLink: false }
];

export const registration_dataRangeEntry_load = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Date Range Entry', path: '/registration/attendance/data-range-entry', isLink: true },
    { name: 'Load', isLink: false }

];
export const time_slot_references = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Setup', path: '/registration/offering/setup', isLink: true },
    { name: 'Time Slots', isLink: false }
];
export const time_slot_references_add_time_slot = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Setup', path: '/registration/offering/setup', isLink: true },
    { name: 'Time Slots', path: '/registration/offering/setup/time-slots', isLink: true },
    { name: 'Add/Update Time Slot', isLink: false }
];
export const academic_register = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', isLink: false }
];
export const academic_register_grade_options = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Grade Options', isLink: false }
];
export const academic_unofficial_grade_options = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Unofficial', path: '/registration/academic/register', isLink: true },
    { name: 'Grade Options', isLink: false }
];
export const academic_register_special_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Special Course', isLink: false }
];
export const academic_unofficial_special_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Unofficial', path: '/registration/academic/register', isLink: true },
    { name: 'Special Course', isLink: false }
];
export const academic_register_search_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Search Course', isLink: false }
];

export const academic_register_student_status = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Update Student Status', isLink: false }
];
export const academic_reg_student_status = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Status', path: '/registration/academic/student-status', isLink: true },
    { name: 'Update Student Status', isLink: false }
];
export const academic_Unofficial_student_status = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Unofficial', path: '/registration/academic/register', isLink: true },
    { name: 'Update Student Status', isLink: false }
];
export const academic_register_student_program = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Add/Update Student Programs', isLink: false }
];
export const academic_unofficial_student_program = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Unofficial', path: '/registration/academic/register', isLink: true },
    { name: 'Update Student Programs', isLink: false }
];

export const academic_register_transcript_comments = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Register', path: '/registration/academic/register', isLink: true },
    { name: 'Transcript Comments', isLink: false }
];
export const registration_academic_maintenance_addCourseOffering = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Offering Maintenance', path: '/registration/offering/offering-maintenance', isLink: true },
    { name: 'Offering', path: '/registration/offering/offering-maintenance/offering', isLink: true },
    { name: 'Add/Update Course', isLink: false }
];
export const academic_degrees_add_commitee_memeber = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Degrees', path: '/registration/academic/degrees', isLink: true },
    { name: 'Add/Update Committee Member', isLink: false }
]

export const academic_degrees = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Degrees', isLink: false }
]
export const academic_degrees_add_degrees = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Degrees', path: '/registration/academic/degrees', isLink: true },
    { name: 'Add/Update Degrees', isLink: false }
]
export const academic_degrees_add_committe_lookup = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Degrees', path: '/registration/academic/degrees', isLink: true },
    { name: 'Add Committee Member', path: '/registration/academic/degrees/add committee member', isLink: true },
    { name: 'Faculty Lookup Criteria', isLink: false }
]
export const registration_academic_AddMaintenance = [

    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Maintenance', path: '/registration/academic/maintenance', isLink: true },
    { name: 'Add/Update Maintenance', isLink: false }

];
export const degree_audit_student_eval = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degre-audit', isLink: true },
    { name: 'Student Evaluation', isLink: false }
]
export const degree_audit_student_eval_add_program = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', path: '/registration/degree-audit/student-evaluation', isLink: true },
    { name: 'Add/Update  Program', isLink: false }
]
export const degree_audit_student_eval_add_programs = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', path: '/registration/degree-audit/student-evaluation', isLink: true },
    { name: 'Update Student Programs', isLink: false }
]
export const degree_audit_student_eval_add_requirement = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', path: '/registration/degree-audit/student-evaluation', isLink: true },
    { name: 'Add/Update Student Requirement', isLink: false }
]
export const degree_audit_student_eval_add_group = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', path: '/registration/degree-audit/student-evaluation', isLink: true },
    { name: 'Add/Update Student Group', isLink: false }
]
export const degree_audit_student_eval_add_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', path: '/registration/degree-audit/student-evaluation', isLink: true },
    { name: 'Add/Update Student Course', isLink: false }
]
export const degree_audit_student_eval_formula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', isLink: false },
]
export const degree_audit_setup__formula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Degree Audit Setup', isLink: false },
]
export const degree_audit_setup_formula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Formula Builder', isLink: false },
]
export const degree_audit_setup_group_formulaa = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Degree Audit Setup', isLink: false }
]
export const degree_audit_student_eval_group_formula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', isLink: false }
]
export const degree_audit_setup_group_formula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Formula Builder', isLink: false }
]
export const degree_audit_student_eval_add_sql = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Student Evaluation', isLink: false }
]
export const degree_audit_setip_sql = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Degree Audit Setup', isLink: false }
]
export const degree_audit_student_setup_add_sql = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Degree Audit Setup', isLink: false }
]

export const offeringsetup_tutionRefuns_costType = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', isLink: false }
];
export const transfer = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Transfer', isLink: false }
];

export const addTransfer = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Transfer', isLink: true },
    { name: 'Add/Update Course Transfer', isLink: false }
];
export const numeric_grade_entry = [
    { name: "Registration", path: "/admissions/registration/academic/grades/numeric-grade-entry", isLink: true },
    { name: 'Academics', path: '/admissions/registration/academic/grades/numeric-grade-entry', isLink: true },
    { name: "Grades", path: "/admissions/registration/academic/grades/numeric-grade-entry", isLink: true },
    { name: "Numeric Grade Entry", isLink: false },

];
export const update_numeric_grade_entry = [
    { name: 'Registration', path: '/admissions/registration/academic/grades/numeric-grade-entry', isLink: true },
    { name: ' Academics', path: '/admissions/registration/academic/grades/numeric-grade-entry', isLink: true },
    { name: ' Grades', path: '/admissions/registration/academic/grades/numeric-grade-entry', isLink: true },
    { name: ' Numeric Grade Entry ', path: '/admissions/registration/academic/grades/numeric-grade-entry', isLink: true },
    { name: 'Update Numeric Grade Entry', isLink: false }
];

export const update_grade_entry = [
    { name: 'Registration', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: ' Academics', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: ' Grades', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: ' Grade Entry ', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: 'Update Grade Entry', isLink: false }
];
export const copyCatalogTerm = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Catalog', path: '#', isLink: true },
    { name: 'Copy Catalog', isLink: false }
];

export const schedule_index = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Schedule', isLink: false }
];

export const schedule_searchCourse = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Schedule', path: '#', isLink: true },
    { name: 'Reports', path: '#', isLink: true },
    { name: 'Course Lookup', isLink: false }
];

export const grade_gradeEntry = [
    { name: "Registration", path: "/admissions/registration/academic/grades/number-grade-entry", isLink: true },
    { name: 'Academics', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: "Grades", path: "/admissions/registration/academic/grades/number-grade-entry", isLink: true },
    { name: "Grade Entry", isLink: false },

];

export const gradeEntry = [
    { name: "Registration", path: "/Registration", isLink: true },
    { name: "Grades", path: "/Registration/Grades", isLink: true },
    { name: "Grade Entry", isLink: false },
];

export const grade_numberGradeEntry = [
    { name: "Registration", path: "/admissions/registration/academic/grades/number-grade-entry", isLink: true },
    { name: 'Academics ', path: '/admissions/registration/academic/grades/number-grade-entry', isLink: true },
    { name: "Grades", path: "/admissions/registration/academic/grades/number-grade-entry", isLink: true },
    { name: "Number Grade Entry", isLink: false },

];

export const register_academic_maintenance_search_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: "Academics", path: "#", isLink: true },
    { name: "Maintenance", path: "#", isLink: true },
    { name: 'Course Lookup', isLink: false }
];

export const register_catalog_maintenance = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Catalog', path: '#', isLink: true },
    { name: 'Maintenance', isLink: false }
];
export const degreeAudit_report_studentBYOR = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Reports', path: 'registration/degree-audit/student-evaluation', isLink: true },
    { name: 'BYOR', isLink: false }
];

export const degreeAudit_report_incompleteCourses = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Degree Audit', path: '/registration/degree-audit', isLink: true },
    { name: 'Reports', path: 'registration/degree-audit/reports', isLink: true },
    { name: 'Incomplete Courses', isLink: false }
];

export const register_reports_gradeReports_gradeCards = [
    { name: "Registration", path: "/Registration", isLink: true },
    { name: "Reports", path: "/Registration/Reports", isLink: true },
    { name: "Grade Reports", path: "/Registration/Reports/Grade Reports", isLink: true },
    { name: "Grade Cards", isLink: false },
];

export const register_reports_directory = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Reports', path: '/registration/reports', isLink: true },
    { name: 'Directory', isLink: false }
];

export const register_reports_grade_summary = [
    { name: 'Registration', path: '/Registration', isLink: true },
    { name: 'Reports', path: '/Registration/Reports', isLink: true },
    { name: 'Grade Summary', isLink: false }
];
export const fullTime_Equivalent = [
    { name: 'Registration', path: '/Registration', isLink: true },
    { name: 'Reports', path: '/Registration/Reports', isLink: true },
    { name: 'Full Time Equivalent', isLink: false }
];

export const register_report_hold_degree_report = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Reports', path: 'registration/reports', isLink: true },
    { name: 'Hold/Degree Report', isLink: false }
];

export const searchCourse = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Search Course', isLink: false }
];

export const academic_maintenance_collegeLookup = [
    { name: "Registration", path: "#", isLink: true },
    { name: "Lookups", path: "#", isLink: true },
    { name: "College Name", isLink: false },
];

export const attendanceReport_BYOR = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Attendance', path: '/registration/attendance', isLink: true },
    { name: 'Reports', path: '/registration/attendance/Reports', isLink: true },
    { name: 'BYOR', isLink: false }
];

export const registration_masterCourseLookup = [
    { name: "Registration", path: "#", isLink: true },
    { name: "Lookups", path: "#", isLink: true },
    { name: "Course Name", isLink: false },
];

export const registration_academic_schedule_course = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Academics', path: '#', isLink: true },
    { name: 'Schedule', isLink: false }
];

export const registration_search_master_course = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Search Master Course', isLink: false }
];

export const registration_search_faculty = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Offering', path: '/registration/offering', isLink: true },
    { name: 'Roster', path: '/registration/offering/roster', isLink: true },
    { name: 'Print Roster', isLink: true },
    { name: 'Search Faculty', isLink: false }
];








export const search_cohort_lookup = [
    { name: "Registration", path: "#", isLink: true },
    { name: "Academic", path: "#", isLink: true },
    { name: "Maintenance", path: "#", isLink: true },
    { name: "Non Traditional", path: "#", isLink: true },
    { name: "Add Courses", path: "#", isLink: true },
    { name: "Course Name Lookup", isLink: false },
];

export const DegreeAudit_Setup = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/degree-audit", isLink: true },
    { name: "Degree Audit Setup", isLink: false }
];
export const AddProgramDetails = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/degree-audit", isLink: true },
    { name: "Degree Audit Setup", path: "/registration/degree-audit/degree-audit-setup", isLink: true },
    { name: "Programs", path: "/registration/degree-audit/degree-audit-setup/programs", isLink: true },
    { name: "Add/Update Programs Detail", isLink: false }
]
export const ProgramDetails = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/program-details", isLink: true },
    { name: "Program Details", isLink: false }
]
export const RequirementsDetail = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/degree-audit", isLink: true },
    { name: "Degree Audit Setup", path: "/registration/degree-audit/degree-audit-setup", isLink: true },
    { name: "Requirements", path: "/registration/degree-audit/degree-audit-setup/requirements", isLink: true },
    { name: "Add/Update Requirements Detail", isLink: false }
]
export const GroupDetails = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/degree-audit", isLink: true },
    { name: "Degree Audit Setup", path: "/registration/degree-audit/degree-audit-setup", isLink: true },
    { name: "Groups", path: "/registration/degree-audit/degree-audit-setup/groups", isLink: true },
    { name: "Add/Update Groups Detail", isLink: false }
]
export const AddCourseDetails = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Degree Audit", path: "/registration/degree-audit", isLink: true },
    { name: "Degree Audit Setup", path: "/registration/degree-audit/degree-audit-setup", isLink: true },
    { name: "Courses", path: "/registration/degree-audit/degree-audit-setup/courses", isLink: true },
    { name: "Add/Update Courses Detail ", isLink: false }
]


export const catalogcourse_maintainance = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Catalog", path: "/registration/catalog", isLink: true },
    { name: "Maintenance", path: "/registration/catalog/maintenance", isLink: true },
    { name: "Catalog Courses", isLink: false }
];

export const catalogcourse_maintainance_division = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Catalog", path: "/registration/catalog", isLink: true },
    { name: "Maintenance", path: "/registration/catalog/maintenance", isLink: true },
    { name: "Add/Update Catalog Course", isLink: false }
];
export const registration_student_search = [
    { name: 'Registration', path: '/registration/select-student', isLink: true },
    { name: 'Search Student', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_addCopy = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Copy Term Options', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_addCost = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add New Cost Record', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_setup = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Setup Parameters', isLink: false }
]

export const offeringsetup_tutionRefuns_costType_addGroup = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add/Update Refund Group', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_addnewTerm = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add New Term', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_addPeriod = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add/Update Refund Period', isLink: false }
]
export const offeringsetup_tutionRefuns_costType_addPrint = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add Print', isLink: false }
]

export const offeringsetup_tutionRefuns_costType_costType = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add/Update Cost Type', isLink: false }
]

export const offeringsetup_tutionRefuns_costType_courseFee = [
    { name: 'Registration', path: '#', isLink: true },
    { name: 'Offering', path: '#', isLink: true },
    { name: 'Setup', path: '#', isLink: true },
    { name: 'Tuition & Refunds', path: '#', isLink: true },
    { name: 'Add/Update Fees', isLink: false }
]
export const reg_studentStatus_add = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Status', path: '/registration/student-status', isLink: true },
    { name: 'Add/Update Student Status', isLink: false }

]
export const academic_register_program = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Status', path: '/registration/student-status', isLink: true },
    { name: 'Add/Update Student Program', isLink: false }

]
export const reg_studentStaus_addExtracurricula = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academic', isLink: true },
    { name: 'Student Status', path: '/registration/student-status', isLink: true },
    { name: 'Add/Update Extra Curricular Activities', isLink: false }

]

export const registration_change_address = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academics', isLink: true },
    { name: 'Change Address', isLink: false }
]
export const registration_change_address_copy = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academics', isLink: true },
    { name: 'Change Address', path: '/registration/academics/change-address', isLink: true },
    { name: 'Copy Address', isLink: false }
];
export const registration_add_address = [
    { name: 'Registration', path: '/registration', isLink: true },
    { name: 'Academics', path: '/registration/academics', isLink: true },
    { name: 'Change Address', path: '/registration/academics/change-address', isLink: true },
    { name: 'Add/Update Address', isLink: false }
];
export const registration_DocumentTracking = [
    { name: 'Registration', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Academics', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const registration_loadColleges = [
    { name: 'Registration', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Academics', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Load Colleges', isLink: false }
];
export const registration_addDocTracking = [
    { name: 'Registration', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Academics', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Add/Update Document Tracking', isLink: false }
];
export const registration_loadDocSequence = [
    { name: 'Registration', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Academics', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Document Tracking', path: '/registration/academic/document-tracking', isLink: true },
    { name: 'Load Document Sequence', isLink: false }
];
export const enrollment_Verification_reports = [
    { name: "Registration", path: "/registration", isLink: true },
    { name: "Reports", path: "/registration/Reports", isLink: true },
    { name: "Enrollment Verification", path: "/registration/reports/enrollment-verification", isLink: false },
];