import { ADD_INTEREST, DELETE_INTEREST, ADD_STUDENT_INTEREST, DELETE_STUDENT_INTEREST } from './type'

const initialState = {
    interest: [
    ],
    studentInterest: [
    ]
};

const interestReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_INTEREST:
            return {
                ...state,
                interest: [...payload]
            }
        case DELETE_INTEREST:
            return {
                ...state,
                interest: [...state.interest.filter(item => !payload.includes(item.id.toString()))]
            }
        case ADD_STUDENT_INTEREST:
            return {
                ...state,
                studentInterest: [...payload]
            }
        case DELETE_STUDENT_INTEREST:
            return {
                ...state,
                studentInterest: [...state.studentInterest.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default interestReducer;