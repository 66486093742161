import { UPDATE_OFFERING_FORM,UPDATE_OFFERING_TABLE } from './type'

const initialState = {
    offeringTab: {
        formValues:{
            termId: 0,
            courseName: "",
            courseDepartment: "",
            courseNumber: "",
            courseType: ""
        },
        offeringRows:[]
    }
};

const dataEntryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_OFFERING_FORM:
            return {
                ...state,
                offeringTab:{
                    ...state.offeringTab,formValues: payload
                }
            }
            case UPDATE_OFFERING_TABLE:
            return {
                ...state,
                offeringTab:{
                    ...state.offeringTab,offeringRows: payload
                }
            }
        default:
            return state;
    }
};

export default dataEntryReducer;