import {
    GET_LOADPARAMS_FORMS, GET_OFFERED_COURSES, GET_REGISTERED_COURSES,
    GET_WAITINGLIST,
    GET_TRANSCRIPT_TABLE,
    GET_DROPPED_TABLE,
    GET_REGISTERED_TEMP_COURSES,
    GET_UNOFFICIAL_WAITINGLIST,
    DELETE_UNOFFICIAL_WAITINGLIST,
    DROPPED_UNOFFICIAL_COURSES
} from './types'

const initialState = {
    loadParameter: {
    },
    offeredCourses: [],
    registerCourses: [],
    waitingListTable: [],
    transcriptTable: [],
    droppedTable: [],
    registeredArray: [],
    waitingUnofficialArray: [],
    droppedUnofficialCourse: []
};

const unofficialReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_LOADPARAMS_FORMS:
            return {
                ...state,
                loadParameter: { ...payload }
            };
        case DROPPED_UNOFFICIAL_COURSES:
            return {
                ...state,
                droppedUnofficialCourse: [...payload]
            };
        case GET_OFFERED_COURSES:
            return {
                ...state,
                offeredCourses: [...payload]
            };
        case GET_REGISTERED_COURSES:
            return {
                ...state,
                registerCourses: [...payload]
            };
        case GET_WAITINGLIST:
            return {
                ...state,
                waitingListTable: [...payload]
            };
        case GET_TRANSCRIPT_TABLE:
            return {
                ...state,
                transcriptTable: [...payload]
            };
        case GET_DROPPED_TABLE:
            return {
                ...state,
                droppedTable: [...payload]
            };
        case GET_REGISTERED_TEMP_COURSES:
            return {
                ...state,
                registeredArray: [...payload]
            };
        case GET_UNOFFICIAL_WAITINGLIST:
            return {
                ...state,
                waitingUnofficialArray: [...payload] 
            };
        case DELETE_UNOFFICIAL_WAITINGLIST:
            return {
                ...state,
                waitingUnofficialArray: state.waitingUnofficialArray.filter((item, ind) =>
                    payload[0].indexOf(ind.toString()) == -1
                )
            }
        default:
            return state;
    }
};

export default unofficialReducer;