import { ADD_HIGH_SCHOOL, DELETE_HIGH_SCHOOL, ADD_STUDENT_HIGH_SCHOOL, DELETE_STUDENT_HIGH_SCHOOL } from './type'

const initialState = {
    highSchool: [],
    studentHighSchool: []
};

const highSchoolReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_HIGH_SCHOOL:
            return {
                ...state,
                highSchool: [...payload]
            }
        case DELETE_HIGH_SCHOOL:
            return {
                ...state,
                highSchool: [...state.highSchool.filter(item => !payload.includes(item.studentHighSchoolID.toString()))]
            }
        case ADD_STUDENT_HIGH_SCHOOL:
            return {
                ...state,
                studentHighSchool: [...payload]
            }
        case DELETE_STUDENT_HIGH_SCHOOL:
            return {
                ...state,
                studentHighSchool: [...state.studentHighSchool.filter(item => !payload.includes(item.studentHighSchoolID.toString()))]
            }
        default:
            return state;
    }
};

export default highSchoolReducer;