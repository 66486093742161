import {
    ADD_STUDENT_LIST, ADD_COURSE_LIST, ADD_FILTERED_COURSE_LIST, ADD_SELECTED_COURSE_LIST,
    ADD_FROM_COURSE_LIST, ADD_STUDENT_SELECTION_LIST, ADD_TO_COURSE_LIST,
    ADD_DROP_STUDENT_LIST, ADD_DROP_COURSE_LIST,
    ADD_DROP_COURSE_COURSE_LIST, ADD_DROP_COURSE_STUDENT_LIST,
    ADD_VIEW_STUDENT_LIST, ADD_VIEW_COURSE_LIST,
    UPDATE_VIEW_TERM, UPDATE_REGISTER_TERM, UPDATE_MOVE_TERM, UPDATE_DROP_STUDENT_TERM, UPDATE_DROP_COURSE_TERM
    , RESET_STATE, UPDATE_MOVE_SELECT_ALL, UPDATE_DROP_SELECT_ALL_COURSES, UPDATE_DROP_SELECT_ALL_STUDENTS,
    UPDATE_DROP_STUDENT_DATE,UPDATE_DROP_COURSE_DATE
} from './type'

const initialState = {
    register: {
        term: "",
        studentSelectionList: [],
        courseSelectionList: [],
        isLoadingCourse: false,
        selectedResultList: [],
        filteredCourseList: [],
        selectedCourseList: []
    },
    move: {
        term: "",
        selectAllStudents: false,
        fromCourseList: [],
        studentSelectionList: [],
        toCourseList: []
    },
    dropStudent: {
        term: "",
        selectAllStudents: false,
        effectiveDate:null,
        courseSelectionList: [],
        studentSelectionList: []
    },
    dropCourse: {
        term: "",
        effectiveDate:null,
        selectAllCourses: false,
        courseSelectionList: [],
        studentSelectionList: []
    },
    view: {
        term: "",
        courseSelectionList: [],
        registeredstudentList: []
    }
};

const massStudentRegistrationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_STUDENT_LIST:
            return {
                ...state,
                register: {
                    ...state.register, studentSelectionList: payload
                }

            }
        case ADD_COURSE_LIST:
            return {
                ...state,
                register: {
                    ...state.register, courseSelectionList: payload
                }
            }
        case ADD_FILTERED_COURSE_LIST:
            return {
                ...state,
                register: {
                    ...state.register, filteredCourseList: payload
                }
            }
        case ADD_SELECTED_COURSE_LIST:
            return {
                ...state,
                register: {
                    ...state.register, selectedCourseList: payload
                }
            }
        case ADD_FROM_COURSE_LIST:
            return {
                ...state,
                move: {
                    ...state.move, fromCourseList: payload
                }
            }
        case ADD_STUDENT_SELECTION_LIST:
            return {
                ...state,
                move: {
                    ...state.move, studentSelectionList: payload
                }
            }
        case ADD_TO_COURSE_LIST:
            return {
                ...state,
                move: {
                    ...state.move, toCourseList: payload
                }
            }
        case ADD_DROP_COURSE_LIST:
            return {
                ...state,
                dropStudent: {
                    ...state.dropStudent, courseSelectionList: payload
                }
            }
        case ADD_DROP_STUDENT_LIST:
            return {
                ...state,
                dropStudent: {
                    ...state.dropStudent, studentSelectionList: payload
                }
            }
        case ADD_DROP_COURSE_STUDENT_LIST:
            return {
                ...state,
                dropCourse: {
                    ...state.dropCourse, studentSelectionList: payload
                }
            }
        case ADD_DROP_COURSE_COURSE_LIST:
            return {
                ...state,
                dropCourse: {
                    ...state.dropCourse, courseSelectionList: payload
                }
            }
        case ADD_VIEW_COURSE_LIST:
            return {
                ...state,
                view: {
                    ...state.view, courseSelectionList: payload
                }
            }
        case ADD_VIEW_STUDENT_LIST:
            return {
                ...state,
                view: {
                    ...state.view, registeredstudentList: payload
                }
            }
        case UPDATE_VIEW_TERM:
            return {
                ...state,
                view: {
                    ...state.view, term: payload
                }
            }
        case UPDATE_MOVE_TERM:
            return {
                ...state,
                move: {
                    ...state.move, term: payload
                }
            }
        case UPDATE_DROP_STUDENT_TERM:
            return {
                ...state,
                dropStudent: {
                    ...state.dropStudent, term: payload
                }
            }
        case UPDATE_DROP_COURSE_TERM:
            return {
                ...state,
                dropCourse: {
                    ...state.dropCourse, term: payload
                }
            }
        case UPDATE_REGISTER_TERM:
            return {
                ...state,
                register: {
                    ...state.register, term: payload
                }
            }
        case UPDATE_MOVE_SELECT_ALL:
            return {
                ...state,
                move: {
                    ...state.move, selectAllStudents: payload
                }
            }
        case UPDATE_DROP_SELECT_ALL_COURSES:
            return {
                ...state,
                dropCourse: {
                    ...state.dropCourse, selectAllCourses: payload
                }
            }
        case UPDATE_DROP_SELECT_ALL_STUDENTS:
            return {
                ...state,
                dropStudent: {
                    ...state.dropStudent, selectAllStudents: payload
                }
            }
            case UPDATE_DROP_STUDENT_DATE:
            return {
                ...state,
                dropStudent: {
                    ...state.dropStudent, effectiveDate: payload
                }
            }
            case UPDATE_DROP_COURSE_DATE:
            return {
                ...state,
                dropCourse: {
                    ...state.dropCourse, effectiveDate: payload
                }
            }
        case RESET_STATE:
            return {...initialState}
        default:
            return state;
    }
};

export default massStudentRegistrationReducer;