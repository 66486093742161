import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbLink } from '../../../../../utils/Breadcrumbs/Breadcrumbs';
import HeaderTitle from '../../../../../utils/HeaderTitle/HeaderTitle';
import { Criteria } from './CriteriaTab';
import { reports_working_to_do } from '../../../../../services/navigation_folder/admissions'

const WorkingToDo = () => {
    const [showTab, setShowTab] = useState(false);
    const addData = (value) => {
        setShowTab(value)
    }

    return (
        <>
            {(!showTab) &&
                <>
                    <Breadcrumb>
                        {[...reports_working_to_do].map(({ name, isLink, path }, index) => (
                            <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} />
                        ))}
                    </Breadcrumb>
                    <HeaderTitle headerTitle={"Working To Do"} help={true} />
                </>
            }
            <Criteria addData={addData} />
        </>
    )
}
export default WorkingToDo