/**
 * Formats the value in form of (111) 111-1111
 * @param {boolean} useSymbol - format with formatphonenumber symbol
 * @returns {Number}
 */
export const formatPhoneNumber = (phoneNumber) => {
    // for eg: pass localnumber
    // let localNumber = '19999989898';
    //Filter only numbers from the input
    let filteredNumber = ('' + phoneNumber).replace(/\D/g, '');
    //// append regex as per needed
    let regex = /^(\d{3})(\d{3})(\d{4,18})$/;
    let verifyPhone = filteredNumber.match(regex);

    if (verifyPhone) {
        let internationalNumber = verifyPhone[1] + '-' + verifyPhone[2] + '-' + verifyPhone[3];
        return internationalNumber;
    }
    else {
        return phoneNumber;
    }
}

/**
 * convert (111) 111-1111 to 1111111111
 * @param {string} useSymbol - format with formPhoneNumber symbol
 * @returns {String}
 */
export const formPhoneNumber = (value) => {
    // return ((value).replace(/[-]/g, '')).toString()
    return ((value).replace(/[^\d*]/g, '')).toString()
}
const maskingPatterns = [
    {
        pattern: '(###) ###-####******',
        key: 'bracket'
    },
    {
        pattern: '###-###-####********',
        key: 'hyphen'
    },
    {
        pattern: '###.###.####********',
        key: 'dot'
    },
    {
        pattern: '********************',
        key: 'none'
    }];

export const getPhoneMaskPattern = (pattern) => {
    return (maskingPatterns.find(mp => mp.pattern === pattern)).key
}
export const validatePhone = (val, pattern) => {
    // eslint-disable-next-line default-case
    switch (pattern) {
        case 'bracket': return ((/^\([0-9]{3}\)[\s]?[0-9]{3}[-]?[0-9]{4,18}$/).test(val))
        case 'hyphen':return (/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4,18}$/im.test(val))
        case 'dot': return (/^[0-9]{3}[.]?[0-9]{3}[.]?[0-9]{4,18}$/im.test(val))
        case 'none': return (/^[0-9]{3}[0-9]{3}[0-9]{4,18}$/im.test(val))
    }
}
const phoneMaskingPatternWise = (pattern, x,y,z) => {
    // eslint-disable-next-line default-case
    switch (pattern) {
        case 'bracket': return '(' + x + ') ' + (y ? `${y}` : '') + (z ? `-${z}` : '')
        case 'dot':return x + (y ? `.${y}` : '') + (z ? `.${z}` : '')
        case 'hyphen': return x + (y ? `-${y}` : '') + (z ? `-${z}` : '')
        case 'none': return x+y+z
    }
}
export const phoneInputMask = (phone, maskPattern) => {
    // let abc = '';
    // solution 1
    if(phone.length >= 10) {
        return phone.replace(/[^\d*]/g, "")
        .replace(/^(\d{3})(\d{3})(\d{4,18})$/, (_,x,y,z) => 
        phoneMaskingPatternWise(maskPattern, x,y,z)
        )
    } else { return phone;}
   

//  solution 2
    // if (phone.match(/^\d*$/)) {
    //     if(phone.length < 28) {
    //         if(phone.length < 3) {
    //             abc = `(${phone.substring(0,3)})`
    //         } else if(phone.length >= 3 && phone.length < 6) {
    //             abc = `(${phone.substring(0,3)}) ${phone.substring(3,6)}`
    //         } else if(phone.length >= 5 && phone.length < 28) {
    //             abc =  `(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6,)}`
    //         }
    //     } else {
    //     return phone;
    //     }
    //     return abc;
    // }
  };

  export const phoneKeyInputMask = (phone, maskPattern) => {
    // solution 1
        return phone.replace(/[^\d*]/g, "")
        .replace(/^(\d{3})(\d{3})(\d{4,18})$/, (_,x,y,z) => 
        phoneMaskingPatternWise(maskPattern, x,y,z)
        )
  };