import { UPDATE_USER, UPDATE_PASSWORD_SETTINGS } from "./types";


const initialState = {
    access: {
        parentLevel: [],
        nestedParentLevel: [],
        subNestedParentLevel: [],
        subDeepNestedParentLevel: []
    },
    pwdSetting : {
        "enablePasswordExpiration": false,
        "passwordExpiresInDays": 16,
        "minPasswordLength": 6,
        "requireUpperAndLowerCase": false,
        "requireSpecialCharacters": false,
        "requireNumericCharacters": false,
        "uniquePasswordCount": 2,
        "userTimeOut": 2,
        "maxLoginAttempts": 0
    }
};

const updateAccessReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_USER:
            return {
                ...state,
                access: payload
            };
        case UPDATE_PASSWORD_SETTINGS:
            return {
                ...state,
                pwdSetting: payload
            };
        default:
            return state;
    }
};

export default updateAccessReducer;