import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { TextInput, SelectInput, RadioInput } from '../Forms';
import { FormGroup, useStyles, ButtonWrapperFilter } from '../../styles/common-styles'
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { facultyaddAddress, editAddress } from '../../redux/faculty-manager/faculty-maintenance/addAddress/action';
import { addAddressSelector } from '../../redux/faculty-manager/faculty-maintenance/addAddress/selectors';
import { updateFormStatus } from '../../redux/faculty-manager/faculty-maintenance/formValueChange/action';
import { getApi, postApi, putApi } from '../../services/apiBase'
import { addInfo_dropdown, faculty_address } from '../../services/apiURL';
import { scrollIntoViewHelper } from '../../helper';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber, formPhoneNumber, getPhoneMaskPattern, phoneInputMask, validatePhone } from '../../helper/formatePhone';
import GenericModal from './GenericModal';
import DefaultTable from '../../utils/Table/DefaultTable';
import { updateUserSelector } from '../../redux/faculty-manager/faculty-maintenance/login/selectors';
import { updateAccessSelector } from '../../redux/faculty-manager/faculty-maintenance/roleAccess/selectors';
import { changeProspect, changeStudent } from '../../helper/constants';
import { toastMessage } from '../../utils/Message/toasts';



const CopyAddressFaculty = (props) => {
    const { t } = useTranslation();

    const { onClose, selectedRow, selectedIndex, type ,copy} = props;
    const tableRows = useSelector(addAddressSelector);
    console.log("selectedRow" + selectedRow)
    const dispatch = useDispatch();
    const classess = useStyles();
    // const [formValues, setFormValues] = useState('');
    const [toolTipStatus, setToolTipStatus] = React.useState('');
    const username = localStorage.getItem("username");
    const [international, setinternational] = useState('false');
    const [internationalRadioFlag, setRadioFlag] = useState('');
    const [billingFlag, setBillingFlag] = useState(false)
    const history = useHistory();
    const [facultyFlag, setFacultyFlag] = useState(false);
    const [activeAddress, setActiveAddress] = useState(false);
    const [isLoading, setIsloading] = useState(false)

    const [Dropdown, setDropdown] = useState({
        "activeDrop": [],
        "addressTypeDrop": [],
        "statusDrop": [],
        "countryDrop": [],
        "stateDrop": [],
        "billingDrop": []
    })
    const initialValues = {
        "addressTypeId": 0,
        "address1": "",
        "address2": "",
        "address3": "",
        "city": "",
        "county": "",
        "stateId": 0,
        "zipCode": "",
        "email1": "",
        "phone1": "",
        "phone2": "",
        "active": 1,
        "addressCategory": false,
        "workPhone1": "",
        "mobilePhone": "",
        "email2": "",
        "email3": "",
        "fax1": "",
        "countryId": 0,
        "town": ""
    }

    const [formValues, setFormValues] = useState(initialValues);
    //ELEMENTS CONFIGURATION
    const { configuration } = useSelector(updateUserSelector);
    const [elemConfiguration, setElemConfiguration] = useState('');
    useEffect(() => {
        if (configuration.camsConfig) {
            setElemConfiguration(configuration.camsConfig)
        }
    }, [configuration])
    //ELEMENTS CONFIGURATION

    useEffect(() => {
        if (selectedRow.length === 0) {
            if (configuration.camsConfig) {
                let initialValueAddress = Number(configuration.camsConfig.addressSelection) === 1 ? 'false' : 'true'
                setinternational(initialValueAddress)
            }
        }
    }, [configuration])



    const validationSchema = yup.object({
        address1: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabel.addressLine1') })),
        addressTypeId: yup.number()
            .min(1, t('validationMessages.isRequired', { what: t('commonLabel.addressType') }))
            .required(t('validationMessages.isRequired', { what: t('commonLabel.addressType') })),
        active: yup
            .string(t('validationMessages.isRequired', { what: t('commonLabel.activeAddress1') })),
        email1: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email1') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email1') })),
        email2: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email2') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email2') })),
        email3: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email3') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email3') })),
        phone1: yup
            .string()
            .test('is', t('validationMessages.validInput', { what: t('commonLabel.phone1') }),
                (val) => {
                    if (val) {
                        let getMaskedKey = (getPhoneMaskPattern(elemConfiguration.domesticPhoneMask));
                        if (!validatePhone(val, getMaskedKey)) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        phone2: yup
            .string()
            .test('is', t('validationMessages.validInput', { what: t('commonLabel.phone2') }),
                (val) => {
                    if (val) {
                        let getMaskedKey = (getPhoneMaskPattern(elemConfiguration.domesticPhoneMask));
                        if (!validatePhone(val, getMaskedKey)) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        workPhone1: yup
            .string()
            .test('is', t('validationMessages.validInput', { what: t('commonLabel.workPhone') }),
                (val) => {
                    if (val) {
                        let getMaskedKey = (getPhoneMaskPattern(elemConfiguration.domesticPhoneMask));
                        if (!validatePhone(val, getMaskedKey)) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        mobilePhone: yup
            .string()
            .test('is', t('validationMessages.validInput', { what: t('commonLabel.mobilePhone') }),
                (val) => {
                    if (val) {
                        let getMaskedKey = (getPhoneMaskPattern(elemConfiguration.domesticPhoneMask));
                        if (!validatePhone(val, getMaskedKey)) {
                            return false;
                        }
                    }
                    return true;
                }
            ),
        fax1: yup
            .string()
            .test('is', t('validationMessages.validInput', { what: t('commonLabel.fax') }),
                (val) => {
                    if (val) {
                        let getMaskedKey = (getPhoneMaskPattern(elemConfiguration.domesticPhoneMask));
                        if (!validatePhone(val, getMaskedKey)) {
                            return false;
                        }
                    }
                    return true;
                }
            )
    });

    const formik = useFormik({
        initialValues: formValues || initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let updatedRows = [];
            const HomeAddressUnique = (Dropdown.addressTypeDrop.find((row) => row.displayText === (t('commonLabel.home'))))?.uniqueId;
            const BillingAddressUnique = (Dropdown.addressTypeDrop.find((row) => row.displayText === ("Billing")))?.uniqueId;
            const LocalAddressUnique = (Dropdown.addressTypeDrop.find((row) => row.displayText === ("Local")))?.uniqueId;


            if (tableRows.address.length > 0) {
                if ((values.active == 2)) {
                    let activeRows = [];
                    activeRows = tableRows.address.filter(row => (row.active == 2 || row.activeFlag == "No") && row.addressTypeId === values.addressTypeId);
                    if (activeRows.length < 1) {
                        if (type === 'faculty') {
                            if (HomeAddressUnique === values.addressTypeId || BillingAddressUnique === values.addressTypeId
                                || LocalAddressUnique === values.addressTypeId) {
                                formik.setFieldError("active", t('validationMessages.isRequired', { what: t('commonLabel.atLeastOneAddress') }))
                                return;
                            }
                        } else {
                            if (HomeAddressUnique == values.addressTypeId) {
                                formik.setFieldError("active", t('validationMessages.isRequired', { what: t('commonLabel.atLeastOneAddress') }))
                                return;
                            }
                        }
                    } else if (activeRows.length > 0) {
                        if (type === 'faculty') {
                            setAlertActive(true)
                            return;
                        }
                        else {
                            let homeaddressLength = tableRows.address.filter(row => HomeAddressUnique == row.addressTypeId);
                            if (homeaddressLength.length > 0) {
                                if (values.addressTypeId === HomeAddressUnique) {
                                    setAlertActive(true)
                                    return;
                                }
                            } else {
                                setAlertActive(true)
                                return;
                            }
                        }
                    }
                }
                let DuplicateAddress = tableRows.address.filter(row => row.addressTypeId === values.addressTypeId);
                if (DuplicateAddress.length > 0) {
                    updatedRows = tableRows.address.map((v, i) => {
                        if (v.addressTypeId === values.addressTypeId) {
                            v.active = 2
                        }
                        return v;
                    });
                } else {
                    updatedRows = tableRows.address
                }
            }
            let payload = {
                ...values,
                "addressID": copy ? 0 : values.addressID,
                // "isActive": values.active === 1 ? true : false,
                "phone1": (values.phone1 === '') ? '' : formPhoneNumber(values.phone1),
                // "phone2": (values.phone2 === '') ? '' : formPhoneNumber(values.phone2),
                // "mobilePhone": (values.mobilePhone === '') ? '' : formPhoneNumber(values.mobilePhone),
                // "workPhone": (values.workPhone === '') ? '' : formPhoneNumber(values.workPhone),
                // "fax": (values.fax === '') ? '' : formPhoneNumber(values.fax),
            };
            // add records
            dispatch(facultyaddAddress([...updatedRows, payload]));
            dispatch(updateFormStatus(true));
            onClose(false);
        }
    });


    //If editable row data tiggers, then below useEffect will execute
    useEffect(() => {


    }, [selectedRow])

    useEffect(() => {
        history.location.pathname == "/admissions/students/add-students" ? setFacultyFlag(true) : setFacultyFlag(false)
        getApi(addInfo_dropdown).then((resp) => {
            const { active,
                addressType, country,
                state, billingCategory
            } = resp.data.data
            setDropdown({
                ...Dropdown,
                "activeDrop": active,
                "addressTypeDrop": addressType,
                "countryDrop": country,
                "stateDrop": state,
                // "billingDrop": billingCategory
            })
            if (selectedRow.length > 0) {
                let getPhoneMaskingPattern = getPhoneMaskPattern(configuration.camsConfig.domesticPhoneMask);
                setFormValues({
                    ...selectedRow[0],
                    "phone1": (selectedRow[0].phone1 === '') ? '' : phoneInputMask(selectedRow[0].phone1, getPhoneMaskingPattern),
                    "active": selectedRow[0].activeFlag === "Yes" ? 1 : 2,
                    // "phone2": (selectedRow[0].phone2 === '') ? '' : phoneInputMask(selectedRow[0].phone2, getPhoneMaskingPattern),
                    // "mobilePhone": (selectedRow[0].mobilePhone === '') ? '' : phoneInputMask(selectedRow[0].mobilePhone, getPhoneMaskingPattern),
                    // "workPhone": (selectedRow[0].workPhone === '') ? '' : phoneInputMask(selectedRow[0].workPhone, getPhoneMaskingPattern),
                    // "fax": (selectedRow[0].fax === '') ? '' : phoneInputMask(selectedRow[0].fax, getPhoneMaskingPattern)
                });
                selectedRow[0].addressTypeText == t('commonLabel.billing') ? setBillingFlag(true) : setBillingFlag(false)
                selectedRow[0].addressCategory ? setinternational('true') : setinternational('false');
                setToolTipStatus('edit');
                // DefaultTable.resetTable("studentAdmission")
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    //Role Based Access implementation starts here
    // Role based access control redux
    const { access } = useSelector(updateAccessSelector)
    const { subNestedParentLevel } = access;
    const [facultyAccess, setFacultyAccess] = useState({
        changeProspect: 2,
        changeFacultyAccess: 2,
    })
    useEffect(() => {
        let ChangeProspectAccess = subNestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === changeProspect && v.moduleorder === 1
        )
        let ChangeFacultyAccess = subNestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === changeStudent && v.moduleorder === 1
        )
        setFacultyAccess({
            ...facultyAccess,
            changeFaculty: (ChangeFacultyAccess.length > 0) ? ChangeFacultyAccess[0].menuAccess : 2,
            changeFacultyAccess: (ChangeFacultyAccess.length > 0) ? ChangeFacultyAccess[0].menuAccess : 2,
        })

    }, [access])

    useEffect(() => {
        const BillingUnique = (Dropdown.addressTypeDrop.find((row) => row.displayText == (t('commonLabel.billing'))))?.uniqueId;
        if (formik.values.addressTypeId == BillingUnique) {
            facultyFlag ? setBillingFlag(true) : setBillingFlag(false)
            return
        } else setBillingFlag(false)
    }, [formik.values.addressTypeId])
    useEffect(() => {
        (formik.values.addressCategory) ?
            setRadioFlag(true) : setRadioFlag(false)

    }, [formik.values.addressCategory])
    useEffect(() => {
        if (selectedRow.length > 0) {

        }
    }, [formik.values.active])
    const handleSelection = (event) => {
        setinternational(event.target.value);
        formik.setFieldValue("addressCategory", event.target.value == "false" ? false : true);
    }
    useEffect(() => {
        //Checking if form is submitted or not 
        if (!formik.isSubmitting) return;
        //Checking the input if it has error and passing the error object {name:"name"} to scroll helper
        if (Object.keys(formik.errors).length > 0) {
            scrollIntoViewHelper(formik.errors);
        }
    }, [formik]);
    /// phone number validation
    const onBlurPhone = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, formatPhoneNumber(value));
    }

    const [alertActive, setAlertActive] = useState(false);


    return (
        <>
            <GenericModal infoMessage={"You cannot unset this active address. Create a new active address of this address type or set an existing address of this address type as active to automatically unset this address."}
                open={alertActive} onClose={setAlertActive}
                buttonText='Ok'

            />
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                <FormGroup>
                    <SelectInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)} mandatory={true} label={t('commonLabel.addressType')} labelId='addressTypeId' formikValue={formik.values.addressTypeId} formikChange={formik.handleChange}
                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.addressTypeDrop }}
                        errors={formik.errors.addressTypeId}
                        touched={formik.touched.addressTypeId}
                    />
                    {/* {billingFlag && <SelectInput disabled={( !facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && FacultyAccess.changeStudentAccess ===1) } label={t('commonLabel.billingCategory')} labelId='billingCategory' formikValue={formik.values.billingCategory} formikChange={formik.handleChange} dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.billingDrop }}
                    />} */}
                    {/* <SelectInput label={t('commonLabel.activeAddress')} labelId='active' formikValue={formik.values.active} formikChange={formik.handleChange}
                        errors={formik.errors.active}
                        touched={formik.touched.active}
                        disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)}
                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.activeDrop }} /> */}

                    <RadioInput label={t('commonLabel.addressSelection')} labelId='international' formikValue={international} customChange={handleSelection} isDoubled={true}
                        disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)}
                        list={[
                            {
                                label: t('commonLabel.usa'),
                                value: 'false'
                            },
                            {
                                label: t('commonLabel.nonUSA'),
                                value: 'true'
                            },
                        ]} />
                </FormGroup>
                <FormGroup>
                    <SelectInput label={t('commonLabel.activeAddress')} labelId='active' formikValue={formik.values.active} formikChange={formik.handleChange}
                        errors={formik.errors.active}
                        touched={formik.touched.active}
                        disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)}
                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.activeDrop }} />
                </FormGroup>
                <Divider />
                <br />
                <FormGroup>
                    <TextInput mandatory={true} labelId='address1' label={t('commonLabel.addressLine1')} formikValue={formik.values.address1} maxlength={'60'} formikChange={formik.handleChange}
                        errors={formik.errors.address1}
                        touched={formik.touched.address1}
                        disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)}
                        isDoubled={true} />
                </FormGroup>
                <FormGroup>
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='address2' label={t('commonLabel.addressLine2')} formikValue={formik.values.address2} maxlength={'60'} formikChange={formik.handleChange} isDoubled={true} />
                </FormGroup>
                <FormGroup>
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='address3' label={t('commonLabel.addressLine3')} formikValue={formik.values.address3} maxlength={'60'} formikChange={formik.handleChange} isDoubled={true} />
                    {(internationalRadioFlag) &&
                        <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} label={t('commonLabel.town')} labelId={"town"} formikValue={formik.values.town} formikChange={formik.handleChange} isDoubled={true} />
                    }
                </FormGroup>
                {/* <FormGroup>
                    {(internationalRadioFlag) &&
                        <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} label={t('commonLabel.town')} labelId={"town"} formikValue={formik.values.town} formikChange={formik.handleChange} isDoubled={true} />
                    }
                </FormGroup> */}
                  <FormGroup>
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)} labelId='city' maxlength={'60'} label={t('commonLabel.city')} formikValue={formik.values.city} formikChange={formik.handleChange} />
                    <SelectInput disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)}
                        label={t('commonLabel.state')} labelId='stateId' formikValue={formik.values.stateId} formikChange={formik.handleChange}
                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.stateDrop }}
                    />
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)} labelId='zipCode' maxlength={'10'} label={(internationalRadioFlag) ? t('commonLabel.postalCode') : t('commonLabel.zipCode')} formikValue={formik.values.zipCode} formikChange={formik.handleChange} />
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)} labelId='county' maxlength={'40'} label={(internationalRadioFlag) ? t('commonLabel.region') : t('commonLabel.county')} formikValue={formik.values.county} formikChange={formik.handleChange} />
                </FormGroup>
                <FormGroup>
                    <SelectInput disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)} label={t('commonLabel.country')} labelId='countryId' formikValue={formik.values.countryId} formikChange={formik.handleChange} dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.countryDrop }} />
                </FormGroup>
                <FormGroup>
                    <TextInput labelId='phone1'
                        // disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changefacultyAccess === 1)}
                        label={t('commonLabel.phoneOrMobile1')}
                        // onBlur={onBlurPhone}
                        phoneFormat={true}
                        maskPattern={elemConfiguration && getPhoneMaskPattern(elemConfiguration.domesticPhoneMask)}
                        maxlength={'18'}
                        helperText={formik.touched.phone1 && formik.errors.phone1}
                        errors={formik.touched.phone1 && Boolean(formik.errors.phone1)}
                        formikValue={formik.values.phone1}
                        formikChange={formik.handleChange} />
                    <TextInput labelId='phone2'
                        // disabled={(!studentFlag && prospectAccess.changeProspect === 1) || (studentFlag && prospectAccess.changeStudentAccess === 1)}
                        label={t('commonLabel.phoneOrMobile2')}
                        // onBlur={onBlurPhone}
                        phoneFormat={true}
                        maskPattern={elemConfiguration && getPhoneMaskPattern(elemConfiguration.domesticPhoneMask)}
                        maxlength={'18'}
                        helperText={formik.touched.phone2 && formik.errors.phone2}
                        errors={formik.touched.phone2 && Boolean(formik.errors.phone2)}
                        formikValue={formik.values.phone2}
                        formikChange={formik.handleChange} />
                    <TextInput labelId='workPhone1'
                        // disabled={(!studentFlag && prospectAccess.changeProspect === 1) || (studentFlag && prospectAccess.changeStudentAccess === 1)}
                        label={t('commonLabel.workPhone')}
                        // onBlur={onBlurPhone}
                        phoneFormat={true}
                        maskPattern={elemConfiguration && getPhoneMaskPattern(elemConfiguration.domesticPhoneMask)}
                        maxlength={'18'}
                        helperText={formik.touched.workPhone1 && formik.errors.workPhone1}
                        errors={formik.touched.workPhone1 && Boolean(formik.errors.workPhone1)}
                        formikValue={formik.values.workPhone1}
                        formikChange={formik.handleChange} />
                    <TextInput labelId='mobilePhone'
                        maxlength={'18'}
                        // disabled={(!studentFlag && prospectAccess.changeProspect === 1) || (studentFlag && prospectAccess.changeStudentAccess === 1)}
                        // onBlur={onBlurPhone}
                        phoneFormat={true}
                        maskPattern={elemConfiguration && getPhoneMaskPattern(elemConfiguration.domesticPhoneMask)}
                        helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
                        errors={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
                        label={t('commonLabel.mobilePhone')}
                        formikValue={formik.values.mobilePhone}
                        formikChange={formik.handleChange} />
                </FormGroup>
                <FormGroup>
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='email1' label={'Email 1'} formikValue={formik.values.email1} maxlength={'50'} formikChange={formik.handleChange}
                        errors={formik.errors.email1}
                        touched={formik.touched.email1} />
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='email2' label={t('commonLabel.email2')} formikValue={formik.values.email2} maxlength={'50'} formikChange={formik.handleChange}
                        errors={formik.errors.email2}
                        touched={formik.touched.email2}
                    />
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='email3' label={t('commonLabel.email3')} formikValue={formik.values.email3} maxlength={'50'} formikChange={formik.handleChange}
                        errors={formik.errors.email3}
                        touched={formik.touched.email3}
                    />
                    <TextInput disabled={(!facultyFlag && facultyAccess.changeProspect === 1) || (facultyFlag && facultyAccess.changeStudentAccess === 1)} labelId='fax1' label={t('commonLabel.fax')}
                        formikValue={formik.values.fax1}
                        formikChange={formik.handleChange}
                        // onBlur={onBlurPhone}
                        phoneFormat={true}
                        maskPattern={elemConfiguration && getPhoneMaskPattern(elemConfiguration.domesticPhoneMask)}
                        maxlength={'18'}
                        helperText={formik.touched.fax1 && formik.errors.fax1}
                        errors={formik.touched.fax1 && Boolean(formik.errors.fax1)} />
                </FormGroup>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    {/* <Tooltips flag={toolTipStatus} row={{
                        "insertUserID": formik.values.insertUserId,
                        "insertTime": formik.values.insertTime,
                        "updateUserID": formik.values.updateUserId,
                        "updateTime": formik.values.updateTime
                    }} /> */}
                    <ButtonWrapperFilter>
                        <Button aria-label={'Add'} className='BgYellowOrange' variant="contained" size="large" type="submit" disabled={(!facultyFlag && facultyAccess.changeFaculty === 1) || (facultyFlag && facultyAccess.changeFacultyAccess === 1)}>
                            {t('buttonLabel.add')}
                        </Button>
                        <Button onClick={() => { dispatch(updateFormStatus(false)); onClose(); }} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                            {t('buttonLabel.cancel')}
                        </Button>
                    </ButtonWrapperFilter>
                </div>
            </form>
            <GenericModal infoMessage={t('popupMessages.facultyCreated')}
                open={activeAddress} onClose={setActiveAddress}
                buttonText={t('buttonLabel.ok')}
            />
        </>
    )
}

export default CopyAddressFaculty;


