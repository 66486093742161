import { ADD_FERPA, DELETE_FERPA } from './type'

const initialState = {
    FERPA: []
};

const FERPAReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_FERPA:
            return {
                ...state,
                FERPA: [...payload]
            }
        case DELETE_FERPA:
            return {
                ...state,
                FERPA: [...state.FERPA.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default FERPAReducer;