import { GET_CRITERIA_TAB, UPDATE_FILE, GET_RECORDS_TAB, GET_HOLDING_TAB, GET_IMPORT_TAB } from './types'

const initialState = {
    criteriaTab: {
        academicyear: 0,
        financialAidYear: '',
        isiryearindicator: 1,
        tableRow1: [],
        tableRow: [],
        documentID: '',
        textData: true,
        isirYearIndicatorText: ''
    },
    fileDetails: {
        document: [],
        fileName: ''
    },
    recordsTab: {
        recordRows1: [],
        recordRows2: [],
        recordOneHeadCell: [],
        recordTwoHeadCell: [],
        selectedRow: {}
    },
    holdingTab: {
        holdingHeadCell: [],
        holdingRows: [],
        holdingSelectedRow: {},
        disableButton: false
    },
    importTab: {
        packageAsLoad: 0,
        packageStatus: 0,
        updateExistingStatuswithISIRData: false,
        importAllDuplicateTransactions: false,
        packageEnrollmentIntensity: 0
    }
};

const criteriaTabReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_FILE:
            return {
                ...state,
                fileDetails: {
                    ...state.fileDetails, [payload['id']]: payload['value']
                }
            }
        case GET_RECORDS_TAB:
            return {
                ...state,
                recordsTab: {
                    ...state.recordsTab, [payload['id']]: payload['value']
                }
            }
        case GET_HOLDING_TAB:
            return {
                ...state,
                holdingTab: {
                    ...state.holdingTab, [payload['id']]: payload['value']
                }
            }
        case GET_IMPORT_TAB:
            return {
                ...state,
                importTab: {
                    ...state.importTab, [payload['id']]: payload['value']
                }
            }

        default:
            return state;
    }
};

export default criteriaTabReducer;