import { UPDATE_CRITERIA_TAB, UPDATE_EXPORT_TAB } from './types'
const initialState = {
    criteriaTab: {
        formValue: {
            schoolCode: "",
            branchCode: "",
            officialSchoolName: "",
            transmissionDate: null,
            degreePeriod: "",
            termFrom: 0,
            termTo: 0,
            degreeEarned: "Yes",
            showAllDegrees: false,
            degrees: [],
            dateFrom: null,
            dateTo: null,
            transferDegree: "No"
        }
    },
    exportTab: {
        studentLoadingFlag:false,
        studentRows: [],
        exportRows: [],
        exportLoadingFlag:false,
        exportData: {},
    },

};
const degreeVerifyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_EXPORT_TAB:
            return {
                ...state,
                exportTab: {
                    ...state.exportTab, [payload['id']]: payload['value']
                }
            };
        default:
            return state;
    }
}
export default degreeVerifyReducer;