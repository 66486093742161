import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Card, CardContent, Button, Collapse } from '@material-ui/core';
import { FormGroup, ButtonWrapperFilter, useStyles } from '../../../../../styles/common-styles';
import { SelectInput, MultipleSelectInput, DateInput } from '../../../../../components/Forms/index';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup'
import { criteria_tab_load_info, criteria_tab_selectitems, student_info_delete } from '../../../../../services/apiURL';
import { deleteApi, getApi, postApi } from '../../../../../services/apiBase';
import HeaderTitle from '../../../../../utils/HeaderTitle/HeaderTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccessSelector } from '../../../../../redux/common/roleAccess/selectors';
import { workingToDo } from '../../../../../helper/constants';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { Addtodo } from './WorkingToDo';
import DefaultTable from '../../../../../utils/Table/DefaultTable';
import { toastMessage } from '../../../../../utils/Message/toasts';
import { reportWorkingToDo } from '../../../../../redux/admisssion/studentReports/workingToDo/selectors';
import { getCriteriaSearchData } from '../../../../../redux/admisssion/studentReports/workingToDo/action';
import ElementsDataGrid from '../../../../../utils/DataGrid/ElementsDataGrid';

export const Criteria = ({ addData }) => {
    const [toggleMoreOption, setToggleMoreOption] = useState(true);
    const [toggleMoreOption2, setToggleMoreOption2] = useState(true);
    const [multiColumnOptions, setMultiColumnOptions] = useState([]);
    const dispatch = useDispatch()
    const { criteriaTab } = useSelector(reportWorkingToDo)
    //Checking Super User and binding the Campus ID
    let superUser = localStorage.getItem('superUser')
    let campusID = localStorage.getItem('campusID')

    const [defaultValueCampus, setDefaultValueCampus] = useState([])
    //API Call for dropdown
    const [Dropdown, setDropdown] = useState({
        initialMajorDrop: [],
        accessCampusDrop: [],
        termDrop: [],
        admissionsCounselorDrop: [],
        initialStatusDrop: [],
        applicantEventDrop: [],
        addressTypeDrop: [],
        activityEventDrop: [],
        applicantStatusDrop: [],
        cancelledDrop: [],
        emailTemplateDrop: [],
        documentNameDrop: []
    })

    useEffect(() => {
        getApi(criteria_tab_selectitems).then((resp) => {
            const { initialMajor, email, accessCampus, term, admissionsCounselor, initialStatus, applicantEvent, addressType, activityEvent, applicantStatus, cancelled, documentRef } = resp.data.data;
            const Campusid = campusID.split(',').map(id => Number(id.trim()))
            const defaultCampusIds = accessCampus.filter(v => Campusid.includes(v.uniqueId)).map(v => Number(v.uniqueId));
            const transformedData = term.map(item => ({
                value: item.uniqueId,
                columns: [
                    { value: item.displayText },
                    { value: item.startDate.split('T')[0] },
                    { value: item.endDate.split('T')[0] }
                ]
            }))
            setDropdown({
                ...Dropdown,
                initialMajorDrop: initialMajor,
                accessCampusDrop: accessCampus,
                termDrop: term,
                admissionsCounselorDrop: admissionsCounselor,
                initialStatusDrop: initialStatus,
                applicantEventDrop: applicantEvent,
                addressTypeDrop: addressType,
                activityEventDrop: activityEvent,
                applicantStatusDrop: applicantStatus,
                cancelledDrop: cancelled,
                emailTemplateDrop: email,
                documentNameDrop: documentRef
            })
            setDefaultValueCampus(defaultCampusIds);
            setMultiColumnOptions(transformedData);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const initialValues = {
        initialMajor: [],
        accessCampus: (superUser === 'N') ? campusID.split(',').map(id => Number(id.trim())) : defaultValueCampus,
        term: [],
        admissionsCounselor: [],
        activityDateFrom: null,
        activityDateTo: null,
        applicantStatus: [],
        applicantEvent: [],
        initialStatus: [],
        activityEvent: [],
        addressType: 287,
        cancelled: 0,
    }
    const { t } = useTranslation();
    const [dateError, setDateError] = useState('');
    const [dateError1, setDateError1] = useState('');
    const classess = useStyles();


    const validationSchema = yup.object({
        activityDateFrom: yup
            .date().nullable()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.activityDueDateFrom') }))
            .max(new Date(t('validationMessages.maxDate'))),
        activityDateTo: yup
            .date().nullable()
            .required(t('validationMessages.isRequired', { what: t('commonLabel.activityDateTo') }))
            .max(new Date(t('validationMessages.maxDate'))),
        addressType: yup.number()
            .min(1, t('validationMessages.isRequired', { what: t('commonLabel.addressType') }))
            .required(t('validationMessages.isRequired', { what: t('commonLabel.addressType') })),

    })

    const headers = [
        { text: 'Term', width: "33%" },
        { text: 'Start Date', width: "33%" },
        { text: 'End Date', width: "33%" }
    ];
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(getCriteriaSearchData(values));
            const payloadRequest = {
                "addressTypeID": values.addressType.toString(),
                "activityDateFrom": values.activityDateFrom,
                "activityDateTo": values.activityDateTo,
                "strMajor": values.initialMajor.map(String),
                "strActivity": values.activityEvent.map(String),
                "strCampus": values.accessCampus.map(String),
                "strExpectedTerm": values.term.map(String),
                "strAdmCounselor": values.admissionsCounselor.map(String),
                "strInitialStatus": values.initialStatus.map(String),
                "strAppEvent": values.applicantEvent.map(String),
                "strAppStatus": values.applicantStatus.map(String),
                "cancelled": values.cancelled,
            }
            postApi(`${criteria_tab_load_info}`, payloadRequest).then((resp) => {
                setDisableBtn(false)
                setToggleMoreOption(false)
                settableRow(resp.data.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    });
    const initialState = {
        columns: {
            columnVisibilityModel: {
                phone2: false,
                fax1: false,
                email2: false,
                notes: false,
                campusName: false,
                prospectStatus: false,
                initialMajor: false,
                admissionCounselor: false,
                applicantEvent: false,
                addressType: false,
                cancelled: false,
            },
        },
    }
    const [headerCells] = useState([
        { id: 'lastName', numeric: true, disablePadding: false, label: 'Last Name' },
        { id: 'firstname', numeric: true, disablePadding: false, label: 'First Name' },
        { id: 'studentID', numeric: true, disablePadding: false, label: 'Student ID' },
        { id: 'phone1', numeric: true, disablePadding: false, label: 'Phone1' },
        { id: 'phone2', numeric: true, disablePadding: false, label: 'Phone2' },
        { id: 'workPhone1', numeric: true, disablePadding: false, label: 'Work Phone' },
        { id: 'fax1', numeric: true, disablePadding: false, label: 'Fax' },
        { id: 'email1', numeric: true, disablePadding: false, label: 'Email1' },
        { id: 'email2', numeric: true, disablePadding: false, label: 'Email2' },
        { id: 'activityDueDate', numeric: true, disablePadding: false, label: 'Activity Due Date' },
        { id: 'term', numeric: true, disablePadding: false, label: 'Term' },
        { id: 'activity', numeric: true, disablePadding: false, label: 'Activity' },
        { id: 'activityType', numeric: true, disablePadding: false, label: 'Activity Type' },
        { id: 'notes', numeric: true, disablePadding: false, label: 'Notes' },
        { id: 'campusName', numeric: true, disablePadding: false, label: 'Access Campus' },
        { id: 'prospectStatus', numeric: true, disablePadding: false, label: 'Applicant Status' },
        { id: 'initialMajor', numeric: true, disablePadding: false, label: 'Initial Major' },
        { id: 'admissionCounselor', numeric: true, disablePadding: false, label: 'Admission Counselor' },
        { id: 'applicantEvent', numeric: true, disablePadding: false, label: 'Applicant Event' },
        { id: 'addressType', numeric: true, disablePadding: false, label: 'Address Type' },
        { id: 'cancelled', numeric: true, disablePadding: false, label: 'Cancelled' },
    ]);
    const { access } = useSelector(updateAccessSelector)
    const { subDeepNestedParentLevel } = access;
    const [workingToDoAccess, setWorkingToDAccess] = useState({
        workingToDo: 2,
    })
    useEffect(() => {

        let workingToDoAccess = subDeepNestedParentLevel.filter((v, i) =>
            v.menuItemName.trim() === workingToDo && v.moduleorder === 1
        )

        setWorkingToDAccess({
            ...workingToDoAccess,
            workingToDo: (workingToDoAccess.length > 0) ? workingToDoAccess[0].menuAccess : 2,
        })

    }, [access])
    const [accessFlag, setAccessFlag] = useState(false)
    useEffect(() => {
        (workingToDoAccess.workingToDo < 2) ? setAccessFlag(true) : setAccessFlag(false)
    }, [workingToDoAccess])
    //Role Based Access implementation ends here
    const [showAddData, setShowAddData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const initialHeadCell = [
        { id: 'lastName', numeric: true, disablePadding: false, label: 'Last Name' },
        { id: 'firstName', numeric: true, disablePadding: false, label: 'First Name' },
        { id: 'studentID', numeric: true, disablePadding: false, label: 'Student ID' },
        { id: 'phone1', numeric: true, disablePadding: false, label: 'Phone 1' },
        { id: 'workPhone1', numeric: true, disablePadding: false, label: 'Work Phone' },
        { id: 'email1', numeric: true, disablePadding: false, label: 'Email1' },
        { id: 'activityDueDate', numeric: true, disablePadding: false, label: 'Activity Due Date' },
        { id: 'term', numeric: true, disablePadding: false, label: 'Term' },
        { id: 'activity', numeric: true, disablePadding: false, label: 'Activity' },
        { id: 'activityType', numeric: true, disablePadding: false, label: 'Activity Type' },
    ];
    const [tableRow, settableRow] = useState([]);
    const [headCells, setHeadCell] = useState(initialHeadCell)
    const [disableBtn, setDisableBtn] = useState(true)
    const onClose = (value) => {
        addData(value);
        setShowAddData(value);
        document.getElementsByTagName('html')[0].classList.remove('popup-Page');
        handlePostCall();
    }
    const handlePostCall = () => {
        const payloadRequest = {
            "addressTypeID": criteriaTab.addressType.toString(),
            "activityDateFrom": criteriaTab.activityDateFrom,
            "activityDateTo": criteriaTab.activityDateTo,
            "strMajor": criteriaTab.initialMajor.map(String),
            "strActivity": criteriaTab.activityEvent.map(String),
            "strCampus": criteriaTab.accessCampus.map(String),
            "strExpectedTerm": criteriaTab.term.map(String),
            "strAdmCounselor": criteriaTab.admissionsCounselor.map(String),
            "strInitialStatus": criteriaTab.initialStatus.map(String),
            "strAppEvent": criteriaTab.applicantEvent.map(String),
            "strAppStatus": criteriaTab.applicantStatus.map(String),
            "cancelled": criteriaTab.cancelled,
        }
        postApi(`${criteria_tab_load_info}`, payloadRequest).then((resp) => {
            setToggleMoreOption(false)
            settableRow(resp.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    //work_to_do add
    const handleAdd = () => {
        setShowAddData(true);
        addData(true);
        setSelectedRow1("");
    }


    const handleSelectedRow = (value) => {
        setSelectedRow1(value);
    }
    //working_to_do edit 
    const handleEditRow = () => {
        setShowAddData(true);
        addData(true);
        setToggleMoreOption2(true);
    }
    const handleView = () => {
        setShowAddData(true);
        addData(true);
        setToggleMoreOption2(true);
    }
    const handleDeleteRow = () => {
        if (selectedRow1) {
            deleteApi(`${student_info_delete}/${selectedRow1.studentActivityID}`).then((res) => {
                let tempDelete = tableRow.filter((item) => item.studentActivityID !== selectedRow1.studentActivityID)
                settableRow(tempDelete);
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyDeleted'));
                DefaultTable.resetTable('workingToDoTable');
            }).catch((err) => console.log(err))
        }
    }
    const [selectedRow1, setSelectedRow1] = useState('')
    const resetForm = () => {
        formik.resetForm();
        handleDate();
    }
    const getYesterdayDate = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return yesterday;

    }
    const handleDate = () => {
        const today1 = getYesterdayDate().toISOString().split('T')[0]
        formik.setFieldValue("activityDateFrom", today1)
        const today = new Date();
        formik.setFieldValue("activityDateTo", today)
    }
    useEffect(() => {
        handleDate();
    }, [defaultValueCampus])
    return (
        <>
            {(!showAddData) ?
                <>
                    <Card>
                        <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                            <CardContent>
                                <p style={{ display: 'flex' }}>
                                    <span style={{ flex: '1' }} className="searchLanding">{t('commonLabel.criteria')}</span>
                                    <ArrowForwardIos
                                        className={`${toggleMoreOption ? 'active' : ''} arrow`}
                                        onClick={() => { setToggleMoreOption(!toggleMoreOption) }}
                                    />
                                </p>
                                <br />
                                <FormGroup onClick={() => setToggleMoreOption(true)} onFocus={() => setToggleMoreOption(true)}>
                                    <DateInput label={t('commonLabel.activityDueDateFrom')}
                                        labelId={'activityDateFrom'}
                                        formik={formik}
                                        maxDate={new Date(t('validationMessages.maxDate'))}
                                        defaultValue={formik.values.activityDateFrom}
                                        maxDateMessage={t('validationMessages.greaterThanDate',
                                            { what: t('commonLabel.activityDueDateFrom') }
                                        )}
                                        onError={(errMsg) => setDateError(errMsg)}
                                        errors={formik.errors.activityDateFrom}
                                        touched={formik.touched.activityDateFrom}
                                        mandatory={true}
                                    />
                                    <DateInput
                                        label={t('commonLabel.activityDueDateTo')}
                                        labelId={'activityDateTo'}
                                        formik={formik}
                                        maxDate={new Date(t('validationMessages.maxDate'))}
                                        defaultValue={formik.values.activityDateFrom ? formik.values.activityDateTo : formik.values.activityDateFrom}
                                        minDate={formik.values.activityDateFrom}
                                        maxDateMessage={t('validationMessages.greaterThanDate',
                                            { what: t('commonLabel.activityDueDateTo') }
                                        )}
                                        minDateMessage={t('validationMessages.lessThan',
                                            {
                                                from: t('commonLabel.activityDueDateFrom'),
                                                to: t('commonLabel.activityDueDateTo')
                                            }
                                        )}
                                        errors={formik.errors.activityDateTo}
                                        touched={formik.touched.activityDateTo}
                                        onError={(errMsg) => setDateError1(errMsg)}
                                        mandatory={true}
                                    />
                                    <MultipleSelectInput
                                        label={t('commonLabel.initialMajor')}
                                        labelId='initialMajor'
                                        formik={formik}
                                        list={Dropdown.initialMajorDrop}
                                    />
                                    <MultipleSelectInput
                                        label={t('commonLabel.accessCampus')}
                                        labelId={'accessCampus'}
                                        formik={formik}
                                        list={Dropdown.accessCampusDrop}
                                        disabled={(superUser === 'N') ? true : false}
                                    />
                                </FormGroup>
                                <Collapse in={toggleMoreOption} timeout="auto" unmountOnExit>
                                    <FormGroup>
                                        <MultipleSelectInput
                                            label={t('commonLabel.expectedTerm')}
                                            labelId={'term'}
                                            formik={formik}
                                            list={Dropdown.termDrop}
                                            isDoubled={false}
                                            singleSelect={false}
                                            editedValue={[]}
                                            disabled={false}
                                            useMultiColumn={true}
                                            multiColumnOptions={multiColumnOptions}
                                            headers={headers}
                                        />
                                        <MultipleSelectInput
                                            label={t('commonLabel.admCounselor')}
                                            labelId={'admissionsCounselor'}
                                            formik={formik}
                                            list={Dropdown.admissionsCounselorDrop}
                                        />
                                        <MultipleSelectInput
                                            label={t('commonLabel.initialStatus')}
                                            labelId={'initialStatus'}
                                            formik={formik}
                                            list={Dropdown.initialStatusDrop}
                                        />
                                        <MultipleSelectInput
                                            label={t('commonLabel.applicationEvent')}
                                            labelId={'applicantEvent'}
                                            formik={formik}
                                            list={Dropdown.applicantEventDrop}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <MultipleSelectInput
                                            label={t('commonLabel.applicationStatus')}
                                            labelId={'applicantStatus'}
                                            formik={formik}
                                            list={Dropdown.applicantStatusDrop}
                                        />
                                        <SelectInput
                                            label={t('commonLabel.addressType')}
                                            labelId='addressType'
                                            mandatory={true}
                                            formikValue={formik.values.addressType}
                                            formikChange={formik.handleChange}
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.addressTypeDrop }}
                                            touched={formik.touched.addressType}
                                            errors={formik.errors.addressType}
                                        />
                                        <MultipleSelectInput
                                            label={t('commonLabel.activityEvent')}
                                            labelId={'activityEvent'}
                                            formik={formik}
                                            list={Dropdown.activityEventDrop}
                                        />
                                        <SelectInput
                                            label={t('commonLabel.cancelled')}
                                            labelId='cancelled'
                                            formikValue={formik.values.cancelled}
                                            formikChange={formik.handleChange}
                                            dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.cancelledDrop }}
                                        />
                                    </FormGroup>
                                    <ButtonWrapperFilter>
                                        <Button className="BgYellowOrange" variant="contained" size="large" type="submit" >
                                            {t('buttonLabel.search')}
                                        </Button>
                                        <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={resetForm}>
                                            {t('buttonLabel.reset')}
                                        </Button>
                                    </ButtonWrapperFilter>
                                </Collapse>
                            </CardContent>
                        </form >

                    </Card>
                    <>
                        <HeaderTitle headerTitle={t('commonLabel.searchResults')} variant={'subHeading'} />
                        <Card>
                            <CardContent>
                                <HeaderTitle headerTitle={t('commonLabel.workingToDo')} variant="subHeading" buttonText={t('buttonLabel.add')}
                                // addData={handleAdd} disableButton={(disableBtn) || (accessFlag)} 
                                />
                                <ElementsDataGrid
                                    trackingLabel={"workingToDoTable"}
                                    headCells={headerCells}
                                    rows={tableRow}
                                    pagination={tableRow.length > 0}
                                    isLoading={isLoading}
                                    dataGridToolbar={true}
                                    isLookup={false}
                                    onAdd={handleAdd}
                                    disableAdd={(disableBtn) || (accessFlag)}
                                    onEdit={handleEditRow}
                                    onView={handleView}
                                    viewAccess={workingToDoAccess.workingToDo !== 2}
                                    addEditAccess={workingToDoAccess.workingToDo === 2}
                                    dataGridColumnMenus={true}
                                    onRowSelect={handleSelectedRow}
                                    deleteDisable={accessFlag}
                                    initialColumn={initialState}
                                />
                            </CardContent>
                        </Card>
                    </>
                </>
                : <Addtodo onClose={onClose} Dropdown={Dropdown} selectedRow1={selectedRow1} accessFlag={accessFlag} toggleMoreOption2={toggleMoreOption2} setToggleMoreOption2={setToggleMoreOption2} />}
        </>

    )
}