import { UPDATE_CRITERIA_TAB, UPDATE_IMPORT_TAB, UPDATE_FILE,RESET} from './types'
const initialState = {
    criteriaTab:{
        tableRow1: [],
        tableRow: [],
    },
    ImportTab:"",
    fileDetails:""
};
const importPowerFaidsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
                
            };
        case UPDATE_IMPORT_TAB:
            return {
                ...state,
                ImportTab: {...(state.ImportTab),...payload}
                
            };
        case UPDATE_FILE:
            return {
                ...state,
            fileDetails: {...(state.fileDetails),...payload}
            }
            case RESET:
                return {
                    ...state,
                    ...initialState
                }
        default:
            return state;
    }
}
export default importPowerFaidsReducer