export const UPDATE_PLAN_MAP_ROWS = 'UPDATE_PLAN_MAP_ROWS';
export const UPDATE_SELECTED_PLAN_MAP = 'UPDATE_SELECTED_PLAN_MAP';
export const UPDATE_CRITERIA = 'UPDATE_CRITERIA';
export const UPDATE_SELECTED_STUDENT = 'UPDATE_SELECTED_STUDENT';
export const UPDATE_PLAN_SETUP = 'UPDATE_PLAN_SETUP';
export const UPDATE_CRITERIA_SELECTED = 'UPDATE_CRITERIA_SELECTED'
export const UPDATE_PROCESS_ROWS = 'UPDATE_PROCESS_ROWS'





