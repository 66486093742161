import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getSelectedProfiles } from '../../../../redux/common/search-data/action';
import DocumentTracking from './DocumentTracking';

const ProspectDocView = () => {
    // Declaring Breadcrumb value here
     const history = useHistory();

    const dispatch = useDispatch();
    const [selectedSelected, SetSelectedSelected] = useState('');
   
    useEffect(() => {
        if (history.location.state) {
            SetSelectedSelected(history.location.state.selectedRow)
            dispatch(getSelectedProfiles({ profileName: `${history.location.state.selectedRow.prospectName}`, profileId: history.location.state.selectedRow.prospectId, type:"prospect"}))
        } 
    }, [])

  
    return (
        <>
           <DocumentTracking selectedRow={selectedSelected} SetSelectedSelected={SetSelectedSelected}/>
        </>
    )
}

export default ProspectDocView;