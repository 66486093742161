import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import { ButtonWrapperFilter } from '../../styles/common-styles';
import styled from 'styled-components';

export const ConfirmDialog = (props) => {
  const { title, open, setOpen, onConfirm, onCancel, btnTextOne, btnTextTwo } = props;
  const flag = false;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      disableEscapeKeyDown
    >
      <DialogTitle id="confirm-dialog" style={{ fontSize: '14px' }}>{title}</DialogTitle>
      <DialogActions>
        <ButtonWrapperFilter>
          <Button
            variant="contained"
            onClick={() => { setOpen(false); onConfirm(); }}
            className={'BgLightBlue confirm'}
          >
            {btnTextOne}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onCancel();
            }}
            className={'BgYellowOrange confirm'}
          >
            {btnTextTwo}
          </Button>
        </ButtonWrapperFilter>
      </DialogActions>
    </Dialog>
  );
};
export const PromptDialog = (props) => {
  const { title, open, setOpen, onConfirm } = props;
  const flag = false;
  const backClick = () => {
    return flag;
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="prompt-dialog"
      disableEscapeKeyDown
    >
      <DialogTitle id="prompt-dialog" style={{ fontSize: '14px' }}>{title}</DialogTitle>
      <DialogActions>
        <ButtonWrapperFilter>
          <Button
            variant="contained"
            onClick={() => { setOpen(false); onConfirm(); }}
            className={'BgLightBlue confirm'}
          >
            Ok
          </Button>
        </ButtonWrapperFilter>
      </DialogActions>
    </Dialog>
  );
};
  const HeadTitle = styled.span`
  display: flex;
  color: green;
  margin-bottom: 15px;
  line-height: 1.25;
  font-size: 1.25em;
  `
export const SuccessPopup = (props) => {
  const { title, open, setOpen, onConfirm, confirmText } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="prompt-dialog"
      disableEscapeKeyDown
    >
      <DialogTitle id="prompt-dialog" style={{ fontSize: '14px' }}>
        <HeadTitle><i className="material-icons-outlined">check_circle_outline</i>{title}</HeadTitle>
        <p>{confirmText}</p>
      </DialogTitle>
      <DialogActions>
        <ButtonWrapperFilter style={{justifyContent:'center'}}>
          <Button
            variant="contained"
            onClick={() => { setOpen(false); onConfirm(); }}
            className={'BgLightBlue confirm'}
          >
            Ok
          </Button>
        </ButtonWrapperFilter>
      </DialogActions>
    </Dialog>
  );
};