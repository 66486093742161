import React, { Suspense, useEffect, useState } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import routesConfig from "./routeConfig";
import CircularProgress from '@mui/material/CircularProgress';
// import { startAutoRefresh } from '../services/autoTokenRefresh';
// import config from "../services/config";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";  

export const Routewrapper = () => {
    // const [intervalId, setIntervalId] = useState();
    // useEffect(() => {
    //     intervalId && clearInterval(intervalId);

    //     const tokenRefreshInMin = config.credentials.tokenRefreshInMin;

    //     setIntervalId(startAutoRefresh(tokenRefreshInMin));

    //     return () => {
    //         clearInterval(intervalId);
    //     };

    // }, []);

    //Reloading of the page, will prompt to stay or leave
    // useEffect(() => {
    //     window.addEventListener("beforeunload", function(e) {
    //       let confirmationMessage = "";
    //       (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    //       return confirmationMessage; //Webkit, Safari, Chrome
    //     });
    //   });

    const handleKeyDown = (event) => {
        if (event.keyCode === 255) {
          event.preventDefault();
          const currentUrl = window.location.origin;
          window.open(currentUrl +"/SM Lite Built Online Help/Default.htm");
        }
      };
     
      useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    return (
        <>
            <Suspense
                fallback={
                    <div className="loader" style={{ height: "100vh" }}>
                        <CircularProgress style={{ color:'#6AC5DA' }} />
                    </div>
                }
            >
                <Router basename={'#'}>
                    <Switch>
                        {routesConfig.map(({ Layout, exact, path, component }, index) => {
                            return (
                                <Layout
                                    exact={exact}
                                    path={path}
                                    component={component}
                                    key={`route+${index}`}
                                />
                            );
                        })}
                    </Switch>
                </Router>
            </Suspense>
            <ToastContainer position="top-center" style={{ width: '600px' }} autoClose={3000}
                hideProgressBar={true} newestOnTop={false} closeOnClick
                rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </>
    )
}
