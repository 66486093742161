export const formatDate = function formatDate(date) {
    date = date ? new Date(date) : new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let formattedDate = mm + '/' + dd + '/' + yyyy;
    return formattedDate;
}

export const format_Date = function formatDate(date) {
    date = date ? new Date(date) : new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let formattedDate = yyyy + '-' + mm + '-' + dd;
    return formattedDate;
}