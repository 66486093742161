import { ADD_ADDRESS, DELETE_ADDRESS, EDIT_ADDRESS, RESET_ADDRESS, GET_ADDRESS } from './type'

export const addAddress = (address) => async (dispatch) => {
    await dispatch({
        type: ADD_ADDRESS,
        payload: [...address]
    });
};

export const getAddress = (address) => async (dispatch) => {
    await dispatch({
        type: GET_ADDRESS,
        payload: [...address]
    });
};

export const deleteAddress = (address) => async (dispatch) => {
    await dispatch({
        type: DELETE_ADDRESS,
        payload: address
    });
};

export const editAddress = (address) => async (dispatch) => {
    await dispatch({
        type: EDIT_ADDRESS,
        payload: address
    });
};

export const resetAddress = (address) => async (dispatch) => {
    await dispatch({
        type: RESET_ADDRESS,
        payload: address
    });
};