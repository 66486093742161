import { UPDATE_CRITERIA_TAB, UPDATE_EDE_TAB, UPDATE_IMPORT_TAB } from './types'
const initialState = {
    criteriaTab: {
        formValue: {
            academicYear: "",
            financialAidYear: "",
            fileName:""
        },
        termsInCalendarRows:[]
    },
    edeTab: {
        toBeImportedRows: [],
        distributionRows: [],
        notToBeImportedRows:[]
    },
    importTab: {
        importFormValue:{
            academicYearID: "",
            packageLoadID: 0,
            fundDecisionToAcceptIDs: [],
            packageStatusID: 0,
        },
        awardStatusRows: [],
        selectedAwardStatus:{}
    }
};
const edeAwardsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_CRITERIA_TAB:
            return {
                ...state,
                criteriaTab: {
                    ...state.criteriaTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_EDE_TAB:
            return {
                ...state,
                edeTab: {
                    ...state.edeTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_IMPORT_TAB:
            return {
                ...state,
                importTab: {
                    ...state.importTab, [payload['id']]: payload['value']
                }
            };
        default:
            return state;
    }
}
export default edeAwardsReducer;