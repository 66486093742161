import { UPDATE_LOAD_PROSPECT,RESET_STATE } from './types'

export const updateLoadProspect = (payload) => async (dispatch) => {
    await dispatch({
        type: UPDATE_LOAD_PROSPECT,
        payload: payload
    });
};

export const resetState = () => async (dispatch) => {
    await dispatch({
        type: RESET_STATE,
    });
};
