// export const formatTimeWithoutSeconds = function formatTime(date) {
//     date = date ? new Date(date) : new Date();
//     var hours = date.getHours();
//     var minutes = date.getMinutes();
//     var ampm = hours >= 12 ? 'pm' : 'am';
//     hours = hours % 12;
//     hours = hours ? hours : 12; // the hour '0' should be '12'
//     minutes = minutes < 10 ? '0' + minutes : minutes;
//     var strTime = hours + ':' + minutes + ' ' + ampm;
//     return strTime;
// }

export const formatTime = function formatTime(date) {
    date = date ? new Date(date) : new Date();
    var dd = String(date.getDate());
    var mm = String(date.getMonth() + 1); //January is 0!
    var yyyy = date.getFullYear();
    let formattedDate = mm + '/' + dd + '/' + yyyy;
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "pm" : "am";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = formattedDate + " " + hours + ":" + minutes + ":" + seconds + " " + am_pm;
    return time;
};

// returns HH:MM XM
export const formatTimeWithoutSeconds = function formatTime(date) {
    date = date ? new Date(date) : new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "pm" : "am";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var time = hours + ":" + minutes + " " + am_pm;
    return time;
};


export const formatMonthYear = (date) => {
    date = date ? new Date(date) : new Date();
    var mm = String(date.getMonth() + 1); //January is 0!
    var yyyy = date.getFullYear();
    let formattedDate = mm + '/' + yyyy;
    return formattedDate;
};

export const formatTimeOnly = function formatTime(date) {
    date = date ? new Date(date) : new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "pm" : "am";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    return time;
};