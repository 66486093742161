import axios from 'axios';

function getApi(path,config) {
    return axios.get(path,config);
}

function getApiWithParams(path, data) {
    return axios.get(path, {
        params: data
    });
}

function postApi(path, data) {
    return axios.post(path, data);
}

function putApi(path, data) {
    return axios.put(path, data);
}

function puttApiWithParams(path, data) {
    return axios.put(
        path,
        {},
        {
            params: data
        }
    );
}
function patchApi(path, data) {
    return axios.patch(path, data);
}
function patchApiWithParams(path, data) {
    return axios.patch(
        path,
        {},
        {
            params: data
        }
    );
}
function postApiWithParams(path, data) {
    return axios.post(
        path,
        {},
        {
            params: data
        }
    );
}

function deleteApi(path, payload='') {
    if (payload) {
        return axios.delete(path, payload)
    }
    return axios.delete(path);
}
function deleteApiWithPayload(path, payload) {
        return axios.delete(path, {data: payload})
}
function patchApiWithPayload(path, payload) {
    return axios.patch(path, payload);
}

function deleteApiWithParams(path, data) {
    return axios.delete(path, {
        params: data
    });
}

function getApiAccessRefreshToken(path, username, password) {
    return axios.get(path,
        {
            headers: {
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Credentials": true,
                // "Access-Control-Allow-Methods": "*"
            },
            auth: {
                username,
                password
            }
        });
}

export {
    getApi,
    getApiWithParams,
    postApi,
    postApiWithParams,
    deleteApi,
    getApiAccessRefreshToken,
    deleteApiWithParams,
    putApi,
    puttApiWithParams,
    patchApi,
    patchApiWithParams,
    deleteApiWithPayload,
    patchApiWithPayload
};
