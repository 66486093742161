import { GET_SCOREDATA, GET_SUBJECTDATA , GET_TYPEDATA} from './types';

const initialState = {
    scoreTab:{
        assessment: "M",
        type: "X",
        requirementstable:[]
    },
    subjectTab: {
        mathSubject: [],
        readingSubject: [],
        writingSubject: [],
    },
    typesTab: {
        developmentalCourseTypes: [],
        nonDevelopmentalCourseTypes: [],
        technicalCourseTypes: [],
    },
}

const texasStateReportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SCOREDATA:
            return {
                ...state,
                scoreTab: {
                    ...state.scoreTab, 
                    ...payload
                }
            };
        case GET_SUBJECTDATA:
            return {
                ...state,
                subjectTab: {
                    ...state.subjectTab, 
                    ...payload
                }
            };
        case GET_TYPEDATA:
            return {
                ...state,
                typesTab: {
                    ...state.typesTab, 
                    ...payload
                }
            };
        default:
            return state;
    }
}

export default texasStateReportReducer;

