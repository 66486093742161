import {
    GET_SELECTED_STUDENT_STATUS
} from './types';


const initialState = {
    studentStatus: {
        selectedStudentStatus: ''
    },
    studentProgram: {

    },
    extracurricularActivities: {

    }
}

const studentStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SELECTED_STUDENT_STATUS:
            return {
                ...state,
                studentStatus: {
                    ...state, selectedStudentStatus: payload
                }
            }

        default:
            return state;

    }

}

export default studentStatusReducer