import React from 'react';
import { FormInputWrapper } from '../../styles/common-styles'
import { RadioGroup, FormLabel, FormControl, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

/**
 * @param {{
 * label: string, 
 * labelId: string, 
 * formikValue: Object, 
 * formikChange: Function, 
 * isDoubled?: Boolean,
//  * verticle?: Boolean,
 * list: [{label:string,value:string,disabled:boolean}]
 * }} props 
 */

export const RadioInput = (props) => {
    const { label, labelId, formikValue, formikChange,customStyle, isDoubled,isTripple, errors, touched, mandatory, list, customChange,disabled , defaultValue } = props;
    return (
        <FormInputWrapper style={customStyle} isDoubled={isDoubled ? isDoubled : null} isTripple={isTripple ? isTripple : null} className={isDoubled ? 'doubled' : null}>
            <FormControl component="fieldset" >
                <RadioGroup className={'RadioGroup'} aria-label={labelId} name={labelId} defaultValue={defaultValue}  value={formikValue} onChange={formikChange ? formikChange : (e)=>customChange(e)} >
                    {label && <FormLabel style={{ alignSelf: "center", fontFamily: 'Lato-regular, sans-serif', fontWeight:700, marginRight:"10px", fontSize:"14px"}} className={(mandatory) ? 'mandatory' : ''} component="legend"  >{label}
                    <span className={(mandatory) ? 'asterisk' : ''}>{(mandatory) ? '*' : ''}</span></FormLabel>
                    }
                    
                    {list && list.map((v, i) => 
                        <FormControlLabel title= {(v.title) ? v.title : ''} key={i} value={v.value} 
                        control={<Radio inputProps={{'aria-label':  v.label}} />} 
                        label={v.label} 
                        
                        disabled={(v && v?.disabled) ? v.disabled : disabled}/>)
                    }
                </RadioGroup>
                {errors && touched && <FormHelperText className="helperText">{errors}</FormHelperText>}

            </FormControl>
        </FormInputWrapper>
    )
}