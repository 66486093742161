import { isMobileOrTablet } from '../../../helper/drawerSetting';
import { UPDATE_DRAWER, UPDATE_FORM_STATUS, UPDATE_MANDATORY_FIELDS, UPDATE_SIDEBAR_ITEMS, RESET_SIDEBAR_ITEMS } from './types'

const initialState = {
    formStatus: false,
    sideBar: (isMobileOrTablet() ? false : true),
    mandatoryField: false,
    sideBarItems: []
};

const updateFormStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_FORM_STATUS:
            return {
                ...state,
                formStatus: payload
            };
        case UPDATE_DRAWER:
            return {
                ...state,
                sideBar: payload
            };
        case UPDATE_MANDATORY_FIELDS:
            return {
                ...state,
                mandatoryField: payload
            };
        case UPDATE_SIDEBAR_ITEMS:
            return {
                ...state,
                sideBarItems: [...state.sideBarItems, ...payload]
            };
        case RESET_SIDEBAR_ITEMS:
            return {
                ...state,
                sideBarItems: payload
            };
        default:
            return state;
    }
};

export default updateFormStatusReducer;