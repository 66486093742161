import { UPDATE_GRADE_CATALOG_TAB, UPDATE_CUSTOM_GRADES_TAB } from './types'
const initialState = {
    gradeCatalogTab: {
        gradeCatalogDropDown:[],
        gradeCatalog: 0,
    },
    customGradeTab: {
        customGradeRows: [],
        selectedCustomRow:{}
    },
};
const customGradeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_GRADE_CATALOG_TAB:
            return {
                ...state,
                gradeCatalogTab: {
                    ...state.gradeCatalogTab, [payload['id']]: payload['value']
                }
            };
        case UPDATE_CUSTOM_GRADES_TAB:
            return {
                ...state,
                customGradeTab: {
                    ...state.customGradeTab, [payload['id']]: payload['value']
                }
            };
        default:
            return state;
    }
}
export default customGradeReducer