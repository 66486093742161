import { ADD_ETHNICITY, DELETE_ETHNICITY, ADD_STUDENT_ETHNICITY, DELETE_STUDENT_ETHNICITY } from './type'

const initialState = {
    ethnicity: [
    ],
    studentEthnicity: [
    ]
};

const ethnicityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_ETHNICITY:
            return {
                ...state,
                ethnicity: [...payload]
            }
        case DELETE_ETHNICITY:
            return {
                ...state,
                ethnicity: [...state.ethnicity.filter(item => !payload.includes(item.id.toString()))]
            }
        case ADD_STUDENT_ETHNICITY:
            return {
                ...state,
                studentEthnicity: [...payload]
            }
        case DELETE_STUDENT_ETHNICITY:
            return {
                ...state,
                studentEthnicity: [...state.studentEthnicity.filter(item => !payload.includes(item.id.toString()))]
            }
        default:
            return state;
    }
};

export default ethnicityReducer;