import React, { useEffect, useState } from 'react';
import {  Button , InputLabel, TextareaAutosize} from '@material-ui/core';
import { useFormik } from 'formik';
import { TextInput, SelectInput, RadioInput } from '../../components/Forms';
import { FormGroup, useStyles, ButtonWrapperFilter,FormInputWrapper } from '../../styles/common-styles'
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, editAddress } from '../../redux/admisssion/prospects/addAddress/action';
import { addAddressSelector } from '../../redux/admisssion/prospects/addAddress/selectors';
import { updateFormStatus } from '../../redux/common/formValueChange/action';
import { getApi } from '../../services/apiBase'
import { addInfo_dropdown } from '../../services/apiURL';
import { scrollIntoViewHelper } from '../../helper';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber, formPhoneNumber } from '../../helper/formatePhone';
import GenericModal from '../../components/Modal/GenericModal';



const AddCourse = (props) => {
    const { t } = useTranslation();

    const { onClose, selectedRow, selectedIndex } = props;
    const tableRows = useSelector(addAddressSelector);
    const dispatch = useDispatch();
    const classess = useStyles();
    const [formValues, setFormValues] = useState('');
    const [toolTipStatus, setToolTipStatus] = React.useState('');
    const username = localStorage.getItem("username");
    const [international, setinternational] = useState('false');
    const [internationalRadioFlag, setRadioFlag] = useState('');
    const [billingFlag, setBillingFlag] = useState(false)
    const history = useHistory();
    const [studentFlag, setStudentFlag] = useState(false);
    const [activeAddress, setActiveAddress] = useState(false);

    const [Dropdown, setDropdown] = useState({
        "activeDrop": [],
        "addressTypeDrop": [],
        "statusDrop": [],
        "countryDrop": [],
        "stateDrop": [],
        "billingDrop": []
    })
    const initialValues = {
        "active": 1,
        "address1": "",
        "address2": "",
        "address3": "",
        "addressCategory": false,
        "addressId": 0,
        "addressType": 0,
        "billingCategory": 0,
        "city": "",
        "countryId": 0,
        "county": "",
        "email1": "",
        "email2": "",
        "email3": "",
        "fax": "",
        "mobilePhone": "",
        "phone1": "",
        "phone2": "",
        "state": 0,
        "workPhone": "",
        "zipCode": "",
    }
    const validationSchema = yup.object({
        address1: yup.string("").required(t('validationMessages.isRequired', { what: t('commonLabel.addressLine1') })),
        addressType: yup.number()
            .min(1, t('validationMessages.isRequired', { what: t('commonLabel.addressType') }))
            .required(t('validationMessages.isRequired', { what: t('commonLabel.addressType') })),
        active: yup
            .string(t('validationMessages.isRequired', { what: t('commonLabel.activeAddress1') })),
        email1: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email1') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email1') })),
        email2: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email2') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email2') })),
        email3: yup
            .string(t('validationMessages.validInput', { what: t('commonLabel.email3') }))
            .email(t('validationMessages.validInput', { what: t('commonLabel.email3') })),
        phone1: yup
            .string()
            .matches(/^[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,18}$/im, t('validationMessages.validInput', { what: t('commonLabel.phone1') })),
        phone2: yup
            .string()
            .matches(/^[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,18}$/im, t('validationMessages.validInput', { what: t('commonLabel.phone2') })),
        workPhone: yup
            .string()
            .matches(/^[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,18}$/im, t('validationMessages.validInput', { what: t('commonLabel.workPhone') })),
        mobilePhone: yup
            .string()
            .matches(/^[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,18}$/im, t('validationMessages.validInput', { what: t('commonLabel.mobilePhone') })),
        fax: yup
            .string()
            .matches(/^[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,18}$/im, t('validationMessages.validInput', { what: t('commonLabel.fax') })),
    });
    const formik = useFormik({
        initialValues: formValues || initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (tableRows.address.length > 0) {
                if ((values.active == 2) && selectedRow.length > 0) {
                    let activeRows = tableRows.address.filter(row => row.active == 1 && row.addressId != selectedRow[0].addressId);
                    if (activeRows.length < 1) {
                        formik.setFieldError("active", t('validationMessages.isRequired', { what: t('commonLabel.atLeastOneAddress') }))
                        return;
                    }
                }
            }
            let payload = {
                ...values,
                "phone1": (values.phone1 === '') ? '' : formPhoneNumber(values.phone1),
                "phone2": (values.phone2 === '') ? '' : formPhoneNumber(values.phone2),
                "mobilePhone": (values.mobilePhone === '') ? '' : formPhoneNumber(values.mobilePhone),
                "workPhone": (values.workPhone === '') ? '' : formPhoneNumber(values.workPhone),
                "fax": (values.fax === '') ? '' : formPhoneNumber(values.fax),
            };
            // add records
            if (selectedRow.length === 0) {
                dispatch(addAddress([payload]));
                dispatch(updateFormStatus(true));
            } else {
                // update records
                dispatch(editAddress({ ind: selectedIndex, data: payload }));
            }
            onClose();
        }
    });
    //If editable row data tiggers, then below useEffect will execute
    useEffect(() => {
        if (selectedRow.length > 0) {
            setFormValues({
                ...selectedRow[0],
                "phone1": (selectedRow[0].phone1 === '') ? '' : formatPhoneNumber(selectedRow[0].phone1),
                "phone2": (selectedRow[0].phone2 === '') ? '' : formatPhoneNumber(selectedRow[0].phone2),
                "mobilePhone": (selectedRow[0].mobilePhone === '') ? '' : formatPhoneNumber(selectedRow[0].mobilePhone),
                "workPhone": (selectedRow[0].workPhone === '') ? '' : formatPhoneNumber(selectedRow[0].workPhone),
                "fax": (selectedRow[0].fax === '') ? '' : formatPhoneNumber(selectedRow[0].fax)
            });

            selectedRow[0].addressCategory ? setinternational('true') : setinternational('false');
            setToolTipStatus('edit');
        }

    }, [selectedRow])
    useEffect(() => {
        history.location.pathname == "/admissions/students/add-students" ? setStudentFlag(true) : setStudentFlag(false)
        getApi(addInfo_dropdown).then((resp) => {
            const { active,
                addressType, country,
                state, billingCategory
            } = resp.data.data
            setDropdown({
                ...Dropdown,
                "activeDrop": active,
                "addressTypeDrop": addressType,
                "countryDrop": country,
                "stateDrop": state,
                "billingDrop": billingCategory
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    useEffect(() => {
        if (formik.values.addressType == "283") {
            studentFlag ? setBillingFlag(true) : setBillingFlag(false)
        } else setBillingFlag(false)
    }, [formik.values.addressType])
    useEffect(() => {
        (formik.values.addressCategory) ?
            setRadioFlag(true) : setRadioFlag(false)

    }, [formik.values.addressCategory])
    useEffect(() => {
        if (selectedRow.length > 0) {

        }
    }, [formik.values.active])

    useEffect(() => {
        //Checking if form is submitted or not 
        if (!formik.isSubmitting) return;
        //Checking the input if it has error and passing the error object {name:"name"} to scroll helper
        if (Object.keys(formik.errors).length > 0) {
            scrollIntoViewHelper(formik.errors);
        }
    }, [formik]);
    /// phone number validation

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classess.root}>
                <FormGroup>
                    <SelectInput mandatory={false} label={"SelectedCourse"} labelId='addressType' formikValue={formik.values.addressType} formikChange={formik.handleChange} dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.addressTypeDrop }}
                        errors={formik.errors.addressType}
                        touched={formik.touched.addressType}
                        isDoubled={true}
                    />
                </FormGroup>
                {/* <FormGroup>
                    <FormInputWrapper style={{ width: "100%" }} >
                        <InputLabel htmlFor="answer">{t('commonLabel.answer')}</InputLabel>
                        <TextareaAutosize
                            // disabled={!(popupHeaderTitle == "Give Answer")}
                            aria-label="minimum height"
                            maxRows={10}
                            minRows={6}
                            name="answer"
                            onChange={formik.handleChange}
                            value={formik.values.answer}
                            style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                        />
                    </FormInputWrapper>
                </FormGroup> */}
{/*              
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <ButtonWrapperFilter>
                        <Button aria-label={'Add'} className='BgYellowOrange' variant="contained" size="large" type="submit">
                            {selectedRow.length > 0 ? t('buttonLabel.update') : t('buttonLabel.add')}
                        </Button>
                        <Button onClick={() => { dispatch(updateFormStatus(false)); onClose(); }} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                            {t('buttonLabel.cancel')}
                        </Button>
                    </ButtonWrapperFilter>
                </div> */}
            </form>
            <GenericModal infoMessage={t('popupMessages.studentCreated')}
                open={activeAddress} onClose={setActiveAddress}
                buttonText={t('buttonLabel.ok')}
            />
        </>
    )
}

export default AddCourse;


