import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Card, CardContent, TextareaAutosize, InputLabel, Button } from '@material-ui/core';
import PopupHeader from '../../../common-page/popupHeader';
import Tooltips from '../../../../utils/Tooltip/Tooltip';
import { SelectInput, TextInput, DateInput, CheckBoxInputFilter } from '../../../../components/Forms';
import { FormGroup,  useStyles, ButtonWrapperFilter, FormInputWrapper, } from '../../../../styles/common-styles';
import { useTranslation } from 'react-i18next';
import { getApi, postApi } from '../../../../services/apiBase';
import { document_tracking, prospect_document_tracking} from '../../../../services/apiURL';
import { getDescription } from '../../../../helper/getDescription';
import { toastMessage } from '../../../../utils/Message/toasts';
import * as yup from 'yup';
import { admissions_addDocTracking, document_tracking_addDocumentTracking } from '../../../../services/navigation_folder/admissions'
import { registration_addDocTracking, registration_student_status } from '../../../../services/navigation_folder/registration';
import { financialAid_addDocTracking } from '../../../../services/navigation_folder/financialAid';

const AddDocTracking = ({ setIsloading, setTableRow, module, Dropdown, selectedStudent, selectedDoc, setShowAddData, setAddDoc, accessFlag }) => {
    const { t } = useTranslation();
    const classess = useStyles();
    const [formValues, setFormValues] = useState('');
    const username = localStorage.getItem("username");
    const dueDateDefault= new Date();
    const [toolTipStatus, setToolTipStatus] = React.useState('');
    const initialValues = {
        docTrackId: 0,
        locationId: module.moduleId,
        locationName: '',
        admissionGpagroupId: 0,
        docDate: dueDateDefault,
        compDate: null,
        docNameId: 0,
        docDescription: '',
        docStatusId: 0,
        userDefinedFieldId: 0,
        reference: '',
        internal: false,
        comments: '',
    }
    const validationSchema = yup.object({
        docDate: yup
            .date()
            .nullable()
            .typeError('')
            .max(new Date('12/12/2100')) // date max range 2100
            .min(new Date('12/12/2000')), // date min range 2000
        compDate: yup
            .date()
            .nullable()
            .typeError('')
            .min(new Date('12/12/2000')) // date min range 2000
            .max(new Date('12/12/2100')),
    });
    const formik = useFormik({
        initialValues: formValues || initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            let payload =
            {
                "docTrackId": selectedDoc.docTrackId ? selectedDoc.docTrackId : 0,
                "studentUid": selectedStudent.prospectId ? selectedStudent.prospectId : 0,
                "docDate": values.docDate,
                "docNameId": values.docNameId,
                "docDescription": values.docDescription,
                "docStatusId": values.docStatusId,
                "comments": values.comments,
                "reference": values.reference,
                "compDate": values.compDate,
                "userDefinedFieldId": values.userDefinedFieldId,
                "finAidYearSeq": 0,
                "docGroupID": 0,
                "locationId": module.moduleId,
                "admissionGpagroupId": values.admissionGpagroupId,
                "internal": values.internal,
                "isProspectDoc": (window.location.href.indexOf('/admissions/prospects/document-tracking')) ? true: false,
                "userId": username
            }
            setIsloading(true);
            postApi(`${document_tracking}?requestFrom=DIRECT`, [payload]).then((res) => {
                onCancel();
                getApi(`${prospect_document_tracking}/${selectedStudent.prospectId}/${-1}`).then((resp) => {
                    setTableRow(resp.data.data);
                    setIsloading(false);
                    selectedDoc.docTrackId ? toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated')) :
                        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyAdded'))
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err)
            })
        },
    })
    useEffect(() => {
        document.getElementsByTagName('html')[0].classList.add('popup-Page');
    }, [])
    useEffect(() => {
        module.moduleName && formik.setFieldValue("locationName", module.moduleName);
        if (selectedDoc.docTrackId) {
            setFormValues(selectedDoc);
            setToolTipStatus('edit');
            return;
        }
        setFormValues(initialValues);
    }, [])
    useEffect(() => {
        formik.setFieldValue("docDescription", getDescription(Dropdown.documentNames, formik.values.docNameId));
    }, [formik.values.docNameId])
    const onCancel = () => {
        setAddDoc(false)
        setShowAddData(true)
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    }
    return (
        <>
           
            <PopupHeader profileData={{profileName: selectedStudent.prospectName, profileId: selectedStudent.prospectId, Id: true }} title={selectedDoc.docTrackId ? t('commonLabel.updateDocumentTrackingModule') : t('commonLabel.addDocumentTrackingModule')} onClose={onCancel} navigationList={admissions_addDocTracking} />
            
            <form className={classess.root} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <TextInput label={t('commonLabel.documentLocation')} labelId={'locationName'} formikValue={formik.values.locationName} formikChange={formik.handleChange} disabled={true} />
                            <SelectInput label={t('commonLabel.admissionGPAGroup')}
                                labelId={"admissionGpagroupId"}
                                disabled={accessFlag}
                                formikValue={formik.values.admissionGpagroupId}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.admissionGPAGroup.filter(value => value.uniqueId !== -1) }}
                                formikChange={formik.handleChange} />
                            <DateInput disabled={accessFlag} label={t('commonLabel.documentDate')} labelId={"docDate"} formik={formik} defaultValue={formik.values.docDate} />
                            <DateInput disabled={accessFlag} label={t('commonLabel.completionDate')} labelId={"compDate"} formik={formik} defaultValue={formik.values.compDate} />
                        </FormGroup>
                        <FormGroup>
                            <SelectInput label={t('commonLabel.documentName')}
                                labelId={"docNameId"}
                                disabled={accessFlag}
                                formikValue={formik.values.docNameId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.documentNames }}
                            />
                            <TextInput disabled={accessFlag} label={t('commonLabel.description')} labelId={'docDescription'} formikValue={formik.values.docDescription} formikChange={formik.handleChange} />
                            <SelectInput label={t('commonLabel.status')}
                                labelId={"docStatusId"}
                                disabled={accessFlag}
                                formikValue={formik.values.docStatusId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.status }}
                            />
                            <SelectInput label={t('commonLabel.userDefinedField')}
                                labelId={"userDefinedFieldId"}
                                disabled={accessFlag}
                                formikValue={formik.values.userDefinedFieldId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: Dropdown.userDefinedFields }}

                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput disabled={accessFlag} label={t('commonLabel.reference')} maxlength={'60'} labelId={'reference'} formikValue={formik.values.reference} formikChange={formik.handleChange} />
                            <CheckBoxInputFilter disabled={accessFlag} label={t('commonLabel.internal')} labelId={'internal'} checked={formik.values.internal} formikValue={formik.values.internal} formikChange={formik.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <FormInputWrapper style={{ width: '100%' }}>
                                <InputLabel htmlFor="Comments">{t('commonLabel.comments')} </InputLabel>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    maxRows={10}
                                    minRows={4}
                                    name="comments"
                                    onChange={formik.handleChange}
                                    disabled={accessFlag}
                                    value={formik.values.comments}
                                    maxlength={'2000'}
                                    style={{ width: '100%', padding: '16px', boxSizing: 'border-box', height: 'auto', overflow: 'auto' }}
                                />
                            </FormInputWrapper>
                        </FormGroup>
                    </CardContent>
                    <CardContent>
                        <div style={{ display: 'flex' }}>
                            <Tooltips flag={toolTipStatus} row={{
                                "insertUserID": formik.values.insertUserId,
                                "insertTime": formik.values.insertTime,
                                "updateUserID": formik.values.updateUserId,
                                "updateTime": formik.values.updateTime
                            }} />
                            <ButtonWrapperFilter className="activityButton">
                                <Button disabled={(!formik.isValid)||(accessFlag)} aria-label={'Save'} className='BgYellowOrange' variant="contained" size="large" type="submit">
                                    {selectedDoc.docTrackId ? t('buttonLabel.update') : t('buttonLabel.add')}
                                </Button>
                                <Button aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large" onClick={onCancel}>
                                    {t('buttonLabel.cancel')}
                                </Button>
                            </ButtonWrapperFilter>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    )
}

export default React.memo(AddDocTracking);