export const billing_account_payable = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Billing Accounting', path: '/billing/billing-accounting', isLink: true },
    { name: 'Post Options', path: '/billing/billing-accounting/post-options', isLink: true },
    { name: 'Accounts Payable', isLink: false }
];
export const billing_accounts_payable = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Billing Accounting', path: '/billing/billing-accounting', isLink: true },
    { name: 'Re-Create Options', path: '/billing/billing-accounting/re-create-options', isLink: true },
    { name: 'Account Payable', isLink: false }
];
export const billing_batch = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', isLink: false }
];
export const billing_batch_addBatch = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Add Batch', isLink: false }
];
export const billing_batch_distribute = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Distribute', isLink: false }
];
export const billing_batch_addStudentBatchTransaction = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Student', path: '/billing/batch', isLink: true },
    { name: 'Add/Update Transaction', isLink: false }
];
export const billing_batch_addNonStudentBatchTransaction = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Non Student', path: '/billing/batch', isLink: true },
    { name: 'Add/Update Transaction', isLink: false }
];
export const billing_faculty = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Search Faculty', isLink: false },
];
export const billing_batch_paymentParametes = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Payment Parameters', isLink: false }
];
export const billing_batch_printBatch = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Print Batch', isLink: false }

];
export const billing_maintenance = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Maintenance', isLink: false }
];
export const billing_maintenance_addTransDoc = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Maintenance', path: '/billing/maintenace', isLink: true },
    { name: 'Add/Update Transaction', isLink: false }

];
export const billing_maintenance_userDefined = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Maintenance', path: '/billing/maintenace', isLink: true },
    { name: 'Billing', path: '/billing/maintenace/billing', isLink: true },
    { name: 'User Defined', isLink: false }

];
export const billing_maintenance_void = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Maintenance', path: '/billing/maintenace', isLink: true },
    { name: 'Void', isLink: false }

];
export const billing_cashier_entry = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Cashier Entry', isLink: false }
];
export const billing_cashier_entry_add_batch = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Cashier Entry', path: '/billing/cashier entry', isLink: true },
    { name: 'Add Batch', isLink: false }
];
export const billing_cashier_entry_transaction_type = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Cashier Entry', path: '/billing/cashier entry', isLink: true },
    { name: 'Select Transaction Type', isLink: false }
];
export const billing_cashier_entry_add_batch_transaction = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Cashier Entry', path: '/billing/cashier entry', isLink: true },
    { name: 'Add/Update Batch Entry', isLink: false }
];
export const billing_payment_plan_maintenance = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Payment Plans', path: '/billing/payment plans', isLink: true },
    { name: 'Maintenance', isLink: false }
];
export const billing_payment_plan_maintenance_add_payment_plan = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Payment Plans', path: '/billing/payment plans', isLink: true },
    { name: 'Maintenance', path: '/billing/payment plans/maintenance', isLink: true },
    { name: 'Add/Update Payment plan', isLink: false }
];
export const billing_account_general = [
    { name: 'Billing', path: '/billing/billing-accounting', isLink: true },
    { name: 'Billing Accounting', path: '/billing/billing-accounting/post-options', isLink: true },
    { name: 'Post Options', path: '/billing/billing-accounting/post-options/general-ledger', isLink: true },
    { name: 'General Ledger', isLink: false },
];
export const billing_account_payment = [
    { name: 'Billing', path: '/billing/setup', isLink: true },
    { name: 'Payment Plans', path: '/billing/paymentplans/setup', isLink: true },
    { name: 'Setup', path: '/billing/paymentplans/setup/paymentplantypesetup', isLink: true },
    { name: 'Payment Plan Type Setup', isLink: false },
];
export const billing_account_payment_addpayment = [
    { name: 'Billing', path: '/billing/setup', isLink: true },
    { name: 'Payment Plans', path: '/billing/paymentplans/setup', isLink: true },
    { name: 'Setup', path: '/billing/paymentplans/setup/paymentplantypesetup', isLink: true },
    { name: 'Payment Plan Type Setup', path: '/billing/paymentplans/setup/paymentplantypesetup/addpayment', isLink: true },
    { name: ' Add/Update Payment Plan Type Setup', isLink: false },
];
export const billing_account_payment_addtrans = [
    { name: 'Billing', path: '/billing/setup', isLink: true },
    { name: 'Payment Plans', path: '/billing/paymentplans/setup', isLink: true },
    { name: 'Setup', path: '/billing/paymentplans/setup/paymentplantypesetup', isLink: true },
    { name: 'Payment Plan Type Setup', path: '/billing/paymentplans/setup/paymentplantypesetup/addtransdocument', isLink: true },
    { name: ' Add/Update Transaction Document', isLink: false },
];
export const billing_account_payment_addfee = [
    { name: 'Billing', path: '/billing/setup', isLink: true },
    { name: 'Payment Plans', path: '/billing/paymentplans/setup', isLink: true },
    { name: 'Setup', path: '/billing/paymentplans/setup/paymentplantypesetup', isLink: true },
    { name: 'Payment Plan Type Setup', path: '/billing/paymentplans/setup/paymentplantypesetup/addfee', isLink: true },
    { name: 'Add/Update Fee', isLink: false },
];
export const billing_byor = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports Menu ', path: '/billing/reports menu', isLink: true },
    { name: 'BYOR', isLink: false }
];
export const billing_1098Texport = [
    { name: "Billing", path: "/billing", isLink: true },
    { name: "Reports Menu ", path: "/billing/reports menu", isLink: true },
    { name: "1098-T Export", isLink: false },
];
export const lookup_posting = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Lookups', path: '/billing/reports menu', isLink: true },
    { name: 'Posting', isLink: false },
];


export const billing_reports_aging = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports Menu', path: '/billing/reportsMenu', isLink: true },
    { name: 'Aging Reports', isLink: false },

];
export const billing_reports_online_payments = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reportsMenu', isLink: true },
    { name: 'Online Payment', isLink: false },

];
export const billing_reports_t4a_form = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reportsMenu', isLink: true },
    { name: 'T4A Form', isLink: false },

];
export const source_billing = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Lookups', path: '/billing/reports menu', isLink: true },
    { name: 'SourceBillingBatch', isLink: false },
];
export const billing_t2202AForm_form = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reportsMenu', isLink: true },
    { name: 'T2202 Form ', isLink: false },
];

export const billing_addDetails = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reportsMenu', isLink: true },
    { name: 'T2202 Form ', isLink: false },
];

export const billing_UpdateDetails = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reportsMenu', isLink: true },
    { name: 'update T2202A Form ', isLink: false },
];

export const billing_statements = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reports menu', isLink: true },
    { name: 'Statements', isLink: false },
];
export const billing_comments = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports', path: '/billing/reports menu', isLink: true },
    { name: 'Billing Comments', isLink: false },
];


export const billing_1098tfrom = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports Menu ', path: '/billing/reports menu', isLink: true },
    { name: '1098-T Form', isLink: false }
];
export const billing_repotsMenu_add1098TForm = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports Menu ', path: '/billing/reports menu', isLink: true },
    { name: 'Add/Update 1098-T From', isLink: false }
]
export const billing_repotsMenu_recordsPresent = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Reports Menu ', path: '/billing/reports menu', isLink: true },
    { name: 'Records Present', isLink: false }
]
export const billing_alter_plans = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Payment Plans ', path: '/billing/payment-plans', isLink: true },
    { name: 'Alter Plan', isLink: false }
]
export const add_plan_map = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Payment Plans ', path: '/billing/payment-plans', isLink: true },
    { name: 'Alter Plan',path: '/billing/payment-plans/alter-plan', isLink: false },
    { name: 'Add/Update Alter Payment Plan Details', isLink: false }
]
export const billing_account_recreateOption_ledger = [
    { name: 'Billing', path: '/billing/billing-accounting', isLink: true },
    { name: 'Billing Accounting', path: '/billing/billing-accounting/recreate-options', isLink: true },
    { name: 'Re-Create Options', path: '/billing/billing-accounting/recreate-options/general-ledger', isLink: true },
    { name: 'General Ledger', isLink: false },
];
export const billing_account_recreateOptions = [
    { name: 'Billing', path: '/billing/billing-accounting', isLink: true },
    { name: 'Billing Accounting', path: '/billing/billing-accounting/recreate-options', isLink: true },
    { name: 'Re-Create Options', path: '/billing/billing-accounting/recreate-options/general-ledger', isLink: true },
    { name: 'General Ledger', path: '/billing/billing-accounting/recreate-options/general-ledger/recreate-options', isLink: true },
    { name: 'Recreate Options', isLink: false },
];
export const billing_change_address = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Change Address', isLink: false }
];
export const billing_add_address = [
    { name: 'Billing', path: '/billing/change-address', isLink: true },
    { name: 'Change Address', path: '/billing/change-address/add-address', isLink: true },
    { name: 'Add/Update Address', isLink: false }

]
export const billing_change_address_copyAddress = [
    { name: 'Billing', path: '/billing/change-address', isLink: true },
    { name: 'Change Address', path: '/billing/change-address/copyaddress', isLink: true },

    { name: 'Copy Address', isLink: false }
]
export const billing_authorize = [
    { name: 'Billing', path: '/billing/authorize', isLink: true },
    { name: 'Authorize', isLink: false }

]

export const billing_searchContact = [
    { name: 'Billing', path: '/billing/authorize', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Search Contact', isLink: false }
]

export const billing_addRecreateAccount = [
    { name: 'Billing', path: '#', isLink: true },
    { name: 'Billing Accounting', path: '#', isLink: true },
    { name: 'Re-Create Options', path: '#', isLink: true },
    { name: 'Accounts Payable', isLink: true },
    { name: 'Recreate Batch', isLink: false }
];
export const billing_transactionType = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Batch', path: '/billing/batch', isLink: true },
    { name: 'Transaction Document', isLink: false }
]
export const billing_student_search =  [
    { name: 'Billing', path: '/billing/student-search', isLink: true },
    { name: 'Search Student', isLink: false }
]
export const Billing_DocumentTracking = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Document Tracking', isLink: false }
];
export const Blling_addDocTracking = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Document Tracking', path: '/billing/document-tracking', isLink: true },
    { name: 'Add/Update Document Tracking', isLink: false }
];
export const Billing_loadColleges = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Document Tracking', path: '/billing/document-tracking', isLink: true },
    { name: 'Load Colleges', isLink: false }
];
export const Billing_loadDocSequence = [
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Document Tracking', path: '/billing/document-tracking', isLink: true },
    { name: 'Load Document Sequence', isLink: false }
];
export const billing_Holds=[
    { name: 'Billing', path: '/billing/holds', isLink: true },
    { name: 'Student Holds', isLink: false },
]
export const billing_addDetail=[
    { name: 'Billing', path: '/billing', isLink: true },
    { name: 'Student Holds', path: '/billing/holds', isLink: true},
    { name: 'Add/Update Business Hold', isLink: false },
]
export const billing_studentNotes=[
    { name: 'Billing', path: '/billing/student-notes', isLink: true },
    { name: 'Student Notes', isLink: false }
   
]
export const billing_addNotes=[
    { name: 'Billing', path: '/billing/student-notes', isLink: true },
    { name: 'Student Notes',isLink: true},
    { name: 'Add/Update Notes', isLink: false },
]
export const billing_addUserList=[
    { name: 'Billing', path: '/billing/student-notes', isLink: true },
    { name: 'Student Notes',isLink: true},
    { name: 'Groups',isLink: true},
    { name: 'User List Criteria', isLink: false },
]