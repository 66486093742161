import React, { useState, useEffect } from 'react'

import { Button, Card, CardContent } from '@material-ui/core'
import { useFormik } from 'formik'
import {
  SelectInput,
} from '../../../../components/Forms'
import {
  FormGroup,
  ButtonWrapperFilter,
  useStyles,
  Splitter,
} from '../../../../styles/common-styles'
import { useTranslation } from 'react-i18next'
import { financialAid_report } from '../../../../services/navigation_folder/financialAid'
import PopupHeader from '../../../common-page/popupHeader'
import * as yup from 'yup'
import {
  more_tab_add_report,
  more_tab_acadmecId,
} from '../../../../services/apiURL'
import { getApi } from '../../../../services/apiBase'
import { updateAccessSelector } from '../../../../redux/common/roleAccess/selectors'
import { useSelector } from 'react-redux'
import { maintenance } from '../../../../helper/constants'

const AddReport = ({ onClose, selectedRow }) => {
  const { t } = useTranslation([
    'translation',
    'financialAid',
    'financialAidHeadcells',
  ])
  const classess = useStyles()
  const initialValues = {
    reports: '',
    academicYears: '',
    financialAidYear: '',
    addressTypes: '',
    terms: '',
    termTo: ''
  }

  const validationSchema = yup.object({
    terms:
      yup.number()
        .min(1, t('validationMessages.isRequired', { what: t('commonLabel.term') }))
        .required(t('validationMessages.isRequired', { what: t('commonLabel.termFrom') })),
    termTo:
      yup.number()
        .min(1, t('validationMessages.isRequired', { what: t('commonLabel.term') }))
        .required(t('validationMessages.isRequired', { what: t('commonLabel.termTo') })),
    reports: yup
      .string('')
      .required(
        t('validationMessages.isRequired', {
          what: t('commonLabel.selectReport', { ns: 'financialAid' }),
        }),
      ),
    academicYears: yup
      // .date()
      // .nullable()
      .string('')
      .required(
        t('validationMessages.isRequired', {
          what: t('commonLabel.academicYear', { ns: 'financialAid' }),
        }),
      ),
    financialAidYear: yup
      .string('')
      .required(
        t('validationMessages.isRequired', {
          what: t('commonLabel.financialAidYear', { ns: 'financialAid' }),
        }),
      ),
    addressTypes: yup
      .string('')
      .required(
        t('validationMessages.isRequired', {
          what: t('commonLabel.addressType', { ns: 'financialAid' }),
        }),
      ),
  })
  const handleAcademicYear = (e) => {
    formik.handleChange(e)
    const { value } = e.target
    getApi(`${more_tab_acadmecId}/${value}`)
      .then((resp) => {
        setDropdown({
          ...Dropdown,
          finacialDrop: resp.data.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => { },
  })
  const [Dropdown, setDropdown] = useState({
    reportsDrop: [],
    addressTypeDrop: [],
    academicDrop: [],
    finacialDrop: [],
    termDrop: []
  })
  useEffect(() => {
    getApi(more_tab_add_report)
      .then((resp) => {
        const {
          academicYears,
          addressTypes,
          reports,
          terms
        } = resp.data.data
        setDropdown({
          ...Dropdown,
          reportsDrop: reports,
          addressTypeDrop: addressTypes,
          academicDrop: academicYears,
          termDrop: terms
        })
      })
      .catch((err) => {
        console.log(err)
      })
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
  }, [])
  // Role based access control redux
  const { access } = useSelector(updateAccessSelector)
  const { nestedParentLevel } = access;

  const [statusAccess, setStatusAccess] = useState({
    maintenance: 2,
  })
  useEffect(() => {


    let finStatusAccess = nestedParentLevel.filter((v, i) =>
      v.menuItemName.trim() === maintenance && v.moduleorder === 4
    )

    setStatusAccess({
      ...statusAccess,
      maintenance: (finStatusAccess.length > 0) ? finStatusAccess[0].menuAccess : 2,
    })

  }, [access])
  const [accessFlag, setAccessFlag] = useState(false)
  useEffect(() => {
    (statusAccess.maintenance !== 2) ? setAccessFlag(true) : setAccessFlag(false)
  }, [statusAccess])
  //RBA Ends here


  return (
    <>
      <PopupHeader
        title={t('commonLabel.reportOptions', { ns: 'financialAid' })}
        navigationList={financialAid_report}
        onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <FormGroup>
              <SelectInput
                label={t('commonLabel.selectReport', { ns: 'financialAid' })}
                labelId={'reports'}
                formikValue={formik.values.reports}
                formikChange={formik.handleChange}
                errors={formik.errors.reports}
                touched={formik.touched.reports}
                disabled={accessFlag}
                mandatory
                dropdownData={{
                  key: 'displayText',
                  value: 'uniqueId',
                  optionLists: Dropdown.reportsDrop,
                }}
              />
            </FormGroup>
            {(formik.values.reports.includes('Package') || formik.values.reports.includes('1002')) && (!formik.values.reports.includes('1003')) && (formik.values.reports) ?
              <>
                <FormGroup>
                  <SelectInput
                    label={t('commonLabel.academicYear', { ns: 'financialAid' })}
                    labelId={'academicYears'}
                    formikValue={formik.values.academicYears}
                    formikChange={handleAcademicYear}
                    errors={formik.errors.academicYears}
                    touched={formik.touched.academicYears}
                    mandatory
                    dropdownData={{
                      key: 'displayText',
                      value: 'uniqueId',
                      optionLists: Dropdown.academicDrop,
                    }}
                  />
                  <SelectInput
                    label={t('commonLabel.financialAidYear', {
                      ns: 'financialAid',
                    })}
                    labelId={'financialAidYear'}
                    formikValue={formik.values.financialAidYear}
                    formikChange={formik.handleChange}
                    errors={formik.errors.financialAidYear}
                    touched={formik.touched.financialAidYear}
                    mandatory
                    dropdownData={{
                      key: 'displayText',
                      value: 'uniqueId',
                      optionLists: Dropdown.finacialDrop,
                    }} />
                </FormGroup>
              </> : (!formik.values.reports.includes('1003')) && (formik.values.reports) &&
              <FormGroup>
                <SelectInput
                  label={t('commonLabel.termFrom', { ns: 'financialAid' })}
                  labelId={'terms'}
                  formikValue={formik.values.terms}
                  formikChange={formik.handleChange}
                  errors={formik.errors.terms}
                  touched={formik.touched.terms}
                  mandatory
                  dropdownData={{
                    key: 'displayText',
                    value: 'uniqueId',
                    optionLists: Dropdown.termDrop,
                  }}
                />
                <SelectInput
                  label={t('commonLabel.to', { ns: 'financialAid' })}
                  labelId={'termTo'}
                  formikValue={formik.values.termTo}
                  formikChange={formik.handleChange}
                  errors={formik.errors.termTo}
                  touched={formik.touched.termTo}
                  mandatory
                  dropdownData={{
                    key: 'displayText',
                    value: 'uniqueId',
                    optionLists: Dropdown.termDrop,
                  }}
                />
              </FormGroup>}
            {(formik.values.reports) && (!formik.values.reports.includes('1003')) ?
              <FormGroup>
                <SelectInput
                  label={t('commonLabel.addressType', { ns: 'financialAid' })}
                  labelId={'addressTypes'}
                  formikValue={formik.values.addressTypes}
                  formikChange={formik.handleChange}
                  errors={formik.errors.addressTypes}
                  touched={formik.touched.addressTypes}
                  mandatory
                  dropdownData={{
                    key: 'displayText',
                    value: 'uniqueId',
                    optionLists: Dropdown.addressTypeDrop,
                  }}
                />
              </FormGroup> : null}
            <Splitter height={'10px'} />
            <div style={{ display: 'flex' }}>
              <ButtonWrapperFilter>
                <Button
                  aria-label={'Send'}
                  className="BgYellowOrange Search"
                  variant="contained"
                  size="large"
                  type='submit'
                disabled={accessFlag}
                >
                  {t('buttonLabel.print', { ns: 'financialAid' })}
                </Button>
                <Button
                  aria-label={'Cancel'}
                  className="BgLightBlue"
                  variant="contained"
                  size="large"
                  onClick={() => onClose('')}
                >
                  {t('buttonLabel.cancel', { ns: 'financialAid' })}
                </Button>
              </ButtonWrapperFilter>
            </div>
          </CardContent>
        </Card>
      </form>
    </>
  )
}
export default AddReport
