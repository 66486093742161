import { UPDATE_FORM, UPDATE_WORK_STUDY_DETAILS } from './types'
const initialState = {
    workStudySetup: {
        allowJobOverride: false,
        awardTypeReqd: false,
        createFAAward: false,
        docTrackSeqInPackageReqd: false,
        docTrackSeqNotInPackageReqd: false,
        existingFAAwardReqd: false,
        faStatusReqd: false,
        maxAmountReqd: false,
        maxUnitsReqd: false,
        tsCheckTimeOverlap: false,
        tsCheckTrackSeqComplete: false,
        tsDisableMassAddBtn: false,
        tsStartEndDtReqd: false,
        tsUpdateFAAwardAmount: false,
        workerCanChangeJob: false,
    },
    workStudyDetails: {}
};
const workStudySetupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_FORM:
            return {
                ...state,
                workStudySetup: payload
            }
        case UPDATE_WORK_STUDY_DETAILS:
            return {
                ...state,
                workStudyDetails: payload
            }
        default:
            return state;
    }
}
export default workStudySetupReducer